<div class="row" style="width:100%;margin:0 auto;">
   <div class="col-12">
      <mdb-card>
         <mdb-card-header class="bg-white ">
            <div class="headerCover">
               <div class="headerTilte app_list_header">
                  <h5 class="m-0" class="mainHeadingStyle">OT Booking</h5>
               </div>
               <div class="headerButtons p-2">
                  <!-- *ngIf="!showCalendarViewFlag" (click)="calendarView()" -->
                  <div *ngIf="!showCreateDetailFlag" class="radio-inputs mx-2">
                     <label class="radio">
                        <input type="radio" name="radio" value="Table" (change)="changeView($event)">
                        <span class="name">Tabular View</span>
                     </label>
                     <label class="radio">
                        <input type="radio" name="radio" value="Calendar" (change)="changeView($event)"
                           [checked]="true">
                        <span class="name">Calendar View</span>
                     </label>
                  </div>
                  
                  <img *ngIf="!showCreateDetailFlag" class="saveimgbtn_inpatinfo"
                     src="../../../assets/ui_icons/buttons/new_button.svg" (click)="createBooking()"
                     style="margin-left: 9px ;" />
                  <img *ngIf="showCreateDetailFlag" class="saveimgbtn_inpatinfo"
                     src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="goBack()"
                     style="margin-left: 9px ;" />
                  <img *ngIf="showCreateDetailFlag" src="../../../assets/ui_icons/buttons/save_button.svg"
                     class="saveimgbtn_inpatinfo" (click)="saveDetails('add')" />
               </div>
            </div>
         </mdb-card-header>
         <mdb-card-body>
            <div class="row" *ngIf="otListFlag">
               <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <p class="nodata" *ngIf="otDataList.length == 0">No OT(s) found</p>
                  <table *ngIf="otDataList.length" mdbTable datatable [dtOptions]="dtOptions"
                     class="mt-4 mb-4 table table-nowrap table-sm dataTable billlisttable" style="width: 95%;">
                     <thead>
                        <tr>
                           <th>Profile Image</th>
                           <th>Patient name</th>
                           <th>Operation Theatre Name</th>
                           <th>Booking Date</th>
                           <th>From Time</th>
                           <th>To Time</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr *ngFor="let ot of otDataList; let i = index" (click)="getOtList(ot)">
                           <td><img src="{{ot.profile_image}}" onerror="this.src='../../../assets/img/default.jpg';"
                                 height="40px" width="40px" class="img-fluid z-depth-1 rounded-circle mr-1"></td>
                           <td style="font-size: 14px; text-align: center;">{{ ot.patFirstName }}</td>
                           <td style="font-size: 14px; text-align: center;">{{ ot.ot_name }}</td>
                           <td style="font-size: 14px; text-align: center;">{{ ot.booking_date }}</td>
                           <td style="font-size: 14px; text-align: center;">{{ ot.from_time}}</td>
                           <td style="font-size: 14px; text-align: center;">{{ ot.to_time}}</td>
                        </tr>
                     </tbody>
                  </table>
                  <br><br><br>
               </div>
            </div>
            <div class="row justify-content-center" *ngIf="showCreateDetailFlag">
               <div class="col-12">
                  <div class="conatiner-fluid">
                     <div class="row d-flex justify-content-center align-items-center p-3">
                        <div class="col-3 d-flex justify-content-center">
                           <img class="profile-pic" src="../../../assets/img/default.jpg" width="120px" />
                        </div>
                        <div class="col-9">
                           <div class="row">
                              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 m-2">
                                 <mat-label class="matlabel label-style">Patient Name
                                    <input type="text" class="ipcss inputStyle2" [(ngModel)]="patName" matInput
                                       [matAutocomplete]="auto1" (keyup)="getInpatients($event)" />
                                    <mat-autocomplete #auto1="matAutocomplete">
                                       <mat-option id="optionfont" *ngFor="let inpatient of inpatientList"
                                          (click)="selectInpatients(inpatient)" value="{{inpatient.inpatient_id}}">
                                          {{inpatient.name}}
                                       </mat-option>
                                    </mat-autocomplete>
                                 </mat-label>
                              </div>
                              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 m-2">
                                 <mat-label class="matlabel label-style">Operation Theatre<br>
                                    <mat-select [(ngModel)]="otId" class="ipcss inputStyle2" required>
                                       <mat-option value="select">Select</mat-option>
                                       <mat-option *ngFor="let otl of otList" value="{{otl.ot_id}}">{{otl.ot_name}}
                                       </mat-option>
                                    </mat-select>
                                 </mat-label>
                              </div>
                              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 m-2">
                                 <mat-label class="matlabel label-style">Date Of Booking
                                    <input type="date" class="ipcss inputStyle2" [(ngModel)]="surgery_date" matInput />
                                 </mat-label>
                              </div>
                              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-4 m-2">
                                 <mat-label class="matlabel label-style">Start time<br></mat-label>
                                 <div class="row">
                                    <div class="col-3" style="padding-right:0;">
                                       <select type="date" class="ipcss inputStyle2 inpat_width"
                                          [(ngModel)]="start_time1">
                                          <option *ngFor="let hrs of surgery_hrs" value="{{hrs}}">{{hrs}}
                                          </option>
                                       </select>
                                    </div>
                                    <div class="col-1" style="padding:0;position: relative;top: 5px;left: 8px;">
                                       : </div>
                                    <div class="col-3" style="padding-left:0;">
                                       <select type="date" class="ipcss inputStyle2 inpat_width"
                                          [(ngModel)]="start_time2">
                                          <option *ngFor="let min of surgery_min" value="{{min}}">{{min}}
                                          </option>
                                       </select>
                                    </div>
                                    <div class="col-5">
                                       <select type="date" class="ipcss inputStyle2 inpat_width"
                                          [(ngModel)]="start_time3">
                                          <option value="AM">AM</option>
                                          <option value="PM">PM</option>
                                       </select>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-4 m-2">
                                 <mat-label class="matlabel label-style">End time<br></mat-label>
                                 <div class="row">
                                    <div class="col-3" style="padding-right:0;">
                                       <select type="date" class="ipcss inputStyle2 inpat_width"
                                          [(ngModel)]="end_time1">
                                          <option *ngFor="let hrs of surgery_hrs" value="{{hrs}}">{{hrs}}
                                          </option>
                                       </select>
                                    </div>
                                    <div class="col-1" style="padding:0;position: relative;top: 5px;left: 8px;">
                                       : </div>
                                    <div class="col-3" style="padding-left:0;">
                                       <select type="date" class="ipcss inputStyle2 inpat_width"
                                          [(ngModel)]="end_time2">
                                          <option *ngFor="let min of surgery_min" value="{{min}}">{{min}}
                                          </option>
                                       </select>
                                    </div>
                                    <div class="col-5">
                                       <select type="date" class="ipcss inputStyle2 inpat_width"
                                          [(ngModel)]="end_time3">
                                          <option value="AM">AM</option>
                                          <option value="PM">PM</option>
                                       </select>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-12 card-container mt-3">
                  <div class="conatiner-fluid">
                     <div class="row">
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 m-2">
                           <mat-label class="label-style">Specialization<br>
                              <mat-select class="ipcss inputStyle2 inpat_width" [(ngModel)]="spl_id">
                                 <mat-option Selected>Select</mat-option>
                                 <mat-option *ngFor="let spl of spl_array" (click)="Change_Specialization(spl.spl_id)"
                                    value="{{spl.spl_id}}">
                                    {{spl.spl_name}}</mat-option>
                              </mat-select>
                           </mat-label>
                        </div>

                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 m-2">
                           <mat-label class="matlabel label-style">Surgery<br>
                              <mat-select  class="ipcss inputStyle2 inpat_width" [(ngModel)]="surgery_id" >
                                 <mat-option Selected>Select</mat-option>
                                 <mat-option value="all">All</mat-option>
                                 <mat-option *ngFor="let surgery of surgery_list" (click)="showsurgery()" value="{{surgery.surgery_id}}" >
                                    {{surgery.surgery_name}}
                                 </mat-option>
                              </mat-select>
                           </mat-label>
                        </div>

                        <div class="col-6 col-sm-12 col-md-4 col-lg-3 col-xl-4 col-xxl-4 m-2">
                           <mat-label class="label-style">Surgeons<br>
                              <mat-select class="ipcss inputStyle2 inpat_width" [(ngModel)]="surg_doc" multiple>
                                 <mat-option *ngFor="let doctor of surgeon_list" (click)="surgeonsdata(doctor)" value="{{doctor.prov_id}}">
                                    {{doctor.docname}}</mat-option>
                              </mat-select>
                           </mat-label>
                        </div>
                        <div class="col-1" style="padding: 0;">
                           <img  class="manuf_add"style="width:20px;height:20px;position:relative;top: 33px;"  (click)="addsurgendetails()" src="../../../assets/ui_icons/Add_icon.svg" />
                       </div>

                       <div class="table-responsive" [hidden]="surgerydet.length == 0">
                       <table [hidden]="surgerydet.length == 0">
                        <thead >
                        <tr>
                           <td>Specialization</td>
                           <td>Surgery name</td>
                           <td>doctor name</td>
                           <td></td>
                           </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let surg of surgerydet">
                           <td>{{surg.Specialization}}</td>
                           <td>{{surg.surgery_name}}</td>
                           <td>{{surg.surgeon}}</td>
                           <td [hidden]="surg.inp_surgery_id == '1'">
                              <a (click)="deletesurg(surg)">
                                 <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="20" height="20" /></a>
                           </td>
                           </tr>
                        </tbody>
                        </table>
                        </div>

                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 m-2">
                           <mat-label class="label-style">Consultants<br>
                              <mat-select class="ipcss inputStyle2 inpat_width" [(ngModel)]="consultant" multiple>
                                 <mat-option *ngFor="let doctor of surgeon_list" value="{{doctor.prov_id}}">
                                    {{doctor.docname}}</mat-option>
                              </mat-select>
                           </mat-label>
                        </div>

                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 m-2">
                           <mat-label class="label-style">Anesthetist<br>
                              <mat-select class="ipcss inputStyle2 inpat_width" [(ngModel)]="anaest_doc" multiple>
                                 <mat-option *ngFor="let doctor of anaest_doctors" value="{{doctor.doc_reg_id}}">
                                    {{doctor.name}}</mat-option>
                              </mat-select>
                           </mat-label>
                        </div>

                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 m-2">
                           <mat-label class="label-style">Anesthesia Type<br>
                              <mat-select class="ipcss inputStyle2 inpat_width" [(ngModel)]="anaesttype">
                                 <mat-option Selected>Select</mat-option>
                                 <mat-option *ngFor="let type of anaest_types" value="{{type.type_id}}" (click)="showsurgery()">
                                    {{type.description}}</mat-option>
                              </mat-select>
                           </mat-label>
                        </div>



                        <div class="col-12" style="margin-top: 10px;">
                           <input class="mr-2" type="checkbox" id="surgery" name="vehicle1" value="true"
                              [(ngModel)]="isPatUpd">
                           <label class="mr-3" for="surgery"> is patient updated about the procedure and
                              process of the surgery</label><br>
                        </div>
                     </div>
                  </div>
               </div>

               <mat-accordion [hidden]="surgequpart" class="mataccordion mt-3">
                  <mat-expansion-panel class="expandpanel" [expanded]="true">
                     <mat-expansion-panel-header class="exppanel width">
                        <mat-panel-title class="title">
                           Surgery items
                        </mat-panel-title>
                     </mat-expansion-panel-header>
                     <hr class="hr">
                     <div class="conatiner-fluid">
                        <div class="row">
                           <div class="col-12 card-container1 mt-2">
                              <div class="conatiner-fluid">
                                <div class="container">
                                 <div class="row">
                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 masterboxcol"
                                       *ngFor="let equp of surgeryitemlist" >

                                        <table id="salesTable" class="table table-sm discount_table">
                                            <tbody style="border-radius: 10px;">
                                                <tr style="margin-bottom: 5px;">
                                                    <td style="  text-align: left;padding-left:5px;
                                                    padding-bottom: 15px;width: 80%;">
                                                      {{equp.item}}
                                                   </td>
                                                    <td style="  text-align: right;padding-bottom: 15px;width: 20%;">
                                                    <input type="text" [(ngModel)]="equp.qty" style="text-align:right" class="ipcss inputStyle2 inpat_width" matInput />
                                                    </td>
                                                    <td (click)="deletesurgeryitem(equp.item)">
                                                      <img src="../../assets/dist/images/delete.png"
                                                      width="20" height="20"
                                                      style="margin: 0 5px;" />
                                                      </td>
                                                </tr>
                                            </tbody>

                                        </table>

                                    </div>

                                    <div class="col-1" style="padding: 0;">
                                       <img  class="manuf_add"  (click)="redirect()"
                                           src="../../../assets/ui_icons/Add_icon.svg" style="width: 22px;height: 22px;position: relative;top: 26px;left: 16px;"/>
                                    </div>
                                </div>
                                </div>

                              </div>

                           </div>
                                                      
                        </div>
                     </div>
                  </mat-expansion-panel>
               </mat-accordion>
               <div class="col-12" style="margin-top: 10px;">
                  <div class="conatiner-fluid">
                     <div class="row">
                        <div class="col-6">
                           <div class="ql-container">
                              <div class="col-12">
                                 <mat-label class="matlabel"><b class="label_bold">OT Notes</b></mat-label>
                              </div>
                              <div class="col-12">

                                 <quill-editor id="editor1" [(ngModel)]="otNotes"
                                    [styles]="{height: 'calc(30vh - 100px)'}" [modules]="modules"
                                    placeholder="Enter Text" (onContentChanged)="changeOTNotes($event)">
                                 </quill-editor>
                                 <div>
                                    <quill-view-html hidden id="viewhtml1" style="overflow: hidden;"
                                       [content]="otNotes"></quill-view-html>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-6">
                           <div class="ql-container">
                              <div class="col-12">
                                 <mat-label class="matlabel"><b class="label_bold">Anaestesia
                                       procedure</b></mat-label>
                              </div>
                              <div class="col-12">
                                 <quill-editor id="editor2" [(ngModel)]="ansProc"
                                    [styles]="{height: 'calc(30vh - 100px)'}" [modules]="modules"
                                    placeholder="Enter Text" (onContentChanged)="changeAnaeste($event)"></quill-editor>
                                 <div>
                                    <quill-view-html hidden id="viewhtml1" [content]="ansProc"></quill-view-html>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-6">
                           <div class="ql-container">
                              <div class="col-12">
                                 <mat-label class="matlabel"><b class="label_bold">Surgery
                                       procedure</b></mat-label>
                              </div>
                              <div class="col-12">
                                 <quill-editor id="editor3" [(ngModel)]="surgeryProc"
                                    [styles]="{height: 'calc(30vh - 100px)'}" [modules]="modules"
                                    placeholder="Enter Text"></quill-editor>
                                 <div>
                                    <quill-view-html hidden id="viewhtml1" [content]="surgeryProc"></quill-view-html>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-6">
                           <div class="ql-container">
                              <div class="col-12">
                                 <mat-label class="matlabel"><b class="label_bold">Surgery
                                       outcome</b></mat-label>
                              </div>
                              <div class="col-12">
                                 <quill-editor id="editor4" [(ngModel)]="surgeryOut"
                                    [styles]="{height: 'calc(30vh - 100px)'}" [modules]="modules"
                                    placeholder="Enter Text"></quill-editor>
                                 <div>
                                    <!-- Preview -->
                                    <quill-view-html hidden id="viewhtml1" [content]="surgeryOut"></quill-view-html>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row justify-content-center" *ngIf="showCalendarViewFlag">
               <div class="col-10">
                  <full-calendar [events]="eventList" [options]="calendarOptions"></full-calendar>
               </div>
            </div>
         </mdb-card-body>
      </mdb-card> <br><br><br>
   </div>
</div>