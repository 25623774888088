import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Helper_Class } from '../../helper_class';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Time_Formate, Date_Formate } from '../../../assets/js/common.js';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Chart } from 'chart.js';
@Component({
   selector: 'app-diagnosis-report-approval-detailedview',
   templateUrl: './diagnosis-report-approval-detailedview.component.html',
   styleUrls: ['./diagnosis-report-approval-detailedview.component.scss']
})
export class DiagnosisReportApprovalDetailedviewComponent implements OnInit {
   public tableViewTestFlag: boolean;
   public paraViewReportTypeFlag: boolean;
   public dynamicHeading;
   public updateButtonFlag: boolean;
   public reportType;
   public diagAppID;
   public presTestID;
   public diagID;
   public clientName;
   public clientGender;
   public clientAge;
   public reviewSign;
   public signFlag: boolean;
   public revieSigSection: boolean;
   public reviewFlag: boolean;
   public reviewBy;
   public sampleCollectedTime;
   public report_img;
   public doctorFlag: boolean;
   public sequences;
   public impression;
   public tempTestList = [];
   public DiagTestReadingArray = [];
   public diagName;
   public diagAdd;
   public diagLocation;
   public diagCityZip;
   public diagStateCon;
   public diagTelephone;
   public clentID;
   public diagReferBy;
   public date;
   public time;
   public testArray = [];
   public sequence;
   public Impression;
   public reportFlag;
   public approvelFlag: boolean;
   public uploadFlag: boolean;
   public loader: boolean;
   public reportDetail;
   public testName: string;
   public testMethod;
   public subtestFlag: boolean = false;
   public subTest;
   public subTestMedFlag: boolean;
   public createdBy;
   public detailedViewFlag: boolean = false;
   public culture;
   public cultureFlag: boolean = false;
   public patientID;

   public diagCenterId;
   public relationId;
   public subRelationId;
   public testCategory;
   public reporttype;
   public recollect_reason;
   public recheck_reason;
   public patientHistoryList: any = [];
   dtOptions: DataTables.Settings = {};
   template_flag: boolean;
   template: any;
   report_type: any;
   pathology_flag: boolean = true;
   radiology_flag: boolean = true;
   microbiology_flag: boolean = true;
   colonyCountArray: any = [];
   value_flag: boolean;
   public oraganismIso1: any;
   public oraganismIso2: any;
   public oraganismIso3: any;
   culture_report_type_desc: any;
   gram_stain_desc: any;
   culture_report_desc: any;
   ot_sterile: any = [];
   ot_listFlag: boolean;

   public address1;
   public doctorname;
   public address2; public clntlocation; public clntstate; public clntdistrict; public clntcountry; public clntzipcode;
   // module flags
   public newbutton: boolean = false
   public editbutton: boolean = false;
   public deletebutton: boolean = false;
   public printbutton: boolean = false;
   public viewbutton: boolean = false;
   public recollectflag: boolean = true;
   public moduleList: any = [];
   test_category: any;
   patient_type: any;
   mobile: any;

   public org_iso_1_code: any;
   public org_iso_2_code: any;
   public org_iso_3_code: any;

   public org_iso_1_desc: any;
   public org_iso_2_desc: any;
   public org_iso_3_desc: any;

   public org_iso_1_cc_desc: any;
   public org_iso_2_cc_desc: any;
   public org_iso_3_cc_desc: any;

   public org_iso1_cr_desc: any;
   public org_iso2_cr_desc: any;
   public org_iso3_cr_desc: any;

   public org_iso1_growth_type_desc: any;
   public org_iso2_growth_type_desc: any;
   public org_iso3_growth_type_desc: any;

   public org_iso1_antibiotics: any = [];
   public org_iso2_antibiotics: any = [];
   public org_iso3_antibiotics: any = [];
   public org_iso1_flag: boolean = false;
   public org_iso2_flag: boolean = false;
   public org_iso3_flag: boolean = false;
   public technical_note: any;
   public comment: any;
   public reading_flag: boolean = false;

   public mic_iso1_flag: any = false;
   public mic_iso2_flag: any = false;
   public mic_iso3_flag: any = false;

   public org_iso1_desc_flag: boolean = false;
   public org_iso1_cc_flag: boolean = false;
   public org_iso1_cr_flag: boolean = false;
   public org_iso1_growth_type_flag: boolean = false;

   public org_iso2_desc_flag: boolean = false;
   public org_iso2_cc_flag: boolean = false;
   public org_iso2_cr_flag: boolean = false;
   public org_iso2_growth_type_flag: boolean = false;

   public org_iso3_desc_flag: boolean = false;
   public org_iso3_cc_flag: boolean = false;
   public org_iso3_cr_flag: boolean = false;
   public org_iso3_growth_type_flag: boolean = false;

   public technical_noteFlag: boolean = false;
   public commentsFlag: boolean = false;
   approver_name: string;
   qualityHead_name: string;
   consultant: string;

   public pres_tests: any = [];
   public test_flag: any;
   public approve_data_arr: any = [];
   public showtestname_flag: boolean;

   public egfr_flag: boolean;
   public age: any;
   public serum: number;
   public weight: string;
   public serumValue: number;
   public gender: string;
   public total: number;
   public eGFR: number;
   sample_id: any;
   sample_type: any;
   public org_iso_heading: string;
   public selected_approver: string;
   public approverList: any = [];
   visited_date_time: any;

   public totchole: any;
   public hdl: any;
   public trigs: any;
   public ldl: number;
   public vldl: number;
   public cho_hdl_ratio: number;
   public ldl_hdl_ratio: number;
   public hbA1c: any;
   public mean_bloodGlucose: number;
   public egfr_senddata: { life_style_id: any; serum_creatinine: any; egfr: any; country: any; };
   public send_lipidProf_data: { life_style_id: any; total_cholesterol: any; hdl: any; ldl: any; vldl: any; trigs: any; country: any; };
   public lipidData_flag: boolean = false;

   public bilitubin_total: any;
   public bilirubin_direct: any;
   public bilirubin_indirect: any;
   public total_Protein: any;
   public albumin: any;
   public globulin: any;
   public ag_ratio: any;
   life_style_id: any;
   egfr_value: any;
   tot_ProteinFlag: boolean = false;

   public patient_historyList: any = [];
   public dateList: any = [];
   public readinglist: any = [];
   public patHistCanvas: any;

   public urine_creatinine: any;
   public urine_micro_albumin: any;
   public creatine_albumin_ratio: any;
   patGender: any;


   constructor(public messageservice: MenuViewService, public gservice: CommonDataService, public toastr: ToastrService, public http: Http, public router: Router) {
   }
   ngOnInit(): void {
      this.report_type = Diagnosis_Helper.getReportType();
      if (this.report_type != "radiology") {
         this.recollectflag = false;
      } else {
         this.recollectflag = true;
      }
      this.moduleList = Helper_Class.getmodulelist();

      if (this.moduleList != undefined) {
         for (var i = 0; i < this.moduleList.length; i++) {
            if (this.moduleList[i].module_id == "106" && this.report_type == "pathology" || this.moduleList[i].module_id == "110" && this.report_type == "radiology" || this.moduleList[i].module_id == "114" && this.report_type == "microbiology") {
               console.log(JSON.stringify(this.moduleList[i]));
               if (this.moduleList[i].edit == "1") {
                  this.editbutton = true;//update button
               }
               if (this.moduleList[i].create == "1") {
                  this.newbutton = true;
               }
               if (this.moduleList[i].delete == "1") {
                  this.deletebutton = true;
               }
               if (this.moduleList[i].print == "1") {
                  this.printbutton = true;
               }
               if (this.moduleList[i].view == "1") {
                  this.viewbutton = true;
               }
            }
         }
      }

      this.dtOptions = {
         pagingType: 'simple_numbers_no_ellipses',
         "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
         "order": [[1, 'asc']],
         "columnDefs": [
            { "orderable": false, "targets": [0, 2] },
         ],
         "language": {
            "search": "<img src='../../../assets/img/search.png' width='18px'/>",
            searchPlaceholder: "Search by name or test type or test name"
         },
         dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
      };

      //navParams.get("type");  
      if (Diagnosis_Helper.getReportApproval().type != undefined) {
         this.reportType = (Diagnosis_Helper.getReportApproval().type).charAt(0).toUpperCase() + (Diagnosis_Helper.getReportApproval().type).slice(1);
      }

      this.diagAppID = Diagnosis_Helper.getReportApproval().diag_app_id;  //navParams.get("diag_app_id");
      this.presTestID = Diagnosis_Helper.getReportApproval().pres_test_id; //navParams.get("pres_test_id");
      this.diagID = Diagnosis_Helper.getReportApproval().pres_diag_id; // navParams.get("pres_diag_id");
      this.reportFlag = Diagnosis_Helper.getReportApproval().Diag_report_flag; //navParams.get("Diag_report_flag");
      this.testName = Diagnosis_Helper.getReportApproval().test_name; //navParams.get("test_name");

      this.diagCenterId = Diagnosis_Helper.getReportApproval().diag_centre_id;
      this.relationId = Diagnosis_Helper.getReportApproval().relation_id;
      this.subRelationId = Diagnosis_Helper.getReportApproval().sub_rel_id;
      this.testCategory = Diagnosis_Helper.getReportApproval().test_cat_id;
      this.pres_tests = Diagnosis_Helper.getReportApproval().pres_tests;
      this.test_flag = Diagnosis_Helper.getReportApproval().test_flag;
      this.visited_date_time = Diagnosis_Helper.getReportApproval().visited_date_time;

      console.log(this.report_type)

      if (this.report_type == "pathology") {
         this.pathology_flag = false;
         this.consultant = "Consultant pathologist";
         // this.radiology_flag = true;
      } else if (this.report_type == "radiology") {
         this.radiology_flag = false;
         this.consultant = "Consultant radiologist";
         // this.pathology_flag = true;
      } else {
         this.microbiology_flag = false;
         this.consultant = "Consultant microbiologist";
      }

      this.loader = true;
      if (this.reportFlag == "Approval") {
         this.approvelFlag = false;
         this.uploadFlag = true;
      } else {
         this.approvelFlag = true;
         this.uploadFlag = false;
      }
      if (this.report_type == "pathology") {
         this.tableViewTestFlag = false;
         this.paraViewReportTypeFlag = true;
      } else {// Scan == X-ray == Ultra sound
         this.tableViewTestFlag = true;
         this.paraViewReportTypeFlag = false;
         this.dynamicHeading = this.reportType == "scans" ? "Sequences" : "Observation";
         this.updateButtonFlag = this.reportType == "Approval" ? false : true;
         this.cultureFlag = this.reportType == "Culture" ? true : false;
      }
      this.clientGender = "0";
      this.clientAge = "0";

      if (this.test_flag == "group") {
         this.getRetrivel2();
         this.approveData_create();
         this.showtestname_flag = false;
      } else {
         this.getRetrivel();
         this.showtestname_flag = true;
      }

      this.getApproverList()

      if (Helper_Class.getInfo().user_type == 'Admin') {
         this.editbutton = true;
         this.newbutton = true;
         this.deletebutton = true;
         this.printbutton = true;
         this.viewbutton = true;
      }
   }

   getRetrivel() { //Get report details
      this.loader = false;
      this.tempTestList = [];
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'record/drview/',
         JSON.stringify({
            flag: "diagnosis",
            country: ipaddress.country_code,
            diag_app_id: this.diagAppID,
            pres_test_id: this.presTestID,
            type: this.report_type,
            test_type: this.reportType,
         }),
         { headers: headers })
         .subscribe(
            response => {
               var obj = JSON.parse(response["_body"]);
               console.log('getRetrivel---obj', obj)
               if (obj != null) {
                  this.detailedViewFlag = true;
                  this.loader = true;
                  this.diagName = obj.diag_centre_name;
                  this.diagAdd = obj.address2 != undefined && obj.address2 != "" ? obj.address1 + " " + obj.address2 : obj.address1;
                  if (obj.date_entered != undefined)
                     this.date = Date_Formate(obj.date_entered);
                  if (obj.time_entered != undefined)
                     this.time = Time_Formate(obj.time_entered);
                  this.diagLocation = obj.location;
                  this.diagCityZip = obj.city + "-" + obj.zipcode;
                  this.diagStateCon = obj.state + "-" + obj.country;
                  this.diagTelephone = obj.telephone;
                  this.clentID = obj.client_reg_id;
                  this.patientID = obj.patient_id;

                  // if (obj.pat_type != undefined) {
                  //    this.patient_type = this.capitalizeFirstLetter(obj.pat_type);
                  // }

                  if (obj.pat_type != undefined) {
                     this.patient_type = this.capitalizeFirstLetter(obj.pat_type);
                  }

                  if (obj.colony_count != undefined) {
                     this.colonyCountArray = obj.colony_count;
                  }

                  if (obj.life_style_id != undefined) {
                     this.life_style_id = obj.life_style_id;
                  }

                  if (obj.app_first_name != undefined && obj.app_last_name != undefined) {
                     this.approver_name = obj.app_first_name + ' ' + obj.app_last_name;
                  } else if (obj.app_first_name != undefined) {
                     this.approver_name = obj.app_first_name;
                  }

                  if (obj.qual_first_name != undefined && obj.qual_last_name != undefined) {
                     this.qualityHead_name = obj.qual_first_name + ' ' + obj.qual_last_name;
                  } else if (obj.qual_first_name != undefined) {
                     this.qualityHead_name = obj.qual_first_name;
                  }


                  if (obj.main_tests != undefined) {
                     if (obj.main_tests[0].org_iso1_cr != undefined && obj.main_tests[0].org_iso1_cr != "") {
                        this.org_iso1_cr_desc = obj.main_tests[0].org_iso1_cr;
                        this.org_iso1_cr_flag = true;
                     } else {
                        this.org_iso1_cr_flag = false;
                     }


                     if (obj.main_tests[0].organism_isolation_2_code != undefined && obj.main_tests[0].organism_isolation_3_code != undefined) {
                        this.org_iso_heading = "Organism Isolated 1";
                     } else {
                        this.org_iso_heading = "Organism Isolated";
                     }

                     if (obj.main_tests[0].organism_isolation_1_code != undefined && obj.main_tests[0].organism_isolation_1_desc != undefined) {
                        this.mic_iso1_flag = false;
                        this.org_iso1_flag = true;
                        this.org_iso_1_code = obj.main_tests[0].organism_isolation_1_code;
                        this.org_iso_1_desc = obj.main_tests[0].organism_isolation_1_desc;

                        if (obj.main_tests[0].org_iso1_colonycount_desc != undefined) {
                           this.org_iso_1_cc_desc = obj.main_tests[0].org_iso1_colonycount_desc;
                           this.org_iso1_cc_flag = true;
                        }

                        if (obj.main_tests[0].org_iso1_growth_type_desc != undefined) {
                           this.org_iso1_growth_type_desc = obj.main_tests[0].org_iso1_growth_type_desc;
                           this.org_iso1_growth_type_flag = true;
                        }

                        if (obj.org_iso1_antibiotics != undefined) {
                           for (var l = 0; l < obj.org_iso1_antibiotics.length; l++) {
                              if (obj.org_iso1_antibiotics[l].sensitivity_desc != "None") {
                                 this.org_iso1_antibiotics.push({
                                    antibiotic: obj.org_iso1_antibiotics[l].antibiotic,
                                    drug_name: obj.org_iso1_antibiotics[l].drug_name,
                                    sensitivity: obj.org_iso1_antibiotics[l].sensitivity,
                                    sensitivity_desc: obj.org_iso1_antibiotics[l].sensitivity_desc,
                                    mic: obj.org_iso1_antibiotics[l].mic
                                 })
                              }
                           }

                           for (var i = 0; i < this.org_iso1_antibiotics.length; i++) {
                              if (this.org_iso1_antibiotics[i].mic != undefined) {
                                 this.mic_iso1_flag = true;
                                 break;
                              }
                           }
                        }
                        // this.org_iso1_antibiotics = obj.org_iso1_antibiotics;
                     }

                     if (obj.main_tests[0].organism_isolation_2_code != undefined && obj.main_tests[0].organism_isolation_2_desc != undefined) {
                        this.org_iso2_flag = true;
                        this.mic_iso2_flag = false;
                        this.org_iso_2_code = obj.main_tests[0].organism_isolation_2_code;
                        this.org_iso_2_desc = obj.main_tests[0].organism_isolation_2_desc;
                        this.org_iso_2_cc_desc = obj.main_tests[0].org_iso2_colonycount_desc;
                        this.org_iso2_cr_desc = obj.main_tests[0].org_iso2_cr;
                        this.org_iso2_growth_type_desc = obj.main_tests[0].org_iso2_growth_type_desc;

                        if (obj.org_iso2_antibiotics != undefined) {
                           for (var l = 0; l < obj.org_iso2_antibiotics.length; l++) {
                              if (obj.org_iso2_antibiotics[l].sensitivity_desc != "None") {
                                 this.org_iso2_antibiotics.push({
                                    antibiotic: obj.org_iso2_antibiotics[l].antibiotic,
                                    drug_name: obj.org_iso2_antibiotics[l].drug_name,
                                    sensitivity: obj.org_iso2_antibiotics[l].sensitivity,
                                    sensitivity_desc: obj.org_iso2_antibiotics[l].sensitivity_desc,
                                    mic: obj.org_iso2_antibiotics[l].mic
                                 })
                              }
                           }
                           // this.org_iso2_antibiotics = obj.org_iso2_antibiotics;

                           for (var i = 0; i < this.org_iso2_antibiotics.length; i++) {
                              if (this.org_iso2_antibiotics[i].mic != undefined) {
                                 this.mic_iso2_flag = true;
                                 break;
                              }
                           }
                        }
                     }

                     if (obj.main_tests[0].organism_isolation_3_code != undefined && obj.main_tests[0].organism_isolation_3_desc != undefined) {
                        this.org_iso3_flag = true;
                        this.mic_iso3_flag = false;

                        this.org_iso_3_code = obj.main_tests[0].organism_isolation_3_code;
                        this.org_iso_3_desc = obj.main_tests[0].organism_isolation_3_desc;
                        this.org_iso_3_cc_desc = obj.main_tests[0].org_iso3_colonycount_desc;
                        this.org_iso3_cr_desc = obj.main_tests[0].org_iso3_cr;
                        this.org_iso3_growth_type_desc = obj.main_tests[0].org_iso3_growth_type_desc;

                        if (obj.org_iso3_antibiotics != undefined) {
                           for (var l = 0; l < obj.org_iso3_antibiotics.length; l++) {
                              if (obj.org_iso3_antibiotics[l].sensitivity_desc != "None") {
                                 this.org_iso3_antibiotics.push({
                                    antibiotic: obj.org_iso3_antibiotics[l].antibiotic,
                                    drug_name: obj.org_iso3_antibiotics[l].drug_name,
                                    sensitivity: obj.org_iso3_antibiotics[l].sensitivity,
                                    sensitivity_desc: obj.org_iso3_antibiotics[l].sensitivity_desc,
                                    mic: obj.org_iso3_antibiotics[l].mic
                                 })
                              }
                           }

                           // this.org_iso3_antibiotics = obj.org_iso3_antibiotics;

                           for (var i = 0; i < this.org_iso3_antibiotics.length; i++) {
                              if (this.org_iso3_antibiotics[i].mic != undefined) {
                                 this.mic_iso3_flag = true;
                                 break;
                              }
                           }
                        }
                     }

                     if (obj.main_tests[0].technical_note != undefined) {
                        this.technical_note = obj.main_tests[0].technical_note;
                        this.technical_noteFlag = true;
                     }
                     if (obj.main_tests[0].comment != undefined) {
                        this.comment = obj.main_tests[0].comment;
                        this.commentsFlag = true;
                     }

                     // OLD variables
                     this.oraganismIso1 = obj.main_tests[0].org_iso_1_desc;
                     this.oraganismIso2 = obj.main_tests[0].org_iso_2_desc;
                     this.oraganismIso3 = obj.main_tests[0].org_iso_3_desc;
                     if (obj.main_tests[0].culture_report_type_desc != undefined) {
                        this.culture_report_type_desc = obj.main_tests[0].culture_report_type_desc;
                     }
                     if (obj.main_tests[0].gram_stain_desc != undefined) {
                        this.gram_stain_desc = obj.main_tests[0].gram_stain_desc;
                     }
                     if (obj.main_tests[0].culture_report_desc != undefined) {
                        this.culture_report_desc = obj.main_tests[0].culture_report_desc;
                     }

                     if (obj.ot_sterile != undefined) {
                        this.ot_sterile = obj.ot_sterile;
                        console.log(JSON.stringify(this.ot_sterile))
                        this.ot_listFlag = true;
                     } else {
                        this.ot_listFlag = false;
                     }
                  }

                  if (obj.main_tests[0].template != undefined) {
                     this.template_flag = true;
                     this.template = obj.main_tests[0].template;
                     console.log("this.template_flag", this.template_flag, this.template)
                  } else {
                     this.template_flag = false;
                     this.template = "";
                     if (this.microbiology_flag == true) {
                        this.value_flag = true;
                     } else {
                        this.value_flag = false;
                     }
                  }

                  if (obj.main_tests[0].test_category != undefined) {
                     this.test_category = obj.main_tests[0].test_category;
                  }

                  if (obj.first_name != null) {
                     if (obj.middle_name != null && encrypt_decript.Decript(obj.middle_name).toString() != "")
                        this.clientName = obj.salutation_desc + ". " + encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
                     else
                        this.clientName = obj.salutation_desc + ". " + encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
                  }
                  if (obj.doc_first_name != undefined) {
                     if (obj.doc_middle_name != undefined && obj.doc_middle_name != null)
                        this.diagReferBy = "Dr." + obj.doc_first_name + " " + obj.doc_middle_name + " " + obj.doc_last_name;
                     else
                        this.diagReferBy = "Dr." + obj.doc_first_name + " " + obj.doc_last_name;
                  } else {
                     this.diagReferBy = "-";
                  }

                  var doc_qualif;
                  if(obj.doc_qualif != undefined && obj.doc_qualif != undefined) {
                     doc_qualif = obj.doc_qualif;
                  } else {
                     doc_qualif = "";
                  }

                  if (obj.dr_first_name != null) {
                     if (obj.dr_middle_name != null && obj.dr_middle_name != "")
                        
                        this.doctorname = "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name + " " + doc_qualif;
                     else
                       
                        this.doctorname = "Dr." + obj.dr_first_name + " " + obj.dr_last_name + " " + doc_qualif;
                  }



                  if (obj.sample_id != undefined) {
                     this.sample_id = obj.sample_id;
                  }

                  if (obj.sample_type != undefined) {
                     this.sample_type = obj.sample_type;
                  }

                  if (obj.client_address2 != undefined) {
                     this.address1 = encrypt_decript.Decript(obj.client_address1) + " " + encrypt_decript.Decript(obj.client_address2);
                  }

                  if (obj.mobile != undefined) {
                     this.mobile = encrypt_decript.Decript(obj.mobile);
                  }
                  //   if (obj.client_address2 != undefined){
                  //       this.address1 = "";
                  //   }
                  if (obj.client_location != undefined) {
                     this.clntlocation = (obj.client_location);
                  }
                  if (obj.client_state
                     != undefined) {
                     this.clntstate = (obj.client_state
                     );
                  }
                  if (obj.client_country != undefined) {
                     this.clntcountry = (obj.client_country
                     );
                  }
                  if (obj.client_district != undefined) {
                     this.clntdistrict = (obj.client_district);
                  }
                  if (obj.client_zipcode != null)
                     this.clntzipcode = encrypt_decript.Decript(obj.client_zipcode);
                  if (obj.gender != null)
                     this.clientGender = encrypt_decript.Decript(obj.gender);
                  if (obj.gender != null && obj.gender != undefined) {
                     var gender;
                     gender = encrypt_decript.Decript(obj.gender);

                     if(gender == "Male") {
                        this.patGender = "M"
                      } else if (gender == "Female") {
                        this.patGender = "F"
                      } else {
                        this.patGender = gender
                      }
                  }    
                  if (obj.age != null)
                     this.clientAge = obj.age == 0 ? "<1" : obj.age;
                  else
                     this.clientAge = "";
                  if (obj.sign != undefined && obj.sign != "") {
                     this.reviewSign = "data:image/png;base64," + obj.sign;
                     this.signFlag = false;
                  } else {
                     this.signFlag = true;
                  }
                  if (obj.sign == undefined && obj.sign == "" || obj.app_middle_name == null)
                     this.revieSigSection = true;
                  this.reviewFlag = obj.app_first_name != null ? false : true;
                  this.reviewBy = obj.app_middle_name != null ? obj.app_first_name + " " + obj.app_middle_name + " " + obj.app_last_name : obj.app_first_name + " " + obj.app_last_name;
                  if (obj.main_tests[0].sample_date != undefined)
                     this.sampleCollectedTime = Date_Formate(obj.main_tests[0].sample_date) + " " + Time_Formate(obj.main_tests[0].sample_time);
                  if (obj.main_tests != null && obj.main_tests.length != 0) {
                     if (obj.main_tests[0].image != undefined && obj.main_tests[0].image != "") {
                        this.reportDetail = ipaddress.Ip_with_img_address + obj.main_tests[0].image;
                        this.report_img = ipaddress.Ip_with_img_address + obj.main_tests[0].image;
                        this.doctorFlag = false;
                     } else {
                        this.doctorFlag = true;
                     }
                     for (var i = 0; i < obj.main_tests.length; i++) {
                        this.presTestID = obj.main_tests[i].pres_test_id;
                        this.sequences = obj.main_tests[i].sequences;
                        this.impression = obj.main_tests[i].impression;
                        if (obj.main_tests[i].created_by != undefined) {
                           this.createdBy = obj.main_tests[i].created_by;
                        }
                        if (obj.main_tests[i].test_method != undefined) {
                           this.testMethod = obj.main_tests[i].test_method;
                        }
                        this.subTest = obj.main_tests[i].test_name;

                        if (obj.main_tests[i].subtests != null) {
                           for (var j = 0; j < obj.main_tests[i].subtests.length; j++) {
                              var temp_value = "";
                              if (obj.main_tests[i].subtests[j].reference != undefined) {
                                 this.subtestFlag = obj.main_tests[i].subtests[j].sub_test_name == this.subTest ? false : true;
                                 if (obj.main_tests[i].subtests[j].reference[0].range != undefined)
                                    temp_value = obj.main_tests[i].subtests[j].reference[0].range;
                              } else {
                                 temp_value = " - ";
                              }

                              var test_method = "";
                              if (obj.main_tests[i].subtests[j].test_method != undefined) {
                                 test_method = obj.main_tests[i].subtests[j].test_method;
                                 this.subTestMedFlag = true;
                              } else {
                                 this.subTestMedFlag = false;
                              }

                              // var isReadingNormal = false;
                              // var isReadingAbnormal = false;
                              // var isReadingCritical = false;
                              // var inputBgColor;
                              // if (obj.main_tests[i].subtests[j].reading != undefined && obj.main_tests[i].subtests[j].reference != undefined && obj.main_tests[i].subtests[j].reference.length != 0) {
                              //    if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_nor_value)) {
                              //       isReadingNormal = true;
                              //       inputBgColor = 'white';
                              //    } else if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_abnor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_abnor_value)) {
                              //       isReadingAbnormal = true;
                              //       inputBgColor = '#ffc55d';
                              //    } else {
                              //       isReadingCritical = true;
                              //       inputBgColor = '#ff5454';
                              //    }
                              // }

                              var isReadingNormal = false;
                              var isReadingAbnormal = false;
                              var isReadingCritical = false;
                              var testReading;
                              if (obj.main_tests[i].subtests[j].reading != undefined && obj.main_tests[i].subtests[j].reference != undefined && obj.main_tests[i].subtests[j].reference.length != 0 && Object.keys(obj.main_tests[i].subtests[j].reference[0]).length != 0) {
                                 var min_nor_value = obj.main_tests[i].subtests[j].reference[0].min_nor_value;
                                 var max_nor_value = obj.main_tests[i].subtests[j].reference[0].max_nor_value;
                                 var min_abnor_value = obj.main_tests[i].subtests[j].reference[0].min_abnor_value;
                                 var max_abnor_value = obj.main_tests[i].subtests[j].reference[0].max_abnor_value;
                                 var min_crit_value = obj.main_tests[i].subtests[j].reference[0].min_crit_value;
                                 var max_crit_value = obj.main_tests[i].subtests[j].reference[0].max_crit_value;

                                 if (((min_nor_value != undefined || min_nor_value != "") && (max_nor_value != undefined || max_nor_value != "")) && ((min_abnor_value == undefined || min_abnor_value == "") && (max_abnor_value == undefined || max_abnor_value == "")) && ((min_crit_value == undefined || min_crit_value == "") && (max_crit_value == undefined || max_crit_value == ""))) {
                                    if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_nor_value)) {
                                       isReadingNormal = true;
                                       testReading = obj.main_tests[i].subtests[j].reading;
                                    } else {
                                       isReadingAbnormal = true;
                                       testReading = obj.main_tests[i].subtests[j].reading + '*';
                                    }
                                 } else {

                                    if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_nor_value)) {
                                       isReadingNormal = true;
                                       testReading = obj.main_tests[i].subtests[j].reading;
                                    } else if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_abnor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_abnor_value)) {
                                       isReadingAbnormal = true;
                                       testReading = obj.main_tests[i].subtests[j].reading + '*';
                                    } else {
                                       isReadingCritical = true;
                                       testReading = obj.main_tests[i].subtests[j].reading + '**';
                                    }
                                 }
                              } else {
                                 testReading = obj.main_tests[i].subtests[j].reading
                              }

                              var printReading;
                              if (obj.main_tests[i].subtests[j].reading != undefined) {
                                 if (obj.main_tests[i].subtests[j].reference != undefined && obj.main_tests[i].subtests[j].reference.length != 0 && Object.keys(obj.main_tests[i].subtests[j].reference[0]).length != 0) {
                                    if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_nor_value)) {
                                       printReading = obj.main_tests[i].subtests[j].reading;
                                    } else if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_abnor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_abnor_value)) {
                                       printReading = obj.main_tests[i].subtests[j].reading + '*';
                                    } else {
                                       printReading = obj.main_tests[i].subtests[j].reading + '**';
                                    }
                                 } else {
                                    printReading = obj.main_tests[i].subtests[j].reading;
                                 }
                              } else {
                                 printReading = "-"
                              }

                              var reading, egfr_value;
                              if (obj.main_tests[i].subtests[j].reading != undefined) {
                                 reading = obj.main_tests[i].subtests[j].reading;
                                 if (obj.main_tests[i].subtests[j].sub_test_name.toLowerCase() == "creatinine") {
                                    this.egfr_flag = true;
                                    egfr_value = this.serumCreatChange(reading);
                                    if (Number.isNaN(egfr_value) == true) {
                                       egfr_value = "-"
                                    }

                                    this.egfr_senddata = {
                                       life_style_id: obj.life_style_id,
                                       serum_creatinine: obj.main_tests[i].subtests[j].reading,
                                       egfr: egfr_value,
                                       country: obj.client_country
                                    }
                                    // alert(JSON.stringify(egfr_senddata))
                                 } else {
                                    this.egfr_flag = false;
                                 }
                              } else {
                                 reading = " - "
                              }

                              // Lipid profile data for casesheet test_name
                              if (obj.main_tests[i].test_name.split(" ").join("").toLowerCase().includes("lipidprofile")) {
                                 this.lipidData_flag = true;
                                 if (obj.main_tests[i].subtests[j].sub_test_name != undefined) {
                                    // var totcholestrol, hdlCholesterol, ldlCholesterol, triglycerides, vldl, choHdl_ratio, ldlHdl_ratio
                                    var subtest_name = obj.main_tests[i].subtests[j].sub_test_name.split(" ").join("").toLowerCase();

                                    if ((subtest_name.includes("totalcholesterol"))) {
                                       this.totchole = obj.main_tests[i].subtests[j].reading;

                                    } else if ((subtest_name.includes("hdl-cholesterol"))) {
                                       this.hdl = obj.main_tests[i].subtests[j].reading;

                                    } else if ((subtest_name.includes("ldl-cholesterol"))) {
                                       this.ldl = obj.main_tests[i].subtests[j].reading;

                                    } else if ((subtest_name.includes("vldl"))) {
                                       this.vldl = obj.main_tests[i].subtests[j].reading;

                                    } else if ((subtest_name.includes("cho/hdlratio"))) {
                                       this.cho_hdl_ratio = obj.main_tests[i].subtests[j].reading;

                                    } else if ((subtest_name.includes("ldl/hdlratio"))) {
                                       this.ldl_hdl_ratio = obj.main_tests[i].subtests[j].reading;

                                    } else if ((subtest_name.includes("triglycerides"))) {
                                       this.trigs = obj.main_tests[i].subtests[j].reading;

                                    }
                                 }
                              }
                              // Lipid profile data  -- end --

                              // bilirubin and globulin calculations 
                              var subtest_name = obj.main_tests[i].subtests[j].sub_test_name.split(" ").join("").toLowerCase();
                              if (obj.main_tests[i].subtests[j].reading != undefined && obj.main_tests[i].subtests[j].reading != "") {
                                 var test_reading = obj.main_tests[i].subtests[j].reading;
                              }

                              if ((subtest_name.includes("hba1c"))) {
                                 this.hbA1c = test_reading;
                              }
                              else if ((subtest_name.includes("urinecreatinine")) ||
                                 (subtest_name.includes("urinemicroalbumin")) && subtest_name == "urinemicroalbumin") {
                                 if (subtest_name.includes("urinecreatinine")) {
                                    this.urine_creatinine = test_reading;
                                 } else if (subtest_name.includes("urinemicroalbumin")) {
                                    this.urine_micro_albumin = test_reading;
                                 }
                              } else if ((subtest_name.includes("totalprotein")) || (subtest_name.includes("albumin"))) {
                                 this.tot_ProteinFlag = true
                                 if (subtest_name.includes("totalprotein")) {
                                    this.total_Protein = test_reading;
                                 } else if (subtest_name.includes("albumin")) {
                                    this.albumin = test_reading;
                                 }
                              }


                              var subtests = undefined;
                              if (obj.main_tests[i].subtests[j].tests != undefined) {
                                 subtests = [];
                                 for (var l = 0; l < obj.main_tests[i].subtests[j].tests.length; l++) {
                                    var isReadingNormal_sub = false;
                                    var isReadingAbnormal_sub = false;
                                    var isReadingCritical_sub = false;
                                    var reference = [];
                                    var sub_reading = obj.main_tests[i].subtests[j].tests[l].reading;
                                    if (obj.main_tests[i].subtests[j].tests[l].reading != undefined && obj.main_tests[i].subtests[j].tests[l].reference != undefined && obj.main_tests[i].subtests[j].tests[l].reference.length != 0 && Object.keys(obj.main_tests[i].subtests[j].tests[l].reference[0]).length != 0) {
                                       var min_nor_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].min_nor_value;
                                       var max_nor_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].max_nor_value;
                                       var min_abnor_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].min_abnor_value;
                                       var max_abnor_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].max_abnor_value;
                                       var min_crit_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].min_crit_value;
                                       var max_crit_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].max_crit_value;
                                       reference = obj.main_tests[i].subtests[j].tests[l].reference;


                                       if (((min_nor_value_sub != undefined || min_nor_value_sub != "") && (max_nor_value_sub != undefined || max_nor_value_sub != "")) && ((min_abnor_value_sub == undefined || min_abnor_value_sub == "") && (max_abnor_value_sub == undefined || max_abnor_value_sub == "")) && ((min_crit_value_sub == undefined || min_crit_value_sub == "") && (max_crit_value_sub == undefined || max_crit_value_sub == ""))) {
                                          // alert(obj.main_tests[i].subtests[j].reference.length + "  " +  obj.main_tests[i].subtests[j].sub_test_name)
                                          if (parseInt(obj.main_tests[i].subtests[j].tests[l].reading) >= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].tests[l].reading) <= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].max_nor_value)) {
                                             isReadingNormal_sub = true;
                                             sub_reading = obj.main_tests[i].subtests[j].tests[l].reading;
                                          } else {
                                             isReadingAbnormal_sub = true;
                                             sub_reading = obj.main_tests[i].subtests[j].tests[l].reading + "*";
                                          }
                                       } else {
                                          if (parseInt(obj.main_tests[i].subtests[j].tests[l].reading) >= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].tests[l].reading) <= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].max_nor_value)) {
                                             isReadingNormal_sub = true;
                                             sub_reading = obj.main_tests[i].subtests[j].tests[l].reading;
                                          } else if (parseInt(obj.main_tests[i].subtests[j].tests[l].reading) >= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].min_abnor_value) && parseInt(obj.main_tests[i].subtests[j].tests[l].reading) <= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].max_abnor_value)) {
                                             isReadingAbnormal_sub = true;
                                             sub_reading = obj.main_tests[i].subtests[j].tests[l].reading + "*";
                                          } else {
                                             isReadingCritical_sub = true;
                                             sub_reading = obj.main_tests[i].subtests[j].tests[l].reading + "**";
                                          }
                                       }
                                    }

                                    // var reference;
                                    // if (obj.main_tests[i].subtests[j].tests[l].reference != undefined) {
                                    //    reference =  obj.main_tests[i].subtests[j].tests[l].reference;
                                    // }

                                    var ref_range = '-';
                                    if (obj.main_tests[i].subtests[j].tests[l].range != undefined) {
                                       ref_range = obj.main_tests[i].subtests[j].tests[l].range;
                                    }

                                    var test_order = '';
                                    if (obj.main_tests[i].subtests[j].tests[l].test_order != undefined) {
                                       test_order = obj.main_tests[i].subtests[j].tests[l].test_order;
                                    }

                                    var test_method = '';
                                    if (obj.main_tests[i].subtests[j].tests[l].test_method != undefined) {
                                       test_method = obj.main_tests[i].subtests[j].tests[l].test_method;
                                    }

                                    subtests.push({
                                       sub_test_list_id: obj.main_tests[i].subtests[j].tests[l].sub_test_list_id,
                                       sub_test_name: obj.main_tests[i].subtests[j].tests[l].sub_test_name,
                                       reading: sub_reading,
                                       unit: obj.main_tests[i].subtests[j].tests[l].unit,
                                       sub_uom: obj.main_tests[i].subtests[j].tests[l].sub_uom,
                                       test_method: test_method,
                                       diag_tests_list_id: obj.main_tests[i].subtests[j].tests[l].diag_tests_list_id,
                                       test_order: test_order,
                                       range: ref_range,
                                       reference: reference,
                                       is_Reading_normal: isReadingNormal_sub,
                                       is_Reading_Abnormal: isReadingAbnormal_sub,
                                       is_Reading_Critical: isReadingCritical_sub,
                                    })

                                    // bilirubin and globulin calculations 
                                    var subtest_name = obj.main_tests[i].subtests[j].tests[l].sub_test_name.split(" ").join("").toLowerCase();
                                    if (obj.main_tests[i].subtests[j].tests[l].reading != undefined && obj.main_tests[i].subtests[j].tests[l].reading != "") {
                                       var test_reading = obj.main_tests[i].subtests[j].tests[l].reading;
                                    }

                                    if ((subtest_name.includes("hba1c"))) {
                                       this.hbA1c = test_reading;
                                    } else if ((subtest_name.includes("bilirubin-total")) || (subtest_name.includes("bilirubin-direct")) || (subtest_name.includes("bilirubin-indirect"))) {
                                       if (subtest_name.includes("bilirubin-total")) {
                                          this.bilitubin_total = test_reading;
                                       } else if (subtest_name.includes("bilirubin-direct")) {
                                          this.bilirubin_direct = test_reading;
                                       }
                                    } else if ((subtest_name.includes("totalprotein")) || (subtest_name.includes("albumin"))) {
                                       this.tot_ProteinFlag = true
                                       if (subtest_name.includes("totalprotein")) {
                                          this.total_Protein = test_reading;
                                       } else if (subtest_name.includes("albumin")) {
                                          this.albumin = test_reading;
                                       }
                                    }
                                    // end

                                 }
                              }

                              this.tempTestList.push({
                                 s_no: j + 1,
                                 sub_test_name: obj.main_tests[i].subtests[j].sub_test_name,
                                 subtest_id: obj.main_tests[i].subtests[j].sub_test_list_id,
                                 reading: testReading,
                                 unit: obj.main_tests[i].subtests[j].sub_uom,
                                 test_range: temp_value,
                                 test_method: test_method,
                                 is_Reading_normal: isReadingNormal,
                                 is_Reading_Abnormal: isReadingAbnormal,
                                 is_Reading_Critical: isReadingCritical,
                                 tests: subtests,
                                 egfr_value: egfr_value,
                                 egfr_flag: this.egfr_flag,
                                 isSelected: false,
                                 test_type: obj.main_tests[i].test_type
                                 // inputBgColor: inputBgColor
                              });
                           }
                        }
                        this.DiagTestReadingArray.push({
                           test_name: obj.main_tests[i].test_name,
                           sub_test_list: this.tempTestList
                        });
                     }

                     console.log("this.send_lipidProf_data", JSON.stringify(this.send_lipidProf_data))
                     // console.log("this.tempTestList", JSON.stringify(this.tempTestList))

                     for (var i = 0; i < obj.main_tests.length; i++) {
                        if (obj.main_tests[i].image_path != undefined && obj.main_tests[i].image_path != "") {
                           this.doctorFlag = false;
                           this.reportDetail = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image_path;
                           this.report_img = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image_path;
                           // alert(this.report_img)
                        } else {
                           this.doctorFlag = true;
                        }
                        if (obj.main_tests[i].sequences != undefined && obj.main_tests[i].impression != undefined) {
                           this.testArray.push({
                              pres_test_id: obj.main_tests[i].pres_test_id,
                              test_name: obj.main_tests[i].test_name,
                              sequences: obj.main_tests[i].sequences,
                              impression: obj.main_tests[i].impression,
                              culture: obj.main_tests[i].culture,
                           });
                           for (var i = 0; i < this.testArray.length; i++) {
                              this.sequence = this.testArray[i].sequences;
                              this.Impression = this.testArray[i].impression;
                              this.culture = this.testArray[i].culture;
                           }
                        } else if (obj.main_tests[i].culture != undefined) {
                           this.testArray.push({
                              pres_test_id: obj.main_tests[i].pres_test_id,
                              test_name: obj.main_tests[i].test_name,
                              culture: obj.main_tests[i].culture,
                           });
                           for (var i = 0; i < this.testArray.length; i++) {
                              this.culture = this.testArray[i].culture;
                              this.testName = this.testArray[i].test_name;
                           }
                        }

                     }


                  }
                  this.patientHistory();
               }
            },
            error => {
               this.toastr.error(Message_data.getNetworkMessage());
               this.loader = true;
            });
   }

   getRetrivel2() { //Get report details
      this.loader = false;
      this.tempTestList = [];
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'record/drvg',
         JSON.stringify({
            flag: "diagnosis",
            country: ipaddress.country_code,
            diag_app_id: this.diagAppID,
            type: this.report_type,
            pres_tests: this.pres_tests,
         }),
         { headers: headers })
         .subscribe(
            response => {
               var obj = JSON.parse(response["_body"]);
               console.log('getRetrivel---obj', obj)
               console.log("this.pres_tests" + JSON.stringify(this.pres_tests))
               if (obj != null) {
                  this.detailedViewFlag = true;
                  this.loader = true;
                  this.diagName = obj.diag_centre_name;
                  this.diagAdd = obj.address2 != undefined && obj.address2 != "" ? obj.address1 + " " + obj.address2 : obj.address1;
                  if (obj.date_entered != undefined)
                     this.date = Date_Formate(obj.date_entered);
                  if (obj.time_entered != undefined)
                     this.time = Time_Formate(obj.time_entered);
                  this.diagLocation = obj.location;
                  this.diagCityZip = obj.city + "-" + obj.zipcode;
                  this.diagStateCon = obj.state + "-" + obj.country;
                  this.diagTelephone = obj.telephone;
                  this.clentID = obj.client_reg_id;
                  this.patientID = obj.patient_id;

                  if (obj.pat_type != undefined) {
                     this.patient_type = this.capitalizeFirstLetter(obj.pat_type);
                  }

                  if (obj.colony_count != undefined) {
                     this.colonyCountArray = obj.colony_count
                  }

                  if (obj.app_first_name != undefined && obj.app_last_name != undefined) {
                     this.approver_name = obj.app_first_name + ' ' + obj.app_last_name;
                  } else if (obj.app_first_name != undefined) {
                     this.approver_name = obj.app_first_name;
                  }



                  if (obj.sample_id != undefined) {
                     this.sample_id = obj.sample_id;
                  }

                  if (obj.sample_type != undefined) {
                     this.sample_type = obj.sample_type;
                  }

                  if (obj.life_style_id != undefined) {
                     this.life_style_id = obj.life_style_id;
                  }

                  if (obj.qual_first_name != undefined && obj.qual_last_name != undefined) {
                     this.qualityHead_name = obj.qual_first_name + ' ' + obj.qual_last_name;
                  } else if (obj.qual_first_name != undefined) {
                     this.qualityHead_name = obj.qual_first_name;
                  }


                  if (obj.main_tests != undefined) {
                     if (obj.main_tests[0].org_iso1_cr != undefined && obj.main_tests[0].org_iso1_cr != "") {
                        this.org_iso1_cr_desc = obj.main_tests[0].org_iso1_cr;
                        this.org_iso1_cr_flag = true;
                     } else {
                        this.org_iso1_cr_flag = false;
                     }


                     if (obj.main_tests[0].organism_isolation_2_code != undefined && obj.main_tests[0].organism_isolation_3_code != undefined) {
                        this.org_iso_heading = "Organism Isolated";
                     } else {
                        this.org_iso_heading = "Organism Isolated 1";
                     }

                     if (obj.main_tests[0].organism_isolation_1_code != undefined && obj.main_tests[0].organism_isolation_1_desc != undefined) {
                        this.org_iso1_flag = true;
                        this.org_iso_1_code = obj.main_tests[0].organism_isolation_1_code;
                        this.org_iso_1_desc = obj.main_tests[0].organism_isolation_1_desc;

                        if (obj.main_tests[0].org_iso1_colonycount_desc != undefined) {
                           this.org_iso_1_cc_desc = obj.main_tests[0].org_iso1_colonycount_desc;
                           this.org_iso1_cc_flag = true;
                        }

                        if (obj.main_tests[0].org_iso1_growth_type_desc != undefined) {
                           this.org_iso1_growth_type_desc = obj.main_tests[0].org_iso1_growth_type_desc;
                           this.org_iso1_growth_type_flag = true;
                        }

                        this.org_iso1_antibiotics = obj.org_iso1_antibiotics;
                        this.mic_iso1_flag = this.org_iso1_antibiotics.every(element => element.mic != undefined);
                     }

                     if (obj.main_tests[0].organism_isolation_2_code != undefined && obj.main_tests[0].organism_isolation_2_desc != undefined) {
                        this.org_iso2_flag = true;
                        this.org_iso_2_code = obj.main_tests[0].organism_isolation_2_code;
                        this.org_iso_2_desc = obj.main_tests[0].organism_isolation_2_desc;
                        this.org_iso_2_cc_desc = obj.main_tests[0].org_iso2_colonycount_desc;
                        this.org_iso2_cr_desc = obj.main_tests[0].org_iso2_cr;
                        this.org_iso2_growth_type_desc = obj.main_tests[0].org_iso2_growth_type_desc;
                        this.org_iso2_antibiotics = obj.org_iso2_antibiotics;

                        this.mic_iso2_flag = this.org_iso2_antibiotics.every(element => element.mic != undefined);
                     }

                     if (obj.main_tests[0].organism_isolation_3_code != undefined && obj.main_tests[0].organism_isolation_3_desc != undefined) {
                        this.org_iso3_flag = true;

                        this.org_iso_3_code = obj.main_tests[0].organism_isolation_3_code;
                        this.org_iso_3_desc = obj.main_tests[0].organism_isolation_3_desc;
                        this.org_iso_3_cc_desc = obj.main_tests[0].org_iso3_colonycount_desc;
                        this.org_iso3_cr_desc = obj.main_tests[0].org_iso3_cr;
                        this.org_iso3_growth_type_desc = obj.main_tests[0].org_iso3_growth_type_desc;
                        this.org_iso3_antibiotics = obj.org_iso3_antibiotics;
                        this.mic_iso3_flag = this.org_iso3_antibiotics.every(element => element.mic != undefined);
                     }

                     if (obj.main_tests[0].technical_note != undefined) {
                        this.technical_note = obj.main_tests[0].technical_note;
                        this.technical_noteFlag = true;
                     }
                     if (obj.main_tests[0].comment != undefined) {
                        this.comment = obj.main_tests[0].comment;
                        this.commentsFlag = true;
                     }

                     // OLD variables
                     this.oraganismIso1 = obj.main_tests[0].org_iso_1_desc;
                     this.oraganismIso2 = obj.main_tests[0].org_iso_2_desc;
                     this.oraganismIso3 = obj.main_tests[0].org_iso_3_desc;
                     if (obj.main_tests[0].culture_report_type_desc != undefined) {
                        this.culture_report_type_desc = obj.main_tests[0].culture_report_type_desc;
                     }
                     if (obj.main_tests[0].gram_stain_desc != undefined) {
                        this.gram_stain_desc = obj.main_tests[0].gram_stain_desc;
                     }
                     if (obj.main_tests[0].culture_report_desc != undefined) {
                        this.culture_report_desc = obj.main_tests[0].culture_report_desc;
                     }

                     if (obj.ot_sterile != undefined) {
                        this.ot_sterile = obj.ot_sterile;
                        console.log(JSON.stringify(this.ot_sterile))
                        this.ot_listFlag = true;
                     } else {
                        this.ot_listFlag = false;
                     }
                  }

                  if (obj.main_tests[0].template != undefined) {
                     this.template_flag = true;
                     this.template = obj.main_tests[0].template;
                     console.log("this.template_flag", this.template_flag, this.template)
                  } else {
                     this.template_flag = false;
                     this.template = "";
                     if (this.microbiology_flag == true) {
                        this.value_flag = true;
                     } else {
                        this.value_flag = false;
                     }
                  }

                  if (obj.main_tests[0].test_category != undefined) {
                     this.test_category = obj.main_tests[0].test_category;
                  }

                  if (obj.first_name != null) {
                     if (obj.middle_name != null && encrypt_decript.Decript(obj.middle_name).toString() != "")
                        this.clientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
                     else
                        this.clientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
                  }
                  if (obj.doc_first_name != undefined) {
                     if (obj.doc_middle_name != undefined && obj.doc_middle_name != null)
                        this.diagReferBy = "Dr." + obj.doc_first_name + " " + obj.doc_middle_name + " " + obj.doc_last_name;
                     else
                        this.diagReferBy = "Dr." + obj.doc_first_name + " " + obj.doc_last_name;
                  } else {
                     this.diagReferBy = "-";
                  }

                  var doc_qualif;
                  if(obj.doc_qualif != undefined && obj.doc_qualif != undefined) {
                     doc_qualif = obj.doc_qualif;
                  } else {
                     doc_qualif = "";
                  }

                  if (obj.dr_first_name != null) {
                     if (obj.dr_middle_name != null && obj.dr_middle_name != "")
                     
                        this.doctorname = "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name + " " + doc_qualif;
                     else
                        
                        this.doctorname = "Dr." + obj.dr_first_name + " " + obj.dr_last_name + " " + doc_qualif;
                  }

                  if (obj.client_address2 != undefined) {
                     this.address1 = encrypt_decript.Decript(obj.client_address1) + " " + encrypt_decript.Decript(obj.client_address2);
                  }

                  if (obj.mobile != undefined) {
                     this.mobile = encrypt_decript.Decript(obj.mobile);
                  }
                  //   if (obj.client_address2 != undefined){
                  //       this.address1 = "";
                  //   }
                  if (obj.client_location != undefined) {
                     this.clntlocation = (obj.client_location);
                  }
                  if (obj.client_state
                     != undefined) {
                     this.clntstate = (obj.client_state
                     );
                  }
                  if (obj.client_country != undefined) {
                     this.clntcountry = (obj.client_country
                     );
                  }
                  if (obj.client_district != undefined) {
                     this.clntdistrict = (obj.client_district);
                  }
                  if (obj.client_zipcode != null)
                     this.clntzipcode = encrypt_decript.Decript(obj.client_zipcode);
                 
                  if (obj.gender != null)
                     this.clientGender = encrypt_decript.Decript(obj.gender);

                  if (obj.gender != null && obj.gender != undefined) {
                     var gender;
                     gender = encrypt_decript.Decript(obj.gender);

                     if(gender == "Male") {
                        this.patGender = "M"
                      } else if (gender == "Female") {
                        this.patGender = "F"
                      } else {
                        
                        this.patGender = gender
                      }
                
                  }    
                     
                  if (obj.age != null)
                     this.clientAge = obj.age == 0 ? "<1" : obj.age;
                  else
                     this.clientAge = "";
                  if (obj.sign != undefined && obj.sign != "") {
                     this.reviewSign = "data:image/png;base64," + obj.sign;
                     this.signFlag = false;
                  } else {
                     this.signFlag = true;
                  }
                  if (obj.sign == undefined && obj.sign == "" || obj.app_middle_name == null)
                     this.revieSigSection = true;
                  this.reviewFlag = obj.app_first_name != null ? false : true;
                  this.reviewBy = obj.app_middle_name != null ? obj.app_first_name + " " + obj.app_middle_name + " " + obj.app_last_name : obj.app_first_name + " " + obj.app_last_name;
                  if (obj.main_tests[0].sample_date != undefined)
                     this.sampleCollectedTime = Date_Formate(obj.main_tests[0].sample_date) + " " + Time_Formate(obj.main_tests[0].sample_time);
                  if (obj.main_tests != null && obj.main_tests.length != 0) {
                     if (obj.main_tests[0].image != undefined && obj.main_tests[0].image != "") {
                        this.reportDetail = ipaddress.Ip_with_img_address + obj.main_tests[0].image;
                        this.report_img = ipaddress.Ip_with_img_address + obj.main_tests[0].image;
                        this.doctorFlag = false;
                     } else {
                        this.doctorFlag = true;
                     }
                     for (var i = 0; i < obj.main_tests.length; i++) {
                        this.presTestID = obj.main_tests[i].pres_test_id;
                        this.sequences = obj.main_tests[i].sequences;
                        this.impression = obj.main_tests[i].impression;
                        if (obj.main_tests[i].created_by != undefined) {
                           this.createdBy = obj.main_tests[i].created_by;
                        }
                        if (obj.main_tests[i].test_method != undefined) {
                           this.testMethod = obj.main_tests[i].test_method;
                        }
                        this.subTest = obj.main_tests[i].test_name;

                        if (obj.main_tests[i].subtests != null) {
                           this.tempTestList = [];
                           for (var j = 0; j < obj.main_tests[i].subtests.length; j++) {
                              var temp_value = "";
                              if (obj.main_tests[i].subtests[j].reference != undefined) {
                                 this.subtestFlag = obj.main_tests[i].subtests[j].sub_test_name == this.subTest ? false : true;
                                 if (obj.main_tests[i].subtests[j].reference[0].range != undefined)
                                    temp_value = obj.main_tests[i].subtests[j].reference[0].range;
                              } else {
                                 temp_value = " - ";
                              }

                              var test_method = "";
                              if (obj.main_tests[i].subtests[j].test_method != undefined) {
                                 test_method = obj.main_tests[i].subtests[j].test_method;
                                 this.subTestMedFlag = true;
                              } else {
                                 this.subTestMedFlag = false;
                              }

                              // var isReadingNormal = false;
                              // var isReadingAbnormal = false;
                              // var isReadingCritical = false;
                              // if (obj.main_tests[i].subtests[j].reading != undefined && obj.main_tests[i].subtests[j].reference != undefined && obj.main_tests[i].subtests[j].reference.length != 0) {
                              //    if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_nor_value)) {
                              //       isReadingNormal = true;
                              //    } else if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_abnor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_abnor_value)) {
                              //       isReadingAbnormal = true;
                              //    } else {
                              //       isReadingCritical = true;
                              //    }
                              // }

                              var isReadingNormal = false;
                              var isReadingAbnormal = false;
                              var isReadingCritical = false;
                              var testReading;
                              if (obj.main_tests[i].subtests[j].reading != undefined && obj.main_tests[i].subtests[j].reference != undefined && obj.main_tests[i].subtests[j].reference.length != 0 && Object.keys(obj.main_tests[i].subtests[j].reference[0]).length != 0) {
                                 var min_nor_value = obj.main_tests[i].subtests[j].reference[0].min_nor_value;
                                 var max_nor_value = obj.main_tests[i].subtests[j].reference[0].max_nor_value;
                                 var min_abnor_value = obj.main_tests[i].subtests[j].reference[0].min_abnor_value;
                                 var max_abnor_value = obj.main_tests[i].subtests[j].reference[0].max_abnor_value;
                                 var min_crit_value = obj.main_tests[i].subtests[j].reference[0].min_crit_value;
                                 var max_crit_value = obj.main_tests[i].subtests[j].reference[0].max_crit_value;

                                 if (((min_nor_value != undefined || min_nor_value != "") && (max_nor_value != undefined || max_nor_value != "")) && ((min_abnor_value == undefined || min_abnor_value == "") && (max_abnor_value == undefined || max_abnor_value == "")) && ((min_crit_value == undefined || min_crit_value == "") && (max_crit_value == undefined || max_crit_value == ""))) {
                                    // alert(obj.main_tests[i].subtests[j].reference.length + "  " +  obj.main_tests[i].subtests[j].sub_test_name)
                                    if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_nor_value)) {
                                       isReadingNormal = true;
                                       testReading = obj.main_tests[i].subtests[j].reading;
                                    } else {
                                       isReadingAbnormal = true;
                                       testReading = obj.main_tests[i].subtests[j].reading + '*';
                                    }
                                 } else {

                                    if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_nor_value)) {
                                       isReadingNormal = true;
                                       testReading = obj.main_tests[i].subtests[j].reading;
                                    } else if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_abnor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_abnor_value)) {
                                       isReadingAbnormal = true;
                                       testReading = obj.main_tests[i].subtests[j].reading + '*';
                                    } else {
                                       isReadingCritical = true;
                                       testReading = obj.main_tests[i].subtests[j].reading + '**';
                                    }
                                 }
                              } else {
                                 testReading = obj.main_tests[i].subtests[j].reading
                              }

                              var printReading;
                              if (obj.main_tests[i].subtests[j].reading != undefined) {
                                 if (obj.main_tests[i].subtests[j].reference != undefined && obj.main_tests[i].subtests[j].reference.length != 0 && Object.keys(obj.main_tests[i].subtests[j].reference[0]).length != 0) {
                                    if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_nor_value)) {
                                       printReading = obj.main_tests[i].subtests[j].reading;
                                    } else if (parseInt(obj.main_tests[i].subtests[j].reading) >= parseInt(obj.main_tests[i].subtests[j].reference[0].min_abnor_value) && parseInt(obj.main_tests[i].subtests[j].reading) <= parseInt(obj.main_tests[i].subtests[j].reference[0].max_abnor_value)) {
                                       printReading = obj.main_tests[i].subtests[j].reading + '*';
                                    } else {
                                       printReading = obj.main_tests[i].subtests[j].reading + '**';
                                    }
                                    // alert(printReading)
                                 } else {
                                    printReading = obj.main_tests[i].subtests[j].reading;
                                 }
                              } else {
                                 printReading = "-"
                              }

                              var reading, egfr_value;
                              if (obj.main_tests[i].subtests[j].reading != undefined) {
                                 reading = obj.main_tests[i].subtests[j].reading;
                                 if (obj.main_tests[i].subtests[j].sub_test_name.toLowerCase() == "creatinine") {
                                    this.egfr_flag = true;
                                    egfr_value = this.serumCreatChange(reading);
                                    // alert(egfr_value + "   " + Number.isNaN(egfr_value))
                                    if (Number.isNaN(egfr_value) == true) {
                                       egfr_value = "-"
                                    }

                                    this.egfr_senddata = {
                                       life_style_id: obj.life_style_id,
                                       serum_creatinine: obj.main_tests[i].subtests[j].reading,
                                       egfr: egfr_value,
                                       country: obj.client_country
                                    }
                                 } else {
                                    this.egfr_flag = false;
                                 }
                              } else {
                                 reading = " - "
                                 this.egfr_flag = false;
                              }

                              // Lipid profile data for casesheet test_name
                              if (obj.main_tests[i].test_name.split(" ").join("").toLowerCase().includes("lipidprofile")) {
                                 this.lipidData_flag = true;
                                 if (obj.main_tests[i].subtests[j].sub_test_name != undefined) {
                                    // var totcholestrol, hdlCholesterol, ldlCholesterol, triglycerides, vldl, choHdl_ratio, ldlHdl_ratio
                                    var subtest_name = obj.main_tests[i].subtests[j].sub_test_name.split(" ").join("").toLowerCase();

                                    if ((subtest_name.includes("totalcholesterol"))) {
                                       this.totchole = obj.main_tests[i].subtests[j].reading;

                                    } else if ((subtest_name.includes("hdl-cholesterol"))) {
                                       this.hdl = obj.main_tests[i].subtests[j].reading;

                                    } else if ((subtest_name.includes("ldl-cholesterol"))) {
                                       this.ldl = obj.main_tests[i].subtests[j].reading;

                                    } else if ((subtest_name.includes("vldl"))) {
                                       this.vldl = obj.main_tests[i].subtests[j].reading;

                                    } else if ((subtest_name.includes("cho/hdlratio"))) {
                                       this.cho_hdl_ratio = obj.main_tests[i].subtests[j].reading;

                                    } else if ((subtest_name.includes("ldl/hdlratio"))) {
                                       this.ldl_hdl_ratio = obj.main_tests[i].subtests[j].reading;

                                    } else if ((subtest_name.includes("triglycerides"))) {
                                       this.trigs = obj.main_tests[i].subtests[j].reading;

                                    }
                                 }
                              }
                              // Lipid profile data  -- end --

                              // bilirubin and globulin calculations 
                              var subtest_name = obj.main_tests[i].subtests[j].sub_test_name.split(" ").join("").toLowerCase();
                              if (obj.main_tests[i].subtests[j].reading != undefined && obj.main_tests[i].subtests[j].reading != "") {
                                 var test_reading = obj.main_tests[i].subtests[j].reading;
                              }

                              if ((subtest_name.includes("hba1c"))) {
                                 this.hbA1c = test_reading;
                              }
                              else if ((subtest_name.includes("urinecreatinine")) ||
                                 (subtest_name.includes("urinemicroalbumin")) && subtest_name == "urinemicroalbumin") {
                                 if (subtest_name.includes("urinecreatinine")) {
                                    this.urine_creatinine = test_reading;
                                 } else if (subtest_name.includes("urinemicroalbumin")) {
                                    this.urine_micro_albumin = test_reading;
                                 }
                              } else if ((subtest_name.includes("totalprotein")) || (subtest_name.includes("albumin"))) {
                                 this.tot_ProteinFlag = true
                                 if (subtest_name.includes("totalprotein")) {
                                    this.total_Protein = test_reading;
                                 } else if (subtest_name.includes("albumin")) {
                                    this.albumin = test_reading;
                                 }
                              }

                              var subtests = undefined;
                              if (obj.main_tests[i].subtests[j].tests != undefined) {
                                 subtests = [];

                                 for (var l = 0; l < obj.main_tests[i].subtests[j].tests.length; l++) {
                                    var isReadingNormal_sub = false;
                                    var isReadingAbnormal_sub = false;
                                    var isReadingCritical_sub = false;
                                    var reference = [];
                                    var sub_reading = obj.main_tests[i].subtests[j].tests[l].reading;
                                    if (obj.main_tests[i].subtests[j].tests[l].reading != undefined && obj.main_tests[i].subtests[j].tests[l].reference != undefined && obj.main_tests[i].subtests[j].tests[l].reference.length != 0 && Object.keys(obj.main_tests[i].subtests[j].tests[l].reference[0]).length != 0) {
                                       var min_nor_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].min_nor_value;
                                       var max_nor_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].max_nor_value;
                                       var min_abnor_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].min_abnor_value;
                                       var max_abnor_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].max_abnor_value;
                                       var min_crit_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].min_crit_value;
                                       var max_crit_value_sub = obj.main_tests[i].subtests[j].tests[l].reference[0].max_crit_value;
                                       reference = obj.main_tests[i].subtests[j].tests[l].reference;


                                       if (((min_nor_value_sub != undefined || min_nor_value_sub != "") && (max_nor_value_sub != undefined || max_nor_value_sub != "")) && ((min_abnor_value_sub == undefined || min_abnor_value_sub == "") && (max_abnor_value_sub == undefined || max_abnor_value_sub == "")) && ((min_crit_value_sub == undefined || min_crit_value_sub == "") && (max_crit_value_sub == undefined || max_crit_value_sub == ""))) {
                                          if (parseInt(obj.main_tests[i].subtests[j].tests[l].reading) >= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].tests[l].reading) <= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].max_nor_value)) {
                                             isReadingNormal_sub = true;
                                             sub_reading = obj.main_tests[i].subtests[j].tests[l].reading;
                                          } else {
                                             isReadingAbnormal_sub = true;
                                             sub_reading = obj.main_tests[i].subtests[j].tests[l].reading + "*";
                                          }
                                       } else {
                                          if (parseInt(obj.main_tests[i].subtests[j].tests[l].reading) >= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].min_nor_value) && parseInt(obj.main_tests[i].subtests[j].tests[l].reading) <= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].max_nor_value)) {
                                             isReadingNormal_sub = true;
                                             sub_reading = obj.main_tests[i].subtests[j].tests[l].reading;
                                          } else if (parseInt(obj.main_tests[i].subtests[j].tests[l].reading) >= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].min_abnor_value) && parseInt(obj.main_tests[i].subtests[j].tests[l].reading) <= parseInt(obj.main_tests[i].subtests[j].tests[l].reference[0].max_abnor_value)) {
                                             isReadingAbnormal_sub = true;
                                             sub_reading = obj.main_tests[i].subtests[j].tests[l].reading + "*";
                                          } else {
                                             isReadingCritical_sub = true;
                                             sub_reading = obj.main_tests[i].subtests[j].tests[l].reading + "**";
                                          }
                                       }
                                    }

                                    // var reference;
                                    // if (obj.main_tests[i].subtests[j].tests[l].reference != undefined) {
                                    //    reference =  obj.main_tests[i].subtests[j].tests[l].reference;
                                    // }

                                    var ref_range = '-';
                                    if (obj.main_tests[i].subtests[j].tests[l].range != undefined) {
                                       ref_range = obj.main_tests[i].subtests[j].tests[l].range;
                                    }

                                    var test_order = '';
                                    if (obj.main_tests[i].subtests[j].tests[l].test_order != undefined) {
                                       test_order = obj.main_tests[i].subtests[j].tests[l].test_order;
                                    }

                                    var test_method = '';
                                    if (obj.main_tests[i].subtests[j].tests[l].test_method != undefined) {
                                       test_method = obj.main_tests[i].subtests[j].tests[l].test_method;
                                    }

                                    subtests.push({
                                       sub_test_list_id: obj.main_tests[i].subtests[j].tests[l].sub_test_list_id,
                                       sub_test_name: obj.main_tests[i].subtests[j].tests[l].sub_test_name,
                                       reading: sub_reading,
                                       unit: obj.main_tests[i].subtests[j].tests[l].unit,
                                       sub_uom: obj.main_tests[i].subtests[j].tests[l].sub_uom,
                                       test_method: test_method,
                                       diag_tests_list_id: obj.main_tests[i].subtests[j].tests[l].diag_tests_list_id,
                                       test_order: test_order,
                                       range: ref_range,
                                       reference: reference,
                                       is_Reading_normal: isReadingNormal_sub,
                                       is_Reading_Abnormal: isReadingAbnormal_sub,
                                       is_Reading_Critical: isReadingCritical_sub,
                                    })

                                    // bilirubin and globulin calculations 
                                    var subtest_name = obj.main_tests[i].subtests[j].tests[l].sub_test_name.split(" ").join("").toLowerCase();
                                    if (obj.main_tests[i].subtests[j].tests[l].reading != undefined && obj.main_tests[i].subtests[j].tests[l].reading != "") {
                                       var test_reading = obj.main_tests[i].subtests[j].tests[l].reading;
                                    }

                                    if ((subtest_name.includes("hba1c"))) {
                                       this.hbA1c = test_reading;
                                    } else if ((subtest_name.includes("bilirubin-total")) || (subtest_name.includes("bilirubin-direct")) || (subtest_name.includes("bilirubin-indirect"))) {
                                       if (subtest_name.includes("bilirubin-total")) {
                                          this.bilitubin_total = test_reading;
                                       } else if (subtest_name.includes("bilirubin-direct")) {
                                          this.bilirubin_direct = test_reading;
                                       }
                                    } else if ((subtest_name.includes("urinecreatinine")) ||
                                       (subtest_name.includes("urinemicroalbumin")) && subtest_name == "urinemicroalbumin") {
                                       if (subtest_name.includes("urinecreatinine")) {
                                          this.urine_creatinine = test_reading;
                                       } else if (subtest_name.includes("urinemicroalbumin")) {
                                          this.urine_micro_albumin = test_reading;
                                       }
                                    } else if ((subtest_name.includes("totalprotein")) || (subtest_name.includes("albumin"))) {
                                       this.tot_ProteinFlag = true
                                       if (subtest_name.includes("totalprotein")) {
                                          this.total_Protein = test_reading;
                                       } else if (subtest_name.includes("albumin")) {
                                          this.albumin = test_reading;
                                       }
                                    }
                                 }
                              }

                              this.tempTestList.push({
                                 sub_test_name: obj.main_tests[i].subtests[j].sub_test_name,

                                 subtest_id: obj.main_tests[i].subtests[j].sub_test_list_id,
                                 reading: testReading,
                                 unit: obj.main_tests[i].subtests[j].sub_uom,
                                 test_range: temp_value,
                                 test_method: test_method,
                                 is_Reading_normal: isReadingNormal,
                                 is_Reading_Abnormal: isReadingAbnormal,
                                 is_Reading_Critical: isReadingCritical,
                                 tests: subtests,
                                 egfr_value: egfr_value,

                                 egfr_flag: this.egfr_flag,
                                 isSelected: false,
                                 test_type: obj.main_tests[i].test_type
                              });
                           }
                        }
                        this.DiagTestReadingArray.push({
                           test_name: obj.main_tests[i].test_name,
                           sub_test_list: this.tempTestList,
                           test_order: obj.main_tests[i].test_order,
                        });
                     }

                     this.DiagTestReadingArray = this.DiagTestReadingArray.sort((a, b) => a.test_order - b.test_order);
                     this.tempTestList = [];
                     // console.log("this.tempTestList", JSON.stringify(this.tempTestList))

                     for (var m = 0; m < this.DiagTestReadingArray.length; m++) {
                        for (var n = 0; n < this.DiagTestReadingArray[m].sub_test_list.length; n++) {
                           this.tempTestList.push(this.DiagTestReadingArray[m].sub_test_list[n]);
                        }
                     }

                     for (var l = 0; l < this.tempTestList.length; l++) {
                        this.tempTestList[l].s_no = l + 1;
                     }

                     console.log("this.tempTestList", JSON.stringify(this.tempTestList))

                     for (var i = 0; i < obj.main_tests.length; i++) {
                        if (obj.main_tests[i].image_path != undefined && obj.main_tests[i].image_path != "") {
                           this.doctorFlag = false;
                           this.reportDetail = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image_path;
                           this.report_img = ipaddress.Ip_with_img_address.toString() + obj.main_tests[i].image_path;
                           // alert(this.report_img)
                        } else {
                           this.doctorFlag = true;
                        }
                        if (obj.main_tests[i].sequences != undefined && obj.main_tests[i].impression != undefined) {
                           this.testArray.push({
                              pres_test_id: obj.main_tests[i].pres_test_id,
                              test_name: obj.main_tests[i].test_name,
                              sequences: obj.main_tests[i].sequences,
                              impression: obj.main_tests[i].impression,
                              culture: obj.main_tests[i].culture,
                           });
                           for (var i = 0; i < this.testArray.length; i++) {
                              this.sequence = this.testArray[i].sequences;
                              this.Impression = this.testArray[i].impression;
                              this.culture = this.testArray[i].culture;
                           }
                        } else if (obj.main_tests[i].culture != undefined) {
                           this.testArray.push({
                              pres_test_id: obj.main_tests[i].pres_test_id,
                              test_name: obj.main_tests[i].test_name,
                              culture: obj.main_tests[i].culture,
                           });
                           for (var i = 0; i < this.testArray.length; i++) {
                              this.culture = this.testArray[i].culture;
                              this.testName = this.testArray[i].test_name;
                           }
                        }

                     }
                  }
                  this.patientHistory();
               }
            },
            error => {
               this.toastr.error(Message_data.getNetworkMessage());
               this.loader = true;
            });
   }

   uploadReport() {//report upload
      var send_data;
      if (this.reportType == "scans") {
         send_data = {
            pres_diag_id: this.diagID,
            diag_app_id: this.diagAppID,
            test_id: this.presTestID,
            user_id: Diagnosis_Helper.getUserID_Type().user_id,
            country: ipaddress.country_code,
            test_type: this.reportType,
            sequences: this.sequence,
            impression: this.Impression,
         }
      } else {
         send_data = {
            pres_diag_id: this.diagID,
            diag_app_id: this.diagAppID,
            test_id: this.presTestID,
            user_id: Diagnosis_Helper.getUserID_Type().user_id,
            country: ipaddress.country_code,
            test_type: this.reportType,
            observation: this.sequence,
            impression: this.Impression,
         };
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/udreport/", JSON.stringify(send_data), { headers: headers }).subscribe(
         data => {
            if (data.json().key == "1") {
               this.toastr.success(Message_data.rptApprovedSuccess);
               this.reporttype = Diagnosis_Helper.getReportType();

               if (this.reporttype == 'pathology') {
                  this.messageservice.sendMessage("report_approval_pathology");
               } else if (this.reporttype == 'radiology') {
                  this.messageservice.sendMessage("report_approval_radiology");
               } else if (this.reporttype == 'microbiology') {
                  this.messageservice.sendMessage("report_approval_microbiology");
               }
            } else {
               if (data.json().result != null)
                  this.toastr.error(data.json().result);
               else
                  this.toastr.error(Message_data.rptApprovedNotSuccess);
            }
         });
   }
   //Get zoom image
   clickDocument() {
      // this.navCtrl.push('ReportZoomPage', {
      //     report_image: this.reportDetail
      // })
   }
   rejectReport(type) {
      var reason;
      if (type == 'recheck') {
         reason = this.recheck_reason
      } else {
         reason = this.recollect_reason
      }

      console.log("rejectReport---type", type)
      var send_data;

      var selected_tests = [];
      selected_tests = this.tempTestList.filter((item) => item.isSelected == true);

      if (selected_tests.length != 0) {
         this.approve_data_arr = [];
         for (var i = 0; i < selected_tests.length; i++) {
            this.approve_data_arr.push({
               test_id: selected_tests[i].subtest_id,
               report_type: selected_tests[i].test_type
            })
         }
      }

      if (this.test_flag == "group") {
         send_data = {
            pres_diag_id: this.diagID,
            diag_app_id: this.diagAppID,
            // test_id: this.presTestID,
            user_id: Diagnosis_Helper.getUserID_Type().user_id,
            country: ipaddress.country_code,
            test_type: this.report_type,
            // report_type: this.reportType,
            reject_reason: reason,
            type: "reject",
            reject_type: type,
            tests: this.approve_data_arr
         }
      } else {
         send_data = {
            pres_diag_id: this.diagID,
            diag_app_id: this.diagAppID,
            // test_id: this.presTestID,
            user_id: Diagnosis_Helper.getUserID_Type().user_id,
            country: ipaddress.country_code,
            test_type: this.report_type,
            // report_type: this.reportType,
            reject_reason: reason,
            type: "reject",
            reject_type: type,
            tests: [{
               test_id: this.presTestID,
               report_type: this.reportType,
            }]
         }
      }


      console.log("rejectReport---send_data", send_data)
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/stapp/", JSON.stringify(send_data), { headers: headers }).subscribe(
         data => {
            if (data.json().key == "1") {
               if (type == 'recollect') {
                  this.toastr.success("Successfully rejected report for recollecting sample");
               } else if (type == 'recheck') {
                  this.toastr.success("Successfully rejected report for rechecking");
               } else {
                  this.toastr.success(Message_data.rptRejectedSuccess);
               }
               this.reporttype = Diagnosis_Helper.getReportType();

               if (this.reporttype == 'pathology') {
                  this.messageservice.sendMessage("report_approval_pathology");
               } else if (this.reporttype == 'radiology') {
                  this.messageservice.sendMessage("report_approval_radiology");
               } else if (this.reporttype == 'microbiology') {
                  this.messageservice.sendMessage("report_approval_microbiology");
               }
            } else {
               if (data.json().result != null)
                  this.toastr.error(data.json().result);
               else
                  this.toastr.error(Message_data.unableToRejectRpt);
            }
         });
   }

   rejectReport2(type) {
      var reason;
      if (type == 'recheck') {
         reason = this.recheck_reason
      } else {
         reason = this.recollect_reason
      }

      console.log("rejectReport---type", type)
      console.log("tempTestList", this.tempTestList.filter((item) => item.isSelected == true))

      var selected_tests = [];
      selected_tests = this.tempTestList.filter((item) => item.isSelected == true);

      if (selected_tests.length != 0) {
         this.approve_data_arr = [];
         for (var i = 0; i < selected_tests.length; i++) {
            this.approve_data_arr.push({
               test_id: selected_tests[i].subtest_id,
               report_type: selected_tests[i].test_type
            })
         }
      }

      var send_data;

      if (this.test_flag == "group") {
         send_data = {
            pres_diag_id: this.diagID,
            diag_app_id: this.diagAppID,
            // test_id: this.presTestID,
            user_id: Diagnosis_Helper.getUserID_Type().user_id,
            country: ipaddress.country_code,
            test_type: this.report_type,
            // report_type: this.reportType,
            reject_reason: reason,
            type: "reject",
            reject_type: type,
            tests: this.approve_data_arr
         }
      } else {
         send_data = {
            pres_diag_id: this.diagID,
            diag_app_id: this.diagAppID,
            // test_id: this.presTestID,
            user_id: Diagnosis_Helper.getUserID_Type().user_id,
            country: ipaddress.country_code,
            test_type: this.report_type,
            // report_type: this.reportType,
            reject_reason: reason,
            type: "reject",
            reject_type: type,
            tests: [{
               test_id: this.presTestID,
               report_type: this.reportType,
            }]
         }
      }


      console.log("rejectReport---send_data", send_data)

   }

   approveReport() {//Reportapprove

      // clearing ldl calculation variables
      this.totchole = undefined;
      this.hdl = undefined;
      this.trigs = undefined;
      this.ldl = undefined;
      this.vldl = undefined;
      this.cho_hdl_ratio = undefined;
      this.ldl_hdl_ratio = undefined;

      this.urine_creatinine = undefined;
      this.urine_micro_albumin = undefined;
      this.creatine_albumin_ratio = undefined;

      // clearing globulin and bilirubin calculation variables
      // this.bilitubin_total = undefined;
      // this.bilirubin_direct = undefined;
      // this.bilirubin_indirect = undefined;
      // this.total_Protein = undefined;
      // this.albumin = undefined;
      // this.globulin = undefined;
      // this.ag_ratio = undefined;

      var subtest_array = [];
      for (var i = 0; i < this.tempTestList.length; i++) {
         if (this.tempTestList[i].tests != undefined && this.tempTestList[i].tests.length != 0) {
            for (var j = 0; j < this.tempTestList[i].tests.length; j++) {
               subtest_array.push({
                  sub_tests_id: this.tempTestList[i].tests[j].sub_test_list_id,
                  reading: this.tempTestList[i].tests[j].reading.replace(/\*/g, ''),
               })
            }
         } else {
            subtest_array.push({
               sub_tests_id: this.tempTestList[i].subtest_id,
               reading: this.tempTestList[i].reading.replace(/\*/g, ''),
            })
         }
      }

      console.log("subtest_array - for approve reading edit", subtest_array)

      var send_data;
      if (this.report_type == "pathology") {
         if (this.test_flag == "group") {
            send_data = {
               pres_diag_id: this.diagID,
               diag_app_id: this.diagAppID,
               
               user_id: this.selected_approver,
               country: ipaddress.country_code,
               test_type: this.report_type,
               type: "approve",
              
               tests: this.approve_data_arr,
               sub_tests: subtest_array,
              
            }

         } else {
            send_data = {
               pres_diag_id: this.diagID,
               diag_app_id: this.diagAppID,
               
               user_id: this.selected_approver,
               country: ipaddress.country_code,
               test_type: this.report_type,
               type: "approve",
              
               tests: [{
                  test_id: this.presTestID,
                  report_type: this.reportType,
               }],
               sub_tests: subtest_array,
              
            }
         }

      } else {
         if (this.reportType == "scans") {
            send_data = {
               pres_diag_id: this.diagID,
               diag_app_id: this.diagAppID,
               test_id: this.presTestID,
               user_id: this.selected_approver,
               country: ipaddress.country_code,
               test_type: this.report_type,
               sequences: this.sequence,
               impression: this.Impression,
               type: "approve",
               report_type: this.reportType,
               
            };

         } else {
            send_data = {
               pres_diag_id: this.diagID,
               diag_app_id: this.diagAppID,
               
               user_id: this.selected_approver,
               country: ipaddress.country_code,
               test_type: this.report_type,
               type: "approve",
               
               tests: [{
                  test_id: this.presTestID,
                  report_type: this.reportType,
                  observation: this.sequence,
                  impression: this.Impression,
               }]
            }
         }
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/stapp/", JSON.stringify(send_data), { headers: headers }).subscribe(
         data => {
            if (data.json().key == "1") {
               this.toastr.success(Message_data.rptApprovedSuccess);

               if (this.egfr_flag == true) {
                  this.egfrSendData();
               }

               if (this.lipidData_flag == true) {
                  this.getLipidProfileData();
               }

               if (this.tot_ProteinFlag == true) {
                  this.getTotalProteinData();
               }

               if (this.report_type == "pathology") {
                  this.messageservice.sendMessage("report_approval_pathology");
               } else if (this.report_type == "radiology") {
                  this.messageservice.sendMessage("report_approval_radiology");
               } else {
                  this.messageservice.sendMessage("report_approval_microbiology");
               }

            } else {
               if (data.json().result != null)
                  this.toastr.error(data.json().result);
               else
                  this.toastr.error(Message_data.rptApprovedNotSuccess);
            }
         });
   }

   egfrSendData() {
      console.log("this.egfr_senddata  ", this.egfr_senddata)
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "neph/sdread", JSON.stringify(this.egfr_senddata), { headers: headers }).subscribe(
         data => {
            console.log("egfrSendData  " + JSON.stringify(data.json()))
         });
   }

   getTotalProteinData() {
      var totalProteinSenddata = {
         life_style_id: this.life_style_id,
         albumin: this.albumin,

         protein: this.total_Protein,
         country: this.clntcountry
      }
      console.log("this.totalProteinSenddata  ", totalProteinSenddata)
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "neph/sdread", JSON.stringify(totalProteinSenddata), { headers: headers }).subscribe(
         data => {
            console.log("totalProteinSenddata  " + JSON.stringify(data.json()))
         });
   }

   getLipidProfileData() {
      this.send_lipidProf_data = {
         life_style_id: this.life_style_id,
         total_cholesterol: this.totchole,
         hdl: this.hdl,
         ldl: this.ldl,
         vldl: this.vldl,
         trigs: this.trigs,
         country: this.clntcountry
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "diab/reading", JSON.stringify(this.send_lipidProf_data), { headers: headers }).subscribe(
         data => {
            console.log("send_lipidProf_data  " + JSON.stringify(data.json()))
         });
   }

   backNAV() {
      this.reporttype = Diagnosis_Helper.getReportType();

      if (this.reporttype == 'pathology') {
         this.messageservice.sendMessage("report_approval_pathology");
      } else if (this.reporttype == 'radiology') {
         this.messageservice.sendMessage("report_approval_radiology");
      } else if (this.reporttype == 'microbiology') {
         this.messageservice.sendMessage("report_approval_microbiology");
      }

      // this.messageservice.sendMessage("report_approval");

      // clearing ldl calculation variables
      this.totchole = undefined;
      this.hdl = undefined;
      this.trigs = undefined;
      this.ldl = undefined;
      this.vldl = undefined;
      this.cho_hdl_ratio = undefined;
      this.ldl_hdl_ratio = undefined;

      // clearing globulin and bilirubin calculation variables
      this.bilitubin_total = undefined;
      this.bilirubin_direct = undefined;
      this.bilirubin_indirect = undefined;
      this.total_Protein = undefined;
      this.albumin = undefined;
      this.globulin = undefined;
      this.ag_ratio = undefined;

      this.urine_creatinine = undefined;
      this.urine_micro_albumin = undefined;
      this.creatine_albumin_ratio = undefined;
   }

   patientHistory() {
      // Diagnosis_Helper.setPatientDetails(null);
      var send_data = {
         client_reg_id: this.clentID,
         relation_id: this.relationId,
         sub_rel_id: this.subRelationId,
         diag_centre_id: this.diagCenterId,
         test_cat_id: this.testCategory,
      };

      if (this.relationId == undefined) {
         this.relationId = '1';
      }

      console.log("send_data", send_data);

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/grbc',
         JSON.stringify(send_data),
         { headers: headers })
         .subscribe(
            response => {
               this.patientHistoryList = [];
               var obj = JSON.parse(response["_body"]);
               console.log("Patient History Details", obj)

               if (obj.test_history != null) {
                  for (var i = 0; i < obj.test_history.length; i++) {
                     var dateval = obj.test_history[i].appointment_date.split("-");
                     var dval = dateval[2] + "-" + dateval[1] + "-" + dateval[0];
                     this.patientHistoryList.push({
                        appointment_date: dval,
                        diag_appointment_id: obj.test_history[i].diag_appointment_id,
                        reading: obj.test_history[i].reading,
                        sub_test_id: obj.test_history[i].sub_test_id,
                        sub_test_name: obj.test_history[i].sub_test_name,
                        test_id: obj.test_history[i].test_id,
                        test_name: obj.test_history[i].test_name,
                     })
                  }
                  console.log("this.patientHistoryList", this.patientHistoryList);
               }
            },
            error => {
               this.toastr.error(Message_data.getNetworkMessage());
            });
      // Diagnosis_Helper.setPatientDetails(pat_detials);
      // this.messageservice.sendMessage("patient_history");
   }

   approveData_create() {
      this.approve_data_arr = [];
      if (this.pres_tests != undefined) {
         for (var i = 0; i < this.pres_tests.length; i++) {
            this.approve_data_arr.push({
               test_id: this.pres_tests[i].pres_test_id,
               report_type: this.pres_tests[i].test_type
            })
         }
      }

      console.log(this.approve_data_arr)
   }

   serumCreatChange(SerumCreat) {//Serium criteria calculation for egrf
      // alert("SerumCreat "+SerumCreat)
      if (SerumCreat != "") {
         if (this.clientAge != undefined && this.clientAge != null) {
            if (this.clientGender == "Male") {
               this.eGFR = 175 * (parseFloat(SerumCreat) ** -1.154) * (parseInt(this.clientAge) ** -0.203);
            } else if (this.clientGender == "Female") {
               this.eGFR = 175 * (parseFloat(SerumCreat) ** -1.154) * (parseInt(this.clientAge) ** -0.203) * 0.742;
            }
         }
         // alert(this.eGFR.toFixed(2))
         return this.eGFR.toFixed(2);
      }
      else {
         this.eGFR = undefined;
      }
   }

   getApproverList() {
      var send_data = {
         type: this.report_type,
         diag_centre_id: this.diagCenterId,
      };
      console.log("send_data", send_data);

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gabt',
         JSON.stringify(send_data),
         { headers: headers })
         .subscribe(
            response => {
               this.approverList = [];
               var obj = JSON.parse(response["_body"]);
               this.approverList = obj.approvers;
               console.log("Approver list", this.approverList)
               if (this.approverList.length != 0) {
                  this.selected_approver = this.approverList[0].doc_reg_id;
               }
            },
            error => {
               this.toastr.error(Message_data.getNetworkMessage());
            });

   }

   capitalizeFirstLetter(input: string): string {
      if (!input) return ''; // Handle empty string or null input
      return input.charAt(0).toUpperCase() + input.slice(1);
   }

   checkRef(e, subData) {
      console.log("subData", subData)

      if (subData.sub_test_name != undefined) {
         var reading = e.toString();
         var testtype_name;
         var subtest_name = subData.sub_test_name.split(" ").join("").toLowerCase();
         // alert((subtest_name.includes("totalcholesterol")) + "   " + subData.sub_test_name.toLowerCase())
         if ((subtest_name.includes("totalcholesterol")) || (subtest_name.includes("hdl-cholesterol")) || (subtest_name.includes("triglycerides")) || (subtest_name.includes("ldl-cholesterol"))) {
            if ((subtest_name.includes("totalcholesterol"))) {
               testtype_name = "totCholesterol"
               this.calculateLDL(reading, testtype_name, subData);
               // alert("Tot cholestrol")
            } else if ((subtest_name.includes("hdl-cholesterol"))) {
               testtype_name = "hdlCholesterol"
               this.calculateLDL(reading, testtype_name, subData);
               // alert("hdlcholesterol")
            } else if ((subtest_name.includes("ldl-cholesterol"))) {
               testtype_name = "ldlCholesterol"
               this.calculateLDL(reading, testtype_name, subData);
            } else {
               testtype_name = "triglycerides"
               this.calculateLDL(reading, testtype_name, subData);
               // alert("triglycerides")
            }
         } else if ((subtest_name.includes("hba1c"))) {
            this.calculateA1cMean(reading, subData);
         } else if ((subtest_name.includes("bilirubin-total")) || (subtest_name.includes("bilirubin-direct")) || (subtest_name.includes("bilirubin-indirect"))) {
            if (subtest_name.includes("bilirubin-total")) {
               testtype_name = "bilirubinTotal"
               this.calculateBilirubin(reading, testtype_name, subData);

            } else if (subtest_name.includes("bilirubin-direct")) {
               testtype_name = "bilirubinDirect"
               this.calculateBilirubin(reading, testtype_name, subData);
            }
         } else if ((subtest_name.includes("urinecreatinine")) ||
            (subtest_name.includes("urinemicroalbumin")) && subtest_name == "urinemicroalbumin") {
            if (subtest_name.includes("urinecreatinine")) {
               testtype_name = "urineCreatinine"
               this.calculateAlbuminCreatineRatio(reading, testtype_name, subData);

            } else if (subtest_name.includes("urinemicroalbumin")) {
               testtype_name = "urineMicroAlbumin"
               this.calculateAlbuminCreatineRatio(reading, testtype_name, subData);

            }
         } else if ((subtest_name.includes("totalprotein")) || (subtest_name.includes("albumin"))) {
            if (subtest_name.includes("totalprotein")) {
               testtype_name = "totalProtein"
               this.calculateGlobulin(reading, testtype_name, subData);

            } else if (subtest_name.includes("albumin")) {
               testtype_name = "albumin"
               this.calculateGlobulin(reading, testtype_name, subData);

            }
         } else if (subtest_name.includes("creatinine")) {
            this.egfr_value = this.serumCreatChange(reading);

            if (Number.isNaN(this.egfr_value) != true) {
               subData.egfr_value = this.egfr_value;
            } else {
               subData.egfr_value = 0;
            }

            this.egfr_senddata.egfr = this.egfr_value;
            this.egfr_senddata.serum_creatinine = reading;
         }
      }
   }

   calculateAlbuminCreatineRatio(reading, testtype_name, subData) {
      this.urine_creatinine, this.urine_micro_albumin, this.creatine_albumin_ratio;

      if (testtype_name == 'urineCreatinine') {
         this.urine_creatinine = reading;

      } else if (testtype_name == 'urineMicroAlbumin') {
         this.urine_micro_albumin = reading;

      }

      if (this.urine_creatinine != undefined && this.urine_micro_albumin != undefined) {
         this.creatine_albumin_ratio = parseFloat(this.urine_micro_albumin) / parseFloat(this.urine_creatinine)

         for (var i = 0; i < this.tempTestList.length; i++) {
            if (this.tempTestList[i].sub_test_name.split(" ").join("").toLowerCase().includes("urinemicroalbumin/creatinineratio")) {
               if (Number.isNaN(this.creatine_albumin_ratio) != true) {
                  this.tempTestList[i].reading = this.creatine_albumin_ratio.toFixed(2);
                  this.tempTestList[i].is_disabled = true;
               } else {
                  this.tempTestList[i].reading = 0;
               }
            }

            if (this.tempTestList[i].tests != undefined) {
               if (this.tempTestList[i].tests.length != 0) {
                  for (var j = 0; j < this.tempTestList[i].tests.length; j++) {
                     if (this.tempTestList[i].tests[j].sub_test_name.split(" ").join("").toLowerCase().
                        includes("urinemicroalbumin/creatinineratio")) {
                        if (Number.isNaN(this.creatine_albumin_ratio) != true) {
                           this.tempTestList[i].tests[j].reading = this.creatine_albumin_ratio.toFixed(2);
                           this.tempTestList[i].tests[j].is_disabled = true;
                        } else {
                           this.tempTestList[i].tests[j].reading = 0;
                        }
                     }
                  }
               }
            }
         }
      }
   }

   calculateLDL(reading, testtype_name, subData) {
      if (testtype_name == 'totCholesterol') {
         this.totchole = reading;

      } else if (testtype_name == 'hdlCholesterol') {
         this.hdl = reading;

      } else if (testtype_name == 'ldlCholesterol') {
         this.ldl = reading;

      } else if (testtype_name == 'triglycerides') {
         this.trigs = reading;
      }

      if (this.totchole != undefined && this.hdl != undefined && this.trigs != undefined && this.ldl != undefined) {
         // this.ldl = parseFloat(this.totchole) - (parseFloat(this.hdl) + (parseFloat(this.trigs) / 5))
         this.vldl = parseFloat(this.trigs) / 5;
         this.cho_hdl_ratio = parseFloat(this.totchole) / parseFloat(this.hdl);
         this.ldl_hdl_ratio = this.ldl / parseFloat(this.hdl);

         for (var i = 0; i < this.tempTestList.length; i++) {
            if (this.tempTestList[i].sub_test_name.split(" ").join("").toLowerCase().includes("cho/hdlratio")) {
               if (Number.isNaN(this.cho_hdl_ratio) != true) {
                  this.tempTestList[i].reading = this.cho_hdl_ratio.toFixed(2);
               } else {
                  this.tempTestList[i].reading = "0";
               }
            }
            if (this.tempTestList[i].sub_test_name.split(" ").join("").toLowerCase().includes("ldl/hdlratio")) {
               if (Number.isNaN(this.ldl_hdl_ratio) != true) {
                  this.tempTestList[i].reading = this.ldl_hdl_ratio.toFixed(2);
               } else {
                  this.tempTestList[i].reading = "0";
               }
            }
            if (this.tempTestList[i].sub_test_name.toLowerCase().includes("vldl")) {
               if (Number.isNaN(this.vldl) != true) {
                  this.tempTestList[i].reading = this.vldl.toFixed(2);
               } else {
                  this.tempTestList[i].reading = "0";
               }
            }

            if (this.tempTestList[i].tests != undefined) {
               if (this.tempTestList[i].tests.length != 0) {
                  for (var j = 0; j < this.tempTestList[i].tests.length; j++) {
                     if (this.tempTestList[i].tests[j].sub_test_name.split(" ").join("").toLowerCase().includes("cho/hdlratio")) {
                        if (Number.isNaN(this.cho_hdl_ratio) != true) {
                           this.tempTestList[i].tests[j].reading = this.cho_hdl_ratio.toFixed(2);
                        } else {
                           this.tempTestList[i].tests[j].reading = "0";
                        }
                     }
                     if (this.tempTestList[i].tests[j].sub_test_name.split(" ").join("").toLowerCase().includes("ldl/hdlratio")) {
                        if (Number.isNaN(this.ldl_hdl_ratio) != true) {
                           this.tempTestList[i].tests[j].reading = this.ldl_hdl_ratio.toFixed(2);
                        } else {
                           this.tempTestList[i].tests[j].reading = "0";
                        }
                     }
                     if (this.tempTestList[i].tests[j].sub_test_name.toLowerCase().includes("vldl")) {
                        if (Number.isNaN(this.vldl) != true) {
                           this.tempTestList[i].tests[j].reading = this.vldl.toFixed(2);
                        } else {
                           this.tempTestList[i].tests[j].reading = "0";
                        }
                     }
                  }
               }
            }
         }
      }
   }

   calculateA1cMean(reading, subData) {
      console.log(subData)
      this.hbA1c = reading;

      if (this.hbA1c != undefined) {
         this.mean_bloodGlucose = parseFloat(this.hbA1c) * 28.7 - 46.7;
      }

      var reading;
      for (var i = 0; i < this.tempTestList.length; i++) {
         if (this.tempTestList[i].sub_test_name.split(" ").join("").toLowerCase().includes("meanbloodglucose")) {
            if (Number.isNaN(this.mean_bloodGlucose) != true) {
               reading = Math.ceil(this.mean_bloodGlucose);
               this.tempTestList[i].reading = reading.toString();
            } else {
               this.tempTestList[i].reading = "0";
            }
         }
         for (var j = 0; j < this.tempTestList[i].tests.length; j++) {
            if (this.tempTestList[i].tests[j].sub_test_name.split(" ").join("").toLowerCase().includes("meanbloodglucose")) {
               if (Number.isNaN(this.mean_bloodGlucose) != true) {
                  reading = Math.ceil(this.mean_bloodGlucose);
                  this.tempTestList[i].tests[j].reading = reading.toString();
               } else {
                  this.tempTestList[i].tests[j].reading = "0";
               }
            }
         }
      }

   }

   calculateBilirubin(reading, testtype_name, subData) {
      this.bilitubin_total, this.bilirubin_direct, this.bilirubin_direct;

      if (testtype_name == 'bilirubinTotal') {
         this.bilitubin_total = reading;

      } else if (testtype_name == 'bilirubinDirect') {
         this.bilirubin_direct = reading;

      }

      if (this.bilitubin_total != undefined && this.bilirubin_direct != undefined) {
         this.bilirubin_indirect = parseFloat(this.bilitubin_total) - parseFloat(this.bilirubin_direct)

         for (var i = 0; i < this.tempTestList.length; i++) {
            if (this.tempTestList[i].sub_test_name.split(" ").join("").toLowerCase().includes("bilirubin-indirect")) {
               if (Number.isNaN(this.bilirubin_indirect) != true) {
                  this.tempTestList[i].reading = this.bilirubin_indirect.toFixed(2);
               } else {
                  this.tempTestList[i].reading = 0;
               }
            }

            if (this.tempTestList[i].tests != undefined) {
               if (this.tempTestList[i].tests.length != 0) {
                  for (var j = 0; j < this.tempTestList[i].tests.length; j++) {
                     if (this.tempTestList[i].tests[j].sub_test_name.split(" ").join("").toLowerCase().includes("bilirubin-indirect")) {
                        if (Number.isNaN(this.bilirubin_indirect) != true) {
                           this.tempTestList[i].tests[j].reading = this.bilirubin_indirect.toFixed(2);
                        } else {
                           this.tempTestList[i].tests[j].reading = 0;
                        }
                     }
                  }
               }
            }
         }
      }
   }

   calculateGlobulin(reading, testtype_name, subData) {
      // alert(testtype_name)
      this.total_Protein, this.albumin, this.globulin, this.ag_ratio;

      // alert(JSON.stringify({
      //    total_protein: this.total_Protein,
      //    albumin: this.albumin,
      //    globulin: this.globulin,
      //    ag_ratio: this.ag_ratio,
      // }))

      if (testtype_name == 'totalProtein') {
         this.total_Protein = reading;

      } else if (testtype_name == 'albumin') {
         this.albumin = reading;

      }

      if (this.total_Protein != undefined && this.albumin != undefined) {
         this.globulin = parseFloat(this.total_Protein) - parseFloat(this.albumin)

         if (this.globulin != undefined && Number.isNaN(this.globulin) != true) {
            this.ag_ratio = parseFloat(this.albumin) / parseFloat(this.globulin)
         } else {
            this.globulin = "0.00"
            this.ag_ratio = "0.00"
         }

         for (var i = 0; i < this.tempTestList.length; i++) {
            if (this.tempTestList[i].sub_test_name.split(" ").join("").toLowerCase().includes("globulin")) {
               if (Number.isNaN(this.globulin) != true) {
                  this.tempTestList[i].reading = this.globulin.toFixed(2);
               } else {
                  this.tempTestList[i].reading = 0;
               }
            }

            if (this.tempTestList[i].sub_test_name.split(" ").join("").toLowerCase().includes("a/gratio")) {
               if (Number.isNaN(this.ag_ratio) != true) {
                  this.tempTestList[i].reading = this.ag_ratio.toFixed(2);
               } else {
                  this.tempTestList[i].reading = 0;
               }
            }

            if (this.tempTestList[i].tests != undefined) {
               if (this.tempTestList[i].tests.length != 0) {
                  for (var j = 0; j < this.tempTestList[i].tests.length; j++) {
                     if (this.tempTestList[i].tests[j].sub_test_name.split(" ").join("").toLowerCase().includes("globulin")) {
                        if (Number.isNaN(this.globulin) != true) {
                           this.tempTestList[i].tests[j].reading = this.globulin.toFixed(2);
                        } else {
                           this.tempTestList[i].tests[j].reading = 0;
                        }
                     }

                     if (this.tempTestList[i].tests[j].sub_test_name.split(" ").join("").toLowerCase().includes("a/gratio")) {
                        if (Number.isNaN(this.ag_ratio) != true) {
                           this.tempTestList[i].tests[j].reading = this.ag_ratio.toFixed(2);
                        } else {
                           this.tempTestList[i].tests[j].reading = 0;
                        }
                     }
                  }
               }
            }
         }
      }
   }

   getPatientHistory_new(testId) {
      // alert(testId)
      this.patient_historyList = [];
      this.dateList = [];
      this.readinglist = [];

      var send_data = {
         client_reg_id: this.clentID,
         relation_id: this.relationId,
         sub_rel_id: this.subRelationId,
         diag_test_id: testId
      }

      console.log(send_data)

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/ghrbtc', JSON.stringify(send_data),
         { headers: headers })
         .subscribe(
            response => {
               var obj = JSON.parse(response["_body"]);
               console.log("obj----getPatientHistory", obj)

               var date_val;
               var final_date;
               if (obj.test_history != undefined) {
                  for (var i = 0; i < obj.test_history.length; i++) {
                     if (obj.test_history[i].entry_date != undefined) {
                        date_val = obj.test_history[i].entry_date.split("-");
                        final_date = date_val[2] + "-" + date_val[1] + "-" + date_val[0];
                        this.dateList.push(Date_Formate(obj.test_history[i].entry_date));
                        this.readinglist.push(obj.test_history[i].reading);
                     } else {
                        final_date = "";
                     }

                     this.patient_historyList.push({
                        appointment_date: obj.test_history[i].appointment_date,
                        diag_tests_id: obj.test_history[i].diag_tests_id,
                        test_name: obj.test_history[i].test_name,
                        reading: obj.test_history[i].reading,
                        entry_date: final_date,
                     })
                  }
                  // this.patient_historyList = obj.test_history;
                  console.log("this.dateList", this.dateList)

                  setTimeout(() => {
                     this.openPatHistoryGraph(this.dateList, this.readinglist);
                  }, 200)
               }
            }, error => { });
   }

   openPatHistoryGraph(date, reading) {

      if (this.patHistCanvas) {
         this.patHistCanvas.destroy();
      }

      var graph_type;
      if (date.length <= 1 || reading.length <= 1) {
         graph_type = 'bar';
      } else {
         graph_type = 'line';
      }

      var ctx = document.getElementById('myChart');

      this.patHistCanvas = new Chart(ctx, {

         type: graph_type,
         data: {
            labels: date,
            datasets: [{
               label: 'Previous Readings',
               data: reading,
               borderColor: '#FF6384',
               borderWidth: 1
            }]
         },

      });
   }

}
