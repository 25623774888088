<div class="row" style="width:100%;margin:0 auto;">
   <div class="col-12">
      <mdb-card>
         <mdb-card-header class="bg-white">
            <div class="headerCover">
               <div class="headerTilte app_list_header">
                  <h5 class="m-0" class="mainHeadingStyle">Outsource Payments</h5>
               </div>
               <div class="headerButtons">
                  <img src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo"
                     (click)="savePayments()" [hidden]="!isAnySelected()" />
               </div>
            </div>
         </mdb-card-header>
         <mdb-card-body>
            <div class="row">
               <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="row">
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                     <mat-label class="matlabel">Outsourcing Center<br>
                        <select disableOptionCentering class="ipcss " required [(ngModel)]="outSrcId">
                           <option disabled>Select</option>
                           <option *ngFor="let centerName of outsourceList" value="{{centerName.outsource_id}}">
                              {{centerName.centre_name}}</option>
                        </select>
                     </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style="width: 15%;">
                     <mat-label class="matlabel" style="margin-top: 7px;">From
                        <input type="date" class="ipcss_date " [(ngModel)]="fromDate" #matInput style="width: 140px;">
                     </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style="width: 15%;">
                     <mat-label class="matlabel" style="margin-top: 7px;">To
                        <input type="date" class="ipcss_date " [(ngModel)]="toDate" min="" #matInput
                           style="width: 140px;">
                     </mat-label>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" style="width: 15%;">
                     <mat-label class="matlabel">Duration<br>
                        <select [(ngModel)]="filtduration" class="ipcss">
                           <option value="select">Select</option>
                           <option value="7">1 week</option>
                           <option value="30">1 month</option>
                           <option value="91">3 months</option>
                           <option value="183">6 months</option>
                           <option value="365">1 year</option>
                        </select>
                     </mat-label>
                  </div>

                  <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"  *ngIf="outSrcBills.length != 0">
                     <mat-label>Invoice No<br>
                        <input type="text" class="ipcss"  autocomplete="off" [(ngModel)]="invoiceNo">
                     </mat-label>
                     <!-- <label for="name"></label> -->
                  </div>
                  
                  <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1">
                     <mat-label class="matlabel"><br>
                        <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                           (click)="getOutsourceBills()" />
                     </mat-label>
                  </div>
                  </div>
               </div>

                 

                  <div class="col-12 mt-3" *ngIf="outSrcBills.length != 0">
                     <table mdbTable datatable [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable">
                        <thead>
                           <tr>
                              <th style="width: 10%;">Bill No</th>
                              <th style="width: 10%;">Invoice Number</th>
                              <th style="width: 15%;">Amount</th>
                              <th style="width: 15%;">Payment</th>
                              <th style="width:10%">Paid amount</th>
                              <th style="width:10%">Paid on</th> 
                              <th style="width:10%">Paid by</th>
                              <th style="width:10%">Payment mode</th>
                              <th style="width:10%">Balance</th>
                              <th style="width: 10%;">Status</th>
                              <th style="width: 5%;">&nbsp;</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let bill of outSrcBills">
                              <td style="text-align: center !important;">{{bill.billing_id}}</td>
                              <td style="text-align: center !important;"><input [(ngModel)]="bill.inv_num" class="ipcss"
                                    matInput text-right maxlength="10" style="margin:0" style="text-align:right;" />
                              </td>
                              <td style="text-align: center !important;">{{bill.amount}}</td>
                              <td style="text-align: center !important;"><input [(ngModel)]="bill.paid_amount"
                                    class="ipcss" matInput text-right maxlength="10" style="margin:0"
                                    style="text-align:right;" /></td>
                                    <td style="text-align: right !important;">{{bill.paid_amount}}</td>
                              <td style="text-align: right !important;">{{bill.paid_date}}</td> 
                              <td style="text-align: right !important;">{{bill.paid_by}}</td>
                              <td style="text-align: right !important;">
                                 <select class="ipcss " [(ngModel)]="bill.payment_mode" disableOptionCentering>
                                    <option *ngFor="let pay of payTypeList" value={{pay.pay_id}}>{{pay.pay_desc}}</option>
                                  </select>
                                  <!-- payment_mode -->
                              </td>     
                              <td style="text-align: right !important;">{{bill.balance}}</td>
                              <td style="text-align: center !important;">{{bill.paid_flag}}</td>
                              <td style="font-size: 12px;width: 5%;" >
                                 <input type="checkbox" (change)="getInvoiceNo()" [(ngModel)]="bill.isSelected"
                                    name="bill.billing_id" value="{{bill.billing_id}}" [disabled]="bill.checkboxtype">
                                 <!-- </mdb-checkbox> -->
                                 <!-- [(ngModel)]="list.isSelected" name="list.Clnt_Name"
                                 value="{{list.Clnt_Name}}" [disabled]="list.disableflag" -->
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
               <div class="col-12" *ngIf="outSrcBills.length == 0">
                  <p class="nodata">No data found</p>
              
            </div>
            <div class="row" [hidden]="true">
               <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 select_bottom">
                  <mat-label class="matlabel">Amount to be paid<br>
                     <input class="ipcss" matInput text-right maxlength="10" style="margin:0" style="text-align:right;"
                        disabled />
                  </mat-label>
               </div>
               <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                  <mat-label class="matlabel">Payment type<br>
                     <select class="ipcss " disableOptionCentering>
                        <option></option>
                     </select>
                  </mat-label>
               </div>
               <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <mat-label class="matlabel">Insurer<br>
                     <select class="ipcss " disableOptionCentering>
                        <option></option>
                     </select>
                  </mat-label>
               </div>
               <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                  <mat-label class="matlabel">Card No <br>
                     <input class="ipcss  widthbillcreate" matInput />
                  </mat-label>
               </div>
               <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                  <mat-label class="matlabel">Holder name<br>
                     <input class="ipcss  widthbillcreate" matInput />
                  </mat-label>
               </div>
               <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                  <mat-label class="matlabel">Transaction ID<br>
                     <input class="ipcss  widthbillcreate" matInput />
                  </mat-label>
               </div>
               <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                  <mat-label class="matlabel">Cheque No <br>
                     <input class="ipcss  widthbillcreate" matInput />
                  </mat-label>
               </div>
               <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom">
                  <mat-label class="matlabel">Bank Name <br>
                     <input class="ipcss  widthbillcreate" matInput />
                  </mat-label>
               </div>
            </div>
            <ng-template #mymodal let-modal>
               <div class="dig_table_overflow">
                  <div class="table-responsive">
                     <table id="tbl" class="table table-hover table-dynamic" style="font-size: 11px;">
                        <thead>
                           <tr>
                              <th>Amount</th>
                              <th style="width: 125px;">Paid on</th>
                              <th>Payment mode</th>
                              <th>Remarks</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td></td>
                              <td style="width: 125px;"></td>
                              <td></td>
                              <td></td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </ng-template>
         </mdb-card-body>
      </mdb-card>
   </div>
</div>