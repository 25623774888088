<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Order Detail View</h5>
          </div>
          <div class="headerButtons">
            <a (click)="back_Nav()">
            <img src="../../../assets/ui_icons/buttons/Back_button.svg"
            class="saveimgbtn_inpatinfo"/></a>
            <img printSectionId="print" (click)="printArea()" src="../../../assets/img/printer.svg" *ngIf="printbutton"
              class="saveimgbtn_inpatinfo" style="margin-left:5px;height: 25px;width: 25px;">

              <!-- <img printSectionId="print" (click)="printnonserv()" src="../../../assets/img/printer.svg" *ngIf="printbutton"
              class="saveimgbtn_inpatinfo" style="margin-left:5px;height: 25px;width: 25px;"> -->

              
            <img src="../../../assets/ui_icons/buttons/cancel_button.svg" *ngIf="!cancelBtn"
              class="saveimgbtn_inpatinfo" (click)="cancelProduct()">
            <img src="../../../assets/ui_icons/buttons/Delivery_button.svg" *ngIf="!deliverBtn"
              class="saveimgbtn_inpatinfo" (click)="deliverProduct()">
            <img src="../../../assets/ui_icons/buttons/view_RX_button.svg" *ngIf="!presFlag"
              class="saveimgbtn_inpatinfo" (click)="viewPrescription()">
              
              <img src="../../../assets/ui_icons/buttons/save_button.svg" *ngIf="!confirmBtn" class="saveimgbtn_inpatinfo"
              (click)="confirmProduct()">

              <!--  -->
              <!-- [hidden]="this.printcover_req == '1'" -->
              <img  (click)="printcover()"  src="../../../assets/img/Print_cover.svg" class="saveimgbtn_inpatinfo"> 
              <!-- <img (click)="device_request()" src="../../../assets/ui_icons/buttons/thermal_request.svg" class="saveimgbtn_inpatinfo"> -->
              <!-- <img id="device" src="../../../assets/img/Print_cover.svg" class="saveimgbtn_inpatinfo"> -->
            </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div>
          <!-- <div class="cover_div" style="padding-bottom: 13px;">
            <div class="header_lable">
              Billing details
            </div>
            <div class="content_cover">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel"> <b>{{orderByName}}</b></mat-label><br>
                  <mat-label class="matlabel">Bill number :{{orderNum}}</mat-label><br>
                  <mat-label class="matlabel">MR number:{{mrnumber}}</mat-label><br>
                  <mat-label class="matlabel">Bill type :{{purchaseType}}</mat-label><br>
                  <mat-label class="matlabel" [hidden]="preferredTimeFlag">Preferred time :{{totTime}}</mat-label><br>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel"> Bill date :{{orderDate}}</mat-label><br>
                  <mat-label class="matlabel">Bill time :{{orderTime}}</mat-label><br>
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div">
              <div class="header_lable">
                 Patient details
              </div>
              <div class="content_cover">
                <div class="row">
                  <div class="col-3">
                    <table style="width: 100%;" class="head-table">
                       <tbody>
                          <tr class="head-tr" [hidden]="this.mrnumber == ''">
                             <td class="head-td"
                                style="vertical-align: top;width: 100px; font-weight: 400;"><strong>MR
                                   No</strong></td>
                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                             <td class="head-td">{{mrnumber}}</td>
                          </tr>
                          <tr class="head-tr">
                             <td class="head-td"
                                style="vertical-align: top;width: 100px; font-weight: 400;">
                                <strong>Name</strong>
                             </td>
                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                             <td class="head-td"  [hidden]=" clntsalutation == ''">{{clntsalutation}}. {{orderByName}}</td>
                             <td class="head-td"  [hidden]=" clntsalutation != ''">{{orderByName}}</td>
                          </tr>
                          <tr class="head-tr">
                             <td class="head-td"
                                style="vertical-align: top;width: 100px; font-weight: 400;"><strong>Age
                                   /
                                   Gender</strong></td>
                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                             <td class="head-td">{{age}} / {{headergen}}</td>
                          </tr>
                          <tr class="head-tr" *ngIf="patienttype != 'outpatient'">
                            <td class="head-td"
                               style="vertical-align: top; width: 130px; font-weight: 400;">
                               <strong>Ward name</strong>
                            </td>
                            <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                            <td class="head-td">{{Ward_name}}</td>
                         </tr>

                       </tbody>
                    </table>
                 </div>

                 <div class="col-3" style="width:20%">
                    <table style="width: 100%;" class="head-table">
                       <tbody>
                          <tr class="head-tr">
                             <td class="head-td"
                                style="vertical-align: top;width: 100px; font-weight: 400;">
                                <strong>Mobile</strong>
                             </td>
                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                             <td class="head-td">{{mobileno}}</td>
                          </tr>
                          <tr class="head-tr">
                             <td class="head-td"
                                style="vertical-align: top;width: 100px; font-weight: 400;">
                                <strong>IP/OP</strong>
                             </td>
                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                             <td class="head-td">{{ipop}}</td>
                          </tr>

                          <tr class="head-tr" *ngIf="patienttype != 'outpatient'">
                            <td class="head-td"
                               style="vertical-align: top; width: 130px; font-weight: 400;">
                               <strong>Bed no</strong>
                            </td>
                            <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                            <td class="head-td">{{Bed_no}}</td>
                         </tr>
                         <tr class="head-tr" *ngIf="tokennumberflag">
                          <td class="head-td"
                             style="vertical-align: top;width: 100px; font-weight: 400;">
                             <strong>Token no:</strong>
                          </td>
                          <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                          <td class="head-td">{{tokenno}}</td>
                       </tr>
                       </tbody>
                    </table>
                 </div>

                 <div class="col-3" style="width:20%">
                    <table style="width: 100%;" class="head-table">
                       <tbody>
                          <tr class="head-tr">
                             <td class="head-td"
                                style="vertical-align: top;width: 100px; font-weight: 400;">
                                <strong>Bill type</strong>
                             </td>
                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                             <td class="head-td">{{purchaseType}}</td>
                          </tr>
                          <tr class="head-tr">
                             <td class="head-td"
                                style="vertical-align: top;width: 130px; font-weight: 400;">
                                <strong>Preferred time</strong>
                             </td>
                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                             <td class="head-td">{{totTime}}</td>
                          </tr>


                         <tr class="head-tr" *ngIf='this.ipop == "IP"'>
                          <td class="head-td" [hidden]="this.bill_incl_pharma ==''"
                             style="vertical-align: top;width: 100px; font-weight: 400;">
                             <strong>Bill mode</strong>
                          </td>
                          <td class="head-td" [hidden]="this.bill_incl_pharma ==''" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                          <td class="head-td" [hidden]="this.bill_incl_pharma ==''" style="vertical-align: top;">{{this.bill_incl_pharma}}</td>
                       </tr>
                       </tbody>
                    </table>
                 </div>


                <div class="col-3" style="width:35%">
                <table style="width: 100%;" class="head-table">
                  <tbody>
                    <tr class="head-tr">
                      <td class="head-td"
                         style="vertical-align: top;width: 100px; font-weight: 400;">
                         <strong>Doctor</strong>
                      </td>
                      <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                      <td class="head-td">Dr. {{drName}} <span [hidden]="qualification_flag" style="font-size:11px">{{qualification}}</span> </td>
                   </tr>
                   <tr class="head-tr">
                    <td class="head-td"
                       style="vertical-align: top;width: 150px; font-weight: 400;">
                       <strong>Bill date time</strong>
                    </td>
                    <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                    <td class="head-td" style="vertical-align: top;">{{orderDate}} {{orderTime}}</td>
                 </tr>
                 <tr class="head-tr" *ngIf="patienttype != 'outpatient'">
                  <td class="head-td"
                     style="vertical-align: top; width: 180px; font-weight: 400;">
                     <strong>Admission date time</strong>
                  </td>
                  <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                  <td class="head-td" style="vertical-align: top;">{{admissiondate}}  {{admission_time}}</td>
               </tr>
               <tr class="head-tr" *ngIf="pres_flag">
                <td class="head-td"
                   style="vertical-align: top; width: 180px; font-weight: 400;">
                   <strong>Prescription date time</strong>
                </td>
                <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>

                <td class="head-td" style="vertical-align: top;"><span *ngIf="pres_dayflag">{{prescription_date}}</span> <span *ngIf="pres_timeflag"> {{prescription_time}}</span></td>
              </tr>
                    </tbody>
                  </table>
                </div>
                

              </div>
              </div>
          </div>
          </div>
          
          
          <div class="cover_div" style="padding-bottom: 13px;" [hidden]="addDelivery">
            <div class="header_lable">
              Address details
            </div>
            <div class="content_cover">
              <div class="row" [hidden]="addDelivery">
                <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                  <table [hidden]="addDelivery" id="patienttable" class="table table-hover table-dynamic">
                    <b>Address to deliver</b>
                    <p style="margin-left: 10px;">
                      {{name}}
                      <br />
                      {{address1+","+address2}}
                      <br />
                      {{locationDesc}}
                      <br /> {{cityDesc+ " - " +zipcode}}
                      <br /> {{stateDesc+ " - " +countryDesc}}
                      <br /> {{contactNo}}
                      <br />
                    </p>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="cover_div" style="padding-bottom: 13px;" [hidden]="storeDiv">
            <div class="header_lable">
              Delivery details
            </div>
            <div class="content_cover">
              <div class="row">

                <div class="col-4 col-sm-4 col-md-4 col-lg-2 col-xl-2">
                  <mat-label class="matlabel" [hidden]="storeDiv">Validity Date<br>
                    <input type="text" class=" ipcss" [disabled]="this.type == 'past'" [(ngModel)]="validityDays" #matInput />
                  </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-2 col-xl-2" [hidden]="storeDiv">
                  <mat-label class="matlabel" [hidden]="storeDiv">Validity Time<br>
                    <input type="text" class=" ipcss" [disabled]="this.type == 'past'" [(ngModel)]="availableTo" #matInput />
                  </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-2 col-xl-2" [disabled]="discountValue">
                  <mat-label class="matlabel" [disabled]="discountValue">Rate plan<br>
                    <select disableOptionCentering class="ipcss " [disabled]="discountValue" [(ngModel)]="discType"
                      (change)="discountChange(discType)">
                      <option *ngFor="let loc of discountDatasArray" value={{loc.disc_type_id}}>{{loc.description}} ({{loc.discount_type}})
                      </option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="paytypreqtp">
                  <mat-label class="matlabel"  [disabled]="orderType">Payment type<br>
                    <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="payType"
                      (selectionChange)="changePay()" disableOptionCentering>
                      <mat-option *ngFor="let pay of paytypeList" value={{pay.pay_id}}>{{pay.pay_desc}}</mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                
                <div class="col-12 col-sm-6 col-md-4 col-xl-2 col-lg-2" [hidden]="languagetypeflag">
                  <mat-label class="matlabel">Language<br>
                     <mat-select disableOptionCentering class="ipcss" [(ngModel)]="language"
                        (selectionChange)="languageChange()">
                        <mat-option value="English">English</mat-option>
                        <mat-option value="Tamil">Tamil</mat-option>
                        <mat-option value="Telugu">Telugu</mat-option>
                        <mat-option value="Kannada">Kannada</mat-option>
                        <mat-option value="Malayalam">Malayalam</mat-option>
                        <mat-option value="Oriya">Oriya</mat-option>
                        <mat-option value="Bengali">Bengali</mat-option>
                        <mat-option value="Hindi">Hindi</mat-option>
                     </mat-select>
                  </mat-label>
               </div>

              
               <!-- <div class="col-12 col-sm-6 col-md-4 col-xl-2 col-lg-2" [hidden]="insuranceshowflag" >
                <mat-label class="matlabel">Insurance<br>
                  <mat-select disableOptionCentering class="ipcss order_width" required [(ngModel)]="drugins_allow" (selectionChange)="insurancechg(drugins_allow)">
                    <mat-option selected   value="1">allow</mat-option>
                    <mat-option  value="0">disallow</mat-option>
                  </mat-select>
                </mat-label>
             </div> -->

                <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="insurFlag">
                  <mat-label class="matlabel">Insurer<br>
                    <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="insurerID" disableOptionCentering>
                      <mat-option *ngFor="let ins of insurerList" value={{ins.insurer_id}}>{{ins.insurer_desc}}
                      </mat-option>
                    </mat-select>
                  </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="cardFlag">
                  <mat-label class="matlabel">Card No <br>
                    <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                  </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="cardFlag">
                  <mat-label class="matlabel">Holder name<br>
                    <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
                  </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="tranFlag">
                  <mat-label class="matlabel">Transaction ID<br>
                    <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionID" />
                  </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="chequeFlag">
                  <mat-label class="matlabel">Cheque No <br>
                    <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                  </mat-label>
                </div>
                <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="chequeFlag">
                  <mat-label class="matlabel">Bank Name <br>
                    <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" />
                  </mat-label>
                </div>
                <div [hidden]="delDiv" class="col-12">
                  <table class="tablewidth">
                    <tr>
                      <td>
                        <mat-label class="matlabel">Delivery date </mat-label>
                      </td>
                      <td>
                        <input type="text" [(ngModel)]="deldate" class="ipcss " matInput>
                      </td>
                    </tr>
                  </table>
                  <table class="tablewidth">
                    <tr>
                      <td>
                        <mat-label class="matlabel">Delivery time</mat-label>
                      </td>
                      <td>
                        <input type="text" [(ngModel)]="deliverTime" class="ipcss " matInput>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top:2px" *ngIf="orderType">
            <div class="col-12" *ngIf="billEdit">
              <img style="float:right;" class="pluseIcon" src="../../../assets/ui_icons/Add_icon.svg"
                (click)="addNewDrugDetails()" />
            </div>
          </div>
          <div class="row">
            <div class="col-12" style="margin-top: 15px;">
              <div class="table-resposive  dig_table_overflow" style="margin-bottom: 20px;">
                
                
                <table cellspacing="0" cellpadding="0" *ngIf="drugDetailsArray.length"
                class="table table-nowrap table-sm pharmacycurrentorder">
                <thead class="tableheading">
                  <tr>
                    <th style="width: 45px;">Si.No</th>
                    <th style="width:150px;"></th>
                         
                    <th>Medicine name</th>
                    <th style="width: 12%;">Batch no</th>
                    <th style="width: 8%;"> Quantity</th>
                    <th style="width: 12%;"> Expiry date</th>
                    <th style="width: 10%;"> Price</th>
                    <th style="width: 10%;"> Discount (%)</th>
                    <th style="width: 10%;"> CGST</th>
                    <th style="width: 5%;"> SGST</th>
                    <th style="width: 5%;"> Amount</th>
                    <th *ngIf="ordercreatedetailview == 'past'"></th>
                    <th  *ngIf="orderType && billEdit" > action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let medpre of drugDetailsArray;let i = index">
                    <th >{{i + 1}} </th>
                    <td style="width:150px;text-align:left">
                      <span class="color-square" data-toggle="tooltip" data-placement="top"  [title]="medpre.look_alikevalue"  [hidden]="medpre.look_alike == ''" [ngStyle]="{'background-color': medpre.look_alike, 'position': 'relative','left': '5px'}">
                      </span>
                      <span class="color-square" data-toggle="tooltip" data-placement="top"  [title]="medpre.sound_alikevalue"  [hidden]="medpre.sound_alike == ''" [ngStyle]="{'background-color': medpre.sound_alike, 'position': 'relative','left': '10px'}">
                      </span>
                      <span class="color-square" data-toggle="tooltip" data-placement="top"  [title]="medpre.high_riskvalue" [hidden]="medpre.high_risk == ''" [ngStyle]="{'background-color': medpre.high_risk, 'position': 'relative','left': '15px'}">
                      </span>
                      <span class="color-square" data-toggle="tooltip" data-placement="top"  [title]="medpre.ward_onlyvalue"  [hidden]="medpre.ward_only == ''" [ngStyle]="{'background-color': medpre.ward_only, 'position': 'relative','left':  '20px'}">
                      </span>
                    </td>
                    <td style="text-align: left;">
                      <div *ngIf="medpre.fromRetrivel == true">{{medpre.drug_disp_name}}</div>
                      <div *ngIf="medpre.fromRetrivel == false">

                        <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
                          (keyup)="changeMedicineName($event)" maxlength="50" [(ngModel)]="medpre.drug_name" matInput
                          [matAutocomplete]="auto2" />
                        
                          <mat-autocomplete #auto2="matAutocomplete" [panelWidth]="'auto'">
                          <mat-option (click)="selectMedicineItem(medicine,medpre)"
                            *ngFor="let medicine of MedicineNameArray" value="{{medicine}}">
                            {{medicine}}
                          </mat-option>
                        </mat-autocomplete>
                      </div>
                    </td>
                    <td>
                      <div *ngIf="!orderType">
                        {{medpre.batch_no}}
                      </div>
                     
                      <div *ngIf="orderType"  >
                        <input type="text" class="ipcss order_width" [ngStyle]="medpre.batch_required" required
                          [(ngModel)]="medpre.batch_no" matInput maxlength="50" readonly autocomplete="on"
                          (click)="getCORD($event,'drug_'+medpre.index);medpre.show_batcharray = !medpre.show_batcharray" />
                        
                          <div [id]="'drug_'+medpre.index" class="auto_complete_text1"
                          *ngIf="medpre.batcharray.length != 0" style="display: none;">
                          <table id="tbl_med" class="batchtable" style="width: px;">
                            <thead>
                              <tr>
                                <th> Batch no</th>
                                <th> Qty </th>
                                <th> MRP </th>
                                <th> Exp Date </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let batch of medpre.batcharray"
                                (click)="selectedBatch(batch,medpre,'drug_'+medpre.index)">
                                <td [ngStyle]="{'color':batch.color}">{{batch.batch_no}}</td>
                                <td [ngStyle]="{'color':batch.color}">{{batch.quantity}}</td>
                                <td [ngStyle]="{'color':batch.color}">{{batch.mrp}}</td>
                                <td [ngStyle]="{'color':batch.color}">{{batch.expiry_date}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <!-- <p [hidden]="medpre.batch_hide">{{medpre.batch_no}}</p>
                      <select [(ngModel)]="medpre.batch_no" [hidden]="!medpre.batch_hide" class="ipcss " required
                        style="margin-top: 10px;" (change)="retrieveBatch(medpre)">
                        <option *ngFor="let batch of medpre.batchnos" value={{batch}}> {{batch}}</option>
                      </select> -->
                    </td>
                    <td>
                      <!-- (focus)="clearField(medpre)" -->
                      <input type="text" matInput class="ipcss " [(ngModel)]="medpre.quantity"
                         style="width: 65%;margin: 0 auto !important;" maxlength="5"
                        [hidden]="confirmBtn" class="ipcss "
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        (blur)="comparedrug(medpre.drug_id,medpre)"
                        (keyup)="discountcalculatestaff()">
                        <!-- calculatePrice(medpre.drug_id,medpre.type_name,medpre.drug_name,medpre.quantity,medpre.price,medpre.per_unit,medpre.discount,medpre.index) -->
                        <p [hidden]="!confirmBtn">{{medpre.quantity}} </p>
                    </td>
                    <td>
                      <p style="margin-top: 10px !important;">{{medpre.expiry_date}} </p>
                    </td>
                    <td *ngIf="!confirmFlag">
                      <input type="text" [(ngModel)]="medpre.price" maxlength="10"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" [readonly]="true"  [disabled]="priceflag"
                          style="width: 75%;margin: 0 auto !important;" 
                          (keyup)="calculatePrice(medpre.drug_id,medpre.type_name,medpre.drug_name,medpre.quantity,medpre.price,medpre.per_unit,medpre.discount,medpre.index)">
                    </td>

                    <td *ngIf="!confirmFlag">
                      <input type="text" [(ngModel)]="medpre.discount" maxlength="2" 
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"  [disabled]="lineitmdisflag"
                        style="width: 75%;margin: 0 auto !important;" 
                        (keyup)="discountcalculatestaff()">
                        <!-- (keyup)="calculatePrice(medpre.drug_id,medpre.type_name,medpre.drug_name,medpre.quantity,medpre.price,medpre.per_unit,medpre.discount,medpre.index)"> -->
                    </td>
                    
                    <td *ngIf="confirmFlag">
                      <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                    </td>
                    <td *ngIf="confirmFlag">
                      <p style="float: right; margin-right: 10px;">{{medpre.discount}} </p>
                    </td>
                    <td>
                      <p style="float: right; margin-right: 10px;margin-top: 10px !important;">{{medpre.cgst}} </p>
                    </td>
                    <td>
                      <p style="float: right; margin-right: 10px;margin-top: 10px !important;">{{medpre.sgst}} </p>
                    </td>
                    <td>
                      <p style="float: right; margin-right: 10px;margin-top: 10px !important;">{{medpre.amount}}</p>
                    </td>

                    <td *ngIf="ordercreatedetailview == 'past'">
                      <input type="checkbox" [(ngModel)]="medpre.printcover" (change)="printcoverdata(medpre)">
                    </td>

                    <td style="text-align: center;" *ngIf="orderType && billEdit">
                      <a (click)="deleteMedicine(medpre.drug_id,medpre.index)">
                        <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="20" height="20" /></a>
                    </td>
                  </tr>
                </tbody>
              </table> 
              </div>
            </div>
          </div>
          <div class="row">
            <!-- payment type -->
            <div class="col-0 col-sm-3 col-md-7 col-lg-8 col-xl-8">
              <div class="cover_div" [hidden]="paytypreq">
                <div class="row">
                  <div class="col-2">
                    <mat-label class="matlabel">Credit bill
                      <mat-checkbox (change)="showCreditOption()" color="primary" [(ngModel)]="credit_bill">
                      </mat-checkbox>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4" [hidden]="credit_flag">
                    <div class="row">
                      <div class="col-4">
                        <mat-label class="matlabel">Credit type </mat-label>
                      </div>
                      <div class="col-8">
                        <select class="ipcss" [(ngModel)]="credit_type" disableOptionCentering>
                          <option *ngFor="let credit of hosp_credits" value={{credit.credit_id}}> {{credit.credit_desc}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-5" [hidden]="credit_flag">
                    <input class="ipcss" [(ngModel)]="credit_person" placeholder="name of employee" matInput
                      maxlength="50" />
                  </div>
                </div>
                <div class="row" [hidden]="!credit_flag">
                  <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4 select_bottom">
                    <mat-label class="matlabel">Payment type<br>
                      <select class="ipcss " [(ngModel)]="payType1" disableOptionCentering>
                        <option *ngFor="let pay of payTypeListArrray" value={{pay.pay_id}}>{{pay.pay_desc}}</option>
                      </select>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">
                    <mat-label class="matlabel">Amount<br>
                      <input type=number class="ipcss billing_text_align" style="text-align:right" [(ngModel)]="amountval" matInput
                        maxlength="7" />
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-2 col-md-3 col-lg-2 col-xl-2">
                    <mat-label class="matlabel ">
                      <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                        (click)="addPaymentDetails()" style="margin-top:19px" />
                    </mat-label>
                  </div>
                </div>
                <div class="row">
                  <table>
                    <tr *ngFor="let pay of paymentsarray">
                      <td style="width: 150px !important;">{{pay.pay_desc}}</td>
                      <td style="width: 150px !important;">{{pay.amount}}</td>
                      <td>
                        <img src="../../../assets/img/edit.png" class="billing_edit" (click)="editPayment(pay)">
                        &nbsp;&nbsp;
                        <img src="../../../assets/img/Delete.png" class="billing_edit" (click)="deletePayment(pay)">
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="cover_div_past" [hidden]="paymentpastorder">
                <div class="row">
                  
                <div class="row">
                  <table style="width:50%">
                    <tr *ngFor="let pay of paymentpast_ord_array">
                      <td style="width: 150px !important;">{{pay.pay_desc}}</td>
                      <td style="width: 150px !important;">{{pay.amount}}</td>
                     
                    </tr>
                  </table>
                </div>

              </div>
            </div>
            </div>
            <!-- payment type -->
            <div class="col-0 col-sm-9 col-md-5 col-lg-4 col-xl-4">
              <table class="price_table">
                
                <tr [hidden]="total_billflag">
                  <td class="billamtlabel">
                    <mat-label class="matlabel">
                      <b>Total bill </b>
                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                        style="width: 10px; height: 10px;" />
                    </mat-label>
                  </td>
                  <!-- readonly -->
                  <td class="billamttext" *ngIf="orderType">
                    <input type="text" class="ipcss" 
                      style="border:none !important;text-align: right !important;" [(ngModel)]="totalValue1">
                  </td>
                  <td class="billamttext" *ngIf="!orderType">
                    <mat-label class="matlabel" style="margin-right: 21px;">{{totalValue1}}</mat-label>
                  </td>
                </tr>


                <tr [hidden]="discFlag">
                  <!--  -->
                  <td class="billamtlabel">
                    <mat-label class="matlabel" [hidden]="lineitmdisflag">
                      <b>Discount</b>
                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                        style="width: 10px; height: 10px;" />
                    </mat-label>

                    <mat-label class="matlabel" [hidden]="!lineitmdisflag">
                      <b>Discount</b>
                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                        style="width: 10px; height: 10px;" />{{disperce}}%
                    </mat-label>

                  </td>
                  <td class="billamttext" *ngIf="orderType">
                    <mat-label class="matlabel" style="margin-right: 0px;">{{prodDisc}}</mat-label>
                  </td>
                  
                  <td class="billamttext" *ngIf="!orderType">
                    <mat-label class="matlabel" style="margin-right: 21px;">{{prodDisc}}</mat-label>
                  </td>

                </tr>
 

                <tr [hidden]="GSTamountflag">
                  <td class="billamtlabel">
                    <mat-label class="matlabel">
                      <b>GST</b>
                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                        style="width: 10px; height: 10px;" />
                    </mat-label>
                  </td>
                  <td class="billamttext" *ngIf="orderType">
                    <mat-label class="matlabel" style="margin-right: 0px;">{{GSTamount}}</mat-label>
                  </td>
                  
                  <td class="billamttext" *ngIf="!orderType">
                    <mat-label class="matlabel" style="margin-right: 21px;">{{GSTamount}}</mat-label>
                  </td>
                </tr>


               <tr>       
                  <td class="billamtlabel">
                    <mat-label class="matlabel">
                      <b>Bill amount</b>
                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                        style="width: 10px; height: 10px;" />
                    </mat-label>
                  </td>
                 <!-- readonly -->
                  <td class="billamttext" *ngIf="orderType">
                    <input type="text" class="ipcss" 
                      style="border:none !important;text-align: right !important;" [(ngModel)]="bill_amount">
                  </td>
                  <td class="billamttext" *ngIf="!orderType">
                    <mat-label class="matlabel" style="margin-right: 21px;">{{bill_amount}}</mat-label>
                  </td>
                </tr> 

                <tr [hidden]="viewFlag">
                  <td class="billamtlabel">
                    <mat-label class="matlabel" style="margin-left: 70px">
                      <b>Paid</b>
                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                        style="width: 10px; height: 10px;" />
                    </mat-label>
                  </td>
                  <td class="billamttext" *ngIf="orderType">
                    <input type="number" class="ipcss" style="text-align: right !important;" [(ngModel)]="paid" matInput
                      (keyup)="changeAdvance();changePaid()" (blur)="paidBlur()" (focus)="paidFocus()">
                  </td>
                  <td class="billamttext" *ngIf="!orderType">
                    <mat-label class="matlabel" style="margin-right: 21px;">{{paid}}</mat-label>
                  </td>

                </tr>

                <tr [hidden]="duesponserflag">
                  <td class="billamtlabel">
                    <mat-label class="matlabel" style="margin-left: 70px">
                      <b>Sponsor due</b>
                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                        style="width: 10px; height: 10px;" />
                    </mat-label>
                  </td>
                  <td class="billamttext" *ngIf="orderType">
                    <input type="text" class="ipcss" style="border:none !important;text-align: right !important;" readonly [(ngModel)]="duesponser">
                  </td>
                  <td class="billamttext" *ngIf="!orderType">
                    <mat-label class="matlabel" style="margin-right: 21px;"
                      *ngIf="remaingBalance != 'NaN'  "> {{duesponser}} </mat-label>
                  </td>
                </tr>
               
                <tr [hidden]="viewFlag">
                  <td class="billamtlabel">
                    <mat-label class="matlabel" style="margin-left: 70px">
                      <b>Balance</b>
                      <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                        style="width: 10px; height: 10px;" />
                    </mat-label>
                  </td>
                  <td class="billamttext" *ngIf="orderType">
                    <input type="text" class="ipcss" style="border:none !important;text-align: right !important;" readonly [(ngModel)]="remaingBalance">
                  </td>
                  <td class="billamttext" *ngIf="!orderType">
                    <mat-label class="matlabel" style="margin-right: 21px;"
                      *ngIf="remaingBalance != 'NaN'  "> {{remaingBalance}} </mat-label>
                  </td>
                </tr>
              </table>
            </div>
            <!-- payment type -->
            <!--  -->
          <!--  -->
          </div>
       

          <div [hidden]="true" id="printsmallcover" #printsmallcover>
            <div class="container-fluid m-1">
              <div class="row">
                <div class="col-6">
                  <!-- margin-top:50px -->
                  <div style="width:120px;height:200px;" *ngFor="let drug of printcoverarray">
                    <table class="covertablebig" border="1" style="width:100%;height:200px;margin-left:20px">
                      <thead class="report-header">
                        <tr>
                          <td style="text-align: left;font-size:11px" colspan="4">
                            <b> Name: <span>{{clntsalutation}}. </span> {{orderByName}} </b>
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: left;font-size:11px" colspan="4">
                            <b> MR no: {{this.mrnumber}} </b>
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: left;font-size:11px" colspan="4">
                            <b> Medicine: {{drug.drug_disp_name}} </b>
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: left;font-size:11px" colspan="4">
                            <b> Quantity:{{drug.quantity}} </b>
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: left;font-size:11px" colspan="4">
                            <b> Batch no:{{drug.batch_no}} </b>
                          </td>
                        </tr>
                        <tr>
                          <td style="text-align: left;font-size:11px" colspan="4">
                            <b> Expire date:{{drug.expiry_date}} </b>
                          </td>
                        </tr>
                        <tr [hidden]="drug.alternatsession">
                          <td colspan="4" style="text-align: left;font-size:10px"><b>{{drug.intake_txt}}</b></td>
                        </tr>
                        <tr [hidden]="drug.sessionhide" *ngIf="drug.intake_txt === 'Morning only' || 'Afternoon only' || 'Night only' 
                  || 'Morning & Afternoon' || 'Morning & Evening' ||'Morning & Night' || 'Afternoon & Night'||
                  'Afternoon & Evening'||'Evening & Night' || 'Four times a day'||'Once a week'
                  ||'Alternative day'||'Once in two weeks'||'Once a month'
                  ">
                          <th style="width:25%;font-size:8px">
                            <b>{{'Morning'}}
                              <span>(</span>{{'Morning'|translate}}<span>)</span></b>
                          </th>
                          <th style="width:25%;font-size:8px"><b> {{'Afternoon'}}
                              <span> (</span>{{'Afternoon'|translate}}<span>)</span></b>
                          </th>
                          <th style="width:25%;font-size:8px"><b> {{'Evening'}}
                              <span>(</span>{{'Evening'| translate}}<span>)</span></b>
                          </th>
                          <th style="width:25%;font-size:8px"><b> {{'Night'}}
                              <span>(</span>{{'Night'| translate}}<span>)</span></b>
                          </th>

                        </tr>

                        <tr [hidden]="drug.sessionhrs"
                          *ngIf="drug.intake_txt === 'Every 6 hours'||'SOS'||'Stat'||'Once a day' || 'Every 8 hours' || 'Every 12 hours' ||'SOS' || 'Once a day'">
                          <th colspan="4" style="text-align: left;;font-size:10px">{{drug.intake_txt}}</th>
                        </tr>


                      </thead>

                      <tbody style="height:20px">
                        <tr [hidden]="drug.sessionhide" *ngIf="drug.intake_txt === 'Morning only' || 'Afternoon only' 
                  || 'Night only' || 'Morning & Afternoon' ||'Morning & Evening' ||'Morning & Night' || 'Afternoon & Night'||
                  'Afternoon & Evening'||'Evening & Night' || 'Four times a day'||'Once a week'||
                  'Alternative day'||'Once in two weeks'||'Once a month'
                  ">
                          <td style="text-align: center;;font-size:10px"><b>{{drug.morning}}{{drug.shortname}}</b></td>
                          <td style="text-align: center;;font-size:10px"><b>{{drug.afternoon}}{{drug.shortname}}</b>
                          </td>
                          <td style="text-align: center;font-size:10px"><b>{{drug.evening}}{{drug.shortname}}</b></td>
                          <td style="text-align: center;font-size:10px"><b>{{drug.night}}{{drug.shortname}}</b></td>
                        </tr>

                        <tr [hidden]="drug.sessionhrs"
                          *ngIf="drug.intake_txt === 'Every 6 hours'||'SOS'||'Stat'||'Once a day' || 'Every 8 hours' || 'Every 12 hours' ||'SOS' || 'Once a day'">
                          <td colspan="4" style="font-size:10px;font-size:10px">{{drug.intake_txt}} - {{drug.morning}}</td>
                        </tr>
                      </tbody>

                      <tfoot>
                        <tr style="border:1px solid black">
                          <th style="text-align: left;font-size:10px" colspan="4">
                            {{drug.days}} {{drug.daydur_txt |translate}}
                          </th>

                        </tr>
                        <tr style="border:1px solid black" [hidden]="drug.sessionhide">
                          <th style="text-align: left;font-size:10px" colspan="4" [hidden]="drug.time_duration_flaghide">
                             {{drug.time_duration}} {{'Minutes'|translate}} - {{drug.drug_intake
                             |translate}}
                          </th>
                          <th style="text-align: left;font-size:10px" colspan="4" [hidden]="drug.time_duration_flagshow">
                             {{drug.drug_intake|translate}}
                          </th>

                       </tr>
                      </tfoot>
                    </table>
                  </div>

                </div>
              </div>
              <!-- margin-left:20px !important;
             -->
            </div>
          </div>
          <div [hidden]="true" id="printsmallcovereng" #printsmallcovereng>
            <div class="container-fluid m-1">
            <div class="row">
               <div class="col-6">
               <div style="width:120px;height:200px;" *ngFor="let drug of printcoverarray">


              <table class="covertablebig" border="1" style="width:100%;height:200px;margin-left:20px">
                <thead class="report-header">

                  <tr>
                    <td style="text-align: left;font-size:10px" colspan="4">
                      <b> Name: <span>{{clntsalutation}} </span> {{orderByName}}</b>
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: left;font-size:10px" colspan="4">
                      <b> MR number: {{this.mrnumber}}</b>
                    </td>
                  </tr>
                  <tr>

                    <td style="text-align: left;font-size:10px" colspan="4">
                      <b>Medicine: {{drug.drug_disp_name}}</b>
                    </td>

                  </tr>
                  <tr>

                    <td style="text-align: left;font-size:10px" colspan="4">
                      <b> Quantity:{{drug.quantity}}</b>
                    </td>

                  </tr>
                  <tr>

                    <td style="text-align: left;font-size:10px" colspan="4">
                      <b> Batch no:{{drug.batch_no}}</b>
                    </td>

                  </tr>
                  <tr>
                    <td style="text-align: left;font-size:10px" colspan="4">
                      <b> Expire date:{{drug.expiry_date}}</b>
                    </td>
                  </tr>

                  <tr [hidden]="drug.alternatsession">
                    <td colspan="4" style="text-align: left;font-size:10px"><b>{{drug.intake_txt}}</b></td>
                  </tr>

                  <tr [hidden]="drug.sessionhide" *ngIf="drug.intake_txt === 'Morning only' || 'Afternoon only' || 'Night only' 
                  || 'Morning & Afternoon' ||'Morning & Evening'||'Morning & Night' || 'Afternoon & Night'||
                  'Afternoon & Evening'||'Evening & Night'||'Four times a day'||'Once a week'
                  ||'Alternative day'||'Once in two weeks'||'Once a month'
                  ">
                    <th style="width:25%;font-size:10px">{{'Morning'}}
                    </th>
                    <th style="width:25%;font-size:10px">{{'Afternoon'}}

                    </th>
                    <th style="width:25%;font-size:10px">{{'Evening'}}

                    </th>
                    <th style="width:25%;font-size:10px">{{'Night'}}
                    </th>

                  </tr>

                  <tr [hidden]="drug.sessionhrs"
                    *ngIf="drug.intake_txt  === 'Every 6 hours'||'SOS'||'Stat'||'Once a day' || 'Every 8 hours' || 'Every 12 hours' ||'SOS' || 'Once a day'">
                    <th colspan="4" style="text-align: left;;font-size:10px">{{drug.intake_txt}}</th>
                  </tr>
                </thead>

                <tbody style="height:20px">
                  <tr [hidden]="drug.sessionhide" *ngIf="drug.intake_txt === 'Morning only' || 'Afternoon only' 
                  || 'Night only' || 'Morning & Evening'||'Morning & Afternoon' || 'Morning & Night' || 'Afternoon & Night'||
                  'Afternoon & Evening'||'Evening & Night' || 'Four times a day'||'Once a week'||
                  'Alternative day'||'Once in two weeks'||'Once a month'
                  ">
                    <td style="text-align: center;;font-size:10px">{{drug.morning }}{{drug.shortname}}</td>
                    <td style="text-align: center;;font-size:10px">{{drug.afternoon}}{{drug.shortname}}</td>
                    <td style="text-align: center;font-size:10px">{{drug.evening}}{{drug.shortname}}</td>
                    <td style="text-align: center;font-size:10px">{{drug.night}}{{drug.shortname}}</td>
                  </tr>
                  <tr [hidden]="sessionhrs"
                    *ngIf="drug.intake_txt === 'Every 6 hours'||'SOS'||'Stat'||'Once a day' || 'Every 8 hours' || 'Every 12 hours' ||'SOS' || 'Once a day'">
                    <td colspan="4" style="font-size:10px;font-size:10px">{{drug.intake_txt}} - {{drug.morning}}</td>
                  </tr>
                </tbody>

                <tfoot [hidden]="drug.sessionhide">
                  <tr style="border:1px solid black">
                    <th style="text-align: left;font-size:10px" colspan="4">
                       {{drug.days}} {{drug.daydur_txt}}
                    </th>
                 </tr>
                 <tr style="border:1px solid black">
                    <th style="text-align: left;font-size:10px" colspan="4" [hidden]="drug.time_duration_flaghide">
                       {{drug.time_duration}} {{'Minutes'}} -
                       {{drug.drug_intake}}
                    </th>
                    <th style="text-align: left;font-size:10px" colspan="4" [hidden]="drug.time_duration_flagshow">
                       {{drug.drug_intake}}
                    </th>
                 </tr>
                </tfoot>
              </table>
            </div>

            </div>
            </div>
          </div>
          </div>

<!-- print cover -->

<div [hidden]="true">
  <div [hidden]="true" #printlogowithname id="printlogowithname">
    <div>
      <table style="border:'0';width:100%">                 
        <thead>
          <tr>
          
            <th style="width:100%;">                                 
              <div> 
                <div style="width: 100%;"> 
                  <div style="width: 100%;">
                    <thead class="report-header">
                      <tr>
                        <td style="display: flex;justify-content: center;">
                          <div>
                            <img alt="image" src={{pharmaLogo}} width="100px" height="100px"
                              style="vertical-align: middle;">
                          </div>
                          <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                            <p><b>{{pharmaName}}</b>,</p>
                            <span *ngIf="pharmaAddress1 != undefined">{{pharmaAddress1}},</span><span
                              *ngIf="pharmaAddress2 != undefined">{{pharmaAddress2}},</span><br />
                            <span>{{location}},</span> <span>{{city}},</span>
                            <span>{{state}},</span><span>{{country}}.</span><br>
                            <p><span>DL No : {{dlNO}} &nbsp;&nbsp;</span><span>GST : </span></p>
                          </div>
                        </td>
                      </tr>
                    </thead>
                  </div>                                                             
                  <table style="width: 100%;font-size: 12px;margin-top: 15px;margin-bottom: 5px;border-radius: 10px;margin-left: 15px;"> 
                    <tbody>
                      
                      <tr>
                        <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                        <td>:  <span [hidden]="clntsalutation == ''">{{clntsalutation}}. </span>
                          {{orderByName}} </td>
                          <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                          <td>: <span>Dr. </span> {{drName}} <span [hidden]="qualification_flag" style="font-size:11px">{{qualification}}</span> </td>
                         
                      </tr>
                      <tr>
                        <td style="padding-left: 5px;"><strong>Patient
                          type</strong></td>                             
                        <td>:  {{ipop}} </td> 
                        <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                        <td>: {{orderNum}}</td>
                      </tr>
                      <tr>
                        <td style="padding-left: 5px;" >
                          <strong>Age/Gender</strong></td>
                        <td>: {{age}} / {{headergen}}</td>
                        <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                        <td>: {{orderDate}}&nbsp;{{orderTime}}</td>
                      </tr>
                        <tr>
                   

                        <td style="padding-left: 5px;" *ngIf="patienttype != 'outpatient'"><strong>Ward name / bed no
                        </strong></td>                             
                      <td *ngIf="patienttype != 'outpatient'">:   {{Ward_name}}/
                        {{Bed_no}} </td> 
                      
                        <td style="padding-left: 5px;" *ngIf="patienttype != 'outpatient'"><strong>Admission date / time</strong></td>                             
                        <td >:   {{admissiondate}}  {{admission_time}}</td> 
                       </tr>
                       <tr>
                        <td style="padding-left: 5px;vertical-align:top" [hidden]='mobileno == ""'><strong>Mobile
                          number</strong></td>                             
                        <td style="vertical-align:top" [hidden]='mobileno == ""'>:  {{mobileno}} </td> 
                        <td style="padding-left: 5px;vertical-align: top !important" *ngIf="pres_flag"><strong>Prescription date/Time</strong></td>                             
                        <td style="vertical-align: top !important" *ngIf="pres_flag">:  <span *ngIf="pres_dayflag">{{prescription_date}}</span>/<span *ngIf="pres_timeflag">{{prescription_time}}</span></td> 
                       </tr>
                       <tr  >
                        <td  style="padding-left: 5px;vertical-align: top !important;"><strong><p [hidden]='bill_pay_type_req == "0"'>Payment
                          type</p></strong></td>                             
                        <td ><p [hidden]='bill_pay_type_req == "0"'>:  {{payDes}} </p></td> 
                        <td><br></td>
                        <td  [ngStyle]="getbarcode()" [hidden]="mrnumber == ''"> 
                          <img alt="image" [hidden]="barcode_logo == ''" src={{barcode_logo}} style="width: 157px;height: 35px;position:relative;right: 20px;">
                          <!-- <ngx-barcode class="barcode" [bc-value]="mrnumber" [bc-display-value]="displayValue"
                          [bc-line-color]="lineColor" [element-type]="elementType" [format]="format"
                          [bc-width]="width" [bc-height]="barcodeheight" [bc-font-options]="fontOptions"
                          [bc-font]="font" [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                          [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                          [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                          [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode> -->
                      
                          </td>
                       </tr>
                    </tbody>
                  </table>

                </div>
              </div>               
            </th>
          </tr>
        </thead>                       
        <tbody>  
          <tr>  
            <td width="100%">  
              <p style="text-align:center"><b>{{pagetype}}</b></p>
              <div>
              <div>                
                <div  style="margin-left: 20px;margin-right: 10px;">                      
                  <div class="row">
                    <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;width:95%">
                      <thead>
                        <tr style="border:1px solid black;">
                          <th style="border:1px solid black;text-align:center">Si.No</th>

                          <th style="border:1px solid black;text-align:center" [hidden]="this.show_hsn == '0'">HSN No</th>
                          <th style="border:1px solid black;text-align:center">Medicine name</th>
                          <th style="border:1px solid black;text-align:center">Qty</th>
                          <th style="border:1px solid black;text-align:center">Batch No</th>
                          <th style="border:1px solid black;text-align:center">Exp date</th>
                          <th style="border:1px solid black;text-align:center">Price</th>
                          <th style="border:1px solid black;text-align:center" [hidden]="show_gst == 0">CGST(%)</th>
                          <th style="border:1px solid black;text-align:center" [hidden]="show_gst == 0">SGST(%)</th>
                          <th style="border:1px solid black;text-align:center" [hidden]="show_disc == '0' || this.rateplanetype == 'Product wise'">Discount</th>
                          <th style="border:1px solid black;text-align:center"> Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let billOrder of drugDetailsArray;let i = index" style="border:1px solid">
                          <td style="border:1px solid black;">{{i+1}}</td>
                          <td style="border:1px solid black;" [hidden]="this.show_hsn == '0'"> {{billOrder.hsn_no}}</td>
                          <td style="border:1px solid black;"> {{billOrder.drug_disp_name}}</td>
                          <td style="border:1px solid black;">
                            <p style="float: right; margin-right: 10px;"> {{billOrder.quantity}} </p>
                          </td>
                          <td style="border:1px solid black;">
                            <p style="float: right; margin-right: 10px;"> {{billOrder.batch_no}} </p>
                          </td>
                          <td style="border:1px solid black;">
                            <p style="float: right; margin-right: 10px;"> {{billOrder.expiry_date }}</p>
                          </td>
                          <td style="border:1px solid black;">
                            <p style="float: right; margin-right: 0px;"> {{billOrder.price }}</p>
                          </td>
                          <td style="border:1px solid black;" [hidden]="show_gst == 0">
                            <p style="float: right; margin-right: 0px;"> {{billOrder.cgst}} </p>
                          </td>
                          <td style="border:1px solid black;" [hidden]="show_gst == 0">
                            <p style="float: right; margin-right: 0px;"> {{billOrder.sgst}} </p>
                          </td>
                          <td style="border:1px solid black;" [hidden]="show_disc == '0' || this.rateplanetype == 'Product wise'">
                        
                            <p style="float: right; margin-right: 0px;" > {{billOrder.discount}} </p>
                          </td>
                          <td style="border:1px solid black;text-align:right">
                            <p style="float: right; margin-right: 0px;"> {{billOrder.amount}} </p>
                          </td>
                        </tr>     

                      </tbody>                                         
                    </table>

                  </div>
                </div>                   
                <!-- <div>
                  
                  <div class="main_class row">
                    
                  </div>
                </div>                    -->
                <div style="width: 100%;">
                  <table style="margin-bottom: -20px;">
                    <tr>
                      <td style="vertical-align: top; width: 26%;" [hidden]='bill_pay_type_req == "1"'>
                        <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 15px;">
                          <tr *ngFor="let pay of paymentsarray">
                            <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                            <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                          </tr>
                        </table>                                                       
                      </td>
                         
                      <td style="vertical-align: top;width: 26%;padding-left: 5px !important;" [hidden]="amountinwordflag"><strong>In Words:</strong>&nbsp;Received Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                      <td style="vertical-align: top;width: 31%;">
                    
                        <table  [ngStyle]="getClass()" >
                          <tr [hidden]="totalamountfalg == true">
                            <th style="text-align: left;">Total (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                              class="fee_txt">
                              {{totalValue1}}
                            </td>
                          </tr>
                        
                          <tr [hidden]="prodDisc == '0.00'">
                            <th style="text-align: left;"[hidden]="lineitmdisflag">Discount <span [hidden]=""></span> (र) </th>
                            <th style="text-align: left;"[hidden]="!lineitmdisflag">Discount ({{disperce}}%) <span [hidden]=""></span> (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                              {{prodDisc}}
                            </td>
                          </tr>
                          <tr [hidden]="GSTamount == 0">
                            <th style="text-align: left;">GST(र) </th>
                            <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                              {{this.GSTamount}}
                            </td>
                          </tr>
                          <tr [hidden]="duesponser == '0.00'">
                            <th style="text-align: left;">Sponsor due(र) </th>
                            <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                              {{this.duesponser}}
                            </td>
                          </tr>

                          <tr [hidden]="paidprintflag">
                            <th style="text-align: left;">Paid (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                              class="fee_txt">
                              {{this.paid}}
                            </td>
                          </tr>
                          <tr [hidden]="remaingBalance == '0.00'">
                            <th style="text-align: left;">Balance (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                              class="fee_txt">
                              {{this.remaingBalance}}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
                <div  style="width: 100%;margin-left: 20px;margin-top: 15px;" [hidden]="receivedflag">
                  <div class="row">
                    <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;" >
                      <tr>
                        <td><strong>In Words:</strong>&nbsp;Received Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                      </tr>
                    </table>
                  </div>                   
                </div>
              </div>              
            </div>                
            </td>
          </tr>
        </tbody>
        <tfoot style="margin-bottom: 10px;"> 
          <tr>  
            <td width="100%">  
              <table style="width: 100%;">  
              <tr>  
                  <td style="vertical-align: top;width:50%;">
                    <table style="float: left !important;margin-left: 15px;">
                      <!-- <tr>
                        <td>&nbsp;</td>
                      </tr> --> 
                    </table> 
                  </td>  
                  <td style="vertical-align: top;width:50%;">
                    <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                      <!-- <tr>
                        <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                          <p style="margin-bottom: 3px !important;text-align: center;"></p>
                          <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                        </div>                       
                      </tr> -->
                    </table>                      
                  </td>
                </tr>  
              </table> 
            </td>
          </tr> 
         </tfoot>    
      </table>
    </div>
  </div>  
  <div [hidden]="true" #printbanner id="printbanner">
    <div>
      <table style="border:'0';width:100%">                 
        <thead>
          <tr>
          
            <th style="width:100%;">                                 
              <div> 
                <div style="width: 100%;"> 
                  <div style="width: 100%;">
                    <img alt="image" src={{pharmaLogo}}  [style]="printlogostyle">
                  </div>                                                             
                  <table style="width: 100%;font-size: 12px;margin-top: 15px;margin-bottom: 5px;border-radius: 10px;margin-left: 15px;"> 
                    <tbody>
                      
                      <tr>
                        <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                        <td>:  <span [hidden]="clntsalutation == ''">{{clntsalutation}}. </span>
                          {{orderByName}} </td>
                          <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                          <td>: <span>Dr. </span> {{drName}} <span [hidden]="qualification_flag" style="font-size:11px">{{qualification}}</span> </td>
                         
                      </tr>
                      <tr>
                        <td style="padding-left: 5px;"><strong>Patient
                          type</strong></td>                             
                        <td>:  {{ipop}} </td> 
                        <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                        <td>: {{orderNum}}</td>
                      </tr>
                      <tr>
                        <td style="padding-left: 5px;" >
                          <strong>Age/Gender</strong></td>
                        <td>: {{age}} / {{headergen}}</td>
                        <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                        <td>: {{orderDate}}&nbsp;{{orderTime}}</td>
                      </tr>
                        <tr>
                   

                        <td style="padding-left: 5px;" *ngIf="patienttype != 'outpatient'"><strong>Ward name / bed no
                        </strong></td>                             
                      <td *ngIf="patienttype != 'outpatient'">:   {{Ward_name}}/
                        {{Bed_no}} </td> 
                      
                        <td style="padding-left: 5px;" *ngIf="patienttype != 'outpatient'"><strong>Admission date / time</strong></td>                             

                        <td *ngIf="patienttype != 'outpatient'">:   {{admissiondate}}  {{admission_time}}</td> 
                       </tr>
                       <tr>
                        <td style="padding-left: 5px;vertical-align:top" [hidden]='mobileno == ""'><strong>Mobile
                          number</strong></td>                             
                        <td style="vertical-align:top" [hidden]='mobileno == ""'>:  {{mobileno}} </td> 
                        <td style="padding-left: 5px;vertical-align: top !important" *ngIf="pres_flag"><strong>Prescription date/Time</strong></td>                             
                        <td style="vertical-align: top !important" *ngIf="pres_flag">:  <span *ngIf="pres_dayflag">{{prescription_date}}</span>&nbsp;<span *ngIf="pres_timeflag">{{prescription_time}}</span></td> 
                       </tr>
                       <tr  >
                        <td  style="padding-left: 5px;vertical-align: top !important;"><strong><p [hidden]='bill_pay_type_req == "0"'>Payment
                          type</p></strong></td>                             
                        <td ><p [hidden]='bill_pay_type_req == "0"'>:  {{payDes}} </p></td> 
                        <td><br></td>
                        <td  [ngStyle]="getbarcode()" [hidden]="mrnumber == ''"> 
                          <img alt="image" [hidden]="barcode_logo == ''" src={{barcode_logo}} style="width: 157px;height: 35px;position:relative;right: 20px;">
                          <!-- <ngx-barcode class="barcode" [bc-value]="mrnumber" [bc-display-value]="displayValue"
                          [bc-line-color]="lineColor" [element-type]="elementType" [format]="format"
                          [bc-width]="width" [bc-height]="barcodeheight" [bc-font-options]="fontOptions"
                          [bc-font]="font" [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                          [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                          [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                          [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode> -->
                      
                          </td>
                       </tr>
                    </tbody>
                  </table>

                </div>
              </div>               
            </th>
          </tr>
        </thead>                       
        <tbody>  
          <tr>  
            <td width="100%">  
              <p style="text-align:center"><b>{{pagetype}}</b></p>
              <div>
              <div >                
                <div  style="margin-left: 20px;margin-right: 10px;">                      
                  <div class="row">
                    <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;width:95%">
                      <thead>
                        <tr style="border:1px solid black;">
                          <th style="border:1px solid black;text-align:center">Si.No</th>

                          <th style="border:1px solid black;text-align:center" [hidden]="this.show_hsn == '0'">HSN No</th>
                          <th style="border:1px solid black;text-align:center">Medicine name</th>
                          <th style="border:1px solid black;text-align:center">Qty</th>
                          <th style="border:1px solid black;text-align:center">Batch No</th>
                          <th style="border:1px solid black;text-align:center">Exp date</th>
                          <th style="border:1px solid black;text-align:center">Price</th>
                          <th style="border:1px solid black;text-align:center" [hidden]="show_gst == 0">CGST(%)</th>
                          <th style="border:1px solid black;text-align:center" [hidden]="show_gst == 0">SGST(%)</th>
                          <th style="border:1px solid black;text-align:center" [hidden]="show_disc == '0' || this.rateplanetype == 'Product wise'">Discount</th>
                          <th style="border:1px solid black;text-align:center"> Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let billOrder of drugDetailsArray;let i = index" style="border:1px solid">
                          <td style="border:1px solid black;">{{i+1}}</td>
                          <td style="border:1px solid black;" [hidden]="this.show_hsn == '0'"> {{billOrder.hsn_no}}</td>
                          <td style="border:1px solid black;"> {{billOrder.drug_disp_name}}</td>
                          <td style="border:1px solid black;">
                            <p style="float: right; margin-right: 10px;"> {{billOrder.quantity}} </p>
                          </td>
                          <td style="border:1px solid black;">
                            <p style="float: right; margin-right: 10px;"> {{billOrder.batch_no}} </p>
                          </td>
                          <td style="border:1px solid black;">
                            <p style="float: right; margin-right: 10px;"> {{billOrder.expiry_date }}</p>
                          </td>
                          <td style="border:1px solid black;">
                            <p style="float: right; margin-right: 0px;"> {{billOrder.price }}</p>
                          </td>
                          <td style="border:1px solid black;" [hidden]="show_gst == 0">
                            <p style="float: right; margin-right: 0px;"> {{billOrder.cgst}} </p>
                          </td>
                          <td style="border:1px solid black;" [hidden]="show_gst == 0">
                            <p style="float: right; margin-right: 0px;"> {{billOrder.sgst}} </p>
                          </td>
                          <td style="border:1px solid black;" [hidden]="show_disc == '0' || this.rateplanetype == 'Product wise'">
                        
                            <p style="float: right; margin-right: 0px;" > {{billOrder.discount}} </p>
                          </td>
                          <td style="border:1px solid black;text-align:right">
                            <p style="float: right; margin-right: 0px;"> {{billOrder.amount}} </p>
                          </td>
                        </tr>     

                      </tbody>                                         
                    </table>

                  </div>
                </div>                   

                <!--<div>
                  
                  <div class="main_class row">
                    
                  </div>

                </div>-->
                <div style="width: 100%;">
                  <table style="margin-bottom: -20px;">
                    <tr>
                      <td style="vertical-align: top; width: 26%;" [hidden]='bill_pay_type_req == "1"'>
                        <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 15px;">
                          <tr *ngFor="let pay of paymentsarray">
                            <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                            <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                          </tr>
                        </table>                                                       
                      </td>
                     
                      <td style="vertical-align: top !important;width: 26%;padding-left: 5px !important;" [hidden]="amountinwordflag"><strong>In Words:</strong>&nbsp;Received Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                      <td style="vertical-align: top;width: 31%;">
                         
                      
                        <table  [ngStyle]="getClass()" >
                          
                          <tr [hidden]="totalamountfalg == true">
                            <th style="text-align: left;">Total (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                              class="fee_txt">
                              {{totalValue1}}
                            </td>
                          </tr>
                       
                          <tr [hidden]="prodDisc == '0.00'">
                            <th style="text-align: left;"[hidden]="lineitmdisflag">Discount <span [hidden]=""></span> (र) </th>
                            <th style="text-align: left;"[hidden]="!lineitmdisflag">Discount ({{disperce}}%) <span [hidden]=""></span> (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                              {{prodDisc}}
                            </td>
                          </tr>
                          <tr [hidden]="GSTamount == 0">
                            <th style="text-align: left;">GST(र) </th>
                            <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                              {{this.GSTamount}}
                            </td>
                          </tr>
                          <tr [hidden]="totalAmt == '0.00'">
                            <th style="text-align: left;">Bill amount (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                              class="fee_txt">
                              {{totalAmt}}
                            </td>
                          </tr>
                          <tr [hidden]="duesponser == '0.00'">
                            <th style="text-align: left;">Sponsor due(र) </th>
                            <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                              {{this.duesponser}}
                            </td>
                          </tr>
             
                          <tr [hidden]="paidprintflag">
                            <th style="text-align: left;">Paid (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                              class="fee_txt">
                              {{this.paid}}
                            </td>
                          </tr>
                          <tr [hidden]="remaingBalance == '0.00'">
                            <th style="text-align: left;">Balance (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                              class="fee_txt">
                              {{this.remaingBalance}}
                            </td>
                          </tr>
                        
 
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
                <div  style="width: 100%;margin-left: 20px;margin-top: 15px;" [hidden]="receivedflag">
                  <div class="row">
                    <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;" >
                      <tr>
                        <td><strong>In Words:</strong>&nbsp;Received Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                      </tr>
                    </table>
                  </div>                   
                </div>
              </div>              
            </div>                
            </td>
          </tr>
        </tbody>
        <tfoot style="margin-bottom: 10px;"> 
          <tr>  
            <td width="100%">  
              <table style="width: 100%;">  
              <tr>  
                  <td style="vertical-align: top;width:50%;">
                    <table style="float: left !important;margin-left: 15px;">
                      <!-- <tr>
                        <td>&nbsp;</td>
                      </tr> --> 
                    </table> 
                  </td>  
                  <td style="vertical-align: top;width:50%;">
                    <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                      <!-- <tr>
                        <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                          <p style="margin-bottom: 3px !important;text-align: center;"></p>
                          <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                        </div>                       
                      </tr> -->
                    </table>                      
                  </td>
                </tr>  
              </table> 
            </td>
          </tr> 
         </tfoot>    
      </table>
    </div>
  </div>  
  <div [hidden]="true" #printnoheader id="printnoheader">
    <div class="noheader_cls">
      <table style="border:'0';width:100% !important">             
        <thead>
          <tr>
            <th style="width:100% !important;">
              <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                <div style="width:100% !important;">
                  <div style="height: 5px !important;"></div>
                  <table style="width: 100%;font-size: 12px;border-radius: 10px;height: 85px;"> 
                    <tbody>
                      <tr>
                        <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                        <td>:  <span [hidden]="clntsalutation == ''">{{clntsalutation}}. </span>
                          {{orderByName}} </td>
                          <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                          <td>: <span>Dr. </span> {{drName}} <span [hidden]="qualification_flag" style="font-size:11px">{{qualification}}</span> </td>
                         
                      </tr>
                      <tr>
                        <td style="padding-left: 5px;"><strong>Patient
                          type</strong></td>                             
                        <td>:  {{ipop}} </td> 
                        <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                        <td>: {{orderNum}}</td>
                      </tr>
                      <tr>
                        <td style="padding-left: 5px;" >
                          <strong>Age/Gender</strong></td>
                        <td>: {{age}} / {{headergen}}</td>
                        <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                        <td>: {{orderDate}}&nbsp;{{orderTime}}</td>
                      </tr>
                        <tr>
                   

                        <td style="padding-left: 5px;" *ngIf="ipop != 'OP'"><strong>Ward name / bed no
                        </strong></td>                             
                      <td *ngIf="ipop != 'OP'">:   {{Ward_name}}/
                        {{Bed_no}} </td> 
                       
                        <td style="padding-left: 5px;" *ngIf="ipop != 'OP'"><strong>Admission date</strong></td>                             
                        <td *ngIf="ipop != 'OP'">:  {{admissiondate}}  {{admission_time}}</td> 
                       </tr>
                       <tr>
                        <td style="padding-left: 5px;vertical-align:top" [hidden]='mobileno == ""'><strong>Mobile
                          number</strong></td>                             
                        <td style="vertical-align:top" [hidden]='mobileno == ""'>:  {{mobileno}} </td> 
                        <td style="padding-left: 5px;vertical-align: top !important" *ngIf="pres_flag"><strong>Prescription date/Time</strong></td>                             
                        <td style="vertical-align: top !important" *ngIf="pres_flag">:  <span *ngIf="pres_dayflag">{{prescription_date}}</span>&nbsp;<span *ngIf="pres_timeflag">{{prescription_time}}</span></td> 
                       </tr>
                        <tr  >
                          <td  style="padding-left: 5px;vertical-align: top !important;" [hidden]='bill_pay_type_req == "0"'><strong><p >Payment
                            type</p></strong></td>                             
                          <td [hidden]='bill_pay_type_req == "0"'><p >:  {{payDes}} </p></td> 
                          <td [hidden]='this.bill_incl_pharma ==""' style="padding-left: 5px;vertical-align: top !important;">
                            <strong><p >Bill mode</p></strong>
                          </td>
                          <td [hidden]='this.bill_incl_pharma ==""'>
                            <p>:  {{bill_incl_pharma}} </p>
                            </td>
                         </tr>
                         <tr>
                          <td>
                            </td>
                            <td>
                              </td>
                              <td><br></td>
                              <td  [ngStyle]="getbarcode()" [hidden]="mrnumber == ''"> 
                                <img alt="image" [hidden]="barcode_logo == ''" src={{barcode_logo}} style="width: 157px;height: 35px;position:relative;right: 20px;">
                                <!-- <ngx-barcode class="barcode" [bc-value]="mrnumber" [bc-display-value]="displayValue"
                                [bc-line-color]="lineColor" [element-type]="elementType" [format]="format"
                                [bc-width]="width" [bc-height]="barcodeheight" [bc-font-options]="fontOptions"
                                [bc-font]="font" [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                                [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                                [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                                [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode> -->
                            
                                </td>
                          </tr>
                         
                    </tbody>
                  </table>
                </div>
              </div>
            </th>
          </tr>
        </thead>       
        <tbody>  
          <tr>  
            <td width="100%"> 
              <p style="text-align: center;font-size: 16px;margin: 0px;margin-bottom: 3px;margin-right: 10px;"><b></b></p> 
            <div class="bill_border">
              <div>   
                <div  style="margin-left: 20px;margin-right: 10px;">                      
                  <div class="row">
                    <p style="text-align:center"><b>{{pagetype}}</b></p>
                    <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;width:97%">
                      <thead>
                        <tr style="border:1px solid black;">
                          <th style="border:1px solid black;text-align:center">Si.No</th>

                          <th style="border:1px solid black;text-align:center" [hidden]="this.show_hsn == '0'">HSN No</th>
                          
                          <th style="border:1px solid black;text-align:center">Medicine name</th>
                          <th style="border:1px solid black;text-align:center">Qty</th>
                          <th style="border:1px solid black;text-align:center">Batch No</th>
                          <th style="border:1px solid black;text-align:center">Exp date</th>
                          <th style="border:1px solid black;text-align:center">Price</th>
                          <th style="border:1px solid black;text-align:center" [hidden]="show_gst == 0">CGST(%)</th>
                          <th style="border:1px solid black;text-align:center" [hidden]="show_gst == 0">SGST(%)</th>
                          <th style="border:1px solid black;text-align:center" [hidden]="show_disc == '0' || this.rateplanetype == 'Product wise'">Discount</th>
                          <th style="border:1px solid black;text-align:center"> Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let billOrder of drugDetailsArray;let i = index" style="border:1px solid">
                          <td style="border:1px solid black;">{{i+1}}</td>
                          <td style="border:1px solid black;" [hidden]="this.show_hsn == '0'"> {{billOrder.hsn_no}}</td>
                         
                          <td style="border:1px solid black;"> {{billOrder.drug_disp_name}}</td>
                          <td style="border:1px solid black;">
                            <p style="float: right; margin-right: 10px;"> {{billOrder.quantity}} </p>
                          </td>
                          <td style="border:1px solid black;">
                            <p style="float: right; margin-right: 10px;"> {{billOrder.batch_no}} </p>
                          </td>
                          <td style="border:1px solid black;">
                            <p style="float: right; margin-right: 10px;"> {{billOrder.expiry_date }}</p>
                          </td>
                          <td style="border:1px solid black;">
                            <p style="float: right; margin-right: 0px;"> {{billOrder.price }}</p>
                          </td>
                          <td style="border:1px solid black;" [hidden]="show_gst == 0">
                            <p style="float: right; margin-right: 0px;"> {{billOrder.cgst}} </p>
                          </td>
                          <td style="border:1px solid black;" [hidden]="show_gst == 0">
                            <p style="float: right; margin-right: 0px;"> {{billOrder.sgst}} </p>
                          </td>
                          <td style="border:1px solid black;" [hidden]="show_disc == '0' || this.rateplanetype == 'Product wise'">
                        
                            <p style="float: right; margin-right: 0px;" > {{billOrder.discount}} </p>
                          </td>
                          <td style="border:1px solid black;text-align:right">
                            <p style="float: right; margin-right: 0px;"> {{billOrder.amount}} </p>
                          </td>
                        </tr>     
                      </tbody>                                                                 
                    </table>
                  </div>
                </div>
                
                <div style="width: 100%;">
                  <table style="margin-bottom: -20px;">
                    <tr>
                      <td style="vertical-align: top; width: 26%;" [hidden]='bill_pay_type_req == "1"'>
                        <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;margin-left: 15px;">
                          <tr *ngFor="let pay of paymentsarray">
                            <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                            <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                          </tr>
                        </table>                                                       
                      </td>
                    
                      <td style="vertical-align: top;width: 26%;" [hidden]="amountinwordflag"><strong>In Words:</strong>&nbsp;Received Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                      <td style="vertical-align: top;width: 31%;">
                        <!-- style="float: right;margin-left: 80px;margin-right: 25px;" -->
                        <table  [ngStyle]="getClass()">
                          <tr [hidden]="totalamountfalg == true">
                            <th style="text-align: left;">Total (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                              class="fee_txt">
                              {{totalValue1}}
                            </td>
                          </tr>
                        
                          <tr [hidden]="prodDisc == '0.00'">
                            <th style="text-align: left;"[hidden]="lineitmdisflag">Discount <span [hidden]=""></span> (र) </th>
                            <th style="text-align: left;"[hidden]="!lineitmdisflag">Discount ({{disperce}}%) <span [hidden]=""></span> (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                              {{prodDisc}}
                            </td>
                          </tr>
                          <tr [hidden]="GSTamount == 0">
                            <th style="text-align: left;">GST(र) </th>
                            <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                              {{this.GSTamount}}
                            </td>
                          </tr>
                          <tr [hidden]="totalAmt == '0.00'">
                            <th style="text-align: left;">Bill amount (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                              class="fee_txt">
                              {{totalAmt}}
                            </td>
                          </tr>
                          <tr [hidden]="duesponser == '0.00'">
                            <th style="text-align: left;">Sponsor due(र) </th>
                            <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                              {{this.duesponser}}
                            </td>
                          </tr>
                    
                          <tr [hidden]="paidprintflag">
                            <th style="text-align: left;">Paid (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                              class="fee_txt">
                              {{this.paid}}
                            </td>
                          </tr>
                          <tr [hidden]="remaingBalance == '0.00'">
                            <th style="text-align: left;">Balance (र)</th>
                            <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                              class="fee_txt">
                              {{this.remaingBalance}}
                            </td>
                          </tr>
                        </table> 
                      </td>
                    </tr>
                  </table>
                </div>
                <div  style="width: 100%;margin-left: 20px;margin-top: 15px;" [hidden]="receivedflag">
                  <div class="row">
                    <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;">
                      <tr>
                        <td><strong>In Words:</strong>&nbsp;Received Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                      </tr>
                    </table>
                  </div>                   
                </div>
              </div>              
            </div>                
            </td>
          </tr>
        </tbody>
        <tfoot style="margin-bottom: 10px;"> 
          
        </tfoot>  
      </table>
    </div>        
  </div>
</div>

        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>

