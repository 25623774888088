import { Component, OnInit ,ViewChild , ElementRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { ipaddress } from '../../ipaddress' ;import { MatDialog } from '@angular/material/dialog';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { FrontDeskService } from '../front-desk/front-desk.service';
import { Message_data } from 'src/assets/js/Message_data';
declare var $:any;
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Time_Formate, convertNumberToWords } from 'src/assets/js/common';
import { Helper_Class } from '../../helper_class';

@Component({
  selector: 'app-billing-diag-view',
  templateUrl: './billing-diag-view.component.html',
  styleUrls: ['./billing-diag-view.component.scss']
})
export class BillingDiagViewComponent implements OnInit {
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  public billingId: string;
  public diagCenterId;
  public providerType;
  public billingPrintView = []; 
  public cgstFlag;
  public sgstFlag;
  public billingDetialedView;
  public billPrintconcse: boolean;
  public totalFlag: boolean;
  public billAdvanceFlag: boolean;
  public prevBalance: boolean;
  public prevBalanceFlag: boolean;
  public mainBalanceFlag: boolean;
  public estimatePrintData;
  public treatmentPlanFlag: boolean;
  public charge = [];
  public hospAddress: boolean;
  public billAmount: any;
  public totalBill: string;
  public centralGST: any;
  public stateGST: any;
  public billingDetailList = [];
  public clientName: string;
  public doctorName: string;
  dtOptions: DataTables.Settings = {};
  private finyear;
  public profile_image: string;
  public ageval: any;
  public genderval: any;
  public mobile;
  public inpatientflag:boolean=false;
  public prevBalFlag: boolean;
  public prevBlance;
  public totalBillAmount: any;
  public discount_amount: any;
  public paid;
  public paymentsarray:any=[];
  public main_balshow: boolean;
  public credit_type: string;
  public credit_person:string;
  public clientaddress1: string;
  public clientaddress2: string;
  public paytypreq:boolean=false;
  public concession: any;
  public totalShow: boolean;
  public type: any;
  public hospitalLogo;
  public printtemplate: string;
  public print_style: string;
  public print_size: any;
  public billingPrintViewFlag: boolean;
  public billingDetails = [];
  public numToWords: string;
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public barcode_flag:boolean = false;
  public displayValue = true;
  public barcode_url;
  public barcode_style: string;
  public nobanner_style;
  public discount_flag:boolean = false;
  public bill_qty_flag: boolean=false;
  public logUserName;
  public inpatient_flag:boolean = false;
  public multiply_flag: boolean = true;
  public amount_td_style;
  public p_tag_style: any;
  public diag_center_id;
  lineColor = '#000000';
  elementType: 'svg';
  format:
    | ''
    | 'CODE128'
    | 'CODE128A'
    | 'CODE128B'
    | 'CODE128C'
    | 'EAN'
    | 'UPC'
    | 'EAN8'
    | 'EAN5'
    | 'EAN2'
    | 'CODE39'
    | 'ITF14'
    | 'MSI'
    | 'MSI10'
    | 'MSI11'
    | 'MSI1010'
    | 'MSI1110'
    | 'pharmacode'
    | 'codabar';
  width = 1.0;
  barcodeheight = 23;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 15;
  background = '#ffffff';

  constructor(public gservice:CommonDataService,public http:HttpClient,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService,  public messageservice:MenuViewService,public dialog:MatDialog) {
    this.discount_flag = false;
   }
 
  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.profile_image = "../../assets/img/default.jpg";
    this.billingPrintViewFlag = true;
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2,] },
      ],
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };
    if (FrontDesk_Helper.getmodules() != null && FrontDesk_Helper.getmodules() != undefined) {
      for (var i = 0; i < FrontDesk_Helper.getmodules().length; i++) {
        if (FrontDesk_Helper.getmodules()[i].module_id == "92") {
          if (FrontDesk_Helper.getmodules()[i].edit == "1") {
            this.editbutton = true;
          }
          if (FrontDesk_Helper.getmodules()[i].create == "1") {
            this.newbutton = true;
          }
          if (FrontDesk_Helper.getmodules()[i].delete == "1") {
            this.deletebutton = true;
          }
          if (FrontDesk_Helper.getmodules()[i].print == "1") {
            this.printbutton = true;
          }
          if (FrontDesk_Helper.getmodules()[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    }

    if (Helper_Class.getInfo().hptl_name == 'SGH') {
      this.p_tag_style = "text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;margin-top: 10px;";
    } else {
      this.p_tag_style = "text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;";
    }

    this.prevBalFlag = true;
    this.discount_amount = 0;
    this.centralGST = true;
    this.stateGST = true;
    if(Helper_Class.getInfo().user_type=="diagnosis"){
      if(Helper_Class.getInfo().hospitals[0].bill_pay_type_req =="1"){
        this.paytypreq=true;
      } else {
        this.paytypreq=false;
      }    
      
    } else {
      if(Helper_Class.getInfo().bill_pay_type_req =="1"){
        this.paytypreq=true;
      } else {
        this.paytypreq=false;
      }
    }

    if(Helper_Class.getInfo().bill_qty_flag == 0){
      this.bill_qty_flag = true;
    } else {
      this.bill_qty_flag = false;
    }

    if (Helper_Class.getInfo().first_name != undefined) {
      this.logUserName = Helper_Class.getInfo().first_name;
      if (Helper_Class.getInfo().last_name != undefined) {
        this.logUserName = Helper_Class.getInfo().first_name +" "+ Helper_Class.getInfo().last_name;
      }
    }

    this.print_size = Helper_Class.getInfo().bill_print_paper;
    this.diag_center_id = Helper_Class.getInfo().diag_center_id;
    if( Helper_Class.getLoginType() == "diagnosis" ){
      this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().hospitals[0].bill_print_logo;
      this.printtemplate = Helper_Class.getInfo().bill_print_template;
      this.print_style=Helper_Class.getInfo().hospitals[0].bill_print_logo_style;    
    } else{
      this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
      this.printtemplate = Helper_Class.getInfo().bill_print_template;
      this.print_style=Helper_Class.getInfo().bill_print_logo_style;
    }
    
    var getBillingdetails = FrontDesk_Helper.getBillsDetails();
    this.type = getBillingdetails.type != undefined ? getBillingdetails.type : "";
    this.billingId = getBillingdetails.bill_view_id;
    this.providerType = getBillingdetails.provider_type;
    this.diagCenterId= getBillingdetails.provider;
    this.finyear = getBillingdetails.fin_year;
    this.getBillDetailedView();
  }

  getBillDetailedView() {
    var headers = new HttpHeaders();
    var send = {
      doc_reg_id: this.diagCenterId,
      billing_id: this.billingId,
      app_type: "diagnosis",
      fin_year: this.finyear,
      center_id: this.diag_center_id,
    }
    
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbill/', send,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          if (obj.bills != null) {
            if (obj.cgst != null) {
              this.centralGST = false;
              this.stateGST = false;
            }

            if (obj.rate_card_desc == 'Service wise') {                  
              this.discount_flag = false;                 
            } else {
              this.discount_flag = true;
            }

            if (obj.inp_hosp_id != undefined && obj.inp_hosp_id != null && obj.inp_hosp_id != "") {
              this.inpatient_flag = true;
            } else {
              this.inpatient_flag = false;
            }

            if (this.bill_qty_flag == true && !this.discount_flag == false && !this.inpatient_flag == true) {
              this.multiply_flag = true;
            } else if (this.bill_qty_flag == true && !this.discount_flag == true && !this.inpatient_flag == true) {
              this.multiply_flag = true;
            } else if (this.bill_qty_flag == true && !this.discount_flag == false && this.inpatient_flag == true) {
              this.multiply_flag = false;
            } else if (this.bill_qty_flag == true && !this.discount_flag == true && this.inpatient_flag == true) {
              this.multiply_flag = true;
            } else if (this.bill_qty_flag == false && !this.discount_flag == false && !this.inpatient_flag == true) {
              this.multiply_flag = false;
            } else if (this.bill_qty_flag == false && !this.discount_flag == true && !this.inpatient_flag == true) {
              this.multiply_flag = true;
            } else if (this.bill_qty_flag == false && !this.discount_flag == false && this.inpatient_flag == true) {
              this.multiply_flag = false;
            } else if (this.bill_qty_flag == false && !this.discount_flag == true && this.inpatient_flag == true) {
              this.multiply_flag = true;
            }

            if (this.bill_qty_flag == true && this.discount_flag == true && !this.inpatient_flag == true) {
              this.amount_td_style = "text-align: right; border: 1px solid black;border-collapse: collapse;width: 300px";
            } else {
              this.amount_td_style = "text-align: right; border: 1px solid black;border-collapse: collapse";
            }

            if (obj.bills != null && obj.bills.length != 0) {
              this.billingDetialedView = obj.bills;
              this.billingPrintViewFlag = this.billingDetialedView != null && this.billingDetialedView[0].test_id != null ?  false : true;
              this.totalShow = obj.bill_amount == undefined && obj.bill_amount == "" ? true : false;
              var sno=0;
              for (var i = 0; i < obj.bills.length; i++) {            
                var multiply_value;
                multiply_value = (parseFloat(obj.bills[i].amount_txt) * parseFloat(obj.bills[i].quantity));           

                sno +=1;
                this.billingDetails.push({
                  sno:sno,
                  test_name: obj.bills[i].test_name,
                  amount_txt: parseFloat(obj.bills[i].amount_txt),
                  fee: parseFloat(obj.bills[i].fee),
                  pay_type: obj.bills[i].pay_type,
                  concession: parseFloat(obj.bills[i].concession),
                  quantity: parseFloat(obj.bills[i].quantity),
                  multiply_value: parseFloat(multiply_value),
                  finalfee: parseFloat(obj.bills[i].fee)
                });
                this.concession = obj.bills[i].concession;
              }
            }
            
            if (obj.profile_image != ""){
              this.profile_image = ipaddress.Ip_with_img_address + obj.profile_image;
            }

            if (obj.middle_name != null && obj.middle_name != "") {
              this.clientName = encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.middle_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
            } else {
              this.clientName = encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
            }
            
            var cname;
            if (obj.salutation != undefined) {
              cname = obj.salutation+"."+this.clientName;
            } else {
              cname = this.clientName;
            }

            if (obj.age != undefined){
              this.ageval = obj.age;
            } else {
              this.ageval="";
            }

            if (obj.gender != undefined){
              this.genderval = obj.gender;  
            } else {
              this.genderval="";
            } 

            var genshort;
            if (this.genderval !=undefined && encrypt_decript.Decript(this.genderval) =="Male"){
              genshort = "M";
            } else {
              genshort="F";
            }  

            if (obj.mobile != undefined){
              this.mobile = encrypt_decript.Decript(obj.mobile);
            }

            var docName;
            if (obj.dr_middle_name != undefined && obj.dr_middle_name != null) {
              docName = "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
            } else if (obj.dr_first_name != undefined && obj.dr_last_name != undefined) {
              docName = "Dr." + obj.dr_first_name + " " + obj.dr_last_name;
            } 

            if (obj.doc_qualif != undefined && obj.doc_qualif != null && obj.doc_qualif != "") {
              docName = docName + " " + obj.doc_qualif;
            }

            var inpatientid,bed_no,admission_time,ward_name,admission_date;
            if(obj.inp_hosp_id != undefined) {
              inpatientid = obj.inp_hosp_id;
              this.inpatientflag=true;

            } else {
              this.inpatientflag=false;
              if(obj.appointment_id != undefined){
                inpatientid = obj.appointment_id;
              }               
            }
            if(obj.bed_no != undefined) {
              bed_no = obj.bed_no;

            } 
            if(obj.ward_name != undefined) {
              ward_name = obj.ward_name;

            } 
            if(obj.admission_date != undefined) {
              admission_date = obj.admission_date;

            } 
            if(obj.admission_time != undefined) {
              admission_time = Time_Formate(obj.admission_time);
            } 

            if (obj.created_date != undefined) {
              var get_date = obj.created_date.toString().split('-');
              var datetxt = get_date[2] + "-" + get_date[1] + "-" + get_date[0]+" "+Time_Formate(obj.created_time);
            }

            if (obj.caddress2 != null && obj.caddress2 != undefined && obj.caddress1 != undefined) {
              this.clientaddress1 = encrypt_decript.Decript(obj.caddress1)+ ",";
              if(obj.caddress2 != " " && obj.caddress2 != "null") {
                if (encrypt_decript.Decript(obj.caddress2) == "") {
                  this.clientaddress2 == "";
                } else {
                  this.clientaddress2 = encrypt_decript.Decript(obj.caddress2)+",";
                }
              }
            } else if (obj.caddress1 != undefined) {
              this.clientaddress1 = encrypt_decript.Decript(obj.caddress1)+",";
            } else if (obj.caddress2 != undefined) {
              this.clientaddress2 = encrypt_decript.Decript(obj.caddress2)+",";
            }      

            var clocation = '',czipcode = '',ccity = '',cstate = '',ccountry = '';
            if (obj.clocation != undefined) {
              clocation = obj.clocation+",";
            }
  
            if (obj.ccity != undefined) {
              ccity = obj.ccity+"-";
            }

            if(obj.czipcode != undefined) {
              czipcode = encrypt_decript.Decript(obj.czipcode)+",";
            }
  
            if (obj.cstate != undefined) {
              cstate = obj.cstate+"-";
            }   
            
            if(obj.ccountry != undefined) {
              ccountry = obj.ccountry+".";
            }

            if (obj.bill_amount == undefined && obj.bill_amount == "") 
              this.totalFlag = true;

            if (obj.advance == undefined || obj.advance == "" ) 
              this.billAdvanceFlag = true;

            if (obj.prev_balance != null) 
              this.prevBalance = obj.prev_balance;
            else 
              this.prevBalanceFlag = true;

            if (obj.balance == undefined || obj.balance == "") 
              this.mainBalanceFlag = true;

            this.estimatePrintData = obj.estimates;
            this.treatmentPlanFlag = this.estimatePrintData != "" && this.estimatePrintData != undefined ? false : true;
            this.charge = [];
            this.hospAddress = obj.diag_centre_name != null ? false : true;         

            if (obj.first_name != undefined) {
              var tot_amt1:any = 0;
              if (obj.bills != null && obj.bills.length != 0) {
                for (var i = 0; i < obj.bills.length; i++) {
                  if (obj.bills[i].amount_txt != null) {
                    tot_amt1 = (parseFloat(tot_amt1) + (parseInt(obj.bills[i].amount_txt) * parseFloat(obj.bills[i].quantity)));
                    this.discount_amount = (parseFloat(this.discount_amount) + (parseFloat (obj.bills[i].amount_txt) * parseFloat(obj.bills[i].quantity)) * (parseFloat(obj.bills[i].concession)/ 100));
                    this.concession = obj.bills[i].concession;
                  }
                }

                if (this.discount_amount != '') {          
                  var floatValue = this.discount_amount;
                  var floatString = floatValue.toString();
                  var parts = floatString.split('.');
                  var digitsAfterDecimal = parts[1] ? parts[1] : 0;
                  if (digitsAfterDecimal[0] > 5) {
                    this.discount_amount = Math.round(this.discount_amount);
                    this.discount_amount = parseInt(this.discount_amount); 
                  } else {
                    this.discount_amount = parseInt(this.discount_amount);
                  }                       
                }
              }
              this.totalBillAmount = tot_amt1;

              var tot_amt: any = 0;
              if (obj.bills != null && obj.bills.length != 0) {
                for (var i = 0; i < obj.bills.length; i++) {
                  if (obj.bills[i].fee != null) {
                    tot_amt = (parseInt(tot_amt) + parseFloat(obj.bills[i].fee));
                  }
                }
              }

              if (obj.estimates != null) {
                if (obj.estimates.length != 0) {
                  for (var i = 0; i < obj.estimates.length; i++) {
                    if (obj.estimates[i].estimate != null) {
                      tot_amt = (parseFloat(tot_amt) + parseFloat(obj.estimates[i].estimate));
                    }
                  }
                }
              }

              this.billAmount = tot_amt;
              this.totalBill = tot_amt;
              if (obj.prev_balance != null && obj.prev_balance != 0) {
                this.prevBalFlag = false;
                this.prevBlance = obj.prev_balance;
                this.billAmount = (parseFloat(tot_amt) + parseFloat(this.prevBlance));
                this.totalBillAmount = (parseFloat(tot_amt1) + parseFloat(this.prevBlance));
              } else {
                this.prevBalFlag = true;
              }          

              if (obj.cgst != null) {
                this.centralGST = (parseFloat(this.billAmount) * (parseFloat(obj.cgst) / 100));
                this.stateGST = (parseFloat(this.billAmount) * (parseFloat(obj.sgst) / 100));
                this.billAmount = parseFloat(tot_amt) + parseFloat(this.centralGST) + parseFloat(this.stateGST);
                this.billAmount = parseFloat(this.billAmount);
                this.totalBillAmount = parseFloat(tot_amt1) + parseFloat(this.centralGST) + parseFloat(this.stateGST);
                this.totalBillAmount = parseFloat(this.totalBillAmount);
              }

              if (obj.bill_amount != undefined && obj.bill_amount != "") {             
                if (parseInt(this.concession) != 100) {
                  this.billAmount = encrypt_decript.Decript(obj.bill_amount); 
                  this.billAmount = parseInt(this.billAmount);                       
                }                
              }            
              
              this.paid = obj.paid_amount != undefined ? parseFloat(obj.paid_amount) : 0;

              var balance;
              if (obj.paid_amount != 0 && obj.advance == 0){
                balance = (parseFloat(this.billAmount)- parseFloat(obj.paid_amount));
              } else {
                balance = parseFloat(obj.balance);
              }

              if (obj.payments != undefined) {
                this.paymentsarray = obj.payments;                           
              }

              if (obj.balance == undefined || obj.balance == "")
                this.main_balshow = true;

              if(obj.credit_type != undefined){
                this.credit_type = obj.credit_type;
                this.credit_person = obj.credit_person;
              } else {              
                  this.credit_type = '';
                  this.credit_person = '';               
              }

              if (obj.barcode != undefined && obj.barcode != null) {
                this.barcode_flag = true;
                this.barcode_url = ipaddress.Ip_with_img_address + obj.barcode;
              }

              if(parseFloat(this.paid) > this.billAmount){              
                this.numToWords = convertNumberToWords(this.billAmount,ipaddress.country_code);
              } else {
                this.numToWords = convertNumberToWords(this.paid,ipaddress.country_code);
              }  

              var advance; 
              if(obj.advance == 0){
                advance = 0;
              } else {
                advance = obj.advance;
              }

              if (this.credit_type == '') {
                this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -35px;";
                this.nobanner_style = "width: 100%; height: 95px;";
              } else {
                this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -45px;";
                this.nobanner_style = "width: 100%; height: 105px;";
              }

              this.billingDetailList.push({               
                licence_code: " Reg. No: " + obj.licence_code,
                diag_centre_name: obj.diag_centre_name,
                location: obj.location + ", ",
                city: obj.city + " - " + obj.zipcode + ",",
                state: obj.state + " - " + obj.country + ",",
                telephone: obj.telephone,
                appointment_id: obj.appointment_id,
                profile_image: this.profile_image,
                client_name: cname,
                age: this.ageval,
                gender: genshort,
                mobile: this.mobile,
                dr_name: docName,
                inpatientid: inpatientid,
                bedno: bed_no,
                wardname: ward_name,
                admitteddate: admission_date,
                admittedtime: admission_time,
                created_date: datetxt,
                advance: advance,
                balance: balance,
                caddress1: this.clientaddress1,
                caddress2: this.clientaddress2,
                clocation: clocation,
                clientcity: ccity + czipcode,
                clestare: cstate + ccountry,
                client_reg_id: obj.client_hosp_id,
                numToWords: this.numToWords
              });
            }
          } else {
            this.toastr.error(Message_data.network);
          }
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }

  printArea() {
    var margin_top;
    let printContents, popupWin;
    if (this.printtemplate != undefined && this.printtemplate == "banner") {
      printContents = this.printbanner.nativeElement.innerHTML; 
      margin_top = '0px';
    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
      margin_top = '122px';
    }
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>                        
          @page {
            size: auto;
            margin-top: ${margin_top};
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 0px;
            overflow: visible;
          } 
          * {
              font-size: 12px !important;
              line-height: 1 !important;
              page-break-inside: always;
              overflow: visible;
            }                                     
          @media print {   
            body {
              height: auto; /* Allow body height to adjust dynamically */
            }          
            .page-break {
              page-break-before: always; /* or page-break-after: always; */
              page-break-inside: always;
            }
          }           
          .address_visibility {
            visibility: hidden;
          }        
          table.report-container {
            page-break-after: always;
          }
          thead.report-header {
            display: table-header-group;
          }
          tfoot.report-footer {
            display: table-footer-group;
          }
          .print:last-child {
            page-break-after: auto;
          }
          .alignRight {
            text-align: right;
          }
          .col-print-1 {width:8%;  float:left;}
          .col-print-2 {width:16%; float:left;}
          .col-print-3 {width:25%; float:left;}
          .col-print-4 {width:33%; float:left;}
          .col-print-5 {width:42%; float:left;}
          .col-print-6 {width:50%; float:left;}
          .col-print-7 {width:58%; float:left;}
          .col-print-8 {width:66%; float:left;}
          .col-print-9 {width:75%; float:left;}
          .col-print-10 {width:83%; float:left;}
          .col-print-11 {width:92%; float:left;}
          .col-print-12 {width:100%; float:left;}
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
    popupWin.document.close();
  }

  back(){
    if (Helper_Class.getInfo().user_type == "Admin") {
      this.messageservice.sendMessage("Frontrefund");
    } else {
      if(this.type == "refund")
        this.messageservice.sendMessage("refund");
      else
        this.messageservice.sendMessage("frontdeskbilling");
    }   
  }
}
