import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Http, Headers } from '@angular/http';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { ipaddress } from '../../ipaddress' ;import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-recept-prescription-list',
  templateUrl: './recept-prescription-list.component.html',
  styleUrls: ['./recept-prescription-list.component.css']
})
export class ReceptPrescriptionListComponent implements OnInit {
  public currentDate;
  public doctorflag:boolean=false;
  public medPresList = [];
  public mpresUrl: string;
  public medPresMasterList;
  public medPresFlag: boolean;
  public doctorList = [];
  public appointmentDate;
  public doctorName: string;
  public doctorId;
  public doctor;
  public userInfo;
  public repflag:boolean = true;
  public editflag:boolean = false;
  private hptlid:string;
  dtOptions: any = {};
  modulelist: any;
  editbutton: boolean = false;
  newbutton: boolean = false;
  deletebutton: boolean = false;
  printbutton: boolean = false;
  viewbutton: boolean = false;

  constructor(public toastr: ToastrService,public http: Http, public frontdeskservice:MenuViewService) {
    this.mpresUrl = ipaddress.getIp + "prescription/dmedpres/";
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.appointmentDate = FrontDesk_Helper.getAppDate();
    if(this.appointmentDate == undefined){
      this.getCurrentDate();
    } else {
      this.currentDate = this.appointmentDate;
    }
    
    this.dtOptions = {
      pagingType: 'simple_numbers', // Use a standard paging type
      pageLength: 25,
      lengthMenu: [[25, 50, -1], [25, 50, "All"]],
      order: [[2, 'asc']], // Keep the initial sorting on the third column (index 2)
      columnDefs: [
        { "type": "date", "orderable": false, "targets": [0] }, // Disable sorting on the first column (index 0)
        { "orderable": false, "targets": [3] } // Disable sorting on the last column (index 3)
      ],
      language: {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by Name"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>>' +
           '<"table-responsive"t>' +
           '<"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10">' +
           '<"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i>' +
           '<"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    }; 
    

    this.medPresFlag = false;
    if(this.userInfo.user_type == "front-desk" || this.userInfo.user_type == "Admin"){
      this.repflag = true;
      this.editflag = false;
      this.doctorflag=true
      this.getDoctors();
      this.hptlid = this.userInfo.hptl_clinic_id;

    } else if(this.userInfo.user_type == "doctor"){
      this.repflag = true;
      this.doctorflag=false;
      if(this.appointmentDate == this.currentDate){
        this.editflag = true;
      } else {
        this.editflag = false;
      }
      this.editflag = true;
      this.doctor = this.userInfo.user_id;
      this.doctorflag=false;
      this.hptlid = this.userInfo.hospitals[0].hptl_clinic_id
      this.getMedPresList();
    }    
    else {
      this.repflag = false;
      if(this.appointmentDate == this.currentDate){
        this.editflag = true;
      } else {
        this.editflag = false;
      }
      this.editflag = true;
      this.doctor = this.userInfo.user_id;
      this.doctorflag=false;
      this.hptlid = this.userInfo.hospitals[0].hptl_clinic_id
      this.getMedPresList();
    }
    if(this.userInfo.user_type == "Admin"){
      this.editflag = true;
    }
    this.modulelist = Helper_Class.getmodulelist();
    if (this.modulelist != null) {
      for (var i = 0; i < this.modulelist.length; i++) {

        if (this.modulelist[i].module_id == "6") {
          if (this.modulelist[i].edit == "1") {
            this.editbutton = true;
          }
          if (this.modulelist[i].create == "1") {
            this.newbutton = true;
          }
          if (this.modulelist[i].delete == "1") {
            this.deletebutton = true;
          }
          if (this.modulelist[i].print == "1") {
            this.printbutton = true;
          }
          if (this.modulelist[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }
  }

  getMedPresList() {
    var send_data = {
      date: this.appointmentDate,
      doc_prov_id: this.doctor,
      hptl_clinic_id: this.hptlid,
    }
    console.log("check data --0"+JSON.stringify(send_data))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.mpresUrl, JSON.stringify(send_data), { headers: headers }).subscribe(
      data => {
        console.log("check data --0"+JSON.stringify(data.json() ))

        if (data.json() != undefined) {
          this.medPresMasterList = data.json().prescriptions;
          if (this.medPresMasterList != null) {
            this.medPresList = [];
            for (var i = 0; i < this.medPresMasterList.length; i++) {
              var new_datalist = this.medPresMasterList[i];
              var docname;
              if (new_datalist.doc_middle_name == undefined) {
                docname = new_datalist.doc_first_name + " " + new_datalist.doc_last_name;
              } else {
                docname = new_datalist.doc_first_name + " " + new_datalist.doc_middle_name + " " + new_datalist.doc_last_name;
              }

              var clntname;
              if (new_datalist.middle_name != undefined) {
                clntname = encrypt_decript.Decript(new_datalist.first_name) + " " + encrypt_decript.Decript(new_datalist.middle_name) + " " + encrypt_decript.Decript(new_datalist.last_name);
              } else {
                clntname = encrypt_decript.Decript(new_datalist.first_name) + " " + encrypt_decript.Decript(new_datalist.last_name);
              }
              var image;var sub_rel_id;
              if (new_datalist.prof_image != undefined) {
                if (new_datalist.prof_image == "default") {
                  image="../../assets/img/default.jpg"
                }else{
                  image=ipaddress.Ip_with_img_address + new_datalist.prof_image
                }
              }
              if(new_datalist.sub_rel_id != undefined){
                sub_rel_id=new_datalist.sub_rel_id;
              }
              var pres_drug_id = "";
              this.medPresList.push({
                doctorname: docname,
                clientname: clntname,
                pres_drug_id: new_datalist.pres_drug_id,
                prof_image: image,
                relation_id:new_datalist.relation_id,
                sub_rel_id:sub_rel_id,
                client_reg_id:new_datalist.client_reg_id,
                barcode:new_datalist.barcode
              });
            }
          }
          this.medPresFlag = this.medPresList.length == 0 ? true  : false;
        }
      },error => {});
  }

  editPrescription(data,list){
    var data1 = {
      pres_id: data,
      rel_id:list.relation_id,
      Client_id:list.client_reg_id,
      barcode:list.barcode
    }
    FrontDesk_Helper.setreceptviewprecription(data1);
    this.frontdeskservice.sendMessage("editpres");
  }

  viewPrescription(data) {
    var data1 = {
      pres_id: data
    }
    FrontDesk_Helper.setreceptviewprecription(data1);
    this.frontdeskservice.sendMessage("receptviewprescription");
  }

  getDoctors() {//Get doctors
   var ipaddress1 = Helper_Class.getIPAddress()
   console.log("check doctors---"+JSON.stringify({
    reg_id: this.userInfo.user_id,
    imei:ipaddress1,
    hptl_clinic_id:this.userInfo.hptl_clinic_id,
   }))

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/docbypres',
      JSON.stringify({
        country: "IN",
        reg_id: this.userInfo.user_id,
        imei:ipaddress1,
        hptl_clinic_id:this.userInfo.hptl_clinic_id,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("check doctors---"+JSON.stringify(obj.doctors))
          for (var i = 0; i < obj.doctors.length; i++) {
            this.doctorName = "";
            if (obj.doctors[i].middle_name != undefined) {
              this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
            } else {
              this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
            }

            this.doctorList.push({
              doc_name: this.doctorName,
              doc_id: obj.doctors[i].prov_id
            });
          }

          this.doctorList = this.doctorList.sort((a, b) => {
            if (a.doc_name < b.doc_name) return -1;
            if (a.doc_name > b.doc_name) return 1;
            return 0; // if they are equal
          });
          
          console.log("check doctors---"+JSON.stringify(this.doctorList))

          this.doctor = this.doctorList[0].doc_id;
          localStorage.setItem("doctor_id", this.doctor)
          if(FrontDesk_Helper.getDocPrescription() != undefined){
            this.changeDoctor(FrontDesk_Helper.getDocPrescription());
          }else{
            this.changeDoctor(this.doctor);
          }
        },
        error => {});
  }

  selectAppointmentDate(e) {
    this.appointmentDate = e;
    FrontDesk_Helper.setDate(undefined);
    FrontDesk_Helper.setDate(e);
    if(this.appointmentDate == this.currentDate){
      this.editflag = true;
    } else {
      this.editflag = false;
    }
    this.getMedPresList();
  }

  changeDoctor(e) {
    this.doctor = e;
    FrontDesk_Helper.setDocPrescription(undefined);
    FrontDesk_Helper.setDocPrescription(this.doctor);
    this.getMedPresList();
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json()
          if (obj.current_date != null) {
            this.currentDate = obj.current_date;
            if(FrontDesk_Helper.getDate() == undefined){
              this.appointmentDate = this.currentDate;
            }else{
              this.appointmentDate = FrontDesk_Helper.getDate();
            }
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }
}
