<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Day Book</h5>
          </div>
          <!-- <div style="width: 145px; margin-left: 15px;">
            <mat-label class="matlabel">Preferrence</mat-label>
            <mat-select class="ipcss" multiple [(ngModel)]="selected_filter"
                (selectionChange)="setTable(selected_filter);">
                <mat-option value="docName">Doctor name</mat-option>
                <mat-option value="billType">Bill type</mat-option>  
                <mat-option value="discount">Discount</mat-option>
                <mat-option value="paid">Paid</mat-option>
                <mat-option value="balance">Balance</mat-option>
                <mat-option value="cash">Cash</mat-option>
                <mat-option value="card">Card</mat-option>  
                <mat-option value="upi">Upi</mat-option> 
                <mat-option value="insurance">Insurance</mat-option>   
                <mat-option value="status">Status</mat-option>                 
            </mat-select>
          </div> -->
          <div class="headerButtons">                      
            <a (click)="print_area()" style="margin:0 5px;">
              <img src="../../../assets/img/printer.svg" class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;" />
            </a>
            <a (click)="getExcelData('excel')">
              <img src="../../../assets/ui_icons/buttons/XL_export.svg" class="saveimgbtn_inpatinfo" style="margin-right:10px" />
            </a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div" style="margin-top: 0px !important;">
          <div class="header_lable">
              Day Book Details
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
                <mat-label class="matlabel" style="margin-top: 7px;">From
                  <input type="date" class="ipcss_date " (change)="selectDate(fromDate,'1')"
                      [(ngModel)]="fromDate" max="{{currentDate}}" #matInput style="width: 140px;">
                </mat-label>
              </div>  
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
                <mat-label class="matlabel" style="margin-top: 7px;">To
                  <input type="date" class="ipcss_date " (change)="selectDate(toDate,'1')"
                      [(ngModel)]="toDate" max="{{currentDate}}" #matInput style="width: 140px;">
                </mat-label>
              </div>                                   
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" style="padding-right: 0px;"><br/>
                <mat-label class="matlabel" style="margin-top: 12px !important;">Hospital
                    <mat-checkbox color="primary" [(ngModel)]="hospitalcheckbox" (change)="check_hospital_depart(hospitalcheckbox,'hospital')"></mat-checkbox>
                </mat-label>
                &nbsp;&nbsp;
                <mat-label class="matlabel" style="margin-top: 12px !important;">Diagnosis
                    <mat-checkbox color="primary" [(ngModel)]="diagnosischeckbox" (change)="check_diag_depart(diagnosischeckbox,'diagnosis')"></mat-checkbox>
                </mat-label>
                &nbsp;&nbsp;
                <mat-label class="matlabel" style="margin-top: 12px !important;">Pharmacy
                  <mat-checkbox color="primary" [(ngModel)]="pharmacycheckbox" (change)="check_pharma_depart(pharmacycheckbox,'pharma')"></mat-checkbox>
                </mat-label>
              </div> 
            </div>
          </div>
        </div>
        <p class="nodata" *ngIf="dayBookList.length == 0">No record(s) found</p>
        <div class="table-responsive" style="width: 100%; height: auto;" *ngIf="dayBookList.length != 0">
          <div class="accordion" id="accordionExample"> 
            <div class="container-fluid" >
              <div class="row">
                <div class="col-12">
                  <div style="font-family: Arial; margin: 2px;">
                    <mat-accordion displayMode="flat" multi="false" class="mat-table">
                      <section matSort class="mat-elevation-z2 mat-header-row" style="background-color: #c1e6fb; position: sticky; top: 0px;
                        z-index: 2;">
                        <span class="mat-header-cell">S.NO</span>
                        <span class="mat-header-cell">USER NAME</span>
                        <!-- <span class="mat-header-cell">BILL AMOUNT</span> -->
                        <span class="mat-header-cell">CASH</span>
                        <span class="mat-header-cell">CARD</span>
                        <span class="mat-header-cell">UPI</span>
                        <span class="mat-header-cell">CHEQUE</span>
                        <span class="mat-header-cell">INSURANCE</span>
                        <span class="mat-header-cell">DUE/CREDIT</span>
                        <span class="mat-header-cell">TOTAL</span>
                      </section>
                      <mat-expansion-panel *ngFor="let user of userdataList">
                        <mat-expansion-panel-header class="mat-row" (click)="getUserbasedDetails(user)">
                          <span class="mat-cell">{{user.sno}}</span>
                          <span class="mat-cell">{{user.username}}</span>
                          <!-- <span class="mat-cell">{{user.bill}}</span> -->
                          <span class="mat-cell">{{user.cash}}</span>
                          <span class="mat-cell">{{user.card}}</span>
                          <span class="mat-cell">{{user.upi}}</span>
                          <span class="mat-cell">{{user.cheque}}</span>
                          <span class="mat-cell">{{user.insurance}}</span>
                          <span class="mat-cell">{{user.balance}}</span>
                          <span class="mat-cell">{{user.total_amount}}</span>
                        </mat-expansion-panel-header>
                        <div style="height: auto;overflow-y: auto;overflow-x: auto;max-height: 50vh;padding: 4px;">
                          <table style="font-size: 12px;font-family: Arial;" class="headerfixed">
                            <thead>
                              <tr>
                                <th>S.no</th>
                                <th style="width:75px">Bill No</th>
                                <th style="width:155px;text-align: left;">Bill Date/Time</th>
                                <th style="width:100px">MR.No</th>
                                <th>Patient Name</th>
                                <th>Patient type</th>
                                <th *ngIf="columns.docName">Doctor</th>
                                <th *ngIf="columns.billType">Bill type</th>
                                <!-- <th>Bill Amt</th> -->
                                <th *ngIf="columns.discount">Discount</th>
                                <!-- <th *ngIf="columns.advance">Advance</th> -->
                                <th *ngIf="columns.paid">Paid</th>
                                <th *ngIf="columns.balance">Due/Credit</th>
                                <th *ngIf="columns.cash">Cash</th>
                                <th *ngIf="columns.card">Card</th>
                                <th *ngIf="columns.upi">UPI</th>
                                <th *ngIf="columns.insurance">Insurance</th>
                                <!-- <th *ngIf="columns.status">Status</th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let sales of filterdaybooklist">
                                <td>{{sales.sno}}</td>
                                <td style="width:75px">{{sales.billing_id}}</td>
                                <td style="width:155px;text-align: left;">{{sales.created_date}}</td>
                                <td style="width:100px">{{sales.mr_no}}</td>
                                <td class="align_left">{{sales.patient_name}}</td>
                                <td style="text-align: center;">{{sales.patient_type}}</td>
                                <td *ngIf="columns.docName" class="align_left">{{sales.doc_name}}</td>
                                <td *ngIf="columns.billType" style="text-transform: capitalize;">{{sales.bill_type}}</td>
                                <!-- <td style="text-align: right;">{{sales.bill_amount}}</td> -->
                                <td style="text-align: right;" *ngIf="columns.discount">{{sales.discount}}</td>
                                <!-- <td style="text-align: right;" *ngIf="columns.advance">{{sales.advance}}</td> -->
                                <td style="text-align: right;" *ngIf="columns.paid">{{sales.paid_amount}}</td>
                                <td style="text-align: right;" *ngIf="columns.balance">{{sales.balance}}</td>
                                <td style="text-align: right;" *ngIf="columns.cash">{{sales.cash}}</td>
                                <td style="text-align: right;" *ngIf="columns.card">{{sales.card}}</td>
                                <td style="text-align: right;" *ngIf="columns.upi">{{sales.upi}}</td>
                                <td style="text-align: right;" *ngIf="columns.insurance">{{sales.insurance}}</td>
                                <!-- <td *ngIf="columns.status">{{sales.paid}}</td> -->
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div> 
              </div>
            </div>
          </div>
          <div [hidden]="true">
            <table style="margin-left:10px;margin-right: 10px;" #printimage id="printimage">
              <tr>
                <td>
                  <img alt="image" src={{hospital_logo}} style="width: 95%; height: 130px;">
                </td>
              </tr>
            </table>
            <div style="position: relative;top: 20px;left: 10px;text-align: left;" #hospitalwithnamelogo id="hospitalwithnamelogo">
              <span><b>{{hosp_name}}</b>,</span><br />
              <span *ngIf="hosp_addr != ''">{{hosp_addr}}</span><br />
              <span>{{hosp_csz}}</span><br />
              <span *ngIf="hosp_tel != undefined"><img src="../../../assets/img/phone.png" width="15px"
                  height="15px" />{{hosp_tel}}.
              </span><br>
            </div>
          </div>
          <table #printusertable [hidden]="true" id="printusertable" style="margin-top: 20px;width:100%;font-size: 12px;position: relative;left: 20%;">
            <thead>
              <tr>
                <td>S.NO</td>
                <th>USER NAME</th>
                <!-- <th>BILL AMOUNT</th> -->
                <th>CASH</th>
                <th>CARD</th>
                <th>UPI</th>
                <th>CHEQUE</th>
                <th>INSURANCE</th>
                <th>DUE/CREDIT</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of userdataList">
                <td>{{user.sno}}</td>
                <td>{{user.username}}</td>
                <!-- <td>{{user.bill}}</td> -->
                <td>{{user.cash}}</td>
                <td>{{user.card}}</td>
                <td>{{user.upi}}</td>
                <td>{{user.cheque}}</td>
                <td>{{user.insurance}}</td>
                <td>{{user.balance}}</td>
              </tr>
            </tbody>
          </table>
          <table #printDBR [hidden]="true" id="printDBR" style="font-size: 12px; width: 95%; margin-top: 10px !important;margin-left:auto;margin-right:auto">
            <thead>
              <tr>
                <th style="border: 1px solid #eeeeee;border-collapse: collapse;">S.no</th>
                <th style="width:42px; border: 1px solid #eeeeee;border-collapse: collapse;">Bill</th>
                <th style="width:80px;text-align: left; border: 1px solid #eeeeee;border-collapse: collapse;">Bill Date</th>
                <th style="width:80px;text-align: left; border: 1px solid #eeeeee;border-collapse: collapse;">Created</th>
                <th style="width:100px;text-align: left; border: 1px solid #eeeeee;border-collapse: collapse;">MR.No</th>
                <th style="width:90px; border: 1px solid #eeeeee;border-collapse: collapse;">Patient Name</th>
                <th style="width:50px;text-align: center; border: 1px solid #eeeeee;border-collapse: collapse;">Patient type</th>
                <th *ngIf="columns.docName" style="width:90px; border: 1px solid #eeeeee;border-collapse: collapse;">Doctor</th>
                <th *ngIf="columns.billType" style="width:90px; border: 1px solid #eeeeee;border-collapse: collapse;">Bill type</th>
                <!-- <th style="border: 1px solid #eeeeee;border-collapse: collapse;">Bill Amt</th> -->
                <th *ngIf="columns.discount" style="border: 1px solid #eeeeee;border-collapse: collapse;">Discount</th>
                <!-- <th *ngIf="columns.advance" style="border: 1px solid #eeeeee;border-collapse: collapse;">Advance</th> -->
                <th *ngIf="columns.paid" style="border: 1px solid #eeeeee;border-collapse: collapse;">Paid</th>
                <th *ngIf="columns.balance" style="border: 1px solid #eeeeee;border-collapse: collapse;">Due/Credit</th>
                <th *ngIf="columns.cash" style="border: 1px solid #eeeeee;border-collapse: collapse;">Cash</th>
                <th *ngIf="columns.card" style="border: 1px solid #eeeeee;border-collapse: collapse;">Card</th>
                <th *ngIf="columns.upi" style="border: 1px solid #eeeeee;border-collapse: collapse;">UPI</th>
                <th *ngIf="columns.insurance" style="border: 1px solid #eeeeee;border-collapse: collapse;">Insurance</th>
                <th *ngIf="columns.status" style="border: 1px solid #eeeeee;border-collapse: collapse;">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of dayBookList">
                <td style="border: 1px solid #eeeeee;border-collapse: collapse;">{{sales.sno}}</td>
                <td style="border: 1px solid #eeeeee;border-collapse: collapse;">{{sales.billing_id}}</td>
                <td style="text-align: left; border: 1px solid #eeeeee;border-collapse: collapse;">{{sales.created_date}}</td>
                <td style="text-align: left; border: 1px solid #eeeeee;border-collapse: collapse;">{{sales.user_name}}</td>
                <td style="text-align: left; border: 1px solid #eeeeee;border-collapse: collapse;">{{sales.mr_no}}</td>
                <td style="border: 1px solid #eeeeee;border-collapse: collapse;" class="align_left">{{sales.patient_name}}</td>
                <td style="text-align: center; border: 1px solid #eeeeee;border-collapse: collapse;">{{sales.patient_type}}</td>
                <td *ngIf="columns.docName" style="border: 1px solid #eeeeee;border-collapse: collapse;"class="align_left">{{sales.doc_name}}</td>
                <td *ngIf="columns.billType" style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: left;text-transform: capitalize;">{{sales.bill_type}}</td>
                <!-- <td style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.bill_amount}}</td> -->
                <td *ngIf="columns.discount" style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.discount}}</td>
                <!-- <td *ngIf="columns.advance" style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.advance}}</td> -->
                <td *ngIf="columns.paid" style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.paid_amount}}</td>
                <td *ngIf="columns.balance" style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.balance}}</td>
                <td *ngIf="columns.cash" style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.cash}}</td>
                <td *ngIf="columns.card" style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.card}}</td>
                <td *ngIf="columns.upi" style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.upi}}</td>
                <td *ngIf="columns.insurance" style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.insurance}}</td>
                <td *ngIf="columns.status" style="border: 1px solid #eeeeee;border-collapse: collapse; text-align: right;">{{sales.paid}}</td>
              </tr>
              <tr>
                <td [attr.colspan]="colspann"></td>
                <td style="border: 1px solid #eeeeee;border-collapse: collapse;">
                  <div style="width: 100%; text-align: end;"><b>Total</b></div>
                </td>
                <td style="border: 1px solid #eeeeee;border-collapse: collapse;">
                  <div style="width: 100%; text-align: end;"><b></b></div>
                </td>
                <td style="border: 1px solid #eeeeee;border-collapse: collapse;">
                  <div style="width: 100%; text-align: end;"><b></b></div>
                </td>
                <td style="border: 1px solid #eeeeee;border-collapse: collapse;">
                  <div style="width: 100%; text-align: end;"><b></b></div>
                </td>
                <td style="border: 1px solid #eeeeee;border-collapse: collapse;">
                  <div style="width: 100%; text-align: end;"><b></b></div>
                </td>
                <td *ngIf="columns.discount" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                  <div style="width: 100%; text-align: end;"><b>{{discountAmount}}</b></div>
                </td>
                <td style="border: 1px solid #eeeeee;border-collapse: collapse;">
                  <div style="width: 100%; text-align: end;"><b>{{totalAmount}} </b></div>
                </td>
                <!-- <td *ngIf="columns.advance" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                  <div style="width: 100%; text-align: end;"><b>{{advanceAmount}}</b></div>
                </td> -->
                <!-- <td *ngIf="columns.paid" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                  <div style="width: 100%; text-align: end;"><b>{{totalAmount}}</b></div>
                </td> -->
                <td *ngIf="columns.balance" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                  <div style="width: 100%; text-align: end;"><b>{{balanceAmount}}</b></div>
                </td>
                <td *ngIf="columns.cash" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                  <div style="width: 100%; text-align: end;"><b>{{cashAmount}}</b></div>
                </td>
                <td *ngIf="columns.card" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                  <div style="width: 100%; text-align: end;"><b>{{cardAmount}}</b></div>
                </td>
                <td *ngIf="columns.upi" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                  <div style="width: 100%; text-align: end;"><b>{{upiAmount}}</b></div>
                </td>
                <td *ngIf="columns.insurance" style="border: 1px solid #eeeeee;border-collapse: collapse;">
                  <div style="width: 100%; text-align: end;"><b>{{insuranceAmount}}</b></div>
                </td>
              </tr>
            </tbody> 
          </table>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div> 
