import { Component, OnInit,ChangeDetectorRef,ViewChild,ElementRef} from '@angular/core';
import { Headers, Http } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Date_Formate, Time_Formate, convertNumberToWords } from '../../../assets/js/common';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Message_data } from '../../../assets/js/Message_data';
import { Helper_Class } from '../../helper_class';
import { ipaddress } from '../../ipaddress';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-diagbillcreate',
  templateUrl: './diagbillcreate.component.html',
  styleUrls: ['./diagbillcreate.component.css']
})
export class DiagbillcreateComponent implements OnInit {
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('focusMRNo') focusMRNo: ElementRef;
  @ViewChild('focusMobileNo') focusMobileNo: ElementRef;
  @ViewChild('focusFName') focusFName: ElementRef;
  public appoint;
  public amount;
  public finalAmount;
  public inGST: boolean;
  public concession;
  public con;
  public fees;
  public tableFlag: boolean;
  public finalFlag: boolean;
  public amountGST;
  public advance;
  public currentDateTime;
  public currentDate;
  public appointDate;
  public billListArray = [];
  public appID;
  public billDenPlanArray = [];
  public gstRetrivelArray = [];
  public addBillArray = [];
  public GSTArray = [];
  public charges;
  public estimateID = [];
  public remaingBalance;
  public addvance;
  public centralGST;
  public stateGST;
  public clientID;
  public relationID;
  public subRelationID;
  public chargesID;
  public Diag_Location_Data = [];
  public userType;
  public location;
  public date;
  public diagID;
  public diagLocation;
  public diagCenterID;
  public testArray = []; 
  public diagName;
  public testName;
  public ipaddress;
  public userInfo;
  //mdbTable
  dtOptions: DataTables.Settings = {};
  public dateTxt;
  public appDate
  public clientName: string;
  public previousBillID;
  public balanceFlag: boolean;
  public amountFlag: boolean;
  public previousBalFlag: boolean;
  public balance;
  public total;
  public payTypeArray: any = [];
  public insuranceFlag;
  public payType: string;
  public insurerancyID: string;
  public insurerancListArray: any = [];
  public inpatientFlag:boolean;
  public tranFlag:boolean;
  public transactionID;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag:boolean;
  public bankName;
  public quantity:any = 1;
  public sno:number=1;
  public saveFlag:boolean = true;
  public paytypreq:boolean=false;
  public paymentsarray:any=[];
  public paymentArray:any=[];
  public payType1: string;
  public amountval: any;
  public tempFinalAmount: any = 0;
  public credit_flag: boolean = true;
  public credit_bill;
  public credit_type = '';
  public hosp_credits: any = [];
  public rate_card;
  public RateCardArray = [];
  public paidBill: any = 0;
  public createpage_flag:boolean = false;
  public mrno;
  public clnt_saldesc;
  public patient_name;
  public clnt_age;
  public gender;
  public mobile_no;
  public pat_reg_date;
  public Moduleidlist = [];
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public credit_person;
  public cashDenomination: { value: number, count: number }[] = [
    { value: 2000, count: 0 },
    { value: 500, count: 0 },
    { value: 200, count: 0 },
    { value: 100, count: 0 },
    { value: 50, count: 0 },
    { value: 20, count: 0 },
    { value: 10, count: 0 },
    { value: 5, count: 0 },
    { value: 2, count: 0 },
    { value: 1, count: 0 }
  ];
  public showModal: boolean = false;
  public totalAmount: number;
  public doctor_name: string;
  public filter: string;
  public toFlag: boolean;
  public fromFlag: boolean;
  public firstNameFlag: boolean;
  public firstName: string;
  public uhidFlag: boolean;
  public patientId: string;
  public mobileFlag: boolean;
  public mobileNo:string;
  public lastName: string;
  public mr_no_flag: boolean;
  public Location: any;
  public city: any;
  public state: any;
  public country: any;
  public zipcode: any;
  public profile_image: string;
  public em_contact: any;
  public totalBillAmount: any = 0;
  public discount_amount: any = 0;
  public tot_pay_array_amount: any;
  public numToWords: any;
  public patient_address1: string;
  public patient_address2: string;
  public temp_key;
  public billId: any;
  public print_template: any;
  public print_size: any;
  public hospitalLogo: any;
  public printlogostyle;
  public noaddress:boolean=false;
  public wardName: any;
  public bed_no: any;
  public pres_diag_id: any;
  public currentTime: any;
  public timevalue: string;
  public discharge_date: any;
  public discharge_time: string;
  public insurFlag: boolean;
  public transactionFlag: boolean;
  public insurerId: string;
  public insurerList = [];
  public insreqflag: boolean = false;
  public cardreqflag: boolean = false;
  public transactionId;
  public transreqflag: boolean = false;
  public chequereqflag: boolean = false;
  public transId;
  public bill_qty_flag: boolean = false;
  public discount_flag: boolean = false;
  public amountAftCon;
  public open_app_billing;
  public barcode_flag:boolean = false;
  public barcode_url;
  public barcode_style: string;
  public logUserName;
  public nobanner_style;
  public inpatient_flag:boolean = false;
  public multiply_flag: boolean = true;
  public amount_td_style;
  public paymentsarray_total;
  public p_tag_style: any;
  displayValue = true;
  lineColor = '#000000';
  elementType: 'svg';
  format:
     | ''
     | 'CODE128'
     | 'CODE128A'
     | 'CODE128B'
     | 'CODE128C'
     | 'EAN'
     | 'UPC'
     | 'EAN8'
     | 'EAN5'
     | 'EAN2'
     | 'CODE39'
     | 'ITF14'
     | 'MSI'
     | 'MSI10'
     | 'MSI11'
     | 'MSI1010'
     | 'MSI1110'
     | 'pharmacode'
     | 'codabar';
  width = 1.0;
  barcodeheight = 23;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 15;
  background = '#ffffff'; 

  constructor(public toastr: ToastrService,public http: Http,public service:MenuViewService,public cdr: ChangeDetectorRef) {
    this.dateTxt = this.appointDate;
    this.insuranceFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.insurFlag = true;
    this.chequeFlag = true;
    this.transactionFlag = true;
    this.firstNameFlag = true;
    this.fromFlag = true;
    this.toFlag = true;
    this.uhidFlag = false;
    this.mobileFlag = true;
    this.mr_no_flag = false;
    this.discount_flag = false;
  }

  ngOnInit(): void {
    this.profile_image = "../../assets/img/default.jpg";
    this.saveFlag = true;
    this.dtOptions = {
      pageLength: 5,
      "lengthMenu": [ [5, 10, 25, 50, -1], [5, 10, 25, 50, "All"] ],
      "columnDefs": [
        { "orderable": false, "targets": [0,1,2,3,4,5] },
      ],
      "language": {
      "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };
   
    this.Moduleidlist = Helper_Class.getmodulelist();
    if (this.Moduleidlist != undefined && this.Moduleidlist.length != 0) {
      for (var i = 0; i < this.Moduleidlist.length; i++) {
        if (this.Moduleidlist[i].module_id == "7") {
          if (this.Moduleidlist[i].edit == "1") {
            this.editbutton = true;
          }                    
          if (this.Moduleidlist[i].create == "1") {
            this.newbutton = true;
          }
          if (this.Moduleidlist[i].delete == "1") {
            this.deletebutton = true;
          }
          if (this.Moduleidlist[i].print == "1") {
            this.printbutton = true;
          }
          if (this.Moduleidlist[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    }

    this.finalAmount = 0;
    this.amountGST = 0;
    this.advance = 0;
    this.concession = 0;
    this.totalBillAmount = 0;
    this.discount_amount = 0;
    this.inGST = false;
    this.gstRetrivelArray = [];
    this.estimateID = [];
    this.previousBalFlag = true;
    this.balanceFlag = true;
    this.getDate();
    this.getLocation();
    this.ipaddress = Helper_Class.getIPAddress();
    this.userInfo = Helper_Class.getInfo();
    console.log(this.userInfo);
    this.diagCenterID = this.userInfo.diag_center_id;
    this.diagID = this.userInfo.diag_center_id;
    this.diagLocation = this.userInfo.location_desc;
    this.userType = this.userInfo.user_type;
    this.hospitalLogo = ipaddress.Ip_with_img_address + this.userInfo.bill_print_logo;
    this.printlogostyle = this.userInfo.bill_print_logo_style;
    if (this.userInfo.bill_print_template != undefined) {
      this.print_template = this.userInfo.bill_print_template;
    } else {
      this.print_template = "noheader";
    }

    this.print_size = this.userInfo.bill_print_paper;

    if (this.userInfo.hptl_name == 'SGH') {
      this.p_tag_style = "text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;margin-top: 10px;";
    } else {
      this.p_tag_style = "text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;";
    }

    if(this.userType =="front-desk"){
      if(this.userInfo.bill_pay_type_req =="1"){
        this.paytypreq=true;
      } else {
        this.paytypreq=false;
      }
    } else {
      if(this.userInfo.hospitals[0].bill_pay_type_req =="1"){
        this.paytypreq=true;
      } else {
        this.paytypreq=false;
      }
    }  
    if (this.userInfo.bill_qty_flag == 0) {
      this.bill_qty_flag = true;
    } else {
      this.bill_qty_flag = false;
    }
    
    if (Diagnosis_Helper.getUserID_Type().app_type == 'outpatient') {
      this.inpatient_flag =  false;
    } else if(Diagnosis_Helper.getUserID_Type().app_type == 'inpatient') {
      this.inpatient_flag =  true;
    }

    if (this.userInfo.open_app_billing != null) {
      this.open_app_billing = this.userInfo.open_app_billing;
    }

    if (this.userInfo.first_name != undefined) {
      this.logUserName = this.userInfo.first_name;
      if (this.userInfo.last_name != undefined) {
        this.logUserName = this.userInfo.first_name+" "+this.userInfo.last_name;
      }
    }
    this.getPaymentType();
    this.getInsurers();
    this.getGST();
    this.getBillType();
    this.getCreditTypes();
    this.RateCardData();
    this.filter = "Patient_ID";  
    this.cdr.detectChanges();
    this.focusMRNoInput();
  }

  showCreditOption(){
    this.credit_flag = !this.credit_flag;
  }

  getCreditTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/ghco',
        JSON.stringify({
          country: ipaddress.country_code.toString(),
          hptl_clinic_id: this.userInfo.hptl_clinic_id,
          type: 'hospital',
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();

          if (obj.hosp_credits != null) {
            for (var i = 0; i < obj.hosp_credits.length; i++) {
              this.hosp_credits.push({
                credit_id: obj.hosp_credits[i].credit_id,
                credit_desc: obj.hosp_credits[i].credit_desc,
              });
            }
          }
        },
        (error) => {}
      );
  }

  getDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            this.currentDateTime = obj.current_date;
            var get_date = obj.current_date.split('-');
            this.appointDate = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
            this.currentDate = Date_Formate(obj.current_date); 
            this.currentTime = obj.current_time;            
            this.timevalue = Time_Formate(this.currentTime);          
            this.date = obj.current_date;
            this.appointDate =  this.currentDateTime;                    
          }
        },error => {});
  }

  getLocation() {//Get location
    this.Diag_Location_Data = [];
    var flag;   
    if (Diagnosis_Helper.getUserID_Type().user_type != null) {
      if(Diagnosis_Helper.getUserID_Type().user_type == "frontDesk"){
        this.userType = "frontDesk";
        flag = "name";

      }else{
        this.userType = "diag_user";
        flag = "name"; 
      }
    } else {
      flag = "name";
      this.userType = "diagnosis";
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/diagloc/',
      JSON.stringify({
        diag_name: Diagnosis_Helper.getUserID_Type().diag_centre_id,
        flag: flag,
        imei: this.ipaddress,
        prov_id: Diagnosis_Helper.getUserID_Type().user_id,
        type: this.userType
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.diag_loc != null && obj.diag_loc.length != 0) {
            for (var i = 0; i < obj.diag_loc.length; i++) {
              this.Diag_Location_Data.push({
                centre_id: obj.diag_loc[i].diag_centre_id,
                centre_name: obj.diag_loc[i].diag_centre_name,
                centre_location: obj.diag_loc[i].diag_loc,
                view_data: obj.diag_loc[i].diag_centre_name + " - " + obj.diag_loc[i].diag_loc
              });
            }
            this.location = this.Diag_Location_Data[0].view_data;
            this.getAppID(this.Diag_Location_Data[0].centre_id, this.Diag_Location_Data[0].centre_location,this.currentDateTime);
          }        
        },error => {});
  }

  //added
  RateCardData() {
    var requestData = {
      centre_id: this.userInfo.diag_center_id,
      center_type:'diagnosis',
    };
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');   
    this.http.post(ipaddress.getIp.toString() + 'mas/grcd', requestData, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();
          console.log(obj);
          var RateCard = obj.rate_cards;
          if (RateCard != undefined && RateCard.length != 0 ) {
            for (var i = 0; i < RateCard.length; i++) {
              this.RateCardArray.push({
                discount: RateCard[i].discount,
                rate_card_desc: RateCard[i].rate_card_desc,
                rate_card_id: RateCard[i].rate_card_id,
                default: RateCard[i].default
              });
            }
            this.rate_card = this.RateCardArray[0].rate_card_id;
            for(var i = 0; i < this.RateCardArray.length; i++) {
              if(this.RateCardArray[i].default == "1") {
                this.rate_card = this.RateCardArray[i].rate_card_id;
              }
            }
            this.getConcession();
          }
        },
        (error) => {
          this.toastr.error('An error occurred');
        }
      );
  }

  fnameToUpper() {
    if (this.firstName != undefined)
      this.firstName = this.firstName.toLocaleUpperCase().trim();
  }

  getPatientListByMRNo(patientId) {
    if(patientId != undefined && patientId.length > 8){
      this.searchPatient();
    }
  }

  getPatientListByMobile_no(mobileNo) {
    if(mobileNo != undefined && mobileNo.length > 9) {
      this.searchPatient();
    }
  }

  lnameToUpper() {
    if (this.lastName != undefined)
      this.lastName = this.lastName.toLocaleUpperCase().trim();
  }

  focusMRNoInput() {
    this.patientId = "";
    document.getElementById("focusMRNo").focus();
    this.focusMRNo.nativeElement.focus();
  }

  focusMobileNoInput() {
    this.mobileNo = "";
    document.getElementById("focusMobileNo").focus();
    this.focusMobileNo.nativeElement.focus();
  }

  focusFirst_nameInput() {
    this.firstName = "";
    document.getElementById("focusFName").focus();
    this.focusFName.nativeElement.focus();
  }

  changeFilter() {  
    this.billListArray = [];
    this.clearData();
    if (this.filter == "Date") {
      this.firstNameFlag = true;
      this.fromFlag = false;
      this.toFlag = false;
      this.uhidFlag = true;
      this.mobileFlag = true;
      this.mr_no_flag = false;
      this.searchPatient();
    } else if (this.filter == "Name") {
      this.firstNameFlag = false;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = true;
      this.mr_no_flag = true;
      this.cdr.detectChanges();
      this.focusFirst_nameInput();
    } else if (this.filter == "Patient_ID") {
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = false;
      this.mobileFlag = true;
      this.mr_no_flag = false;
      this.cdr.detectChanges();
      this.focusMRNoInput();
    } else if (this.filter == "Mobile_No") {
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = false;
      this.mr_no_flag = false;
      this.cdr.detectChanges();
      this.focusMobileNoInput();
    } 
  }

  changePayType() {
    this.insurFlag = true;
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.payType == '2') {
      this.transactionFlag = false;
      this.cardFlag = false;
      this.cardreqflag = true;
      this.transreqflag = true;
      this.chequereqflag = false;
      this.insreqflag = false;
    } else if (this.payType == '3') {
      this.cardreqflag = false;
      this.insurFlag = false;
      this.cardreqflag = false;
      this.transreqflag = false;
      this.chequereqflag = false;
      this.insreqflag = true;
    } else if (this.payType == '4') {
      this.transactionFlag = false;
      this.cardreqflag = false;
      this.transreqflag = true;
      this.chequereqflag = false;
      this.insreqflag = false;
    } else if (this.payType == '5') {
      this.transactionFlag = false;
      this.cardreqflag = false;
      this.transreqflag = true;
      this.chequereqflag = false;
      this.insreqflag = false;
    } else if (this.payType == '6') {
      this.transactionFlag = false;
      this.cardreqflag = false;
      this.transreqflag = true;
      this.chequereqflag = false;
      this.insreqflag = false;
    } else if (this.payType == '7') {
      this.chequeFlag = false;
      this.cardreqflag = false;
      this.transreqflag = false;
      this.chequereqflag = true;
      this.insreqflag = false;
    }
    this.changeAppID();
  }

  getConcession(){
    for (var i=0;i<this.RateCardArray.length;i++) {
      if (this.RateCardArray[i].rate_card_id == this.rate_card) {
        if (this.RateCardArray[i].rate_card_desc == 'Service wise') {         
          this.discount_flag = false;          
          if (this.concession != '') {
            this.concession = this.concession;
          } else {
            this.concession = parseFloat(this.RateCardArray[i].discount);
          }       
        } else {
          this.discount_flag = true;
        }
        if (this.RateCardArray[i].rate_card_desc != 'Service wise') {
          this.concession = parseFloat(this.RateCardArray[i].discount);
        }   
        this.flag_function();     
      }
    }
    
    if (this.addBillArray != undefined && this.addBillArray.length != 0) {
      for (var i=0;i<this.addBillArray.length;i++) {
        this.addBillArray[i].concession = this.concession;
        this.changeAmount(this.addBillArray[i],0);
      }
    }  
  }

  flag_function () {
    if (this.bill_qty_flag == true && !this.discount_flag == false && this.inpatient_flag == false) {
      this.multiply_flag = true;
    } else if (this.bill_qty_flag == true && !this.discount_flag == true && this.inpatient_flag == false) {
      this.multiply_flag = true;
    } else if (this.bill_qty_flag == true && !this.discount_flag == false && this.inpatient_flag == true) {
      this.multiply_flag = false;
    } else if (this.bill_qty_flag == true && !this.discount_flag == true && this.inpatient_flag == true) {
      this.multiply_flag = true;
    } else if (this.bill_qty_flag == false && !this.discount_flag == false && this.inpatient_flag == false) {
      this.multiply_flag = false;
    } else if (this.bill_qty_flag == false && !this.discount_flag == true && this.inpatient_flag == false) {
      this.multiply_flag = true;
    } else if (this.bill_qty_flag == false && !this.discount_flag == false && this.inpatient_flag == true) {
      this.multiply_flag = false;
    } else if (this.bill_qty_flag == false && !this.discount_flag == true && this.inpatient_flag == true) {
      this.multiply_flag = true;
    }

    if (this.bill_qty_flag == true && this.discount_flag == true && this.inpatient_flag == false) {
      this.amount_td_style = "text-align: right; border: 1px solid black;border-collapse: collapse;width: 300px";
    } else {
      this.amount_td_style = "text-align: right; border: 1px solid black;border-collapse: collapse";
    }
  }

  getamountAftCon1(amount) {
    var RateCard = this.RateCardArray;
    for (var i = 0; i < RateCard.length; i++) {
      if (this.rate_card == RateCard[i].rate_card_id) {
        if (RateCard[i].discount == 0 && RateCard[i].rate_card_desc != 'Service wise') {
          return (this.amountAftCon = parseFloat(amount) * this.quantity);
        } else {
          return (this.amountAftCon =
            parseFloat(amount) * this.quantity -
            (this.concession / 100) *
              (parseFloat(amount) * this.quantity));
        }
      }
    }
  }

  getAppointments(){
    this.getAppID(this.Diag_Location_Data[0].centre_id, this.Diag_Location_Data[0].centre_location,this.date);
  }

  deleteBill(data) {
    if (this.addBillArray.length != 0) {
      for (var i = 0; i < this.addBillArray.length; i++) {
        if (this.addBillArray[i].test_id == data.test_id) {
          this.addBillArray.splice(i, 1);
          this.calculateGST("0", "0");
          break;
        }
      }
      var sno=1;
      for (var i = 0; i < this.addBillArray.length; i++) {
        this.addBillArray[i].sno=sno;
        sno+=1;
      }
    }

    if (this.addBillArray.length == 0) {
      this.tableFlag = false;
      this.finalFlag = false;
    } else {
      this.tableFlag = true;
    }

    if (this.finalAmount == 0) {
      this.inGST == false;
    }
    this.calculateGST("0", "0");
  }
  
  getBillType() {//Get bill type
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/chgtype',
      JSON.stringify({
        provider: "diagnosis",
     }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.charges = obj.charges;
        },error => {});
  }

  searchPatient() {
    this.billListArray = []; 
    var fieldvalue;
    if (this.filter == "Patient_ID") {
      fieldvalue = {       
        diag_center_id: this.diagID,
        filt_type: "mr_no",
        filt_value: this.patientId,
        pat_type:Diagnosis_Helper.getUserID_Type().app_type    
      }
    } else if (this.filter == "Name") {
      fieldvalue = {      
        diag_center_id: this.diagID,
        filt_type: "name",    
        first_name: this.firstName,      
        last_name: this.lastName,
        pat_type:Diagnosis_Helper.getUserID_Type().app_type                    
      }
    } else if (this.filter == "Date") {
      fieldvalue = {
        diag_center_id: this.diagID,
        filt_type: "date",
        filt_value: this.date,
        pat_type:Diagnosis_Helper.getUserID_Type().app_type 
      }
    } else if (this.filter == "Mobile_No") {
      fieldvalue = {
        diag_center_id: this.diagID,
        filt_type: "mobile",
        filt_value: this.mobileNo,
        pat_type:Diagnosis_Helper.getUserID_Type().app_type     
      }
    }
    console.log(fieldvalue);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() +  'appointment/appbydate',fieldvalue,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log(obj);       
          if (obj.appointments != null && obj.appointments != 0) {
            for (var i = 0; i < obj.appointments.length; i++) {
              if (obj.appointments[i].middle_name != null && obj.appointments[i].middle_name != undefined) {
                this.diagName = obj.appointments[i].diag_appointment_id + " - " + encrypt_decript.Decript(obj.appointments[i].first_name) + " " + encrypt_decript.Decript(obj.appointments[i].middle_name) + " " + encrypt_decript.Decript(obj.appointments[i].last_name);
              } else if (obj.appointments[i].first_name != null && obj.appointments[i].first_name != undefined && obj.appointments[i].last_name != null && obj.appointments[i].last_name != undefined) {
                this.diagName = obj.appointments[i].diag_appointment_id + " - " + encrypt_decript.Decript(obj.appointments[i].first_name) + " " + encrypt_decript.Decript(obj.appointments[i].last_name);
              } else if (obj.appointments[i].first_name != null && obj.appointments[i].first_name != undefined) {
                this.diagName = obj.appointments[i].diag_appointment_id + " - " + encrypt_decript.Decript(obj.appointments[i].first_name);
              }              
              var doctor_name;
              if (obj.appointments[i].dr_first_name != undefined && obj.appointments[i].dr_middle_name != undefined && obj.appointments[i].dr_last_name != undefined) {
                doctor_name = "Dr."+ obj.appointments[i].dr_first_name + " " + obj.appointments[i].dr_middle_name + " " + obj.appointments[i].dr_last_name;
              } else if(obj.appointments[i].dr_first_name != undefined && obj.appointments[i].dr_last_name != undefined) {
                doctor_name = "Dr."+ obj.appointments[i].dr_first_name + " " + obj.appointments[i].dr_last_name;
              } else if (obj.appointments[i].dr_first_name != undefined) {
                doctor_name = "Dr."+ obj.appointments[i].dr_first_name;
              } else if (obj.appointments[i].dr_last_name != undefined) {
                doctor_name = "Dr."+ obj.appointments[i].dr_last_name;
              } else {
                doctor_name = "";
              }

              if (obj.appointments[i].doc_qualif != undefined && obj.appointments[i].doc_qualif != null && obj.appointments[i].doc_qualif != "") {
                doctor_name = doctor_name + " " + obj.appointments[i].doc_qualif;
              }

              this.inpatientFlag = obj.appointments[i].inpatient_id != undefined ? true : false;
              
              this.billListArray.push({
                doc_app_id: obj.appointments[i].diag_appointment_id,
                client_reg_id: obj.appointments[i].client_reg_id,
                relation_id: obj.appointments[i].relation_id,
                sub_rel_id: obj.appointments[i].sub_rel_id,
                first_name: obj.appointments[i].first_name,
                middle_name: obj.appointments[i].middle_name,
                last_name: obj.appointments[i].last_name,
                diagid_name: this.diagName,  
                doctor_name: doctor_name
              });
            }
          }

          if(this.billListArray != undefined && this.billListArray.length === 1) {
            for(var i = 0; i < this.billListArray.length; i++) {
              this.appoint = this.billListArray[i].doc_app_id;
              this.changeAppID();
            }        
          }
        },error => {});
  }

  getAppID(centerid, centerlocation, date) {
    this.billListArray = [];
    this.appDate=(this.date);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/appbydate',
      JSON.stringify({
        provider: "diagnosis",
        diag_center_id: centerid,
        location: centerlocation,
        session: "all",
        date:this.appDate,
        bill: "yes",
        pattype:Diagnosis_Helper.getUserID_Type().app_type,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();         
          if (obj.appointments != null && obj.appointments.length != 0) {
            for (var i = 0; i < obj.appointments.length; i++) {
              var patname;
              if(obj.appointments[i].first_name != undefined){
                if(obj.appointments[i].last_name != undefined){
                  if (obj.appointments[i].middle_name != undefined && encrypt_decript.Decript(obj.appointments[i].middle_name) != undefined) {
                    this.diagName = obj.appointments[i].diag_appointment_id + " - " + encrypt_decript.Decript(obj.appointments[i].first_name) + " " + encrypt_decript.Decript(obj.appointments[i].middle_name) + " " + encrypt_decript.Decript(obj.appointments[i].last_name);
                    patname = encrypt_decript.Decript(obj.appointments[i].first_name) + " " + encrypt_decript.Decript(obj.appointments[i].middle_name) + " " + encrypt_decript.Decript(obj.appointments[i].last_name);
                  }else{
                    this.diagName = obj.appointments[i].diag_appointment_id + " - " + encrypt_decript.Decript(obj.appointments[i].first_name) + " " + encrypt_decript.Decript(obj.appointments[i].last_name);
                    patname = encrypt_decript.Decript(obj.appointments[i].first_name) + " " + encrypt_decript.Decript(obj.appointments[i].last_name);
                  }
                }else{
                  this.diagName = obj.appointments[i].diag_appointment_id + " - " + encrypt_decript.Decript(obj.appointments[i].first_name);
                  patname = encrypt_decript.Decript(obj.appointments[i].first_name)
                }                
              }    
    
              this.inpatientFlag = obj.appointments[i].inpatient_id != undefined ? true : false;
              this.billListArray.push({
                doc_app_id: obj.appointments[i].diag_appointment_id,
                client_reg_id: obj.appointments[i].client_reg_id,
                relation_id: obj.appointments[i].relation_id,
                sub_rel_id: obj.appointments[i].sub_rel_id,
                first_name: obj.appointments[i].first_name,
                middle_name: obj.appointments[i].middle_name,
                last_name: obj.appointments[i].last_name,
                diagid_name: this.diagName,
                patname:patname,
              });
            }
          }else{
            this.toastr.error(Message_data.noAptForBillCreation);
          }
        },
        error => {});
  }

  changeAppID() {
    this.mrno = "";
    this.patient_name = "";
    this.clnt_age = "";
    this.gender = "";
    this.patient_address1 = "";
    this.patient_address2 = "";
    this.location = "";
    this.city = "";
    this.state = "";
    this.mobile_no = "";
    this.paymentsarray = [];
    this.em_contact = "";
    this.pat_reg_date = "";
    this.profile_image = "";
    this.billDenPlanArray = [];
    if (this.billListArray.length != 0) {
      for (var i = 0; i < this.billListArray.length; i++) {
        if (this.billListArray[i].doc_app_id == this.appoint) {
          this.appID = this.billListArray[i].doc_app_id;
          this.clientName = this.billListArray[i].patname;
          this.doctor_name = this.billListArray[i].doctor_name;
        }
      }
    }
   
    function StringBuilder(this:any,value) {
      this.strings = new Array("");
      this.append(value);
    }

    StringBuilder.prototype.append = function (value) {
      if (value) {
        this.strings.push(value);
      }
    }

    StringBuilder.prototype.clear = function () {
      this.strings.length = 1;
    }

    StringBuilder.prototype.toString = function () {
      return this.strings.join("");
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/viewapp',
      JSON.stringify({
        diag_app_id: this.appID,
        type: "diagnosis",
        country: "IN"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var sb = new StringBuilder("")
          var obj = JSON.parse(response["_body"]);
          console.log("PatientDetails",obj);
          if (obj.centre_name != null) {
            sb.append(obj.centre_name);
            sb.append("\n");
            sb.append(obj.address1);
            sb.append("\n");
            if (obj.address2 != null && obj.address2 != "") {
              sb.append(obj.address2);
              sb.append("\n");
            }

            sb.append(obj.location);
            sb.append("\n");

            sb.append(obj.city + " , " + obj.state);
            sb.append("\n");

            sb.append(obj.country + "-" + obj.zipcode);
            sb.append("\n");

            sb.append(obj.phone);
            sb.append("\n");

            this.diagCenterID = obj.centre_id;
          }

          if (obj.client_reg_id != null) {
            this.clientID = obj.client_reg_id;
          }

          if (obj.relation_id != null) {
            this.relationID = obj.relation_id;
          }

          if (obj.sub_rel_id != null) {
            this.subRelationID = obj.sub_rel_id;
          }

          if (obj.pres_diag_id != null && obj.pres_diag_id != undefined) {
            this.pres_diag_id = obj.pres_diag_id;
          }

          this.wardName = obj.ward_name != undefined ? obj.ward_name : "";
          this.bed_no = obj.bed_no != undefined ? obj.bed_no : "";
          this.discharge_date = obj.discharge_date != undefined ? obj.discharge_date : "";
          if(obj.discharge_time != undefined && obj.discharge_time != "") { 
            this.discharge_time = Time_Formate(obj.discharge_time);
          } else {
            this.discharge_time = "";
          }

          if (obj.first_name != undefined && obj.middle_name != undefined && obj.last_name != undefined) {
            this.clientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
          } else if (obj.first_name != undefined && obj.last_name != undefined){
            this.clientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
          } else if (obj.first_name != undefined) {
            this.clientName = encrypt_decript.Decript(obj.first_name);
          }

          this.patient_name = this.clientName;
          if(obj.profile_image != ""){
            this.profile_image = ipaddress.Ip_with_img_address + obj.profile_image;
          }

          this.em_contact = obj.emerg_contact != undefined ? encrypt_decript.Decript(obj.emerg_contact) : "";
          this.mrno = obj.patient_id;
          if (obj.date != undefined && obj.f_time != undefined) {
            this.pat_reg_date = Date_Formate(obj.date)+" "+Time_Formate(obj.f_time);
          }
         
          if (obj.salutation != undefined) {
            this.clnt_saldesc = obj.salutation+".";
          }         
          if (obj.age != undefined) {
            this.clnt_age = obj.age;
          }
          
          if ( obj.gender != null &&  obj.gender != '0: undefined') {
            this.gender = encrypt_decript.Decript(obj.gender);
            this.gender = this.gender[0];
            if (this.gender!= undefined && this.gender[0] == "T") {
              this.gender = "Tgen";
            }
          }
          this.mobile_no = obj.mobile != undefined ? encrypt_decript.Decript(obj.mobile) : "";

          if (obj.address2 != null && obj.address2 != undefined && obj.address1 != undefined) {
            this.patient_address1 = encrypt_decript.Decript(obj.address1)+ ",";
            if(obj.address2 != " " && obj.address2 != "null") {
              if (encrypt_decript.Decript(obj.address2) == "") {
                this.patient_address2 == "";
              } else {
                this.patient_address2 = encrypt_decript.Decript(obj.address2)+",";
              }
            }
          } else if (obj.address1 != undefined) {
            this.patient_address1 = encrypt_decript.Decript(obj.address1)+",";
          } else if (obj.address2 != undefined) {
            this.patient_address2 = encrypt_decript.Decript(obj.address2)+",";
          }

          if (obj.location != undefined) {
            this.Location = obj.location+",";
          }

          if (obj.city != undefined) {
            this.city = obj.city+"-";
          }

          if(obj.zipcode != undefined) {
            this.zipcode = encrypt_decript.Decript(obj.zipcode)+",";
          }

          if (obj.state != undefined) {
            this.state = obj.state+"-";
          }   
          
          if(obj.country != undefined) {
            this.country = obj.country+".";
          }  

          if (obj.barcode_image != undefined && obj.barcode_image != null) {
            this.barcode_flag = true;
            this.barcode_url = ipaddress.Ip_with_img_address + obj.barcode_image;
          }
          
          var paydesc;
          if (this.payTypeArray.length != 0) {
            for (var i = 0; i < this.payTypeArray.length; i++) {
              if (this.payType == this.payTypeArray[i].pay_id) {
                paydesc = this.payTypeArray[i].pay_desc;
              }
            }
          }

          this.testArray = [];
          this.addBillArray=[];
          this.finalAmount = 0;
          if (obj.lab_test != null) {           
            for (var i = 0; i < obj.lab_test.length; i++) {
              if (this.paytypreq == true) {
                this.addBillArray.push({
                  test_id:obj.lab_test[i].diag_test_id,
                  test_name: obj.lab_test[i].diag_test_name,
                  type_of_test:"test",
                  fee: this.getamountAftCon1(obj.lab_test[i].cost),
                  quantity: this.quantity,
                  concession: this.concession,
                  amount_txt: parseFloat(obj.lab_test[i].cost),
                  total: (parseFloat(this.quantity) * parseFloat(obj.lab_test[i].cost)),
                  pay_type: paydesc,
                  paytype: this.payType,
                  insurer: this.insurerId,
                  ref_code: this.transId,
                });
              } else {
                this.addBillArray.push({
                  test_id:obj.lab_test[i].diag_test_id,
                  test_name: obj.lab_test[i].diag_test_name,
                  type_of_test:"test",
                  fee: this.getamountAftCon1(obj.lab_test[i].cost),
                  quantity: this.quantity,
                  concession: this.concession,
                  amount_txt: parseFloat(obj.lab_test[i].cost),
                  total: (parseFloat(this.quantity) * parseFloat(obj.lab_test[i].cost)),
                  pay_type: paydesc,
                  paytype: this.payType,
                  insurer: this.insurerId,
                  ref_code: this.transId,
                });
              }              
              this.addBillArray.forEach(value => this.changeAmount(value,0));
            }
          }

          if (obj.profile_test != null) {

            for (var i = 0; i < obj.profile_test.length; i++) {
              if (this.paytypreq == true) {
                this.addBillArray.push({
                  test_id:obj.profile_test[i].diag_test_id,
                  test_name: obj.profile_test[i].diag_test_name,
                  type_of_test:"profiles",
                  fee: this.getamountAftCon1(obj.profile_test[i].cost),
                  quantity: this.quantity,
                  concession: this.concession,
                  amount_txt: parseFloat(obj.profile_test[i].cost),
                  total: (parseFloat(this.quantity) * parseFloat(obj.profile_test[i].cost)),
                  pay_type: paydesc,
                  paytype: this.payType,
                  insurer: this.insurerId,
                  ref_code: this.transId,
                });
              } else {    
                this.addBillArray.push({
                  test_id:obj.profile_test[i].diag_test_id,
                  test_name: obj.profile_test[i].diag_test_name,
                  type_of_test:"profiles",
                  fee: this.getamountAftCon1(obj.profile_test[i].cost),
                  quantity: this.quantity,
                  concession: this.concession,
                  amount_txt: parseFloat(obj.profile_test[i].cost),
                  total: (parseFloat(this.quantity) * parseFloat(obj.profile_test[i].cost)),
                  insurer: this.insurerId,
                  ref_code: this.transId,
                });
              }            
              this.addBillArray.forEach(value => this.changeAmount(value,0));
            }
          }

          if (obj.radio_test != null) {
            for (var i = 0; i < obj.radio_test.length; i++) {
              if (this.paytypreq == true) {
                this.addBillArray.push({
                  test_id:obj.radio_test[i].diag_test_id,
                  test_name: obj.radio_test[i].diag_test_name,
                  type_of_test:"Radiology",
                  fee: this.getamountAftCon1(obj.radio_test[i].cost),
                  quantity: this.quantity,
                  concession: this.concession,
                  amount_txt: parseFloat(obj.radio_test[i].cost),
                  total: (parseFloat(this.quantity) * parseFloat(obj.radio_test[i].cost)),    
                  pay_type: paydesc,
                  paytype: this.payType,
                  insurer: this.insurerId,
                  ref_code: this.transId,          
                });
              } else {
                this.addBillArray.push({
                  test_id:obj.radio_test[i].diag_test_id,
                  test_name: obj.radio_test[i].diag_test_name,
                  type_of_test:"Radiology",
                  fee: this.getamountAftCon1(obj.radio_test[i].cost),
                  quantity: this.quantity,
                  concession: this.concession,
                  amount_txt: parseFloat(obj.radio_test[i].cost),
                  total: (parseFloat(this.quantity) * parseFloat(obj.radio_test[i].cost)),    
                  insurer: this.insurerId,
                  ref_code: this.transId,          
                });
              }            
              this.addBillArray.forEach(value => this.changeAmount(value,0));
            }
          }

          if(this.addBillArray.length != 0){
            for(var i= 0 ;i<this.addBillArray.length;i++){
              this.addBillArray[i].sno= i+1;
              this.addBillArray[i].charge_type=this.addBillArray[i].test_name;
              this.addBillArray[i].fee = this.addBillArray[i].fee;
            }
          }

          if (obj.advance != null) {
            this.advance = obj.advance;
          }

          if (obj.prev_bill_id != null) {
            this.previousBillID = obj.prev_bill_id;
            this.previousBalFlag = true;
          }
          if (obj.balance != undefined) {
            this.amountFlag = true;
            this.balanceFlag = false;
            this.previousBalFlag = false;
            this.balance = obj.balance;
            this.finalAmount = obj.balance;
            this.total = obj.balance;
          }
          else {
            this.amountFlag = false;
            this.balanceFlag = true;
            this.previousBalFlag = true;
            this.balance = obj.balance;
            this.finalAmount = obj.balance;
            this.total = obj.balance;
          }
          this.createpage_flag = true;
          this.calculateGST("0","0");
        },error => {});
  }

  changeTest(diag_test_name) {//test change
    this.amount = undefined;
    this.fees = 0;
    for (var i = 0; i < this.testArray.length; i++) {
      if (diag_test_name == this.testArray[i].Testname) {
        this.amount = this.testArray[i].cost
      }
    }

    var perce = this.concession / 100;
    var amt = this.amount;
    var amt_cons = this.fees;
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }

    this.fees = Math.round(tot);
  }

  changeAmount1() {// concession calculation
    var perce = null;
    perce = this.concession / 100;
    var amt = this.amount;
    if(this.quantity > 1){
      amt = amt * this.quantity;
    }

    var amt_cons = this.fees;
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }
    this.fees = Math.round(tot);
  }

  changeAmount(charge,num) {
    for (var i = 0; i < this.addBillArray.length; i++) {
      if (charge.test_name == this.addBillArray[i].test_name) {
        if (num != 2) {
          var concec;       
          if (charge.concession == 'NaN') {
            charge.concession = 0;
            concec = 0;
          } else {
            concec = charge.concession;
          }
          charge.fee = (charge.quantity * (charge.amount_txt - charge.amount_txt * (concec / 100)));
          charge.fee = parseFloat(charge.fee);

          charge.total = (charge.quantity * charge.amount_txt);
          charge.total = parseFloat(charge.total);
        } else {
          var fee;
          if (charge.fee == 'NaN') {
            charge.fee = 0;
            fee = 0;
          } else {
            fee = charge.fee;
          }
           
          if (charge.amount_txt != 0 && charge.quantity != 0 && charge.fee != '') {
            charge.concession = (100 - ((fee / (charge.amount_txt * charge.quantity)) * 100));
          } else {
            charge.concession = 0;
          }
        }
      }
    }
    this.calculateGST("0",num);
  }

  getGST() {//Get gst
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/tax/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.gstRetrivelArray = obj.taxes;
          console.log(this.gstRetrivelArray);
        },error => {});
  }

  includeGST(check) {// Include GST
    this.calculateGST("0", "0");
  }

  calculateGST(palnid,num) {
    this.finalAmount = 0;
    this.amountGST = 0;
    this.total = 0;
    this.GSTArray = [];
    this.totalBillAmount = 0;
    if (num != 1) {
      this.discount_amount = 0;
    }   
    if (this.billDenPlanArray.length != 0) {
      for (var i = 0; i < this.billDenPlanArray.length; i++) {
        for (var j = 0; j < this.estimateID.length; j++) {
          if (this.estimateID[j] == this.billDenPlanArray[i].den_treatment_plan_id) {
            this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.billDenPlanArray[i].estimate));
            this.amountGST = (parseFloat(this.amountGST) + parseFloat(this.billDenPlanArray[i].estimate));

            if (parseFloat(this.advance) < parseFloat(this.finalAmount)) {
              this.balanceFlag = false;
              this.total = (parseFloat(this.finalAmount) - parseFloat(this.advance));
              this.remaingBalance = (parseFloat(this.finalAmount) - parseFloat(this.advance));
            }

            if (parseFloat(this.advance) > parseFloat(this.finalAmount)) {
              this.balanceFlag = false;
              this.remaingBalance = (parseFloat(this.advance) - parseFloat(this.finalAmount));
            }

            this.amountFlag = true;

            if (this.total == 0) {
              this.amountFlag = false;
            }

            if (this.remaingBalance == 0) {
              this.balanceFlag = true;
            }
            break;
          }
        }
      }
    }
    if (this.addBillArray.length != 0) {
      for (var i = 0; i < this.addBillArray.length; i++) {
        if (this.addBillArray[i].fee == '') {
          this.addBillArray[i].fee = 0;
        }
        if (this.addBillArray[i].amount_txt == '') {
          this.addBillArray[i].amount_txt = 0;
        }
        if (this.addBillArray[i].quantity == '') {
          this.addBillArray[i].quantity = 0;
        }
        if (this.addBillArray[i].concession == '') {
          this.addBillArray[i].concession = 0;
        }
        if (num != 1) {
          this.discount_amount = (parseFloat(this.discount_amount) + (parseFloat (this.addBillArray[i].amount_txt) * parseFloat(this.addBillArray[i].quantity)) * (parseFloat(this.addBillArray[i].concession)/ 100));
        }
        this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.addBillArray[i].fee)); 
        this.totalBillAmount = (parseFloat(this.totalBillAmount) + (parseFloat(this.addBillArray[i].amount_txt) * parseFloat(this.addBillArray[i].quantity)));      
        this.amountGST = (parseFloat(this.amountGST) + parseFloat(this.addBillArray[i].fee));
  
        if (parseFloat(this.advance) < parseFloat(this.finalAmount)) {
          this.balanceFlag = false;
          this.remaingBalance = (parseFloat(this.finalAmount) - parseFloat(this.advance));
        }
  
        if (parseFloat(this.advance) > parseFloat(this.finalAmount)) {
          this.balanceFlag = false;
          this.remaingBalance = (parseFloat(this.advance) - parseFloat(this.finalAmount));
          this.total = 0;
        }
  
        if (this.total == 0) {
          this.amountFlag = false;
        }
  
        if (this.remaingBalance == 0) {
          this.balanceFlag = true;
        }
      }
      if (this.inGST == false) {
        this.finalAmount = Math.round(parseFloat(this.finalAmount));
      }
      
      this.finalAmount = parseFloat(this.finalAmount);
      if (this.discount_amount != '' && this.discount_amount != 0) {          
        var floatValue = this.discount_amount;
        var floatString = floatValue.toString();
        var parts = floatString.split('.');
        var digitsAfterDecimal = parts[1] ? parts[1] : 0;
        if (digitsAfterDecimal[0] > 5) {
          this.discount_amount = Math.round(this.discount_amount);
          this.discount_amount = parseInt(this.discount_amount); 
        } else {
          this.discount_amount = parseInt(this.discount_amount);
        }                       
      }   
      for (var i = 0; i < this.addBillArray.length; i++) {
        if (
          this.addBillArray[i].fee == 0 &&
          this.addBillArray[i].test_name == ''
        ) {
          this.addBillArray[i].fee = '';
        }
        if (this.addBillArray[i].amount_txt == 0 && this.addBillArray[i].test_name == '') {
          this.addBillArray[i].amount_txt = '';
        }
        if (this.addBillArray[i].quantity == 0 && (this.addBillArray[i].test_name == '' || this.addBillArray[i].test_name != '')) {
          this.addBillArray[i].quantity = '';
        }
        if (this.addBillArray[i].total == 0 && this.addBillArray[i].test_name == '') {
          this.addBillArray[i].total = '';
        }
        if (this.addBillArray[i].test_name == '') {
          this.addBillArray[i].concession = '';
        }

        if (this.addBillArray[i].concession != '') {   
          var floatValue = this.addBillArray[i].concession;
          var floatString = floatValue.toString();
          var parts = floatString.split('.');
          var digitsAfterDecimal = parts[1] ? parts[1].length : 0;
  
          if (digitsAfterDecimal === 2) { 
            // console.log("Value has 2 digits after the decimal point.");
          } else if (digitsAfterDecimal === 3) {
            this.addBillArray[i].concession = parseFloat(this.addBillArray[i].concession);
            // console.log("Value has 3 digits after the decimal point.");
          } else {
            // console.log("Value doesn't have 2 or 3 digits after the decimal point.");
          }
        }
      }
    }
    
    if (this.inGST == true) {
      for (var i = 0; i < this.gstRetrivelArray.length; i++) {
        this.GSTArray.push({
          Gst_Descrip: this.gstRetrivelArray[i].tax_desc + " " + '('+parseInt(this.gstRetrivelArray[i].tax)+' %'+')',
          Gst_amount: Math.round(parseFloat(this.amountGST) * (parseFloat(this.gstRetrivelArray[i].tax) / 100))
        });
        this.finalAmount = (parseFloat(this.finalAmount) + Math.round(parseFloat(this.amountGST) * (parseFloat(this.gstRetrivelArray[i].tax) / 100)));
        this.totalBillAmount = (parseFloat(this.totalBillAmount) + Math.round(parseFloat(this.amountGST) * (parseFloat(this.gstRetrivelArray[i].tax) / 100)));
      }
      
      this.finalAmount = Math.round(parseFloat(this.finalAmount));
      this.totalBillAmount = parseFloat(this.totalBillAmount);
    }

    if (this.balance != undefined) {
      this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.balance));
      this.totalBillAmount = (parseFloat(this.totalBillAmount) + parseFloat(this.balance));
    }

    //amount to be collected
    this.remaingBalance = (parseFloat(this.finalAmount) - parseFloat(this.advance));

    if (this.estimateID.length == 0 && this.addBillArray.length == 0) {
      this.inGST = false;
      this.GSTArray = [];
      this.advance = 0;
      this.finalAmount = 0;
      this.discount_amount = 0;
      this.totalBillAmount = 0;
      this.amountGST = 0;
      this.total = 0;
      this.remaingBalance = 0;
    }

    this.changeAdvance();
    setTimeout(()=>{
      this.changePaid(); 
    },500);   
  }

  changeDiscount() {
    if (this.discount_amount != '') {
      this.finalAmount = 0;
      this.totalBillAmount = 0; 
      if (this.addBillArray.length != 0) {   
        for (var i = 0; i < this.addBillArray.length; i++) {
          if (this.addBillArray[i].fee == '') {
            this.addBillArray[i].fee = 0;
          }
          if (this.addBillArray[i].amount_txt == '') {
            this.addBillArray[i].amount_txt = 0;
          }
          if (this.addBillArray[i].quantity == '') {
            this.addBillArray[i].quantity = 0;
          }
          if (this.addBillArray[i].concession == '') {
            this.addBillArray[i].concession = 0;
          }
 
          this.finalAmount = (
            parseFloat(this.finalAmount) + parseFloat(this.addBillArray[i].amount_txt)
          );    

          this.totalBillAmount = (
            parseFloat(this.totalBillAmount) +
            parseFloat(this.addBillArray[i].amount_txt) *
              parseFloat(this.addBillArray[i].quantity)
          );                
        }  
      }

      if (this.discount_amount <= this.finalAmount) {
        this.finalAmount = (parseFloat(this.finalAmount) - parseFloat(this.discount_amount));
        if (this.inGST == false) {
          this.finalAmount = Math.round(parseFloat(this.finalAmount));
        }
        this.finalAmount = parseFloat(this.finalAmount);
      }        
      this.calculatePercentage();
    }

    if (this.discount_amount == '') {
      this.discount_amount = 0;
    }
  }

  calculatePercentage() {
    if (this.totalBillAmount && this.discount_amount) {
      this.concession = ((this.discount_amount / this.totalBillAmount) * 100).toFixed(3);
    } else {
      this.concession = 0;
    }
    
    if (this.concession != '') {     
      if (this.addBillArray != undefined && this.addBillArray.length != 0) {
        for (var i = 0; i < this.addBillArray.length; i++) {
          this.addBillArray[i].concession = this.concession;
          this.changeAmount(this.addBillArray[i],1);
        }
      }
      console.log("Calculated_Concession",this.concession);
    }      
  }

  changeAdvance() {
    this.advance = this.advance == "" ? 0 : this.advance;
    this.remaingBalance = ((parseFloat(this.finalAmount)) - (parseFloat(this.paidBill) + parseFloat(this.advance)));
  }

  changePaid() {
    if (this.paymentsarray.length == 0) {
      this.payType1 = this.payTypeArray[0].pay_id;
    } else {
      this.paymentsarray_total = 0;
      for (var i = 0;i < this.paymentsarray.length; i++) {
        this.paymentsarray_total = (parseFloat(this.paymentsarray_total) + parseFloat(this.paymentsarray[i].amount));
      }  
      
      if (this.paymentsarray_total > this.finalAmount)  {
        this.paymentsarray = [];
        this.payType1 = this.payTypeArray[0].pay_id;
      } 
    }
    this.paidBill = this.paidBill == "" ? 0 : this.paidBill;
    this.remaingBalance = ((parseFloat(this.finalAmount)) - (parseFloat(this.paidBill) + parseFloat(this.advance)));
    if(parseFloat(this.paidBill) > this.finalAmount){
      this.amountval = parseFloat(this.finalAmount);
      this.numToWords = convertNumberToWords(this.finalAmount,ipaddress.country_code);
    } else {
      this.amountval = parseFloat(this.paidBill);
      this.numToWords = convertNumberToWords(this.paidBill,ipaddress.country_code);
    }

    if (this.paidBill != undefined && this.paidBill != "") {    
      if (parseFloat(this.paidBill) > parseFloat(this.finalAmount)) {
        if (this.paymentsarray.length == 0) {
          this.amountval = parseFloat(this.finalAmount);
        } else {
          var payArr_Amt = 0;
          for (var i = 0; i < this.paymentsarray.length; i++) {
            payArr_Amt += parseFloat(this.paymentsarray[i].amount);
          }

          if (payArr_Amt > parseFloat(this.finalAmount)) {
            this.amountval = parseFloat(this.finalAmount);
          } else {
            this.amountval = (parseFloat(this.finalAmount) - payArr_Amt);
          }
        }
      } else {
        if (this.paymentsarray.length == 0) {
          this.amountval = parseFloat(this.paidBill);
        } else {
          var payArr_Amt = 0;
          for (var i = 0; i < this.paymentsarray.length; i++) {
            payArr_Amt += parseFloat(this.paymentsarray[i].amount);
          }

          if (payArr_Amt > parseFloat(this.paidBill)) {
            this.amountval = parseFloat(this.paidBill);
          } else {
            this.amountval = (parseFloat(this.paidBill) - payArr_Amt);
          }
        }
      }
    } else {
      this.paidBill = 0;
      this.remaingBalance = this.finalAmount;
    }
  }

  saveBill() { 
    this.getDate();
    Diagnosis_Helper.setbillcreate(null);
    var flag = true;
    var total_paid_amount: any = 0;
    if (parseInt(this.paidBill) != 0 && this.paidBill != "") {
      if (this.paymentsarray.length == 0) {
        this.toastr.error("Please Check the payment details");
        flag = false;
      } else if (this.paymentsarray.length != 0) {
        for (var p = 0; p < this.paymentsarray.length; p++) {
          total_paid_amount = parseFloat(total_paid_amount) + parseFloat(this.paymentsarray[p].amount);
        }
        console.log("this.paidBill  ",parseFloat(this.paidBill)+ "   " + "total_paid_amount  ",parseFloat(total_paid_amount));
        if (parseFloat(this.paidBill) != parseFloat(total_paid_amount)) {
          this.toastr.error("Please Check the payment details");
          flag = false;
        }
      } else {
        console.log("this.paidBill  ",parseFloat(this.paidBill)+ "   " + "total_paid_amount  ",parseFloat(total_paid_amount));
        flag = true;
      }
    }
    if (this.addBillArray.length == 0) {
      this.toastr.error(Message_data.addbill);
      flag = false;
    } else {
      var paytype;
      var instype;
      for (var i = 0; i < this.addBillArray.length; i++) {
        paytype = this.addBillArray[i].paytype;
        instype = this.addBillArray[i].insurer;     
        if (this.addBillArray[i].quantity == '') {
          this.addBillArray[i].quantity = 0;
        }       
        if (this.addBillArray[i].test_name != '' && (this.addBillArray[i].amount_txt == 0 || this.addBillArray[i].amount_txt == 0 || this.addBillArray[i].amount_txt == '0')) {
          this.toastr.error('Test charge cannot be zero');
          flag = false;
          break;
        }
      }
    }

    if (flag == true) {
      if (this.billListArray.length != 0) {
        for (var i = 0; i < this.billListArray.length; i++) {
          if (this.appoint == this.billListArray[i].diagid_name) {
            this.clientID = this.billListArray[i].client_reg_id;
            this.relationID = this.billListArray[i].relation_id;
            if (this.billListArray[i].sub_rel_id != undefined) {
              this.subRelationID = this.billListArray[i].sub_rel_id;
            }
            this.appID = this.billListArray[i].doc_app_id;
          }
        }
      }

      this.addvance = this.advance != undefined ? this.advance : 0;

      if (this.inGST == true && this.gstRetrivelArray.length != 0) {
        this.centralGST = this.gstRetrivelArray[0].tax;
        this.stateGST = this.gstRetrivelArray[1].tax;
      }
     
      var cbill;
      var credit_person;
      if (this.credit_bill == true) {
        cbill = this.credit_type;
        credit_person = this.credit_person;
      } 

      if (this.credit_type == '') {
        this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -35px;";
        this.nobanner_style = "width: 100%; height: 95px;";
      } else {
        this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -45px;";
        this.nobanner_style = "width: 100%; height: 110px;";
      }

      Diagnosis_Helper.setbillcreate( this.finalAmount);
      var app_type;
      app_type = this.userType == "frontDesk" ? "frontdesk" : "diagnosis";

      this.remaingBalance = parseInt(this.remaingBalance) <= 0 ? 0 : this.remaingBalance.toString();
      var status =  parseInt(this.remaingBalance) <= 0 ? "0" : "1";
      var paid_flag;
      if (parseInt(this.addvance) != 0) {
        if (parseInt(this.finalAmount) != 0 &&
          parseInt(this.addvance) != 0 && parseInt(this.finalAmount) != parseInt(this.addvance) && parseInt(this.remaingBalance) > 0) { 
            paid_flag = 'Partially paid';
        } else if (parseInt(this.finalAmount) == parseInt(this.remaingBalance)) {
            paid_flag = 'Un paid';
        } else if (
          this.remaingBalance == 0 ||
          this.remaingBalance == '0' || parseInt(this.remaingBalance) <= 0 
        ) {
          paid_flag = 'Fully paid';
        }
      } else {
        if (parseInt(this.finalAmount) != 0 &&
            parseInt(this.paidBill) != 0 && parseInt(this.remaingBalance) > 0) { 
            paid_flag = 'Partially paid';
        } else if (parseInt(this.finalAmount) == parseInt(this.remaingBalance)) {
            paid_flag = 'Un paid';
        } else if (
          this.remaingBalance == 0 ||
          this.remaingBalance == '0' || parseInt(this.remaingBalance) <= 0 
        ) {
          paid_flag = 'Fully paid';
        }
      }

      if (parseFloat(this.paidBill) > parseFloat(this.finalAmount)) {
        this.paidBill = this.finalAmount;
        paid_flag = 'Fully paid';
      }

      var finalAmount;
      var temp_discount_amount = parseFloat(this.discount_amount).toFixed(2);
      var temp_final_amount = parseFloat(this.finalAmount).toFixed(2);

      if (parseInt(this.finalAmount) == 0) {
        finalAmount = encrypt_decript.Encript(temp_discount_amount).toString();
      } else {
        finalAmount = encrypt_decript.Encript(temp_final_amount).toString();
      }

      var billcreate_value =   JSON.stringify({
        pres_diag_id: this.pres_diag_id,
        diag_centre_id: this.diagCenterID,
        appointment_id: this.appID,
        app_type: app_type,
        client_reg_id: this.clientID,
        relation_id: this.relationID,
        sub_rel_id: this.subRelationID,
        bill_amount: finalAmount,
        advance: this.addvance,
        balance: this.remaingBalance,
        prev_bal: this.balance,
        created_by: Diagnosis_Helper.getUserID_Type().user_id,
        cgst: this.centralGST,
        sgst: this.stateGST,
        bills: this.addBillArray,
        country: ipaddress.country_code,
        prev_bill_id: this.previousBillID,
        paid_amt:this.paidBill,
        paid_flag : paid_flag,
        inpatient_flag : this.inpatientFlag,
        pay_type: paytype,
        insurer: instype,
        card_no: this.cardNumber,
        card_holder_name: this.cardHolderName,
        transaction_no: this.transactionId,
        bank_name: this.bankName,
        payments:this.paymentsarray,
        credit_type: cbill,
        credit_person: credit_person,
        rate_card: this.rate_card,
        status: status,
        discount: this.discount_amount,
        open_app_billing: this.open_app_billing,
        bill_type:"OP"
      });     

      console.log(billcreate_value);
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/savebill/',billcreate_value,
        { headers: headers })
        .subscribe(
          response => {           
            var obj = response.json();
            console.log("BillResponse",obj);         
            if (obj.key === "1") {
              this.billId = obj.bill_no;               
              if(this.temp_key === "print"){
                this.cdr.detectChanges();                                
                this.print_area1();                         
              }   
              this.saveFlag = false;
              this.toastr.success("Bill generated successfully");
              // this.service.sendMessage("frontdeskbilling");
              this.backClicked();
            }  
          },error => {
            this.toastr.error("Unable to generate bill,please try later");
          });
    } else {
      
    }
  }
 
  print_area(print) {
    this.temp_key = print;
    var flag = true;
    if (this.addBillArray.length == 0 && this.estimateID.length == 0) {
      this.toastr.error(Message_data.getAddBill());
    } else {
      if (true) { 
        this.saveBill();           
      }
    }
  }

  print_area1() {
    var margin_top;
    let printContents, popupWin;
    if (this.print_template != undefined && this.print_template == "banner") {
      printContents = this.printbanner.nativeElement.innerHTML; 
      margin_top = '0px';
    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
      margin_top = '122px';
    }
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>                        
          @page {
            size: auto;
            margin-top: ${margin_top};
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 0px;
            overflow: visible;
          } 
          * {
              font-size: 12px !important;
              line-height: 1 !important;
              page-break-inside: always;
              overflow: visible;
            }                                     
          @media print {   
            body {
              height: auto; /* Allow body height to adjust dynamically */
            }          
            .page-break {
              page-break-before: always; /* or page-break-after: always; */
              page-break-inside: always;
            }
          }           
          .address_visibility {
            visibility: hidden;
          }        
          table.report-container {
            page-break-after: always;
          }
          thead.report-header {
            display: table-header-group;
          }
          tfoot.report-footer {
            display: table-footer-group;
          }
          .print:last-child {
            page-break-after: auto;
          }
          .alignRight {
            text-align: right;
          }
          .col-print-1 {width:8%;  float:left;}
          .col-print-2 {width:16%; float:left;}
          .col-print-3 {width:25%; float:left;}
          .col-print-4 {width:33%; float:left;}
          .col-print-5 {width:42%; float:left;}
          .col-print-6 {width:50%; float:left;}
          .col-print-7 {width:58%; float:left;}
          .col-print-8 {width:66%; float:left;}
          .col-print-9 {width:75%; float:left;}
          .col-print-10 {width:83%; float:left;}
          .col-print-11 {width:92%; float:left;}
          .col-print-12 {width:100%; float:left;}
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
    popupWin.document.close();
  }

  getPaymentType() {//Get payment type
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.payments != undefined && obj.payments.length != 0) {
            this.payTypeArray = obj.payments;
            this.payType = this.payTypeArray[0].pay_id;
            this.payType1 = this.payTypeArray[0].pay_id;
          }          
        },error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changePay() {
    document.getElementById("paytype")!.style.borderColor = '#2ca8fe';
    this.insuranceFlag = true;
      this.tranFlag = true;
      this.cardFlag = true;
      this.chequeFlag = true;
      if (this.payType == "3") {
        this.insuranceFlag = false;
      } else if(this.payType == "4"){
        this.tranFlag = false;
      } else if(this.payType == "2"){
        this.tranFlag = false;
        this.cardFlag = false;
      }else if(this.payType == "5"){
        this.chequeFlag = false;
      }else {
        this.insuranceFlag = true;
      }
  }

  getInsurers() {//Get payment type
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          this.insurerancListArray = obj.insurers;
        },error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  backClicked() {
    if (this.userInfo.user_type == "Admin") {
      this.service.sendMessage('frontdeskadminbilling');
    } else if(this.userInfo.user_type == "diag_user") {
      this.service.sendMessage('billinglist');
    } else {
      this.service.sendMessage('frontdeskbilling');   
    }
  }
    
  OnDateChangedto(e){  
    this.date = e;
    this.clearData();
    this.searchPatient();    
  }

  clearData(){
    this.createpage_flag = false;
    this.addBillArray = [];
    this.totalBillAmount = 0;
    this.discount_amount = 0;
    this.firstName = "";
    this.lastName = "";
    this.patientId  = "";
    this.mobileNo = "";
    this.finalAmount = 0;
    this.remaingBalance = 0;
    this.paidBill = 0;
    this.appoint = "";
    this.payType1 = this.payTypeArray[0].pay_id;
    this.paymentsarray = [];
    this.amountval = 0;
    this.amountGST = 0;
    this.advance = 0;
    this.testArray = [];
    this.estimateID = [];
    this.previousBalFlag = true;
    this.balanceFlag = true;
    this.clientName = "";
    this.amount = "";
    this.fees = "";
  }

  showCashModal() {
    this.payType1 = "1";
    this.showModal = true;
  }

  saveCashDenomination() {     
    this.amountval = this.totalAmount;
    this.showModal = false;
  }

  calculateTotalAmount() {
    this.totalAmount = this.cashDenomination.reduce((total, denomination) => {
      return total + (denomination.value * denomination.count);
    }, 0);   
  }

  addPaymentDetails(){
    var bamt:any=0,balance=0;
    var results = this.finekeyInArray_Payment(this.paymentsarray,this.payType1);
    if (this.payType1 != undefined && this.payType1 != "") {
      for(var i=0;i<this.payTypeArray.length;i++){
        if(this.payType1 == this.payTypeArray[i].pay_id){
          if(this.paymentsarray.length != 0){
            for(var j=0;j<this.paymentsarray.length;j++){
              bamt+=parseInt(this.paymentsarray[j].amount);
            }
            bamt+=parseFloat(this.amountval);
          } else {
            bamt+=parseFloat(this.amountval);
          }
         
          if (bamt> (this.paidBill)) {
            this.toastr.error("Amount cannot be more than bill amount");
          } else if(this.amountval == 0 || this.amountval < 0) {
            this.toastr.error("Amount cannot be zero or minus");
          } else if(results == true) {
            this.toastr.error("Payment mode already exists");
          } else {
            if(this.amountval < parseFloat(this.paidBill) || this.amountval == parseFloat(this.paidBill)){
              if(parseFloat(this.paidBill)>parseFloat(this.finalAmount)){
                if(bamt >parseFloat(this.finalAmount)){
                  this.toastr.error("Amount cannot be more than bill amount");
                } else {
                  this.paymentsarray.push({
                    pay_id:this.payTypeArray[i].pay_id,
                    pay_desc:this.payTypeArray[i].pay_desc,
                    amount: parseFloat(this.amountval)
                  })
                  this.amountval= parseFloat(this.finalAmount)-bamt;
                  this.amountval = parseFloat(this.amountval);
                  this.payType1 = "";
                }
                
              } else {
                if(bamt >parseFloat(this.paidBill)){
                  this.toastr.error("Amount cannot be more than bill amount");
                } else {
                  this.paymentsarray.push({
                    pay_id:this.payTypeArray[i].pay_id,
                    pay_desc:this.payTypeArray[i].pay_desc,
                    amount: parseFloat(this.amountval)
                  })
                  this.amountval= parseFloat(this.paidBill)-bamt;
                  this.amountval = parseFloat(this.amountval);
                  this.payType1 = "";
                }
              }
              
            } else {
              this.amountval=0;
            }
           
          }
        }
      }
    } else {
      this.toastr.error('Please select the payment mode');
    }
    
  }

  finekeyInArray_Payment(array, id) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].pay_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  editPayment(data){
    this.payType1=data.pay_id;
    this.amountval=data.amount;
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.paymentsarray.splice(i,1);
      }
    }
  }

  deletePayment(data){
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.paymentsarray.splice(i,1);
      }
    }
  }
}
