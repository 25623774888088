<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
  <div class="row">
    <div class="col-12">
      <div class="row" style="margin-top: 15px;margin-left: 25px;">
        <div class="col-1" style="width: fit-content;">
          <mat-label class="matlabel" style="position: relative;top: 6px;">Date : </mat-label>
        </div>
        <div class="col-8" style="padding-left: 0;width: 14%;">
          <input type="date" class="ipcss_date widthappt" id="appt_date" (change)="OnDateMatePicker(appointmentDate)"
            [(ngModel)]="appointmentDate" #matInput style="width: 140px;">
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3" *ngIf="doctorListflag">
          <select required [(ngModel)]="doctorid" (change)="changedoc()" class="ipcss widthappt">
            <option value="All">All</option>
            <option *ngFor="let doctors of doctorList" value={{doctors.Doc_ID}}>{{doctors.Doc_Name}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-12 p-0">
      <mdb-card>
        <mdb-card-header class="bg-white" style="border-bottom: 0 !important;">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Appointment list</h5>
            </div>
            <div class="headerButtons" *ngIf="!nodata_flag">
              <div [hidden]="cancelFlag">
                <img src="../../../assets/ui_icons/buttons/cancel_button.svg" (click)="cancel()" width="85px"
                  style="margin-right: 9px;" [hidden]="hide_cancel" />
              </div>

              <div [hidden]="confirmFlag">
                <img src="../../../assets/ui_icons/buttons/confirm_button.svg" (click)="Appointment_confirm()"
                  width="85px" style="margin-right: 9px;"  />
              </div>

              <div *ngIf="casesheetFlag">
                <img src="../../../assets/ui_icons/buttons/casesheet.svg" (click)="moveToCasesheet()" width="76px"
                  style="margin-right: 9px;" />
              </div>

              <div [hidden]="createFlag">
                <img src="../../../assets/ui_icons/buttons/new_app.svg" (click)="create_appointment()" width="76px"
                  style="margin-right: 9px;" />
              </div>

              <div *ngIf="view_no_rx_flag">
                <img src="../../../assets/ui_icons/buttons/No_Rx_button.svg" class="saveimgbtn"
                  (click)="close_appointment()" style="margin: 0;margin-right: 9px;" width="85px" />
                <img src="../../../assets/ui_icons/buttons/write_RX_Button.svg" (click)="write_prescription()"                  
                  width="85px" style="margin-right: 9px;"/>                  
              </div>
              <div [hidden]="viewPresFlag">
                <img src="../../../assets/ui_icons/buttons/view_RX_button.svg" (click)="view_prescription()"
                  width="85px" style="margin-right: 9px;" />
              </div>
              <div class="" *ngIf="showAssessment">
                <!-- [hidden]="assessFlag" -->
               <img src="../../../assets/ui_icons/buttons/assessment.svg" (click)="assessment()" width="85px"
                  style="margin-right: 9px;" />
              </div>
            </div>
            <div class="headerButtons" *ngIf="nodata_flag && createFlag">
              <img src="../../../assets/ui_icons/buttons/new_app.svg" (click)="create_appointment()" width="76px"
                style="margin-right: 9px;" />
            </div>
          </div>
        </mdb-card-header>
        <mdb-card-body>
          <div style="width: 60%;">
            <app-loader></app-loader>
          </div>
          <!-- <div appDivToImage>
            <img src="../../../assets/ui_icons/buttons/add_button.svg" alt="Your Image">
          </div>
          
          <button (click)="convertToImage()">Convert to Image</button> -->
          <div class="container-fluid">
            <div class="row">
              <div class="col-12" *ngIf="nodata_flag">
                <p class="nodata">No appointment(s) found</p>
              </div>
              <div class="col-9 col-xxl-8" *ngIf="!nodata_flag">
                <div class="scrollbar" *ngIf="appointment_list.length !=0">
                  <div class="scrollbar-inner">
                    <div class="graph-border p-4">
                      <table *ngIf="appointment_list.length !=0" mdbTable datatable [dtOptions]="dtOptions"
                        class="table table-nowrap table-sm dataTable">
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Name</th>
                            <th style="width: 70px;">Age</th>
                            <th>Mobile</th>
                            <th>Session</th>
                            <th>Token / Time</th>
                            <th>Package</th>
                            <th>Status</th>
                            <!-- <th style="width: 5%;">
                              <mdb-checkbox [default]="true" [(ngModel)]="masterSelected"
                                name="list_name" value="m1" (change)="checkUncheckAll()"></mdb-checkbox></th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr [ngClass]="{ 'active': i === activeRowIndex }"
                            *ngFor="let person of appointment_list; let i = index " (click)='Doctor_View(person, i)'>
                            <td><img src="{{person.profile_image}}"
                                onerror="this.src='../../../assets/img/default.jpg';" height="40px" width="40px"
                                class="img-fluid z-depth-1 rounded-circle mr-1"></td>
                            <td style="font-size: 12px;text-align: left;">{{ person.patient_name }}</td>
                            <td style="font-size: 12px;text-align: left !important;">{{ person.age }}</td>
                            <td style="font-size: 12px;">{{ person.contact }}</td>
                            <td style="font-size: 12px;text-align: left !important;">{{person.session}}</td>
                            <td style="font-size: 12px;text-align: center;">{{ person.tot_app_count }}</td>
                            <td style="font-size: 12px;text-align: center;">{{ person.package }}</td>
                            <td style="font-size: 12px;">
                              <div style="position: relative;top: 9px;">
                                <img *ngIf="person.status == 'Completed'" width="30px" height="auto"
                                  src="../../../assets/ui_icons/Completed_icon.svg" />
                                <img *ngIf="person.status == 'Cancel'" width="30px" height="auto"
                                  src="../../../assets/ui_icons/cancel_icon.svg" />
                                <img *ngIf="person.status == 'Not visited'" width="30px" height="auto"
                                  src="../../../assets/ui_icons/not_visited_icon.svg" />
                                <img *ngIf="person.status == 'Confirmed'" width="30px" height="auto"
                                  src="../../../assets/ui_icons/confirmed_icon.svg" />
                                <img *ngIf="person.status == 'Open'" width="30px" height="32px"
                                  src="../../../assets/ui_icons/opened_icon.svg" />
                                <p>{{person.status}}</p>
                              </div>
                            </td>
                            <!-- <td style="font-size: 12px;width: 5%;">
                              <mdb-checkbox [default]="true"
                                [(ngModel)]="person.isSelected" name="list_name" value="{{person.id}}"
                                (change)="isAllSelected(person)"></mdb-checkbox>
                            </td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3 col-xxl-4" *ngIf="!nodata_flag">
                <div class="scrollbar">
                  <div class="scrollbar-inner">
                    <div>
                      <div class="container-fluid graph-border p-2" style="width: 98%;">
                        <div class="row" *ngFor="let value of appt_List;">
                          <div class="col-12 col-xxl-6">
                            <div class="card-container-app d-flex justify-content-around align-items-center">
                              <img style="width: 40px; " src="../../../assets/ui_icons/app_DashIcons/heart_rate.svg" />
                              <div *ngIf="value.heart_rate != ''">
                                <p class="card-container-text"><span
                                    class="card-container-text-readings">{{value.heart_rate}}</span> bpm
                                </p>
                                <p class="card-container-text">Pulse rate</p>
                              </div>
                              <div *ngIf="value.heart_rate == ''">
                                <p class="card-container-text"><span class="card-container-text-readings">0</span> bpm
                                </p>
                                <p class="card-container-text">Pulse rate</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-xxl-6">
                            <div class="card-container-app d-flex justify-content-around">
                              <img style="width: 40px;  margin-right: 5px;"
                                src="../../../assets/ui_icons/app_DashIcons/blood_pressure.svg" />
                              <div *ngIf="value.blood_pressure != 'null'">
                                <p class="card-container-text" [ngStyle]="{'color': bpcolor ? 'red' : 'black'}"><span
                                    class="card-container-text-readings">{{value.blood_pressure}}</span> mmhg
                                </p>
                                <p class="card-container-text">Blood Pressure</p>
                              </div>
                              <!-- [ngStyle]="{'color': getBloodPressureColor()}" -->
                              <div *ngIf="value.blood_pressure == 'null'">
                                <p class="card-container-text"><span class="card-container-text-readings">0</span> mmhg
                                </p>
                                <p class="card-container-text">Blood Pressure</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-xxl-6">
                            <div class="card-container-app d-flex justify-content-around">
                              <img style="width: 40px;  margin-right: 5px;"
                                src="../../../assets/ui_icons/app_DashIcons/Respiratory_rate.svg" />
                              <div *ngIf="value.respiratory_rate != ''">
                                <p class="card-container-text"><span
                                    class="card-container-text-readings">{{value.respiratory_rate}}</span> bpm</p>
                                <p class="card-container-text">Respiratory rate</p>
                              </div>
                              <div *ngIf="value.respiratory_rate == ''">
                                <p class="card-container-text"><span class="card-container-text-readings">0</span> bpm
                                </p>
                                <p class="card-container-text">Respiratory rate</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-xxl-6">
                            <div class="card-container-app d-flex justify-content-around">
                              <img style="width: 40px;  margin-right: 5px;"
                                src="../../../assets/ui_icons/app_DashIcons/Blood_glucose.svg" />
                              <div>
                                <p class="card-container-text"><span
                                    class="card-container-text-readings">{{value.cbg}}</span> mg/dl
                                </p>
                                <p class="card-container-text">Blood glucose</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-xxl-6">
                            <div class="card-container-app d-flex justify-content-around">
                              <img style="width: 40px;  margin-right: 5px;"
                                src="../../../assets/ui_icons/app_DashIcons/Body_temperature.svg" />
                              <div *ngIf="value.temparature != ''">
                                <p class="card-container-text" [ngStyle]="{'color': tempcolorstyle ? 'red' : 'black'}">
                                  <span class="card-container-text-readings">{{value.temparature}} °F</span></p>
                                <p class="card-container-text">Temperature</p>
                              </div>
                              <div *ngIf="value.temparature == ''">
                                <p class="card-container-text"><span class="card-container-text-readings">0</span></p>
                                <p class="card-container-text">Temperature</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-xxl-6">
                            <div class="card-container-app d-flex justify-content-around">
                              <img style="width: 40px;  margin-right: 5px;"
                                src="../../../assets/ui_icons/app_DashIcons/Blood_cholesterol.svg" />
                              <div *ngIf="value.spo2 != undefined">

                                <p class="card-container-text"><span [ngStyle]="{'color': spo2color ? 'red' : 'black'}"
                                    class="card-container-text-readings">{{value.spo2}}</span> %
                                </p>

                                <p class="card-container-text">SpO2</p>

                              </div>
                              <div *ngIf="value.spo2 == undefined">

                                <p class="card-container-text"><span class="card-container-text-readings">0</span> %
                                </p>
                                <p class="card-container-text">SpO2</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="container-fluid">
                        <div class="row">
                          <div class="col-12 graph-border mt-3">
                            <p class="card-container-text-readings">Sleep </p>
                            <canvas id="piechart_gen" style="display: block;"></canvas>
                          </div>
                        </div>
                      </div>
                      <div class="container-fluid">
                        <div class="row">
                          <div class="col-12 graph-border mt-3">
                            <p class="card-container-text-readings">Activity </p>
                            <canvas id="appt_activity_chrt" style="display: block;"></canvas>
                          </div>
                        </div>
                      </div>
                      <div class="container-fluid">
                        <div class="row">
                          <div class="col-12 graph-border mt-3">
                            <p class="card-container-text-readings">Nutrition</p>
                            <canvas id="appt_nutrition_chrt" style="display: block;"></canvas>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br><br><br>

        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
</div>