import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Helper_Class } from '../../helper_class';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress';
import { Date_Formate, Time_Formate } from 'src/assets/js/common';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-diagnosis-report-approval',
  templateUrl: './diagnosis-report-approval.component.html',
  styleUrls: ['./diagnosis-report-approval.component.scss']
})
export class DiagnosisReportApprovalComponent implements OnInit {
  approvelListArrray: any = [];
  getListArray: any = [];
  public locationArray = [];
  public userType;
  public location;
  public clientGender;
  public clientAge;
  public tableTypeTestFlag: boolean;
  public diagApprovelType;
  public diagType;
  public diagID;
  public reporttype;
  public diagLocation: boolean;
  dtOptions: DataTables.Settings = {};

  // module flags
  public newbutton: boolean = false
  public editbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public moduleList: any = [];
  diag_centre_id: any;
  userstype: string;
  user_id: any;
  public filteredApprovallistArray: any = [];
  public searchInput: any;
  public from_date: any;
  public to_date: any;
  public sort_by_type: any = 'date';
  patGender: string;

  constructor(public messageservice: MenuViewService, public gservice: CommonDataService, public toastr: ToastrService, public http: Http, public router: Router) {
    this.clientGender = "0";
    this.clientAge = "00";
    this.tableTypeTestFlag = true;
    this.diagLocation = true;
  }
  ngOnInit(): void {
    this.moduleList = Helper_Class.getmodulelist();
    console.log(JSON.stringify(this.moduleList))
    this.reporttype = Diagnosis_Helper.getReportType();

    if (this.moduleList != undefined) {
      for (var i = 0; i < this.moduleList.length; i++) {
        if (this.moduleList[i].module_id == "106" && this.reporttype == "pathology" || this.moduleList[i].module_id == "110" && this.reporttype == "radiology" || this.moduleList[i].module_id == "114" && this.reporttype == "microbiology") {
          console.log(JSON.stringify(this.moduleList[i]));
          if (this.moduleList[i].edit == "1") {
            this.editbutton = true;//update button
          }
          if (this.moduleList[i].create == "1") {
            this.newbutton = true;
          }
          if (this.moduleList[i].delete == "1") {
            this.deletebutton = true;
          }
          if (this.moduleList[i].print == "1") {
            this.printbutton = true;
          }
          if (this.moduleList[i].view == "1") {
            this.viewbutton = true;
          }

        }
      }
    
    }


    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[1, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [0, 2] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or test type or test name"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.user_id = Helper_Class.getInfo().user_id;

      this.diagID = Helper_Class.getInfo().diag_centre_id;
      this.diag_centre_id = Helper_Class.getInfo().diag_centre_id;
    
      this.userstype = "diagnosis";
      this.getRetrivel(this.diagID);

      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    }
    else {
      this.user_id = Diagnosis_Helper.getUserID_Type().user_id;
      this.diagID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.diag_centre_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
     
      this.userstype = "diagnosis";
      this.getRetrivel(this.diagID);

    }

    this.sort_filter(this.sort_by_type);
  }
  getRetrivel(info) {
    var send_data = {
      diag_centre_id: info,
      prov_id: this.user_id,
      type: this.userType,
      imei: Helper_Class.getIPAddress(),
      reporttype: this.reporttype,
    }
    console.log("getRetrivel-sendDta", send_data);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/gtapp", JSON.stringify(send_data), { headers: headers }).subscribe(
      data => {
        this.approvelListArrray = [];
        console.log("getRetrivel-data", JSON.stringify(data.json()));
        if (data.json().test_approval != null) {
          var firstname, lastname;
          this.getListArray = data.json().test_approval;
          for (var i = 0; i < this.getListArray.length; i++) {

            if(this.getListArray[i].gender != undefined ){
              var gender = encrypt_decript.Decript(this.getListArray[i].gender)

              if(gender == "Male") {
                this.patGender = "M"
              } else if (gender == "Female") {
                this.patGender = "F"
              } else {
                this.patGender = gender
              }
            }
            

            var age_gender;
            if(this.getListArray[i].age != undefined && this.getListArray[i].gender != undefined ){
              age_gender = this.getListArray[i].age + " / " + this.patGender
            } else if(this.getListArray[i].age != undefined) {
              age_gender = this.getListArray[i].age
            } else if (this.getListArray[i].gender != undefined) {
              age_gender = this.patGender
            } else {
              age_gender = '';
            }

            var patientName;
            if (this.getListArray[i].first_name != undefined && this.getListArray[i].middle_name != undefined && this.getListArray[i].last_name != undefined) {
              patientName = encrypt_decript.Decript(this.getListArray[i].first_name) + " " + encrypt_decript.Decript(this.getListArray[i].middle_name) + " " + encrypt_decript.Decript(this.getListArray[i].last_name);
            } else if (this.getListArray[i].first_name != undefined && this.getListArray[i].last_name != undefined) {
              patientName = encrypt_decript.Decript(this.getListArray[i].first_name) + " " + encrypt_decript.Decript(this.getListArray[i].last_name);
            } else {
              patientName = encrypt_decript.Decript(this.getListArray[i].first_name);
            }

            var Dr_name = "";
            if(this.getListArray[i].inp_doc_fname != undefined && this.getListArray[i].inp_doc_lname != undefined){
              Dr_name = "Dr " + this.getListArray[i].inp_doc_fname + " " + this.getListArray[i].inp_doc_lname;
            } else if (this.getListArray[i].inp_doc_fname != undefined) {
              Dr_name = "Dr " + this.getListArray[i].inp_doc_fname
            } else {
              if(this.getListArray[i].dr_first_mame != undefined && this.getListArray[i].dr_last_mame != undefined){
                Dr_name = "Dr " + this.getListArray[i].dr_first_mame + " " + this.getListArray[i].dr_last_mame;
              } else if(this.getListArray[i].dr_first_mame != undefined) {
                Dr_name = "Dr " + this.getListArray[i].dr_first_mame;
              } else {
                Dr_name = "";
              }
            }

            var wardname_bedNo;
            if(this.getListArray[i].ward_name != undefined && this.getListArray[i].bed_no != undefined){
              wardname_bedNo = this.getListArray[i].ward_name + " - " + this.getListArray[i].bed_no;
            } else if (this.getListArray[i].ward_name != undefined) {
              wardname_bedNo = this.getListArray[i].ward_name;
            } else {
              wardname_bedNo = "";
            }

            if (this.getListArray[i].first_name != undefined) {
              if (this.getListArray[i].last_name != undefined) {
                if (encrypt_decript.Decript(this.getListArray[i].first_name) != "" && encrypt_decript.Decript(this.getListArray[i].last_name) != "") {
                  firstname = encrypt_decript.Decript(this.getListArray[i].first_name);
                  lastname = encrypt_decript.Decript(this.getListArray[i].last_name);
                }
              }
              else {
                firstname = encrypt_decript.Decript(this.getListArray[i].first_name);
                lastname = "";
              }
            } else {
              firstname = "";
              lastname = "";
            }

            var test_values = [];
            if (this.getListArray[i].tests.length != 0 && this.getListArray[i].tests != undefined) {
              for (var j = 0; j < this.getListArray[i].tests.length; j++) {
               
                var bill_date = this.getListArray[i].tests[j].bill_date != undefined ? Date_Formate(this.getListArray[i].tests[j].bill_date) : "";
                var bill_time = this.getListArray[i].tests[j].bill_time != undefined ? Time_Formate(this.getListArray[i].tests[j].bill_time) : "";
                var sample_collect_date = this.getListArray[i].tests[j].sample_collect_date != undefined ? Date_Formate(this.getListArray[i].tests[j].sample_collect_date) : "";
                var sample_collect_time = this.getListArray[i].tests[j].sample_collect_time != undefined ? Time_Formate(this.getListArray[i].tests[j].sample_collect_time) : "";
                var assert_date = this.getListArray[i].tests[j].assertion_date != undefined ? Date_Formate(this.getListArray[i].tests[j].assertion_date) : "";

                var assert_time = this.getListArray[i].tests[j].assertion_time != undefined ? Time_Formate(this.getListArray[i].tests[j].assertion_time) : "";

                var visited_date = this.getListArray[i].tests[j].visited_date != undefined ? Date_Formate(this.getListArray[i].tests[j].visited_date) : "";

                var visited_time = this.getListArray[i].tests[j].visited_time != undefined ? Time_Formate(this.getListArray[i].tests[j].visited_time) : "";

                var date = this.getListArray[i].tests[j].created_date != undefined ? Date_Formate(this.getListArray[i].tests[j].created_date) : "";

                var time = this.getListArray[i].tests[j].created_time != undefined ? Time_Formate(this.getListArray[i].tests[j].created_time) : "";



                test_values.push({
                  test_name: this.getListArray[i].tests[j].test_name,

                  bill_date_time: bill_date + ' ' + bill_time,
                  assert_date_time: assert_date + ' ' + assert_time,
                  visited_date_time: visited_date + ' ' + visited_time,
                  sample_collect_date_time: sample_collect_date + ' ' + sample_collect_time,
                  createdDate: date + ' ' + time,

                  test_type: this.getListArray[i].tests[j].test_type,
                  pat_type: this.getListArray[i].tests[j].pat_type,
                  test_no: this.getListArray[i].tests[j].test_no,
                  pres_diag_id: this.getListArray[i].tests[j].pres_diag_id,
                  test_id: this.getListArray[i].tests[j].test_id,
                  diag_tests_id: this.getListArray[i].tests[j].diag_tests_id,

                  isSelected: false,
                })

              }
            }

            var sample_collect_date = this.getListArray[i].tests[0].sample_collect_date != undefined ? Date_Formate(this.getListArray[i].tests[0].sample_collect_date) : "";


            this.approvelListArrray.push({
              patient_name: patientName,
              f_name: firstname,
              l_name: lastname,
              age_gender: age_gender,
              app_id: this.getListArray[i].diag_app_id,
              diag_id: this.getListArray[i].pres_diag_id,
              client_reg_id: this.getListArray[i].client_reg_id,
              diag_centre_id: this.getListArray[i].diag_centre_id,
              relation_id: this.getListArray[i].relation_id,
              sub_rel_id: this.getListArray[i].sub_rel_id,
              test_cat_id: this.getListArray[i].test_cat_id,
              test_no: this.getListArray[i].test_no,
              patient_diag_id: this.getListArray[i].patient_diag_id,
              test_values: test_values,
              sample_collect_date_time: sample_collect_date + ' ' + sample_collect_time,
              collected_date: this.getListArray[i].tests[0].sample_collect_date,
              Dr_name: Dr_name,
              ward: wardname_bedNo,

              // test_name: this.getListArray[i].test_name,
              // test_id: this.getListArray[i].pres_test_id,
              // createdDate: date + ' ' + time,
              // bill_date_time: bill_date + ' ' + bill_time,
              // assert_date_time: assert_date + ' ' + assert_time,
              // visited_date_time: visited_date + ' ' + visited_time,
            });
          }

          
          this.filteredApprovallistArray = this.approvelListArrray;
          console.log("Appproval List", JSON.stringify(this.approvelListArrray));
          this.sort_filter(this.sort_by_type)
        }

      }, error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });
  }
  viewReportDetails(testID, diagAppID, testType, diagID, testName, approvalList, visited_date_time) {
  
    if (this.viewbutton == true) {
      Diagnosis_Helper.setReportApproval(null);

      this.diagType = "Approval";
      var report = {
        type: testType,
        diag_app_id: diagAppID,
        pres_test_id: testID,
        pres_diag_id: diagID,
        Diag_report_flag: this.diagType,
        user: "diagnosis",
        test_name: testName,
        diag_centre_id: approvalList.diag_centre_id,
        relation_id: approvalList.relation_id,
        sub_rel_id: approvalList.sub_rel_id,
        test_cat_id: approvalList.test_cat_id,
        test_flag: "single",
        visited_date_time: visited_date_time,
      };
      Diagnosis_Helper.setReportApproval(report);
      this.messageservice.sendMessage("report_approval_detailed");
    }
  }

  viewReportDetails2(data) {
  
    console.log(data)
 
    if (this.viewbutton == true) {
      Diagnosis_Helper.setReportApproval(null);
      this.diagType = "Approval";

      var pres_tests = [];

      if(data.test_values != undefined){
        if(data.test_values.every(element => element.isSelected == false)){
       
          for( var j = 0; j < data.test_values.length; j++){
            pres_tests.push({
              pres_test_id: data.test_values[j].test_id,
              test_type: data.test_values[j].test_type,
              pres_diag_id: data.test_values[j].pres_diag_id,
              test_name: data.test_values[j].test_name,
              sample_datetime: data.test_values[j].sample_collect_date_time,
            })         
          }
        } else {
          for( var j = 0; j < data.test_values.length; j++){
            if(data.test_values[j].isSelected == true){
              pres_tests.push({
                pres_test_id: data.test_values[j].test_id,
                test_type: data.test_values[j].test_type,
                pres_diag_id: data.test_values[j].pres_diag_id,
                test_name: data.test_values[j].test_name,
                sample_datetime: data.test_values[j].sample_collect_date_time,
              })  
            }       
          }
        }
      }

      var report = {
        pres_tests: pres_tests,
        test_flag: "group",
        diag_app_id: data.app_id,
        pres_diag_id: data.diag_id,
        Diag_report_flag: this.diagType,
        user: "diagnosis",
        diag_centre_id: data.diag_centre_id,
        relation_id: data.relation_id,
        sub_rel_id: data.sub_rel_id,
        test_cat_id: data.test_cat_id,
      };

      console.log("data", data)

      Diagnosis_Helper.setReportApproval(report);
      this.messageservice.sendMessage("report_approval_detailed");
    }
  }

  sort_filter(filter_type) {
    // this.filteredPendingList = this.pendingLists.sort((a, b) => b.collected_date - a.collected_date);

    // Function to sort by date in descending order
    if(filter_type == 'date') {
      this.approvelListArrray.sort((a, b) => {
        // Convert date strings to Date objects
        const dateA:any = new Date(a.collected_date);
        const dateB:any = new Date(b.collected_date);
  
        // Compare dates to determine order
        return dateB - dateA;
      });
    } else if (filter_type == 'name') {
      this.approvelListArrray.sort((a, b) => a.patient_name.localeCompare(b.patient_name));
    } else {
      this.approvelListArrray.sort((a, b) => a.patient_diag_id.localeCompare(b.patient_diag_id));
    }
    
    this.filteredApprovallistArray = this.approvelListArrray
    console.log(this.approvelListArrray);
  }

  filterApprovallistArray(searchInput) {
    
    this.filteredApprovallistArray = this.approvelListArrray.filter(item =>
      item.patient_diag_id.toLowerCase().includes(searchInput.toLowerCase()) || item.patient_name.toLowerCase().includes(searchInput.toLowerCase())
    );
  }
}
