import { Component, OnInit, ElementRef, Input, ViewChild, ChangeDetectorRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog } from '@angular/material/dialog';
import { Date_Formate, Time_Formate } from '../../../assets/js/common.js';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FrontDeskService } from '../front-desk/front-desk.service';
import { ipaddress } from '../../ipaddress'; import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Doc_Helper } from '../../Doctor_module/Doc_Helper';
import { FrontDesk_Helper } from '../FrontDesk_Helper';

@Component({
  selector: 'app-partial-payment',
  templateUrl: './partial-payment.component.html',
  styleUrls: ['./partial-payment.component.scss']
})
export class PartialPaymentComponent implements OnInit {
  @ViewChild('printlogowithname') printlogowithname: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('dischargesummary') dischargesummary: ElementRef;
  @ViewChild('printheader') printheader: ElementRef;
  @ViewChild('billprint') printsection: ElementRef;

  public filterType: string;
  public fromDate: string;
  public toDate: string;
  public dateFlag: boolean;
  public clientFlag: boolean;
  public billFlag: boolean;
  public billListFlag: boolean;
  public saveFlag: boolean;
  public clientFilterList:any = [];
  public filteredClientList:any = [];
  public clientList:any = [];
  public clntName: string;
  public middleName: string;
  public lastName: string;
  public clientId: string;
  public relationId: string;
  public subRelId: string;
  public billingId: string;
  public fName: string;
  public mName: string;
  public lName: string;
  public billAmount: string;
  public refundType: string;
  public refundAmount: number;
  public hptlClinicId: string;
  public billList:any = [];
  public billDetails:any = [];
  public refundList:any = [];
  @Input() max: any;
  public currentDate;
  public currentYear;
  public currentTime;
  public getDate;
  public hospId;
  public userId;
  public billRemarks;
  public clnt_age;
  public clnt_mobile;
  public patdet_flag: boolean = true;
  public paid_flag: boolean = true;
  public showarray: boolean = true;
  public parpayarray: any = [];
  public paymentArray: any = [];
  private closeResult;
  public print_template;
  public hospital_logo;
  public printlogostyle;
  public printFlag: boolean = false;
  public hosp_addr;
  public hosp_csz;
  public hosp_tel;
  public loader;
  public hosp_name;
  public printbutton: boolean = false;
  public nodata: boolean = true;
  public usertype: string;
  public provtype;
  public provtypeArray: any = [];
  editbuttondie: boolean = false;
  newbuttondie: boolean = false;
  deletebuttondie: boolean = false;
  printbuttondie: boolean = false;
  viewbuttondie: boolean = false;
  diag_centre_id: any;
  public client_name: any;
  public barcode_flag:boolean = false;
  public barcode_url;
  public logUserName;
  public surgery;
  clientAddress1: string;
  clientAddress2: string;
  ageval: any;
  genderval: any;
  clocation: string;
  ccity: string;
  czipcode: string;
  cstate: string;
  ccountry: string;
  dr_name: string;
  billId: any;
  created_date: string;
  barcode_style: string;
  nobanner_style: string;
  public profile_image: string;
  uhid: any;
  mobile: any;
  public createpage_flag: boolean = false;
  public doctor_name_flag: boolean = false;
  public print_header_flag = false;

  constructor(public gservice: CommonDataService, public http: HttpClient,
    public routes: ActivatedRoute, public router: Router, public toastr: ToastrService,
    public messageservice: MenuViewService, private modalService: NgbModal,public cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.usertype = Helper_Class.getInfo().user_type;
      this.hospId = Helper_Class.getInfo().hptl_clinic_id;
      this.userId = Helper_Class.getInfo().user_id;
      this.diag_centre_id = Helper_Class.getInfo().diag_centre_id;
    } else {
      this.usertype = Helper_Class.getInfo().user_type;
      this.hospId = Helper_Class.getInfo().hptl_clinic_id;
      this.userId = Helper_Class.getInfo().user_id;
    }
    
    if (Helper_Class.getInfo().first_name != undefined) {
      this.logUserName = Helper_Class.getInfo().first_name;
      if (Helper_Class.getInfo().last_name != undefined) {
        this.logUserName = Helper_Class.getInfo().first_name+" "+Helper_Class.getInfo().last_name;
      }
    } 
    if (true) {
      this.provtypeArray.push("Hospital");
      if (Helper_Class.getInfo().diag_centre_id != undefined) {
        this.provtypeArray.push("Diagnosis");
      }
      this.provtype = this.provtypeArray[0];
    }
    this.profile_image = "../../assets/img/default.jpg";
    this.filterType = "Name";
    this.filterTypeChange();
    this.hospital_logo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    this.printFlag = Helper_Class.getBillPrint() == "1" ? true : false;
    this.print_template = Helper_Class.getInfo().bill_print_template;
    this.printlogostyle = Helper_Class.getInfo().bill_print_logo_style;
    this.getPaymentType();

    if (FrontDesk_Helper.getmodules() != null) {
      for (var i = 0; i < FrontDesk_Helper.getmodules().length; i++) {
        if (FrontDesk_Helper.getmodules()[i].module_id == "22") {
          if (FrontDesk_Helper.getmodules()[i].edit == "1") {
            this.editbuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].create == "1") {
            this.newbuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].delete == "1") {
            this.deletebuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].print == "1") {
            this.printbuttondie = true;
          }
          if (FrontDesk_Helper.getmodules()[i].view == "1") {
            this.viewbuttondie = true;
          }
        }
      }
    }
  }

  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          //City_change
          this.currentDate = obj.current_date;
          this.getDate = obj.current_date.split('-');
          this.currentYear = this.getDate[0];
          this.currentTime = obj.current_time;
          this.fromDate = this.currentDate;
          this.toDate = this.currentDate;
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  filterTypeChange() {
    this.createpage_flag = false;
    if (this.filterType == "Bill number") {
      this.dateFlag = true;
      this.clientFlag = true;
      this.billFlag = false;
      this.billListFlag = true;
      this.fName = "";
      this.mName = "";
      this.lName = "";
      this.clntName = "";
      this.lastName = "";
      this.billingId = "0";
      this.refundAmount = 0;
      this.saveFlag = false;
      this.billList = [];
    } else if (this.filterType == "MR.No") {
      this.dateFlag = true;
      this.clientFlag = true;
      this.billFlag = false;
      this.billListFlag = true;
      this.fName = "";
      this.mName = "";
      this.lName = "";
      this.clntName = "";
      this.lastName = "";
      this.billingId = "0";
      this.refundAmount = 0;
      this.saveFlag = false;
      this.billList = [];
    } else if (this.filterType == "Mobile") {
      this.dateFlag = true;
      this.clientFlag = true;
      this.billFlag = false;
      this.billListFlag = true;
      this.fName = "";
      this.mName = "";
      this.lName = "";
      this.clntName = "";
      this.lastName = "";
      this.billingId = "0";
      this.refundAmount = 0;
      this.saveFlag = false;
      this.billList = [];
    } else if (this.filterType == "Name") {
      this.dateFlag = true;
      this.clientFlag = false;
      this.billFlag = true;
      this.billListFlag = true;
      this.saveFlag = true;
      this.fName = "";
      this.mName = "";
      this.lName = "";
      this.clntName = "";
      this.lastName = "";
      this.billingId = "0";
      this.refundAmount = 0;
      this.billList = [];
    } else if (this.filterType == "Date") {
      this.fName = "";
      this.mName = "";
      this.lName = "";
      this.clntName = "";
      this.lastName = "";
      this.billingId = "0";
      this.refundAmount = 0;
      this.billList = [];
      this.getCurrentDate();
      this.dateFlag = false;
      this.clientFlag = true;
      this.billFlag = true;
      this.billListFlag = true;
      this.saveFlag = true;
    }
  }

  clientFilter() {
    if (this.clntName.length > 2 && this.clntName != undefined) {
      this.clientFilterList = [];
      this.clientList = [];
      this.clntName = this.clntName.toLocaleUpperCase();
      this.middleName = "";
      this.lastName = "";
      var clntname = encrypt_decript.Encript(this.clntName).toString();

      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'usercontroller/clntbyadm/', { client_name: clntname },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if (obj.clients != null && obj.clients != 0) {
              this.clientFilterList = obj.clients;
              for (var i = 0; i < this.clientFilterList.length; i++) {
                var lName = this.clientFilterList[i].last_name != undefined ? encrypt_decript.Decript(this.clientFilterList[i].last_name) : "";
                var mName = this.clientFilterList[i].middle_name != undefined ? encrypt_decript.Decript(this.clientFilterList[i].middle_name) : "";
                this.clientList.push({
                  name: encrypt_decript.Decript(this.clientFilterList[i].first_name) + " " + lName,
                  first_name: this.clientFilterList[i].first_name,
                  last_name: this.clientFilterList[i].last_name,
                  client_id: this.clientFilterList[i].client_reg_id,
                  relation_id: this.clientFilterList[i].relation_id,
                  sub_rel_id: this.clientFilterList[i].sub_rel_id,
                });
              }
            }
          },
          error => { });
    }
  }

  selectClient(data) {
    this.clntName = encrypt_decript.Decript(data.first_name);
    this.clientId = undefined;
    this.relationId = undefined;
    this.subRelId = undefined;
    for (var i = 0; i < this.clientFilterList.length; i++) {
      if (this.clientFilterList[i].client_reg_id == data.client_id && this.clientFilterList[i].relation_id == data.relation_id) {
        this.middleName = this.clientFilterList[i].middle_name != undefined ? encrypt_decript.Decript(this.clientFilterList[i].middle_name) : "";
        this.lastName = this.clientFilterList[i].last_name != undefined ? encrypt_decript.Decript(this.clientFilterList[i].last_name) : "";
        this.clientId = this.clientFilterList[i].client_reg_id;
        this.relationId = this.clientFilterList[i].relation_id;
        if (this.clientFilterList[i].sub_rel_id != undefined && this.clientFilterList[i].sub_rel_id != null) {
          this.subRelId = this.clientFilterList[i].sub_rel_id;
        }
      }
    }
    this.clientList = [];
  }

  getBillDetails() {
    this.billList = [];
    this.client_name = '';
    this.clientAddress1 = '';
    this.clientAddress2 = '';
    this.ageval = '';
    this.genderval = '';
    this.clocation = '';
    this.ccity = '';
    this.czipcode = '';
    this.cstate = '';
    this.ccountry = '';
    this.barcode_url = '';
    this.surgery = '';
    this.created_date = '';
    this.dr_name = '';
    this.billId = '';
    this.profile_image = '';
    this.uhid = '';
    this.mobile = '';

    var headers = new HttpHeaders();
    var apptype, hospid;
    if (this.provtype == "Hospital") {
      apptype = "doctor",
      hospid = this.hospId

    } else if (this.provtype == "Diagnosis") {
      apptype = "diagnosis";
      hospid = Helper_Class.getInfo().diag_centre_id;

    } else {
      apptype = "pharma";
      hospid = Helper_Class.getInfo().pharma_id;
    }

    var senddata = {
      hptl_clinic_id: hospid,
      billing_id: this.billingId,
      app_type: apptype,
    }
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbillhosp/', senddata, { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          if (obj != undefined && obj.length != 0) {
            this.nodata = true;
            if (obj.first_name != undefined) {
              this.patdet_flag = false;
              if (obj.middle_name != undefined) {
                this.client_name = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
  
              } else {
                this.client_name = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
              }
            }
            var salutation_desc;
            salutation_desc = obj.salutation_desc != undefined ? obj.salutation_desc : '';
  
            this.client_name = salutation_desc != '' ? salutation_desc + '.' + this.client_name : this.client_name;
  
            if (obj.profile_image != "") {
              this.profile_image = ipaddress.Ip_with_img_address + obj.profile_image;
            }
  
            this.uhid = obj.client_hosp_id != undefined ? obj.client_hosp_id : '';
  
            if (obj.caddress2 != null && obj.caddress2 != undefined && obj.caddress1 != undefined) {
              this.clientAddress1 = encrypt_decript.Decript(obj.caddress1)+ ",";
              if(obj.caddress2 != "" && obj.caddress2 != "null") {
                if (encrypt_decript.Decript(obj.caddress2) == "") {
                  this.clientAddress2 == "";
                } else {
                  this.clientAddress2 = encrypt_decript.Decript(obj.caddress2)+",";
                }
              }
            } else if (obj.caddress1 != undefined) {
              this.clientAddress1 = encrypt_decript.Decript(obj.caddress1)+",";
            } else if (obj.caddress2 != undefined) {
              this.clientAddress2 = encrypt_decript.Decript(obj.caddress2)+",";
            }  
  
            this.mobile = obj.mobile != undefined ? encrypt_decript.Decript(obj.mobile) : '';
  
            if(obj.age != undefined){
              this.ageval = obj.age;
            } else {
              this.ageval="";
            }
            if(obj.gender != undefined){
              this.genderval = obj.gender;
            } else {
              this.genderval="";
            } 
           
            if (
              this.genderval != null &&
              this.genderval != undefined
            ) {
                this.genderval = encrypt_decript.Decript(this.genderval);
                this.genderval = this.genderval[0];
              if (this.genderval != undefined && this.genderval[0] == 'T') {
                this.genderval = 'Tgen';
              }
            }  
  
            if (obj.clocation != undefined) {
              this.clocation = obj.clocation+",";
            }
  
            if (obj.ccity != undefined) {
              this.ccity = obj.ccity+"-";
            }
  
            if(obj.czipcode != undefined) {
              this.czipcode = encrypt_decript.Decript(obj.czipcode)+",";
            }
  
            if (obj.cstate != undefined) {
              this.cstate = obj.cstate+"-";
            }   
            
            if(obj.ccountry != undefined) {
              this.ccountry = obj.ccountry+".";
            }
            
            if (obj.dr_middle_name != undefined && obj.dr_middle_name != null) {
              this.dr_name = "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
            } else if (obj.dr_first_name != undefined && obj.dr_last_name != undefined) {
              this.dr_name = "Dr." + obj.dr_first_name + " " + obj.dr_last_name;
            } else {
              this.dr_name = '';
            } 

            if (obj.doc_qualif != undefined && obj.doc_qualif != null && obj.doc_qualif != "") {
              this.dr_name = this.dr_name + " " + obj.doc_qualif;
            }
  
            this.billId  = obj.billing_id != undefined ? obj.billing_id : '';
  
            if (obj.created_date != undefined) {
              var get_date = obj.created_date.toString().split('-');
              this.created_date = get_date[2] + "-" + get_date[1] + "-" + get_date[0]+" "+ Time_Formate(obj.created_time);
            } else {
              this.created_date = '';
            }
  
            if (obj.barcode != undefined && obj.barcode != null) {
              this.barcode_flag = true;
              this.barcode_url = ipaddress.Ip_with_img_address + obj.barcode;
            }
  
            this.surgery = obj.surgery_name != undefined ? obj.surgery_name : '';
            
            this.clnt_mobile = obj.mobile != undefined ? encrypt_decript.Decript(obj.mobile) : '';
            this.clnt_age = obj.age != undefined ? encrypt_decript.Decript(obj.age) : '';
            var dateval = obj.created_date.split("-");
            if (obj.created_time != undefined) {
              var get_time = Time_Formate(obj.created_time);
            }
  
            if (obj.paid_flag == "Fully paid") {
              this.paid_flag = true;
            } else {
              this.paid_flag = false;
            }
            var showlist;
            if (obj.partial_payments != undefined) {
              showlist = true;
            } else {
              showlist = false;
            }

            var advance;
            if (obj.advance != null && obj.advance != undefined) {
              advance = parseFloat(obj.advance);
            } else {
              advance = 0;
            }

            this.billList.push({
              name: this.client_name,
              billno: this.billingId,
              date: dateval[2] + "-" + dateval[1] + "-" + dateval[0]+ " " + get_time,
              billamount: parseFloat(encrypt_decript.Decript(obj.bill_amount)),
              advance: advance,
              paid_amt: parseFloat(obj.paid_amount),
              balance: parseFloat(obj.balance),
              payamount: 0,
              payflag: obj.paid_flag,
              paidflag: this.paid_flag,
              fin_year: obj.fin_year,
              partial_payments: obj.partial_payments,
              showimage: showlist,
              remarks: obj.remarks,
            })
            this.createpage_flag = true;
          } else {
            this.nodata = false;
            this.createpage_flag = false;
          }
        })       
  }

  public remarks = "";
  searchBills() {
    this.billList = [];
    this.client_name = '';
    this.clientAddress1 = '';
    this.clientAddress2 = '';
    this.ageval = '';
    this.genderval = '';
    this.clocation = '';
    this.ccity = '';
    this.czipcode = '';
    this.cstate = '';
    this.ccountry = '';
    this.barcode_url = '';
    this.surgery = '';
    this.created_date = '';
    this.dr_name = '';
    this.billId = '';
    this.profile_image = '';
    this.uhid = '';
    this.mobile = '';
    this.doctor_name_flag = false;
    var apptype, hospid;
    if (this.provtype == "Hospital") {
      apptype = "doctor",
      hospid = this.hospId     
    } else if (this.provtype == "Diagnosis") {
      apptype = "diagnosis";
      hospid = Helper_Class.getInfo().diag_centre_id;
    } else {
      apptype = "pharma";
      hospid = Helper_Class.getInfo().pharma_id;
    }

    if (this.filterType == "Bill number") {
      this.createpage_flag = true;
      this.print_header_flag = false;
      this.getBillDetails();
      this.saveFlag = false;
      this.patdet_flag = false;
    } else {
      this.patdet_flag = true;
      var fromdate;
      var todate;
      var fieldvalue;
      if (this.fromDate != undefined) {
        fromdate = Date_Formate(this.fromDate);
        todate = Date_Formate(this.toDate);
      }

      if (this.filterType == "Date") {
        this.createpage_flag = false;
        this.print_header_flag = true;
        fieldvalue = {
          center_id: hospid,
          from_date: Date_Formate(fromdate),
          to_date: Date_Formate(todate),
          app_type: apptype,
          provtype: this.provtype
        }
      } else if (this.filterType == "Name") {
        // this.createpage_flag = true;
        this.print_header_flag = false;
        fieldvalue = {
          center_id: hospid,
          first_name: this.clntName,
          last_name: this.lastName,
          app_type: apptype,
          provtype: this.provtype
        }
      } else if (this.filterType == "MR.No") {
        // this.createpage_flag = true;
        this.print_header_flag = false;
        fieldvalue = {
          center_id: hospid,
          app_type: apptype,
          patient_id: this.billingId,
          provtype: this.provtype
        }
      } else if (this.filterType == "Mobile") {
        // this.createpage_flag = true;
        this.print_header_flag = false;
        fieldvalue = {
          center_id: hospid,
          app_type: apptype,
          mobile: this.billingId,
          provtype: this.provtype
        }
      }
      console.log(fieldvalue);
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/getbills/', { fieldvalue },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log(obj);
            if (obj.bills != undefined && obj.bills.length != 0) {
              this.billListFlag = false;
              this.nodata = true;
              this.doctor_name_flag = true;
              for (var i = 0; i < obj.bills.length; i++) {
                if (obj.bills[i].paid_flag != undefined && obj.bills[i].paid_flag == "Fully paid") {
                  this.paid_flag = true;
                } else {
                  if (parseFloat(obj.bills[i].paid_amount) >= parseFloat(encrypt_decript.Decript(obj.bills[i].amount))) {
                    this.paid_flag = true;
                  } else {
                    this.paid_flag = false;
                  }
                }
                var showlist;
                if (obj.bills[i].partial_payments != undefined) {
                  showlist = true;
                } else {
                  showlist = false;
                }

                this.remarks = "";
                var objpartial = obj.bills[i].partial_payments
                if (objpartial != undefined) {
                  for (var j = 0; j < objpartial.length; j++) {
                    if (objpartial[j].remarks != undefined || null) {
                      this.remarks += objpartial[j].remarks + ","
                    }
                  }
                }
                var cname;
                if(obj.bills[i].middle_name != undefined){
                  if(obj.bills[i].last_name != undefined){
                    cname = encrypt_decript.Decript(obj.bills[i].first_name)+" "+encrypt_decript.Decript(obj.bills[i].middle_name)+" "+encrypt_decript.Decript(obj.bills[i].last_name);
                  } else {
                    cname = encrypt_decript.Decript(obj.bills[i].first_name);
                  }
                } else {
                  if(obj.bills[i].last_name != undefined){
                    cname = encrypt_decript.Decript(obj.bills[i].first_name)+" "+encrypt_decript.Decript(obj.bills[i].last_name);
                  } else {
                    cname = encrypt_decript.Decript(obj.bills[i].first_name);
                  }
                }
                var salutation;
                if(obj.bills[i].salutation != undefined) {
                  salutation = obj.bills[i].salutation;
                  cname = salutation + '.' + cname;
                  this.client_name = cname;
                }

                if (obj.bills[i].profile_image != "") {
                  this.profile_image = ipaddress.Ip_with_img_address + obj.bills[i].profile_image;
                }

                this.uhid = obj.bills[i].mr_no != undefined ? obj.bills[i].mr_no : '';
                var mr_no = '';
                mr_no = obj.bills[i].mr_no != undefined ? obj.bills[i].mr_no : '';
                this.mobile = obj.bills[i].mobile != undefined ? encrypt_decript.Decript(obj.bills[i].mobile) : '';

                var dr_name;
                if (obj.bills[i].dr_middle_name != undefined && obj.bills[i].dr_middle_name != null) {
                  dr_name = "Dr." + obj.bills[i].dr_first_name + " " + obj.bills[i].dr_middle_name + " " + obj.bills[i].dr_last_name;
                } else if (obj.bills[i].dr_first_name != undefined && obj.bills[i].dr_last_name != undefined) {
                  dr_name = "Dr." + obj.bills[i].dr_first_name + " " + obj.bills[i].dr_last_name;
                } else {
                  dr_name = '';
                } 

                if (obj.bills[i].address2 != null && obj.bills[i].address2 != undefined && obj.bills[i].address1 != undefined) {
                  this.clientAddress1 = encrypt_decript.Decript(obj.bills[i].address1)+ ",";
                  if(obj.bills[i].address2 != "" && obj.bills[i].address2 != "null") {
                    if (encrypt_decript.Decript(obj.bills[i].address2) == "") {
                      this.clientAddress2 == "";
                    } else {
                      this.clientAddress2 = encrypt_decript.Decript(obj.bills[i].address2)+",";
                    }
                  }
                } else if (obj.bills[i].address1 != undefined) {
                  this.clientAddress1 = encrypt_decript.Decript(obj.bills[i].address1)+",";
                } else if (obj.bills[i].address2 != undefined) {
                  this.clientAddress2 = encrypt_decript.Decript(obj.bills[i].address2)+",";
                }  
                var ageval,genderval;
                if(obj.bills[i].age != undefined){
                  this.ageval = obj.bills[i].age;
                  ageval = obj.bills[i].age;
                } else {
                  this.ageval = "";
                  ageval = "";
                }
                if(obj.bills[i].gender != undefined){
                  this.genderval = obj.bills[i].gender;
                  genderval = obj.bills[i].gender;
                } else {
                  this.genderval = "";
                  genderval = "";
                } 
               
                if (
                  this.genderval != null &&
                  this.genderval != undefined
                ) {
                    this.genderval = encrypt_decript.Decript(this.genderval);
                    genderval = encrypt_decript.Decript(genderval);
                    this.genderval = this.genderval[0];
                    genderval = genderval[0];
                  if (this.genderval != undefined && this.genderval[0] == 'T') {
                    this.genderval = 'Tgen';
                    genderval = 'Tgen';
                  }
                }  
      
                if (obj.bills[i].location != undefined) {
                  this.clocation = obj.bills[i].location+",";
                }
      
                if (obj.bills[i].city != undefined) {
                  this.ccity = obj.bills[i].city + "-";
                }
      
                if(obj.bills[i].zipcode != undefined) {
                  this.czipcode = obj.bills[i].zipcode+",";
                }
      
                if (obj.bills[i].state != undefined) {
                  this.cstate = obj.bills[i].state + "-";
                }   
                
                if(obj.bills[i].country != undefined) {
                  this.ccountry = obj.bills[i].country+".";
                }

                if (obj.bills[i].barcode != undefined && obj.bills[i].barcode != null) {
                  this.barcode_flag = true;
                  this.barcode_url = ipaddress.Ip_with_img_address + obj.bills[i].barcode;
                }

                var dateval = obj.bills[i].date.split("-");
                if (obj.bills[i].time != undefined) {
                  var get_time = Time_Formate(obj.bills[i].time);
                }

                var advance;
                if (obj.bills[i].advance != null && obj.bills[i].advance != undefined) {
                  advance = parseFloat(obj.bills[i].advance);
                } else {
                  advance = 0;
                }

                this.billList.push({
                  billno: obj.bills[i].bill_id,
                  date: dateval[2] + "-" + dateval[1] + "-" + dateval[0]+ " " + get_time,
                  mr_no: mr_no,
                  age_gender: ageval + " / " + genderval,
                  billamount: parseFloat(encrypt_decript.Decript(obj.bills[i].amount)),
                  advance: advance,
                  paid_amt: parseFloat(obj.bills[i].paid_amount),
                  balance: parseFloat(obj.bills[i].balance),
                  payamount: 0,
                  payflag: obj.bills[i].paid_flag,
                  paidflag: this.paid_flag,
                  fin_year: obj.bills[i].fin_year,
                  partial_payments: obj.bills[i].partial_payments,
                  showimage: showlist,
                  patremarks: this.remarks,
                  name: cname,
                  dr_name: dr_name
                })
                this.printbutton = true;
              }
              if (this.filterType != 'Date') {
                this.createpage_flag = true;
              }
            } else {
              this.nodata = false;
              this.createpage_flag = false;
            }
          },
          error => { });
    }
  }

  save() {
    var apptype, hospid;
    if (this.provtype == "Hospital") {
      apptype = "doctor",
      hospid = this.hospId
    } else if (this.provtype == "Diagnosis") {
      hospid = Helper_Class.getInfo().diag_centre_id;
      apptype = "diagnosis";
    } else {
      apptype = "pharma";
      hospid = Helper_Class.getInfo().pharma_id;
    }

    var bpayments:any = [];
    for (var i = 0; i < this.billList.length; i++) {
      var paamt = parseInt(this.billList[i].paid_amt);
      var aamt: any = parseInt(this.billList[i].advance);
      var pamt = parseInt(this.billList[i].payamount);
      var bamt = parseInt(this.billList[i].billamount);
      var tamt: any = paamt + pamt + aamt;

      if (tamt > bamt) {
        tamt = bamt;
      }
      var pflag
      var balance: any = bamt - tamt;
      var status;
      
      if (balance == 0) {
        pflag = "Fully paid";
        status = '0';
      } else if (bamt == balance) {
        pflag = "Un paid";
        status = '1';
      } else {
        pflag = "Partially paid";
        status = '1';
      }

      var paid_amount = parseFloat(tamt) - parseFloat(aamt);

      this.billList[i].paid_amt = paid_amount;
      this.billList[i].balance = parseFloat(balance);
      this.billList[i].payflag = pflag;
      if (this.paymentArray != undefined && this.paymentArray.length != 0) {
        for (var p = 0; p < this.paymentArray.length; p++) {
          if (this.billList[i].pay_id == this.paymentArray[p].pay_id) {
            this.billList[i].pay_desc = this.paymentArray[p].pay_desc
          }
        }
      }    

      bpayments.push({
        billing_id: this.billList[i].billno,
        hptl_clinic_id: hospid,
        fin_year: this.billList[i].fin_year,
        paid_amount: paid_amount,
        balance: balance,
        paid_flag: pflag,
        payment: this.billList[i].payamount,
        pay_type: this.billList[i].pay_id,
        status: status,
        remarks: this.billList[i].remarks,
      })
    }
    console.log("SENDDATA=>",bpayments);
    console.log("BILLLISTARRAY",this.billList);
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/spbp/', { bills: bpayments, country: "IN", apptype: apptype, },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          if (obj.key != 0) {
            this.toastr.success(Message_data.saveSuccess);
            if (this.surgery == '') {
              this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -35px;";
              this.nobanner_style = "width: 100% !important; height: 95px !important;";
            } else {
              this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -45px;";
              this.nobanner_style = "width: 100% !important; height: 105px !important;";
            }

            if (this.billId == '') {
              this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: 0px;";
              this.nobanner_style = "width: 100% !important; height: 95px !important;";
            } 
            this.cdr.detectChanges();
            this.print();
            this.billList = [];
            this.patdet_flag = true;
            this.billingId = "";
            this.createpage_flag = false;
          }
        });
  }

  showBillHistory(content: any, billno) {
    this.parpayarray = [];
    for (var i = 0; i < this.billList.length; i++) {
      if (this.billList[i].billno == billno) {
        this.parpayarray = this.billList[i].partial_payments;
      }
    }
    this.showarray = !this.showarray;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getRetrieveData() {
    var send = {
      inpatient_id: Doc_Helper.getAdmission_id().admission_id,
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "inpat/gdsv", send,
      { headers: headers })
      .subscribe(
        response => {
          var values = JSON.parse(JSON.stringify(response));
          if (values != undefined || values != null) {
            this.hosp_name = values.hptl_name;

            var addval;
            if (values.address2 != undefined) {
              addval = values.address1 + "," + values.address2;

            } else {
              addval = values.address1;
            }
            this.hosp_addr = addval;
            this.hosp_csz = values.city + ", " + values.state + " - " + values.zipcode + "  " + values.country;

            if (values.telephone != undefined) {
              this.hosp_tel = values.telephone;

            } else {
              this.hosp_tel = "-";
            }
          }
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  print_area() {  
    if (this.billList.length == 0) {
      this.toastr.error("No Bills Found");
    } else {    
      this.save();     
    }
  }

  print() {
    var margin_top;
    let printContents, popupWin;
    if (this.print_template != undefined && this.print_template == "banner") {
      printContents = this.printbanner.nativeElement.innerHTML; 
      margin_top = '0px';    
    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
      margin_top = '122px';
    }
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>                        
          @page {
            size: auto;
            margin-top: ${margin_top};
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 0px;
            overflow: visible;
          } 
          * {
              font-size: 12px !important;
              line-height: 1 !important;
              page-break-inside: always;
              overflow: visible;
            }                                     
          @media print {   
            body {
              height: auto; /* Allow body height to adjust dynamically */
            }          
            .page-break {
              page-break-before: always; /* or page-break-after: always; */
              page-break-inside: always;
            }
          }           
          .address_visibility {
            visibility: hidden;
          }        
          table.report-container {
            page-break-after: always;
          }
          thead.report-header {
            display: table-header-group;
          }
          tfoot.report-footer {
            display: table-footer-group;
          }
          .print:last-child {
            page-break-after: auto;
          }
          .alignRight {
            text-align: right;
          }
          .col-print-1 {width:8%;  float:left;}
          .col-print-2 {width:16%; float:left;}
          .col-print-3 {width:25%; float:left;}
          .col-print-4 {width:33%; float:left;}
          .col-print-5 {width:42%; float:left;}
          .col-print-6 {width:50%; float:left;}
          .col-print-7 {width:58%; float:left;}
          .col-print-8 {width:66%; float:left;}
          .col-print-9 {width:75%; float:left;}
          .col-print-10 {width:83%; float:left;}
          .col-print-11 {width:92%; float:left;}
          .col-print-12 {width:100%; float:left;}
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
    popupWin.document.close();    
  }

  getPaymentType() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.paymentArray = obj.payments;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }
}
