import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Http, Headers } from '@angular/http';
import {Message_data} from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { ipaddress } from '../../ipaddress' ;
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Time_Formate, convertNumberToWords } from 'src/assets/js/common';

@Component({
  selector: 'app-recept-bill-edit',
  templateUrl: './recept-bill-edit.component.html',
  styleUrls: ['./recept-bill-edit.component.css']
})
export class ReceptBillEditComponent implements OnInit {
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  public docRegId;
  public billType: string;
  public amount;
  public finalAmount;
  public inGST: boolean;
  public concession;
  public sendConcession;
  public amountAftCon;
  public gstAmount;
  public advanceBill;
  public appointmentDate;
  public billDetailedView:any = [];
  public sendBillAppointId;
  public hospitalName;
  public billTreatmentPlanList = [];
  public gstRetriveList = [];
  public billAddList:any = [];
  public paymentsarray:any=[];
  public gstDataList = [];
  public chargeName;
  public estimateId = [];
  public remainingBalance;
  public cgst;
  public sgst;
  public clientRegId;
  public relationId;
  public subRelId;
  public ChargeId;
  public docId: string;
  public billId: string;
  public splName: string;
  public payTypeList = [];
  public insurerList =[];
  public payType: string;
  public insurerId: string;
  public insurFlag:boolean;
  public getBillEdit;
  public patientName: string;
  public prevBillId;
  public balanceFlag: boolean;
  public amountCollected: boolean;
  public prevbalanceFlag: boolean;
  public advanceFlag:boolean;
  public prevbalance;
  public totalCollected;   
  //mdbTable
  dtOptions: DataTables.Settings = {};
  public transactionFlag:boolean;
  public transactionId;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag:boolean;
  public bankName;
  public serviceType = [];
  public inpatientFlag: boolean = false;
  public quantity: any = 1;
  public discount: any = 0;
  public paidBill: any = 0;
  public updateFlag:boolean = true;
  public updater;
  public finyear:string;
  private sno:number=0;
  public tamt:number=0;
  public paytypreq: boolean=false;
  public payType1;
  public amountval:any;
  private userInfo;
  public checkBox: boolean;
  public RateCardArray = [];
  public hptlClinicId: any;
  public rate_card: any;
  public Moduleidlist: any = [];
  public editbutton: boolean;
  public newbutton: boolean;
  public deletebutton: boolean;
  public printbutton: boolean;
  public viewbutton: boolean;
  public mrno: any;
  public clnt_age: any;
  public gender: any;
  public mobile_no: any;
  public address: string;
  public location: string;
  public city: string;
  public state: string;
  public dr_name: string;
  public createpage_flag: boolean = false;
  public profile_image: string;
  public admitted_date: any;
  public admitted_time: string;
  public e_contact: any;
  public dept_desc: any;
  public wardName: any;
  public bed_no: any;
  public totalBillAmount: any;
  public discount_amount;
  public settled;
  public finalized;
  public status: string = "";
  public bill_status: any = "";
  public total: string;
  public user_id: any;
  public billingFlow;
  public credit_flag: boolean = true;
  public credit_bill;
  public credit_type: string;
  public hosp_credits: any = [];
  public credit_person;
  public discount_flag:boolean = false;
  public inpatient_flag:boolean = false;
  public bill_qty_flag: boolean = false;
  public multiply_flag: boolean = true;
  public amount_td_style;
  public temp_key;
  public printlogostyle: string;
  public hospitalLogo;
  public printtemplate;
  public print_size;
  public created_date: string;
  public surgery;
  public barcode_style: string;
  public nobanner_style;
  public barcode_flag:boolean = false;
  public barcode_url;
  public numToWords: any;
  public logUserName;
  public paymentsarray_total;
  public p_tag_style: any;
  public sendAdvanceAmount: any;
  public categoryChargeList: any[];
  // public doctor_visits_quantity: any = "1";
  
  constructor(public toastr: ToastrService, public http: Http,
    public frontservice: MenuViewService, public adminservice:MenuViewService,public cdr: ChangeDetectorRef){
    this.insurFlag = true;
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.finalAmount = 0;
    this.gstAmount = 0;
    this.advanceBill = 0;
    this.concession = 0;
    this.inGST = false;
    this.discount_flag = false;
  }

  ngOnInit(): void {
    this.profile_image = "../../assets/img/default.jpg";
    this.updateFlag = true;
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
  
    this.userInfo = Helper_Class.getInfo();
    console.log("UserInfo",this.userInfo);
    this.user_id = this.userInfo.user_id;
    
    if(Helper_Class.getIsAdmin() != undefined){
      this.updater = "Admin";
    }else{
      
      this.updater = this.userInfo.user_type;
    }
    if(this.userInfo.bill_pay_type_req =="1"){
      this.paytypreq = false;
    } else {
      this.paytypreq = true;
    }

    if (this.userInfo.bill_qty_flag == 0) {
      this.bill_qty_flag = true;
    } else {
      this.bill_qty_flag = false;
    }

    if (this.userInfo.first_name != undefined) {
      this.logUserName = this.userInfo.first_name;
      if (this.userInfo.last_name != undefined) {
        this.logUserName = this.userInfo.first_name+" "+this.userInfo.last_name;
      }
    }

    this.Moduleidlist = Helper_Class.getmodulelist();
    if (this.Moduleidlist != undefined && this.Moduleidlist.length != 0) {
      for (var i = 0; i < this.Moduleidlist.length; i++) {
        if (this.Moduleidlist[i].module_id == "7") {
          if (this.Moduleidlist[i].edit == "1") {
            this.editbutton = true;
          }                    
          if (this.Moduleidlist[i].create == "1") {
            this.newbutton = true;
          }
          if (this.Moduleidlist[i].delete == "1") {
            this.deletebutton = true;
          }
          if (this.Moduleidlist[i].print == "1") {
            this.printbutton = true;
          }
          if (this.Moduleidlist[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    }

    if (this.userInfo.hptl_name == 'SGH') {
      this.p_tag_style = "text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;margin-top: 10px;";
    } else {
      this.p_tag_style = "text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;";
    }

    if( Helper_Class.getLoginType() == "doctor" ){   
      this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().hospitals[0].bill_print_logo;
      this.printtemplate = Helper_Class.getInfo().hospitals[0].bill_print_template;   
      this.printlogostyle = Helper_Class.getInfo().hospitals[0].bill_print_logo_style;
      this.print_size = Helper_Class.getInfo().hospitals[0].bill_print_paper;
      
    } else{
      this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
      this.printtemplate = Helper_Class.getInfo().bill_print_template;   
      this.printlogostyle = Helper_Class.getInfo().bill_print_logo_style;
      this.print_size = Helper_Class.getInfo().bill_print_paper;
    } 
    
    this.totalBillAmount = 0;
    this.discount_amount = 0;
    this.hptlClinicId = this.userInfo.hospitals[0].hptl_clinic_id;
    this.getBillEdit = FrontDesk_Helper.getreceptbilledit();
    this.billId =  this.getBillEdit.bill_view_id;
    this.docId = this.getBillEdit.doc_reg_id;
    this.finyear = this.getBillEdit.fin_year;
    this.inpatientFlag = this.getBillEdit.is_inaptient == "yes" ? true : false;
    this.docRegId = this.getBillEdit.doc_reg_id;
    this.splName = Helper_Class.getspecalization_frontdesk();
    this.Get_current_date();
    this.gstRetriveList = [];
    this.billAddList = [];
    this.Get_Gst_Data();
    this.getPaymentType();
    // this.Get_Bill_Type();
    this.estimateId = [];
    this.prevbalanceFlag = true;
    this.advanceFlag = true;
    this.balanceFlag = true;   
    this.RateCardData();     
    // this.cdr.detectChanges();
    this.getBillDetails();
    this.getInsurers();   
    this.getCreditTypes();
    setTimeout(()=>{
      this.Get_Bill_Type();
    },500);   
  }
  
  Bill_add_click() {//Bill add table
    if (this.billType == undefined) {
      this.toastr.error(Message_data.sltServiceType);
    } else if (this.amount == undefined || this.amount == '') 
      this.toastr.error(Message_data.enterAmt);
    else if (this.quantity == undefined) 
      this.toastr.error(Message_data.enterQty);
    else {
      if (this.finalAmount == 0) {
        this.inGST == false;
      }

      var flag = true;

      for (var i = 0; i < this.billAddList.length; i++) {
        if(this.billAddList[i].status != '2'){
          if (this.billAddList[i].biill_type == this.billType && this.billAddList[i].paytype == this.payType) {
            flag = false;
            break;
          }
        }
      }

      if (flag == true) {
        if (this.chargeName.length != 0) {
          for (var i = 0; i < this.chargeName.length; i++) {
            if (this.billType == this.chargeName[i].description) {
              this.ChargeId = this.chargeName[i].charge_id;
            }
          }
        }
        var paydesc;
        if (this.payTypeList.length != 0) {
          for (var i = 0; i < this.payTypeList.length; i++) {
            if (this.payType == this.payTypeList[i].pay_id) {
              paydesc = this.payTypeList[i].pay_desc;
            }
          }
        }

        this.sendConcession = this.concession == "" ? 0 : this.concession;
        this.sno+=1;
        var total: any = 0;
        total = (parseFloat(this.quantity) * parseFloat(this.amount));
        
        this.billAddList.push({
          sno:this.sno.toString(),
          biill_type: this.billType,
          amount_txt: parseFloat(this.amount),
          concession: parseFloat(this.sendConcession),
          fee: parseFloat(this.amountAftCon),
          total: total,
          insurer:this.insurerId,
          charge_type: this.ChargeId,
          pay_type:paydesc,
          paytype:this.payType,
          status: "1",
          quantity: parseFloat(this.quantity),
          fin_year:this.finyear,
        });
        this.billType = undefined;
        this.amount = '';
        this.concession = 0;
        this.amountAftCon = '';
        this.quantity = 1;
      }

      if (this.finalAmount == 0) {
        this.inGST == false;
      }
      this.Calculate_gst("0", "0");
    }
  }

  consolidatedBilling(){
    var inpatientBill = {
        app_id : this.getBillEdit.app_id,
        flow: "inpatien-edit",
        bill_id:this.billId,
        updater_type : "front-desk",
    }
    Helper_Class.setInpatientBillEdit(inpatientBill);
    Helper_Class.setInpatientBillCreate("front-desk");
    this.frontservice.sendMessage("ipatientBillCreate");
  }

  getCreditTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/ghco',
        JSON.stringify({
          country: ipaddress.country_code.toString(),
          hptl_clinic_id: this.userInfo.hptl_clinic_id,
          type: 'hospital',
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();
          if (obj.hosp_credits != null) {
            for (var i = 0; i < obj.hosp_credits.length; i++) {
              this.hosp_credits.push({
                credit_id: obj.hosp_credits[i].credit_id,
                credit_desc: obj.hosp_credits[i].credit_desc,
              });
            }
          }
        },
        (error) => {}
      );
  }

  RateCardData() {
    var requestData = { 
      centre_id: this.hptlClinicId,
      center_type:'hospital' 
    };
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');  
    this.http.post(ipaddress.getIp.toString() + 'mas/grcd', requestData, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();
          console.log(obj);
          var RateCard = obj.rate_cards;
          for (var i = 0; i < RateCard.length; i++) {
            this.RateCardArray.push({
              discount: RateCard[i].discount,
              rate_card_desc: RateCard[i].rate_card_desc,
              rate_card_id: RateCard[i].rate_card_id,
            });
          }
          console.log(this.RateCardArray);
        },
        (error) => {
          this.toastr.error('An error occurred');
        }
      );
  } 

  getConcession(){
    for (var i=0;i<this.RateCardArray.length;i++) {
      if (this.RateCardArray[i].rate_card_id == this.rate_card) {
        if (this.RateCardArray[i].rate_card_desc == 'Service wise') {         
          this.discount_flag = false;          
          if (this.concession != '') {
            this.concession = this.concession;
          } else {
            this.concession = parseFloat(this.RateCardArray[i].discount);
          }       
        } else {
          this.discount_flag = true;
        }
        if (this.RateCardArray[i].rate_card_desc != 'Service wise') {
          this.concession = parseFloat(this.RateCardArray[i].discount);
        }  
        this.flag_function();   
      }
    } 

    if (this.billAddList != undefined && this.billAddList.length != 0) {
      for (var i = 0; i < this.categoryChargeList.length; i++) {
        for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
            this.categoryChargeList[i].charges[j].concession == this.concession;    
        }
      }
      this.billAddList = this.combineCharges(this.categoryChargeList);
      for (var i = 0; i < this.billAddList.length; i++) {
        this.billAddList[i].concession = this.concession;
        this.changeamount1(this.billAddList[i],0);
      }
    }  
  } 

  flag_function () {
    if (this.bill_qty_flag == true && !this.discount_flag == false && this.inpatient_flag == false) {
      this.multiply_flag = true;
    } else if (this.bill_qty_flag == true && !this.discount_flag == true && this.inpatient_flag == false) {
      this.multiply_flag = true;
    } else if (this.bill_qty_flag == true && !this.discount_flag == false && this.inpatient_flag == true) {
      this.multiply_flag = false;
    } else if (this.bill_qty_flag == true && !this.discount_flag == true && this.inpatient_flag == true) {
      this.multiply_flag = true;
    } else if (this.bill_qty_flag == false && !this.discount_flag == false && this.inpatient_flag == false) {
      this.multiply_flag = false;
    } else if (this.bill_qty_flag == false && !this.discount_flag == true && this.inpatient_flag == false) {
      this.multiply_flag = true;
    } else if (this.bill_qty_flag == false && !this.discount_flag == false && this.inpatient_flag == true) {
      this.multiply_flag = false;
    } else if (this.bill_qty_flag == false && !this.discount_flag == true && this.inpatient_flag == true) {
      this.multiply_flag = true;
    }

    if (this.bill_qty_flag == true && this.discount_flag == true && this.inpatient_flag == false) {
      this.amount_td_style = "text-align: right; border: 1px solid black;border-collapse: collapse;width: 300px";
    } else {
      this.amount_td_style = "text-align: right; border: 1px solid black;border-collapse: collapse;width: 150px;";
    }
  }

  Delete_bill(data) {    
    if (data.biill_type.length != 0  ) {    
      if (!this.inpatient_flag) {
        for (var i = 0; i < this.billAddList.length; i++) {
          if (this.billAddList[i].status != '2') {
              if (this.billAddList[i].biill_type == data.biill_type && this.billAddList[i].amount_txt == data.amount_txt) {                       
                this.billAddList.splice(i, 1);
                this.Calculate_gst("0", "0");
                break;                         
              }         
          }
        }
  
        this.sno=1;
        for (var i = 0; i < this.billAddList.length; i++) {
          if (this.billAddList[i].status != '2') {
            this.billAddList[i].sno=this.sno;
            this.sno +=1;
          }
        }
      }
      
      if (this.inpatient_flag) {
        for (var i = 0; i < this.categoryChargeList.length; i++) {
          for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
            if (
              this.categoryChargeList[i].charges[j].biill_type == data.biill_type &&
              this.categoryChargeList[i].charges[j].amount_txt == data.amount_txt
            ) {
              this.categoryChargeList[i].charges.splice(j, 1);
              break;
            }
          }
        }
  
        var rowid = 1;
        for (var i = 0; i < this.categoryChargeList.length; i++) {
          for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
            this.categoryChargeList[i].charges[j].sno = rowid;
            rowid += 1;
          }
        }
  
        this.createGroupping();
        this.billAddList = this.combineCharges(this.categoryChargeList);
        this.Calculate_gst("0", "0");
      }
      
      if (this.finalAmount == 0) {
        this.inGST == false;
      }
      
    } else {     
      if (!this.inpatient_flag) {
        for (var i = 0; i < this.billAddList.length; i++) {
          if (this.billAddList[i].sno == data.sno) {
            if (data.biill_type == '') {
              this.billAddList.splice(i, 1);
              this.Calculate_gst('0','0');
              break;
            }
          }     
        }
  
        this.sno=1;
        for (var i = 0; i < this.billAddList.length; i++) {   
          this.billAddList[i].sno=this.sno;
          this.sno +=1;           
        }
      }
      
      if (this.inpatient_flag) {
        for (var i = 0; i < this.categoryChargeList.length; i++) {
          for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
            if (
              this.categoryChargeList[i].charges[j].sno == data.sno &&
              data.biill_type == ''
            ) {
              this.categoryChargeList[i].charges.splice(j, 1);
              this.createGroupping();
              this.Calculate_gst('0', '0');
              break;
            }
          }
        }
  
        var rowid = 1;
        for (var i = 0; i < this.categoryChargeList.length; i++) {
          for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
            this.categoryChargeList[i].charges[j].sno = rowid;
            rowid += 1;
          }
        }
      }  
    }     
  }

  combineCharges(groupedData) {
    // Use reduce to iterate over each item and combine their charges
    // if(groupedData != undefined) {
      return groupedData.reduce((combinedCharges, item) => {
        return combinedCharges.concat(item.charges);
      }, []);
    // }
  }

  Get_Bill_Type() {//Get bill type
    var type;
    if (Helper_Class.getBillingFlow() != undefined)
      type = Helper_Class.getBillingFlow().flow == 'patient' ? "doctor" : "dietician";
    else
      type = "doctor";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/chgtype',
      JSON.stringify({
        hptl_clinic_id: this.hptlClinicId,
        provider: type,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log(obj);
          this.chargeName = obj.charges;
          console.log(this.inpatient_flag);
          if (this.inpatient_flag == true) {
            this.chargeName = Helper_Class.getBillCharges();
            this.chargeName = this.chargeName.charges;
          }
          console.log("ChargeNameArray",this.chargeName);  

        },
        error => {
         this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  charge_change(charge){
    this.billType = charge;
    this.serviceType = [];
      for (var i = 0; i < this.chargeName.length; i++) {
        if (this.chargeName[i].description == this.billType) {
          this.amount = this.chargeName[i].amount;
          this.amountAftCon = this.chargeName[i].amount;
        }
      }  
  }

  getPaymentType() {//Get payment type
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.payTypeList = obj.payments;
        },
        error => {
         this.toastr.error(Message_data.defaultErr);
        });
  }

  pay_change(){
    this.insurFlag = true;
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.payType == "3")
      this.insurFlag = false;
    else if(this.payType == "4")
      this.transactionFlag = false;
    else if(this.payType == "2"){
      this.transactionFlag = false;
      this.cardFlag = false;
    }else if(this.payType == "5")
      this.chequeFlag = false;
    else 
      this.insurFlag = true;
  }

  getInsurers() {//Get payment type
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.insurerList = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  Get_current_date() {//Get current date
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            var get_date = obj.current_date.split('-');
            this.appointmentDate = get_date[2] + "-" + get_date[1] + "-" + get_date[0]
          }
        },
        error => {
         this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  getBillDetails() {
    this.mrno = "";
    this.patientName = "";
    this.clnt_age = "";
    this.gender = "";
    this.address = "";
    this.location = "";
    this.city = "";
    this.state = "";
    this.mobile_no = "";
    this.dr_name = "";
    this.admitted_date = "";
    this.admitted_time = "";
    this.e_contact = "";           
    this.dept_desc = "";
    this.wardName = "";
    this.bed_no = "";
    this.profile_image = "";

    var send;
    this.billingFlow = Helper_Class.getBillingFlow();
    var type;
    if (this.billingFlow != undefined)
      type = this.billingFlow.flow == 'patient'  ? "doctor" : this.billingFlow.flow == 'package' ? 'package' : this.billingFlow.flow == 'All' ? 'All': "dietician";
    else
      type = 'doctor';

    if (type == 'doctor') {
      if(Helper_Class.getIsAdmin() != undefined){
        send = {
          doc_reg_id: this.docId,
          billing_id: this.billId,
          app_type:type,
          bill_to:"edit",
          fin_year: this.finyear,
          center_id: this.hptlClinicId
        }
      }else{
        send = {
          doc_reg_id: this.docId,
          billing_id: this.billId,
          spl_name: this.splName,
          app_type:type,
          bill_to:"edit",
          fin_year: this.finyear,
          center_id: this.hptlClinicId
        }
      }
    } else {
      if(Helper_Class.getIsAdmin() != undefined){
        send = {
          billing_id: this.billId,
          app_type:type,
          bill_to:"edit",
          fin_year: this.finyear,
          // center_id: this.hptlClinicId
        }
      }else{
        send = {
          billing_id: this.billId,
          spl_name: this.splName,
          app_type:type,
          bill_to:"edit",
          fin_year: this.finyear,
          // center_id: this.hptlClinicId
        }
      }
    }
   
    console.log(send);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbill/',
      JSON.stringify(send),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log(obj);
          this.createpage_flag = true;
          this.hospitalName=obj.hptl_name;
          var saldata;
          saldata = obj.salutation != undefined ? obj.salutation+"." : "";

          if (obj.rate_card_desc == 'Service wise') {                  
            this.discount_flag = false;                 
          } else {
            this.discount_flag = true;
          }

          if (obj.inp_hosp_id != undefined && obj.inp_hosp_id != null && obj.inp_hosp_id != "") {
            this.inpatient_flag = true;
          } else {
            this.inpatient_flag = false;
          }
          console.log(this.inpatient_flag);

          if (obj.middle_name != null && obj.middle_name != undefined) {
            this.patientName = saldata + encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.middle_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
          } else if(obj.first_name != undefined && obj.last_name != undefined){
            this.patientName = saldata + encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
          } else {
            this.patientName = "";
          }

          if (obj.caddress2 != null && obj.caddress2 != undefined) {
            this.address = encrypt_decript.Decript(obj.caddress1)+ ","+encrypt_decript.Decript(obj.caddress2);
          } else if (obj.caddress1 != null){
            this.address = encrypt_decript.Decript(obj.caddress1);
          }
          var zipcode;
          zipcode = obj.czipcode != undefined ? encrypt_decript.Decript(obj.czipcode) : "";

          var country;
          country = obj.ccountry != undefined ? obj.ccountry : "";
          if (obj.dr_middle_name != undefined && obj.dr_middle_name != null) {
            this.dr_name = "Dr."+obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
          } else if (obj.dr_first_name != undefined && obj.dr_last_name != undefined){
            this.dr_name = "Dr."+obj.dr_first_name + " " + obj.dr_last_name;
          } else if (obj.dr_first_name != undefined) {
            this.dr_name = "Dr."+obj.first_name;
          } else {
            this.dr_name = "";
          }

          if (obj.doc_qualif != undefined && obj.doc_qualif != null && obj.doc_qualif != "") {
            this.dr_name = this.dr_name + " " + obj.doc_qualif;
          }

          if (obj.profile_image != ""){
            this.profile_image = ipaddress.Ip_with_img_address + obj.profile_image;
          }

          this.location =  obj.clocation != undefined ? obj.clocation+"," : "";
          this.city =  obj.ccity != undefined ? obj.ccity +" - " + zipcode + ",": "";
          this.state = obj.cstate != undefined ? obj.cstate + " - " + country + "." : "";
          this.mrno = obj.client_hosp_id != undefined ? obj.client_hosp_id : "";
          this.clnt_age = obj.age != undefined ? obj.age : "";
          this.gender = obj.gender != undefined ? encrypt_decript.Decript(obj.gender) : "";
          if ( this.gender != null &&  this.gender != undefined && this.gender != "") {          
            this.gender = this.gender[0];
            if (this.gender[0] == "T") {
              this.gender = "Tgen";
            }
          }

          this.mobile_no = obj.mobile != undefined ? encrypt_decript.Decript(obj.mobile) : "";
          this.admitted_date = obj.admission_date != undefined ? obj.admission_date : "";
          this.admitted_time = obj.admission_time != undefined ? Time_Formate(obj.admission_time) : "";
          this.e_contact = obj.emerg_contact != undefined ? encrypt_decript.Decript(obj.emerg_contact) : "";
          this.dept_desc = obj.department_desc != undefined ? obj.department_desc : "";
          this.wardName = obj.ward_name != undefined ? obj.ward_name : "";
          this.bed_no = obj.bed_no != undefined ? obj.bed_no : "";

          if (obj.rate_card != null) {
            this.rate_card = obj.rate_card;
            this.getConcession();
          }

          if (obj.prev_balance != null && obj.prev_balance != undefined && obj.prev_balance != 0) {
            this.prevbalanceFlag = false;
            this.prevbalance = parseFloat(obj.prev_balance);
          }

          // if(obj.advance != null && obj.advance != undefined && obj.advance != 0) {
          //   this.advanceFlag = false;
          //   this.advanceBill = parseFloat(obj.advance);
          // }
            
          this.clientRegId = obj.client_reg_id;

          this.finalAmount = obj.bill_amount != undefined ? parseFloat(encrypt_decript.Decript(obj.bill_amount)) : "";
          this.paidBill = obj.paid_amount != undefined ? parseFloat(obj.paid_amount) : 0;
        
          this.discount = obj.round_off !=  undefined ? obj.round_off : 0;
          
          if (obj.estimates != null) {
            var rowcount=1;
            for (var i = 0; i < obj.estimates.length; i++) {
              var checkflag;
              if (this.billId == obj.estimates[i].bill_id){
                checkflag = true;
                this.estimateId.push(obj.estimates[i].den_treatment_plan_id);
              } else {
                checkflag = false;
              }
              
              this.billTreatmentPlanList.push({
                treatment_desc: obj.estimates[i].treatment_desc,
                estimate: obj.estimates[i].estimate,
                den_treatment_plan_id: obj.estimates[i].den_treatment_plan_id,
                rowid:rowcount,
                checked:checkflag,
              });
              rowcount++;
            }
          }

          if (obj.bills != null) {
            var sno=1;
            for(var i=0;i<obj.bills.length;i++){            
              var perce = obj.bills[i].concession / 100;
              var amt = obj.bills[i].fee;
              var amt_cons = this.amountAftCon;
              var net_pay = parseFloat(amt) * parseFloat(perce.toString());
              var tot = amt - net_pay;
              if (isNaN(tot)) {
                tot = 0;
              }
              
              var lineitem = (parseFloat(obj.bills[i].fee)/(100- parseFloat(obj.bills[i].concession))*100);
              var quantity = 1;
              if(obj.bills[i].quantity != undefined){
                quantity = obj.bills[i].quantity;
              }

              var total: any = 0;
              total = (parseFloat(obj.bills[i].quantity) * parseFloat(obj.bills[i].amount_txt));

              this.quantity = obj.bills[i].quantity;
              var concession = obj.bills[i].concession != undefined && obj.bills[i].concession != null && obj.bills[i].concession != "" ?  obj.bills[i].concession : 0;
              var fee = obj.bills[i].fee != undefined && obj.bills[i].fee != null && obj.bills[i].fee != "" ?   obj.bills[i].fee : 0;
              var lineitem = (parseFloat(fee)/(100 - parseInt(concession))*100);
              this.sno=parseInt(obj.bills[i].index);
              this.billAddList.push({
                sno: sno,
                biill_type: obj.bills[i].charge_desc,
                amount_txt: parseFloat(obj.bills[i].amount_txt),
                concession: concession,
                fee: parseFloat(fee),
                charge_type: obj.bills[i].charge_type,
                paytype:obj.bills[i].pay_id,
                pay_type:obj.bills[i].pay_type,
                status:"2",
                quantity: parseFloat(obj.bills[i].quantity),
                total: total,
                bill_details_id:obj.bills[i].bill_details_id,
                fin_year:obj.bills[i].fin_year,
                edit_flag: true,
                category: obj.bills[i].category,
                order_no: obj.bills[i].order_no,
                charge_cat_id: obj.bills[i].charge_cat_id
              });
              this.concession = concession;
              sno+=1;   
                         
            }
            console.log(this.billAddList);

            // if (this.inpatient_flag && obj.doctor_visits != undefined && obj.doctor_visits.length != 0) {
            //   var sno=1;
            //   for (var i = 0; i < obj.doctor_visits.length; i++) {
            //     this.doctor_visits_quantity = "1";
            //     var total;
            //     total = (parseFloat(obj.doctor_visits[i].fee) * parseFloat(this.doctor_visits_quantity));

            //     if (obj.doctor_visits[i].fee == '') {
            //       obj.doctor_visits[i].fee = 0;
            //     }

            //     this.billAddList.push({
            //       sno:sno,
            //       biill_type: obj.doctor_visits[i].charge_desc,
            //       amount_txt: parseFloat(obj.doctor_visits[i].fee),
            //       concession: parseFloat(this.concession),
            //       fee: this.getamountAftCon2(obj.doctor_visits[i].fee,this.doctor_visits_quantity),
            //       charge_type: obj.doctor_visits[i].charge_id,
            //       quantity: this.doctor_visits_quantity,
            //       total: parseFloat(total),
            //       category: obj.doctor_visits[i].category,   
            //       charge_cat_id: obj.doctor_visits[i].charge_cat_id
            //     })
            //     sno+=1;
            //   }
            // }

            if (this.inpatient_flag) {
              this.createGroupping();
              this.billAddList = this.combineCharges(this.categoryChargeList);
            }

            if (obj.cgst != undefined) {
              this.inGST= true;
              this.include_gst_click(this.inGST);
            }

            if (obj.barcode != undefined && obj.barcode != null) {
              this.barcode_flag = true;
              this.barcode_url = ipaddress.Ip_with_img_address + obj.barcode;
            }

            if (obj.created_date != undefined) {
              var get_date = obj.created_date.toString().split('-');
              var datetxt = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
              this.created_date = datetxt;
            }
            
            if (obj.created_time != undefined) {
              this.created_date = datetxt +" "+ Time_Formate(obj.created_time);
            }
            this.surgery = obj.surgery != undefined ? obj.surgery : '';

            var tot_amt: any = 0;
            if (obj.bills != null && obj.bills.length != 0) {
              for (var i = 0; i < obj.bills.length; i++) {
                if (obj.bills[i].fee != null) {
                  tot_amt = (parseInt(tot_amt) + parseFloat(obj.bills[i].fee));
                }
              }
            }

            if (obj.estimates != null) {
              if (obj.estimates.length != 0) {
                for (var i = 0; i < obj.estimates.length; i++) {
                  if (obj.estimates[i].estimate != null) {
                    tot_amt = (parseFloat(tot_amt) + parseFloat(obj.estimates[i].estimate));
                  }
                }
              }
            }

            var docname = "";
            if(obj.dr_middle_name != undefined)
              docname = obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
            else
              docname = obj.dr_first_name + " " + obj.dr_last_name;

            this.billDetailedView = {
              client_reg_id:obj.client_reg_id,
              doc_app_id:obj.appointment_id,
              relation_id:obj.relation_id != undefined ? obj.relation_id : "1",
              sub_rel_id:obj.sub_rel_id != undefined ? obj.sub_rel_id : undefined,
              docid_name: docname,
              doc_reg_id:obj.doc_reg_id,
            }
            
            if(obj.payments != undefined){
              for(var i=0;i<obj.payments.length;i++){
                this.paymentsarray.push({
                  pay_id: obj.payments[i].pay_id,
                  pay_desc:obj.payments[i].pay_desc,
                  amount:obj.payments[i].amount
                })
              }
            } else {
              this.payType1 = this.payTypeList[0].pay_id;
            }

            if (obj.status != undefined && obj.status != "") {
              this.bill_status = obj.status;
            }        
            this.Calculate_gst("0","0");                     
          } else {
            this.toastr.error(Message_data.getNetworkMessage())
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage())
        });
  }

  getamountAftCon2(amount,quantity) {
    var amount_aftcon;
    console.log(amount_aftcon = parseFloat(amount) * quantity - (this.concession / 100) * (parseFloat(amount) * quantity));
    return (amount_aftcon = parseFloat(amount) * quantity - (this.concession / 100) * (parseFloat(amount) * quantity));
  }

  createGroupping() {
    console.log(this.billAddList);
    this.categoryChargeList = this.billAddList;
    const groupedByCategory = this.groupBy(this.categoryChargeList, 'category');
    console.log("groupedByCategory",groupedByCategory);
    var hospitalChargeArray2 = [];
    var new_viewchargearray = [];
    for (var key in groupedByCategory) {
      console.log(key + ': ' + JSON.stringify(groupedByCategory[key]));
      if (key !== 'undefined') {
        console.log(key);
        new_viewchargearray = [];
        for (var k = 0; k < groupedByCategory[key].length; k++) {
            var charge_order_list = groupedByCategory[key][k].order_no;
            var category_Name = "";
            if (groupedByCategory.length > 1) {
              category_Name = groupedByCategory[key][k].category;
            }

            new_viewchargearray.push({
              sno: k + 1,
              biill_type: groupedByCategory[key][k].biill_type,
              amount_txt: groupedByCategory[key][k].amount_txt,
              concession: groupedByCategory[key][k].concession,
              fee: groupedByCategory[key][k].fee,
              charge_type: groupedByCategory[key][k].charge_type,
              paytype: groupedByCategory[key][k].paytype,
              pay_type: groupedByCategory[key][k].pay_type,
              status: groupedByCategory[key][k].status,
              quantity: groupedByCategory[key][k].quantity,
              total: groupedByCategory[key][k].total,
              bill_details_id: groupedByCategory[key][k].bill_details_id,
              fin_year: groupedByCategory[key][k].fin_year,
              edit_flag: groupedByCategory[key][k].edit_flag,
              category: key,
              order_no: charge_order_list,
              charge_cat_id: groupedByCategory[key][k].charge_cat_id,
            })
        }
        var category_flag;
        if (new_viewchargearray.length == 0) 
          category_flag = false;
        else 
          category_flag = true;
        
        hospitalChargeArray2.push({
            category: key,
            category_flag: category_flag,
            order_no: charge_order_list,
            charges: new_viewchargearray.sort((a, b) => a.order_no - b.order_no),
        })
      }  
    }

    this.categoryChargeList = [];
    this.categoryChargeList = hospitalChargeArray2.sort((a,b)=> a.order_no - b.order_no);
    var rowid = 1;
    for (var i = 0; i < this.categoryChargeList.length; i++) {
      for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
        this.categoryChargeList[i].charges[j].sno = rowid;
        rowid += 1;
      }
    }
    console.log("hospitalChargeArray2", hospitalChargeArray2);
    console.log("this.categoryChargeList",this.categoryChargeList);
  }

  groupBy(array, key) {
    return array.reduce((result, obj) => {
       const keyValue = obj[key];
       // If the key doesn't exist in the result, create an empty array for it
       if (!result[keyValue]) {
          result[keyValue] = [];
       }
       // Push the object to the corresponding key's array
       result[keyValue].push(obj);
       return result;
    }, {});
  }
 
  handleCheckboxChange(checkboxType: string) {
    if (checkboxType === 'settled' && this.settled) {
      this.finalized = false;
      this.status = "3";
    } else if (checkboxType === 'finalized' && this.finalized) {
      this.settled = false;
      this.status = "2";     
    }
  }

  Appointmnetid_change() {
    this.billTreatmentPlanList = [];
    if (this.billDetailedView.length != 0) {
        if (this.billDetailedView.docid_name == this.docRegId) {
          this.sendBillAppointId = this.billDetailedView.doc_app_id;
        }
    }

    this.Get_Bill_Type();
    var type = Helper_Class.getBillingFlow() != undefined ? "dietician" : "doctor";
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getappdet',
      JSON.stringify({
        appointment_id: this.sendBillAppointId,
        doc_reg_id: localStorage.getItem("user_id"),
        type: type,
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.hospitalName = obj.hosp_name;
          if (obj.middle_name !=  undefined) {
            this.patientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);
          } else {
            this.patientName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
          }     

          if (obj.phases != null) {
            for (var i = 0; i < obj.phases.length; i++) {
              this.billTreatmentPlanList.push({
                treatment_desc: obj.phases[i].treatment_desc,
                estimate: obj.phases[i].estimate,
                den_treatment_plan_id: obj.phases[i].den_treatment_plan_id,
              });
            }
          }

          // if (obj.advance != null && obj.advance != undefined){
          //   this.advanceBill = (obj.adv_balance != null && obj.adv_balance != undefined) ? (parseFloat(obj.advance) + parseFloat(obj.adv_balance)) : parseFloat(obj.advance); 
          // } else{
          //   this.advanceBill = (obj.adv_balance != null && obj.adv_balance != undefined) ? (parseFloat(obj.adv_balance)) : 0;
          // }

          if (obj.prev_bill_id != null)
            this.prevBillId = obj.prev_bill_id;

          if (obj.balance != 0) {
            this.amountCollected = true;
            this.balanceFlag = false;
            this.prevbalanceFlag = false;
            this.prevbalance = parseFloat(obj.balance);
            this.finalAmount = parseFloat(obj.balance);
            this.totalCollected = parseFloat(obj.balance);
          }
        },
        error => {});
  }

  addNewBillDetails() {
    if (this.inpatient_flag == false) {
      this.billAddList.push({
        sno: this.sno,
        biill_type: "",
        amount_txt: "",
        concession: "",
        fee: "",
        charge_type: "",
        insurer: "",
        ref_code: "",
        quantity: "",
        total: ""
      });
      this.sno=1;
      for (var i = 0; i < this.billAddList.length; i++) {
        this.billAddList[i].sno=this.sno;
        this.sno +=1;
      }
    }

    if (this.inpatient_flag == true) {
      this.categoryChargeList.push({
        charges: [{ sno: this.sno,
                    biill_type: '',
                    amount_txt: '',
                    concession: '',
                    fee: '',
                    charge_type: '',
                    pay_type: '',
                    insurer: '',
                    ref_code: '',
                    quantity: '',
                    total: '',
                  }],
        category_flag: true         
      });
  
      var rowid = 1;
      for (var i = 0; i < this.categoryChargeList.length; i++) {
        for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
          this.categoryChargeList[i].charges[j].sno = rowid;
          rowid += 1;
        }
      }
      console.log(this.categoryChargeList);
    }
    
    setTimeout(function () {
      $(".getChargeDesc").last().focus();
    }, 500);
  }

  amount_change() {// concession calculation
    var perce = null;
    perce = this.concession / 100;
    var amt = this.amount;
    if(this.quantity > 1){
      amt = amt * this.quantity;
    }
    var amt_cons = this.amountAftCon;
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }
    this.amountAftCon = Math.round(tot);
  }

  changeamount1(charge,num) {
    for (var i = 0; i < this.billAddList.length; i++) {
      if (charge.biill_type == this.billAddList[i].biill_type) {
        if (num != 2) {
          var concec;       
          if (charge.concession == 'NaN') {
            charge.concession = 0;
            concec = 0;
          } else {
            concec = charge.concession;
          }
          charge.fee = (charge.quantity * (charge.amount_txt - charge.amount_txt * (concec / 100)));
          charge.fee = parseFloat(charge.fee);

          charge.total = (charge.quantity * charge.amount_txt);
          charge.total = parseFloat(charge.total);
        } else {
          var fee;
          if (charge.fee == 'NaN') {
            charge.fee = 0;
            fee = 0;
          } else {
            fee = charge.fee;
          }
           
          if (charge.amount_txt != 0 && charge.quantity != '0' && charge.fee != '') {
            charge.concession = (100 - ((fee / (charge.amount_txt * charge.quantity)) * 100));
          } else {
            charge.concession = 0;
          }
        }
        this.billAddList = this.combineCharges(this.categoryChargeList);
        console.log(this.billAddList);
      }
    }
    this.Calculate_gst("0",num);
  }

  Get_Gst_Data() { //Get gst
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'gen/tax/',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.gstRetriveList = obj.taxes;
        },
        error => {
         this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  include_gst_click(check) {// Include GST
    this.Calculate_gst("0", "0");
  }

  Calculate_gst(palnid, num) {
    this.finalAmount = 0;
    this.totalBillAmount = 0;
    if (num != 1) {
      this.discount_amount = 0;
    }  
    this.gstAmount = 0;
    this.totalCollected = 0;
    this.gstDataList = [];

    if (this.billTreatmentPlanList.length != 0) {
      for (var i = 0; i < this.billTreatmentPlanList.length; i++) {
        for (var j = 0; j < this.estimateId.length; j++) {
          if (this.estimateId[j] == this.billTreatmentPlanList[i].den_treatment_plan_id) {
            this.totalBillAmount = (parseFloat(this.totalBillAmount) + parseFloat(this.billTreatmentPlanList[i].estimate));
            this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.billTreatmentPlanList[i].estimate));
            this.gstAmount = (parseFloat(this.gstAmount) + parseFloat(this.billTreatmentPlanList[i].estimate));

            if (parseFloat(this.advanceBill) < parseFloat(this.finalAmount)) {
              this.balanceFlag = false;
              this.totalCollected = (parseFloat(this.finalAmount) - parseFloat(this.advanceBill));
              this.remainingBalance = (parseFloat(this.finalAmount) - parseFloat(this.advanceBill));
            }

            if (parseFloat(this.advanceBill) > parseFloat(this.finalAmount)) {
              this.balanceFlag = false;
              this.remainingBalance = (parseFloat(this.advanceBill) - parseFloat(this.finalAmount));
            }

            this.amountCollected = true;

            if (this.totalCollected == 0) 
              this.amountCollected = false;

            if (this.remainingBalance == 0)
              this.balanceFlag = true;
            break;
          }
        }
      }
    }
  
    if (this.billAddList != undefined && this.billAddList.length != 0) {
      for (var i = 0; i < this.billAddList.length; i++) {
        if(this.billAddList[i].fee == ""){
          this.billAddList[i].fee = 0;
        }
        if(this.billAddList[i].amount_txt == ""){
          this.billAddList[i].amount_txt = 0;
        }
        if(this.billAddList[i].quantity == ""){
          this.billAddList[i].quantity = 0;
        }
        if(this.billAddList[i].concession == ""){
          this.billAddList[i].concession = 0;
        }

        if(this.billAddList.length != 0){ 
          if (num != 1) {

            this.discount_amount = (parseFloat(this.discount_amount) + (parseFloat (this.billAddList[i].amount_txt) * parseFloat(this.billAddList[i].quantity)) * (parseFloat(this.billAddList[i].concession)/ 100));
          }         
          this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.billAddList[i].fee));
          this.totalBillAmount = (parseFloat(this.totalBillAmount) + (parseFloat(this.billAddList[i].amount_txt) * parseFloat(this.billAddList[i].quantity)));
          this.gstAmount = (parseFloat(this.gstAmount) + parseFloat(this.billAddList[i].fee)); 
          if (parseFloat(this.paidBill) < parseFloat(this.finalAmount)) 
            this.remainingBalance = (parseFloat(this.finalAmount) - (parseFloat(this.paidBill) + parseFloat(this.advanceBill)));
  
          if (this.totalCollected == 0) 
            this.amountCollected = false;
  
          if (this.remainingBalance == 0)
            this.balanceFlag = true;
        }
     
        if (
          this.billAddList[i].fee == 0 &&
          this.billAddList[i].biill_type == ''
        ) {
          this.billAddList[i].fee = '';
        }
        if (this.billAddList[i].amount_txt == 0 && this.billAddList[i].biill_type == '') {
          this.billAddList[i].amount_txt = '';
        }
        if (this.billAddList[i].quantity == 0 && (this.billAddList[i].biill_type == '' || this.billAddList[i].biill_type != '')) {
          this.billAddList[i].quantity = '';
        }
        if (this.billAddList[i].total == 0 && this.billAddList[i].biill_type == '') {
          this.billAddList[i].total = '';
        }
        if (this.billAddList[i].biill_type == '') {
          this.billAddList[i].concession = '';
        }     
       
        if (this.billAddList[i].concession != '') {          
          var floatValue = this.billAddList[i].concession;
          var floatString = floatValue.toString();
          var parts = floatString.split('.');
          var digitsAfterDecimal = parts[1] ? parts[1].length : 0;
  
          if (digitsAfterDecimal === 2) { 
            // console.log("Value has 2 digits after the decimal point.");
          } else if (digitsAfterDecimal === 3) {
            this.billAddList[i].concession = parseFloat(this.billAddList[i].concession);
            // console.log("Value has 3 digits after the decimal point.");
          } else {
            // console.log("Value doesn't have 2 or 3 digits after the decimal point.");
          }
        }      
      }
      if (this.inGST == false) {
        this.finalAmount = Math.round(parseFloat(this.finalAmount));
      }   
      this.finalAmount = parseFloat(this.finalAmount);
      if (this.discount_amount != '' && this.discount_amount != 0) {          
        var floatValue = this.discount_amount;
        var floatString = floatValue.toString();
        var parts = floatString.split('.');
        var digitsAfterDecimal = parts[1] ? parts[1] : 0;
        if (digitsAfterDecimal[0] > 5) {
          this.discount_amount = Math.round(this.discount_amount);
          this.discount_amount = parseInt(this.discount_amount); 
        } else {
          this.discount_amount = parseInt(this.discount_amount);
        }                       
      }   
    }   

    if (this.inGST == true) {
      for (var i = 0; i < this.gstRetriveList.length; i++) {
        this.gstDataList.push({
          Gst_Descrip: this.gstRetriveList[i].tax_desc + " " +'('+ parseInt(this.gstRetriveList[i].tax)+' %'+')',
          Gst_amount: Math.round(parseFloat(this.gstAmount) * (parseFloat(this.gstRetriveList[i].tax) / 100))
        });
        this.finalAmount = (parseFloat(this.finalAmount) + Math.round(parseFloat(this.gstAmount) * (parseFloat(this.gstRetriveList[i].tax) / 100)));
        this.totalBillAmount = parseFloat(this.totalBillAmount) + Math.round(parseFloat(this.gstAmount) * (parseFloat(this.gstRetriveList[i].tax) / 100));
      }
      
      this.finalAmount = Math.round(parseFloat(this.finalAmount));
      this.totalBillAmount = parseFloat(this.totalBillAmount);
    }

    if (this.prevbalance != undefined) {
      this.finalAmount = (parseFloat(this.finalAmount) + parseFloat(this.prevbalance));
      this.totalBillAmount = (parseFloat(this.totalBillAmount) + parseFloat(this.prevbalance));
    }    

    //amount to be collected
    this.remainingBalance = (parseFloat(this.finalAmount) - (parseFloat(this.advanceBill) + parseFloat(this.paidBill)));
    
    if (this.estimateId.length == 0 && this.billAddList.length == 0) {      
      this.inGST = false;
      this.gstDataList = [];
      this.advanceBill = 0;
      this.finalAmount = 0;
      this.gstAmount = 0;
      this.totalCollected = 0;
      this.remainingBalance = 0;
    }

    if (parseFloat(this.finalAmount)<parseFloat(this.advanceBill)) 
      this.remainingBalance = 0;

    setTimeout(()=>{
      this.paid_change(); 
    },500);
  }

  changeDiscount() {
    if (this.discount_amount != '') {
      this.finalAmount = 0;
      this.totalBillAmount = 0; 
      if (this.billAddList.length != 0) {   
        for (var i = 0; i < this.billAddList.length; i++) {
          if (this.billAddList[i].fee == '') {
            this.billAddList[i].fee = 0;
          }
          if (this.billAddList[i].amount_txt == '') {
            this.billAddList[i].amount_txt = 0;
          }
          if (this.billAddList[i].quantity == '') {
            this.billAddList[i].quantity = 0;
          }
          if (this.billAddList[i].concession == '') {
            this.billAddList[i].concession = 0;
          }
 
          this.finalAmount = (
            parseFloat(this.finalAmount) + parseFloat(this.billAddList[i].amount_txt)
          );    
          
          this.totalBillAmount = (
            parseFloat(this.totalBillAmount) +
            parseFloat(this.billAddList[i].amount_txt) *
              parseFloat(this.billAddList[i].quantity)
          );                
        }      
      }

      if (this.discount_amount <= this.finalAmount) {
        this.finalAmount = (parseFloat(this.finalAmount) - parseFloat(this.discount_amount));
        if (this.inGST == false) {
          this.finalAmount = Math.round(parseFloat(this.finalAmount));
        }
        this.finalAmount = parseFloat(this.finalAmount);
      }        
      this.calculatePercentage();
    }

    if (this.discount_amount == '') {
      this.discount_amount = 0;
    }
  }

  calculatePercentage() {
    if (this.totalBillAmount && this.discount_amount) {
      this.concession = ((this.discount_amount / this.totalBillAmount) * 100).toFixed(3);
    } else {
      this.concession = 0;
    }
    
    if (this.concession != '') {     
      if (this.billAddList != undefined && this.billAddList.length != 0) {
        for (var i = 0; i < this.billAddList.length; i++) {
          this.billAddList[i].concession = this.concession;
          this.changeamount1(this.billAddList[i],1);
        }
      } 
      console.log("Calculated_Concession",this.concession);
    }      
  }

  advance_change() {
    if (this.advanceBill != undefined && this.advanceBill != "")
      this.remainingBalance = (parseFloat(this.finalAmount) - parseFloat(this.advanceBill));
    else
      this.remainingBalance = this.finalAmount;

    if (parseFloat(this.advanceBill) > parseFloat(this.finalAmount))
      this.remainingBalance = 0;
  }

  backClicked() {
    if(Helper_Class.getIsAdmin() != undefined)
      this.adminservice.sendMessage("frontdeskadminbilling");
    else if (Helper_Class.getLoginType() == 'doctor')
      this.frontservice.sendMessage('billing');   
    else
      this.frontservice.sendMessage('frontdeskbilling');
  }       

  bill_click(planid, planmodal, rowid){
    if(this.estimateId.length !=0){
      if(this.estimateId.indexOf(planid) >-1){
        for(var i=0;i<this.estimateId.length;i++){
          if(this.estimateId[i] == planid){
            this.estimateId.splice(i,1);
            this.Calculate_gst("0", "0");
          }
        }   
      } else {
        this.estimateId.push(planid)
        this.Calculate_gst("0", "0");
      }
    } else {
      this.estimateId.push(planid);
      this.Calculate_gst("0", "0");
    }
    
    for (var i = 0; i < this.billTreatmentPlanList.length; i++) {
      for (var j = 0; j < this.estimateId.length; j++) {
        if (this.estimateId[j] == this.billTreatmentPlanList[i].den_treatment_plan_id){
          this.billTreatmentPlanList[i].checked=true;
        }
      }
    }
  }

  print_area(print) {
    this.temp_key = print;
    var flag = true;
    if (this.billAddList.length == 0) {
      this.toastr.error(Message_data.getAddBill());
    } else {
      if (true) {
        this.Bill_update();
      }
    }
  }


  Bill_update() {
    var flag = true;
    var total_paid_amount: any = 0;
    if (parseInt(this.paidBill) != 0 && this.paidBill != "") {
      if (this.paymentsarray.length == 0) {
        this.toastr.error("Please Check the payment details");
        flag = false;
      } else if (this.paymentsarray.length != 0) {
        for (var p = 0; p < this.paymentsarray.length; p++) {
          total_paid_amount = parseFloat(total_paid_amount) + parseFloat(this.paymentsarray[p].amount);
        }
        console.log("this.paidBill  ",parseFloat(this.paidBill)+ "   " + "total_paid_amount  ",parseFloat(total_paid_amount));
        if (parseFloat(this.paidBill) != parseFloat(total_paid_amount)) {
          this.toastr.error("Please Check the payment details");
          flag = false;
        }
      } else {
        console.log("this.paidBill  ",parseFloat(this.paidBill)+ "   " + "total_paid_amount  ",parseFloat(total_paid_amount));
        flag = true;
      }
    }
    var cbill;
    var credit_person;
    if (this.credit_bill == true) {
      cbill = this.credit_type;
      credit_person = this.credit_person;
    } else {       
      this.credit_type = '';
      this.credit_person = '';     
    }

    if (this.surgery == '' && this.credit_type == '') {
      this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -35px;";
      this.nobanner_style = "width: 100%; height: 95px;";
    } else if (this.surgery != '' && this.credit_type != '') {
      this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -65px;";
      this.nobanner_style = "width: 100%; height: 115px;";
    } else if (this.surgery != '' || this.credit_type != '') {
      this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -45px;";
      this.nobanner_style = "width: 100%; height: 110px;";
    }

    if (this.billAddList.length == 0 && this.estimateId.length == 0) {
     this.toastr.error(Message_data.addbill);
      flag = false;
    }

    if (this.billAddList != undefined && this.billAddList.length != 0) {
      for (var i = 0; i < this.billAddList.length; i++) {     
        if (this.billAddList[i].quantity == '') {
          this.billAddList[i].quantity = '0';
        }      
        if (this.billAddList[i].biill_type == "" || this.billAddList[i].amount_txt == "") {
          this.billAddList.splice(i,1);
        }   
      }

      this.sno = 1;
      for (var i = 0; i < this.billAddList.length; i++) {
        if (this.billAddList[i].biill_type == '') {
          this.toastr.error("Remove the empty charge type rows");
          flag = false;
        }
        this.billAddList[i].sno = this.sno;
        this.sno +=1;
      }
    }

    if (this.billAddList.length != 0) {
      for (var i = 0; i < this.billAddList.length; i++) {
        if (this.billAddList[i].biill_type != '' && (this.billAddList[i].amount_txt == '0.00' || this.billAddList[i].amount_txt == 0 || this.billAddList[i].amount_txt == '0')) {
          this.toastr.error('Service charge cannot be zero');
          flag = false;
          break;
        }
      }
    }   

    if (flag == true) {
      if (this.billDetailedView.length != 0) {
        if (this.docRegId == this.billDetailedView.docid_name) {
          this.clientRegId = this.billDetailedView.client_reg_id;
          this.relationId = this.billDetailedView.relation_id;
          if (this.billDetailedView.sub_rel_id != undefined) {
            this.subRelId = this.billDetailedView.sub_rel_id;
          }
        }
        this.sendBillAppointId = this.billDetailedView.doc_app_id;
      }

      if (this.inGST == true && this.gstRetriveList.length != 0) {
        this.cgst = this.gstRetriveList[0].tax;
        this.sgst = this.gstRetriveList[1].tax;
      }

      // var paid_flag;
      // if(parseInt(this.finalAmount) != 0 && parseInt(this.remainingBalance) != 0){
      //   if (this.finalAmount == this.remainingBalance) {
      //     paid_flag = 'Un paid';
      //   }  
      // }else if (this.finalAmount != this.remainingBalance && (this.remainingBalance != 0)){
      //   paid_flag = "Partially paid";
      // }else if( this.remainingBalance == 0 || this.remainingBalance == "0" || this.remainingBalance == 0.00 || this.remainingBalance == 0 || parseInt(this.remainingBalance) < 0){
      //   paid_flag = "Fully paid";
      // }

      var advance;
      var totalAdvance;
      if( parseFloat(this.finalAmount) == (parseFloat(this.paidBill) + parseFloat(this.advanceBill))){
        advance = 0;
        totalAdvance = parseFloat(this.advanceBill);

      }else if( parseFloat(this.finalAmount) < parseFloat(this.advanceBill) ){
        advance = ((parseFloat(this.advanceBill) - parseFloat(this.finalAmount)));
        totalAdvance = (parseFloat(this.advanceBill) - parseFloat(advance));

      }else{
        advance = 0;
        totalAdvance = (parseFloat(this.advanceBill));
      }

      this.sendAdvanceAmount =
        totalAdvance != undefined ? totalAdvance : 0;
      
      var paid_flag;
      if (parseInt(this.sendAdvanceAmount) != 0) {
        if (parseInt(this.finalAmount) != 0 &&
          parseInt(this.sendAdvanceAmount) != 0 && parseInt(this.finalAmount) != parseInt(this.sendAdvanceAmount) && parseInt(this.remainingBalance) > 0) { 
            paid_flag = 'Partially paid';
        } else if (parseInt(this.finalAmount) == parseInt(this.remainingBalance)) {
            paid_flag = 'Un paid';
        } else if (
          this.remainingBalance == 0 ||
          this.remainingBalance == '0' || parseInt(this.remainingBalance) <= 0 
        ) {
          paid_flag = 'Fully paid';
        }
      } else {
        if (parseInt(this.finalAmount) != 0 &&
            parseInt(this.paidBill) != 0 && parseInt(this.remainingBalance) > 0) { 
            paid_flag = 'Partially paid';
        } else if (parseInt(this.finalAmount) == parseInt(this.remainingBalance)) {
            paid_flag = 'Un paid';
        } else if (
          this.remainingBalance == 0 ||
          this.remainingBalance == '0' || parseInt(this.remainingBalance) <= 0 
        ) {
          paid_flag = 'Fully paid';
        }
      }

      if (parseFloat(this.paidBill) > parseFloat(this.finalAmount)) {
        this.paidBill = this.finalAmount;
        paid_flag = 'Fully paid';
      }

      var send_status,status;
      if (this.settled) {
        send_status = this.status;
      } else if (this.finalized) {
        send_status = this.status;
      } else if (this.bill_status != undefined && this.bill_status != "") {
        status =  parseInt(this.remainingBalance) <= 0 ? "0" : "1";
        send_status = status;
      }

      var balance = parseInt(this.remainingBalance) <= 0 ? 0 : this.remainingBalance.toString();
      var finalAmount;
      var temp_discount_amount = parseFloat(this.discount_amount).toFixed(2);
      var temp_final_amount = parseFloat(this.finalAmount).toFixed(2);
    
      if (parseInt(this.finalAmount) == 0) {
        finalAmount = encrypt_decript.Encript(temp_discount_amount).toString();
      } else {
        finalAmount = encrypt_decript.Encript(temp_final_amount).toString();
      }

      var send_datavalue;
      var type = Helper_Class.getBillingFlow() != undefined ? "dietician" : "doctor";

      send_datavalue =  {
        bill_id: this.billId,
        app_type: type,
        doc_reg_id: this.docId,
        bill_amount: finalAmount,
        paid_amount:this.paidBill,
        paid_flag : paid_flag,
        created_by: this.user_id,
        country: ipaddress.country_code,  
        advance_balance: advance,
        advance: totalAdvance,
        client_reg_id: this.clientRegId,
        relation_id: this.relationId,
        balance: balance,
        cgst: this.cgst,
        sgst: this.sgst,
        appointment_id : this.sendBillAppointId,
        prev_bal: this.prevbalance,
        prev_bill_id: this.prevBillId,
        hptl_clinic_id: this.hptlClinicId,
        round_off : this.discount,
        updater_type: this.updater,
        card_no : this.cardNumber,
        card_holder_name : this.cardHolderName,
        transaction_no : this.transactionId,
        bank_name: this.bankName,
        estimates: this.estimateId,
        fin_year:this.finyear,
        bills: this.billAddList,
        payments: this.paymentsarray,
        rate_card: this.rate_card,
        status: send_status,
        discount: this.discount_amount,
        credit_type: cbill,
        credit_person: credit_person,
      };

      if (this.subRelId != null && this.subRelId != undefined && this.subRelId != "" && this.subRelId.length != 0)
        send_datavalue.sub_rel_id = this.subRelId;

      localStorage.setItem("sent_bill", this.finalAmount);
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/ubill/', JSON.stringify(send_datavalue),      
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log(obj);
            if (obj["result"] != null) {
              if (obj["result"] == "Bill updated successful") {
                if (this.temp_key === 'print') {
                  this.cdr.detectChanges();
                  this.print_area1();
                }
                this.updateFlag = false;
                this.toastr.success(Message_data.billUpdSuccess);              
                if (Helper_Class.getIsAdmin() != undefined)
                  this.adminservice.sendMessage("bill_list");
                else if (Helper_Class.getLoginType() == 'doctor')
                  this.frontservice.sendMessage('billing');   
                else
                  this.frontservice.sendMessage('frontdeskbilling');

              } else {
                this.updateFlag = true;
                this.toastr.error("Unable to update Bill,please try again later");
              }
            }
          },
          error => {
            this.updateFlag = true;
           this.toastr.error("Unable to update Bill,please try again later");
          });
    } else {
      this.updateFlag = true;
    }
  }

  print_area1() {
    var margin_top;
    let printContents, popupWin;
    if (this.printtemplate != undefined && this.printtemplate == 'banner') {
      printContents = this.printbanner.nativeElement.innerHTML;
      margin_top = '0px';
    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
      margin_top = '122px';
    }
    popupWin = window.open(
      '',
      '_blank',
      'top=20,left=10,height=100%,width=auto'
    );
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>                        
          @page {
            size: auto;
            margin-top: ${margin_top};
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 0px;
            overflow: visible;
          } 
          * {
              font-size: 12px !important;
              line-height: 1 !important;
              page-break-inside: always;
              overflow: visible;
            }                                     
          @media print {   
            body {
              height: auto; /* Allow body height to adjust dynamically */
            }          
            .page-break {
              page-break-before: always; /* or page-break-after: always; */
              page-break-inside: always;
            }
          }           
          .address_visibility {
            visibility: hidden;
          }        
          table.report-container {
            page-break-after: always;
          }
          thead.report-header {
            display: table-header-group;
          }
          tfoot.report-footer {
            display: table-footer-group;
          }
          .print:last-child {
            page-break-after: auto;
          }
          .alignRight {
            text-align: right;
          }
          .col-print-1 {width:8%;  float:left;}
          .col-print-2 {width:16%; float:left;}
          .col-print-3 {width:25%; float:left;}
          .col-print-4 {width:33%; float:left;}
          .col-print-5 {width:42%; float:left;}
          .col-print-6 {width:50%; float:left;}
          .col-print-7 {width:58%; float:left;}
          .col-print-8 {width:66%; float:left;}
          .col-print-9 {width:75%; float:left;}
          .col-print-10 {width:83%; float:left;}
          .col-print-11 {width:92%; float:left;}
          .col-print-12 {width:100%; float:left;}
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
    popupWin.document.close();
  }

  getServiceType(bill_type) {
    if (this.chargeName == undefined) {
      this.Get_Bill_Type();
    }
    this.serviceType = []; 
    if (bill_type.length >= 2 && bill_type !== undefined) {
      if (this.chargeName.length !== 0) {
        const filteredItems = this.chargeName.filter(o =>
          o.description.toLowerCase().includes(bill_type.toLowerCase())
        );
        
        if (filteredItems.length > 0) {
          filteredItems.forEach(o => {
            this.serviceType.push({
              description: o.description,
              amount: o.amount,
              charge_id: o.charge_id,
              category_desc: o.category_desc,
              charge_cat_id: o.charge_cat_id,
              order_no: o.order_no
            });
          });
        } else {
          this.serviceType = [];
        }
      }
    }
  }

  handleKeyUp(event: KeyboardEvent, biill_type, person) {
    const key = event.key;
    if (key !== 'ArrowUp' && key !== 'ArrowDown') {
      var biill_type;
      this.getServiceType(biill_type);
    }
    if (key === 'Enter') {
      let charge;
      for (let i = 0; i < this.serviceType.length; i++) {
        if (this.serviceType[i].description === biill_type) {
          const { description, amount, charge_id, category_desc, charge_cat_id, order_no } = this.serviceType[i];
          charge = {
            description,
            amount,
            charge_id,
            category_desc,
            charge_cat_id,
            order_no
          };
          break;
        }

      }
      if (biill_type) {
        this.changeChargeType1(charge, person);
      }
    }
  }

  showCreditOption() {
    this.credit_flag = !this.credit_flag;
  }

  changeChargeType1(charge,person) {  
    var result = this.finekeyInArray_billaddChargeType(this.billAddList,charge.description);      
    if (result == true) { 
      this.toastr.error("Charge type already exists");
      person.biill_type = '';
      this.serviceType = [];

    } else {
      person.biill_type = charge.description;
      person.edit_flag = true;
      person.charge_type =charge.charge_id; 
      person.amount_txt =  parseFloat(charge.amount);
      person.quantity =  this.quantity;     
      person.concession = this.concession;
      person.fee = parseFloat(this.getamountAftCon1(charge.amount));
      person.status = "1";
      person.total = (parseFloat(this.quantity) * parseFloat(charge.amount));
      person.category = charge.category_desc;
      person.charge_cat_id = charge.charge_cat_id;
      person.order_no = charge.order_no;
      if(this.categoryChargeList != undefined) {
        this.billAddList = this.combineCharges(this.categoryChargeList);
      }
      
      this.createGroupping();
      console.log(this.billAddList);
      this.Calculate_gst("0","0");
      this.checkBox = true;
      this.serviceType = [];  
    }
  }

  finekeyInArray_billaddChargeType(array, biill_type) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].biill_type == biill_type) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  getamountAftCon1(amount) {
    var RateCard = this.RateCardArray;
    for (var i = 0; i < RateCard.length; i++) {
      if (this.rate_card == RateCard[i].rate_card_id) {
        if(RateCard[i].discount == "0" && RateCard[i].rate_card_desc != 'Service wise') {
          return this.amountAftCon = amount;
        } 
        else {
          return (this.amountAftCon =
            parseFloat(amount) * this.quantity -
            (this.concession / 100) * (parseFloat(amount) * this.quantity));
        }        
      }
    }
  }        

  addRound(data) {
    this.discount = this.discount == "" ? 0 : this.discount;
    if (data == "puls")
      this.remainingBalance = ((parseFloat(this.finalAmount) + parseFloat(this.discount)) - (parseFloat(this.paidBill) + parseFloat(this.advanceBill)));
    else
      this.remainingBalance = ((parseFloat(this.finalAmount) - parseFloat(this.discount)) - (parseFloat(this.paidBill) + parseFloat(this.advanceBill)));
  }

  paid_change() {
    if (this.paymentsarray.length == 0) {
      this.payType1 = this.payTypeList[0].pay_id;
    } else {
      this.paymentsarray_total = 0;
      for (var i = 0;i < this.paymentsarray.length; i++) {
        this.paymentsarray_total = (parseFloat(this.paymentsarray_total) + parseFloat(this.paymentsarray[i].amount));
      }  
      console.log("paymentsarray_total ",this.paymentsarray_total,"finalAmount",this.finalAmount);
      if (parseInt(this.paymentsarray_total) > parseInt(this.finalAmount))  {
        this.paymentsarray = [];
        this.payType1 = this.payTypeList[0].pay_id;
      } 
    }
    this.paidBill = this.paidBill == "" ? 0 : this.paidBill;
    this.remainingBalance = ((parseFloat(this.finalAmount) - parseFloat(this.discount)) - (parseFloat(this.paidBill) + parseFloat(this.advanceBill)));
    if(parseFloat(this.paidBill) > this.finalAmount){
      this.amountval = parseFloat(this.finalAmount);
      this.numToWords = convertNumberToWords(
        this.finalAmount,
        ipaddress.country_code
      );
    } else {
      this.amountval = parseFloat(this.paidBill);
      this.numToWords = convertNumberToWords(
        this.paidBill,
        ipaddress.country_code
      );
    }

    if (this.paidBill != undefined && this.paidBill != "") {    
      if (parseFloat(this.paidBill) > parseFloat(this.finalAmount)) {
        if (this.paymentsarray.length == 0) {
          this.amountval = parseFloat(this.finalAmount);
        } else {
          var payArr_Amt = 0;
          for (var i = 0; i < this.paymentsarray.length; i++) {
            payArr_Amt += parseFloat(this.paymentsarray[i].amount);
          }

          if (payArr_Amt > parseFloat(this.finalAmount)) {
            this.amountval = parseFloat(this.finalAmount);
          } else {
            this.amountval = (parseFloat(this.finalAmount) - payArr_Amt);
          }
        }
      } else {
        if (this.paymentsarray.length == 0) {
          this.amountval = parseFloat(this.paidBill);
        } else {
          var payArr_Amt = 0;
          for (var i = 0; i < this.paymentsarray.length; i++) {
            payArr_Amt += parseFloat(this.paymentsarray[i].amount);
          }

          if (payArr_Amt > parseFloat(this.paidBill)) {
            this.amountval = parseFloat(this.paidBill);
          } else {
            this.amountval = (parseFloat(this.paidBill) - payArr_Amt);
          }
        }
      }
    } else {
      this.paidBill = 0;
      this.remainingBalance = this.finalAmount;
    }
    
  }

  addPaymentDetails(){
    var bamt:any=0,balance=0;
    var results = this.finekeyInArray_Payment(this.paymentsarray,this.payType1);
    if (this.payType1 != undefined && this.payType1 != "") {
      for(var i=0;i<this.payTypeList.length;i++){
        if(this.payType1 == this.payTypeList[i].pay_id){
          if(this.paymentsarray.length != 0){
            for(var j=0;j<this.paymentsarray.length;j++){
              bamt+=parseInt(this.paymentsarray[j].amount);
            }
            bamt+=parseFloat(this.amountval);
          } else {
            bamt+=parseFloat(this.amountval);
          }
         
          if (bamt> (this.paidBill)) {
            this.toastr.error("Amount cannot be more than bill amount");
          } else if(this.amountval == 0 || this.amountval < 0) {
            this.toastr.error("Amount cannot be zero or minus");
          } else if(results == true) {
            this.toastr.error("Payment mode already exists");
          } else {
            if(this.amountval < parseFloat(this.paidBill) || this.amountval == parseFloat(this.paidBill)){
              if(parseFloat(this.paidBill)>parseFloat(this.finalAmount)){
                if(bamt >parseFloat(this.finalAmount)){
                  this.toastr.error("Amount cannot be more than bill amount");
                } else {
                  this.paymentsarray.push({
                    pay_id:this.payTypeList[i].pay_id,
                    pay_desc:this.payTypeList[i].pay_desc,
                    amount: parseFloat(this.amountval)
                  })
                  this.amountval= parseFloat(this.finalAmount)-bamt;
                  this.amountval = parseFloat(this.amountval);
                  this.payType1 = "";
                }
                
              } else {
                if(bamt >parseFloat(this.paidBill)){
                  this.toastr.error("Amount cannot be more than bill amount");
                } else {
                  this.paymentsarray.push({
                    pay_id:this.payTypeList[i].pay_id,
                    pay_desc:this.payTypeList[i].pay_desc,
                    amount: parseFloat(this.amountval)
                  })
                  this.amountval= parseFloat(this.paidBill)-bamt;
                  this.amountval = parseFloat(this.amountval);
                  this.payType1 = "";
                }
              }
              
            } else {
              this.amountval=0;
            }        
          }
        }
      }
    } else {
      this.toastr.error('Please select the payment mode');
    }  
  }

  finekeyInArray_Payment(array, id) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].pay_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  editPayment(data){
    this.payType1=data.pay_id;
    this.amountval=parseInt(data.amount);   
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
        this.paymentsarray.splice(i,1);
      }
    }
  }

  deletePayment(data){
    for(var i=0;i<this.paymentsarray.length;i++){
      if(data.pay_id ==this.paymentsarray[i].pay_id){
       this.paymentsarray.splice(i,1);
      }
    }
  }
}
