import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FormControl } from '@angular/forms';
import { Doc_Helper } from '../Doc_Helper';
import { Date_Formate, ConvertTimeformat, Time_Formate, convertNumberToWords } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Master_Helper } from '../Master_Helper';
import { MasterHelperService } from '../MasterHelper.service';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import moment from 'moment';
import { FrontDesk_Helper } from 'src/app/FrontDesk_module/FrontDesk_Helper';
declare var $: any;

@Component({
  selector: 'app-inpatient-bill',
  templateUrl: './inpatient-bill.component.html',
  styleUrls: ['./inpatient-bill.component.scss']
})
export class InpatientBillComponent implements OnInit {
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('focusMRNo') focusMRNo: ElementRef;
  @ViewChild('focusMobileNo') focusMobileNo: ElementRef;
  @ViewChild('focusFName') focusFName: ElementRef;
  public save_billcreate: any;
  public quantityArray = [];
  tomorrow = new Date();
  public doc_visitscreate = [];
  public warddetails_billcreate = [];
  public bill_palnche;
  public appoint_txt;
  public charge_type: string;
  public charge_id: string;
  public amount_txt;
  public final_amount: any = 0;
  public totalBillAmount: any = 0;
  public inGST: boolean;
  public msgflag: boolean = false;
  public profile_image: string;
  public first_name;
  public last_name;
  public hospital_logo;
  public messages;
  public notifications;
  public notifyflag: boolean = false;
  public concession_txt;
  public con_txt;
  public session: string;
  public amount_aftcon: any;
  public table_div: boolean;
  public final_div: boolean;
  public gst_amount_txt;
  public Advance_bill;
  public CurrentDatetime;
  public Appoint_Date;
  public appointment_list: any = [];
  public Docidname;
  public Bill_appoint_id;
  public hospital_name;
  public middle_appoin: string;
  public billden_plan: any = [];
  public gst_retrive_list: any = [];
  public billaddlist: any = [];
  public gst_data_list: any = [];
  public chk_treat_arr: any = [];
  public charge_name;
  public estimate_id: any = [];
  public paytypelist: any = [];
  public insurerlist: any = [];
  public pay_type: string;
  public insurer_id: string;
  public insurFlag: boolean;
  public remaing_billbal: any = 0;
  public addvancetxt;
  public totalcolletxt;
  public cent_gst;
  public sta_gst;
  public client_reg_id;
  public relation_id;
  public sub_rel_id;
  public Charge_id;
  public charge_desc;
  public hptl_clinic_id: string;
  public header_footer_flag: boolean;
  public addbtn_flag: boolean;
  public bill_date;
  public current_amount: any = 0;
  public billdate;
  public diet_followtable: boolean;
  public Gsthidden: boolean = false;
  public submenu_flag: boolean = false;
  public submenu_flag1: boolean = false;
  public doctor_profile_img!: string | null;
  public cenra_gst;
  public stat_gst;
  public prev_bal: string;
  public total_bill: string;
  public total_bill1: string;
  public currentDate;
  public userinfo;
  public tempRoundoff = 0;
  //mdbTable
  dtOptions: DataTables.Settings = {};
  public persons: any = [];
  public masterSelected: boolean = false;
  public checklist: any;
  public checkedList: any;
  public user_id;
  //Get apointment details
  public patient_name: string;
  public prev_bill_id;
  public balance_show: boolean;
  public amount_collected: boolean;
  public previous_balshow: boolean;
  public balance_data;
  public total_collected;
  public round_off;
  public paid_bill;
  public sign;
  public billing_print_array;
  public medcalDetailsArray: any = [];
  public digDetailsArray: any = [];
  public hospitalAddress;
  public Hospital_location;
  public diagnosisAmount: any = 0;
  public medicine_amount = 0;
  public medicinBal: any = 0;
  public dog_reg_id;
  public serviceType: any = [];
  public inpatientEdit: boolean = false;
  public quantity: any = 1;
  public hospitalBills: any = 0;
  public saveBillURL;
  public bill_id;
  public pre_paid_bill = 0;
  public pre_bill_amount: any = 0;
  public pharmaPaid: any = 0;
  public diagPaid: any = 0;
  public show_save_btn: boolean = true;
  public bill_cons_flag: boolean = true;
  public bill_qty_flag: boolean = true;
  private rowid: number = 1;
  public bill_time1;
  public bill_time2;
  public bill_time3;
  public bill_hrs: any = [];
  public bill_min: any = [];
  public currentTime;
  public department_id;
  public ward_id;
  public chargeNameArray: any = [];
  public transId;
  public RateCardArray = [];
  public rate_card;
  public checkBox: any;
  public paytypreq: boolean = false;
  public credit_flag: boolean = true;
  public hosp_credits: any = [];
  public credit_type: string;
  public credit_bill;
  public payType1;
  public amountval: any;
  public paymentsarray: any = [];
  public mrno;
  public clnt_saldesc;
  public clnt_age;
  public gender;
  public mobile_no;
  public e_contact;
  public admitted_date;
  public admitted_time;
  public discharge_date;
  public discharge_time;
  public createpage_flag: boolean = false;
  public Moduleidlist = [];
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public bulkAddFlag: boolean = false;
  public credit_person;
  public bill_head;
  public billId;
  public numToWords: any;
  public time;
  public temp_key: any;
  public print_size: any = null;
  public showModal: boolean = false;
  public cashDenomination: { value: number; count: number }[] = [
    { value: 2000, count: 0 },
    { value: 500, count: 0 },
    { value: 200, count: 0 },
    { value: 100, count: 0 },
    { value: 50, count: 0 },
    { value: 20, count: 0 },
    { value: 10, count: 0 },
    { value: 5, count: 0 },
    { value: 2, count: 0 },
    { value: 1, count: 0 },
  ];
  public totalAmount: number;
  public doctorVisitsChargeArray = [];
  public hospitalLogo: any;
  public printlogostyle: any;
  public print_template: any;
  public doctor_name: any;
  public dept_desc: any;
  public wardName: any;
  public bed_no: any;
  public profileImg: string;
  public discount_amount: any = 0;
  public tot_pay_array_amount: any;
  public filter: string;
  public toFlag: boolean;
  public fromFlag: boolean;
  public date;
  public firstNameFlag: boolean;
  public firstName: string;
  public uhidFlag: boolean;
  public patientId;
  public mobileFlag: boolean;
  public mobileNo: string;
  public lastName: string;
  public mr_no_flag: boolean;
  public total: any;
  public other_charges_array = [];
  public hosp_charges = [];
  public diag_charges = [];
  public diagnosis_bills = [];
  public pharma_charges = [];
  public pharma_bills = [];
  public sendAdvanceAmount: any;
  public dateval: moment.Moment;
  public enddate: any;
  public endtime: any;
  public HalfDayHour: number;
  public DayHour: number;
  public HourlyHour: number;
  public cardFlag: boolean;
  public cardNumber;
  public cardreqflag: boolean = false;
  public cardHolderName;
  public transactionFlag: boolean;
  public transactionId;
  public transreqflag: boolean = false;
  public chequeFlag: boolean;
  public chequereqflag: boolean = false;
  public bankName;
  public insreqflag: boolean = false;
  public discount_flag: boolean = false;
  public open_app_billing;
  public wardbasecharge: string;
  public surgery: any;
  public bill_type_pharma: any;
  public barcode_url;
  public barcode_flag: boolean = false;
  public logUserName;
  public barcode_style;
  public nobanner_style;
  public inpatient_flag: boolean = true;
  public multiply_flag: boolean = true;
  public amount_td_style;
  public paymentsarray_total;
  public p_tag_style: any;
  public categoryChargeList: any = [];
  public ward_details_flag: boolean = false;
  public paid_amount_flag: boolean = false;
  public rate_card_disable: boolean = false;
  public billAddList: any[];
  public hospital_finalAmount: any;
  public hospital_totalBillAmount: any;
  public hospital_discount_amount: any;
  public hospital_gstAmount: any;
  public hospital_totalCollected: any;
  public hospital_gstDataList: any[] = [];
  public hospital_remainingBalance;
  public hospital_paidBill: any = 0;
  public hospital_paymentsarray: any = [];
  public hospital_billId = '';
  public hospital_paid_percentage = [];
  public hospital_pay_arrry_tot_amt;
  public hospitalEqualSplitValue;
  public sponsorDueflag: boolean = false;
  public sponsorDue;
  public adv_refflag: boolean = false;
  public refund_reason: any;
  public totalPaidAmountvalue = "";
  public totalBalanceAmountvalue = "";
  public totalBillAmountvalue = "";
  public totalDiscountvalue = "";
  public total_advancevalue = '';
  public discount_rembalance = 0;
  public selectedAllFlage: boolean = false;
  public is_insurance: boolean;
  public totalPaidAmount: string;
  public TotalBalance: string;
  public medBills: any;
  public diagBills: any;
  public doctorBills: any;
  public docBills: any;
  public sendDoctor: any[];
  public totalDiscount: string;
  public totalBill: string;
  public totalPaid: string;
  public sendPharmacy: any[];
  public mediBillAmount: string;
  public sendDiagnosis: any;
  public diag_centre_id: any;
  public fin_year: any;
  public pharma_id;
  public combinedArray: any[];
  public refund_amount: any = 0;
  public temppaid_bill = "0.00";
  public totalAdvance_bills = 0;
  public remainingbalance_advance: any;
  public remainingbalance_sponsor_due: any;
  public sponsor_due
  public approved_amount = "0.00";
  public paidflag: boolean = false;
  public discountflag: boolean = false;
  public balanceflag: boolean = false;
  public advanceflag: boolean = false;
  public paid_bill1;
  public totalBillAmount1 = "0.00";
  public listview: boolean = true;
  public doctor;
  public Insuranceflag: boolean = false;
  public insuranceFlag: boolean = false;
  public patientList = [];
  public floorvalue;
  public floorarray = [];
  public count;
  public payment_mode;
  public doctorList = [];
  public doctorName;
  public graphicalformat: boolean = false;
  public graph_defaultimg: boolean = false;
  public tabular_format: boolean = true;
  public view_type;
  public name;
  public location;
  public mobile: string;
  public mrnoflag: boolean;
  public locationflag: boolean;
  public nameflag: boolean;
  public fdateflag: boolean;
  public todateflag: boolean;
  public mobileflag: boolean;
  public fromDate;
  public toDate;
  public filterbasedurl;
  public selectvalue;
  public currentMaxDate;
  public remaining_billbal;
  public total_advance = "0.00";
  public discountvaluecheck = 0.00;
  public client_id;
  public sub_id;
  public rel_id;
  public tempparray;
  public app_id;
  public inpatientId
  public admitted_timevalue;
  public inpid;
  public insurer;
  public insCardNo;
  public policyno;
  public CCNno;
  public clientnamenew; public mobilenumber; public doctorname; inpatientidnew;
  public age;
  public bedno;
  public agegendervalue;
  public ward_name;
  public surgery_name: string;
  public doc_qualif: any;
  public specialization_name: any;
  public dischargeTime = "";
  public rate_plan: any;
  public Filter_txt;
  public f_date;
  public t_date;
  public admissionDate = "";
  public admissionTime = "";
  public dischargeDate = "";
  public DisplayBills: any = [];
  public insuranceDec;
  public patient_selectionflag: boolean = true;
  public docBillDetails: any = [];
  public header_style: string;
  public bill_no: string;
  public bill_type: string;
  public From_show: boolean = true;
  public To_show: boolean = true;
  public dateshow: boolean = false;
  public Filter_based;
  public mr_no;
  public rate_card_disc: any;
  public advanceList = [];
  public isDisInitiate: boolean = false;
  public package = [];
  public return_paymentsarray: any[];
  public return_pay_arrry_tot_amt: any;
  public returnEqualSplitValue: any;
  public local_payment_array = [];
  public hosp_id;
  public settled;
  public finalized;
  public status: string = "";
  public pending;
  public selectedValue;
  public med_with_det: boolean = false;
  public bank_details_flag_adv: boolean = false;
  public acc_number_adv: any;
  public holder_Name_adv: any;
  public bank_Name_adv: any;
  public bank_ifc_code_adv: any;
  public refund_array = [];  
  public inp_hosp_id: any;
  public insurance_flag: boolean = false;
  public pharma_bill_flag: boolean = false;
  public pay_mode;
  public sponser_name;
  public sponser_GSTIN;

  constructor(
    public doctorservice: MenuViewService,
    public toastr: ToastrService,
    public http: Http,
    public router: Router,
    public messageservice: MenuViewService,
    public masterData: MasterHelperService,
    private cdr: ChangeDetectorRef
  ) {
    this.pending = true;
    this.addbtn_flag = true;
    this.insurFlag = true;
    this.tomorrow.setDate(this.tomorrow.getDate() + 0);
    this.billdate = new FormControl(new Date());
    this.userinfo = Helper_Class.getInfo();
    this.user_id = this.userinfo.user_id;
    this.bill_time1 = '';
    this.bill_time2 = '00';
    this.amount_txt = ' ';
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.bill_hrs.push('0' + i);
      } else {
        this.bill_hrs.push(i);
      }
    }

    for (var i = 0; i <= 59; i += 1) {
      if (i < 10) {
        this.bill_min.push('0' + i);
      } else {
        this.bill_min.push(i);
      }
    }
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.firstNameFlag = true;
    this.fromFlag = true;
    this.toFlag = true;
    this.uhidFlag = false;
    this.mobileFlag = true;
    this.mr_no_flag = false;
    this.discount_flag = false;
    this.med_with_det = false;
  }

  ngOnInit(): void {
    this.refund_amount = 0;
    this.credit_type = '';
    this.paid_amount_flag = false;
    this.profileImg = '../../assets/img/default.jpg';
    this.ward_details_flag = false;
    this.show_save_btn = true;
    $(document).ready(function () {
      $('input').attr('autocomplete', 'off');
    });
    var hospitaldet = Helper_Class.getHospital();
    this.hptl_clinic_id = hospitaldet[0].hptl_clinic_id;
    this.userinfo = Helper_Class.getInfo();
    console.log(this.userinfo);

    if (this.userinfo.fin_year != undefined) {
      this.fin_year = this.userinfo.fin_year;
    }

    if (this.userinfo.pharma_id != undefined) {
      this.pharma_id = this.userinfo.pharma_id;
    }

    if (this.userinfo.diag_centre_id != undefined) {
      this.diag_centre_id = this.userinfo.diag_centre_id;
    }

    this.wardbasecharge = this.userinfo.ward_base_charge;
    this.print_size = this.userinfo.bill_print_paper;
    if (this.userinfo.bill_pay_type_req == '1') {
      this.paytypreq = false;
    } else {
      this.paytypreq = true;
    }
    this.hospital_logo =
      ipaddress.Ip_with_img_address + Helper_Class.getInfo().hospitals[0].logo;
    if (this.userinfo.bulk_add_req === '1') {
      this.bulkAddFlag = true;
    }
    this.dtOptions = {
      pageLength: 25,
      lengthMenu: [
        [25, 50, -1],
        [25, 50, 'All'],
      ],
      columnDefs: [{ orderable: false, targets: [2, 3, 4, 5, 6] }],
      language: {
        search: "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: 'Search chargetypes by name',
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>',
    };

    this.Moduleidlist = Helper_Class.getmodulelist();
    if (this.Moduleidlist != undefined && this.Moduleidlist.length != 0) {
      for (var i = 0; i < this.Moduleidlist.length; i++) {
        if (this.Moduleidlist[i].module_id == '7') {
          if (this.Moduleidlist[i].edit == '1') {
            this.editbutton = true;
          }
          if (this.Moduleidlist[i].create == '1') {
            this.newbutton = true;
          }
          if (this.Moduleidlist[i].delete == '1') {
            this.deletebutton = true;
          }
          if (this.Moduleidlist[i].print == '1') {
            this.printbutton = true;
          }
          if (this.Moduleidlist[i].view == '1') {
            this.viewbutton = true;
          }
        }
      }
    }

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    }

    if (this.userinfo.hptl_name == 'SGH') {
      this.p_tag_style = "text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;margin-top: 10px;";
    } else {
      this.p_tag_style = "text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;";
    }

    this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    this.printlogostyle = Helper_Class.getInfo().bill_print_logo_style;
    this.doctor_profile_img = this.userinfo.profile_image;
    this.amount_change();
    this.getPaymentType();
    this.getCreditTypes();
    this.final_amount = 0;
    this.discount_amount = 0;
    this.totalBillAmount = 0;
    this.gst_amount_txt = 0;
    this.Advance_bill = 0;
    this.paid_bill = 0;
    this.round_off = 0;
    this.concession_txt = 0;
    this.quantity = 1;
    this.inGST = false;
    this.Get_current_date();
    this.gst_retrive_list = [];
    this.billaddlist = [];
    this.categoryChargeList = [];
    this.Get_Gst_Data();
    this.estimate_id = [];
    this.previous_balshow = true;
    this.balance_show = true;
    this.getInsurers();
    this.RateCardData();
    this.saveBillURL = 'bill/savebill/';
    this.pharma_bill_flag = false;
    if (Helper_Class.getInfo().bill_cons_flag == 0) {
      this.bill_cons_flag = true;

    } else {
      this.bill_cons_flag = false;
    }


    if (Helper_Class.getInfo().bill_qty_flag == 0) {
      this.bill_qty_flag = true;
    } else {
      this.bill_qty_flag = false;
    }

    if (this.userinfo.bill_print_template != undefined) {
      this.print_template = this.userinfo.bill_print_template;
    } else {
      this.print_template = 'noheader';
    }

    if (this.userinfo.open_app_billing != null) {
      this.open_app_billing = this.userinfo.open_app_billing;
    }

    if (this.userinfo.first_name != undefined) {
      this.logUserName = this.userinfo.first_name;
      if (this.userinfo.last_name != undefined) {
        this.logUserName = this.userinfo.first_name + " " + this.userinfo.last_name;
      }
    }

    this.bill_head = Helper_Class.getInfo().bill_heading;
    this.filter = 'Patient_ID';
    this.cdr.detectChanges();
    // this.focusMRNoInput();
    this.Filter_based = "mrno";
    this.dog_reg_id = "All";
    this.doctor = "All";
    this.selectvalue = "mrno";
    this.floorvalue = "All";
    this.view_type = "graph";
    this.bill_type = "doctor";
    this.Filter_txt = "All";
    this.advanceList = [];
    this.Filter_change();
    this.Filter_changename(this.Filter_based);
    this.Get_current_date();
    this.getPaymentType();
    this.getDoctors();
    this.getPatients();
    this.viewChange(this.view_type);
    this.Filterbydata(this.selectvalue);
    this.selectedValue = 'pending';
    this.billType(this.selectedValue);
    this.insurance_flag = false;
  }

  billType(status) {
    if (status == "pending") {
      this.settled = false;
      this.finalized = false;
      this.pending = true;
      this.status = "1";
    } else if (status == "finalized") {
      this.settled = false;
      this.pending = false;
      this.finalized = true;
      this.status = "2"; 
    } else if (status == "settled") {
      this.finalized = false;
      this.pending = false;
      this.settled = true;
      this.status = "0";
    } 
  }

  Filter_change() {
    if (this.Filter_txt == "All") {
      this.From_show = true;
      this.To_show = true;
    } else if (this.Filter_txt == "Date") {
      this.From_show = false;
      this.To_show = false;
      this.dateshow = true;
    }
  }

  Filter_changename(data) {
    this.appointment_list = [];
    console.log("check data" + JSON.stringify(Helper_Class.getinpatientfilterdata()))
    if (data == "mrno") {
      this.mrnoflag = true;
      this.locationflag = false;
      this.nameflag = false;
      this.fdateflag = false;
      this.todateflag = false;
      this.mobileflag = false;
      this.To_show = true;
      this.mr_no = "";
      this.patient_selectionflag = true;
    }
    if (data == "name") {
      this.mrnoflag = false;
      this.nameflag = true;
      this.locationflag = false;
      this.fdateflag = false;
      this.todateflag = false;
      this.mobileflag = false;
      this.appoint_txt = "";
      this.patient_selectionflag = true;
    }
    if (data == "mobile") {
      this.fdateflag = true;
      this.todateflag = true;
      this.mrnoflag = false;
      this.nameflag = false;
      this.locationflag = false;
      this.mobileflag = true;
      this.To_show = true;
      this.mobile = "";
      this.patient_selectionflag = true;
    }
  }

  focusMRNoInput() {
    this.patientId = '';
    document.getElementById('focusMRNo').focus();
    this.focusMRNo.nativeElement.focus();
  }

  focusMobileNoInput() {
    this.mobileNo = '';
    document.getElementById('focusMobileNo').focus();
    this.focusMobileNo.nativeElement.focus();
  }

  focusFirst_nameInput() {
    this.firstName = '';
    document.getElementById('focusFName').focus();
    this.focusFName.nativeElement.focus();
  }

  showCreditOption() {
    this.credit_flag = !this.credit_flag;
  }

  getCreditTypes() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/ghco',
        JSON.stringify({
          country: ipaddress.country_code.toString(),
          hptl_clinic_id: this.userinfo.hptl_clinic_id,
          type: 'hospital',
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();
          if (obj.hosp_credits != null) {
            for (var i = 0; i < obj.hosp_credits.length; i++) {
              this.hosp_credits.push({
                credit_id: obj.hosp_credits[i].credit_id,
                credit_desc: obj.hosp_credits[i].credit_desc,
              });
            }
          }
        },
        (error) => { }
      );
  }

  backClicked() {
    if (this.userinfo.user_type == 'Admin') {
      this.messageservice.sendMessage('frontdeskadminbilling');
    } else {
      if (Helper_Class.getInpatientBillCreate() == 'front-desk') {
        this.messageservice.sendMessage('frontdeskbilling');
      } else {
        Doc_Helper.setIpCreateBill('ipbill');
        this.doctorservice.sendMessage('billing');
      }
    }
  }

  clearData() {
    this.createpage_flag = false;
    this.profile_image = '';
    this.patientId = '';
    this.firstName = '';
    this.lastName = '';
    this.mobileNo = '';
    this.clnt_saldesc = '';
    this.patient_name = '';
    this.clnt_age = '';
    this.gender = '';
    this.billing_print_array = [];
    this.mobileNo = '';
    this.doctor_name = '';
    this.admitted_date = '';
    this.admitted_time = '';
    this.e_contact = '';
    this.appointment_list = [];
    this.createpage_flag = false;
    this.billaddlist = [];
    this.categoryChargeList = [];
    this.appoint_txt = '';
    this.totalBillAmount = 0;
    this.discount_amount = 0;
    this.final_amount = 0;
    this.remaing_billbal = 0;
    this.amountval = 0;
    this.paid_bill = 0;
    this.payType1 = this.paytypelist[0].pay_id;
    this.paymentsarray = [];
    this.gst_amount_txt = 0;
    this.Advance_bill = 0;
    this.previous_balshow = true;
    this.balance_show = true;
    this.hospital_paidBill = 0;
    this.insurance_flag = false;
    this.pharma_bill_flag = false;
  }

  changeFilter() {
    this.appointment_list = [];
    this.clearData();
    if (this.filter == 'Date') {
      this.firstNameFlag = true;
      this.fromFlag = false;
      this.toFlag = false;
      this.uhidFlag = true;
      this.mobileFlag = true;
      this.mr_no_flag = false;
      this.searchPatient();
    } else if (this.filter == 'Name') {
      this.firstNameFlag = false;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = true;
      this.mr_no_flag = true;
      this.cdr.detectChanges();
      this.focusFirst_nameInput();
    } else if (this.filter == 'Patient_ID') {
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = false;
      this.mobileFlag = true;
      this.mr_no_flag = false;
      this.cdr.detectChanges();
      this.focusMRNoInput();
    } else if (this.filter == 'Mobile_No') {
      this.firstNameFlag = true;
      this.fromFlag = true;
      this.toFlag = true;
      this.uhidFlag = true;
      this.mobileFlag = false;
      this.mr_no_flag = false;
      this.cdr.detectChanges();
      this.focusMobileNoInput();
    }
  }

  OnDateChangedto(e) {
    this.date = e;
    this.clearData();
    this.searchPatient();
  }

  fnameToUpper() {
    if (this.firstName != undefined)
      this.firstName = this.firstName.toLocaleUpperCase().trim();
  }

  lnameToUpper() {
    if (this.lastName != undefined)
      this.lastName = this.lastName.toLocaleUpperCase().trim();
  }

  getPatientListByMRNo(patientId) {
    if (patientId != undefined && patientId.length > 8) {
      this.searchPatient();
    }
  }

  getPatientListByMobile_no(mobileNo) {
    if (mobileNo != undefined && mobileNo.length > 9) {
      this.searchPatient();
    }
  }

  RateCardData() {
    this.rate_card_disable = false;
    this.RateCardArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    var requestData = {
      centre_id: this.hptl_clinic_id,
      center_type: 'hospital',
    };
    this.http
      .post(ipaddress.getIp.toString() + 'mas/grcd', requestData, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();
          console.log(obj);
          var RateCard = obj.rate_cards;
          if (RateCard != undefined && RateCard.length != 0) {
            for (var i = 0; i < RateCard.length; i++) {
              this.RateCardArray.push({
                discount: RateCard[i].discount,
                rate_card_desc: RateCard[i].rate_card_desc,
                rate_card_id: RateCard[i].rate_card_id,
                default: RateCard[i].default,
                inp_default: RateCard[i].inp_default
              });
            }
            this.rate_card = this.RateCardArray[0].rate_card_id;
            for (var i = 0; i < this.RateCardArray.length; i++) {
              if (this.RateCardArray[i].inp_default != undefined && this.RateCardArray[i].inp_default == '1') {
                this.rate_card = this.RateCardArray[i].rate_card_id;
                this.rate_card_disable = true;
                break;
              }
            }
            console.log(this.RateCardArray);
            this.getConcession();
            this.charge_name = Helper_Class.getBillCharges();
            if (this.charge_name != undefined && this.charge_name.charges != 0) {
              this.charge_name = this.charge_name.charges;
            }
            console.log(this.charge_name);
          }
        },
        (error) => {
          this.toastr.error('An error occurred');
        }
      );
  }

  //Bill add table
  Bill_add_click() {
    this.diet_followtable = true;
    this.addbtn_flag = true;

    if (this.appoint_txt == undefined) {
      this.toastr.error(Message_data.sltAppId);
    } else if (this.charge_type == undefined) {
      this.toastr.error(Message_data.sltBillType);
    } else if (this.amount_txt == undefined || this.amount_txt == '') {
      this.toastr.error(Message_data.enterAmt);
    } else {
      if (this.final_amount == 0) {
        this.inGST == false;
      }
      this.table_div = true;
      this.final_div = true;
      var flag = true;
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (
          this.billaddlist[i].biill_type == this.charge_type &&
          this.billaddlist[i].paytype == this.pay_type
        ) {
          flag = false;
          break;
        }
      }

      if (flag == true) {
        if (this.charge_name.length != 0) {
          for (var i = 0; i < this.charge_name.length; i++) {
            if (this.charge_type == this.charge_name[i].description) {
              this.charge_id = this.charge_name[i].charge_id;
            }
          }
        }
        this.con_txt = this.concession_txt == '' ? 0 : this.concession_txt;
        var paydesc;
        if (this.paytypelist.length != 0) {
          for (var i = 0; i < this.paytypelist.length; i++) {
            if (this.pay_type == this.paytypelist[i].pay_id) {
              paydesc = this.paytypelist[i].pay_desc;
            }
          }
        }

        this.total = parseInt(this.quantity) * parseInt(this.amount_txt);
        var result = this.finekeyInArray_billaddChargeType(
          this.billaddlist,
          this.charge_id
        );
        if (result == true) {
          this.toastr.error('Charge type already exists');
        } else if (this.paytypreq == true) {
          this.billaddlist.push({
            sno: this.rowid,
            bill_type: 'hospital',
            biill_type: this.charge_type,
            amount_txt: parseInt(this.amount_txt),
            concession: this.con_txt,
            fee: parseInt(this.amount_aftcon),
            charge_type: this.charge_id,
            pay_type: paydesc,
            paytype: this.pay_type,
            insurer: this.insurer_id,
            quantity: this.quantity,
            total: this.total,
          });
        } else {
          this.billaddlist.push({
            sno: this.rowid,
            bill_type: 'hospital',
            biill_type: this.charge_type,
            amount_txt: parseInt(this.amount_txt),
            concession: this.con_txt,
            fee: parseInt(this.amount_aftcon),
            charge_type: this.charge_id,
            pay_type: paydesc,
            paytype: this.pay_type,
            insurer: this.insurer_id,
            ref_code: this.transId,
            quantity: this.quantity,
            total: this.total,
          });
        }

        this.hospitalBills = 0;
        this.rowid = 1;
        if (this.billaddlist.length != 0) {
          for (var i = 0; i < this.billaddlist.length; i++) {
            if (this.billaddlist[i].fee == '') {
              this.billaddlist[i].fee = 0;
            }
            this.hospitalBills = (
              parseFloat(this.hospitalBills) +
              parseFloat(this.billaddlist[i].fee)
            );
            this.billaddlist[i].sno = this.rowid;
            this.rowid += 1;
          }
        }
        this.charge_type = '';
        this.amount_txt = '';
        this.amount_aftcon = '';
      }

      if (this.final_amount == 0) {
        this.inGST == false;
      }

      this.Calculate_gst('0', '0');
      this.checkBox = true;
      this.checkBoxTrueOrFalse(this.charge_id, this.checkBox);
    }
  }

  finekeyInArray_billaddChargeType(array, biill_type) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].charge_type == biill_type) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  updateBillList(charge) {
    var results = this.finekeyInArray_billadd(
      this.billaddlist,
      charge.charge_type
    );
    if (charge.selected) {
      if (results == false) {
        this.charge_type = charge.biill_type;
        this.amount_txt = parseFloat(charge.amount_txt);
        this.concession_txt = charge.concession;
        this.amount_aftcon = parseFloat(charge.fee);
        this.charge_id = charge.charge_type;
        this.insurer_id = charge.insurer;
        this.transId = charge.ref_code;
        this.quantity = charge.quantity;
        setTimeout(() => {
          this.Bill_add_click();
        }, 500);
      }
    } else {
      this.Delete_bill(charge);
    }
  }

  Delete_bill(charge) {
    this.checkBox = false;
    this.checkBoxTrueOrFalse(charge.charge_type, this.checkBox);
    if (charge.charge_type.length != 0) {
      if (this.billaddlist.length != 0) {
        for (var i = 0; i < this.billaddlist.length; i++) {
          if (
            this.billaddlist[i].charge_type == charge.charge_type &&
            this.billaddlist[i].amount_txt == charge.amount_txt
          ) {
            this.billaddlist.splice(i, 1);
            break;
          }
        }
        for (var i = 0; i < this.hosp_charges.length; i++) {
          if (
            this.hosp_charges[i].charge_id == charge.charge_type &&
            this.hosp_charges[i].amount == charge.amount_txt
          ) {
            this.hosp_charges.splice(i, 1);
            break;
          }
        }
        for (var i = 0; i < this.categoryChargeList.length; i++) {
          for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
            if (
              this.categoryChargeList[i].charges[j].charge_type == charge.charge_type &&
              this.categoryChargeList[i].charges[j].amount_txt == charge.amount_txt
            ) {
              this.categoryChargeList[i].charges.splice(j, 1);
              break;
            }
          }
        }
      }
      this.rowid = 0;
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (this.billaddlist[i].status != '2') {
          this.rowid += 1;
          this.billaddlist[i].sno = this.rowid;
        }
      }

      this.rowid = 1;
      for (var i = 0; i < this.categoryChargeList.length; i++) {
        for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
          this.categoryChargeList[i].charges[j].sno = this.rowid;
          this.rowid += 1;
        }
      }
      // this.createGroupping();
      this.billaddlist = this.combineCharges(this.categoryChargeList);
      this.hospitalBills = 0;
      if (this.billaddlist.length != 0) {
        for (var i = 0; i < this.billaddlist.length; i++) {
          if (this.billaddlist[i].fee == '') {
            this.billaddlist[i].fee = 0;
          }
          this.hospitalBills = (
            parseFloat(this.hospitalBills) + parseFloat(this.billaddlist[i].fee)
          );
        }
      }
      if (this.billaddlist.length == 0) {
        this.table_div = false;
        this.final_div = false;
      } else {
        this.table_div = true;
      }

      if (this.final_amount == 0) {
        this.inGST == false;
      }
      
      this.Calculate_gst('0', '0');
      this.calculateHospitalGst('0', '0');
      // this.discount_amount = "0";
      this.discountvalue("1");
      this.subtotalCalculation();
    } else {
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (charge.sno == this.billaddlist[i].sno) {
          if (charge.biill_type == '') {
            this.billaddlist.splice(i, 1);
            this.calculateHospitalGst('0', '0');
            this.Calculate_gst('0', '0');
            // this.discount_amount = "0";
            this.discountvalue('0');
            this.subtotalCalculation();
            break;
          }
        }
      }
      for (var i = 0; i < this.categoryChargeList.length; i++) {
        for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
          if (
            this.categoryChargeList[i].charges[j].sno == charge.sno &&
            charge.biill_type == ''
          ) {
            this.categoryChargeList[i].charges.splice(j, 1);
            // this.createGroupping();
            this.calculateHospitalGst('0', '0');
            this.Calculate_gst('0', '0');
            // this.discount_amount = "0";
            this.discountvalue('0');
            this.subtotalCalculation();
            break;
          }
        }
      }

      this.rowid = 1;
      for (var i = 0; i < this.billaddlist.length; i++) {
        this.billaddlist[i].sno = this.rowid;
        this.rowid += 1;
      }

      this.rowid = 1;
      for (var i = 0; i < this.categoryChargeList.length; i++) {
        for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
          this.categoryChargeList[i].charges[j].sno = this.rowid;
          this.rowid += 1;
        }
      }
    }
    console.log(this.categoryChargeList);
  }

  finekeyInArray_billadd(array, id) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].charge_type === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  getBillingCharges() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/chgtype',
        JSON.stringify({
          hptl_clinic_id: this.hptl_clinic_id,
          provider: 'doctor',
          type: 'in-patient',
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();
          this.charge_name = obj.charges;
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );
  }

  Get_Bill_Type() {
    this.categoryChargeList = [];
    console.log("Function Called..");
    var paydesc;
    if (this.paytypelist.length != 0) {
      for (var i = 0; i < this.paytypelist.length; i++) {
        if (this.pay_type == this.paytypelist[i].pay_id) {
          paydesc = this.paytypelist[i].pay_desc;
        }
      }
    }
    console.log(this.hosp_charges);
    var hospital_charges = [];
    if (this.hosp_charges.length != 0) {
      for (var i = 0; i < this.hosp_charges.length; i++) {
        var total = (parseFloat(this.hosp_charges[i].quantity) * parseFloat(this.hosp_charges[i].amount));
        var concession = this.hosp_charges[i].concession != undefined ? this.hosp_charges[i].concession : parseFloat(this.rate_card_disc);
        var created_date_time = this.hosp_charges[i].created_date != undefined ? Date_Formate(this.hosp_charges[i].created_date) + " " + Time_Formate(this.hosp_charges[i].created_time) : '';
        var final_amount = this.hosp_charges[i].final_amount != undefined && this.hosp_charges[i].final_amount != null ? parseFloat(this.hosp_charges[i].final_amount) : parseFloat('0.00');
        var cqty = this.hosp_charges[i].quantity.split(".");
        var hqty = 0;
        if (cqty[1] == "0") {
          hqty = cqty[0];
        } else {
          hqty = this.hosp_charges[i].quantity;
        }
        if (this.hosp_charges[i].charge_desc != undefined && this.hosp_charges[i].charge_desc != "") {
          hospital_charges.push({
            sno: i + 1,
            bill_type: this.hosp_charges[i].type,
            created_date_time: created_date_time,
            created_date: this.hosp_charges[i].created_date,
            created_time: this.hosp_charges[i].created_time,
            biill_type: this.hosp_charges[i].charge_desc,
            amount_txt: parseFloat(this.hosp_charges[i].amount),
            concession: concession,
            fee: final_amount,
            charge_type: this.hosp_charges[i].charge_id,
            paytype: this.pay_type,
            pay_type: paydesc,
            insurer: this.insurer_id,
            quantity: hqty,
            total: total,
            edit_flag: true,
            category: this.hosp_charges[i].category_desc,
            charge_cat_id: this.hosp_charges[i].charge_category_id,
            order_no: this.hosp_charges[i].order_no
          })
        }
      }
      this.createGroupping(hospital_charges);
    }

    var diag_charges = [];
    if (this.diag_charges.length != 0) {
      for (var i = 0; i < this.diag_charges.length; i++) {
        if (this.diag_charges[i].amount != "0.00") {
          var total = (parseFloat(this.diag_charges[i].quantity) * parseFloat(this.diag_charges[i].amount));
          var concession = this.diag_charges[i].concession != undefined ? this.diag_charges[i].concession : parseFloat("0.00");
          var created_date_time = this.diag_charges[i].created_date != undefined ? Date_Formate(this.diag_charges[i].created_date) + " " + Time_Formate(this.diag_charges[i].created_time) : '';
        
          diag_charges.push({
            sno: i + 1,
            billing_id: this.diag_charges[i].billing_id,
            bill_type: this.diag_charges[i].type,
            sub_type: this.diag_charges[i].sub_type,
            created_date_time: created_date_time,
            created_date: this.diag_charges[i].created_date,
            created_time: this.diag_charges[i].created_time,
            biill_type: this.diag_charges[i].charge_desc,
            amount_txt: parseFloat(this.diag_charges[i].amount),
            concession: concession,
            fee: parseFloat(this.diag_charges[i].final_amount),
            charge_type: this.diag_charges[i].charge_id,
            paytype: this.pay_type,
            pay_type: paydesc,
            insurer: this.insurer_id,
            quantity: parseInt(this.diag_charges[i].quantity),
            total: total,
            edit_flag: true,
          })
        }
      }

      if (diag_charges.length != 0) {
        var diag_bill_array = [];
        var sub_total = 0;
        diag_charges.forEach(diag => {
          diag_bill_array.push(diag)
          sub_total = sub_total + diag.total;
        })

        this.categoryChargeList.push({
          bill_type: "Diagnosis",
          charges: diag_bill_array,
          sub_total: sub_total
        });
      }

      if (this.categoryChargeList.length != 0) {
        this.rowid = 1;
        for (var i = 0; i < this.categoryChargeList.length; i++) {
          for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
            this.categoryChargeList[i].charges[j].sno = this.rowid;
            this.rowid += 1;
          }
        }
      }
    }

    var pharma_charges = [];
    if (this.pharma_charges.length != 0 && this.pharma_bills.length != 0) {
      if (this.med_with_det == true) {
        for (var i = 0; i < this.pharma_charges.length; i++) {
          var total = (parseFloat(this.pharma_charges[i].quantity) * parseFloat(this.pharma_charges[i].amount));
          var concession = this.pharma_charges[i].concession != undefined ? this.pharma_charges[i].concession : parseFloat("0.00");
          var created_date_time = this.pharma_charges[i].created_date != undefined ? Date_Formate(this.pharma_charges[i].created_date) + " " + Time_Formate(this.pharma_charges[i].created_time) : '';
          pharma_charges.push({
            sno: i + 1,
            billing_id: this.pharma_charges[i].billing_id,
            bill_type: this.pharma_charges[i].type,
            created_date_time: created_date_time,
            created_date: this.pharma_charges[i].created_date,
            created_time: this.pharma_charges[i].created_time,
            biill_type: this.pharma_charges[i].charge_desc,
            amount_txt: Math.round(this.pharma_charges[i].amount),
            concession: concession,
            fee: Math.round(this.pharma_charges[i].final_amount),
            charge_type: this.pharma_charges[i].charge_id,
            paytype: this.pay_type,
            pay_type: paydesc,
            insurer: this.insurer_id,
            quantity: parseInt(this.pharma_charges[i].quantity),
            total: Math.round(this.pharma_charges[i].final_amount),
            edit_flag: true,
          })
        }
      } else {
        for (var i = 0; i < this.pharma_bills.length; i++) {
          var total = Math.round(this.pharma_bills[i].bill_amount);
          var concession:any = this.pharma_bills[i].bill_disc != undefined ? parseFloat(this.pharma_bills[i].bill_disc) : parseFloat("0.00");
          var created_date_time = this.pharma_bills[i].created_date != undefined ? Date_Formate(this.pharma_bills[i].created_date) + " " + Time_Formate(this.pharma_bills[i].created_time) : '';
          pharma_charges.push({
            sno: i + 1,
            billing_id: this.pharma_bills[i].billing_id,
            bill_type: "pharmacy",
            created_date_time: created_date_time,
            created_date: this.pharma_bills[i].created_date,
            created_time: this.pharma_bills[i].created_time,
            biill_type: "Medicine Bill No - " + this.pharma_bills[i].billing_id,
            amount_txt: Math.round(this.pharma_bills[i].bill_amount),
            concession: concession,
            fee: Math.round(this.pharma_bills[i].bill_amount),
            charge_type: this.pharma_bills[i].charge_id,
            paytype: this.pay_type,
            pay_type: paydesc,
            insurer: this.insurer_id,
            quantity: "-",
            total: total,
            edit_flag: true,
          })
        }
      }
      
      if (pharma_charges.length != 0) {
        var pharma_bill_array = [];
        var sub_total = 0;
        pharma_charges.forEach(pharma => {
          pharma_bill_array.push(pharma)
          sub_total = sub_total + pharma.total;
        })

        this.categoryChargeList.push({
          bill_type: "Medicines",
          charges: pharma_bill_array,
          sub_total: sub_total
        });
      }

      if (this.categoryChargeList.length != 0) {
        this.rowid = 1;
        for (var i = 0; i < this.categoryChargeList.length; i++) {
          for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
            this.categoryChargeList[i].charges[j].sno = this.rowid;
            this.rowid += 1;
          }
        }
      }
    } 

    this.billaddlist = this.combineCharges(this.categoryChargeList);
    if (this.billaddlist.length == 0 && this.wardName == 'Dialysis') {
      this.addNewBillDetails();
    }
    this.rowid = 1;
    this.hospitalBills = 0;
    for (var i = 0; i < this.billaddlist.length; i++) {
      this.billaddlist[i].sno = this.rowid;
      this.rowid += 1;
      if (this.billaddlist[i].fee == '') {
        this.billaddlist[i].fee = 0;
      }
      this.hospitalBills = (
        parseFloat(this.hospitalBills) + parseFloat(this.billaddlist[i].fee)
      );
    }
    this.Calculate_gst('0', '0');
    this.calculateHospitalGst('0', '0');
  }

  createGroupping(hospital_charges) {
    console.log(hospital_charges);
    this.categoryChargeList = [];
    this.categoryChargeList = hospital_charges;
    const groupedByCategory = this.groupBy(this.categoryChargeList, 'category');
    console.log("groupedByCategory", groupedByCategory);
    var hospitalChargeArray2 = [];
    var new_viewchargearray = [];
    for (var key in groupedByCategory) {
      console.log(key + ': ' + JSON.stringify(groupedByCategory[key]));
      if (key !== 'undefined') {
        console.log(key);
        new_viewchargearray = [];
        var sub_total = 0;
        for (var k = 0; k < groupedByCategory[key].length; k++) {
          var charge_order_list = groupedByCategory[key][k].order_no;
          var category_Name = "";
          if (groupedByCategory.length > 1) {
            category_Name = groupedByCategory[key][k].category;
          }
          new_viewchargearray.push({
            category: key,
            order_no: charge_order_list,
            sno: k + 1,
            bill_type: groupedByCategory[key][k].bill_type,
            created_date: groupedByCategory[key][k].created_date,
            created_time: groupedByCategory[key][k].created_time,
            created_date_time: groupedByCategory[key][k].created_date_time,
            biill_type: groupedByCategory[key][k].biill_type,
            amount_txt: groupedByCategory[key][k].amount_txt,
            concession: groupedByCategory[key][k].concession,
            fee: groupedByCategory[key][k].fee,
            charge_type: groupedByCategory[key][k].charge_type,
            pay_type: groupedByCategory[key][k].pay_type,
            paytype: groupedByCategory[key][k].paytype,
            insurer: groupedByCategory[key][k].insurer,
            quantity: groupedByCategory[key][k].quantity,
            total: groupedByCategory[key][k].total,
            edit_flag: groupedByCategory[key][k].edit_flag,
            charge_cat_id: groupedByCategory[key][k].charge_cat_id,
          })
          sub_total = sub_total + groupedByCategory[key][k].total;
        }
        var category_flag;
        if (new_viewchargearray.length == 0)
          category_flag = false;
        else
          category_flag = true;

        hospitalChargeArray2.push({
          category: key,
          category_flag: category_flag,
          order_no: charge_order_list,
          charges: new_viewchargearray.sort((a, b) => a.order_no - b.order_no),
          sub_total: sub_total,
        })
      }
    }

    this.categoryChargeList = [];
    this.categoryChargeList = hospitalChargeArray2.sort((a, b) => a.order_no - b.order_no);
    this.rowid = 1;
    for (var i = 0; i < this.categoryChargeList.length; i++) {
      for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
        this.categoryChargeList[i].charges[j].sno = this.rowid;
        this.rowid += 1;
      }
    }
    console.log("hospitalChargeArray2", hospitalChargeArray2);
    console.log("this.categoryChargeList", this.categoryChargeList);
  }

  combineCharges(groupedData) {
    // Use reduce to iterate over each item and combine their charges
    return groupedData.reduce((combinedCharges, item) => {
      return combinedCharges.concat(item.charges);
    }, []);
  }

  groupBy(array, key) {
    return array.reduce((result, obj) => {
      const keyValue = obj[key];
      // If the key doesn't exist in the result, create an empty array for it
      if (!result[keyValue]) {
        result[keyValue] = [];
      }
      // Push the object to the corresponding key's array
      result[keyValue].push(obj);
      return result;
    }, {});
  }

  addNewBillDetails() {
    this.categoryChargeList.push({
      charges: [{
        sno: this.rowid,
        biill_type: '',
        amount_txt: '',
        concession: '',
        fee: '',
        charge_type: '',
        pay_type: '',
        insurer: '',
        ref_code: '',
        quantity: '',
        total: '',
      }],
      category_flag: true
    });

    this.rowid = 1;
    for (var i = 0; i < this.categoryChargeList.length; i++) {
      for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
        this.categoryChargeList[i].charges[j].sno = this.rowid;
        this.rowid += 1;
      }
    }

    console.log(this.categoryChargeList);
    setTimeout(function () {
      $(".getChargeDesc").last().focus();
    }, 500);
  }

  getamountAftCon1(amount) {
    var RateCard = this.RateCardArray;
    for (var i = 0; i < RateCard.length; i++) {
      if (this.rate_card == RateCard[i].rate_card_id) {
        if (RateCard[i].discount == 0 && RateCard[i].rate_card_desc != 'Service wise') {
          return (this.amount_aftcon = parseFloat(amount) * this.quantity);
        } else {
          return (this.amount_aftcon =
            parseFloat(amount) * this.quantity -
            (this.concession_txt / 100) *
            (parseFloat(amount) * this.quantity));
        }
      }
    }
  }

  //Get payment type
  getPaymentType() {
    var obj = Master_Helper.getMasterPaymentType();
    if (obj != undefined) {
      this.paytypelist = obj.payments;
      if (this.paytypreq == false) {
        this.pay_type = this.paytypelist[0].pay_id;
      }
      this.payType1 = this.paytypelist[0].pay_id;
    }
  }

  pay_change() {
    document.getElementById('paytype')!.style.borderColor = '#2ca8fe';
    if (this.pay_type == '3') {
      this.insurFlag = false;
    } else {
      this.insurFlag = true;
    }
  }

  changePayType() {
    this.insurFlag = true;
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.pay_type == '2') {
      this.transactionFlag = false;
      this.cardFlag = false;
      this.cardreqflag = true;
      this.transreqflag = true;
      this.chequereqflag = false;
      this.insreqflag = false;
    } else if (this.pay_type == '3') {
      this.cardreqflag = false;
      this.insurFlag = false;
      this.cardreqflag = false;
      this.transreqflag = false;
      this.chequereqflag = false;
      this.insreqflag = true;
    } else if (this.pay_type == '4') {
      this.transactionFlag = false;
      this.cardreqflag = false;
      this.transreqflag = true;
      this.chequereqflag = false;
      this.insreqflag = false;
    } else if (this.pay_type == '5') {
      this.transactionFlag = false;
      this.cardreqflag = false;
      this.transreqflag = true;
      this.chequereqflag = false;
      this.insreqflag = false;
    } else if (this.pay_type == '6') {
      this.transactionFlag = false;
      this.cardreqflag = false;
      this.transreqflag = true;
      this.chequereqflag = false;
      this.insreqflag = false;
    } else if (this.pay_type == '7') {
      this.chequeFlag = false;
      this.cardreqflag = false;
      this.transreqflag = false;
      this.chequereqflag = true;
      this.insreqflag = false;
    }
    this.Appointmnet_change(this.appoint_txt);
  }

  //Get payment type
  getInsurers() {
    var obj = Master_Helper.getMasterInsurers();
    if (obj != undefined)
      this.insurerlist = obj.insurers;
  }

  getConcession() {
    for (var i = 0; i < this.RateCardArray.length; i++) {
      if (this.RateCardArray[i].rate_card_id == this.rate_card) {
        if (this.RateCardArray[i].rate_card_desc == 'Total Bill') {
          this.paid_amount_flag = true;
        } else {
          this.paid_amount_flag = false;
        }
        if (this.RateCardArray[i].rate_card_desc == 'Service wise') {
          this.discount_flag = false;
          if (this.concession_txt != '') {
            this.concession_txt = parseFloat(this.concession_txt);
          } else {
            this.concession_txt = parseFloat(this.RateCardArray[i].discount);
          }
        } else {
          this.discount_flag = true;
        }
        if (this.RateCardArray[i].rate_card_desc != 'Service wise') {
          this.concession_txt = parseInt(this.RateCardArray[i].discount);
          console.log(this.concession_txt);
        }
        this.flag_function();
      }
    }
    if (this.billaddlist != undefined && this.billaddlist.length != 0) {
      for (var i = 0; i < this.categoryChargeList.length; i++) {
        for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
          this.categoryChargeList[i].charges[j].concession == this.concession_txt;
        }
      }
      this.billaddlist = this.combineCharges(this.categoryChargeList);
      for (var i = 0; i < this.billaddlist.length; i++) {
        this.billaddlist[i].concession = parseFloat(this.concession_txt);
        this.changeamount1(this.billaddlist[i], 0);
      }
    }
  }

  charge_change(charge) {
    this.charge_type = charge.description;
    this.serviceType = [];
    for (var i = 0; i < this.charge_name.length; i++) {
      if (this.charge_name[i].description == this.charge_type) {
        this.amount_txt = this.charge_name[i].amount;
        this.amount_aftcon = this.charge_name[i].amount;
      }
    }
    this.addbtn_flag = false;
  }

  checkBoxTrueOrFalse(type, checkBox) {
    for (var i = 0; i < this.chargeNameArray.length; i++) {
      for (var j = 0; j < this.billaddlist.length; j++) {
        for (var k = 0; k < this.charge_name.length; k++) {
          if (this.chargeNameArray[i].selected == true || checkBox) {
            if (
              this.billaddlist[j].charge_type ==
              this.chargeNameArray[i].charge_type
            ) {
              this.chargeNameArray[i].selected = true;
              this.chargeNameArray[i].quantity = this.billaddlist[j].quantity;
              this.chargeNameArray[i].amount_txt = parseFloat(
                this.billaddlist[j].amount_txt
              );
              this.chargeNameArray[i].concession =
                this.billaddlist[j].concession;
              this.chargeNameArray[i].fee = parseFloat(
                this.billaddlist[j].fee
              );
            }
          }
          if (this.chargeNameArray[i].selected == false || !checkBox) {
            if (
              type == this.chargeNameArray[i].charge_type &&
              this.charge_name[k].charge_id == type
            ) {
              this.chargeNameArray[i].selected = false;
              this.chargeNameArray[i].quantity = this.quantity;
              this.chargeNameArray[i].amount_txt = parseFloat(
                this.charge_name[k].amount
              );
              this.chargeNameArray[i].concession = this.concession_txt;
              this.chargeNameArray.forEach((value) => this.changeamount(value));
            }
          }
        }
      }
    }
  }

  changeChargeType1(charge, person) {
    var result = this.finekeyInArray_billaddChargeType(
      this.billaddlist,
      charge.charge_id
    );
    
    if (result == true && this.wardName != "Dialysis") {
      this.toastr.error('Charge type already exists');
      this.serviceType = [];
      person.biill_type = '';
    } else {
      this.Get_current_date();
      setTimeout(()=>{
        if (this.hosp_charges != undefined) {  
          this.hosp_charges.push({
            amount: parseInt(charge.amount),
            category_desc: charge.category_desc,
            charge_category_id: charge.charge_cat_id,
            charge_desc: charge.description,
            charge_id: charge.charge_id,
            created_date: this.currentDate,
            created_time: this.currentTime,
            final_amount: this.getamountAftCon1(charge.amount),
            order_no: charge.order_no,
            quantity: '1.0',
            type: "hospital",
          })
        }
  
        console.log("hosp_charges", this.hosp_charges);
        this.Get_Bill_Type();
        this.serviceType = [];
      },400)  
    }
  }

  getServiceType(serviceType) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/chgtype',
        JSON.stringify({
          hptl_clinic_id: this.hptl_clinic_id,
          provider: 'doctor',
          type: 'in-patient',
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();
          this.charge_name = obj.charges;
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );

    this.serviceType = [];
    if (serviceType.length >= 3) {
      if (this.charge_name.length != 0) {
        if (
          this.charge_name.filter((o) =>
            o.description.toLowerCase().startsWith(serviceType.toLowerCase())
          )
        ) {
          var chg = this.charge_name.filter((o) =>
            o.description.toLowerCase().startsWith(serviceType.toLowerCase())
          );
          for (var i = 0; i < chg.length; i++) {
            this.serviceType.push(chg[i].description);
          }
        } else {
          this.serviceType = [];
        }
      }
    }
  }

  getServiceType1(bill_type) {
    this.serviceType = [];
    var filteredItems = [];
    if (bill_type.length >= 2 && bill_type !== undefined) {
      if (this.charge_name.length !== 0) {
        if (
          this.department_id != undefined &&
          this.department_id != '' &&
          this.ward_id != undefined &&
          this.ward_id != ''
        ) {
          if (this.wardbasecharge == "1") {
            filteredItems = this.charge_name.filter(
              (o) =>
                o.description.toLowerCase().includes(bill_type.toLowerCase()) &&
                o.department_id.includes(this.department_id) &&
                o.ward_id.includes(this.ward_id)
            );
          } else {
            filteredItems = this.charge_name.filter((o) =>
              o.description.toLowerCase().includes(bill_type.toLowerCase())
            );
          }

        } else {
          filteredItems = this.charge_name.filter((o) =>
            o.description.toLowerCase().includes(bill_type.toLowerCase())
          );
        }

        if (filteredItems.length > 0) {
          filteredItems.forEach((o) => {
            this.serviceType.push({
              description: o.description,
              amount: o.amount,
              charge_id: o.charge_id,
              category_desc: o.category_desc,
              charge_cat_id: o.charge_cat_id,
              order_no: o.order_no
            });
          });
        } else {
          this.serviceType = [];
        }
      }
    }
  }

  handleKeyUp(event: KeyboardEvent, biill_type, person) {
    const key = event.key;
    if (key !== 'ArrowUp' && key !== 'ArrowDown') {
      var biill_type;
      this.getServiceType1(biill_type);
    }
    if (key === 'Enter') {
      let charge;
      for (let i = 0; i < this.serviceType.length; i++) {
        if (this.serviceType[i].description === biill_type) {
          const { description, amount, charge_id, category_desc, charge_cat_id, order_no } = this.serviceType[i];
          charge = {
            description,
            amount,
            charge_id,
            category_desc,
            charge_cat_id,
            order_no
          };
          break;
        }
      }
      if (biill_type) {
        this.changeChargeType1(charge, person);
      }
    }
  }

  searchPatient() {
    this.appointment_list = [];
    var fieldvalue;
    if (this.filter == 'Patient_ID') {
      fieldvalue = {
        hptl_clinic_id: this.hptl_clinic_id,
        mr_no: this.patientId,
      };
    } else if (this.filter == 'Name') {
      fieldvalue = {
        hptl_clinic_id: this.hptl_clinic_id,
        first_name: this.firstName,
        last_name: this.lastName,
      };
    } else if (this.filter == 'Date') {
      fieldvalue = {
        hptl_clinic_id: this.hptl_clinic_id,
        date: this.date,
      };
    } else if (this.filter == 'Mobile_No') {
      fieldvalue = {
        hptl_clinic_id: this.hptl_clinic_id,
        mobile: this.mobileNo,
      };
    }
    console.log(fieldvalue);
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'bill/gipbc', fieldvalue, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          var obj = response.json();
          console.log("====================== " + JSON.stringify(obj));
          if (obj.appointment != null) {
            for (var i = 0; i < obj.appointment.length; i++) {
              var wardDetails = [];
              if (
                obj.appointment[i].ward_details != undefined &&
                obj.appointment[i].ward_details.length != 0
              ) {
                wardDetails = obj.appointment[i].ward_details;
              }

              var doc_visits = [];
              if (
                obj.appointment[i].doc_visits != undefined &&
                obj.appointment[i].doc_visits.length != 0
              ) {
                doc_visits = obj.appointment[i].doc_visits;
              }

              var other_charges = [];
              if (
                obj.appointment[i].other_charges != undefined &&
                obj.appointment[i].other_charges.length != 0
              ) {
                other_charges = obj.appointment[i].other_charges;
              }

              var date =
                obj.appointment[i].admitted_date != undefined
                  ? ' (' + Date_Formate(obj.appointment[i].admitted_date) + ')'
                  : ' ';
              if (obj.appointment[i].middle_name != undefined) {
                this.Docidname =
                  obj.appointment[i].doc_app_id +
                  ' - ' +
                  encrypt_decript.Decript(obj.appointment[i].first_name) +
                  ' ' +
                  encrypt_decript.Decript(obj.appointment[i].middle_name) +
                  ' ' +
                  encrypt_decript.Decript(obj.appointment[i].last_name) +
                  date;
              } else {
                this.Docidname =
                  obj.appointment[i].doc_app_id +
                  ' - ' +
                  encrypt_decript.Decript(obj.appointment[i].first_name) +
                  ' ' +
                  encrypt_decript.Decript(obj.appointment[i].last_name) +
                  date;
              }

              var sessiondata = null;
              if (
                obj.appointment[i].session != undefined &&
                obj.appointment[i].session != undefined &&
                obj.appointment[i].session != null
              ) {
                sessiondata = encrypt_decript.Decript(
                  obj.appointment[i].session
                );
              }

              var address1 = '',
                address2 = '';
              if (
                obj.appointment[i].address2 != null &&
                obj.appointment[i].address2 != undefined &&
                obj.appointment[i].address1 != undefined
              ) {
                address1 =
                  encrypt_decript.Decript(obj.appointment[i].address1) + ',';
                if (
                  obj.appointment[i].address2 != ' ' &&
                  obj.appointment[i].address2 != 'null'
                ) {
                  if (
                    encrypt_decript.Decript(obj.appointment[i].address2) == ''
                  ) {
                    address2 == '';
                  } else {
                    address2 =
                      encrypt_decript.Decript(obj.appointment[i].address2) +
                      ',';
                  }
                }
              } else if (obj.appointment[i].address1 != undefined) {
                address1 =
                  encrypt_decript.Decript(obj.appointment[i].address1) + ',';
              } else if (obj.appointment[i].address2 != undefined) {
                address2 =
                  encrypt_decript.Decript(obj.appointment[i].address2) + ',';
              }

              var location;
              if (
                obj.appointment[i].location != undefined &&
                obj.appointment[i].location != ''
              ) {
                location = obj.appointment[i].location + ',';
              }

              var city = '';
              if (
                obj.appointment[i].district != undefined &&
                obj.appointment[i].district != null
              ) {
                city = obj.appointment[i].district + '-';
              }

              var zipcode;
              if (
                obj.appointment[i].zipcode != undefined &&
                obj.appointment[i].zipcode != null
              ) {
                zipcode =
                  encrypt_decript.Decript(obj.appointment[i].zipcode) + ',';
              }

              var state = '';
              if (
                obj.appointment[i].state != undefined &&
                obj.appointment[i].state != null
              ) {
                state = obj.appointment[i].state + '-';
              }

              var country = '';
              if (
                obj.appointment[i].country != undefined &&
                obj.appointment[i].country != null
              ) {
                country = obj.appointment[i].country + '.';
              }

              var hospital;
              if (obj.hosp_address2 != undefined) {
                hospital = obj.hosp_address1 + ',' + obj.hosp_address2;
              } else {
                hospital = obj.hosp_address1;
              }

              var docName;
              if (
                obj.appointment[i].dr_middle_name != undefined &&
                obj.appointment[i].dr_middle_name != null
              ) {
                docName =
                  'Dr.' +
                  obj.appointment[i].dr_first_name +
                  ' ' +
                  obj.appointment[i].dr_middle_name +
                  ' ' +
                  obj.appointment[i].dr_last_name;
              } else if (
                obj.appointment[i].dr_first_name != undefined &&
                obj.appointment[i].dr_last_name != undefined
              ) {
                docName =
                  'Dr.' +
                  obj.appointment[i].dr_first_name +
                  ' ' +
                  obj.appointment[i].dr_last_name;
              }

              if (obj.appointment[i].doc_qualif != undefined && obj.appointment[i].doc_qualif != null && obj.appointment[i].doc_qualif != "") {
                docName = docName + " " + obj.appointment[i].doc_qualif;
              }

              if (obj.appointment[i].profile_image != '') {
                var image =
                  ipaddress.Ip_with_img_address +
                  obj.appointment[i].profile_image;
              }

              var inpatientId =
                obj.appointment[i].inpat_id != undefined
                  ? obj.appointment[i].inpat_id
                  : '-';
              this.hospital_name =
                obj.hosp_name != undefined ? obj.hosp_name : '';
              this.hospitalAddress = hospital;
              this.Hospital_location =
                obj.hosp_location +
                ', ' +
                obj.hosp_district +
                ' - ' +
                obj.hosp_state +
                ' ' +
                obj.hosp_country +
                '  ' +
                obj.hosp_zip;
              this.mobile_no =
                obj.appointment[i].mobile != undefined
                  ? encrypt_decript.Decript(obj.appointment[i].mobile)
                  : '';
              this.e_contact =
                obj.appointment[i].emerg_contact != undefined
                  ? encrypt_decript.Decript(obj.appointment[i].emerg_contact)
                  : '';

              var surgery = obj.appointment[i].surgery_desc != undefined ? obj.appointment[i].surgery_desc : '';
              var bill_type_pharma = obj.appointment[i].bill_incl_pharma != undefined ? obj.appointment[i].bill_incl_pharma : '';
              var barcode;
              if (obj.appointment[i].barcode != undefined && obj.appointment[i].barcode != null) {
                this.barcode_flag = true;
                barcode = ipaddress.Ip_with_img_address + obj.appointment[i].barcode;
              }
              var hosp_charges = [];
              if (obj.appointment[i].hosp_charges != undefined) {
                hosp_charges = obj.appointment[i].hosp_charges;
              }

              var diag_charges = [];
              if (obj.appointment[i].diag_charges != undefined) {
                diag_charges = obj.appointment[i].diag_charges;
              }

              var diagnosis_bills = [];
              if (obj.appointment[i].diagnosis_bills != undefined) {
                diagnosis_bills = obj.appointment[i].diagnosis_bills;
              }

              var pharma_charges = [];
              if (obj.appointment[i].pharma_charges != undefined) {
                pharma_charges = obj.appointment[i].pharma_charges;
              }

              var pharma_bills = [];
              if (obj.appointment[i].pharma_bills != undefined) {
                pharma_bills = obj.appointment[i].pharma_bills;
              }

              var rate_card_disc = obj.appointment[i].rate_card_disc != undefined ? obj.appointment[i].rate_card_disc : 0;
              var isDisInitiate = false;
              if(obj.appointment[i].discharge_initiate != undefined && obj.appointment[i].discharge_initiate == "1") {
                isDisInitiate = true;
              }
              if (obj.pkg_hosp_id != undefined) {
                this.hosp_id = obj.pkg_hosp_id;
              }

              if (obj.appointment[i].pat_type != undefined) {
                var ins_or_nonins = obj.appointment[i].pat_type;
              }
              
              var insurer = obj.appointment[i].insurer != undefined ? obj.appointment[i].insurer : '';
              var sponser_name = obj.appointment[i].tpa != undefined ? obj.appointment[i].tpa : '';
              var sponser_GSTIN = obj.policy_no != undefined ? obj.policy_no : '';

              this.appointment_list.push({
                doc_app_id: obj.appointment[i].doc_app_id,
                doc_reg_id: obj.appointment[i].doc_reg_id,
                client_reg_id: obj.appointment[i].client_reg_id,
                relation_id: obj.appointment[i].relation_id,
                sub_rel_id: obj.appointment[i].sub_rel_id,
                address1: address1,
                address2: address2,
                location: location,
                district: city,
                state: state,
                country: country,
                first_name: obj.appointment[i].first_name,
                middle_name: obj.appointment[i].middle_name,
                last_name: obj.appointment[i].last_name,
                hptl_clinic_id: obj.appointment[i].hptl_clinic_id,
                session: sessiondata,
                city: city,
                zipcode: zipcode,
                docid_name: this.Docidname,
                docName: docName,
                advance: obj.appointment[i].advance,
                advance_list: obj.appointment[i].advances,
                balance: obj.appointment[i].balance,
                inpatientId: inpatientId,
                cgst: '12%',
                sgst: '12$',
                admitted_date: obj.appointment[i].admitted_date,
                admitted_time: obj.appointment[i].admitted_time,
                discharge_date: obj.appointment[i].discharge_date,
                discharge_time: obj.appointment[i].discharge_time,
                department_id: obj.appointment[i].department_id,
                department_desc: obj.appointment[i].department_desc,
                ward_id: obj.appointment[i].ward_id,
                ward_name: obj.appointment[i].ward_name,
                bed_no: obj.appointment[i].bed_no,
                profile_image: image,
                patient_id: obj.appointment[i].patient_id,
                age: obj.appointment[i].age,
                gender: obj.appointment[i].gender,
                mobile: this.mobile_no,
                emerg_contact: this.e_contact,
                salutation_desc: obj.appointment[i].salutation_desc,
                ward_details: wardDetails,
                doc_visits: doc_visits,
                other_charges: other_charges,
                surgery_desc: surgery,
                bill_type_pharma: bill_type_pharma,
                barcode: barcode,
                approved_amount: obj.appointment[i].approved_amount,
                hosp_charges: hosp_charges,
                diag_charges: diag_charges,
                diagnosis_bills: diagnosis_bills,
                pharma_charges: pharma_charges,
                pharma_bills: pharma_bills,
                rate_card_disc: rate_card_disc,
                isDisInitiate: isDisInitiate,
                inp_hosp_id: obj.appointment[i].inp_hosp_id,
                ins_or_nonins: ins_or_nonins,
                insurer: insurer,
                sponser_name: sponser_name,
                sponser_GSTIN: sponser_GSTIN
              });
            }

            if (
              this.appointment_list != undefined &&
              this.appointment_list.length === 1
            ) {
              for (var i = 0; i < this.appointment_list.length; i++) {
                this.appoint_txt = this.appointment_list[i].docid_name;
                this.Appointmnet_change(this.appoint_txt);
              }
            }
          }
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );
  }

  Appointmnet_change(e) {
    var patientname = e.split('-');
    this.billden_plan = [];
    this.billaddlist = [];
    this.billAddList = [];
    this.categoryChargeList = [];
    this.medcalDetailsArray = [];
    this.digDetailsArray = [];
    this.billing_print_array = [];
    this.quantityArray = [];
    this.other_charges_array = [];
    this.hosp_charges = [];
    this.diag_charges = [];
    this.diagnosis_bills = [];
    this.pharma_charges = [];
    this.pharma_bills = [];
    this.final_amount = 0;
    this.totalBillAmount = 0;
    this.remaing_billbal = 0;
    this.balance_data = 0;
    this.Advance_bill = 0;
    this.mrno = '';
    this.clnt_saldesc = '';
    this.patient_name = '';
    this.clnt_age = '';
    this.gender = '';
    this.mobile_no = '';
    this.doctor_name = '';
    this.admitted_date = '';
    this.admitted_time = '';
    this.e_contact = '';
    this.dept_desc = '';
    this.wardName = '';
    this.bed_no = '';
    this.ward_id = '';
    this.profileImg = '';
    this.hospitalBills = 0;
    this.doc_visitscreate = [];
    this.doctorVisitsChargeArray = [];
    this.rate_card_disc = 0;
    this.advanceList = [];
    this.refund_array = [];
    this.acc_number_adv = '';
    this.holder_Name_adv = '';
    this.bank_Name_adv = '';
    this.bank_ifc_code_adv = '';
    this.med_with_det = false;
    this.inp_hosp_id = '';
    this.selectedValue = 'pending';
    this.billType(this.selectedValue);
    this.insurance_flag = false;
    this.pharma_bill_flag = false;
    this.pay_mode = '';
    this.insurer = '';
    this.sponser_name = '';
    this.sponser_GSTIN = '';

    for (var i = 0; i < this.appointment_list.length; i++) {
      if (this.appointment_list[i].doc_app_id == parseFloat(patientname[0])) {
        this.isDisInitiate = this.appointment_list[i].isDisInitiate;
        if (this.appointment_list[i].ins_or_nonins != undefined && this.appointment_list[i].ins_or_nonins == 'insurance') {
          this.insurance_flag = true;
          this.pay_mode = 'Insurance';
          this.insurer = this.appointment_list[i].insurer;
          this.sponser_name = this.appointment_list[i].sponser_name;
          this.sponser_GSTIN = this.appointment_list[i].sponser_GSTIN;
        } else {
          this.insurance_flag = false;
        }
        
        if (
          this.appointment_list[i].ward_details != null &&
          this.appointment_list[i].ward_details.length != 0
        ) {
          this.warddetails_billcreate = this.appointment_list[i].ward_details;
          console.log(this.warddetails_billcreate);
          if (this.warddetails_billcreate.length != 0) {
            this.ward_details_flag = true;
          } else {
            this.ward_details_flag = false;
          }
          console.log("ward_details_flag", this.ward_details_flag);

          this.warddetails_billcreate.forEach((detail) => {
            var createdDateTime = moment(
              `${detail.created_date} ${detail.created_time}`
            );
            var changeDateTime;
            if (detail.change_date && detail.change_time != undefined) {
              changeDateTime = moment(
                `${detail.change_date} ${detail.change_time}`
              );
            } else {
              changeDateTime = moment(); // Set to current date and time
              detail.change_date = changeDateTime.format('YYYY-MM-DD');
              detail.change_time = changeDateTime.format('HH:mm:ss');
            }
            var duration = moment.duration(
              changeDateTime.diff(createdDateTime)
            );
            var hourdif = (
              moment(changeDateTime).diff(createdDateTime, 'hours') / 24
            )
              .toFixed(2)
              .split('.');

            var hours = hourdif[0];
            var remhour = (parseInt(hourdif[1]) * 24) / 100;
            if (hours != '0') {
              if (this.userinfo.inpat_bill_type == 'Hourly') {
                if (remhour > 12) {
                  this.quantity =
                    parseInt(hours) + remhour * 0.0416666666666667;
                } else if (remhour != 0 && remhour > 1) {
                  this.quantity =
                    parseInt(hours) + remhour * 0.0416666666666667;
                } else {
                  this.quantity = hours;
                }
              } else if (this.userinfo.inpat_bill_type == 'Half a day') {
                if (remhour > 12) {
                  this.quantity = parseInt(hours) + 1;
                } else if (remhour != 0 && remhour > 1) {
                  this.quantity = hours + '.5';
                } else {
                  this.quantity = hours;
                }
              } else {
                this.quantity = hours;
              }
              if (this.quantity != '0') {
                this.quantityArray.push(this.quantity);
              } else {
                this.quantity = '1';
              }
            } else {
              this.quantity = '1';
            }
          });
        }

        if (
          this.appointment_list[i].doc_visits != null &&
          this.appointment_list[i].doc_visits.length != 0
        ) {
          this.doc_visitscreate = this.appointment_list[i].doc_visits;
          for (var j = 0; j < this.doc_visitscreate.length; j++) {
            if (this.doc_visitscreate[j].charge_id != undefined) {
              this.doctorVisitsChargeArray.push({
                charge_id: this.doc_visitscreate[j].charge_id,
              });
            }
          }
        }
        if (this.appointment_list[i].admitted_date != undefined) {
          var admdate =
            this.appointment_list[i].admitted_date != undefined
              ? this.appointment_list[i].admitted_date.split('-')
              : '';
          var admtime =
            this.appointment_list[i].admitted_time != undefined
              ? this.appointment_list[i].admitted_time
              : '';
          this.dateval = moment(
            admdate[2] + '/' + admdate[1] + '/' + admdate[0] + ' ' + admtime,
            'DD/MM/YYYY HH:mm'
          );
          console.log(this.dateval);

          if (
            this.appointment_list[i].discharge_date &&
            this.appointment_list[i].discharge_time != undefined &&
            '' &&
            null
          ) {
            this.enddate = this.appointment_list[i].discharge_date.split('-');
            this.endtime = this.appointment_list[i].discharge_time;

          } else {
            this.enddate = this.CurrentDatetime.split('-');
            this.endtime = this.currentTime;
          }
          console.log("END_TIME", this.endtime);
        }
        this.department_id = this.appointment_list[i].department_id;
        console.log('Department_Id', this.department_id);

        this.ward_id = this.appointment_list[i].ward_id;
        console.log('Ward_Id', this.ward_id);

        if (this.appointment_list[i].middle_name != undefined) {
          this.patient_name =
            encrypt_decript.Decript(this.appointment_list[i].first_name) +
            ' ' +
            encrypt_decript.Decript(this.appointment_list[i].middle_name) +
            ' ' +
            encrypt_decript.Decript(this.appointment_list[i].last_name);
        } else {
          if (this.appointment_list[i].last_name != undefined) {
            this.patient_name =
              encrypt_decript.Decript(this.appointment_list[i].first_name) +
              ' ' +
              encrypt_decript.Decript(this.appointment_list[i].last_name);
          } else {
            this.patient_name = encrypt_decript.Decript(
              this.appointment_list[i].first_name
            );
          }
        }

        if (this.appointment_list[i].advance != undefined) {
          this.Advance_bill = parseFloat(
            this.appointment_list[i].advance
          );
        }
        if (this.appointment_list[i].approved_amount != undefined) {
          this.sponsorDue = parseFloat(
            this.appointment_list[i].approved_amount
          );
          this.sponsorDueflag = true;
        }

        if (
          this.appointment_list[i].balance != '0' ||
          this.appointment_list[i].balance != 0
        ) {
          this.amount_collected = true;
          this.balance_show = false;
          this.previous_balshow = false;
          this.balance_data = parseFloat(
            this.appointment_list[i].balance
          );
          this.total_collected = parseFloat(
            this.appointment_list[i].balance
          );
        }

        this.dog_reg_id = this.appointment_list[i].doc_reg_id;
        var zipcode =
          this.appointment_list[i].czipcode != undefined
            ? encrypt_decript.Decript(this.appointment_list[i].czipcode)
            : '';
        this.mrno = this.appointment_list[i].patient_id;

        if (this.appointment_list[i].salutation_desc != undefined) {
          this.clnt_saldesc = this.appointment_list[i].salutation_desc + '.';
        }

        this.clnt_age = this.appointment_list[i].age;
        if (
          this.appointment_list[i].gender != null &&
          this.appointment_list[i].gender != undefined
        ) {
          this.gender = encrypt_decript.Decript(
            this.appointment_list[i].gender
          );
          this.gender = this.gender[0];
          if (this.gender[0] == 'T') {
            this.gender = 'Tgen';
          }
        }

        this.mobile_no =
          this.appointment_list[i].mobile != undefined
            ? this.appointment_list[i].mobile
            : '';
        this.e_contact =
          this.appointment_list[i].emerg_contact != undefined
            ? this.appointment_list[i].emerg_contact
            : '';
        this.admitted_date =
          this.appointment_list[i].admitted_date != undefined
            ? this.appointment_list[i].admitted_date
            : '';
        this.admitted_time = admtime != undefined ? Time_Formate(admtime) : '';
        this.discharge_date =
          this.appointment_list[i].discharge_date != undefined
            ? this.appointment_list[i].discharge_date
            : '';
        this.doctor_name =
          this.appointment_list[i].docName != undefined
            ? this.appointment_list[i].docName
            : '';
        this.dept_desc =
          this.appointment_list[i].department_desc != undefined
            ? this.appointment_list[i].department_desc
            : '';
        this.wardName =
          this.appointment_list[i].ward_name != undefined
            ? this.appointment_list[i].ward_name
            : '';
        this.bed_no =
          this.appointment_list[i].bed_no != undefined
            ? this.appointment_list[i].bed_no
            : '';
        this.profileImg =
          this.appointment_list[i].profile_image != undefined
            ? this.appointment_list[i].profile_image
            : '';
        if (
          this.appointment_list[i].discharge_date != undefined &&
          this.appointment_list[i].discharge_date != ''
        ) {
          this.discharge_date =  Date_Formate(
            this.appointment_list[i].discharge_date
          );
        }
        if (
          this.appointment_list[i].discharge_time != undefined &&
          this.appointment_list[i].discharge_time != ''
        ) {
          this.discharge_time = Time_Formate(
            this.appointment_list[i].discharge_time
          );
        }

        if (this.appointment_list[i].other_charges != undefined) {
          this.other_charges_array = this.appointment_list[i].other_charges;
        }

        if (this.appointment_list[i].hosp_charges != undefined) {
          this.hosp_charges = this.appointment_list[i].hosp_charges;
        }

        if (this.appointment_list[i].diag_charges != undefined) {
          this.diag_charges = this.appointment_list[i].diag_charges;
        }

        if (this.appointment_list[i].diagnosis_bills != undefined) {
          this.diagnosis_bills = this.appointment_list[i].diagnosis_bills;
        }

        if (this.appointment_list[i].pharma_charges != undefined) {
          this.pharma_charges = this.appointment_list[i].pharma_charges;
        }

        if (this.appointment_list[i].pharma_bills != undefined) {
          this.pharma_bills = this.appointment_list[i].pharma_bills;
          if (this.pharma_bills.length != 0) {
            this.pharma_bill_flag = true;
          }
        }

        if (this.appointment_list[i].approved_amount != undefined) {
          this.sponsorDue = this.appointment_list[i].approved_amount;
        }

        var advanceList = [];
        if (this.appointment_list[i].advance_list != undefined) {
          advanceList = this.appointment_list[i].advance_list;
          console.log("advanceList",advanceList);
          if (advanceList.length != 0) {
            for (var j = 0; j < advanceList.length; j++) {
              var created_date_time = advanceList[j].created_date != undefined ? Date_Formate(advanceList[j].created_date) + " " + Time_Formate(advanceList[j].created_time) : '';
              this.advanceList.push({
                sno: j + 1,
                advance_id: advanceList[j].advance_id,
                created_date_time: created_date_time,
                created_by: advanceList[j].created_by,
                payment_desc: advanceList[j].payment_desc,
                amount: parseFloat(advanceList[j].amount)
              })
            }
          }
        }
        console.log("advanceList", this.advanceList);
        this.rate_card_disc = this.appointment_list[i].rate_card_disc != undefined ? this.appointment_list[i].rate_card_disc : 0;

        console.log("hosp_charges", this.hosp_charges, "diag_charges", this.diag_charges, "pharma_charges", this.pharma_charges);
        console.log("diagnosis_bills", this.diagnosis_bills, "pharma_bills", this.pharma_bills);
        this.surgery = this.appointment_list[i].surgery_desc != undefined ? this.appointment_list[i].surgery_desc : '';
        this.bill_type_pharma = this.appointment_list[i].bill_type_pharma != undefined ? this.appointment_list[i].bill_type_pharma : '';
        this.barcode_url = this.appointment_list[i].barcode;
        this.inp_hosp_id = this.appointment_list[i].inp_hosp_id != undefined ? this.appointment_list[i].inp_hosp_id : '';
        this.billing_print_array.push({
          dr_name: this.appointment_list[i].docName,
          licence_code: '',
          hptl_name: '',
          created_date: '',
          address1: this.appointment_list[i].address1,
          address2: this.appointment_list[i].address2,
          location: this.appointment_list[i].location,
          city: this.appointment_list[i].city + this.appointment_list[i].zipcode,
          state: this.appointment_list[i].state + this.appointment_list[i].country,
          telephone: this.appointment_list[i].telephone,
          inpatientId: this.appointment_list[i].inpatientId,
          appointment_id: this.appointment_list[i].doc_app_id,
          client_name: this.patient_name,
          caddress: '',
          clocation: this.appointment_list[i].clocation + ', ',
          clientcity: this.appointment_list[i].ccity + ' - ' + zipcode + ',',
          clestare: this.appointment_list[i].cstate + ' - ' + this.appointment_list[i].ccountry + '.',
          client_reg_id: this.appointment_list[i].client_reg_id,
          advance: this.appointment_list[i].advance,
          balance: this.appointment_list[i].balance,
          relation_id: this.appointment_list[i].relation_id,
          sub_rel_id: this.appointment_list[i].sub_rel_id,
        });

        this.createpage_flag = true;
        this.Get_Bill_Type();

        if (this.surgery == '' && this.credit_type == '') {
          this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: 0px;";
          this.nobanner_style = "width: 100%; height: 95px;";
        } else if (this.surgery != '' && this.credit_type != '') {
          this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: 0px;";
          this.nobanner_style = "width: 100%; height: 115px;";
        } else if (this.surgery != '' || this.credit_type != '') {
          this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: 0px;";
          this.nobanner_style = "width: 100%; height: 110px;";
        }
      }
    }
    this.medicinBal = 0;
    this.diagnosisAmount = 0;
  }

  digAmount(id, value) {
    for (var i = 0; i < this.digDetailsArray.length; i++) {
      if (this.digDetailsArray[i].digID == id) {
        var val = value != '' ? value : 0;
        var previous = this.digDetailsArray[i].previous_value;
        this.current_amount = (
          parseFloat(this.current_amount) -
          parseFloat(this.digDetailsArray[i].previous_value)
        );
        this.current_amount = (
          parseFloat(this.current_amount) + parseFloat(val)
        );
        this.total_bill1 = this.current_amount;
        this.total_bill = this.current_amount;
        this.final_amount = this.current_amount;
        if (value != '') {
          this.digDetailsArray[i].amount_txt = val;
          this.digDetailsArray[i].fee = val;
          this.digDetailsArray[i].previous_value = val;
        } else {
          this.digDetailsArray[i].amount_txt = 0;
          this.digDetailsArray[i].fee = 0;
          this.digDetailsArray[i].previous_value = 0;
        }
      }
    }
  }

  amount_change() {
    var perce!: number | null;
    perce = this.concession_txt / 100;
    var amt = this.amount_txt;
    var amt_cons = this.amount_aftcon;
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }
    this.amount_aftcon = Math.round(tot) * parseInt(this.quantity);
  }

  changeamount(charge: any) {
    for (var i = 0; i < this.chargeNameArray.length; i++) {
      if (charge.charge_type == this.chargeNameArray[i].charge_type) {
        var concec;
        if (charge.concession == 'NaN') {
          concec = 0;
        } else {
          concec = charge.concession;
        }
        charge.fee =
          charge.quantity *
          (charge.amount_txt - charge.amount_txt * (concec / 100));
        charge.fee = parseInt(charge.fee);
      }
    }
  }

  changeamount1(charge, num) {
    this.hospitalBills = 0;
    if (this.billaddlist != undefined && this.billaddlist.length != 0) {
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (charge.biill_type == this.billaddlist[i].biill_type) {
          if (this.hosp_charges.length != 0) {
            for (var h = 0; h < this.hosp_charges.length; h++) {
              if (charge.charge_type == this.hosp_charges[h].charge_id) {
                this.hosp_charges[h].amount = charge.amount_txt;
                this.hosp_charges[h].quantity = charge.quantity;
                this.hosp_charges[h].final_amount = charge.amount_txt * charge.quantity;
              } 
            }
          }
          if (num != 2) {
            var concec;
            if (charge.concession == 'NaN') {
              charge.concession = 0;
              concec = 0;
            } else {
              concec = charge.concession;
            }
            charge.fee = (charge.quantity * (charge.amount_txt - charge.amount_txt * (concec / 100)));
            charge.fee = parseFloat(charge.fee);
            charge.total = charge.quantity * charge.amount_txt;
            charge.total = parseInt(charge.total);
          } else {
            var fee;
            if (charge.fee == 'NaN') {
              charge.fee = 0;
              fee = 0;
            } else {
              fee = charge.fee;
            }

            if (charge.amount_txt != 0 && charge.quantity != 0 && charge.fee != '') {
              charge.concession = (100 - ((fee / (charge.amount_txt * charge.quantity)) * 100));
            } else {
              charge.concession = 0;
            }
          }
        }
      }
      console.log("categoryChargeList", this.categoryChargeList);
      this.billaddlist = this.combineCharges(this.categoryChargeList);
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (this.billaddlist[i].fee == '') {
          this.billaddlist[i].fee = 0;
        }
        this.hospitalBills = (
          parseFloat(this.hospitalBills) + parseFloat(this.billaddlist[i].fee)
        );
      }
      console.log("billaddlist", this.billaddlist);
      this.calculateHospitalGst('0', '0');
      this.Calculate_gst('0', num);
      this.discountvalue('0');
      this.subtotalCalculation();
    }
  }

  subtotalCalculation() {
    if (this.categoryChargeList.length != 0 ) {
      for (var i = 0; i < this.categoryChargeList.length; i++) {
        if (this.categoryChargeList[i].charges.length != 0) {
          var sub_total = 0;
          for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) { 
            if (this.categoryChargeList[i].charges[j].total != undefined) {
              sub_total = sub_total + this.categoryChargeList[i].charges[j].total;
            }
          }
          this.categoryChargeList[i].sub_total = sub_total;
        }      
      }
    }
  }

  //Get gst
  async Get_Gst_Data() {
    var obj = Master_Helper.getMasterGSTData();
    if (Master_Helper.getMasterGSTData() == undefined) {
      await this.masterData.getGstData();
    }
    var obj = Master_Helper.getMasterGSTData();
    if (obj != undefined) this.gst_retrive_list = obj.taxes;
  }

  // Include GST
  include_gst_click(gst_click) {
    this.calculateHospitalGst('0', '0');
    this.Calculate_gst('0', '0');
  }

  calculateHospitalGst(gst_click, concession) {
    this.billAddList = [];
    this.hospital_finalAmount = 0;
    this.hospital_totalBillAmount = 0;
    this.hospital_discount_amount = 0;
    this.hospital_gstAmount = 0;
    this.hospital_totalCollected = 0;
    this.hospital_gstDataList = [];

    if (this.billaddlist.length != 0) {
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (this.billaddlist[i].bill_type == 'hospital') {
          this.billAddList.push({
            sno: i + 1,
            bill_type: this.billaddlist[i].bill_type,
            biill_type: this.billaddlist[i].biill_type,
            amount_txt: parseFloat(this.billaddlist[i].amount_txt),
            created_date: this.billaddlist[i].created_date,
            created_time: this.billaddlist[i].created_time,
            concession: this.billaddlist[i].concession,
            fee: parseFloat(this.billaddlist[i].fee),
            charge_type: this.billaddlist[i].charge_type,
            paytype: this.billaddlist[i].paytype,
            pay_type: this.billaddlist[i].pay_type,
            insurer: this.billaddlist[i].insurer,
            quantity: this.billaddlist[i].quantity,
            total: this.billaddlist[i].total,
            edit_flag: this.billaddlist[i].edit_flag,
            category: this.billaddlist[i].category,
            charge_cat_id: this.billaddlist[i].charge_cat_id,
            order_no: this.billaddlist[i].order_no
          })
        }
      }
    }

    if (this.billAddList.length != 0) {
      for (var i = 0; i < this.billAddList.length; i++) {
        if (this.billAddList[i].fee == '') {
          this.billAddList[i].fee = 0;
        }
        if (this.billAddList[i].amount_txt == '') {
          this.billAddList[i].amount_txt = 0;
        }
        if (this.billAddList[i].quantity == '') {
          this.billAddList[i].quantity = 0;
        }
        if (this.billAddList[i].concession == '') {
          this.billAddList[i].concession = 0;
        }

        this.hospital_discount_amount = (
          parseFloat(this.hospital_discount_amount) +
          parseFloat(this.billAddList[i].amount_txt) *
          parseFloat(this.billAddList[i].quantity) *
          (parseFloat(this.billAddList[i].concession) / 100)
        );
        this.hospital_finalAmount = (
          parseFloat(this.hospital_finalAmount) + parseFloat(this.billAddList[i].fee)
        );

        this.hospital_totalBillAmount = (
          parseFloat(this.hospital_totalBillAmount) +
          parseFloat(this.billAddList[i].amount_txt) *
          parseFloat(this.billAddList[i].quantity)
        );
        this.hospital_gstAmount = (
          parseFloat(this.hospital_gstAmount) + parseFloat(this.billAddList[i].fee)
        );

        if (parseFloat(this.Advance_bill) < parseFloat(this.hospital_finalAmount)) {
          this.hospital_remainingBalance = (
            parseFloat(this.hospital_finalAmount) - parseFloat(this.Advance_bill)
          );
        }

        if (parseFloat(this.Advance_bill) > parseFloat(this.hospital_finalAmount)) {
          this.hospital_remainingBalance = (
            parseFloat(this.Advance_bill) - parseFloat(this.hospital_finalAmount)
          );
          this.hospital_totalCollected = 0;
        }
      }
      this.hospital_discount_amount = parseInt(this.hospital_discount_amount);
      if (this.inGST == false) {
        this.hospital_finalAmount = Math.round(parseFloat(this.hospital_finalAmount));
      }

      this.hospital_finalAmount = parseFloat(this.hospital_finalAmount);
    }

    for (var i = 0; i < this.billAddList.length; i++) {
      if (
        this.billAddList[i].fee == 0 &&
        this.billAddList[i].biill_type == ''
      ) {
        this.billAddList[i].fee = '';
      }
      if (this.billAddList[i].amount_txt == 0 && this.billAddList[i].biill_type == '') {
        this.billAddList[i].amount_txt = '';
      }
      if (this.billAddList[i].quantity == 0 && (this.billAddList[i].biill_type == '' || this.billAddList[i].biill_type != '')) {
        this.billAddList[i].quantity = '';
      }
      if (this.billAddList[i].total == 0 && this.billAddList[i].biill_type == '') {
        this.billAddList[i].total = '';
      }
      if (this.billAddList[i].biill_type == '') {
        this.billAddList[i].concession = '';
      }
    }

    if (this.inGST == true) {
      for (var i = 0; i < this.gst_retrive_list.length; i++) {
        this.hospital_gstDataList.push({
          Gst_Descrip:
            this.gst_retrive_list[i].tax_desc + ' ' + '(' + parseInt(this.gst_retrive_list[i].tax) + ' %' + ')',
          Gst_amount: Math.round(
            parseFloat(this.hospital_gstAmount) *
            (parseFloat(this.gst_retrive_list[i].tax) / 100)
          ),
        });

        this.hospital_finalAmount = (parseFloat(this.hospital_finalAmount) +
          Math.round(parseFloat(this.hospital_gstAmount) *
            (parseFloat(this.gst_retrive_list[i].tax) / 100)));

        this.hospital_totalBillAmount =
          parseFloat(this.hospital_totalBillAmount) +
          Math.round(parseFloat(this.hospital_gstAmount) *
            (parseFloat(this.gst_retrive_list[i].tax) / 100));
      }
      this.hospital_finalAmount = parseFloat(this.hospital_finalAmount);
      this.hospital_totalBillAmount = parseFloat(this.hospital_totalBillAmount);
    }

    if (this.balance_data != undefined) {
      this.hospital_finalAmount = (
        parseFloat(this.hospital_finalAmount) + parseFloat(this.balance_data)
      );
      this.hospital_totalBillAmount = (
        parseFloat(this.hospital_totalBillAmount) + parseFloat(this.balance_data)
      );
    }

    //amount to be collected
    this.hospital_remainingBalance = (
      parseFloat(this.hospital_finalAmount) - parseFloat(this.Advance_bill)
    );
    if (this.estimate_id.length == 0 && this.billAddList.length == 0) {
      this.inGST = false;
      this.hospital_gstDataList = [];
      // this.Advance_bill = 0;
      this.hospital_finalAmount = 0;
      this.hospital_totalBillAmount = 0;
      this.hospital_gstAmount = 0;
      this.hospital_totalCollected = 0;
      this.hospital_remainingBalance = 0;
    }

    // if (parseFloat(this.Advance_bill) > parseFloat(this.hospital_finalAmount)) {
    //   this.hospital_remainingBalance = 0;
    // }

    console.log("HospitalTotalAmount", this.hospital_totalBillAmount);
    console.log("HospitalDiscountAmount", this.hospital_discount_amount);
    console.log("HospitalFinalAmount", this.hospital_finalAmount);
    console.log("HospitalRemainingBalance", this.hospital_remainingBalance);
    console.log("HospitalGstDataList", this.hospital_gstDataList);
    this.getAllBills("0");
  }

  paidCalculation() {
    var temp_paidBill: any;
    temp_paidBill = parseFloat(this.paid_bill) + parseFloat(this.Advance_bill); //parseFloat(this.Advance_bill)
    console.log("Temp_PaidBill", temp_paidBill);
    if (this.billAddList != undefined && this.billAddList.length != 0 && this.hospital_finalAmount != 0) {
      if (temp_paidBill != '') {
        if (temp_paidBill >= this.hospital_finalAmount) {
          this.hospital_paidBill = this.hospital_finalAmount;
          this.hospital_remainingBalance = 0;
          temp_paidBill = (parseFloat(temp_paidBill) - parseFloat(this.hospital_finalAmount));
        } else {
          this.hospital_paidBill = parseFloat(temp_paidBill);
          this.hospital_remainingBalance = (parseFloat(this.hospital_finalAmount) - parseFloat(temp_paidBill));
          temp_paidBill = 0;
        }
      }
      console.log('temp_paidBill', temp_paidBill);
      console.log("HospitalPaidBill", this.hospital_paidBill);
      console.log("HospitalRemainingBalance", this.hospital_remainingBalance);
    }
  }

  paymentArrayCalculation(paid_amount) {
    this.return_paymentsarray = [];
    this.return_pay_arrry_tot_amt = 0;
    this.returnEqualSplitValue = 0;
    if (this.paid_bill != '' && this.paid_bill != 0 ) {
      if (paid_amount != '' && paid_amount != 0) {
        for (var i = 0; i < this.local_payment_array.length; i++) {
          if (this.local_payment_array[i].amount != 0) {
            if (parseInt(paid_amount) != parseInt(this.paid_bill)) {
              this.return_paymentsarray.push({
                pay_id: this.local_payment_array[i].pay_id,
                pay_desc: this.local_payment_array[i].pay_desc,               
                amount: Math.round((paid_amount / this.paid_bill) * parseFloat(this.local_payment_array[i].amount))
              })
            } else {
              this.return_paymentsarray.push({
                pay_id: this.local_payment_array[i].pay_id,
                pay_desc: this.local_payment_array[i].pay_desc,               
                amount: Math.round(parseFloat(this.local_payment_array[i].amount))
              })
            }
            
          }
        }  
        
        if (this.return_paymentsarray.length != 0) {
          for (var i = 0; i < this.return_paymentsarray.length; i++) {
            this.return_pay_arrry_tot_amt = parseInt(this.return_pay_arrry_tot_amt) + parseInt(this.return_paymentsarray[i].amount);
          }                   
          if (parseInt(this.return_pay_arrry_tot_amt) < parseInt(paid_amount)) {
            this.returnEqualSplitValue = ((parseFloat(paid_amount) - parseFloat(this.return_pay_arrry_tot_amt)) / this.return_paymentsarray.length);              
            for (var i = 0; i < this.return_paymentsarray.length; i++) {
              this.return_paymentsarray[i].amount = (parseFloat(this.return_paymentsarray[i].amount) + parseFloat(this.returnEqualSplitValue));                
            }
          } else if (parseInt(this.return_pay_arrry_tot_amt) > parseInt(paid_amount)) {
            this.hospitalEqualSplitValue = ((parseFloat(this.return_pay_arrry_tot_amt) - parseFloat(paid_amount)) / this.return_paymentsarray.length);               
            for (var i = 0; i < this.return_paymentsarray.length; i++) {
              this.return_paymentsarray[i].amount = (parseFloat(this.return_paymentsarray[i].amount) - parseFloat(this.returnEqualSplitValue));
            }
          }
        }
        console.log('return_paymentsarray',this.return_paymentsarray);
        return this.return_paymentsarray;
      }
    } 
    console.log(console.log('return_paymentsarray',this.return_paymentsarray));
    console.log("local_payment_array---",this.local_payment_array);
    if (this.return_paymentsarray.length != 0 && this.local_payment_array.length != 0) {
      for (var r = 0; r < this.return_paymentsarray.length; r++) {
        for (var p = 0; p < this.local_payment_array.length; p++) {
          if (this.local_payment_array[p].pay_id === this.return_paymentsarray[r].pay_id && parseInt(this.local_payment_array[p].amount) != 0 && parseInt(this.return_paymentsarray[r].amount) != 0) { 
            this.local_payment_array[p].amount = parseInt(this.local_payment_array[p].amount) - parseInt(this.return_paymentsarray[r].amount);
          }
        }
      }
    }
    console.log("local_payment_array---",this.local_payment_array);
  }

  Calculate_gst(gst_click, num) {
    console.log(this.billaddlist);
    this.final_amount = 0;
    this.totalBillAmount = 0;
    if (num != 1) {
      this.discount_amount = 0;
    }
    this.gst_amount_txt = 0;
    this.total_collected = 0;
    this.current_amount = 0;
    this.gst_data_list = [];

    if (this.billden_plan.length != 0) {
      for (var i = 0; i < this.billden_plan.length; i++) {
        for (var j = 0; j < this.estimate_id.length; j++) {
          if (
            this.estimate_id[j] == this.billden_plan[i].den_treatment_plan_id
          ) {
            this.current_amount = (
              parseFloat(this.current_amount) +
              parseFloat(this.billden_plan[i].estimate)
            );
            this.final_amount = (
              parseFloat(this.final_amount) +
              parseFloat(this.billden_plan[i].estimate)
            );
            this.totalBillAmount = (
              parseFloat(this.final_amount) +
              parseFloat(this.billden_plan[i].estimate)
            );
            this.gst_amount_txt = (
              parseFloat(this.gst_amount_txt) +
              parseFloat(this.billden_plan[i].estimate)
            );

            if (parseFloat(this.Advance_bill) < parseFloat(this.final_amount)) {
              this.balance_show = false;
              this.total_collected = (
                parseFloat(this.final_amount) - parseFloat(this.Advance_bill)
              );
              this.remaing_billbal = (
                parseInt(this.final_amount) - parseInt(this.Advance_bill)
              );
            }
            if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
              this.balance_show = false;
              this.remaing_billbal = (
                parseFloat(this.Advance_bill) - parseFloat(this.final_amount)
              );
            }

            this.amount_collected = true;
            if (this.total_collected == 0) {
              this.amount_collected = false;
            }
            if (this.remaing_billbal == 0) {
              this.balance_show = true;
            }
            break;
          }
        }
      }
    }

    if (this.pre_bill_amount != undefined && this.inpatientEdit == true) {

      this.pre_bill_amount = (
        parseInt(this.pre_bill_amount) +
        parseInt(this.pharmaPaid) +
        parseInt(this.diagPaid)
      );
      this.current_amount = (
        parseFloat(this.current_amount) + parseFloat(this.pre_bill_amount)
      );
      this.final_amount = (
        parseFloat(this.final_amount) + parseFloat(this.pre_bill_amount)
      );
      this.totalBillAmount = (
        parseFloat(this.totalBillAmount) + parseFloat(this.pre_bill_amount)
      );
      this.gst_amount_txt = (
        parseFloat(this.gst_amount_txt) + parseFloat(this.pre_bill_amount)
      );
      this.remaing_billbal = 0;
      this.paid_bill =
        this.Advance_bill != undefined
          ? (
            parseFloat(this.pre_bill_amount) - parseFloat(this.Advance_bill)
          )
          : parseFloat(this.pre_bill_amount);

      if (this.total_collected == 0) {
        this.amount_collected = false;
      }

      if (this.remaing_billbal == 0) {
        this.balance_show = true;
      }
    } else {
      var diag_bill_amount: any = 0,pharma_bill_amount: any = 0;
      if (this.diagnosis_bills.length != 0) {
        for (var d = 0; d < this.diagnosis_bills.length; d++) {
          if (this.diagnosis_bills[d].bill_amount != undefined && this.diagnosis_bills[d].bill_amount != "") {
            diag_bill_amount = parseFloat(diag_bill_amount) + parseFloat(this.diagnosis_bills[d].bill_amount);
          }
        }
      }
      if (this.pharma_bills.length != 0) {
        for (var p = 0; p < this.pharma_bills.length; p++) {
          if (this.pharma_bills[p].bill_amount != undefined && this.pharma_bills[p].bill_amount != "") {
            pharma_bill_amount = parseFloat(pharma_bill_amount) + parseFloat(this.pharma_bills[p].bill_amount);
          }
        }
      }
      console.log("diag_bill_amount---",diag_bill_amount ,"pharma_bill_amount--- ",pharma_bill_amount);
      console.log("Else Called");
      console.log(this.billaddlist);
      if (this.billaddlist.length != 0) {
        for (var i = 0; i < this.billaddlist.length; i++) {
          if (this.billaddlist[i].amount_txt == '') {
            this.billaddlist[i].amount_txt = 0;
          }
          if (this.billaddlist[i].quantity == '') {
            this.billaddlist[i].quantity = 0;
          }
          if (this.billaddlist[i].concession == '') {
            this.billaddlist[i].concession = 0;
          }


          // if (num != 1) {
          //   this.discount_amount = (
          //     parseFloat(this.discount_amount) +
          //     parseFloat(this.billaddlist[i].amount_txt) *
          //     parseFloat(this.billaddlist[i].quantity) *
          //     (parseInt(this.billaddlist[i].concession) / 100)
          //   );

          // }
          if (this.billaddlist[i].bill_type == 'hospital') {
            this.totalBillAmount = (parseFloat(this.totalBillAmount) + parseFloat(this.billaddlist[i].fee));
          }
        }
        this.totalBillAmount = parseFloat(this.totalBillAmount) + parseFloat(diag_bill_amount) + parseFloat(pharma_bill_amount);
        console.log("totalBillAmount",this.totalBillAmount);
        if (this.wardName == 'Dialysis' && this.insurance_flag) {
          this.sponsorDue = parseInt(this.totalBillAmount);
          this.sponsorDueflag = true;
        }
        console.log("sponsorDue",this.sponsorDue);
        this.current_amount = Math.round(parseFloat(this.current_amount) + parseFloat(this.hospitalBills));
        this.current_amount = parseFloat(this.current_amount);
        // if (this.discount_amount != '' && this.discount_amount != 0) {
        //   var floatValue = this.discount_amount;
        //   var floatString = floatValue.toString();
        //   var parts = floatString.split('.');
        //   var digitsAfterDecimal = parts[1] ? parts[1] : 0;
        //   if (digitsAfterDecimal[0] > 5) {
        //     this.discount_amount = Math.round(this.discount_amount);
        //     this.discount_amount = parseInt(this.discount_amount);
        //   } else {
        //     this.discount_amount = parseInt(this.discount_amount);
        //   }
        // }

        if (this.inGST == false) {
          this.final_amount = Math.round(parseFloat(this.final_amount) + parseFloat(this.hospitalBills));
        } else {
          this.final_amount = (
            parseFloat(this.final_amount) + parseFloat(this.hospitalBills)
          );
        }
        this.final_amount = parseFloat(this.final_amount);
        this.gst_amount_txt = (
          parseFloat(this.gst_amount_txt) + parseFloat(this.hospitalBills)
        );

        if (parseFloat(this.Advance_bill) < parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (
            parseFloat(this.final_amount) - parseFloat(this.Advance_bill)
          );
        }

        if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (
            parseFloat(this.Advance_bill) - parseFloat(this.final_amount)
          );
          this.total_collected = 0;
        }
        if (this.sponsorDue != undefined) {
          this.remaing_billbal = parseFloat(this.remaing_billbal) - parseFloat(this.sponsorDue);
        }

        if (this.total_collected == 0) {
          this.amount_collected = false;
        }

        if (this.remaing_billbal == 0) {
          this.balance_show = true;
        }
      }
      if (this.medcalDetailsArray.length != 0) {
        this.current_amount = (
          parseFloat(this.current_amount) + parseFloat(this.medicinBal)
        );
        this.final_amount = (
          parseFloat(this.final_amount) + parseFloat(this.medicinBal)
        );
        this.totalBillAmount = (
          parseFloat(this.totalBillAmount) + parseFloat(this.medicinBal)
        );
        this.gst_amount_txt = (
          parseFloat(this.gst_amount_txt) + parseFloat(this.medicinBal)
        );
        if (parseFloat(this.Advance_bill) < parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (
            parseFloat(this.final_amount) - parseFloat(this.Advance_bill)
          );
        }

        if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (
            parseFloat(this.Advance_bill) - parseFloat(this.final_amount)
          );
          this.total_collected = 0;
        }
        if (this.sponsorDue != undefined) {
          this.remaing_billbal = parseFloat(this.remaing_billbal) - parseFloat(this.sponsorDue);
        }
        if (this.total_collected == 0) {
          this.amount_collected = false;
        }

        if (this.remaing_billbal == 0) {
          this.balance_show = true;
        }
      }
      if (this.digDetailsArray.length != 0) {
        this.current_amount = (
          parseFloat(this.current_amount) + parseFloat(this.diagnosisAmount)
        );
        this.final_amount = (
          parseFloat(this.final_amount) + parseFloat(this.diagnosisAmount)
        );
        this.totalBillAmount = (
          parseFloat(this.totalBillAmount) + parseFloat(this.diagnosisAmount)
        );
        this.gst_amount_txt = (
          parseFloat(this.gst_amount_txt) + parseFloat(this.diagnosisAmount)
        );
        if (parseFloat(this.Advance_bill) < parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (
            parseFloat(this.final_amount) - parseFloat(this.Advance_bill)
          );
        }

        if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
          this.balance_show = false;
          this.remaing_billbal = (
            parseFloat(this.Advance_bill) - parseFloat(this.final_amount)
          );
          this.total_collected = 0;
        }

        if (this.total_collected == 0) {
          this.amount_collected = false;
        }

        if (this.remaing_billbal == 0) {
          this.balance_show = true;
        }
      }
      for (var i = 0; i < this.billaddlist.length; i++) {
        if (this.billaddlist[i].fee == 0 && this.billaddlist[i].biill_type == '') {
          this.billaddlist[i].fee = '';
        }
        if (this.billaddlist[i].amount_txt == 0 && this.billaddlist[i].biill_type == '') {
          this.billaddlist[i].amount_txt = '';
        }
        if (this.billaddlist[i].quantity == 0 && (this.billaddlist[i].biill_type == '' || this.billaddlist[i].biill_type != '')) {
          this.billaddlist[i].quantity = '';
        }
        if (this.billaddlist[i].total == 0 && this.billaddlist[i].biill_type == '') {
          this.billaddlist[i].total = '';
        }
        if (this.billaddlist[i].biill_type == '') {
          this.billaddlist[i].concession = '';
        }

        if (this.billaddlist[i].concession != '') {
          var floatValue = this.billaddlist[i].concession;
          var floatString = floatValue.toString();
          var parts = floatString.split('.');
          var digitsAfterDecimal = parts[1] ? parts[1].length : 0;

          if (digitsAfterDecimal === 2) {
          } else if (digitsAfterDecimal === 3) {
            this.billaddlist[i].concession = parseFloat(this.billaddlist[i].concession);
          } else {
          }
        }

      }
    }

    if (this.inGST == true) {
      for (var i = 0; i < this.gst_retrive_list.length; i++) {
        this.gst_data_list.push({
          Gst_Descrip:
            this.gst_retrive_list[i].tax_desc +
            ' ' + '(' +
            parseInt(this.gst_retrive_list[i].tax) + ' %' + ')',
          Gst_amount: Math.round(
            parseFloat(this.gst_amount_txt) *
            (parseFloat(this.gst_retrive_list[i].tax) / 100)
          ),
        });
        this.final_amount = (parseFloat(this.final_amount) +
          Math.round(parseFloat(this.gst_amount_txt) *
            (parseFloat(this.gst_retrive_list[i].tax) / 100)));


        this.totalBillAmount =
          parseFloat(this.totalBillAmount) +
          Math.round(parseFloat(this.gst_amount_txt) *
            (parseFloat(this.gst_retrive_list[i].tax) / 100));
      }
      this.final_amount = Math.round(parseFloat(this.final_amount));
      this.totalBillAmount = parseFloat(this.totalBillAmount);
    }

    if (
      this.estimate_id.length == 0 &&
      this.billaddlist.length == 0 &&
      this.medcalDetailsArray.length == 0 &&
      this.digDetailsArray.length == 0
    ) {
      this.inGST = false;
      this.gst_data_list = [];
      this.final_amount = 0;
      this.discount_amount = 0;
      this.totalBillAmount = 0;
      this.gst_amount_txt = 0;
      this.total_collected = 0;
      this.remaing_billbal = 0;
    }

    // if (parseFloat(this.Advance_bill) > parseFloat(this.final_amount)) {
    //   this.remaing_billbal = 0;
    // }
    console.log(this.final_amount);
    console.log(this.remaing_billbal);
  }

  hospital_save_bill() {
    this.Get_current_date();
    if (!this.adv_refflag) {
      this.allBillPaymentsArray();
    } 
    console.log(this.paymentsarray);
    var flag = true;
    if (this.adv_refflag && !this.settled) {
      flag = false;
      this.selectedValue = "settled";
      this.billType('settled');
      this.refundcal();
      this.toastr.error("Please settle the refund bill");
    } else if (this.adv_refflag && this.settled) {
      flag = true;
      console.log("this.adv_refflag  ",this.adv_refflag,"this.settled  ",this.settled)
    }

    if (this.settled == true && this.adv_refflag == false && flag) {
      if (parseInt(this.remaing_billbal) == 0 || parseFloat(this.remaing_billbal) == 0 || this.remaing_billbal == "0") {
        flag = true;
      } else if (parseFloat(this.remaing_billbal) != 0 || this.remaing_billbal != 0) {
        flag = false;
        this.toastr.error("Please settle the bill");
      } 
    } else if (this.settled == true && this.adv_refflag == true && flag) {
      var total_refund_amount: any = 0;
      if (parseInt(this.remaing_billbal) != 0 && this.remaing_billbal != "") {
        if (this.paymentsarray.length == 0) {
          this.toastr.error("Please Check the payment details");
          flag = false;
        } else if (this.paymentsarray.length != 0) {
          for (var p = 0; p < this.paymentsarray.length; p++) {
            total_refund_amount = parseFloat(total_refund_amount) + parseFloat(this.paymentsarray[p].amount);
          }
          console.log("this.paidBill  ",parseFloat(this.remaing_billbal)+ "   " + "total_refund_amount  ",parseFloat(total_refund_amount));
          total_refund_amount = Math.round(total_refund_amount);
          console.log("total_refund_amount",total_refund_amount);
          if (parseFloat(this.remaing_billbal) != parseFloat(total_refund_amount)) {
            this.toastr.error("Please Check the payment details");
            flag = false;
          }
        } else {
          console.log("this.total_refund_amount  ",parseFloat(this.remaing_billbal)+ "   " + "total_refund_amount  ",parseFloat(total_refund_amount));
          flag = true;
        }
      }
    }
    var total_paid_amount: any = 0;
    if (parseInt(this.paid_bill) != 0 && this.paid_bill != "" && !this.adv_refflag) {
      if (this.paymentsarray.length == 0) {
        this.toastr.error("Please Check the payment details");
        flag = false;
      } else if (this.paymentsarray.length != 0) {
        for (var p = 0; p < this.paymentsarray.length; p++) {
          total_paid_amount = parseFloat(total_paid_amount) + parseFloat(this.paymentsarray[p].amount);
        }
        console.log("this.paidBill  ",parseFloat(this.paid_bill)+ "   " + "total_paid_amount  ",parseFloat(total_paid_amount));
        if (parseFloat(this.paid_bill) != parseFloat(total_paid_amount)) {
          this.toastr.error("Please Check the payment details");
          flag = false;
        }
      } else {
        console.log("this.paidBill  ",parseFloat(this.paid_bill)+ "   " + "total_paid_amount  ",parseFloat(total_paid_amount));
        flag = true;
      }
    }

    var paytype;
    var instype;
    var patientname = this.appoint_txt.split('-');

    if (this.billAddList != undefined && this.billAddList.length != 0) {
      for (var i = 0; i < this.billAddList.length; i++) {
        if (
          this.billAddList[i].biill_type == '' &&
          this.billAddList[i].amount_txt == ''
        ) {
          this.billAddList.splice(i, 1);
        }
      }

      this.rowid = 1;
      for (var i = 0; i < this.billAddList.length; i++) {
        if (this.billAddList[i].biill_type == '') {
          this.toastr.error("Remove the empty charge type rows");
          flag = false;
          break;
        }
        this.billAddList[i].sno = this.rowid;
        this.rowid += 1;
      }
    }

    if (this.billAddList.length == 0 && this.estimate_id.length == 0) {
      this.toastr.error(Message_data.getAddBill());
      flag = false;
    } else {
      for (var i = 0; i < this.billAddList.length; i++) {
        paytype = this.billAddList[i].paytype;
        instype = this.billAddList[i].insurer;
        if (this.billAddList[i].quantity == '') {
          this.billAddList[i].quantity = 0;
        }
        if (this.billAddList[i].biill_type != '' && (this.billAddList[i].amount_txt == 0 || this.billAddList[i].amount_txt == 0 || this.billAddList[i].amount_txt == '0')) {
          this.toastr.error('Service charge cannot be zero');
          flag = false;
          break;
        }
      }
    }
    paytype = this.pay_type;
    if (flag == true) {
      if (this.billing_print_array.length != 0) {
        for (var i = 0; i < this.billing_print_array.length; i++) {
          this.client_reg_id = this.billing_print_array[i].client_reg_id;
          this.relation_id = this.billing_print_array[i].relation_id;
          if (this.billing_print_array[i].sub_rel_id != undefined) {
            this.sub_rel_id = this.billing_print_array[i].sub_rel_id;
          }
        }
      }

      var bill_time;
      if (this.bill_time1 != undefined && this.bill_time2 != undefined) {
        bill_time = ConvertTimeformat(
          '',
          this.bill_time1 + ':' + this.bill_time2 + ' ' + this.bill_time3
        );
      }

      this.addvancetxt =
        this.Advance_bill != undefined ? this.Advance_bill : 0;
      this.totalcolletxt =
        this.total_collected != undefined ? this.total_collected : 0;

      if (this.inGST == true && this.gst_retrive_list.length != 0) {
        this.cent_gst = this.gst_retrive_list[0].tax;
        this.sta_gst = this.gst_retrive_list[1].tax;
      }
      if (this.estimate_id.length != 0) {
        var estimateid = this.estimate_id;
      }

      var doctp_id = Helper_Class.getInfo().user_id;

      var cbill;
      var credit_person;
      if (this.credit_bill == true) {
        cbill = this.credit_type;
        credit_person = this.credit_person;
      } else {
        this.credit_type = '';
        this.credit_person = '';
      }

      if (this.addvancetxt != undefined) {
        this.hospital_remainingBalance = parseFloat(this.hospital_remainingBalance) + parseFloat(this.addvancetxt);
      }

      var advance;
      var totalAdvance;

      if (parseInt(this.addvancetxt) != 0) {
        advance = parseFloat(this.addvancetxt);
        totalAdvance = 0;
      }

      this.sendAdvanceAmount =
        totalAdvance != undefined ? totalAdvance : 0;

      var balance = parseFloat(this.hospital_finalAmount);
      
      var send_status,status;
      if (this.settled) {
        send_status = this.status;
      } else if (this.finalized) {
        send_status = this.status;
      } else if (this.pending) {
        send_status = this.status;
      } else {
        status = parseInt(this.hospital_remainingBalance) <= 0 ? '0' : '1';
        send_status = status;
      }

      var temp_discount_amount = parseFloat(this.hospital_discount_amount).toFixed(2);
      var temp_final_amount = parseFloat(this.hospital_finalAmount).toFixed(2);
      var paid_flag = "Un paid";

      var finalAmount;
      if (parseInt(this.hospital_finalAmount) == 0) {
        finalAmount = encrypt_decript.Encript(temp_discount_amount).toString();
      } else {
        finalAmount = encrypt_decript.Encript(temp_final_amount).toString();
      }

      if (
        this.sub_rel_id != null &&
        this.sub_rel_id != undefined &&
        this.sub_rel_id != '' &&
        this.sub_rel_id.length != 0
      ) {
        this.save_billcreate = JSON.stringify({
          appointment_id: parseFloat(patientname).toString(),
          app_type: 'doctor',
          hptl_clinic_id: this.hptl_clinic_id.toString(),
          doc_reg_id: this.dog_reg_id.toString(),
          bill_amount: finalAmount,
          created_by: this.user_id,
          bills: this.billAddList,
          country: ipaddress.country_code.toString(),
          estimates: estimateid,
          client_reg_id: this.client_reg_id,
          relation_id: this.relation_id.toString(),
          sub_rel_id: this.sub_rel_id,
          advance_balance: advance,
          advance: totalAdvance,
          balance: balance,
          round_off: this.round_off.toString(),
          cgst: this.cent_gst,
          sgst: this.sta_gst,
          prev_bal: this.balance_data,
          prev_bill_id: this.prev_bill_id,
          pay_type: paytype.toString(),
          insurer: instype,
          card_no: this.cardNumber,
          card_holder_name: this.cardHolderName,
          transaction_no: this.transactionId,
          bank_name: this.bankName,
          userid: doctp_id,
          paid_flag: paid_flag,
          paid_amount: this.hospital_paidBill,
          bill_date: this.bill_date,
          bill_time: bill_time,
          credit_type: cbill,
          credit_person: credit_person,
          payments: this.hospital_paymentsarray,
          // rate_card: this.rate_card,
          status: send_status,
          discount: this.hospital_discount_amount,
          open_app_billing: this.open_app_billing,
          bill_type:"IP"
        });
      } else {
        this.save_billcreate = JSON.stringify({
          appointment_id: parseFloat(patientname).toString(),
          app_type: 'doctor',
          hptl_clinic_id: this.hptl_clinic_id,
          doc_reg_id: this.dog_reg_id,
          bill_amount: finalAmount,
          created_by: this.user_id,
          bills: this.billAddList,
          country: ipaddress.country_code,
          estimates: estimateid,
          client_reg_id: this.client_reg_id,
          relation_id: this.relation_id,
          advance_balance: advance,
          advance: totalAdvance,
          balance: balance,
          round_off: this.round_off.toString(),
          cgst: this.cent_gst,
          sgst: this.sta_gst,
          prev_bal: this.balance_data,
          prev_bill_id: this.prev_bill_id,
          pay_type: paytype,
          insurer: instype,
          card_no: this.cardNumber,
          card_holder_name: this.cardHolderName,
          transaction_no: this.transactionId,
          bank_name: this.bankName,
          userid: doctp_id,
          paid_flag: paid_flag,
          paid_amount: this.hospital_paidBill,
          bill_date: this.bill_date,
          bill_time: bill_time,
          credit_type: cbill,
          credit_person: credit_person,
          payments: this.hospital_paymentsarray,
          // rate_card: this.rate_card,
          status: send_status,
          discount: this.hospital_discount_amount,
          open_app_billing: this.open_app_billing,
          bill_type:"IP"
        });
      }
      Doc_Helper.setbillid_create(this.billAddList);
      console.log(this.save_billcreate);
      // this.allBillSave();
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http
        .post(
          ipaddress.getIp.toString() + this.saveBillURL,
          this.save_billcreate,
          { headers: headers }
        )
        .subscribe(
          (response) => {
            var obj = response.json();
            console.log(obj);
            if (obj.result != null) {
              if (obj.key == '1') {
                this.doctorBills = [];
                this.billId = obj.bill_no;
                console.log(this.billId);
                if (this.sendDoctor.length != 0) {
                  this.sendDoctor[0].billing_id = this.billId;
                }
                console.log("sendDoctor", this.sendDoctor);
                this.allBillSave();
                // setTimeout(()=>{
                //   this.print_area1();
                // },1000);
              } else {
                this.toastr.error(Message_data.getBillUngen());
              }
            }
          },
          (error) => {
            this.show_save_btn = true;
          }
        );
    } else {
      this.show_save_btn = true;
    }
  }

  //add the bill details
  advance_change() {
    this.Advance_bill = this.Advance_bill == '' ? 0 : this.Advance_bill;
    this.remaing_billbal = (
      parseFloat(this.final_amount) -
      parseFloat(this.round_off) -
      (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))
    );
  }

  addRound(data) {
    this.sign = data;
    this.round_off = this.round_off == '' ? 0 : this.round_off;
    if (data == 'puls') {
      this.remaing_billbal = (
        parseFloat(this.final_amount) +
        parseFloat(this.round_off) -
        (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))
      );
    } else {
      this.remaing_billbal = (
        parseFloat(this.final_amount) -
        parseFloat(this.round_off) -
        (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))
      );
    }
    this.tempRoundoff = this.round_off;
  }

  paid_change() {
    if (this.paymentsarray.length == 0) {
      this.payType1 = this.paytypelist[0].pay_id;
    } else {
      this.paymentsarray_total = 0;
      for (var i = 0; i < this.paymentsarray.length; i++) {
        this.paymentsarray_total = (parseFloat(this.paymentsarray_total) + parseFloat(this.paymentsarray[i].amount));
      }
      if (this.paymentsarray_total > this.final_amount) {
        this.paymentsarray = [];
        this.payType1 = this.paytypelist[0].pay_id;
      }
    }
    this.paid_bill = this.paid_bill == '' ? 0 : this.paid_bill;
    this.remaing_billbal = (
      parseFloat(this.final_amount) -
      parseFloat(this.round_off) -
      (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))
    );
    if (parseFloat(this.paid_bill) > this.final_amount) {
      this.amountval = parseFloat(this.final_amount);
      this.numToWords = convertNumberToWords(
        this.final_amount,
        ipaddress.country_code
      );
    } else {
      this.amountval = parseFloat(this.paid_bill);
      this.numToWords = convertNumberToWords(
        this.paid_bill,
        ipaddress.country_code
      );
    }

    if (this.paid_bill != undefined) {
      if (parseFloat(this.paid_bill) > parseFloat(this.final_amount)) {
        if (this.paymentsarray.length == 0) {
          this.amountval = parseFloat(this.final_amount);
        } else {
          var payArr_Amt = 0;
          for (var i = 0; i < this.paymentsarray.length; i++) {
            payArr_Amt += parseFloat(this.paymentsarray[i].amount);
          }

          if (payArr_Amt > parseFloat(this.final_amount)) {
            this.amountval = parseFloat(this.final_amount);
          } else {
            this.amountval = (parseFloat(this.final_amount) - payArr_Amt);
          }
        }
      } else {
        if (this.paymentsarray.length == 0) {
          this.amountval = parseFloat(this.paid_bill);
        } else {
          var payArr_Amt = 0;
          for (var i = 0; i < this.paymentsarray.length; i++) {
            payArr_Amt += parseFloat(this.paymentsarray[i].amount);
          }

          if (payArr_Amt > parseFloat(this.paid_bill)) {
            this.amountval = parseFloat(this.paid_bill);
          } else {
            this.amountval = (parseFloat(this.paid_bill) - payArr_Amt);
          }
        }
      }
    } else {
      this.paid_bill = 0;
      this.remaing_billbal = this.final_amount;
    }
  }

  //Bill click
  bill_click(planid, planmodal, rowid) {
    if (this.estimate_id.length != 0) {
      if (this.estimate_id.indexOf(planid) > -1) {
        for (var i = 0; i < this.estimate_id.length; i++) {
          if (this.estimate_id[i] == planid) {
            this.estimate_id.splice(i, 1);
            this.Calculate_gst('0', '0');
          }
        }
      } else {
        this.estimate_id.push(planid);
        this.Calculate_gst('0', '0');
      }
    } else {
      this.estimate_id.push(planid);
      this.Calculate_gst('0', '0');
    }

    for (var i = 0; i < this.billden_plan.length; i++) {
      for (var j = 0; j < this.estimate_id.length; j++) {
        if (this.estimate_id[j] == this.billden_plan[i].den_treatment_plan_id) {
          this.billden_plan[i].checked = true;
        }
      }
    }
  }

  flag_function() {
    this.inpatient_flag = true;
    if (this.bill_qty_flag == true && !this.discount_flag == false && this.inpatient_flag == true) {
      this.multiply_flag = false;
    } else if (this.bill_qty_flag == true && !this.discount_flag == true && this.inpatient_flag == true) {
      this.multiply_flag = true;
    } else if (this.bill_qty_flag == false && !this.discount_flag == false && this.inpatient_flag == true) {
      this.multiply_flag = false;
    } else if (this.bill_qty_flag == false && !this.discount_flag == true && this.inpatient_flag == true) {
      this.multiply_flag = true;
    }

    if (this.bill_qty_flag == true && this.discount_flag == true && !this.inpatient_flag) {
      this.amount_td_style = "text-align: right; border: 1px solid black;border-collapse: collapse;width: 300px";
    } else {
      this.amount_td_style = "text-align: right; border: 1px solid black;border-collapse: collapse;width: 150px;";
    }
  }

  allBillSave() {  
    var patientname = this.appoint_txt.split('-');
    var flag = true;
    if (this.sendDoctor.length == 0 && this.sendPharmacy.length == 0 && this.sendDiagnosis.length == 0) {
      this.toastr.error("Select atleast one bill type");
      flag = false;
    } 
    if (this.adv_refflag == true) {
      this.advanceRefundCalculation();
    }
    console.log("sendDiagnosis",this.sendDiagnosis);
    this.package = [];
    if (this.sendDiagnosis.length != 0) {
      for (var d = 0; d < this.sendDiagnosis.length; d++) {
        if (this.sendDiagnosis[d].sub_type != undefined && this.sendDiagnosis[d].sub_type == 'package') {
          this.package.push({
            client_reg_id: this.client_reg_id,
            hosp_id: this.hosp_id,
            billing_id: this.sendDiagnosis[d].billing_id,
            bill_amount: this.sendDiagnosis[d].bill_amount,
            paid_amount: this.sendDiagnosis[d].paid_amount,
            balance: this.sendDiagnosis[d].balance,
            paid_flag: this.sendDiagnosis[d].paid_flag,
            advance: this.sendDiagnosis[d].advance,
            sponsor_due: this.sendDiagnosis[d].sponsor_due,
            bill_date: this.sendDiagnosis[d].bill_date,
            fin_year: this.sendDiagnosis[d].fin_year,
            paytypes: this.sendDiagnosis[d].paytypes
          })
        }
      }

      for (var d = 0; d < this.sendDiagnosis.length; d++) {
        if (this.sendDiagnosis[d].sub_type != undefined && this.sendDiagnosis[d].sub_type == 'package') {
          this.sendDiagnosis.splice(d,1);
          d--;
        }
      }
      console.log("package",this.package);
    }
    console.log("sendDiagnosis",this.sendDiagnosis);
    var advance;
    if (parseFloat(this.final_amount) == (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))) {
      advance = "0.00";
    } else if (parseFloat(this.final_amount) < parseFloat(this.Advance_bill)) {
      advance = ((parseFloat(this.Advance_bill) - parseFloat(this.final_amount))).toFixed(2);
    } else {
      advance = "0.00";
    }

    if (flag == true) {
      console.log("Doctor bills " + JSON.stringify(this.sendDoctor));
      console.log("PHARMA BILLS " + JSON.stringify(this.sendPharmacy));
      console.log("DIAGNOSIS BILLS " + JSON.stringify(this.sendDiagnosis));
      var send_status,status;
      if (this.settled) {
        send_status = this.status;
      } else if (this.finalized) {
        send_status = this.status;
      } else if (this.pending) {
        send_status = this.status;
      } else {
        status = parseInt(this.remaing_billbal) <= 0 ? '0' : '1';
        send_status = status;
      }
      var sendData: any = {
        client_reg_id: this.client_reg_id,
        advance_balance: advance,
        hptl_clinic_id: this.hptl_clinic_id,
        appointment_id: parseFloat(patientname).toString(),
        pharma: this.sendPharmacy,
        diagnosis: this.sendDiagnosis,
        doctor: this.sendDoctor,
        created_by: this.user_id,
        creator_name:this.userinfo.first_name+" "+this.userinfo.last_name,
        status: send_status
      }
      var neft_flag = false;
      if (this.paymentsarray.length != 0) {
        for (var i = 0; i < this.paymentsarray.length; i++) {
          if (this.paymentsarray[i].pay_desc == 'NEFT') {
            neft_flag = true;
            break;
          }
        }
      }

      if (this.adv_refflag == true) {
        sendData.ref_pay_id = this.paymentsarray[0].pay_id;
        if (neft_flag == true) {
          sendData.ref_account_no = this.acc_number_adv;
          sendData.ref_holder_name = this.holder_Name_adv;
          sendData.ref_bank_name = this.bank_Name_adv;
          sendData.ref_bank_ifc_code = this.bank_ifc_code_adv;
        } 
        sendData.refund_array = this.refund_array;
      }
      if (this.package.length != 0) {
        sendData.package = this.package;
      }

      console.log("send datanbjnb" + JSON.stringify(sendData));
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/ucb/', sendData,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log(obj);
            if (obj["result"] != null) {
              if (obj["key"] == "1") {
                this.toastr.success("Bill saved successfully");
                if (this.settled) {
                  setTimeout(()=>{
                    this.print_area1();
                  },1000);
                }              
                this.show_save_btn = false;
                this.backtoApplist('view');
              } else {
                this.toastr.error("Error in save bill");
              }
            }
          },
          error => {
            this.toastr.error(Message_data.getNetworkMessage());
          }
        )
    }
  }

  allBillSet(num) {
    this.totalPaidAmountvalue = "0";
    this.totalBalanceAmountvalue = "0";
    this.totalBillAmountvalue = "0.00";
    this.totalDiscountvalue = "0";
    this.total_advancevalue = "0";
    this.discount_rembalance = 0.00;
    this.remaing_billbal = 0;
    this.selectedAllFlage = true;
    if (this.selectedAllFlage == true) {
      if (this.docBills.length != 0) {
        this.sendDoctor = [];
        for (var k = 0; k < this.docBills.length; k++) {
          var flag;
          if (parseFloat(this.docBills[k].balance) == 0.00) {
            flag = "Fully paid";
          } else if (parseFloat(this.docBills[k].balance) == parseFloat(this.docBills[k].bill_amount)) {
            flag = "un paid";
          } else {
            flag = "Partially paid";
          }
          console.log("doc bills " + JSON.stringify(this.docBills))
          console.log("doc sendDoctor " + JSON.stringify(this.sendDoctor))
          this.sendDoctor.push({
            billing_id: this.docBills[k].billing_id,
            hptl_clinic_id: this.hptl_clinic_id,
            paid_amount: this.docBills[k].paid_amount,
            balance: this.docBills[k].balance,
            paid_flag: flag,
            advance: this.docBills[k].advance,
            sponsor_due: this.docBills[k].sponsor_due,
            discount: this.docBills[k].discount,
            temp_balance: this.docBills[k].balance,
            fin_year: this.docBills[k].fin_year,
            paidamountprint: this.docBills[k].paid_amount,
            bill_amount: this.docBills[k].bill_amount,
            discountprint: this.docBills[k].discount != undefined ? this.docBills[k].discount : "0.00",
            bill_date: this.docBills[k].bill_date,
            paytypes: this.paymentArrayCalculation(this.docBills[k].paid_amount)
          });
          this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.docBills[k].balance)).toFixed(2);
          this.totalDiscount = (parseFloat(this.totalDiscount) + parseFloat(this.docBills[k].discount)).toFixed(2);
          this.totalBill = (parseFloat(this.totalBill) + parseFloat(this.docBills[k].bill_amount)).toFixed(2);
          this.totalPaid = (parseFloat(this.totalPaid) + parseFloat(this.docBills[k].paid_amount)).toFixed(2);
        }
      }

    } else {
      this.sendDoctor = [];
      for (var k = 0; k < this.docBills.length; k++) {
        this.docBills[k].checked = this.selectedAllFlage;
        this.final_amount = (parseFloat(this.final_amount) - parseFloat(this.docBills[k].balance)).toFixed(2);
        this.totalDiscount = (parseFloat(this.totalDiscount) - parseFloat(this.docBills[k].discount)).toFixed(2);
        this.totalBill = (parseFloat(this.totalBill) - parseFloat(this.docBills[k].bill_amount)).toFixed(2);
        this.totalPaid = (parseFloat(this.totalPaid) - parseFloat(this.docBills[k].paid_amount)).toFixed(2);
      }
    }
    if (this.selectedAllFlage == true) {
      if (this.medBills.length != 0) {
        this.sendPharmacy = [];
        for (var k = 0; k < this.medBills.length; k++) {
          var flag;
          if (parseFloat(this.medBills[k].balance) == 0.00) {
            flag = "Fully paid";
          } else if (parseFloat(this.medBills[k].balance) == parseFloat(this.medBills[k].bill_amount)) {
            flag = "un paid";
          } else {
            flag = "Partially paid";
          }
          this.sendPharmacy.push({
            order_id: this.medBills[k].billing_id,
            pharma_id: this.medBills[k].pharma_id,
            paid_amount: this.medBills[k].paid_amount,
            balance: this.medBills[k].balance,
            paidamountprint: this.medBills[k].paid_amount,
            bill_amount: this.medBills[k].bill_amount,
            discountprint: this.medBills[k].discount != undefined ? this.medBills[k].discount : "0.00",
            paid_flag: flag,
            temp_balance: this.medBills[k].balance,
            fin_year: this.medBills[k].fin_year,
            advance: this.medBills[k].advance != undefined ? this.medBills[k].advance : "0.00",
            sponsor_due: this.medBills[k].sponsor_due,
            bill_date: this.medBills[k].bill_date,
            paytypes: this.paymentArrayCalculation(this.medBills[k].paid_amount)
          });
          this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.medBills[k].balance)).toFixed(2);
          this.mediBillAmount = (parseFloat(this.mediBillAmount) + parseFloat(this.medBills[k].bill_amount)).toFixed(2);
          this.totalDiscount = (parseFloat(this.totalDiscount) + parseFloat(this.medBills[k].discount)).toFixed(2);
          this.totalBill = (parseFloat(this.totalBill) + parseFloat(this.medBills[k].bill_amount)).toFixed(2);
          this.totalPaid = (parseFloat(this.totalPaid) + parseFloat(this.medBills[k].paid_amount)).toFixed(2);
        }
      }

    } else {
      this.sendPharmacy = [];
      for (var k = 0; k < this.medBills.length; k++) {
        this.medBills[k].checked = this.selectedAllFlage;
        this.final_amount = (parseFloat(this.final_amount) - parseFloat(this.medBills[k].balance)).toFixed(2);
        this.mediBillAmount = (parseFloat(this.mediBillAmount) - parseFloat(this.medBills[k].bill_amount)).toFixed(2);
        this.totalDiscount = (parseFloat(this.totalDiscount) - parseFloat(this.medBills[k].discount)).toFixed(2);
        this.totalBill = (parseFloat(this.totalBill) - parseFloat(this.medBills[k].bill_amount)).toFixed(2);
        this.totalPaid = (parseFloat(this.totalPaid) - parseFloat(this.medBills[k].paid_amount)).toFixed(2);
      }
    }
    console.log("diagBills", JSON.stringify(this.diagBills));
    if (this.selectedAllFlage == true) {
      if (this.diagBills.length != 0) {
        this.sendDiagnosis = [];
        var sno = 1;
        for (var k = 0; k < this.diagBills.length; k++) {
          var flag;
          if (parseFloat(this.diagBills[k].balance) == 0.00) {
            flag = "Fully paid";
          } else if (parseFloat(this.diagBills[k].balance) == parseFloat(this.diagBills[k].bill_amount)) {
            flag = "un paid";
          } else {
            flag = "Partially paid";
          }
          this.sendDiagnosis.push({
            billing_id: this.diagBills[k].billing_id,
            diag_centre_id: this.diag_centre_id,
            sub_type: this.diagBills[k].sub_type,
            paid_amount: this.diagBills[k].paid_amount,
            balance: this.diagBills[k].balance,
            paid_flag: flag,
            temp_balance: this.diagBills[k].balance,
            fin_year: this.diagBills[k].fin_year,
            advance: this.diagBills[k].advance != undefined ? this.diagBills[k].advance : "0.00",
            sponsor_due: this.diagBills[k].sponsor_due,
            paidamountprint: this.diagBills[k].paid_amount,
            bill_amount: this.diagBills[k].bill_amount,
            bill_date: this.diagBills[k].bill_date,
            discountprint: this.diagBills[k].discount != undefined ? this.diagBills[k].discount : "0.00",
            paytypes: this.paymentArrayCalculation(this.diagBills[k].paid_amount)
          });
          this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.diagBills[k].balance)).toFixed(2);
          this.totalBill = (parseFloat(this.totalBill) + parseFloat(this.diagBills[k].bill_amount)).toFixed(2);
          this.totalPaid = (parseFloat(this.totalPaid) + parseFloat(this.diagBills[k].paid_amount)).toFixed(2);
        }
      }

    } else {
      this.sendDiagnosis = [];
      for (var k = 0; k < this.diagBills.length; k++) {
        this.diagBills[k].checked = this.selectedAllFlage;
        this.final_amount = (parseFloat(this.final_amount) - parseFloat(this.diagBills[k].balance)).toFixed(2);
        this.totalBill = (parseFloat(this.totalBill) - parseFloat(this.diagBills[k].bill_amount)).toFixed(2);
        this.totalPaid = (parseFloat(this.totalPaid) - parseFloat(this.diagBills[k].paid_amount)).toFixed(2);
      }
    }
    this.combinedArray = [...this.sendDoctor, ...this.sendDiagnosis, ...this.sendPharmacy];
    this.remaing_billbal = this.totalBill;
    this.calculateCombinedValue();
    this.calculateAdvanceAmount(num);
  }  

  calculateCombinedValue() {
    this.refund_amount = 0;
    this.refund_reason = '';
    for (var item of this.combinedArray) {
      this.totalPaidAmountvalue = (parseFloat(this.totalPaidAmountvalue) + parseFloat(item.paid_amount)).toFixed(2);
      this.totalBalanceAmountvalue = (parseFloat(this.totalBalanceAmountvalue) + parseFloat(item.balance)).toFixed(2);
      this.totalBillAmountvalue = (parseFloat(this.totalBillAmountvalue) + parseFloat(item.bill_amount)).toFixed(2);
      this.totalDiscountvalue = (parseFloat(this.totalDiscountvalue) + parseFloat(item.discountprint)).toFixed(2);
      this.total_advancevalue = (parseFloat(this.total_advancevalue) + parseFloat(item.advance)).toFixed(2);
    }

    // Calculate paid_bill
    this.temppaid_bill = (parseFloat(this.totalPaidAmountvalue) - parseFloat(this.total_advancevalue)).toFixed(2);
    this.paid_bill = parseFloat(this.totalPaidAmountvalue).toFixed(2);
    this.amountval = parseFloat(this.paid_bill).toFixed(2);
    this.discount_amount = parseFloat(this.totalDiscountvalue);
    var totalAdvance = parseFloat(this.Advance_bill);
    var totalAdvanceBills = !isNaN(this.totalAdvance_bills) ? this.totalAdvance_bills : 0;
    this.remainingbalance_advance = (totalAdvance - totalAdvanceBills).toFixed(2);
    this.remainingbalance_sponsor_due = parseFloat(this.sponsorDue);
    console.log("this.remainingbalance_advance   **" + totalAdvance + "????" + totalAdvanceBills)
    console.log("this.remainingbalance_advance   " + this.remainingbalance_advance)
    // Calculate remaining balance

    if (this.sponsorDue !== undefined) {
      var deductval = parseFloat(this.totalPaidAmountvalue) + totalAdvance + parseFloat(this.sponsorDue) + this.discount_amount;
      this.remaing_billbal = (parseFloat(this.totalBillAmountvalue) - deductval).toFixed(2);
    } else {
      deductval = parseFloat(this.totalPaidAmountvalue) + totalAdvance + this.discount_amount;
      this.remaing_billbal = (parseFloat(this.totalBillAmountvalue) - deductval).toFixed(2);
      console.log("calculateCombinedValue  --" + this.totalBillAmountvalue + " ...." + deductval)
      console.log("calculateCombinedValue  --" + this.remaing_billbal + " ...." + deductval)
    }

    if (this.totalBillAmountvalue.trim() === this.paid_bill.trim()) {
      this.paidflag = false;

    } else {
      this.paidflag = true;
    }
    if (this.totalDiscountvalue === "0" || this.totalDiscountvalue === "0.00" || this.totalDiscountvalue === "0.0") {
      this.discountflag = false;

    } else {
      this.discountflag = true;
    }
    if (this.remaing_billbal == 0) {
      this.balanceflag = false;

    } else {
      this.balanceflag = true;
    }
    if (this.Advance_bill === "0.00" || this.Advance_bill === "0") {
      this.advanceflag = false;

    } else {
      this.advanceflag = true;
    }

    if (parseInt(this.Advance_bill) > parseInt(this.totalBillAmountvalue) && parseInt(this.paid_bill) == 0) {
      console.log(" if this.total_advance ----" + this.Advance_bill + "????this.remaining_billbal???" + this.totalBillAmountvalue);
      this.adv_refflag = true;
      this.refund_amount = Math.abs(this.remaing_billbal);
      this.remaing_billbal = parseFloat(this.refund_amount).toFixed(2);
      this.refundcal();
    } else {
      this.adv_refflag = false;
      this.refund_amount = 0;
      this.bank_details_flag_adv = false;
      if (this.paymentsarray.length == 0) {
        this.payType1 = this.paytypelist[0].pay_id;
        this.amountval = parseFloat(this.remaing_billbal);
      }
    }
  }

  checkdiscount() {
    this.discount_amount = 0;
  }

  discountvalue(num) {
    console.log(this.discount_amount);
    this.discount_amount = Number(this.discount_amount); // Convert to number if needed
    console.log(this.totalDiscountvalue);
    console.log(this.discount_amount);
    var totalDiscountValueNumber = parseFloat(this.totalDiscountvalue); // Convert string to number
    this.discount_amount += totalDiscountValueNumber;
    this.remaing_billbal = this.remaing_billbal - this.discount_amount;
    this.remaing_billbal = parseFloat(this.remaing_billbal);
    if (this.discount_amount != 0 || this.discount_amount != 0.00) {
      this.discountflag = true;
    }
    this.remaing_billbal = this.checkAndAssignValue(this.remaing_billbal);
    this.discount_amount = this.checkAndAssignValue(this.discount_amount);
    console.log("doctorBills", this.doctorBills);
    console.log("sendDoctor", this.sendDoctor);
    if (this.discount_amount != 0 && this.sendDoctor[0].balance != 0) {
      this.sendDoctor[0].discount = this.discount_amount;
      this.sendDoctor[0].balance = this.sendDoctor[0].balance - this.discount_amount;
    }
    console.log("afterhospBillAmount", this.sendDoctor);
    // this.calculatePaidAmount(num);
    console.log("this.adv_refflag",this.adv_refflag);
    // this.refundcal();
  }

  checkAndAssignValue(value: any): number {
    return isNaN(value) ? 0.00 : value;
  }

  checkBalance1() {
    this.paid_bill = "0";
  }

  calculateAdvanceAmount(num) {
    if (parseFloat(this.Advance_bill) != 0) {
      var advance_balance: any = parseFloat(this.Advance_bill);

      if (this.sendDoctor != undefined && this.sendDoctor.length != 0) {
        for (var i = 0; i < this.sendDoctor.length; i++) {
          console.log("this.sendDoctor[i].balance", this.sendDoctor[i].balance);
          if (parseFloat(this.sendDoctor[i].bill_amount) >= parseFloat(advance_balance)) {
            this.sendDoctor[i].paid_amount = parseFloat("0.00");
            console.log("this.sendDoctor[i].balance  ", parseFloat(this.sendDoctor[i].balance), "advance_balance  ", parseFloat(advance_balance));
            this.sendDoctor[i].balance = parseFloat(this.sendDoctor[i].balance) - parseFloat(advance_balance);
            this.sendDoctor[i].advance = parseFloat(advance_balance);
            console.log("this.sendDoctor[i].balance  ", parseFloat(this.sendDoctor[i].balance), "advance_balance  ", parseFloat(advance_balance));
            advance_balance = parseFloat("0.00");
            this.sendDoctor[i].paid_flag = "Fully paid";
          } else {
            this.sendDoctor[i].paid_amount = parseFloat("0.00");
            this.sendDoctor[i].balance = parseFloat("0.00");
            this.sendDoctor[i].advance = parseFloat(this.sendDoctor[i].bill_amount);
            advance_balance = parseFloat(advance_balance) - parseFloat(this.sendDoctor[i].bill_amount);
            this.sendDoctor[i].paid_flag = "Partially paid";
          }
        }
        console.log("DoctorBillAfterAdvanceCalculate", this.sendDoctor);
      }

      if (this.sendDiagnosis != undefined && this.sendDiagnosis.length != 0) {
        for (var i = 0; i < this.sendDiagnosis.length; i++) {
          if (parseFloat(this.sendDiagnosis[i].bill_amount) >= parseFloat(advance_balance)) {
            this.sendDiagnosis[i].paid_amount = parseFloat("0.00");
            console.log("this.sendDiagnosis[i].balance", parseFloat(this.sendDiagnosis[i].balance) + "----" + "advance_balance" + parseFloat(advance_balance));
            this.sendDiagnosis[i].balance = parseFloat(this.sendDiagnosis[i].balance) - parseFloat(advance_balance);
            this.sendDiagnosis[i].advance = parseFloat(advance_balance);
            advance_balance = parseFloat("0.00");
            this.sendDiagnosis[i].paid_flag = "Fully paid";
          } else {
            this.sendDiagnosis[i].paid_amount = parseFloat("0.00");
            this.sendDiagnosis[i].balance = parseFloat("0.00");
            this.sendDiagnosis[i].advance = parseFloat(this.sendDiagnosis[i].bill_amount);
            advance_balance = parseFloat(advance_balance) - parseFloat(this.sendDiagnosis[i].bill_amount);
            this.sendDiagnosis[i].paid_flag = "Partially paid";
          }
        }
        console.log("DiagBillAfterAdvanceCalculate", this.sendDiagnosis);
      }

      if (this.sendPharmacy != undefined && this.sendPharmacy.length != 0) {
        for (var i = 0; i < this.sendPharmacy.length; i++) {
          if (parseFloat(this.sendPharmacy[i].bill_amount) >= parseFloat(advance_balance)) {
            this.sendPharmacy[i].paid_amount = parseFloat("0.00");
            this.sendPharmacy[i].balance = parseFloat(this.sendPharmacy[i].balance) - parseFloat(advance_balance);
            this.sendPharmacy[i].advance = parseFloat(advance_balance);
            advance_balance = parseFloat("0.00");
            this.sendPharmacy[i].paid_flag = "Fully paid";
          } else {
            this.sendPharmacy[i].paid_amount = parseFloat("0.00");
            this.sendPharmacy[i].balance = parseFloat("0.00");
            this.sendPharmacy[i].advance = parseFloat(this.sendPharmacy[i].bill_amount);
            advance_balance = parseFloat(advance_balance) - parseFloat(this.sendPharmacy[i].bill_amount);
            this.sendPharmacy[i].paid_flag = "Partially paid";
          }
        }
        console.log("PharmacyBillAfterAdvanceCalculate", this.sendPharmacy);
      }
    }
    console.log("doctor bills " + JSON.stringify(this.sendDoctor))
    console.log("TOTAL BILL AMOUNT " + this.totalBill)
    this.remaing_billbal = (parseFloat(this.totalBill) - advance_balance).toFixed(2);
    if (this.remaing_billbal < 0 && parseInt(this.paid_bill) == 0) {
      this.adv_refflag = true;
      this.remaing_billbal = Math.abs(this.remaing_billbal);
      this.refundcal();
    } else {
      this.adv_refflag = false;
      this.refund_amount = 0
      this.bank_details_flag_adv = false;
      if (this.paymentsarray.length == 0) {
        this.payType1 = this.paytypelist[0].pay_id;
        this.amountval = parseFloat(this.remaing_billbal);
      }
    }
    this.calculateSponsorDueAmount(num);
  }

  calculateSponsorDueAmount(num) {
    var sponser_due_balance: any = parseFloat(this.sponsorDue);
    console.log(sponser_due_balance);
    if (sponser_due_balance != 0) {
      if (this.sendDoctor != undefined && this.sendDoctor.length != 0) {
        for (var i = 0; i < this.sendDoctor.length; i++) {
          if (parseInt(this.sendDoctor[i].balance) != 0) {
            console.log("Passed", parseInt(this.sendDoctor[i].balance));
            if (parseFloat(this.sendDoctor[i].balance) >= parseFloat(sponser_due_balance)) {
              this.sendDoctor[i].paid_amount = parseFloat("0.00");
              this.sendDoctor[i].sponsor_due = parseFloat(sponser_due_balance);
              this.sendDoctor[i].balance = parseFloat(this.sendDoctor[i].balance) - parseFloat(sponser_due_balance);
              sponser_due_balance = parseFloat("0.00");
              this.sendDoctor[i].paid_flag = "Fully paid";
            } else {
              this.sendDoctor[i].paid_amount = parseFloat("0.00");
              this.sendDoctor[i].sponsor_due = parseFloat(this.sendDoctor[i].balance);
              sponser_due_balance = parseFloat(sponser_due_balance) - parseFloat(this.sendDoctor[i].balance);
              this.sendDoctor[i].balance = parseFloat("0.00");
              this.sendDoctor[i].paid_flag = "Partially paid";
            }
          }
        }
        console.log("DoctorBillAfterSponsor_dueCalculate", this.sendDoctor);
      }

      if (this.sendDiagnosis != undefined && this.sendDiagnosis.length != 0) {
        for (var i = 0; i < this.sendDiagnosis.length; i++) {
          if (parseInt(this.sendDiagnosis[i].balance) != 0) {
            console.log("Passed", parseInt(this.sendDiagnosis[i].balance));
            if (parseFloat(this.sendDiagnosis[i].balance) >= parseFloat(sponser_due_balance)) {
              this.sendDiagnosis[i].paid_amount = parseFloat("0.00");
              this.sendDiagnosis[i].sponsor_due = parseFloat(sponser_due_balance);
              console.log("this.sendDiagnosis[i].balance", parseFloat(this.sendDiagnosis[i].balance) + "----" + "sponser_due_balance" + parseFloat(sponser_due_balance));
              this.sendDiagnosis[i].balance = parseFloat(this.sendDiagnosis[i].balance) - parseFloat(sponser_due_balance);
              sponser_due_balance = parseFloat("0.00");
              this.sendDiagnosis[i].paid_flag = "Fully paid";
            } else {
              this.sendDiagnosis[i].paid_amount = parseFloat("0.00");
              this.sendDiagnosis[i].sponsor_due = parseFloat(this.sendDiagnosis[i].balance);
              sponser_due_balance = parseFloat(sponser_due_balance) - parseFloat(this.sendDiagnosis[i].balance);
              this.sendDiagnosis[i].balance = parseFloat("0.00");
              this.sendDiagnosis[i].paid_flag = "Partially paid";
            }
          }
        }
        console.log("DiagBillAfterSponsor_dueCalculate", this.sendDiagnosis);
      }

      if (this.sendPharmacy != undefined && this.sendPharmacy.length != 0) {
        for (var i = 0; i < this.sendPharmacy.length; i++) {
          if (parseInt(this.sendPharmacy[i].balance) != 0) {
            console.log("Passed", parseInt(this.sendPharmacy[i].balance));
            if (parseFloat(this.sendPharmacy[i].balance) >= parseFloat(sponser_due_balance)) {
              this.sendPharmacy[i].paid_amount = parseFloat("0.00");
              this.sendPharmacy[i].sponsor_due = parseFloat(sponser_due_balance);
              this.sendPharmacy[i].balance = parseFloat(this.sendPharmacy[i].balance) - parseFloat(sponser_due_balance);
              sponser_due_balance = parseFloat("0.00");
              this.sendPharmacy[i].paid_flag = "Fully paid";
            } else {
              this.sendPharmacy[i].paid_amount = parseFloat("0.00");
              this.sendPharmacy[i].sponsor_due = parseFloat(this.sendPharmacy[i].balance);
              sponser_due_balance = parseFloat(sponser_due_balance) - parseFloat(this.sendPharmacy[i].balance);
              this.sendPharmacy[i].balance = parseFloat("0.00");
              this.sendPharmacy[i].paid_flag = "Partially paid";
            }
          }
        }
        console.log("PharmacyBillAfterSponsor_dueCalculate", this.sendPharmacy);
      }
    }
    if (this.remaing_billbal)
      this.remaing_billbal = (parseFloat(this.totalBillAmountvalue) - sponser_due_balance);
    if (num != "1") {
      this.calculatePaidAmount(num);
    }
  }

  calculatePaidAmount(num) {
    if (num == "1") {
      // this.getAllBills("1")
    } 
    console.log("Function Called");
    this.paid_bill1 = (parseFloat(this.paid_bill) + parseFloat(this.totalPaidAmountvalue));
    this.paid_bill = this.paid_bill1;
    if (this.sponsorDue != undefined) {
      var totaldeduct = parseFloat(this.paid_bill1) + parseFloat(this.Advance_bill) + this.discount_amount + parseFloat(this.sponsorDue);
      this.remaing_billbal = (parseFloat(this.totalBillAmountvalue) - totaldeduct);
    } else {
      var totaldeduct = parseFloat(this.paid_bill1) + parseFloat(this.Advance_bill) + this.discount_amount;
      this.remaing_billbal = (parseFloat(this.totalBillAmountvalue) - totaldeduct);
    }
    console.log("remaing_billbal", this.remaing_billbal);
    if (this.remaing_billbal < 0 && parseInt(this.paid_bill) == 0) {
      this.adv_refflag = true;
      this.remaing_billbal = Math.abs(this.remaing_billbal);
      this.refundcal();
    } else {
      this.adv_refflag = false;
      this.bank_details_flag_adv = false;
      if (this.paymentsarray.length == 0) {
        this.payType1 = this.paytypelist[0].pay_id;
        this.amountval = parseFloat(this.remaing_billbal);
      }
    }
    var paid_balance: any = parseFloat(this.paid_bill);
    console.log(paid_balance);
    if (paid_balance != 0) {
      if (this.sendDoctor != undefined && this.sendDoctor.length != 0) {
        for (var i = 0; i < this.sendDoctor.length; i++) {
          if (parseInt(this.sendDoctor[i].balance) != 0) {
            console.log("Passed", parseInt(this.sendDoctor[i].balance));
            if (parseFloat(this.sendDoctor[i].balance) > parseFloat(paid_balance)) {
              this.sendDoctor[i].paid_amount = parseFloat(paid_balance);
              this.sendDoctor[i].balance = parseFloat(this.sendDoctor[i].balance) - parseFloat(paid_balance);
              paid_balance = parseFloat("0.00");
              this.sendDoctor[i].paid_flag = "Partially paid";
            } else if (parseFloat(this.sendDoctor[i].balance) == parseFloat(paid_balance)) {
              this.sendDoctor[i].paid_amount = parseFloat(paid_balance);
              this.sendDoctor[i].balance = parseFloat("0.00");
              paid_balance = parseFloat("0.00");
              this.sendDoctor[i].paid_flag = "Fully paid";
            } else {
              // this.sendDoctor[i].paid_amount = parseFloat(this.sendDoctor[i].balance);
              // paid_balance = parseFloat(paid_balance) - parseFloat(this.sendDoctor[i].balance);
              // this.sendDoctor[i].balance = parseFloat("0.00");
              // this.sendDoctor[i].paid_flag = "Partially paid";
              this.sendDoctor[i].paid_amount = parseFloat(this.sendDoctor[i].balance);
              paid_balance = parseFloat(paid_balance) - parseFloat(this.sendDoctor[i].balance);
              this.sendDoctor[i].balance = parseFloat("0.00");
              this.sendDoctor[i].paid_flag = "Fully Paid";
            }
          }
        }
        console.log("DoctorBillAfterPaidAmountCalculate", this.sendDoctor);
      }

      if (this.sendDiagnosis != undefined && this.sendDiagnosis.length != 0) {
        for (var i = 0; i < this.sendDiagnosis.length; i++) {
          if (parseInt(this.sendDiagnosis[i].balance) != 0) {
            console.log("Passed", parseInt(this.sendDiagnosis[i].balance));
            if (parseFloat(this.sendDiagnosis[i].balance) > parseFloat(paid_balance)) {
              this.sendDiagnosis[i].paid_amount = parseFloat(paid_balance);
              console.log("this.sendDiagnosis[i].balance", parseFloat(this.sendDiagnosis[i].balance) + "----" + "paid_balance" + parseFloat(paid_balance));
              this.sendDiagnosis[i].balance = parseFloat(this.sendDiagnosis[i].balance) - parseFloat(paid_balance);
              paid_balance = parseFloat("0.00");
              this.sendDiagnosis[i].paid_flag = "Partially paid";
            } else if (parseFloat(this.sendDiagnosis[i].balance) == parseFloat(paid_balance)) {
              this.sendDiagnosis[i].paid_amount = parseFloat(paid_balance);
              this.sendDiagnosis[i].balance = parseFloat("0.00");
              paid_balance = parseFloat("0.00");
              this.sendDiagnosis[i].paid_flag = "Fully paid";
            } else {
              // this.sendDiagnosis[i].paid_amount = parseFloat(this.sendDiagnosis[i].balance);
              // paid_balance = parseFloat(paid_balance) - parseFloat(this.sendDiagnosis[i].balance);
              // this.sendDiagnosis[i].balance = parseFloat("0.00");
              // this.sendDiagnosis[i].paid_flag = "Partially paid";
              this.sendDiagnosis[i].paid_amount = parseFloat(this.sendDiagnosis[i].balance);
              paid_balance = parseFloat(paid_balance) - parseFloat(this.sendDiagnosis[i].balance);
              this.sendDiagnosis[i].balance = parseFloat("0.00");
              this.sendDiagnosis[i].paid_flag = "Fully Paid";
            }
          }
        }
        console.log("DiagBillAfterAfterPaidAmountCalculate", this.sendDiagnosis);
      }

      if (this.sendPharmacy != undefined && this.sendPharmacy.length != 0) {
        for (var i = 0; i < this.sendPharmacy.length; i++) {
          if (parseInt(this.sendPharmacy[i].balance) != 0) {
            console.log("Passed", parseInt(this.sendPharmacy[i].balance), "----", parseFloat(paid_balance));
            if (parseFloat(this.sendPharmacy[i].balance) > parseFloat(paid_balance)) {
              this.sendPharmacy[i].paid_amount = parseFloat(paid_balance);
              this.sendPharmacy[i].balance = parseFloat(this.sendPharmacy[i].balance) - parseFloat(paid_balance);
              paid_balance = parseFloat("0.00");
              this.sendPharmacy[i].paid_flag = "Partially paid";
            } else if (parseFloat(this.sendPharmacy[i].balance) == parseFloat(paid_balance)) {
              this.sendPharmacy[i].paid_amount = parseFloat(paid_balance);
              this.sendPharmacy[i].balance = parseFloat("0.00");
              paid_balance = parseFloat("0.00");
              this.sendPharmacy[i].paid_flag = "Fully paid";
            } else {
              // this.sendPharmacy[i].paid_amount = parseFloat(this.sendPharmacy[i].balance);
              // paid_balance = parseFloat(paid_balance) - parseFloat(this.sendPharmacy[i].balance);
              // this.sendPharmacy[i].balance = parseFloat("0.00");
              // this.sendPharmacy[i].paid_flag = "Partially paid";
              this.sendPharmacy[i].paid_amount = parseFloat(this.sendPharmacy[i].balance);
              paid_balance = parseFloat(paid_balance) - parseFloat(this.sendPharmacy[i].balance);
              this.sendPharmacy[i].balance = parseFloat("0.00");
              this.sendPharmacy[i].paid_flag = "Fully Paid";
            }
          }
        }
        console.log("PharmacyBillAfterAfterPaidAmountCalculate", this.sendPharmacy);
      }
    }

    if (this.sendDoctor.length != 0) {
      for (var i = 0; i < this.sendDoctor.length; i++) {
        if (parseInt(this.sendDoctor[i].balance) == 0) {
          this.sendDoctor[i].paid_flag = "Fully paid";
        } else if (parseInt(this.sendDoctor[i].balance) == parseInt(this.sendDoctor[i].bill_amount)) {
          this.sendDoctor[i].paid_flag = "Un paid";
        } else {
          this.sendDoctor[i].paid_flag = "Partially paid";
        }
      }
    }

    if (this.sendDiagnosis.length != 0) {
      for (var i = 0; i < this.sendDiagnosis.length; i++) {
        if (parseInt(this.sendDiagnosis[i].balance) == 0) {
          this.sendDiagnosis[i].paid_flag = "Fully paid";
        } else if (parseInt(this.sendDiagnosis[i].balance) == parseInt(this.sendDiagnosis[i].bill_amount)) {
          this.sendDiagnosis[i].paid_flag = "Un paid";
        } else {
          this.sendDiagnosis[i].paid_flag = "Partially paid";
        }
      }
    }

    if (this.sendPharmacy.length != 0) {
      for (var i = 0; i < this.sendPharmacy.length; i++) {
        if (parseInt(this.sendPharmacy[i].balance) == 0) {
          this.sendPharmacy[i].paid_flag = "Fully paid";
        } else if (parseInt(this.sendPharmacy[i].balance) == parseInt(this.sendPharmacy[i].bill_amount)) {
          this.sendPharmacy[i].paid_flag = "Un paid";
        } else {
          this.sendPharmacy[i].paid_flag = "Partially paid";
        }
      }
    }

    if (this.totalBillAmountvalue == this.paid_bill) {
      this.paidflag = false;
    } else {
      this.paidflag = true;
    }

    if (this.totalDiscountvalue === "0" || this.totalDiscountvalue === "0.00" || this.totalDiscountvalue === "0.0") {
      this.discountflag = false;
    } else {
      this.discountflag = true;
    }

    if (this.remaing_billbal == 0) {
      this.balanceflag = false;
    } else {
      this.balanceflag = true;
    }

    if (this.Advance_bill == "0.00" || this.Advance_bill === "0") {
      this.advanceflag = false;
    } else {
      this.advanceflag = true;
    }
    if(!this.adv_refflag && this.paymentsarray.length == 0) {
      this.changePaidAmount();
    }
  }

  allBillPaymentsArray() {
    if (this.sendDoctor.length != 0 && !this.adv_refflag) {
      for (var i = 0; i < this.sendDoctor.length; i++) {
        if (parseInt(this.sendDoctor[i].paid_amount) != 0) {
          this.sendDoctor[i].paytypes = this.paymentArrayCalculation(this.sendDoctor[i].paid_amount);
        }
      }
    }
    if (this.sendDiagnosis.length != 0 && !this.adv_refflag) {
      for (var i = 0; i < this.sendDiagnosis.length; i++) {
        if (parseInt(this.sendDiagnosis[i].paid_amount) != 0) {
          this.sendDiagnosis[i].paytypes = this.paymentArrayCalculation(this.sendDiagnosis[i].paid_amount);
        }
      }
    }
    if (this.sendPharmacy.length != 0 && !this.adv_refflag) {
      for (var i = 0; i < this.sendPharmacy.length; i++) {
        if (parseInt(this.sendPharmacy[i].paid_amount) != 0) {
          this.sendPharmacy[i].paytypes = this.paymentArrayCalculation(this.sendPharmacy[i].paid_amount);
        }
      }
    }
    console.log("sendDoctor----",this.sendDoctor," sendDiagnosis----",this.sendDiagnosis," sendPharmacy----",this.sendPharmacy);
  }

  changePaidAmount() {
    this.paymentsarray = [];
    if (this.paymentsarray.length == 0) {
      this.payType1 = this.paytypelist[0].pay_id;
    }

    if (this.adv_refflag == false) {
      this.amountval = parseFloat(this.paid_bill);
    } else {
      if (this.paid_bill == 0) {
        this.amountval = 0;
      } else {
        this.amountval = parseFloat(this.paid_bill) - parseFloat(this.remaing_billbal);
      }
    }
    
  }

  getAllBills(num) {
    this.docBills = [];
    this.medBills = [];
    this.diagBills = [];
    this.sendDoctor = [];
    this.sendPharmacy = [];
    this.sendDiagnosis = [];
    this.totalBillAmount1 = "0.00";
    this.totalPaidAmount = "0.00";
    this.TotalBalance = "0.00";
    this.final_amount = "0.00";
    this.totalDiscount = "0.00";
    this.totalPaid = "0.00";
    this.totalBill = "0.00";
    this.refund_amount = 0;
    this.refund_reason = '';
    this.adv_refflag = false;
    this.doctorBills = [];
    this.doctorBills.push({
      advance: "0.00",
      sponsor_due: "0.00",
      bill_amount: this.hospital_finalAmount,
      balance: this.hospital_finalAmount,
      discount: this.hospital_discount_amount,
      billing_id: this.billId,
      created_date: this.CurrentDatetime,
      created_time: this.currentTime,
      paid_amount: "0.00",
      paid_flag: "Un paid"
    })
    var doctorBills = this.doctorBills;
    console.log(doctorBills);
    if (doctorBills != undefined) {
      for (var j = 0; j < doctorBills.length; j++) {
        var bill_amount = doctorBills[j].bill_amount != undefined ? doctorBills[j].bill_amount : "0.00";
        var bill_date = doctorBills[j].created_date != undefined ? Date_Formate(doctorBills[j].created_date) : "";
        var advance = doctorBills[j].advance != undefined ? parseFloat(doctorBills[j].advance).toFixed(2) : "0.00";
        var sponsor_due = doctorBills[j].sponsor_due != undefined ? parseFloat(doctorBills[j].sponsor_due).toFixed(2) : "0.00";
        var balance = (parseFloat(bill_amount) - (parseFloat(doctorBills[j].paid_amount) + parseFloat(doctorBills[j].discount)) - parseFloat(advance)).toFixed(2);
        this.totalBillAmount1 = (parseFloat(this.totalBillAmount1) + parseFloat(bill_amount)).toFixed(2);
        this.totalPaidAmount = (parseFloat(this.totalPaidAmount) + parseFloat(doctorBills[j].paid_amount)).toFixed(2);
        this.TotalBalance = (parseFloat(this.TotalBalance) + parseFloat(doctorBills[j].balance)).toFixed(2);

        var tempDoc = {
          billing_id: doctorBills[j].billing_id,
          bill_type: 'Hospital',
          bill_amount: parseFloat(bill_amount).toFixed(2),
          advance: parseFloat(advance).toFixed(2),
          sponsor_due: parseFloat(sponsor_due).toFixed(2),
          balance: parseFloat(balance).toFixed(2),
          bill_date: bill_date + " " + Time_Formate(doctorBills[j].created_time),
          paid_amount: parseFloat(doctorBills[j].paid_amount).toFixed(2),
          discount: parseFloat(doctorBills[j].discount).toFixed(2),
          fin_year: this.fin_year,
          checked: false,
        }
        this.docBills.push(tempDoc);
        console.log(this.docBills);
      }
    }
    // for Pharma bill 
    var pharmaBills = this.pharma_bills;
    console.log("pharmaBills" + JSON.stringify(pharmaBills));
    if (pharmaBills != undefined) {
      this.medBills = [];
      for (var j = 0; j < pharmaBills.length; j++) {
        var tempPharma = {};
        var pbill_amount;
        if (this.is_insurance == true) {
          if (pharmaBills[j].sponsor_due != undefined) {
            pbill_amount = pharmaBills[j].sponsor_due;
          }
        } else {
          pbill_amount = pharmaBills[j].bill_amount != undefined ? pharmaBills[j].bill_amount : "";
        }
        var blanc;
        if (this.is_insurance == true) {
          if (pharmaBills[j].sponsor_due != undefined) {
            blanc = pharmaBills[j].sponsor_due;
          }
        } else {
          blanc = pharmaBills[j].balance != undefined ? pharmaBills[j].balance : "0.00";
        }

        var bill_date = pharmaBills[j].created_date != undefined ? Date_Formate(pharmaBills[j].created_date) : "";
        var paid = pharmaBills[j].paid_amount != undefined ? pharmaBills[j].paid_amount : "0.00";
        var discount = pharmaBills[j].bill_disc != undefined ? pharmaBills[j].bill_disc : "0.00";
        var pharma_advance = pharmaBills[j].advance != undefined ? pharmaBills[j].advance : "0.00";
        var sponsor_due = pharmaBills[j].sponsor_due != undefined ? parseFloat(pharmaBills[j].sponsor_due).toFixed(2) : "0.00";
        this.totalBillAmount1 = (parseFloat(this.totalBillAmount1) + parseFloat(bill_amount)).toFixed(2);
        this.totalPaidAmount = (parseFloat(this.totalPaidAmount) + parseFloat(paid)).toFixed(2);
        this.TotalBalance = (parseFloat(this.TotalBalance) + parseFloat(blanc)).toFixed(2);

        tempPharma = {
          billing_id: pharmaBills[j].billing_id,
          bill_type: 'Pharmacy',
          bill_amount: parseFloat(pbill_amount).toFixed(2),
          advance: parseFloat(pharma_advance).toFixed(2),
          sponsor_due: parseFloat(sponsor_due).toFixed(2),
          balance: parseFloat(blanc).toFixed(2),
          bill_date: bill_date + " " + Time_Formate(pharmaBills[j].created_time),
          paid_amount: parseFloat(paid).toFixed(2),
          checked: false,
          discount: parseFloat(discount).toFixed(2),
          fin_year: this.fin_year,
          pharma_id: this.pharma_id,
        }
        this.medBills.push(tempPharma);
        console.log("medBills" + JSON.stringify(this.medBills));
      }
    }
    // for Diagnosis bill
    var diagBills = this.diagnosis_bills;
    console.log("diagBills" + JSON.stringify(diagBills));
    if (diagBills != undefined) {
      this.diagBills = [];
      var diag = {};
      for (var j = 0; j < diagBills.length; j++) {
        var bill_amount = diagBills[j].bill_amount != undefined ? diagBills[j].bill_amount : "";
        var paid = diagBills[j].paid_amount != undefined ? diagBills[j].paid_amount : "0.00";
        var diagbalance = diagBills[j].balance != undefined ? diagBills[j].balance : "0.00";
        var discount = diagBills[j].discount != undefined ? diagBills[j].discount : "0.00";
        var diag_advance = diagBills[j].advance != undefined ? diagBills[j].advance : "0.00";
        var sponsor_due = diagBills[j].sponsor_due != undefined ? parseFloat(diagBills[j].sponsor_due).toFixed(2) : "0.00";
        var bill_date = diagBills[j].created_date != undefined ? Date_Formate(diagBills[j].created_date) : "";
        this.totalBillAmount1 = (parseFloat(this.totalBillAmount1) + parseFloat(bill_amount)).toFixed(2);
        this.totalPaidAmount = (parseFloat(this.totalPaidAmount) + parseFloat(paid)).toFixed(2);
        this.TotalBalance = (parseFloat(this.TotalBalance) + parseFloat(diagbalance)).toFixed(2);

        diag = {
          billing_id: diagBills[j].billing_id,
          bill_type: 'Diagnosis',
          sub_type: diagBills[j].sub_type,
          bill_amount: parseFloat(bill_amount).toFixed(2),
          advance: parseFloat(diag_advance).toFixed(2),
          sponsor_due: parseFloat(sponsor_due).toFixed(2),
          balance: parseFloat(diagbalance).toFixed(2),
          bill_date: bill_date + " " + Time_Formate(diagBills[j].created_time),
          paid_amount: parseFloat(paid).toFixed(2),
          checked: false,
          discount: parseFloat(discount).toFixed(2),
          fin_year: this.fin_year,
        }
        this.diagBills.push(diag);
        console.log("AfterSetDiagBills", JSON.stringify(this.diagBills));
      }
    }
    this.allBillSet(num);
  }

  Get_current_date() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'adm/curdate',
        JSON.stringify({
          country: ipaddress.country_code.toString(),
        }),
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = response.json();
          console.log(obj);
          if (obj.current_date != null) {
            this.CurrentDatetime = obj.current_date;
            this.currentTime = obj.current_time;
            var timevalue = Time_Formate(this.currentTime);
            var tval = timevalue.split(' ');
            var tval1 = tval[0].split(':');
            this.bill_time1 = tval1[0];
            this.bill_time2 = tval1[1];
            this.bill_time3 = tval[1];
            var get_date = obj.current_date.split('-');
            this.Appoint_Date = get_date[2] + '-' + get_date[1] + '-' + get_date[0];
            this.currentDate = obj.current_date;
            this.bill_date = obj.current_date;
            this.time = Time_Formate(obj.current_time);
            this.date = obj.current_date;
            this.Appoint_Date = this.CurrentDatetime;
            Doc_Helper.setBillFromDate(null);
            Doc_Helper.setBillToDate(null);
            Doc_Helper.setBillFromDate(obj.current_date);
            Doc_Helper.setBillToDate(obj.current_date);
          }
        },
        (error) => { }
      );
  }

  billDateChange(date) {
    if (date > this.currentDate) {
      this.toastr.error('Select current or past date');
      this.bill_date = undefined;
    } else {
      this.bill_date = date;
    }
  }

  checkUncheckAll() {
    this.checklist = this.billaddlist;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
  }

  isAllSelected() {
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    });
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i].patient_id);
    }

    if (this.checklist.length == this.checkedList.length) {
      this.toastr.success(Message_data.allRowSlt);
    } else if (this.checkedList.length != 0) {
      this.toastr.success('Thank you...', `selected Id: ${this.checkedList}`);
    } else {
      this.checkedList = `No row seleted`;
      this.toastr.success(Message_data.noRowSlt);
    }
  }

  clearAll() {
    this.checklist = this.billaddlist;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = false;
    }
    this.masterSelected = false;
    this.checkedList = [];
  }

  print_area(print) {
    this.temp_key = print;
    if (this.categoryChargeList.length == 0) {
      this.toastr.error(Message_data.getAddBill());
    } else {
      this.print_area1();
    }
  }

  print_area1() {
    var margin_top;
    let printContents, popupWin;
    if (this.print_template != undefined && this.print_template == 'banner') {
      printContents = this.printbanner.nativeElement.innerHTML;
      margin_top = '0px';
    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
      margin_top = '122px';
    }
    popupWin = window.open(
      '',
      '_blank',
      'top=20,left=10,height=100%,width=auto'
    );
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>                        
          @page {
            size: auto;
            margin-top: ${margin_top};
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 0px;
            overflow: visible;
          } 
          * {
              font-size: 12px !important;
              line-height: 1 !important;
              page-break-inside: always;
              overflow: visible;
            }                                     
          @media print {   
            body {
              height: auto; /* Allow body height to adjust dynamically */
            }          
            .page-break {
              page-break-before: always; /* or page-break-after: always; */
              page-break-inside: always;
            }
          }           
          .address_visibility {
            visibility: hidden;
          }        
          table.report-container {
            page-break-after: always;
          }
          thead.report-header {
            display: table-header-group;
          }
          tfoot.report-footer {
            display: table-footer-group;
          }
          .print:last-child {
            page-break-after: auto;
          }
          .alignRight {
            text-align: right;
          }
          .col-print-1 {width:8%;  float:left;}
          .col-print-2 {width:16%; float:left;}
          .col-print-3 {width:25%; float:left;}
          .col-print-4 {width:33%; float:left;}
          .col-print-5 {width:42%; float:left;}
          .col-print-6 {width:50%; float:left;}
          .col-print-7 {width:58%; float:left;}
          .col-print-8 {width:66%; float:left;}
          .col-print-9 {width:75%; float:left;}
          .col-print-10 {width:83%; float:left;}
          .col-print-11 {width:92%; float:left;}
          .col-print-12 {width:100%; float:left;}
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
    popupWin.document.close();
  }

  array_exits(array, id) {
    var flag = false;
    for (var i in array) {
      if (array[i].bill_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  showCashModal() {
    this.payType1 = '1';
    this.showModal = true;
  }

  saveCashDenomination() {
    this.amountval = this.totalAmount;
    this.showModal = false;
  }

  calculateTotalAmount() {
    this.totalAmount = this.cashDenomination.reduce((total, denomination) => {
      return total + denomination.value * denomination.count;
    }, 0);
  }

  addPaymentDetails() {
    this.local_payment_array = [];
    var bamt: any = 0,
      balance = 0;
    var results = this.finekeyInArray_Payment(
      this.paymentsarray,
      this.payType1
    );
    if (!this.adv_refflag) {
      if (this.payType1 != undefined && this.payType1 != '') {
        for (var i = 0; i < this.paytypelist.length; i++) {
          if (this.payType1 == this.paytypelist[i].pay_id) {
            if (this.paymentsarray.length != 0) {
              for (var j = 0; j < this.paymentsarray.length; j++) {
                bamt += parseInt(this.paymentsarray[j].amount);
              }
              bamt += parseFloat(this.amountval);
            } else {
              bamt += parseFloat(this.amountval);
            }
  
            if (bamt > this.paid_bill) {
              this.toastr.error('Amount cannot be more than bill amount');
            } else if (this.amountval == 0 || this.amountval < 0) {
              this.toastr.error('Amount cannot be zero or minus');
            } else if (results == true) {
              this.toastr.error('Payment mode already exists');
            } else {
              if (
                this.amountval < parseFloat(this.paid_bill) ||
                this.amountval == parseFloat(this.paid_bill)
              ) {
                if (parseFloat(this.paid_bill) > parseFloat(this.final_amount)) {
                  if (bamt > parseFloat(this.final_amount)) {
                    this.toastr.error('Amount cannot be more than bill amount');
                  } else {
                    this.paymentsarray.push({
                      pay_id: this.paytypelist[i].pay_id,
                      pay_desc: this.paytypelist[i].pay_desc,
                      amount: parseFloat(this.amountval),
                    });
                    this.amountval = parseFloat(this.final_amount) - bamt;
                    this.amountval = parseFloat(this.amountval);
                    this.payType1 = '';
                  }
                } else {
                  if (bamt > parseFloat(this.paid_bill)) {
                    this.toastr.error('Amount cannot be more than bill amount');
                  } else {
                    this.paymentsarray.push({
                      pay_id: this.paytypelist[i].pay_id,
                      pay_desc: this.paytypelist[i].pay_desc,
                      amount: parseFloat(this.amountval),
                    });
                    this.amountval = parseFloat(this.paid_bill) - bamt;
                    this.amountval = parseFloat(this.amountval);
                    this.payType1 = '';
                  }
                }
              } else {
                this.amountval = 0;
              }
            }
          }
        }
      } else {
        this.toastr.error('Please select the payment mode');
      }
    } else {
      if (this.payType1 != undefined && this.payType1 != '') {
        for (var i = 0; i < this.paytypelist.length; i++) {
          if (this.payType1 == this.paytypelist[i].pay_id) {
            if (this.paymentsarray.length != 0) {
              for (var j = 0; j < this.paymentsarray.length; j++) {
                bamt += parseInt(this.paymentsarray[j].amount);
              }
              bamt += parseFloat(this.amountval);
            } else {
              bamt += parseFloat(this.amountval);
            }
  
            if (bamt > this.remaing_billbal) {
              this.toastr.error('Amount cannot be more than refund amount');
            } else if (this.amountval == 0 || this.amountval < 0) {
              this.toastr.error('Amount cannot be zero or minus');
            } else if (results == true) {
              this.toastr.error('Payment mode already exists');
            } else {
              if (
                this.amountval < parseFloat(this.remaing_billbal) ||
                this.amountval == parseFloat(this.remaing_billbal)
              ) {
                if (parseFloat(this.remaing_billbal) > parseFloat(this.remaing_billbal)) {
                  if (bamt > parseFloat(this.final_amount)) {
                    this.toastr.error('Amount cannot be more than refund amount');
                  } else {
                    this.paymentsarray.push({
                      pay_id: this.paytypelist[i].pay_id,
                      pay_desc: this.paytypelist[i].pay_desc,
                      amount: parseFloat(this.amountval),
                    });
                    this.amountval = parseFloat(this.final_amount) - bamt;
                    this.amountval = parseFloat(this.amountval);
                    this.payType1 = '';
                  }
                } else {
                  if (bamt > parseFloat(this.remaing_billbal)) {
                    this.toastr.error('Amount cannot be more than bill amount');
                  } else {
                    this.paymentsarray.push({
                      pay_id: this.paytypelist[i].pay_id,
                      pay_desc: this.paytypelist[i].pay_desc,
                      amount: parseFloat(this.amountval),
                    });
                    this.amountval = parseFloat(this.remaing_billbal) - bamt;
                    this.amountval = parseFloat(this.amountval);
                    this.payType1 = '';
                  }
                }
              } else {
                this.amountval = 0;
              }
            }
          }
        }
      } else {
        this.toastr.error('Please select the payment mode');
      }
    }
    
    this.local_payment_array = this.paymentsarray;
    // this.allBillPaymentsArray();
  }

  finekeyInArray_Payment(array, id) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].pay_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  editPayment(data) {
    this.payType1 = data.pay_id;
    this.amountval = parseInt(data.amount);
    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        this.paymentsarray.splice(i, 1);
      }
    }
    // this.allBillPaymentsArray();
  }

  deletePayment(data) {
    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        this.paymentsarray.splice(i, 1);
      }
    }
    // this.allBillPaymentsArray();
  }

  /////////////
  changeDoctor(e) {
    FrontDesk_Helper.setdoctor(undefined);
    FrontDesk_Helper.setdoctor(e);
    this.doctor = e;
    this.dog_reg_id = e;
    this.getPatients();
  }

  getPatients() {
    this.insuranceFlag = false;
    this.Insuranceflag = false;
    this.patientList = [];
    var data = null;
    var send_data = {
      doc_reg_id: this.dog_reg_id,
      hptl_clinic_id: this.hptl_clinic_id,
      country: ipaddress.country_code.toString(),
      imei: Helper_Class.getIPAddress(),
      type: "Billing",
      floor: this.floorvalue
    }
    console.log("INPATIENT send_data " + JSON.stringify(send_data))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gpat',
      (send_data),
      { headers: headers })
      .subscribe(
        response => {
          this.floorarray = [];
          this.patientList = [];
          var data = response.json();
          console.log("check datta " + JSON.stringify(data));
          if (data.floors != undefined) {
            this.floorarray.push("All")
            for (var k = 0; k < data.floors.length; k++) {
              this.floorarray.push(data.floors[k])
            }
          }
          if (data.patients != undefined && data.patients.length != 0) {
            this.count = data.patients.length;
            for (var i = 0; i < data.patients.length; i++) {
              var values = data.patients[i];
              var pname, fname, lname, mname, fullname;
              if (values.middle_name != undefined) {
                if (values.middle_name != "") {
                  pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.middle_name) + " " + encrypt_decript.Decript(values.last_name);
                  fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.middle_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                  mname = encrypt_decript.Decript(values.middle_name);
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                } else {
                  pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
                  fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                  mname = "";
                }
              } else {
                pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
                fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                fname = encrypt_decript.Decript(values.first_name);
                lname = encrypt_decript.Decript(values.last_name);
                mname = "";
              }

              var wardname = "", salutation_desc;
              if (values.salutation_desc != undefined) {
                salutation_desc = values.salutation_desc + ".";
              }
              else {
                salutation_desc = " "
              }
              var docname
              var ward_text;
              var wardname = "";
              if (values.ward_name != undefined) {
                wardname = values.ward_name;
              }
              else {
                wardname = "Bed Not allocated"
              }
              var bedno = "";
              if (values.bed_no != undefined && values.bed_no != "") {
                bedno = values.bed_no;
                ward_text = wardname + " - Bed:" + bedno;
              } else {
                bedno = "";
                ward_text = wardname
              }

              var surdate = "";
              if (values.surgery_date != undefined) {
                var sdate = values.surgery_date.split('-');
                surdate = sdate[2] + "-" + sdate[1] + "-" + sdate[0];
              } else {
                sdate = "";
              }

              var cdate;
              if (values.created_date != undefined) {
                var cdval = values.created_date.split('-');
                cdate = cdval[2] + "-" + cdval[1] + "-" + cdval[0]
              } else {
                cdate = "";
              }
              var ctime;
              if (values.created_time != undefined) {
                ctime = Time_Formate(values.created_time);
              } else {
                ctime = "";
              }
              var image
              if (values.profile_image != undefined) {
                if (values.profile_image == "default") {
                  image = "../../../assets/img/default.jpg";
                } else {
                  image = ipaddress.Ip_with_img_address.toString() + values.profile_image;
                }
              }

              if (values.payment_mode != undefined) {
                this.payment_mode = values.payment_mode
                if (this.payment_mode == "Insurance") {
                  this.sponsorDueflag = true
                } else {
                  this.sponsorDueflag = false;
                }
              }
              if (values.doc_name != undefined) {
                docname = values.doc_name
              } else {
                docname = ""
              }
              var gender, age;
              if (values.age != undefined) {
                age = values.age
              }
              if (values.gender != undefined) {
                gender = encrypt_decript.Decript(values.gender);
                gender = gender[0];
                if (gender != undefined && gender[0] == 'T') {
                  gender = 'Tgen';
                }
              }
              var gender_age, address1, address2, location, district, zipcode, state, country, locationcheck, districtcheck;
              if (age != undefined && gender != undefined) {
                gender_age = age + " / " + gender
              } else {
                if (age == undefined) {
                  gender_age = gender
                } else {
                  gender_age = age
                }
              }

              if (values.address2 != null && values.address2 != undefined && values.address1 != undefined) {
                address1 = encrypt_decript.Decript(values.address1) + ",";
                if (values.address2 != " " && values.address2 != "null") {
                  if (encrypt_decript.Decript(values.address2) == "") {
                    address2 == "";
                  } else {
                    address2 = encrypt_decript.Decript(values.address2) + ",";
                  }
                }
              } else if (values.address1 != undefined) {
                address1 = encrypt_decript.Decript(values.address1) + ",";
              } else if (values.address2 != undefined) {
                address2 = encrypt_decript.Decript(values.address2) + ",";
              }

              if (values.location != undefined) {
                locationcheck = values.location
              }
              if (values.location != undefined) {
                location = values.location + ',';
              }

              if (values.district != undefined) {
                districtcheck = values.district
              }
              if (values.district != undefined) {
                district = values.district + ' -';
              }

              if (values.zipcode != undefined) {
                zipcode = encrypt_decript.Decript(values.zipcode) + ",";
              }

              if (values.state != undefined) {
                state = values.state + " -";
              }

              if (values.country != undefined) {
                country = values.country + ".";
              }

              if (values.hosp_inp_id != undefined) {
                var hosp_inp_id1 = values.hosp_inp_id.split("-");
                var hosp_inp_id2 = hosp_inp_id1[0] + hosp_inp_id1[1];
              }

              var admission_date, relation_id;
              if (values.admission_date != undefined) {
                admission_date = values.admission_date;
              }

              if (values.relation_id != undefined) {
                relation_id = values.relation_id;
              }
              var address;
              if (values.address2 != undefined && values.address1 != undefined) {
                address = encrypt_decript.Decript(values.address1) + ", " + encrypt_decript.Decript(values.address2)
              } else if (values.address1 != undefined) {
                address = encrypt_decript.Decript(values.address1);
              } else {
                address = "";
              }

              if (values.gender != undefined) {
                gender = encrypt_decript.Decript(values.gender);
                gender = gender[0];
                if (gender != undefined && gender[0] == 'T') {
                  gender = 'Tgen';
                }
              }
              var discharge_date;
              if (values.discharge_date != undefined) {
                var dval = values.discharge_date.split('-');
                discharge_date = dval[2] + "-" + dval[1] + "-" + dval[0]
              } else {
                discharge_date = "";
              }
              var discharge_time;
              if (values.discharge_time != undefined) {
                discharge_time = Time_Formate(values.discharge_time);
              } else {
                discharge_time = "";
              }
              var barcode_flag = false, barcode_url = "";
              if (values.barcode != undefined) {
                barcode_flag = true;
                barcode_url = ipaddress.Ip_with_img_address + values.barcode;
                console.log("this.barcode_url" + barcode_url);
              }
              this.patientList.push({
                salutation_desc: salutation_desc,
                client_reg_id: values.client_reg_id,
                bed_img: '../../../assets/ui_icons/bed_icon.svg',
                patient_name: salutation_desc + pname,
                patientname: pname,
                surgery_desc: values.surgery_desc,
                patient_namevalue: pname,
                fullname: fullname,
                inpatientId: values.inpatient_id,
                admit_date: cdate,
                admit_time: ctime,
                admitdatetime: cdate + " " + ctime,
                admission_date: admission_date,
                admitted_date: admission_date,
                admitted_time: ctime,
                relation_id: relation_id,
                ward_name: wardname,
                bed_no: bedno,
                mobile: values.mobile,
                profile_image: image,
                first_name: values.first_name,
                last_name: values.last_name,
                middle_name: values.middle_name,
                ward_text: ward_text,
                inpatient_id: values.inpatient_id,
                hosp_inp_id: values.hosp_inp_id,
                speciality: values.speciality,
                specialization_name: values.speciality,
                surgery_date: surdate,
                disflag: false,
                ward_id: values.ward_id,
                doctor_name: docname,
                doctorname: docname,
                doc_reg_id: values.doc_reg_id,
                doc_app_id: values.doc_app_id,
                gender_age: gender_age,
                address: address,
                address1: values.address1,
                address2: values.address2,
                locationcheck: locationcheck,
                location: location,
                districtcheck: districtcheck,
                district: district,
                zipcode: zipcode,
                state: state,
                country: country,
                hosp_inp_idvalue: hosp_inp_id2,
                mobilenumber: encrypt_decript.Decript(values.mobile),
                mobilenuo: encrypt_decript.Decript(values.mobile),
                patient_id: values.patient_id,
                mlc: values.mlc_type,
                age: values.age,
                gender: gender,
                doc_qualif: values.doc_qualif,
                insurer: values.insurer,
                insCardNo: values.ins_card_no,
                policyno: values.policy_no,
                CCNno: values.ins_ccn_no,
                tpa: values.ins_tpa,
                approved_amount: values.approved_amount,
                discharge_date: discharge_date,
                discharge_time: discharge_time,
                rate_plan: values.rate_card_desc,
                payment_mode: values.payment_mode,
                barcode_flag: barcode_flag,
                barcode_url: barcode_url,
              });
              Helper_Class.setinpatientfilterdata(this.patientList);
            }
            console.log("this.patientListthis.patientList" + JSON.stringify(this.patientList));
          } else {
            this.count = "0";
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getDoctors() {
    console.log("get doc ==" + JSON.stringify({
      country: "IN",
      reg_id: Helper_Class.getInfo().user_id,
      imei: Helper_Class.getIPAddress(),
      type: "front-desk",
      hosp_id: this.hptl_clinic_id,
    }))
    this.doctorList = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/docbyrep',
      {
        country: "IN",
        reg_id: Helper_Class.getInfo().user_id,
        imei: Helper_Class.getIPAddress(),
        type: "front-desk",
        hosp_id: this.hptl_clinic_id,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj != null) {
            this.doctorList.push({
              Doc_Name: "All",
              Doc_ID: "All",
            });

            for (var i = 0; i < obj.doctors.length; i++) {
              this.doctorName = "";
              var fname, mname, lname;
              if (obj.doctors[i].middle_name != "" && obj.doctors[i].middle_name != undefined) {
                this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name; mname = obj.doctors[i].middle_name; lname = obj.doctors[i].last_name;
              } else {
                this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name; lname = obj.doctors[i].last_name;
              }
              this.doctorList.push({
                Doc_Name: this.doctorName,
                Doc_ID: obj.doctors[i].prov_id,
              });
            }
            this.doctor = this.doctorList[0].Doc_ID;
            this.changeDoctor(this.doctor);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  viewChange(value) {
    if (value == "graph1") {
      this.graphicalformat = true;
      this.graph_defaultimg = false;
      this.tabular_format = false;
    } else if (value == "tabular") {
      this.graphicalformat = false;
      this.graph_defaultimg = false;
      this.tabular_format = true;
    } else {
      this.tabular_format = false;
      this.graphicalformat = false;
      this.graph_defaultimg = true;
    }
  }

  changefloorvalue(value) {
    this.floorvalue = value;
    this.getPatients();
  }

  Filterbydata(data) {
    this.mrno = "";
    this.name = "";
    this.location = "";
    this.mobile = "";
    console.log("check data" + JSON.stringify(Helper_Class.getinpatientfilterdata()))
    if (data == "mrno") {
      this.mrnoflag = true;
      this.locationflag = false;
      this.nameflag = false;
      this.fdateflag = false;
      this.todateflag = false;
      this.mobileflag = false;
    }
    if (data == "name") {
      this.mrnoflag = false;
      this.nameflag = true;
      this.locationflag = false;
      this.fdateflag = false;
      this.todateflag = false;
      this.mobileflag = false;
    }
    if (data == "location") {
      this.mrnoflag = false;
      this.nameflag = false;
      this.locationflag = true;
      this.fdateflag = false;
      this.todateflag = false;
      this.mobileflag = false;
    }
    if (data == "date") {
      this.fdateflag = true;
      this.todateflag = true;
      this.mrnoflag = false;
      this.nameflag = false;
      this.locationflag = false;
      this.mobileflag = false;
      this.filtercheck();
    }
    if (data == "mobile") {
      this.fdateflag = false;
      this.todateflag = false;
      this.mrnoflag = false;
      this.nameflag = false;
      this.locationflag = false;
      this.mobileflag = true;
    }
    this.patientList = Helper_Class.getinpatientfilterdata();
  }

  filtercheck() {
    console.log("chekc value  --" + JSON.stringify(this.patientList))
    if (this.nameflag == true) {
      let filteredArray = this.patientList.filter(obj => obj.patient_namevalue.toLowerCase().includes(this.name.toLowerCase()));
      console.log("filteredArray ***  --" + JSON.stringify(filteredArray))
      this.filtermethod("name", this.name, '')
    }
    if (this.mobileflag == true) {
      let filteredArray = this.patientList.filter(obj => obj.mobilenumber == this.mobile);
      console.log("filteredArray ***  --" + JSON.stringify(filteredArray));
      this.filtermethod("mobile", this.mobile, '');
    }

    if (this.locationflag == true) {
      let filteredArray1 = this.patientList.filter(obj => obj.locationcheck.trim().toLowerCase() === this.location.trim().toLowerCase());
      console.log("filteredArray loctaion  --" + JSON.stringify(filteredArray1));
      this.filtermethod("location", this.location, '')
    }

    if (this.mrnoflag == true) {
      let filteredArray = this.patientList.filter(obj => obj.hosp_inp_idvalue.toLowerCase() === this.mrno.toLowerCase());
      console.log("filteredArray mrno  --" + JSON.stringify(filteredArray))
      this.patientList = [];
      this.patientList = filteredArray;
      this.filtermethod("mr_no", this.mrno, '');
    }
    if (this.fdateflag == true && this.todateflag == true) {
      let fromDateObj = new Date(this.fromDate.split("-").reverse().join("-"));
      let toDateObj = new Date(this.toDate.split("-").reverse().join("-"));
      let filteredArraycheck = this.patientList.filter(obj => {
        // Convert admit_date to a Date object
        let admitDateObj = new Date(obj.admit_date.split("-").reverse().join("-"));
        // Check if admit_date is between fromDate and toDate
        return (admitDateObj >= fromDateObj && admitDateObj <= toDateObj);
      });
      console.log("filteredArray date   --" + JSON.stringify(filteredArraycheck));
      this.filtermethod("date", '', '');
    }
  }

  filtermethod(type, name, name1) {
    this.patientList = [];
    var senddata;
    if (type == "date") {
      senddata = {
        filter: type,
        fdate: (this.fromDate),
        tdate: (this.toDate), hptl_clinic_id: this.hptl_clinic_id
      }
    }
    if (type == "name") {
      senddata = {
        filter: type,
        fname: encrypt_decript.Encript(this.name).toString(), hptl_clinic_id: this.hptl_clinic_id
      }
    }
    if (type == "mobile") {
      senddata = {
        filter: type,
        mobile: encrypt_decript.Encript(this.mobile).toString(),
        hptl_clinic_id: this.hptl_clinic_id
      }
    }
    if (type == "mr_no") {
      senddata = {
        filter: type,
        mr_no: this.mrno, hptl_clinic_id: this.hptl_clinic_id
      }
    }
    if (type == "location") {
      senddata = {
        filter: type,
        location: this.location, hptl_clinic_id: this.hptl_clinic_id
      }
    }
    console.log("filter basedheck ---" + JSON.stringify(senddata))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.filterbasedurl, senddata,
      { headers: headers }).subscribe(
        response => {
          var data = JSON.parse(JSON.stringify(response));
          console.log("check ---" + JSON.stringify(data))
          this.patientList = [];
          if (data.patients != undefined && data.patients.length != 0) {
            this.count = data.patients.length;
            for (var i = 0; i < data.patients.length; i++) {
              var values = data.patients[i];
              var pname, fname, lname, mname, fullname;
              if (values.middle_name != undefined) {
                if (values.middle_name != "") {
                  pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.middle_name) + " " + encrypt_decript.Decript(values.last_name);
                  fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.middle_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                  mname = encrypt_decript.Decript(values.middle_name);
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                } else {
                  pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
                  fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                  mname = "";
                }
              } else {
                pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
                fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                fname = encrypt_decript.Decript(values.first_name);
                lname = encrypt_decript.Decript(values.last_name);
                mname = "";
              }
              var wardname = "", salutation_desc;
              if (values.salutation_desc != undefined) {
                salutation_desc = values.salutation_desc;
              }
              else {
                salutation_desc = " "
              }
              var docname
              var ward_text;
              var wardname = "";
              if (values.ward_name != undefined) {
                wardname = values.ward_name;
              }
              else {
                wardname = "Bed Not allocated"
              }
              var bedno = "";
              if (values.bed_no != undefined && values.bed_no != "") {
                bedno = values.bed_no;

                ward_text = wardname + " - " + bedno;
              } else {
                bedno = "";
                ward_text = wardname
              }

              var surdate = "";
              if (values.surgery_date != undefined) {
                var sdate = values.surgery_date.split('-');
                surdate = sdate[2] + "-" + sdate[1] + "-" + sdate[0];
              } else {
                sdate = "";
              }

              var cdate;
              if (values.created_date != undefined) {
                var cdval = values.created_date.split('-');
                cdate = cdval[2] + "-" + cdval[1] + "-" + cdval[0]
              } else {
                cdate = "";
              }
              var ctime;
              if (values.created_time != undefined) {
                ctime = Time_Formate(values.created_time);
              } else {
                ctime = "";
              }
              var image
              if (values.profile_image != undefined) {
                if (values.profile_image == "default") {
                  image = "../../../assets/img/default.jpg";

                } else {
                  image = ipaddress.Ip_with_img_address.toString() + values.profile_image;


                }
              }
              if (values.doc_name != undefined) {
                docname = "Dr." + values.doc_name
              } else {
                docname = ""
              }
              var gender, age, location, district;
              if (values.age != undefined) {
                age = values.age
              }
              if (values.gender != undefined) {
                gender = encrypt_decript.Decript(values.gender);
                gender = gender[0];
                if (gender != undefined && gender[0] == 'T') {
                  gender = 'Tgen';
                }
              }
              var gender_age, locationcheck, districtcheck;
              if (age != undefined && gender != undefined) {
                gender_age = age + "/" + gender
              } else {
                if (age == undefined) {
                  gender_age = gender
                } else {
                  gender_age = age
                }
              }
              if (values.location != undefined) {
                locationcheck = values.location
              }
              if (values.district != undefined) {
                districtcheck = values.district
              }
              if (values.hosp_inp_id != undefined) {
                var hosp_inp_id1 = values.hosp_inp_id.split("-");
                var hosp_inp_id2 = hosp_inp_id1[0] + hosp_inp_id1[1];
              }
              var discharge_date;
              if (values.discharge_date != undefined) {
                var dval = values.discharge_date.split('-');
                discharge_date = dval[2] + "-" + dval[1] + "-" + dval[0];
              } else {
                discharge_date = "";
              }
              var discharge_time;
              if (values.discharge_time != undefined) {
                discharge_time = Time_Formate(values.discharge_time);
              } else {
                discharge_time = "";
              }
              var barcode_flag = false, barcode_url = "";
              if (values.barcode != undefined) {
                barcode_flag = true;
                barcode_url = ipaddress.Ip_with_img_address + values.barcode;
                console.log("this.barcode_url" + barcode_url);
              }

              this.patientList.push({
                client_reg_id: values.client_reg_id,
                bed_img: '../../../assets/ui_icons/bed_icon.svg',
                patient_name: salutation_desc + " " + pname,
                patient_namevalue: pname,
                fullname: fullname,
                admit_date: cdate,
                admit_time: ctime,
                admitdatetime: cdate + " " + ctime,
                ward_name: wardname,
                bed_no: bedno,
                mobile: values.mobile,
                patient_id: values.patient_id,
                profile_image: image,
                first_name: fname,
                last_name: lname,
                middle_name: mname,
                ward_text: ward_text,
                inpatient_id: values.inpatient_id,
                hosp_inp_id: values.inp_hosp_id,
                speciality: values.speciality,
                surgery_desc: values.surgery_desc,
                surgery_date: surdate,
                disflag: false,
                ward_id: values.ward_id,
                doctor_name: docname,
                gender_age: gender_age,
                districtcheck: districtcheck,
                locationcheck: locationcheck,
                hosp_inp_idvalue: hosp_inp_id2,
                mobilenumber: encrypt_decript.Decript(values.mobile),
                mobilenuo: encrypt_decript.Decript(values.mobile),
                mlc: values.mlc_type,
                discharge_date: discharge_date,
                discharge_time: discharge_time,
                barcode_flag: barcode_flag,
                barcode_url: barcode_url,
                approved_amount: values.approved_amount
              });
              console.log("this.patientListthis.patientList" + JSON.stringify(this.patientList))
            }
          } else {
            this.count = "0";
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
  }

  getdatavaluemrno(value) {
    if (!this.mrno || this.mrno.trim() === '') {
      console.log('Input value is empty');
      this.patientList = Helper_Class.getinpatientfilterdata();
      // Handle the empty value case
    } else {
      console.log('Input value:', this.mrno);
      this.filtermethod("mr_no", this.mrno, '');
      // Your logic for non-empty value
    }
  }

  getdatamobilenumber(vlaue) {
    if (!this.mobile || this.mobile.trim() === '') {
      console.log('Input value is empty');
      this.patientList = Helper_Class.getinpatientfilterdata();
      // Handle the empty value case
    } else {
      console.log('Input value:', this.mobile);
      this.filtermethod("mobile", this.mobile, '');
      // Your logic for non-empty value
    }
  }

  getdatavalue(value) {
    if (!this.name || this.name.trim() === '') {
      console.log('Input value is empty');
      this.patientList = Helper_Class.getinpatientfilterdata();
      // Handle the empty value case
    } else {
      console.log('Input value:', this.name);
      this.filtermethod("mr_no", this.mrno, '');
      // Your logic for non-empty value
    }
  }

  mrnobased() {
    if (this.name != null)
      this.name = this.name.toLocaleUpperCase();
  }

  getdatavaluelocation(value) {
    if (!this.location || this.location.trim() === '') {
      console.log('Input value is empty');
      this.patientList = Helper_Class.getinpatientfilterdata();
      // Handle the empty value case
    } else {
      console.log('Input value:', this.location);
      if (this.location.length >= 3) {
        this.filtermethod("location", this.location, '');
      }
    }
  }

  selectFromDate(e) {
    this.fromDate = e;
  }

  selectToDate(e) {
    this.toDate = e;
  }

  viewPatient(patient_id, inpatient_id, mobile, regid, fname, mname, lname, speciality, list) {
    console.log("list view  0--" + JSON.stringify(list))
    this.totalBillAmountvalue = "0.00";
    this.sponsorDue = '0.00';
    this.total_advance = "0.00";
    this.paid_bill = '0.00';
    this.remaining_billbal = '0.00';
    this.discountvaluecheck = 0;
    this.amountval = '0';
    this.listview = false;
    if (list.payment_mode == "Insurance") {
      this.sponsorDueflag = true;
    } else {
      this.sponsorDueflag = false;
    }
    this.clearData();
    this.filter == 'Patient_ID';
    this.patientId = list.patient_id;
    this.searchPatient();
  }

  Appointment_change(client_id, rel_id, Sub_id, patientDetails, approved_amount) {
    console.log("in patientDetails " + JSON.stringify(patientDetails));
    this.Insuranceflag = false;
    this.Insuranceflag = false;
    if (approved_amount != undefined) {
      this.sponsorDueflag = true;
      this.sponsorDue = parseFloat(approved_amount).toFixed(2);
    }
    if (this.sponsorDue == "0.00") {
      this.sponsorDueflag = false;
    }
    this.billing_print_array = [];
    this.client_id = client_id;
    this.sub_id = Sub_id;
    this.rel_id = rel_id;
    this.tempparray = patientDetails;
    this.app_id = this.tempparray.doc_app_id;
    this.inpatientId = this.tempparray.inpatientId;
    this.admitted_date = Date_Formate(this.tempparray.admitted_date);
    var data1 = this.tempparray.admitted_time.split(":")
    this.admitted_time = data1[0] + ":" + data1[1];
    this.admitted_timevalue = this.tempparray.admitted_time;
    this.inpid = patientDetails.inpatientId;
    this.insurer = patientDetails.insurer != undefined ? patientDetails.insurer : "";
    this.insCardNo = patientDetails.insCardNo;
    this.policyno = patientDetails.policyNo;
    this.CCNno = patientDetails.CNNno;
    this.approved_amount = patientDetails.approved_amount;
    this.clientnamenew = patientDetails.salutation_desc + patientDetails.patientname;
    this.mobilenumber = patientDetails.mobilenuo;
    this.doctorname = "Dr." + patientDetails.doctorname;
    this.inpatientidnew = patientDetails.patient_id;
    this.age = patientDetails.age;
    this.gender = patientDetails.gender != undefined ? patientDetails.gender : "";
    this.bedno = patientDetails.bed_no != undefined ? patientDetails.bed_no : "";
    this.agegendervalue = this.age + " / " + this.gender;
    this.mrno = patientDetails.mr_no;
    this.ward_name = patientDetails.ward_name != undefined ? patientDetails.ward_name : "";
    this.surgery_name = patientDetails.surgery_desc != undefined ? patientDetails.surgery_desc : "";
    this.doc_qualif = patientDetails.doc_qualif != undefined ? patientDetails.doc_qualif : "";

    if (this.specialization_name != undefined) {
      this.specialization_name = this.specialization_name;
    } else {
      if (patientDetails.specialization_name != undefined) {
        this.specialization_name = patientDetails.specialization_name;
      } else {
        this.specialization_name = ""
      }
    }
    if (this.dischargeTime != undefined) {
      this.dischargeTime = this.dischargeTime;
    } else {
      if (patientDetails.dischargeTime != undefined) {
        this.dischargeTime = patientDetails.dischargeTime;
      } else {
        this.dischargeTime = "";
      }
    }
    if (this.rate_plan != undefined) {
      this.rate_plan = this.rate_plan;
    } else {
      if (patientDetails.rate_plan != undefined) {
        this.rate_plan = patientDetails.rate_plan;
      } else {
        this.rate_plan = "";
      }
    }
    if (patientDetails.insurer != undefined) {
      this.insuranceFlag = true;
      this.Insuranceflag = true;
    }
    if (patientDetails.barcode != undefined) {
      this.barcode_flag = true;
      this.barcode_url = ipaddress.Ip_with_img_address + patientDetails.barcode;
      console.log("this.barcode_url" + this.barcode_url)
    }
    var headers = new Headers();
    var send_data;
    if (this.Filter_txt == "Date") {
      send_data = JSON.stringify({
        client_reg_id: client_id,
        relation_id: rel_id,
        sub_rel_id: Sub_id,
        app_id: this.tempparray.doc_app_id,
        from_date: this.f_date,
        to_date: this.t_date,
      });
    } else {
      send_data = JSON.stringify({
        client_reg_id: client_id,
        relation_id: rel_id,
        app_id: this.tempparray.doc_app_id,
        sub_rel_id: Sub_id,
      });
    }
    console.log("check ------------" + JSON.stringify(patientDetails));
    if (patientDetails.middle_name != undefined) {
      this.patient_name = patientDetails.salutation_desc + "." + encrypt_decript.Decript(patientDetails.first_name) + " " + encrypt_decript.Decript(patientDetails.middle_name) + " " + encrypt_decript.Decript(patientDetails.last_name);
    } else {
      if (patientDetails.last_name != undefined) {
        this.patient_name = patientDetails.salutation_desc + "." + encrypt_decript.Decript(patientDetails.first_name) + " " + encrypt_decript.Decript(patientDetails.last_name);
      } else {
        this.patient_name = encrypt_decript.Decript(patientDetails.first_name);
      }
    }
    if (patientDetails.balance != undefined) {
      this.amount_collected = true;
      this.balance_show = false;
      this.previous_balshow = false;
      this.balance_data = parseFloat(patientDetails.balance).toFixed();
      this.total_collected = parseFloat(patientDetails.balance).toFixed();
    }
    if (this.listview == true) {
      this.dog_reg_id = "All"
    } else {
      this.dog_reg_id = patientDetails.doc_reg_id;
    }
    var zipcode = patientDetails.czipcode != undefined ? encrypt_decript.Decript(patientDetails.czipcode) : "";
    this.admissionDate = patientDetails.admitted_date != undefined ? Date_Formate(patientDetails.admitted_date) : "";
    if (patientDetails.admitted_date != undefined) {
      var admisTime = patientDetails.admitted_time.split(':');
      var h = admisTime[0], m = admisTime[1];
      this.admissionTime = (h > 12) ? (h - 12 + ':' + m + ' PM') : (h + ':' + m + ' AM');
    }
    this.dischargeDate = patientDetails.discharge_date != undefined ? patientDetails.discharge_date : "";
    this.dischargeTime = patientDetails.discharge_time != undefined ? patientDetails.discharge_time : "";
    this.rate_plan = patientDetails.rate_plan != undefined ? patientDetails.rate_plan : "";

    this.billing_print_array.push({
      dr_name: "Dr." + patientDetails.docid_name,
      licence_code: "",
      hptl_name: "",
      created_date: "",
      address: patientDetails.address + ",",
      location: patientDetails.location,
      city: patientDetails.district + " " + patientDetails.zipcode,
      state: patientDetails.state + " " + patientDetails.country,
      telephone: patientDetails.telephone,
      inpatientId: patientDetails.inpatientId,
      appointment_id: patientDetails.doc_app_id,
      client_name: this.patient_name,
      caddress: "",
      clocation: patientDetails.clocation + ", ",
      clientcity: patientDetails.ccity + " - " + zipcode + ",",
      clestare: patientDetails.cstate + " - " + patientDetails.ccountry + ".",
      client_reg_id: patientDetails.client_reg_id,
      advance: patientDetails.advance,
      balance: patientDetails.balance,
      doctorname: "Dr" + "." + patientDetails.doctorname,
      barcode_flag: patientDetails.barcode_flag,
      barcode_url: patientDetails.barcode_url
    });
    this.docBills = [];
    this.medBills = [];
    this.diagBills = [];
    this.DisplayBills = [];
    this.sendDoctor = [];
    this.sendPharmacy = [];
    this.sendDiagnosis = [];
    this.totalBillAmount = "0.00";
    this.totalPaidAmount = "0.00";
    this.TotalBalance = "0.00";
    this.final_amount = "0.00";
    this.totalDiscount = "0.00";
    this.totalPaid = "0.00";
    this.totalBill = "0.00";
    this.refund_amount = 0;
    this.refund_reason = '';
    this.adv_refflag = false;
  }

  backtoApplist(data) {
    if (data == "view") {
      this.listview = true;
      this.selectvalue = "mrno";
      this.mrno = '';
      this.floorvalue = "All";
      this.view_type = "graph";
      this.doctor = "All";
      this.dog_reg_id = "All";
      this.getPatients();
    }
  }

  refundcal() {
    console.log("remaing_billbal",this.remaing_billbal)
    if(this.adv_refflag == true && parseInt(this.paid_bill) == 0 && parseInt(this.remaing_billbal) != 0) {
      if(parseFloat(this.remaing_billbal) > 5000) {
        for(var k = 0; k < this.paytypelist.length; k++) {
          if(this.paytypelist[k].pay_desc == "NEFT") {
            this.payType1 = this.paytypelist[k].pay_id;
            if (this.paymentsarray.length == 0) {
              this.amountval = parseFloat(this.remaing_billbal);
            }
            this.bank_details_flag_adv = true;
          }
        } 
        if (!this.bank_details_flag_adv) {
          console.log(parseFloat(this.remaing_billbal));
          if (this.paymentsarray.length == 0) {
            this.payType1 = this.paytypelist[0].pay_id;
            this.amountval = parseFloat(this.remaing_billbal);
          }
        }
      } else {
        this.bank_details_flag_adv = false;
        console.log(parseFloat(this.remaing_billbal));
        if (this.paymentsarray.length == 0) {
          this.payType1 = this.paytypelist[0].pay_id;
          this.amountval = parseFloat(this.remaing_billbal);
        }
      }
      console.log("bank_details_flag_adv ---",this.bank_details_flag_adv);
    } else {
      this.bank_details_flag_adv = false;
    }
  }

  changePaymentType() { 
    if (this.adv_refflag) {
      for(var i = 0; i < this.paytypelist.length; i++) {
        if(this.payType1 == this.paytypelist[i].pay_id) {
          if(this.paytypelist[i].pay_desc == "NEFT") {
            this.bank_details_flag_adv = true;
          } else {
            this.bank_details_flag_adv = false;
          }
        }
      }
    } else {
      this.bank_details_flag_adv = false;
    } 
  }

  advanceRefundCalculation() {
    this.refund_array = []
    if (this.advanceList != undefined && this.advanceList.length != 0) {
      if (parseFloat(this.remaing_billbal) != 0) {
        var refund_total_amount:any = parseFloat(this.remaing_billbal);
        for (var i = 0; i < this.advanceList.length; i++) {
          if (parseFloat(refund_total_amount) != 0) {
            var refund_amount:any = 0, balance:any = 0;
            if (parseFloat(this.advanceList[i].amount) >= parseFloat(refund_total_amount)) {
              refund_amount = parseFloat(refund_total_amount);
              balance = parseFloat(this.advanceList[i].amount) - parseFloat(refund_total_amount);
              refund_total_amount = parseFloat("0.00");
            } else {
              refund_amount = parseFloat(this.advanceList[i].amount);
              balance = parseFloat("0.00");
              refund_total_amount =  parseFloat(refund_total_amount) - parseFloat(this.advanceList[i].amount);
            }
            console.log(parseFloat(refund_amount) + " --- " + parseFloat(balance) + " --- " + parseFloat(refund_total_amount))
            this.refund_array.push({
              advance_id: this.advanceList[i].advance_id,
              refund_amount: refund_amount,
              balance: balance,
              refund_reason: this.refund_reason,
            })
          }        
        }
      }
    }
  }
}
