<div class="row" style="margin:0 auto; width:100%">
   <div class="col-12">
      <mdb-card>
         <mdb-card-header class="bg-white " style="padding:7px 20px;">
            <div class="headerCover">
               <div class="headerTilte app_list_header">
                  <h5 *ngIf="specimenPageType != 'radiology'" class="m-0" class="mainHeadingStyle">Specimen Collection
                  </h5>
                  <h5 *ngIf="specimenPageType == 'radiology'" class="m-0" class="mainHeadingStyle">Visits</h5>
               </div>

               <div class="headerButtons" style="width: 50px; text-align: left;">
                  <mat-label class="matlabel">Date:&nbsp; </mat-label>
               </div>
               <div class="headerButtons" style="width: 50px;">
                  <input type="date" class="ipcss" (change)="getSampleList(sampledate)" [(ngModel)]="sampledate"
                     #matInput>
               </div>
               <div class="headerButtons" style="width: 50px;">
                  <mat-label class="matlabel"> or&nbsp;&nbsp;&nbsp; </mat-label>
               </div>
               <div class="headerButtons" style="width: 50px;">
                  <mat-label class="matlabel"> Duration:&nbsp; </mat-label>
               </div>
               <div class="headerButtons" style="width: 50px;">
                  <select [(ngModel)]="filtduration" class="ipcss" (change)="getSampleList(sampledate)">
                     <option value="select">Select</option>
                     <option value="7">1 week</option>
                     <option value="30">1 month</option>
                     <option value="91">3 months</option>
                     <option value="183">6 months</option>
                     <option value="365">1 year</option>
                  </select>
               </div>
               <div class="headerButtons" style="width: 50px;">
                  <mat-label class="matlabel"><b>Total Count:</b>&nbsp; </mat-label>
                  <mat-label class="matlabel">{{filteredSamplelistArray.length}}</mat-label>
               </div>
               <div class="headerButtons">
                  <img src="../../../assets/ui_icons/buttons/cancel_button.svg" [hidden]="save_btn" *ngIf="specimenPageType != 'radiology'"
                     class="saveimgbtn_inpatinfo mx-3" data-bs-toggle="modal" data-bs-target="#cancelSampleModal" />
                  <img src="../../../assets/ui_icons/buttons/save_button.svg" [hidden]="save_btn"
                     (click)="saveSamples2()" class="saveimgbtn_inpatinfo" *ngIf="specimenPageType != 'radiology'" />
                  <img src="../../../assets/ui_icons/buttons/save_button.svg" [hidden]="save_btn"
                     (click)="saveSamples2()" class="saveimgbtn_inpatinfo" *ngIf="specimenPageType == 'radiology'" />
                  <!-- <button id="device" (click)="summa_print()"> print </button> -->
               </div>
            </div>
         </mdb-card-header>
         <mdb-card-body>
            <!-- <div class="row"> -->
            <div>
               <p class="nodata" [hidden]='sampleList.length!=0'>No test(s) found</p>
               <div class="container-fluid" *ngIf="sampleList.length">
                  <div class="row">
                     <div class="col-3 m-3" style="margin-left: 0px !important">
                        <input type="text" class="ipcss" aria-label="Number" [(ngModel)]="searchInput"
                           (keyup)="filterSamplelistArray(searchInput)" placeholder="Search by MR.No or Name"
                           matInput />
                     </div>
                     <div class="col-12">
                        <div>
                           <mat-accordion displayMode="flat" multi="false" class="mat-table">
                              <section matSort class="mat-elevation-z2 mat-header-row"
                                 style="background-color: #c1e6fb;">
                                 <span class="mat-header-cell">Mr.No</span>
                                 <span class="mat-header-cell">Patient Name</span>
                                 <span class="mat-header-cell">Bill/Pres Date & time</span>
                                 <!-- <span class="mat-header-cell">Bill Time</span> -->
                                 <span class="mat-header-cell">Doctor Name</span>
                                 <span class="mat-header-cell">Ward Name</span>
                                 <span class="mat-header-cell">Bed No</span>
                              </section>

                              <mat-expansion-panel *ngFor="let list of filteredSamplelistArray">
                                 <mat-expansion-panel-header class="mat-row" (click)="getSampleDetails(list)">
                                    <span class="mat-cell">{{list.mrno}}</span>
                                    <span class="mat-cell">{{list.patient_name}}</span>
                                    <span class="mat-cell">{{list.bill_date}} {{list.bill_time}}</span>
                                    <!-- <span class="mat-cell">{{list.bill_time}}</span> -->
                                    <span class="mat-cell">{{list.dr_name}}</span>
                                    <span class="mat-cell">{{list.ward_name}}</span>
                                    <span class="mat-cell">{{list.bed_no}}</span>
                                 </mat-expansion-panel-header>
                                 <div>
                                    <p class="nodata" [hidden]='sampleDetails_data.length!=0'>No test(s) found</p>
                                    <table *ngIf="sampleDetails_data.length">
                                       <thead>
                                          <tr>
                                             <th>Test No</th>
                                             <th>Test Name</th>
                                             <!-- <th>Subtest Name</th> -->

                                             <th *ngIf="specimenPageType != 'radiology'">Specimen Type</th>
                                             <th *ngIf="specimenPageType != 'radiology'">Tube Type</th>
                                             <th>Priority</th>

                                             <th *ngIf="specimenPageType != 'radiology'">Assertion Reason</th>
                                             <th>Outsource</th>
                                             <th style="width: 5%; "><input type="checkbox" (change)="selectAll()"
                                                   [(ngModel)]="isSelected"></th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr *ngFor="let data of sampleDetails_data">
                                             <td>{{data.test_no}}</td>
                                             <!-- <td>{{data.test_type_name}}</td> -->
                                             <td>{{data.subtest_name}}</td>

                                             <td *ngIf="specimenPageType != 'radiology'">
                                                <mat-label class="matlabel"></mat-label>
                                                <mat-select class="ipcss" multiple [(ngModel)]="data.selected_specimen"
                                                   (selectionChange)="getTubetype(data, data.selected_specimen);">
                                                   <mat-option *ngFor="let specimen of data.specimen_types"
                                                      value="{{specimen.specimen_type_id}}">
                                                      {{specimen.specimen_desc}}</mat-option>
                                                </mat-select>
                                             </td>
                                             <td *ngIf="specimenPageType != 'radiology'">
                                                <div class="wrapper">
                                                   <div [style.background-color]="color.color_code"
                                                      *ngFor="let color of data.tube_color" class="box">
                                                      {{color.tube_size }}
                                                   </div>
                                                </div>
                                                <!-- {{data.tube_type}} -->
                                             </td>
                                             <td>
                                                <select class="ipcss" [(ngModel)]="data.priority">
                                                   <option value="0"> Normal </option>
                                                   <option value="1"> High </option>
                                                </select>
                                             </td>
                                             <td *ngIf="specimenPageType != 'radiology'">{{data.assertion_reason}}</td>
                                             <td>
                                                <mat-label class="matlabel" *ngIf="data.outsource_flag">
                                                   <select disableOptionCentering class="ipcss"
                                                      [(ngModel)]="data.selected_outsource">
                                                      <option *ngFor="let outsource of data.outsource" required
                                                         value={{outsource.out_id}}>
                                                         {{outsource.out_desc}}</option>
                                                   </select>
                                                </mat-label>
                                             </td>
                                             <td style="font-size: 12px;width: 5%; padding-left: 30px;">
                                                <mdb-checkbox [(ngModel)]="data.isSelected"
                                                   (change)="unselectAll(data.isSelected, data)"></mdb-checkbox>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                                 <!-- <mat-action-row>
                                    <button mat-button color="primary" [matMenuTriggerFor]="menu">Assertion</button>
                                    <mat-menu #menu="matMenu">
                                       <button mat-menu-item>Wrong Order</button>
                                       <button mat-menu-item>Patient Fixed But Not Collected</button>
                                    </mat-menu>
                                 </mat-action-row> -->
                              </mat-expansion-panel>
                           </mat-accordion>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- </div> -->
            <div [hidden]="true">
               <div #idprint id="idprint">
                  <div class="container-fluid m-1">
                     <div class="row">
                        <div class="col-6">
                           <!-- <div class="justify-content-center" style="text-align: center;">
                              <h1 style="font-size: 16px; letter-spacing: 4px;  color: rgb(139, 139, 251);">
                                 {{sample_id}}</h1>
                           </div> -->
                           <div *ngFor="let id of sample_id"
                              style="width: 185px; height: 88px; border-style: none; border-width: thin; border-radius: 10px; border-color: rgb(139, 139, 251); margin-top: 5px !important; margin-bottom: 0px !important; margin-left: 90px;">
                              <div style="padding: 6px;">
                                 <div class="row d-flex flex-column justify-content-center">
                                    <div class="col-12">
                                       <p
                                          style="font-size: 11px; margin-bottom: 0px !important; font-weight: bolder; letter-spacing: 1px;">
                                          {{patient_name}}</p>
                                    </div>
                                    <div class="col-12">
                                       <div class="d-flex align-items-center justify-content-center">
                                          <ngx-barcode class="barcode" [bc-value]="id.sample_id"
                                             [bc-display-value]="displayValue" [bc-line-color]="lineColor"
                                             [element-type]="elementType" [format]="format" [bc-width]="width"
                                             [bc-height]="barcodeheight" [bc-font-options]="fontOptions"
                                             [bc-font]="font" [bc-text-align]="textAlign"
                                             [bc-text-position]="textPosition" [bc-text-margin]="textMargin"
                                             [bc-font-size]="fontSize" [bc-background]="background" [bc-margin]="margin"
                                             [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                                             [bc-margin-left]="marginLeft"
                                             [bc-margin-right]="marginRight"></ngx-barcode>
                                       </div>
                                    </div>
                                    <div class="col-12">
                                       <div class="d-flex align-items-center justify-content-between">
                                          <div style="width: 35%;">
                                             <label
                                                style="font-size: 11px; margin-bottom: 0px !important; font-weight: bolder; letter-spacing: 1px;">{{id.sample_type}}</label>
                                             <!-- <p
                                             style="font-size: 12px; margin-bottom: 0px !important; font-weight: bolder; letter-spacing: 1px;">
                                                {{id.sample_type}}</p> -->
                                          </div>
                                          <div style="width: 35%;">
                                             <label
                                                style="font-size: 11px; margin-bottom: 0px !important; font-weight: bolder; letter-spacing: 1px;">{{mrval}}</label>
                                             <!-- <p
                                             style="font-size: 12px; margin-bottom: 0px !important; font-weight: bolder; letter-spacing: 1px;">
                                                {{mrval}}</p> -->
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div class="modal fade" id="cancelSampleModal" tabindex="-1" aria-labelledby="cancelSampleModalLabel"
               aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-header">
                        <h5 class="modal-title" id="cancelSampleModalLabel">Reason For Cancelling</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                     </div>
                     <div class="modal-body">
                        <textarea #matInput class="ipcss txtareaheight" maxlength="50"
                           [(ngModel)]="cancel_reason"></textarea>
                        <div class="radio-group mt-3">
                           <label class="radio-container mx-2">
                              <input type="radio" name="refund" value="true" [(ngModel)]="is_refund"
                                 class="radio-input mx-2" />
                              <span class="radio-label">With Refund</span>
                              <span class="checkmark"></span>
                           </label>
                           <label class="radio-container">
                              <input type="radio" name="refund" value="false" [(ngModel)]="is_refund"
                                 class="radio-input mx-2" />
                              <span class="radio-label">Without Refund</span>
                              <span class="checkmark"></span>
                           </label>
                        </div>
                     </div>
                     <div class="modal-footer">
                        <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                              (click)="rejectReport('recollect')">Save</button> -->
                        <img src="../../../assets/ui_icons/buttons/cancel_button.svg" data-bs-dismiss="modal"
                           (click)="cancelSamples()" class="saveimgbtn_inpatinfo" />
                        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                     </div>
                  </div>
               </div>
            </div>
         </mdb-card-body>
      </mdb-card>
   </div>
</div>