<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Prescription list</h5>
          </div>
          <div class="headerButtons">
            <a *ngIf="showdetails_flag" (click)="backClicked()"><img
                src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo" /></a>

            <a *ngIf="!showdetails_flag" (click)="backToList()">
              <img src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo" /></a>
            <a *ngIf="!showdetails_flag" style="margin-right: 10px;" (click)="print_area()">
              <img style="width: 30px;" src="../../../assets/img/printer.svg" /></a>

          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="Details" [hidden]="!showdetails_flag"
          style="padding:10px 32px; min-height: 200px !important; width:100% !important">
          <div class="row">
            <p *ngIf="med_pres_list.length == 0" class="nodata">No prescription details found</p>
            <div class="dig_table_overflow" *ngIf="med_pres_list.length != 0">
              <div class="table-responsive">
                <table id="tbl" class="table table-hover table-dynamic">
                  <thead>
                    <tr>
                      <th style="padding-left: 8px !important;width: 100px;">Date</th>
                      <th>Prescription</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let prescription of med_pres_list">
                      <td style="font-size: 11px !important;">
                        {{prescription.date}}</td>
                      <td style="font-size: 11px !important;">{{"Dr. " + prescription.doctorname}}
                        prescribed
                        for <span [innerHTML]="prescription.diseasechk" style="color:black"></span></td>
                      <td>
                        <img src="../../../assets/ui_icons/search_icon.svg" width="20px"
                          (click)="getPresDetails(prescription)" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div [hidden]="showdetails_flag" style="padding:10px 32px; min-height: 800px !important; width:100% !important">
          <div class="row">
            <div class="row">
              <div class="col-12">
                <div class="cover_div">
                  <div class="header_lable">Personal Details</div>
                  <div class="content_cover">

                    <div class="row">
                      <div class="col-11">
                        <div class="row">
                          <div class="col-3">
                            <p class="textformat"><span style="font-weight: 550;">MR No:</span> {{mrNumber}}</p>

                          </div>
                          <div class="col-3">
                            <p class="textformat"><span style="font-weight: 550;">Name:</span> {{patientName}}</p>

                          </div>
                          <div class="col-3">
                            <p class="textformat"><span style="font-weight: 550;">Age/Gender:</span> {{age}}/ {{gender}}
                            </p>
                          </div>
                          <div class="col-3">
                            <p class="textformat"><span style="font-weight: 550;">Mobile :</span> {{mobile}}</p>
                          </div>

                          <div class="col-3">
                            <p class="textformat"><span style="font-weight: 550;">Doctor:</span> {{doctorName}}</p>
                          </div>
                          <div class="col-3">
                            <p class="textformat"><span style="font-weight: 550;">Address:</span> {{fulladdress}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: flex;" class="rowformat">
              <p *ngIf="height_flag" class="refformat" style="margin: 10px !important;"><strong>{{'Height'}}:
                </strong>{{height}} </p>

              <p *ngIf="weight_flag" class="refformat" style="margin: 10px !important;"><strong>{{'Weight'}}:
                </strong>
                {{weight}}</p>

              <p *ngIf="temparature_flag" class="refformat" style="margin: 10px !important;"><strong>
                {{'Temperature'}}: </strong>{{temparature}} </p>
              
              <p *ngIf="bp_flag" class="refformat" style="margin: 10px !important;"><strong>{{'BP'}}:
                </strong>{{clnt_bp}}</p>
  
              <p *ngIf="pulseflag" class="refformat" style="margin: 10px !important;"><strong>{{'Pulse'}}:
                </strong>{{clnt_pulse}}</p>

              <p *ngIf="spo2Flag" class="refformat" style="margin: 10px !important;"><strong>{{'SPO2'}}:
                </strong>{{clntSpo2}}</p>  

              <p *ngIf="cvsflag" class="refformat" style="margin: 10px !important;"><strong>{{'CVS'}}:
                </strong>{{clntCvs}}</p>

              <p *ngIf="rsflag" class="refformat" style="margin: 10px !important;"><strong>{{'RS'}}: </strong>
                {{clntRs}}</p>

              <p *ngIf="heartrateflag" class="refformat" style="margin: 10px !important;">
                <b>{{'Heart rate'|translate}}:</b>{{clnt_heartrate}}
              </p>

              <p *ngIf="rrflag" class="refformat" style="margin: 10px !important;"><strong> {{'RR'}}:
                </strong>{{clntRR}}</p>

              <p *ngIf="cns_flag" class="refformat" style="margin: 10px !important;"><strong> {{'CNS'}}:
                </strong>{{clntCns}}</p>

              <p *ngIf="abd_flag" class="refformat" style="margin: 10px !important;"><strong> {{'ABD'}}:
                </strong>{{clntAbd}}</p>

              <p *ngIf="kidney_flag" class="refformat" style="margin: 10px !important;"><strong> {{'Kidney'}}:
                </strong>{{clntKidney}}</p>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="med_heading" style="display: flex;"><strong>Diagnosis:</strong>&nbsp;<span
                    class="med_content" [innerHtml]="dis_txt">
                  </span></div>
                <div class="med_heading" *ngIf="complaints != ''"><strong>Complaints:</strong>&nbsp;<span
                    class="med_content" [innerHtml]="complaints"></span></div>

              </div>
            </div>
            <div class="row">
              <div class="col-12"><strong>Rx</strong></div>
            </div>
            <div *ngIf="listProducts.length !=0" class="medicine_details">
              <div class="dig_table_overflow">
                <table id="tbl" *ngIf="listProducts.length" class="table table-hover table-dynamic">
                  <thead>
                    <tr>
                      <th>{{'Medicine type'}}</th>
                      <th>{{'Medicine name'}}</th>
                      <th>{{'Duration'}}</th>
                      <th>{{'Intake session'}}</th>
                      <th>{{'Intake mode '}}</th>
                      <th>{{'Frequency'}}</th>
                      <th>{{'Intake'}}</th>
                      <th>Note</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let product of listProducts; let i = index">
                      <td style="font-size: 12px;text-align: left;">{{ product.med_typetxt }}</td>
                      <td style="font-size: 12px;text-align: left;">{{ product.drug_name }}</td>
                      <td style="font-size: 12px;">{{product.days}} {{product.period }}</td>
                      <td style="font-size: 12px;">{{product.every_six}}</td>
                      <td style="font-size: 12px;">{{product.intake_mode}}</td>
                      <td style="font-size: 12px;">{{product.frequency }}</td>
                      <td style="font-size: 12px;text-align: left;">{{product.dure_txt_table}}
                        {{product.drug_intake }}
                      </td>
                      <td style="font-size: 12px;text-align: left;">{{product.remarks }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
            <div [hidden]="instructionFlag">
              <mat-label class="matlabel"><strong>{{'Instructions' }}: </strong></mat-label>
              <div class="matlabel" [innerHtml]="instructions"></div>
            </div>
            <div *ngIf="nextVisit!=undefined">
              <mat-label class="matlabel"><strong>{{'Next visit' }}: </strong>{{nextVisit}}</mat-label>
            </div>
            <div *ngIf="pharmacyFlag">
              <div *ngFor="let pharma of pharmacyDetailList" class="row">
                <div class="col-6 col-sm-2 col-md-2 col-lg-2 col-xl-2" style="position: relative;">
                  <img alt="image" src="{{pharma.phar_logo}}" class="hosp_image_class"
                    style="position: absolute;top: 13px;left: 51px;margin: 0;"><br>
                </div>
                <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel"><b>{{pharma.pharmacy_name}}</b></mat-label><br>
                  <mat-label class="matlabel">{{pharma.phar_addr1}},
                    {{pharma.phar_addr2}}</mat-label><br>
                  <mat-label class="matlabel">{{pharma.phar_loc}}</mat-label><br>
                  <mat-label class="matlabel">{{pharma.phar_city}} -
                    {{pharma.phar_zipcode}}</mat-label><br>
                  <mat-label class="matlabel">{{pharma.phar_state}},
                    {{pharma.phar_cnty}}</mat-label><br>
                  <mat-label class="matlabel">{{pharma.telephone}}</mat-label><br>
                </div>
              </div>
            </div>
            <div style="margin-top: 10px;"
              *ngIf="print_labarray.length!=0 || print_radioarray.length !=0 || print_package.length != 0">
              <p><strong>DX</strong></p>
              <div style="border: 1px solid #000;border-radius: 10px;padding: 5px;">
                <div *ngIf="print_labarray.length !=0" style="width: 89%;margin-bottom: 10px;">
                  <div class="heading"><strong>Lab Tests:</strong></div>
                  <div class="test-names-container">
                    <!-- <p></p> -->
                    <ng-container *ngFor="let test of print_labarray; let first = first">
                      <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                    </ng-container>
                    <!-- <ng-container *ngFor="let test of print_labarray; let last = last">
                  <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
              
                </ng-container> -->

                  </div>

                </div>
                <div *ngIf="print_radioarray.length !=0" style="width: 89%;margin-bottom: 10px;">
                  <div class="heading"><strong>Radiology Tests:</strong></div>
                  <div class="test-names-container">

                    <ng-container *ngFor="let test of print_radioarray; let first = first">
                      <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                    </ng-container>
                    <!-- <ng-container *ngFor="let test of print_radioarray; let last = last">
                  <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                </ng-container> -->
                  </div>
                </div>
                <div *ngIf="print_package.length !=0" style="width: 89%;margin-bottom: 10px;">
                  <div class="heading"><strong>Packages:</strong></div>
                  <div class="test-names-container">
                    <ng-container *ngFor="let test of print_package; let first = first">
                      <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                    </ng-container>
                    <!-- <ng-container *ngFor="let test of print_package; let last = last">
                   <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                 </ng-container> -->
                  </div>
                </div>
              </div>

            </div>
            <div style="margin-top: 10px;"
              *ngIf="print_labarraynew.length!=0 || print_radioarraynew.length !=0 ||printnext_package.length != 0 ">
              <p><strong>DX</strong></p>
              <p><strong>Test for Next Visit: </strong></p>
              <div style="border: 1px solid #000;border-radius: 10px;padding: 5px;">
                <div *ngIf="print_labarraynew.length !=0" style="width: 89%;margin-bottom: 10px;">
                  <div class="heading"><strong>Lab Tests:</strong></div>
                  <div class="test-names-container">
                    <!-- <p></p> -->

                    <ng-container *ngFor="let test of print_labarraynew; let first = first">
                      <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                    </ng-container>
                    <!-- <ng-container *ngFor="let test of print_labarraynew; let last = last">
                <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
             
              </ng-container> -->

                  </div>

                </div>
                <div *ngIf="print_radioarraynew.length !=0" style="width: 89%;margin-bottom: 10px;">
                  <div class="heading"><strong>Radiology Tests:</strong></div>
                  <div class="test-names-container">

                    <ng-container *ngFor="let test of print_radioarraynew; let first = first">
                      <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                    </ng-container>
                    <!-- <ng-container *ngFor="let test of print_radioarraynew; let last = last">
                <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
              </ng-container> -->
                  </div>
                </div>
                <div *ngIf="printnext_package.length !=0" style="width: 89%;margin-bottom: 10px;">
                  <div class="heading"><strong>Packages:</strong></div>
                  <div class="test-names-container">
                    <ng-container *ngFor="let test of printnext_package; let first = first">
                      <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                    </ng-container>
                    <!-- <ng-container *ngFor="let test of printnext_package; let last = last">
                 <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
               </ng-container> -->
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>


      </mdb-card-body>
    </mdb-card>
  </div>
</div>
<div [hidden]="true">
  <div #printlogowithname id="printlogowithname">
    <table style="margin-left:50px;margin-top:70px;">
      <tr>
        <td style="width: 450px; vertical-align: top;">
          <!-- width="100px" height="100px" -->
          <img alt="image" src={{hospLogo}} [style]="preslogo_style"
            onerror="this.src='./././assets/img/HospitalIcon.png'">
          <p style="margin-top: -100px;margin-left: 110px;">{{hptlName}}</p>
          <p style="margin-left: 110px;">{{address}},{{doctorLocation}}</p>
          <p style="margin-left: 110px;">{{doctorCity}}</p>
          <p style="margin-left: 110px;">{{doctorState}},{{doctorCountry}}</p>
          <p style="margin-left: 110px;">{{doctorTelephone}}</p>
        </td>
        <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
        <td style="width: 300px;vertical-align: top;">
          <p><b>{{'Doctor'}} : {{doctorName}} {{doctorQualification}}</b></p>
          <p><b>{{'Date'}} : </b>{{prescriptionDate}}</p>
        </td>
      </tr>
    </table>
    <br /><br />
    <table style="margin-left: 50px;">
      <tr>
        <td style="width: 450px;">
          <p><b>{{'Name'}} :</b> {{patientName}}</p>
          <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
          <p><b>{{'Age'}}/{{'Gender'}}:</b> {{age}} / {{gender}} </p>
          <p *ngIf="investigation!=undefined"><b>{{'Investigation'}} :</b> {{investigation}}</p>
        </td>
        <td>
          <p *ngIf="bpFlag">{{'BP'}}: {{clntBp}} &nbsp;&nbsp;&nbsp;{{'Pulse'}}: {{clntPulse}}</p>
          <p *ngIf="heightFlag"><b>{{'Height'}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<b>{{'Weight'}}: </b>
            {{weight}}</p>
          <p *ngIf="temparatureFlag"><b>{{'Temperature'}}: </b>{{temparature}} </p>
          <p *ngIf="orthoFlag">{{'CVS'}}: {{clntCvs}} &nbsp;&nbsp;&nbsp;{{'RS'}}: {{clntRs}}</p>
          <p *ngIf="orthoFlag">{{'Kidney'}}: {{clntKidney}}</p>
          <p *ngIf="pulmFlag">{{'PFT'}}: {{pft}}</p>
        </td>
      </tr>
    </table>
    <div>
      <p><b>{{'Diagnosis' }}: </b>{{diagnosis}}</p>
    </div>
    <br />
    <table style="margin-left: 20px; border: 1px solid black;border-collapse: collapse; width: 95%;">
      <tr *ngIf="medstyle">
        <th style="width: 150px !important;border: 1px solid black;border-collapse: collapse;"><b>{{'Medicine'}}</b>
        </th>
        <th style=" width: 150px !important;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
        <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
        <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
        <th style="width: 150px !important;border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
          Intake</th>
        <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
      </tr>
      <tr *ngIf="medstyle1">
        <th style="width: 150px !important;border: 1px solid black;border-collapse: collapse;"><b>{{'Medicine'}}</b>
        </th>
        <th style=" width: 150px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
        <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
        <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
        <th style="width: 150px !important;border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
          Intake</th>
        <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
      </tr>
      <tr *ngIf="medstyle2">
        <th style="width: 150px !important;border: 1px solid black !important;border-collapse: collapse !important;">
          <b>{{'Medicine'}}</b>
        </th>
        <th style=" width: 150px !important;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
        <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
        <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
        <th style="width: 150px !important;border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
          Intake</th>
        <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
      </tr>
      <tr *ngIf="medstyle3">
        <th style="width: 150px !important;border: 1px solid black !important;border-collapse: collapse !important;">
          <b>{{'Medicine'}}</b>
        </th>
        <th style="width: 150px !important;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
        <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
        <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
        <th style="width: 150px !important;border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
          Intake</th>
        <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
      </tr>
      <tr *ngFor="let product of listProducts">
        <td style="border: 1px solid black;border-collapse: collapse;">
          {{product.short_name}} {{product.drug_name}}
          {{product.generic_name}}
        </td>
        <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
          {{product.every_six}}
        </td>
        <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
          {{product.period}}</td>
        <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
          {{product.frequency}}
        </td>
        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
          <table>
            <tr>
              <td [hidden]="dur_time_flag">{{product.dure_txt_table}} - </td>
              <td [hidden]="dur_flag">{{product.drug_intake}}</td>
            </tr>
          </table>
        </td>
        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">{{product.remarks }}</td>
      </tr>
    </table>
    <div style="margin-left: 50px;">
      <p *ngIf="instructions!=undefined"><b>{{'Instructions' }}: </b>
        <span>
          <div class="matlabel" [innerHtml]="instructions"></div>
        </span>
      </p>
    </div>
    <br />
    <div style="margin-left: 50px;" *ngIf="treatFlag">
      <p *ngIf="treatment!=undefined"><b>{{'Treatment' }}: </b>
        <span>
          <div class="matlabel" [innerHtml]="treatment"></div>
        </span>
      </p>
    </div>
    <div style="margin-left: 50px;">
      <p *ngIf="nextVisit!=undefined"><b>{{'Next visit' }}: </b>{{nextVisit}}</p>
    </div>
    <br />
    <div>
      <p style="margin-left: 50px;font-size: 13px;"> <strong>{{'Dr '+doctorName}}</strong> </p>
      <p style="margin-left: 50px;font-size: 13px">({{licenceCode}})</p>
      <!-- <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i></p>
                        <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b></p> -->
      <br />
    </div>
  </div>
  <div #printbanner id="printbanner">
    <table style="margin-left:10px;margin-top:10px;margin-right: 10px;">
      <tr>
        <td>
          <!-- width="750px" height="200px" -->
          <img alt="image" src={{hptlPresLogo}} [style]="preslogo_style">
        </td>
      </tr>
    </table>
    <br /><br />

    <div *ngIf="print_same_page">
      <table>
        <thead>
          <tr>
            <td>
              <div class=“empty-header“ [style]="preslogo_style"> </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>

              <div class=“content“>

                <div>
                  <div
                    style="margin-left: 25px !important;margin-right: 25px !important;display: flex;padding: 10px; margin-top: 10px; border: 1px solid black; border-radius: 10px;">
                    <div>
                      <div style="display: flex;vertical-align: top;">

                        <table style="width: 450px;vertical-align: top;">

                          <tr style="vertical-align: top;">

                            <td><strong>{{'Name'}}</strong></td>
                            <td>: {{patientName}}</td>
                          </tr>
                          <tr style="vertical-align: top;">

                            <td><strong>{{'Age'}}/{{'Gender'}}</strong></td>
                            <td>: {{age}}/{{gender}}</td>
                          </tr>
                          <tr style="vertical-align: top;">
                            <td><strong>{{'Mobile'}}</strong></td>
                            <td>: {{mobile}}</td>
                          </tr>


                        </table>

                        <table style="width: 350px;vertical-align: top;">
                          <tr style="vertical-align: top;">

                            <td><strong>{{'Date'}} & {{'Time'}}</strong></td>
                            <td>: {{Appoint_Date}}</td>
                          </tr>
                          <tr style="vertical-align: top;">
                            <td>
                              <ngx-barcode class="barcode" [bc-value]="mrNumber" [bc-display-value]="displayValue"
                                [bc-line-color]="lineColor" [element-type]="elementType" [format]="format"
                                [bc-width]="width" [bc-height]="barcodeheight" [bc-font-options]="fontOptions"
                                [bc-font]="font" [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                                [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                                [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                                [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode>

                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                    <p style="margin:0px"><strong>{{'Complaints'}}: </strong>
                      <span>
                        <div class="matlabel" [innerHtml]="complaints"></div>
                      </span>
                    </p>
                  </div>

                  <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                    <p style="margin:0px"><strong>{{'Diagnosis'}}: </strong>
                      <span>
                        <div class="matlabel" [innerHtml]="dis_txt"></div>
                      </span>
                    </p>
                  </div>

                  <div
                    style="display: flex;margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;width: 89%;"
                    class="rowformat">
                    <p *ngIf="height_flag" class="refformat" style="margin: 10px !important;"><strong>{{'Height'}}:
                    </strong>{{height}} </p>
    
                    <p *ngIf="weight_flag" class="refformat" style="margin: 10px !important;"><strong>{{'Weight'}}:
                      </strong>
                      {{weight}}</p>
      
                    <p *ngIf="temparature_flag" class="refformat" style="margin: 10px !important;"><strong>
                      {{'Temperature'}}: </strong>{{temparature}} </p>
                    
                    <p *ngIf="bp_flag" class="refformat" style="margin: 10px !important;"><strong>{{'BP'}}:
                      </strong>{{clnt_bp}}</p>
        
                    <p *ngIf="pulseflag" class="refformat" style="margin: 10px !important;"><strong>{{'Pulse'}}:
                      </strong>{{clnt_pulse}}</p>
      
                    <p *ngIf="spo2Flag" class="refformat" style="margin: 10px !important;"><strong>{{'SPO2'}}:
                      </strong>{{clntSpo2}}</p>  
      
                    <p *ngIf="cvsflag" class="refformat" style="margin: 10px !important;"><strong>{{'CVS'}}:
                      </strong>{{clntCvs}}</p>
      
                    <p *ngIf="rsflag" class="refformat" style="margin: 10px !important;"><strong>{{'RS'}}: </strong>
                      {{clntRs}}</p>
      
                    <p *ngIf="heartrateflag" class="refformat" style="margin: 10px !important;">
                      <b>{{'Heart rate'|translate}}:</b>{{clnt_heartrate}}
                    </p>
      
                    <p *ngIf="rrflag" class="refformat" style="margin: 10px !important;"><strong> {{'RR'}}:
                      </strong>{{clntRR}}</p>
      
                    <p *ngIf="cns_flag" class="refformat" style="margin: 10px !important;"><strong> {{'CNS'}}:
                      </strong>{{clntCns}}</p>
      
                    <p *ngIf="abd_flag" class="refformat" style="margin: 10px !important;"><strong> {{'ABD'}}:
                      </strong>{{clntAbd}}</p>
      
                    <p *ngIf="kidney_flag" class="refformat" style="margin: 10px !important;"><strong> {{'Kidney'}}:
                      </strong>{{clntKidney}}</p>
                  </div>


                  <div style="margin-left: 25px !important;margin-right:25px !important;"
                    *ngIf="listProducts.length != 0">
                    <p><strong>Rx</strong></p>
                  </div>
                  <table *ngIf="listProducts.length != 0"
                    style="margin-left: 25px !important;margin-right:25px !important; border: 1px solid black;border-collapse: collapse;">


                    <tr style="text-align: center;">
                      <th [style]="med_style">

                        <p class="m-0 marginch"> {{'Medicine'}}</p>
                        <p *ngIf="printLanguageflag">{{'Medicine'|translate}}</p>

                      </th>
                      <th [style]="dosage_style">

                        <p class="m-0 marginch">Mor</p>

                        <p *ngIf="printLanguageflag">{{'Mor'|translate}}</p>
                      </th>
                      <th [style]="dosage_style">
                        <p class="m-0 marginch">Aft</p>
                        <p *ngIf="printLanguageflag"> {{'Aft'|translate}}</p>

                      </th>
                      <th [style]="dosage_style">
                        <p class="m-0 marginch">Eve</p>
                        <p *ngIf="printLanguageflag"> {{'Eve'|translate}}</p>

                      </th>
                      <th [style]="dosage_style">
                        <p class="m-0 marginch">Ngt</p>
                        <p *ngIf="printLanguageflag">{{'Ngt'|translate}}</p>

                      </th>
                      <th [style]="dur_style">

                        <p class="m-0 marginch">Duration</p>
                        <p *ngIf="printLanguageflag">{{'Duration'|translate}}</p>

                      </th>
                      <th [style]="freq_style">

                        <p class="m-0 marginch">Frequency & Notes</p>
                        <p *ngIf="printLanguageflag">{{'Frequency'|translate}} & {{'Notes'|translate}}</p>

                      </th>


                    </tr>
                    <tr *ngFor="let product of listProducts">
                      <td style="border: 1px solid black;border-collapse: collapse;width: 310px;">
                        {{product.short_name}} {{product.drug_name}} <span
                          style="font-size:12px">{{product.genericname}}</span>
                      </td>

                      <td style="text-align: left;border: 1px solid black;border-collapse: collapse;width: 100px;"
                        colspan="4" *ngIf="product.param_four ==true">
                        {{product.every_six}}
                      </td>
                      <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                        *ngIf="product.param_four ==false">
                        {{product.morning}}
                      </td>
                      <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                        *ngIf="product.param_four ==false">
                        {{product.afternoon}}
                      </td>
                      <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                        *ngIf="product.param_four ==false">
                        {{product.evening}}
                      </td>
                      <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                        *ngIf="product.param_four ==false">
                        {{product.night}}
                      </td>
                      <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.days}}
                        {{product.period|translate}}</td>
                      <td
                        style="text-align: center;border: 1px solid black;border-collapse: collapse;min-width: 250px;">
                        {{product.frequency
                        |
                        translate}}-{{product.dure_txt_table}}
                        {{product.drug_intake|translate}}<br> {{product.remarks}}</td>
                      <!-- <td style="border: 1px solid black;border-collapse: collapse;min-width: 100px !important;">
                        </td>
                      <td style="border: 1px solid black;border-collapse: collapse;min-width: 250px !important;">
                        </td> -->
                    </tr>
                  </table>

                </div>


                <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 10px;"
                  *ngIf="labarray.length!=0 || radioarray.length!=0 || package.length!=0 ">
                  <p><strong>DX</strong></p>
                  <div style="border: 1px solid #000;border-radius: 10px;padding: 5px;">
                    <div *ngIf="labarray.length !=0" style="width: 89%;margin-bottom: 10px;">
                      <div class="heading"><strong>Lab Tests:</strong></div>
                      <div class="test-names-container">
                        <!-- <p></p> -->

                        <!-- <ng-container *ngFor="let test of labarray; let last = last">
                        <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                      </ng-container> -->
                        <ng-container *ngFor="let test of labarray; let first = first">
                          <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                        </ng-container>

                      </div>

                    </div>
                    <div *ngIf="radioarray.length !=0" style="width: 89%;margin-bottom: 10px;">
                      <div class="heading"><strong>Radiology Tests:</strong></div>
                      <div class="test-names-container">

                        <ng-container *ngFor="let test of radioarray; let first = first">
                          <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                        </ng-container>
                        <!-- <ng-container *ngFor="let test of radioarray; let last = last">
                        <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                   
                      </ng-container> -->
                      </div>
                    </div>
                    <div *ngIf="package.length !=0" style="width: 89%;margin-bottom: 10px;">
                      <div class="heading"><strong>Packages:</strong></div>
                      <div class="test-names-container">
                        <ng-container *ngFor="let test of package; let first = first">
                          <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>

                <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 10px;"
                  *ngIf="labarraynew.length!=0 ||  radioarraynew.length!=0 || printnext_package.length!=0">
                  <p *ngIf="labarraynew.length!=0 || radioarraynew.length!=0 "><strong>Test for next visit:</strong></p>

                  <div style="border: 1px solid #000;border-radius: 10px;padding: 10px;">
                    <div *ngIf="labarraynew.length !=0" style="width: 89%;margin-bottom: 10px;">
                      <div class="heading"><strong>Lab Tests:</strong></div>
                      <div class="test-names-container">
                        <!-- <p></p> -->

                        <ng-container *ngFor="let test of labarraynew; let first = first">
                          <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                        </ng-container>
                        <!-- <ng-container *ngFor="let test of labarraynew; let last = last">
                        <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                    
                      </ng-container> -->
                        <!-- <ng-container *ngFor="let profile of selected_profilesnew let last = last">
                         <span class="test-name">  {{profile.profile_name}}</span>{{ !last ? ',' : '' }}
                       </ng-container> -->
                      </div>

                    </div>
                    <div *ngIf="radioarraynew.length !=0" style="width: 89%;margin-bottom: 10px;">
                      <div class="heading"><strong>Radiology Tests:</strong></div>
                      <div class="test-names-container">

                        <ng-container *ngFor="let test of radioarraynew; let first = first">
                          <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                        </ng-container>
                        <!-- <ng-container *ngFor="let test of radioarraynew; let last = last">
                        <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                      </ng-container> -->
                      </div>
                    </div>
                    <div *ngIf="printnext_package.length !=0" style="width: 89%;margin-bottom: 10px;">
                      <div class="heading"><strong>Packages:</strong></div>
                      <div class="test-names-container">
                        <ng-container *ngFor="let test of printnext_package; let first = first">
                          <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                        </ng-container>
                        <!-- <ng-container *ngFor="let test of radioarraynew; let last = last">
                         <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                       </ng-container> -->
                      </div>
                    </div>
                  </div>


                </div>

                <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                  <p style="margin:0px"><strong>{{'Instructions'}}: </strong>
                    <span>
                      <div class="matlabel" [innerHtml]="instructions"></div>
                    </span>
                  </p>
                </div>


                <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                  <p *ngIf="next_txt!=undefined"><strong>{{'Next visit'}}: </strong>{{next_txt}}</p>
                </div>


                <div>

                  <p
                    style="margin-left: 25px !important;margin-right:25px !important;font-size: 13px;margin-top: 100px;">
                    <strong>{{"Dr."+doctorName}}
                      {{doctorQualification}}</strong>
                  </p>

                  <p style="margin-left: 25px !important;margin-right:25px !important;font-size: 13px">
                    ({{licence_code}})</p>

                  <br />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <div class=”empty-footer”>&nbsp;</div>
        </tfoot>
      </table>
      <div class=”header”>&nbsp;</div>
      <div class=”footer”>&nbsp;</div>
    </div>
  </div>
  <div #printbannerfullpage id="printbannerfullpage">

    <table style="margin-left:50px;margin-top:70px;">
      <tr>
        <td style="width: 450px; vertical-align: top;">
          <!-- width="100px" height="100px" -->
          <img alt="image" src={{hospLogo}} [style]="preslogo_style"
            onerror="this.src='./././assets/img/HospitalIcon.png'">
          <p style="margin-top: -100px;margin-left: 110px;">{{hptlName}}</p>
          <p style="margin-left: 110px;">{{address}},{{doctorLocation}}</p>
          <p style="margin-left: 110px;">{{doctorCity}}</p>
          <p style="margin-left: 110px;">{{doctorState}},{{doctorCountry}}</p>
          <p style="margin-left: 110px;">{{doctorTelephone}}</p>
        </td>
        <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
        <td style="width: 300px;vertical-align: top;">
          <p><b>{{'Doctor'}} : {{doctorName}} {{doctorQualification}}</b></p>
          <p><b>{{'Date'}} : </b>{{prescriptionDate}}</p>
        </td>
      </tr>
    </table>
    <br /><br />
    <table style="margin-left: 50px;">
      <tr>
        <td style="width: 450px;">
          <p><b>{{'Name'}} :</b> {{patientName}}</p>
          <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
          <p><b>{{'Age'}} :</b> {{age}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'}}:
            </b>{{gender}} </p>
          <p *ngIf="investigation!=undefined"><b>{{'Investigation'}} :</b> {{investigation}}</p>
        </td>
        <td>
          <p *ngIf="bpFlag">{{'BP'}}: {{clntBp}} &nbsp;&nbsp;&nbsp;{{'Pulse'}}: {{clntPulse}}</p>
          <p *ngIf="heightFlag"><b>{{'Height'}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<b>{{'Weight'}}: </b>
            {{weight}}</p>
          <p *ngIf="temparatureFlag"><b>{{'Temperature'}}: </b>{{temparature}} </p>
          <p *ngIf="orthoFlag">{{'CVS'}}: {{clntCvs}} &nbsp;&nbsp;&nbsp;{{'RS'}}: {{clntRs}}</p>
          <p *ngIf="orthoFlag">{{'Kidney'}}: {{clntKidney}}</p>
          <p *ngIf="pulmFlag">{{'PFT'}}: {{pft}}</p>
        </td>
      </tr>
    </table>
    <div>
      <p><b>{{'Diagnosis' }}: </b>{{diagnosis}}</p>
    </div>
    <br />
    <table style="margin-left: 20px; border: 1px solid black;border-collapse: collapse; width: 95%;">
      <tr *ngIf="medstyle">
        <th style="width: 150px !important;border: 1px solid black;border-collapse: collapse;"><b>{{'Medicine'}}</b>
        </th>
        <th style=" width: 150px !important;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
        <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
        <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
        <th style="width: 150px !important;border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
          Intake</th>
        <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
      </tr>
      <tr *ngIf="medstyle1">
        <th style="width: 150px !important;border: 1px solid black;border-collapse: collapse;"><b>{{'Medicine'}}</b>
        </th>
        <th style=" width: 150px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
        <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
        <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
        <th style="width: 150px !important;border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
          Intake</th>
        <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
      </tr>
      <tr *ngIf="medstyle2">
        <th style="width: 150px !important;border: 1px solid black !important;border-collapse: collapse !important;">
          <b>{{'Medicine'}}</b>
        </th>
        <th style=" width: 150px !important;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
        <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
        <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
        <th style="width: 150px !important;border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
          Intake</th>
        <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
      </tr>
      <tr *ngIf="medstyle3">
        <th style="width: 150px !important;border: 1px solid black !important;border-collapse: collapse !important;">
          <b>{{'Medicine'}}</b>
        </th>
        <th style="width: 150px !important;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
        <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
        <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
        <th style="width: 150px !important;border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
          Intake</th>
        <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
      </tr>
      <tr *ngFor="let product of listProducts">
        <td style="border: 1px solid black;border-collapse: collapse;">
          {{product.short_name}} {{product.drug_name}}
          {{product.generic_name}}
        </td>
        <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
          {{product.every_six}}
        </td>
        <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
          {{product.period}}</td>
        <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
          {{product.frequency}}
        </td>
        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
          <table>
            <tr>
              <td [hidden]="dur_time_flag">{{product.dure_txt_table}} - </td>
              <td [hidden]="dur_flag">{{product.drug_intake}}</td>
            </tr>
          </table>
        </td>
        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">{{product.remarks }}</td>
      </tr>
    </table>
    <div style="margin-left: 50px;">
      <p *ngIf="instructions!=undefined"><b>{{'Instructions' }}: </b>
        <span>
          <div class="matlabel" [innerHtml]="instructions"></div>
        </span>
      </p>
    </div>
    <br />
    <div style="margin-left: 50px;" *ngIf="treatFlag">
      <p *ngIf="treatment!=undefined"><b>{{'Treatment' }}: </b>
        <span>
          <div class="matlabel" [innerHtml]="treatment"></div>
        </span>
      </p>
    </div>
    <div style="margin-left: 50px;">
      <p *ngIf="nextVisit!=undefined"><b>{{'Next visit' }}: </b>{{nextVisit}}</p>
    </div>
    <br />
    <div>
      <p style="margin-left: 50px;font-size: 13px;"> <strong>{{'Dr '+doctorName}}</strong> </p>
      <p style="margin-left: 50px;font-size: 13px">({{licenceCode}})</p>
      <!-- <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i></p>
                          <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b></p> -->
      <br />
    </div>
  </div>

  <div #printnoheader id="printnoheader">
    <div *ngIf="print_same_page">
      <table>
        <thead>
          <tr>
            <td>
              <div class=“empty-header“ [style]="preslogo_style"> </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class=“content“>

                <div>
                  <div
                    style="margin-left: 25px !important;margin-right: 25px !important;display: flex;padding: 10px; margin-top: 10px; border: 1px solid black; border-radius: 10px;">
                    <div>
                      <div style="display: flex;vertical-align: top;">
                        <table style="width: 450px;vertical-align: top;">

                          <tr style="vertical-align: top;">
                            <td><strong>{{'Name'}}</strong></td>
                            <td>: {{patientName}}</td>
                          </tr>
                          <tr style="vertical-align: top;">
                            <td><strong>{{'Age'}}/{{'Gender'}}</strong></td>
                            <td>: {{age}}/{{gender}}</td>
                          </tr>
                          <tr style="vertical-align: top;">
                            <td><strong>{{'Mobile'}}</strong></td>
                            <td>: {{mobile}}</td>
                          </tr>


                        </table>
                        <table style="width: 350px;vertical-align: top;">
                          <tr style="vertical-align: top;">
                            <td><strong>{{'Date'}} & {{'Time'}}</strong></td>
                            <td>: {{Appoint_Date}}</td>
                          </tr>
                          <tr style="vertical-align: top;">
                            <td>
                              <ngx-barcode class="barcode" [bc-value]="mrNumber" [bc-display-value]="displayValue"
                                [bc-line-color]="lineColor" [element-type]="elementType" [format]="format"
                                [bc-width]="width" [bc-height]="barcodeheight" [bc-font-options]="fontOptions"
                                [bc-font]="font" [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                                [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                                [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                                [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode>

                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                    <p style="margin:0px"><strong>{{'Complaints'}}: </strong>
                      <span>
                        <div class="matlabel" [innerHtml]="complaints"></div>
                      </span>
                    </p>
                  </div>
                  <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                    <p style="margin:0px"><strong>{{'Diagnosis'}}: </strong>
                      <span>
                        <div class="matlabel" [innerHtml]="dis_txt"></div>
                      </span>
                    </p>
                  </div>
                  <div
                    style="display: flex;margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;width: 89%;"
                    class="rowformat">
                    <!-- <p *ngIf="ref_flag" class="refformat" style="margin: 2px !important;">
                   <b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p> -->
                   <p *ngIf="height_flag" class="refformat" style="margin: 10px !important;"><strong>{{'Height'}}:
                  </strong>{{height}} </p>
  
                  <p *ngIf="weight_flag" class="refformat" style="margin: 10px !important;"><strong>{{'Weight'}}:
                    </strong>
                    {{weight}}</p>
    
                  <p *ngIf="temparature_flag" class="refformat" style="margin: 10px !important;"><strong>
                    {{'Temperature'}}: </strong>{{temparature}} </p>
                  
                  <p *ngIf="bp_flag" class="refformat" style="margin: 10px !important;"><strong>{{'BP'}}:
                    </strong>{{clnt_bp}}</p>
      
                  <p *ngIf="pulseflag" class="refformat" style="margin: 10px !important;"><strong>{{'Pulse'}}:
                    </strong>{{clnt_pulse}}</p>
    
                  <p *ngIf="spo2Flag" class="refformat" style="margin: 10px !important;"><strong>{{'SPO2'}}:
                    </strong>{{clntSpo2}}</p>  
    
                  <p *ngIf="cvsflag" class="refformat" style="margin: 10px !important;"><strong>{{'CVS'}}:
                    </strong>{{clntCvs}}</p>
    
                  <p *ngIf="rsflag" class="refformat" style="margin: 10px !important;"><strong>{{'RS'}}: </strong>
                    {{clntRs}}</p>
    
                  <p *ngIf="heartrateflag" class="refformat" style="margin: 10px !important;">
                    <b>{{'Heart rate'|translate}}:</b>{{clnt_heartrate}}
                  </p>
    
                  <p *ngIf="rrflag" class="refformat" style="margin: 10px !important;"><strong> {{'RR'}}:
                    </strong>{{clntRR}}</p>
    
                  <p *ngIf="cns_flag" class="refformat" style="margin: 10px !important;"><strong> {{'CNS'}}:
                    </strong>{{clntCns}}</p>
    
                  <p *ngIf="abd_flag" class="refformat" style="margin: 10px !important;"><strong> {{'ABD'}}:
                    </strong>{{clntAbd}}</p>
    
                  <p *ngIf="kidney_flag" class="refformat" style="margin: 10px !important;"><strong> {{'Kidney'}}:
                    </strong>{{clntKidney}}</p>
                  </div>

                  <div style="margin-left: 25px !important;margin-right:25px !important;"
                    *ngIf="listProducts.length != 0">
                    <p><strong>Rx</strong></p>
                  </div>
                  <table *ngIf="listProducts.length != 0"
                    style="margin-left: 25px !important;margin-right:25px !important; border: 1px solid black;border-collapse: collapse;">


                    <tr style="text-align: center;">
                      <th [style]="med_style">
                        <p class="m-0 marginch"> {{'Medicine'}}</p>
                        <p *ngIf="printLanguageflag">{{'Medicine'|translate}}</p>
                      </th>
                      <th [style]="dosage_style">
                        <p class="m-0 marginch">Mor</p>
                        <p *ngIf="printLanguageflag">{{'Mor'|translate}}</p>
                      </th>
                      <th [style]="dosage_style">
                        <p class="m-0 marginch">Aft</p>
                        <p *ngIf="printLanguageflag"> {{'Aft'|translate}}</p>

                      </th>
                      <th [style]="dosage_style">
                        <p class="m-0 marginch">Eve</p>
                        <p *ngIf="printLanguageflag"> {{'Eve'|translate}}</p>
                      </th>
                      <th [style]="dosage_style">
                        <p class="m-0 marginch">Ngt</p>
                        <p *ngIf="printLanguageflag">{{'Ngt'|translate}}</p>
                      </th>
                      <th [style]="dur_style">
                        <p class="m-0 marginch">Duration</p>
                        <p *ngIf="printLanguageflag">{{'Duration'|translate}}</p>
                      </th>
                      <th [style]="freq_style">
                        <p class="m-0 marginch">Frequency & Notes</p>
                        <p *ngIf="printLanguageflag">{{'Frequency'|translate}} & {{'Notes'|translate}}</p>
                      </th>

                    </tr>
                    <tr *ngFor="let product of listProducts">
                      <td style="border: 1px solid black;border-collapse: collapse;width: 310px;">
                        {{product.short_name}} {{product.drug_name}} <span
                          style="font-size:12px">{{product.genericname}}</span>
                      </td>
                      <td style="text-align: left;border: 1px solid black;border-collapse: collapse;width: 100px;"
                        colspan="4" *ngIf="product.param_four ==true">
                        {{product.every_six}}
                      </td>
                      <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                        *ngIf="product.param_four ==false">
                        {{product.morning}}
                      </td>
                      <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                        *ngIf="product.param_four ==false">
                        {{product.afternoon}}
                      </td>
                      <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                        *ngIf="product.param_four ==false">
                        {{product.evening}}
                      </td>
                      <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                        *ngIf="product.param_four ==false">
                        {{product.night}}
                      </td>
                      <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.days}}
                        {{product.period|translate}}</td>
                      <td
                        style="text-align: center;border: 1px solid black;border-collapse: collapse;min-width: 250px;">
                        {{product.frequency
                        |
                        translate}}-{{product.dure_txt_table}}
                        {{product.drug_intake|translate}}<br> {{product.remarks}}</td>
                      <!-- <td style="border: 1px solid black;border-collapse: collapse;min-width: 100px !important;">
                      </td>
                    <td style="border: 1px solid black;border-collapse: collapse;min-width: 250px !important;">
                      </td> -->
                    </tr>
                  </table>

                </div>
                <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 10px;"
                  *ngIf="labarray.length!=0 || radioarray.length!=0 || package.length!=0 || labarraynew.length!=0 ||  radioarraynew.length!=0 || printnext_package.length!=0">
                  <p><strong>DX</strong></p>
                </div>
                <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 10px;"
                  *ngIf="labarray.length!=0 || radioarray.length!=0 || package.length!=0 ">

                  <div style="border: 1px solid #000;border-radius: 10px;padding: 5px;">
                    <div *ngIf="labarray.length !=0" style="width: 89%;margin-bottom: 10px;">
                      <div class="heading"><strong>Lab Tests:</strong></div>
                      <div class="test-names-container">
                        <!-- <p></p> -->
                        <!-- <ng-container *ngFor="let test of labarray; let last = last">
                      <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                    </ng-container> -->
                        <ng-container *ngFor="let test of labarray; let first = first">
                          <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                        </ng-container>

                      </div>

                    </div>
                    <div *ngIf="radioarray.length !=0" style="width: 89%;margin-bottom: 10px;">
                      <div class="heading"><strong>Radiology Tests:</strong></div>
                      <div class="test-names-container">

                        <ng-container *ngFor="let test of radioarray; let first = first">
                          <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                        </ng-container>
                        <!-- <ng-container *ngFor="let test of radioarray; let last = last">
                      <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                 
                    </ng-container> -->
                      </div>
                    </div>
                    <div *ngIf="package.length !=0" style="width: 89%;margin-bottom: 10px;">
                      <div class="heading"><strong>Packages:</strong></div>
                      <div class="test-names-container">
                        <ng-container *ngFor="let test of package; let first = first">
                          <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                        </ng-container>

                      </div>
                    </div>
                  </div>

                </div>
                <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 10px;"
                  *ngIf="labarraynew.length!=0 ||  radioarraynew.length!=0 || printnext_package.length!=0">
                  <p *ngIf="labarraynew.length!=0 || radioarraynew.length!=0 "><strong>Test for next visit:</strong></p>

                  <div style="border: 1px solid #000;border-radius: 10px;padding: 10px;">
                    <div *ngIf="labarraynew.length !=0" style="width: 89%;margin-bottom: 10px;">
                      <div class="heading"><strong>Lab Tests:</strong></div>
                      <div class="test-names-container">
                        <!-- <p></p> -->

                        <ng-container *ngFor="let test of labarraynew; let first = first">
                          <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                        </ng-container>
                        <!-- <ng-container *ngFor="let test of labarraynew; let last = last">
                      <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                  
                    </ng-container> -->
                        <!-- <ng-container *ngFor="let profile of selected_profilesnew let last = last">
                       <span class="test-name">  {{profile.profile_name}}</span>{{ !last ? ',' : '' }}
                     </ng-container> -->
                      </div>

                    </div>
                    <div *ngIf="radioarraynew.length !=0" style="width: 89%;margin-bottom: 10px;">
                      <div class="heading"><strong>Radiology Tests:</strong></div>
                      <div class="test-names-container">

                        <ng-container *ngFor="let test of radioarraynew; let first = first">
                          <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                        </ng-container>
                        <!-- <ng-container *ngFor="let test of radioarraynew; let last = last">
                      <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                    </ng-container> -->
                      </div>
                    </div>
                    <div *ngIf="printnext_package.length !=0" style="width: 89%;margin-bottom: 10px;">
                      <div class="heading"><strong>Packages:</strong></div>
                      <div class="test-names-container">
                        <ng-container *ngFor="let test of printnext_package; let first = first">
                          <ng-container *ngIf="!first">, </ng-container>{{ test.test_name}}
                        </ng-container>
                        <!-- <ng-container *ngFor="let test of radioarraynew; let last = last">
                       <span class="test-name">{{ test.test_name }}</span>{{ !last ? ',' : '' }}
                     </ng-container> -->
                      </div>
                    </div>
                  </div>


                </div>
                <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                  <p style="margin:0px"><strong>{{'Instructions'}}: </strong>
                    <span>
                      <div class="matlabel" [innerHtml]="instructions"></div>
                    </span>
                  </p>
                </div>

                <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                  <p *ngIf="next_txt!=undefined"><strong>{{'Next visit'}}: </strong>{{next_txt}}</p>
                </div>


                <div>
                  <p
                    style="margin-left: 25px !important;margin-right:25px !important;font-size: 13px;margin-top: 100px;">
                    <strong>{{doctorName}}
                      {{doctorQualification}}</strong>
                  </p>
                  <p style="margin-left: 25px !important;margin-right:25px !important;font-size: 13px">
                    ({{licence_code}})</p>

                  <br />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <div class=”empty-footer”>&nbsp;</div>
        </tfoot>
      </table>
      <div class=”header”>&nbsp;</div>
      <div class=”footer”>&nbsp;</div>
    </div>
  </div>

  <div #fourcolumnnoheader id="fourcolumnnoheader">
    <div style="width: 100%;margin: 20px !important;">
      <table style="border: 1px solid #403f3f;margin-left: 10px !important;margin-top: 200px !important;width:97%">
        <tr>
          <td>{{'UHID'}}:{{clntUHID}} </td>
          <td colspan="2">{{'Name'}} : {{patientName}}</td>
          <td>{{'Age'}}/{{'Gender'}} : {{age}}/{{gender}}</td>
        </tr>
        <tr>
          <td>{{'Type'}}:{{apptype}}</td>
          <td>{{'Mobile'}} : {{mobile}}</td>
          <td>{{'Consultant'}} : {{doctorName}}</td>
          <td><span *ngIf="temparatureFlag">{{'Temp'}}: {{temparature}}</span></td>
        </tr>
        <tr>
          <td>{{'Date'}} : {{prescriptionDate}}</td>
          <td>{{'Created by'}} : {{doctorName}}</td>
          <td>{{'Height'}}: {{height}}</td>
          <td>{{'Weight'}}: {{weight}}</td>
        </tr>
        <tr>
          <td>{{'Time'}} : {{prescriptionTime}}</td>
          <td><span *ngIf="bpFlag">{{'BP'}}: {{clntBp}}</span></td>
          <td><span *ngIf="spo2Flag">{{'SPO2'}}: {{clntSpo2}}</span></td>
          <td><span *ngIf="prFlag">{{'PR'}}: {{clntPr}}</span></td>
        </tr>
      </table>
    </div>
    <div style="width: 100%;margin: 20px !important;" *ngIf="diagnosisflag">
      <div class="row">
        <div class="col-3"><b>{{'Diagnosis'}} :</b>
        </div>
        <div class="col-3">
          <p [innerHTML]="diagnosis"></p>
        </div>
      </div>
      <!-- <p><b>{{'Diagnosis' }}: </b>{{diagnosis}}</p> -->
    </div>
    <br />
    <table style="margin-left: 20px; border: 1px solid black;border-collapse: collapse; width: 95%;">
      <tr *ngIf="medstyle">
        <th style="width: 150px !important;border: 1px solid black;border-collapse: collapse;"><b>{{'Medicine'}}</b>
        </th>
        <th style=" width: 150px !important;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
        <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
        <th style="width: 100px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
        <th style="width: 150px !important;border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
          &nbsp;</th>
        <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
      </tr>
      <tr *ngIf="medstyle1">
        <th style="width: 150px !important;border: 1px solid black;border-collapse: collapse;"><b>{{'Medicine'}}</b>
        </th>
        <th style=" width: 150px !important;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
        <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
        <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
        <th style="width: 150px !important;border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
          Intake</th>
        <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
      </tr>
      <tr *ngIf="medstyle2">
        <th style="width: 150px !important;border: 1px solid black !important;border-collapse: collapse !important;">
          <b>{{'Medicine'}}</b>
        </th>
        <th style=" width: 150px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
        <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
        <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
        <th style="width: 150px !important;border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
          Intake</th>
        <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
      </tr>
      <tr *ngIf="medstyle3">
        <th style="width: 150px !important;border: 1px solid black !important;border-collapse: collapse !important;">
          <b>{{'Medicine'}}</b>
        </th>
        <th style=" width: 150px;border: 1px solid black;border-collapse: collapse;"><b>{{'Dosage'}}</b></th>
        <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Duration'}}</b></th>
        <th style="width: 75px;border: 1px solid black;border-collapse: collapse;"><b>{{'Frequency'}}</b></th>
        <th style="width: 150px !important;border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
          Intake</th>
        <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">Note</th>
      </tr>
      <tr *ngFor="let product of listProducts">
        <td style="border: 1px solid black;border-collapse: collapse;">
          {{product.short_name}} {{product.drug_name}}
          {{product.generic_name}}
        </td>
        <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
          {{product.every_six}}
        </td>
        <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
          {{product.period}}</td>
        <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
          {{product.frequency}}
        </td>
        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
          <table>
            <tr>
              <td [hidden]="dur_time_flag">{{product.dure_txt_table}} - </td>
              <td [hidden]="dur_flag">{{product.drug_intake}}</td>
            </tr>
          </table>
        </td>
        <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">{{product.remarks }}</td>
      </tr>
    </table>
    <div style="width: 100%;margin: 20px !important;">
      <div style="margin-left: 50px;">
        <p *ngIf="instructions!=undefined"><b>{{'Instructions' }}: </b>
          <span>
            <div class="matlabel" [innerHtml]="instructions"></div>
          </span>
        </p>
      </div>
      <br />
      <div style="margin-left: 50px;" *ngIf="treatFlag">
        <p *ngIf="treatment!=undefined"><b>{{'Treatment' }}: </b>
          <span>
            <div class="matlabel" [innerHtml]="treatment"></div>
          </span>
        </p>
      </div>
      <div style="margin-left: 50px;">
        <p *ngIf="nextVisit!=undefined"><b>{{'Next visit' }}: </b>{{nextVisit}}</p>
      </div>
      <br />
      <div>
        <p style="margin-left: 50px;font-size: 13px;"> <strong>{{'Dr '+doctorName}}</strong> </p>
        <p style="margin-left: 50px;font-size: 13px">({{licenceCode}})</p>
        <!-- <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i></p>
                        <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b></p> -->
        <br />
      </div>
    </div>
  </div>
</div>