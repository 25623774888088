import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../../../app/providers/common-data.service';
import { Helper_Class } from 'src/app/helper_class';
// import { PharmacyService } from '../../../pharmacy/pharmacy.service';
import { PharmacyService } from 'src/app/pharma/pharmacy/pharmacy.service';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { ipaddress } from '../../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { pharmacy_helper } from '../../../pharma/Pharmcy_Helper';

@Component({
  selector: 'app-pharmapurchaserequest',
  templateUrl: './pharmapurchaserequest.component.html',
  styleUrls: ['./pharmapurchaserequest.component.css']
})
export class PharmapurchaserequestComponent implements OnInit {
  public getData;
  dtOptions: DataTables.Settings = {};
  persons: any = [];
  productID: any;
  productArray: any;
  allow: boolean;
  pharmacyLocationID: any;
  productTypeList: any;
  vendorID: any;
  pharmacyVendorArray: any = [];
  pharmacyLocationArray: any;
  pharmauserID: any;
  public vendor: string;
  public HSNNumber: string;
  public productName: string;
  public uom: string;
  public pharmacyLocation: string;
  public quantity: any;
  public purchaseReq: any = [];
  pharmacyID: string;
  pharmacistID: string;
  public productType: string;
  public prodType: string;
  public purchaseIndex = this.purchaseReq.length;
  public saveFlag: boolean = true;
  public price;
  public frompurchasereq:any;
  public prstorename;
  public prdepartname;
  public departmentarray=[];
  public storearray=[];
  public hsp_id;
  public user_type;
  public editbutton=false;
  public newbutton=false;
  public deletebutton=false;
  public printbutton=false;
  public viewbutton=false;
  public perunit=[];
  public user_depart_desc;
  public user_depart_id;
  public user_store_desc;
  public user_store_id;
  public departmentlength:boolean=false;
  public storelength:boolean=false;
  public data_type;
  public data_type_req;
  public hsp_id_req;

  constructor(public toastr: ToastrService, public messageservice: MenuViewService,public messageservice1:MenuViewService, public gservice: CommonDataService, public http: Http, public router: Router) {
  }

  ngOnInit(): void {

    this.getData = Helper_Class.getInfo();
    // this.hsp_id=this.getData.hosp_id;
    console.log("hsp id ="+this.hsp_id);

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.pharmacyID = Helper_Class.getInfo().pharma_id;
      this.hsp_id = Helper_Class.getInfo().hptl_clinic_id;
      this.data_type="pharmacy"
    }
    else{
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.hsp_id=this.getData.hospitals[0].hosp_id;
      this.data_type="pharmacy"
    }
    if(Helper_Class.getInfo().user_type == 'pharmacy'){
      this.data_type_req="pharmacy";
      this.hsp_id_req=Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    }else{
      this.data_type_req='hospital'
      this.hsp_id_req=this.getData.hospitals[0].hosp_id
    }
    this.pharmacistID = this.getData.user_id;
    this.frompurchasereq= pharmacy_helper.getfromreqlist();
    this.user_type = Helper_Class.getInfo().user_type;
    this.perunitfunction();
    this.getPharmacy();
    this.getVendor();
    this.getType();
    this.getdepart();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],

      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by vendor, product, or location"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };


if(Helper_Class.getInfo().user_type == 'Admin'){
  this.editbutton=true;
  this.newbutton=true;
  this.deletebutton=true;
  this.printbutton=true;
  this.viewbutton=true;
}else{
  if(Helper_Class.getmodulelist() != undefined){
    for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
      if (Helper_Class.getmodulelist()[i].module_id == "55"){
          if(Helper_Class.getmodulelist()[i].edit == "1"){
            this.editbutton=true;
          }
          if(Helper_Class.getmodulelist()[i].create == "1"){ 
            this.newbutton=true;
          }
          if(Helper_Class.getmodulelist()[i].delete == "1"){
            this.deletebutton=true;
          }
          if(Helper_Class.getmodulelist()[i].print == "1"){
            this.printbutton=true;
          }
          if(Helper_Class.getmodulelist()[i].view == "1"){
            this.viewbutton=true;
          }
        }else{
          this.editbutton=true;
          this.newbutton=true;
          this.deletebutton=true;
          this.printbutton=true;
          this.viewbutton=true;
        }
}
}
}

    
this.user_depart_desc=this.getData.hospitals[0].dept_desc
this.user_depart_id=this.getData.hospitals[0].department_id
this.user_store_desc=this.getData.hospitals[0].store_desc
this.user_store_id=this.getData.hospitals[0].store_id


  }

  // this.pharmacyLocation == undefined || this.pharmacyLocation == "" ||
  
  perunitfunction(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inv/gump',
      JSON.stringify({
        // hptl_clinic_id:this.hsp_id,
        pharma_id: this.hsp_id,
        type:this.data_type
        
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("per unit = "+JSON.stringify(obj))
          for(let i=0; i < obj.measures.length;i++){
            this.perunit.push({
              perunit_id:obj.measures[i].perunit_id,
              perunit_desc:obj.measures[i].perunit_desc
            })
          }
          console.log("perunit = "+JSON.stringify(obj))
        })
  }

  purchaseRequest() {
    if (this.vendor == undefined) {
      this.toastr.error("Enter the supplier name");
    } 
    else if(this.vendor == ""){
      this.toastr.error("Enter the supplier name");
    }
    else if(this.productName == undefined){
      this.toastr.error("Enter the product name");
    }
    else if(this.productName == ""){
      this.toastr.error("Enter the product name");
    }
    else if(this.quantity == undefined){
      this.toastr.error("Enter the quantity");
    }
    else if(this.quantity == ""){
      this.toastr.error("Enter the quantity");
    }
    else {
      if (this.purchaseIndex != 0) {
        this.allow = true;
      } else {
        this.allow = false;
      }
      for (var i = 0; i < this.pharmacyVendorArray.length; i++) {
        if (this.vendor == this.pharmacyVendorArray.name) {
          this.vendorID = this.pharmacyVendorArray[i].supp_id;
        }
      }
      this.purchaseReq.push({
        Index: this.purchaseIndex,
        mfg_id: this.vendorID,
        mfg_name: this.vendor,
        hsn_no: this.HSNNumber,
        product_id: this.productID,
        quantity: this.quantity,
        product_name: this.productName,
        price:this.price,
        uom: this.uom,
        amount: parseInt(this.price) * parseInt(this.quantity),
        location: this.pharmacyLocationID,
        loc_txt: this.pharmacyLocation,
        prod_supp_id: this.vendorID,
      });
      this.purchaseIndex++;
      this.HSNNumber = "";
      this.quantity = "";
      this.uom = "";
      this.productName = "";
      this.price="";
      if (this.purchaseIndex != 0) {
        this.allow = true;
      } else {
        this.allow = false;
      }
    }
  }

  getPharmacy() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/pharlocbyid/",
      {
        pharmacy_id: this.pharmacyID,
        pharmacist_id: this.getData.user_id,
      },
      { headers: headers }).subscribe(
        data => {
          if (data.json().pharma_locations != null) {
            this.pharmacyLocationArray = data.json().pharma_locations;
            console.log("location "+JSON.stringify(this.pharmacyLocationArray))
          }
        },
        error => {
        }
      )
  }

  selected_vendor(vendor){
    this.vendor = vendor.name;
    this.vendorID = vendor.supp_id;
    this.pharmacyVendorArray=[];
  }

  getdepart(){
    console.log("pharmacy Id ="+this.pharmacyID)
    var headers=new Headers();
    headers.append('Content-Type','application/json');
    this.http.post(ipaddress.getIp+"gen/gdbh", JSON.stringify({hptl_clinic_id:this.pharmacyID,type:this.user_type}),{ headers: headers}).subscribe(
      response =>{
        var obj=response.json()
        console.log(" DEPARTMENT DATA"+JSON.stringify(obj))
        this.departmentarray=obj.departments;

        
        if(this.getData.hospitals[0].department_id != undefined){
          this.prdepartname=this.user_depart_id;
          this.getstorename(this.prdepartname)
        }
        if(this.departmentarray.length == 1){
          this.departmentlength=true
        }
      }
    )
  }

  prbaseon_departement(value){

    this.getstorename(this.prdepartname)
  }

  getstorename(value){
    var senddata={
      hptl_clinic_id:this.hsp_id,
      department_id:value
    }
    console.log("send data"+JSON.stringify(senddata))
    // console.log("pharmacy Id ="+this.pharmacyID)
    var headers=new Headers();
    headers.append('Content-Type','application/json');
    this.http.post(ipaddress.getIp+"gen/gsdbh", JSON.stringify({hptl_clinic_id:this.hsp_id,department_id:value}),{ headers: headers}).subscribe(
      
      response =>{
          var obj=response.json()
          console.log(" store DATA"+JSON.stringify(obj));
          this.storearray=obj.stores;
          if(this.getData.hospitals[0].store_id != undefined)
          this.prstorename=this.storearray[0].store_names_id
          if(this.storearray.length == 1){
            this.storelength=true
          }
          // this.departmentarray=obj.departments;
    
      },
      error => {
        console.log("error on retriving the store name")
      }

    )
  }



  getVendor() {
    if(this.vendor!= undefined && this.vendor.length >2){
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "inv/gsupp/", 
        JSON.stringify({ 
           pharmacy_id: this.pharmacyID,
          hptl_clinic_id:this.hsp_id_req,
          name:this.vendor,
          type:this.data_type_req 
        }), 
        
        { headers: headers }).subscribe(
        data => {
          if (data.json().suppliers != null) {
            this.pharmacyVendorArray = data.json().suppliers;
            console.log(" vendor "+JSON.stringify(this.pharmacyVendorArray))
          }
        },
      )
    }
  }



  previous_purchasereqlist(){
    this.messageservice.sendMessage("purchasereqlist");
  }


  getProductDetails() {
    // this.pharmacyVendorArray.forEach(element => {
    //   element.supp_id;
    //   if (element.name == vendor) {
    //     this.vendorID = element.supp_id
    //   }
    // });
    // this.pharmacyVendorArray=[];
    if (this.productName != undefined && this.productName.length > 2) {
      var data_val = {
        pharmacy_id: this.pharmacyID,
        prod_type: this.productType,
        prod_name: this.productName,
        supp_id: this.vendorID,
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/gpcbypharn/",
        JSON.stringify(data_val), { headers: headers }).subscribe(
          data => {
            //var dataval = JSON.parse(JSON.stringify(data));
            if (data.json().products != null) {
              this.productArray = data.json().products;
              console.log("produc array"+JSON.stringify(this.productArray))
            }
          },
        )
    }
  }

  clearProdcode() {
    this.HSNNumber = "";
    this.productArray = [];
  }

  clearProdName() {
    this.productName = "";
    this.productArray = [];
  }

  getType() {
    this.http.get(ipaddress.getIp + "pharmacontrol/mtypes/").subscribe(
      data => {
        if (data.json().med_details != null) {
          this.productTypeList = data.json().med_details;
        }
      },
      error => {
      }
    )
  }

  getProductName(data) {
    this.productName = data.name;
    this.uom = data.uom_desc;
    this.productID = data.product_id;
    this.price=data.cost_price;
    this.HSNNumber = data.hsn_no;
    this.quantity=data.reorder_level;
    this.productArray = [];
  }

  requestSave() {
    this.saveFlag = true;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    var data_value_save = {
      products: this.purchaseReq,
      pharmacist_id: this.pharmacistID,
      pharmacy_id: this.pharmacyID,
      country: 91,
      department_id:this.prdepartname,
      store_id:this.prstorename
    }
    console.log("data_value= "+data_value_save)
    this.http.post(ipaddress.getIp + "pharmacontrol/spr/",
      JSON.stringify(data_value_save), { headers: headers }).subscribe(
        data => {
          var obj = data.json();

          if (obj != undefined && obj.key == "1") {
            this.saveFlag = false;
            this.toastr.success(Message_data.purchaseReqCreatedSuccess);
            this.messageservice.sendMessage("purchasereqlist");
          } else {
            this.saveFlag = true;
            this.toastr.error(Message_data.unableToSave);
          }
        }, error => { this.saveFlag = true; });
  }

  edit(data) {
    this.purchaseReq.splice(data, 1);
  }

  backNav() {
    this.messageservice.sendMessage("pharmacyhome");
  }
}
