import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
// import { ipaddress.getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress.js';
import { Http, Headers } from '@angular/http';
import { ipaddress } from '../../ipaddress' ;import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Doc_Helper } from '../Doc_Helper';
import { MatDialog } from '@angular/material/dialog';
import { BillHistViewComponent } from '../bill-hist-view/bill-hist-view.component';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { DomSanitizer } from '@angular/platform-browser';
import {Time_Formate} from '../../../assets/js/common';

@Component({
  selector: 'app-bill-detail-view',
  templateUrl: './bill-detail-view.component.html',
  styleUrls: ['./bill-detail-view.component.css']
})
export class BillDetailViewComponent implements OnInit {
  @ViewChild('printlogowithname') printlogowithname: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('printheader') printheader: ElementRef;
  public printbutton:boolean=false;
  public bill_view_id !: string | null;
  public billing_print_view: any = [];
  public pay_typeflag:boolean;
  public header_footer_flag: boolean;
  public loader: boolean;
  public pay_flag: boolean;
  public pay_type: string;
  public splname!: string | null;
  public hptl_clinic_id: string;
  public curr_date = null;
  public userinfo
  public profile_image: string;
  public first_name;
  public last_name;
  public hospital_logo;
  public messages;

  public notifications;
  public notifyflag: boolean = false;
  public submenu_flag: boolean = false;
  public submenu_flag1: boolean = false;
  public doctor_profile_img!: string | null;

  public msgflag: boolean;
  public cenra_gst;
  public stat_gst;
  public hospital_name;
  public billing_print_view1:any = [];
  public bill_printconcise: boolean;
  public totalshow: boolean;
  public billadvance_style: boolean;
  public prev_bal: string;
  public prev_balshow: boolean;
  public main_balshow: boolean;
  public estimate_dataprint;
  public treat_printid: boolean;
  public charge = [];
  public filter_style: boolean;
  public hosp_address: boolean;
  public address_datachk: string;
  public client_addbill: string;
  public total_bill: string;
  public total_bill1: string;
  public cent_gst: string;
  public sta_gst: string;
  public billing_print_array: any = [];
  public bill_middle_name: string;
  public doc_name: string;
  //mdbTable
  public printFlag:boolean;
  dtOptions: DataTables.Settings = {};
  persons: any = [];
  masterSelected: boolean = false;
  checklist: any;
  checkedList: any
  public paid;
  public roundoff;
  public appFlow;
  public flowFrom;
  public headerstyle;
  public bill_qty_flag:boolean=false;
  public bill_cons_flag:boolean=false;
  public finyear: string;
  public print_template;
  private rowid:number=0;
  public paytypreq:boolean;
  public paymentsarray:any=[];
  private docid:string;
  public printlogostyle;
  public inpatid;

  constructor(public sanitize:DomSanitizer,public dialog:MatDialog,public toastr: ToastrService, public http: Http, 
    public router: Router,public messageservice:MenuViewService,public dietService : MenuViewService) {
    this.splname = JSON.stringify(Helper_Class.getSpecializations());
    this.pay_flag = true;
  }

  backClicked() {
    if(this.appFlow != undefined){
      this.dietService.sendMessage("billList");
    }else{
      this.messageservice.sendMessage("billing");
    }
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      //"order": [[2, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2,] },
      ],
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-xs-12 col-sm-6 col-md-4"l><"col-xs-12 col-sm-12 col-md-4 text-center sm-order-last"i><"col-xs-12 col-sm-6 col-md-4"p>>'
    };
    if(Helper_Class.getmodulelist() != undefined){
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "7"){
            // if(Helper_Class.getmodulelist()[i].edit == "1"){
            //   this.editbutton=true;
            // }
            // if(Helper_Class.getmodulelist()[i].create == "1"){
            //   this.newbutton=true;
            // }
            // if(Helper_Class.getmodulelist()[i].delete == "1"){
            //   this.deletebutton=true;
            // }
            if(Helper_Class.getmodulelist()[i].print == "1"){
              this.printbutton=true;
            }
            // if(Helper_Class.getmodulelist()[i].view == "1"){
            //   this.viewbutton=true;
            // }
            
            
        }
       
      }
    }
    this.userinfo = Helper_Class.getInfo();   
    this.print_template=Helper_Class.getInfo().hospitals[0].bill_print_template;
    this.printlogostyle=Helper_Class.getInfo().hospitals[0].bill_print_logo_style;
    this.headerstyle = this.sanitize.bypassSecurityTrustHtml(Helper_Class.getInfo().hospitals[0].bill_print_heading);
    if(this.headerstyle == undefined || this.headerstyle == 'undefined'){
      this.headerstyle="";
    }

    if(this.userinfo.hospitals[0].bill_pay_type_req =="1"){
      this.paytypreq=false;
    } else {
      this.paytypreq=true;
    }
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.paytypreq=false;
      
    }
    else{

    }
    this.printFlag = Helper_Class.getHospitalInfo().doc_bill_print == "1" ? true : false;
    this.doctor_profile_img = this.userinfo.profile_image;
    this.hospital_logo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().hospitals[0].bill_print_logo;
    //this.doctor_profile_img=;
    this.first_name = Helper_Class.getProfilename();
    this.bill_view_id = Doc_Helper.getviewbills();
    this.finyear = Doc_Helper.getFinYear();
    this.docid = Doc_Helper.getBillDocId();
    
    this.flowFrom = "doctor";
    this.appFlow = Helper_Class.getBillingFlow();
    console.log("this.appFlow" + JSON.stringify(this.appFlow));
    if(this.appFlow != undefined){
      this.flowFrom = "dietician";
    }
    this.Get_Bill_detail_view();
    this.hosp_address = true;
    this.bill_printconcise = true;
    this.treat_printid = true;
    this.cenra_gst = true;
    this.stat_gst = true;
    this.prev_balshow = true;
    //this.bill_qty_flag = Helper_Class.getInfo().hospitals[0].bill_qty_flag;
   
    if(Helper_Class.getInfo().hospitals[0].bill_qty_flag == 0){
      this.bill_qty_flag =true;
    } else {
      this.bill_qty_flag = false;
    }
    if(Helper_Class.getInfo().hospitals[0].bill_cons_flag == 0){
      this.bill_cons_flag =true;
    } else {
      this.bill_cons_flag = false;
    }
  } 

  Get_Bill_detail_view() {
    var spl_name = "";
    var send_data;
    
    if(Helper_Class.getSpecializations() != undefined){
      var spl = Helper_Class.getSpecializations();
      for(var i = 0; i < spl.length; i++){
        if(spl[i].spl_name == "Dental"){
          spl_name = "Dental";
          send_data = {
            doc_reg_id: this.docid,
            billing_id: this.bill_view_id,
            spl_name: spl_name,
            app_type: this.flowFrom,
            fin_year:this.finyear,
            center_id:this.hptl_clinic_id
          }

        }else{
          send_data = {
            doc_reg_id: this.docid,
            billing_id: this.bill_view_id,
            app_type: this.flowFrom,
            fin_year:this.finyear,
            center_id:this.hptl_clinic_id
          }
        }
      }
    }else{
      send_data = {
        doc_reg_id: this.userinfo.user_id,
        billing_id: this.bill_view_id,
        app_type: this.flowFrom,
        fin_year:this.finyear,
        center_id:this.hptl_clinic_id
      }
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/getbill/',
      JSON.stringify(send_data),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          
          if (obj != null) {
            this.loader = true;
            if (obj.cgst != null) {
              this.cenra_gst = false;
              this.stat_gst = false;
            }
            if (obj.bills != undefined) {
              this.billing_print_view1 = [];
              this.billing_print_view1 = obj.bills;
              if (this.billing_print_view1 != null && this.billing_print_view1[0].charge_type != null) {
                this.bill_printconcise = false;

              } else {
                this.bill_printconcise = true;
              }
              for (var i = 0; i < this.billing_print_view1.length; i++) {
                this.rowid +=1;
                this.billing_print_view.push({
                  index:this.rowid,
                  bill_type: this.billing_print_view1[i].charge_desc,
                  amount_txt: parseFloat(this.billing_print_view1[i].amount_txt).toFixed(2),
                  concession: this.billing_print_view1[i].concession,
                  fee: parseFloat(this.billing_print_view1[i].fee).toFixed(2),
                  charge_type: this.billing_print_view1[i].charge_type,
                  pay_type: this.billing_print_view1[i].pay_type,
                  quantity:this.billing_print_view1[i].quantity,
                })
              }
              //Doc_Helper.setpaytype(this.billing_print_view.pay_type);
              

            }
           //
            if (obj.bill_amount == undefined && obj.bill_amount == "") {
              this.totalshow = true;
            }

            if (obj.advance == undefined || obj.advance == "") {
              this.billadvance_style = true;
            }
           
            if (obj.prev_balance != null) {
              this.prev_balshow = false;
              this.prev_bal = obj.prev_balance;

            } else {
              this.prev_balshow = true;
            }
            if(obj.pay_type != null){
              this.pay_type =obj.pay_type;
            }
            if (obj.balance == undefined || obj.balance == "") {
              this.main_balshow = true;
            }

            this.estimate_dataprint = obj.estimates;
            if (this.estimate_dataprint != undefined) {
              this.treat_printid = false;

            } else {
              this.treat_printid = true;
            }

            this.charge = [];
            if (obj.hptl_name != null) {
              this.hosp_address = false;

            } else {
              this.hosp_address = true;
            }

            if (obj.created_date != undefined) {
              var datetxt;
              var get_date = obj.created_date.toString().split('-');
              datetxt = get_date[2] + "-" + get_date[1] + "-" + get_date[0]+" "+Time_Formate(obj.created_time);
            }

            if (obj.first_name != undefined) {
              this.inpatid =obj.inp_hosp_id;
              if (obj.address2 != null && obj.address2 != "") {
                this.address_datachk = obj.address1 + ", " + obj.address2 + ",";

              } else {
                this.address_datachk = obj.address1 + ","
              }
              
              if(obj.caddress1 != undefined && obj.caddress1 != "null") {
                if(obj.caddress2 != undefined && obj.caddress2 != null ) {
                  this.client_addbill = encrypt_decript.Decript(obj.caddress1).toString() + " " + encrypt_decript.Decript(obj.caddress2).toString() + ",";

                } else {
                  this.client_addbill = encrypt_decript.Decript(obj.caddress1).toString();
                }

              } else {
                this.client_addbill = "";
              }

              var tot_amt = "0";
              if (obj.bills != null && obj.bills.length != 0) {
                for (var i = 0; i < obj.bills.length; i++) {
                  if (obj.bills[i].fee != null) {
                    tot_amt = (parseInt(tot_amt) + parseFloat(obj.bills[i].fee)).toFixed(2);
                  }
                  if (obj.bills[i].pay_type != null) {
                    this.pay_type = obj.bills[i].pay_type;
                    this.pay_flag = false;
                  }
                }
              }

              if (obj.estimates != null) {
                if (obj.estimates.length != 0) {
                  for (var i = 0; i < obj.estimates.length; i++) {
                    if (obj.estimates[i].estimate != null) {
                      tot_amt = (parseFloat(tot_amt) + parseFloat(obj.estimates[i].estimate)).toFixed(2);
                    }
                  }
                }
              }

              this.total_bill = tot_amt;
              this.total_bill1 = tot_amt;
              if (obj.cgst != null) {
                this.cent_gst = (parseFloat(this.total_bill) * (parseFloat(obj.cgst) / 100)).toFixed(2);
                this.sta_gst = (parseFloat(this.total_bill) * (parseFloat(obj.sgst) / 100)).toFixed(2);
              }

              this.total_bill = encrypt_decript.Decript(obj.bill_amount);
              this.paid =  ((parseFloat(this.total_bill) - parseFloat(obj.round_off)) - parseFloat(obj.balance) - parseFloat(obj.advance)).toFixed(2);
              this.paid =  this.paid != undefined ? parseFloat(this.paid).toFixed(2) : '0.00';
              this.roundoff = parseFloat(obj.round_off).toFixed(2);
              if (obj.middle_name != null && obj.middle_name != undefined) {
                this.bill_middle_name = encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.middle_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();

              } else {
                this.bill_middle_name = encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
              }

              if (obj.dr_middle_name == undefined) {
                this.doc_name = "Dr." + obj.dr_first_name + " " + obj.dr_last_name;

              } else {
                this.doc_name = "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
              }

              var cmob;
              if(obj.mobile != undefined){
                cmob = encrypt_decript.Decript(obj.mobile)
              } else {
                cmob="";
              }

              this.billing_print_array.push({
                dr_name: "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name,
                licence_code: " Reg. No: " + obj.licence_code,
                hptl_name: obj.hptl_name,
                address: this.address_datachk,
                location: obj.location + ", ",
                city: obj.city + " - " + obj.zipcode + ",",
                state: obj.state + " - " + obj.country + ",",
                telephone: obj.telephone,
                created_date: datetxt,
                appointment_id: obj.appointment_id,
                client_name: this.bill_middle_name,
                advance: parseFloat(obj.advance).toFixed(2),
                balance: parseFloat(obj.balance).toFixed(2),
                caddress: this.client_addbill,
                pay_type:this.pay_type,
                clocation: obj.clocation + ", ",
                clientcity: obj.ccity + " - " + encrypt_decript.Decript(obj.czipcode) + ",",
                clestare: obj.cstate + " - " + obj.ccountry + ".",
                client_reg_id: obj.client_hosp_id,
                tot_amt : this.total_bill,
                mobile:cmob,
                inpatid:obj.inp_hosp_id,
              });

              if(obj.payments != undefined){
                this.paymentsarray = obj.payments;
              }
            }

          } else {
            this.loader = true;
            this.toastr.error(Message_data.unableToGetBillDet);
          }
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  print_area() {
    let printContents, popupWin;
    if (this.print_template != undefined && this.print_template == "banner") {
      printContents = this.printbanner.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else if (this.print_template != undefined && this.print_template == "logowithname") {
      printContents = this.printlogowithname.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
    }

   // printContents = document.getElementById('bill-print-section')!.innerHTML;
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <head>
      <title>Bill</title>
      <link rel="stylesheet" media="screen" href="">
      <style>
      @page { size: auto;  margin: 0mm; }
      .table_class {
        background-color: rgb(108, 180, 248);
      }
      p{
        margin:0;
      }
      
      </style>
    </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  history(){
    const dialogRef = this.dialog.open(BillHistViewComponent, {
      width: '700px',
      height: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
