import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Date_Formate, ConvertTimeformat, Time_Formate } from '../../../assets/js/common';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ChangeDetectorRef } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreviousPrescriptionPopupComponent } from '../previous-prescription-popup/previous-prescription-popup.component';
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from '../casesheet/casesheet.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MedPresPopupPageComponent } from '../med-pres-popup-page/med-pres-popup-page.component';
import { PresDialogComponent } from '../pres-dialog/pres-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { BmiandbmrService } from '../service/bmiandbmr.service';
import { masterCSData_Helper } from '../casesheet/masterCSData_Helper';
import { Master_Helper } from '../Master_Helper';
import { MasterCSHelperService } from '../casesheet/masterCSData.service';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { FrontDesk_Helper } from '../../FrontDesk_module/FrontDesk_Helper';
import { ipaddress } from '../../ipaddress'; import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from 'moment';

declare var $: any;
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-edit-prescription',
  templateUrl: './edit-prescription.component.html',
  styleUrls: ['./edit-prescription.component.scss']
})
export class EditPrescriptionComponent implements OnInit {
  public print_view_flag: boolean = true;
  public highRiskPresent:boolean=false;
  public disease_flag: boolean = false;
  public Inpatientflag:boolean=false;
  public DATE;
  public no_of_days;
  public hospitalLogo;
  public clnt_abd;
  public cbgflag;
  public clnt_cbg;
  public abdflag: boolean;
  public clnt_rr;
  public cnsflag:boolean;
  public clnt_spo2;
  public clnt_cns;
  public rrflag:boolean;
  public spoflag:boolean;
  public bgImage;
  public consultantanme;
  public food_details;
  public env_details;
  public drug_details;
  public addressdetails;
  public allergy;
  public blood_group;
  public hospitalname;
  public ward_onlycolor;
  public high_riskcolor;
  public sound_alikecolor;
  public look_alikecolor;
  public barcode_url;  
  public lookalike;
  public medicinecolorarray=[];
  public soundalike;
  public high_risk;
  public look_alike;
  public sound_alike;
  public ward_only;
  public highrisk;
  public ward;
  public ward_bed;
 
  public intakesession: boolean = false;
  public genericname;
  public genericnameflag: boolean = false;
  public default_quantity;
  public found;
  public printtime;
  public printLanguageflag: boolean = true;
  public kidney_flag: boolean = false;
  public cvsflag: boolean = false;
  public rsflag: boolean = false;
  public heartrateflag: boolean = false;
  public print_same_page = true;
  public clnt_heartrate;
  public pulseflag: boolean = false;
  public printFlag: boolean = false;
  public param_four: boolean;
  public ynopt1: boolean = true;
  @ViewChild('recognitionResult') recognitionResult: ElementRef;
  @ViewChild('printlogowithname') printlogowithname: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('printnoheadsidebar') printnoheadsidebar: ElementRef;
  public list_button_flag: boolean = false;
  public complaints;
  public saveflag: boolean = false;
  public medPresFlag = "min";
  public hosp_img;
  public hptl_pres_logo: string;
  public doctoraddress;
  public treatflag: boolean;
  public refer_txt;
  public ref_flag: boolean;
  public treatment;
  public new_med: boolean;
  public newmed_flag: boolean;
  public flagvalue: boolean;
  public rowid: string;
  public order_now: boolean = true;
  public remark_txt: string;
  public clnt_bp: string;
  public clnt_pulse: string;
  public addmed: boolean = false;
  public CurrentDatetime;
  public Appoint_Date: string;
  public daydur_txt;
  public dct_hospital: string;
  public doct_name: string;
  public doct_location: string;
  public doct_address: string;
  public doct_city: string;
  public doct_state: string;
  public doct_clientname: string;
  public mobile: string;
  public Medicare_name: string;
  public Medical_prescription_array = [];
  public eng_flag: boolean;
  public tamil_flag: boolean;
  public telugu_flag: boolean;
  public kannada_flag: boolean;
  public malay_flag: boolean;
  public ortho_flag: boolean;
  public hospitalinfoarray;
  public barcode_flag: boolean;
  public barcode;
  public clnt_cvs: string;
  public clnt_rs: string;
  public clnt_kidney: string;
  public instructions: any;
  public print_instruction: any;
  public bp_flag: boolean;
  public symptoms_flag: boolean;
  public med_typetxt1 = [];
  public medtypetxt_type;
  public med_typetxt: string;
  public drug_typetxt: string;
  public medicine_nametxt: string;
  public drug_med_id: string;
  public Get_Intake_array = [];
  public med_detail_list = [];
  public drug_id_list = [];
  public intake_txt: string;
  public mor: string;
  public aft: string;
  public eve: string;
  public ngt: string;
  public every_six: string;
  public dure_txt: string;
  public dure_write: string;
  public listProducts = [];
  public day_txt: string;
  public afterfood_txt: string;
  public afterfood: string;
  public medicineaddbtn: boolean;
  public Medicine_table: boolean = true;
  public prfer_pharmatxt: string;
  public prefer_add: string;
  public Client_id;
  public rel_id;
  public sub_id;
  public specilization_txt;
  public app_id;
  public print_template: string;
  public doc_qualif: string;
  public label1 = "Mor";
  public instr_flag: boolean;

  public dis_txt;
  public oberse_med;
  public home_care: string;
  public presmed_normal: string;
  public diab_medpres: string;
  public check_pres;
  public pharma_id;
  public Pharma_list_arry;
  public Pharma_data = [];
  public Pharmanamelist;
  public dure_show: boolean = false;
  public mor_lable: boolean = true;
  public after_eventxt: boolean = true;
  public mor_id: boolean;
  public after_id: boolean;
  public quan_lable: boolean = true;
  public Med_addbtn: boolean = false;
  public hosp_address: boolean;
  public intake_id;
  public Icd_code: string;
  public Icd_Descript: string;
  matches: String[];
  isRecording = false;
  public header_footer_flag: boolean;
  public pop_flag = null;
  public popup_footer: boolean;
  public hospital_clinic_id;
  public client_gender;
  public client_age;
  public doctorname;
  prev_pres;
  autoRx;
  refill_pres;
  public morning_req: boolean;
  public morning_dis: boolean;
  public afternoon_req: boolean;
  public even_dis: boolean;
  public afternoon_dis: boolean;
  public evening_req: boolean;
  public night_req: boolean;
  public night_dis: boolean;
  public backFlag: boolean = false;
  public bg_image: string;
  public ganericName: string;

  public areas = [
    { size: 30 },
    { size: 70 },
  ];

  presentdate = new Date()
  public mic_off: any = {
    icon: 'mic',
    color: 'balance'
  };
  public mic_on: any = {
    icon: 'mic',
    color: 'danger'
  };
  public mic: any = this.mic_off;
  public recognition: any;
  public recognizing: Boolean;
  public get_txt: string;
  public dure_flag: boolean;
  public medtypetxt;
  public short_form;
  public show;
  public show_intake;
  public eve_eventxt: boolean = true;
  public night_eventxt: boolean = true;
  public fill_type: string;
  public morn_eventxt: boolean;
  public intake_show: boolean;
  public eve_id: boolean;
  public ngt_dis: boolean;
  public licence_code: string;
  public specializations_name = [];
  public qualification = [];
  public dure_require: boolean;
  public pdfObj;
  public imagestring;
  public sendpdf;
  public printpres: string;
  public hosp_footer: string;
  public printf_flag: boolean;
  //list view
  public med_pres_list = [];
  public send_client_reg_id: any = {};
  public med_pres_list_data;
  public med_pres_listdata: boolean;
  public docname_flag: boolean;
  flow: string;
  public curr_date;
  public doc_reg_id;
  public list_data = [];
  public pharcy_details = [];
  public pharma_control: boolean = false;
  public doctor_qualify_data;
  public doctor_Qualify_txt;
  public hptl_name;
  public next_txt1;
  public address;
  public doc_loc;
  public doc_city;
  public doc_state;
  public doc_country;
  public doc_telephone;
  public url_prescription: string;
  public prescription_pres_id;
  public data_prescription: any;
  public tablet_tracker = [];
  public date_pop = [];
  public total_name: string;
  public dr_total_name: string;
  public middlename: string;
  public date_prescription: string;
  public disease_name: string;
  public disease_describtion: string;
  public sub_rel_id: string;
  public rel_name: string;
  public toastMessage: string;
  public has_pharma: boolean = false;
  public pharmacy_name: string;
  public phar_addr1: string;
  public phar_addr2: string;
  public phar_loc: string;
  public phar_city: string;
  public phar_zipcode: string;
  public phar_state: string;
  public phar_cnty: string;
  public telephone: string;
  public store_pick: boolean = false;
  public delopt: boolean = false;
  public home_del: boolean = false;
  public val_days;
  public work_time;
  public notification_id;
  public send_data;
  public has_Search: boolean = false;
  public has_multi_tab: boolean = false;
  public appointment: boolean = false;
  public symptoms: string;
  public language: string;

  public balance_quantity: number = 0;
  public docnameflag: boolean;
  public hospflag: boolean;
  public sen_data;
  public med_view_list: boolean = false;
  public splname;
  subscription: Subscription;
  public flagviewrx: boolean;
  public current_year: string;
  public current_date: string;
  public current_time: string;
  public personalinfo;
  public userinfo;
  public locationinfo;
  public doct_country;
  public zipcode;
  public navigtionflag: string;
  tomorrow = new Date();
  public inpatBackFlag: boolean = false;
  public hospital_logo;
  public doct_address1;
  public doct_address2;
  public add_MP: boolean = false;

  public sugeryimg: string;
  public getdata_value;

  public symptom_data = [];
  public symptomlist = [];
  public symptoms_list = [];
  public selected_symptoms = [];
  public newsymptoms = [];
  public symptoms_text = "";

  public print_same_age: boolean;

  public blood_test_head;
  public blood_test_content;
  public scan_test_head;
  public scan_test_content;
  public us_test_head;
  public us_test_content;
  public xray_test_head;
  public xray_test_content;
  public bio_test_content;
  public bio_test_head;
  public ut_test_content;
  public ut_test_head;
  public ft_test_head;
  public ft_test_content;
  public culture_test_head;
  public culture_test_content;
  public mediceList = [];
  public presID;
  public presListView: boolean;

  Reset() {
    $("#obs_txt1").val("");
    this.get_txt = "";
  }

  public pritn_blood_flag: boolean = false;
  content;
  htmlString: string = '';

  bindings = {
    // This will overwrite the default binding also named 'tab'
    tab: {
      key: 9,
      handler: function () {
        // Handle tab
      }
    },

    // There is no default binding named 'custom'
    // so this will be added without overwriting anything
    custom: {
      key: 'B',
      shiftKey: true,
      handler: function (range, context) {
        // Handle shift+b
      }
    },

    list: {
      key: 'backspace',
      format: ['list'],
      handler: function (range, context) {
        if (context.offset === 0) {
          // When backspace on the first character of a list,
          // remove the list instead
          // this.quill.format('list', false, Quill.sources.USER);
        } else {
          // Otherwise propogate to Quill's default
          return true;
        }
      }
    }
  };

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      // [{ 'header': 1 }, { 'header': 2 }], // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
      [{ 'direction': 'rtl' }], // text direction
      [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean'], // remove formatting button
      // ['link', 'image'], // link and image, video
      // ['table']
    ],
    keyboard: {
      bindings: this.bindings
    }
  };

  editorStyle = {
    height: '300px;',
  };

  // diagnosis and medical flow
  public loc_name;
  public subtestname: any = [];
  public subtestuname: any = [];
  public subtestsname: any = [];
  public subtestxname: any = [];
  public subtestbname: any = [];
  public subtesturname: any = [];
  public subtestfname: any = [];
  public subtestcname: any = [];

  public bloodtest: boolean = true;
  public ultratest: boolean = true;
  public scantest: boolean = true;
  public xraytest: boolean = true;
  public biopsytest: boolean = true;
  public urinetest: boolean = true;
  public faecestest: boolean = true;
  public culturetest: boolean = true;

  public Blood_test = [];
  public filt_city;
  public loc_id;
  public sercah_center: string;

  public filt_state;
  public filt_country;
  public obs_diag: string;
  public city_url = ipaddress.getIp + "gen/city";
  public state_url = ipaddress.getIp + "gen/state";
  public country_url = ipaddress.getIp + "gen/count";
  public urine_test_array = [];
  public feace_test_array = [];
  public scan_test_array = [];
  public xray_test_array = [];
  public ultra_test_array = [];
  public culture_test_array = [];
  public biopsy_test_array = []
  public dig: any;
  public dig_view_flag: boolean;
  public diag_detail_List;
  public location_search_items;
  public location_txt;
  public diag_list_address;
  public diag_address1;
  public diag_address2;
  public diag_cen_name;
  public obs_add_diag: any = [];
  public test_Show: boolean = false;
  public diag_cen_id;
  public diag_centre_id;
  public addr: any;

  public clocation: any[];
  public Urine_test = [];
  public Feace_test = [];
  public Xray_test = [];
  public Scan_test = [];
  public Ultra_test = [];
  public testtypename: any = [];
  public filt_state_desc;
  public filt_city_desc;
  public country_id;
  public diag_location;
  public diag_city;
  public diag_state;
  public diag_zipcode;
  public diag_cntry;
  public diag_telpho;
  public test_flag: boolean = false;
  public checked: boolean = false;
  public frommedical: boolean;
  public test_now: boolean;
  public book_app: any;
  public observation: string;
  public blood_test = [];
  public flagtxt;

  public pritn_scan_flag: boolean = false;
  public pritn_us_flag: boolean = false;
  public pritn_xray_flag: boolean = false;
  public pritn_bio_flag: boolean = false;
  public pritn_ft_flag: boolean = false;
  public pritn_ut_flag: boolean = false;
  public pritn_culture_flag: boolean = false;

  public print_diag: boolean;
  public print_medidiag: boolean = false;
  public print_med: boolean;
  public inpatiant_save: boolean = false;

  public both_save_flag: boolean = false;
  public med_save_flag: boolean = false;
  public diag_save_flag: boolean = false;

  public get_locname_url: string;

  public clnt_location_list = [];

  public clnt_country_list = [];
  public clnt_state_list = [];
  public clnt_city_list = [];

  public clnt_country_id;
  public clnt_state_id;
  public clnt_city_id;
  public clnt_location_id;

  public search_location;
  public search_city;
  public search_state;
  public search_city_desc;
  public search_state_desc;
  public search_country;

  public height;
  public weight;
  public temparature;
  public height_flag: boolean;
  public weight_flag: boolean;
  public temparature_flag: boolean;

  //Get medicine name
  public Medicinenamelist;
  public Medicne_data = [];
  public Medicine_list = [];

  //Medical prescription save
  public next_txt;
  public next_datetxt;
  public daydur_show: boolean;
  public investigation;
  public currentDate;
  public medicineFlag: boolean = false;

  // inpatient view
  public medInpatientViewPrec: boolean;
  public viewpreslist_array = [];
  public user_id;
  public culture_test;
  dtOptions: DataTables.Settings = {};
  private med_id;
  public short_name;
  public genericDisplayFlag: boolean = false;
  public book_app_now: boolean = false;
  public dur_flag: boolean = false;
  public notes_flag: boolean = false;
  public medpres: boolean = true;
  public diagpres: boolean = false;
  public headerstyle;
  public footerstyle;
  public preslogo_style;
  public med_style;
  public dosage_style;
  public dur_style;
  public freq_style;
  public indur_style;
  public note_style;
  public salutation;
  public presType;
  private req_estimate: string;
  private pageflag: string;
  public mixtype: any = [];
  public intakeOption: any = [];
  public mixing_txt: string;
  public mix_show: boolean = true;
  private sendPresIdData;
  private pharmaId;

  public newbutton: boolean = false
  public editbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public moduleList: any = [];
  public intakemodes:any=[];
  public intake_mode:string="";
  public intakemode:boolean=false;

  constructor(private translate: TranslateService, public sanitizer: DomSanitizer, public bmiservice: BmiandbmrService,
    public dialog: MatDialog, public http: Http, public cd: ChangeDetectorRef,
    public routes: ActivatedRoute, public router: Router, public toastr: ToastrService,
    public messageservice: CasesheetService, public naviagationService: MenuViewService, public masterCSdata: MasterCSHelperService) {
    this.mor = "1";
    this.aft = "1";
    this.ngt = "1";
    this.eve = "1";
    this.instr_flag = true;

    this.dure_flag = false;
    this.eng_flag = false;
    this.ortho_flag = false;
    this.bp_flag = false;
    this.treatflag = false;
    this.ref_flag = false;
    this.printf_flag = true;
    this.newmed_flag = true;
    this.dure_show = false;
    this.get_txt = "";
    this.flagviewrx = true;
    this.recognition = null;
    this.recognizing = false;

    this.translate.setDefaultLang('english');
    this.translate.use('english');
    this.get_locname_url = ipaddress.getIp + "usercontroller/locbyname";

    this.addmed = false;
    this.frommedical = false;
    this.listProducts = [];

    this.day_txt = "";
    this.dis_txt = "";
    this.listProducts = [];
    this.Blood_test = [];
    this.testtypename = [];
    this.sercah_center = "";
    this.subtestname = [];
    this.subtestbname = [];
    this.subtestfname = [];
    this.subtestname = [];
    this.subtestname = [];
    this.subtestsname = [];
    this.subtestuname = [];
    this.subtesturname = [];
    this.subtestxname = [];
    this.subtestcname = [];
    this.diag_detail_List = [];
    this.location_txt = [];
    this.obs_add_diag = "";
    this.dig_view_flag = false;

    this.Medicine_table = true;
    this.quan_lable = true;
    this.mor_lable = false;
    this.after_eventxt = false;
    this.eve_eventxt = false;
    this.daydur_show = false;
    this.night_eventxt = false;
    this.mor_id = false;
    this.after_id = false;
    this.eve_id = true;
    this.Med_addbtn = true;
    this.language = "English";
    this.daydur_txt = "day(s)"
    this.afterfood_txt = "After food";
    this.dure_txt = "30";
    this.flow = "order";
  }

  async ngOnInit() {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 5,
      order: [[1, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px' style='display:inline-block;'/>",
        searchPlaceholder: "Search by medicine name"
      },
      dom: '<"row"<"col-sm-12 col-xs-12"f>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center"p>>'
    };
   
    this.presID = FrontDesk_Helper.getreceptviewprecription();
    this.sendPresIdData = {
      pres_id: this.presID.pres_id,
    }
    this.personalinfo = this.presID;
  
    this.subscription = this.bmiservice.getMessage().subscribe(message => {
      if (message) {
        if (message.flag == "vitals") {
          if (message.height != undefined && message.height != null && message.height != "") {
            this.height = message.height + message.height_measure;
            this.height_flag = true;
          } else {
            this.height_flag = false;
          }
          if (message.weight != undefined && message.weight != null && message.weight != "") {
            this.weight = message.weight + message.weight_measure;
            this.weight_flag = true;
          } else {
            this.weight_flag = false;
          }
          if (message.temparature != undefined && message.temparature != null) {
            this.temparature = message.temparature;
            this.temparature_flag = true;
          } else {
            this.temparature_flag = false;
          }
          if (message.bp != null && message.bp != undefined) {
            this.clnt_bp = message.bp;
            this.bp_flag = true;
          } else {
            this.bp_flag = false;
          }
          if (message.pulse != undefined && message.bp != null) {
            this.clnt_pulse = message.pulse;
            this.bp_flag = true;
          } else {
            this.bp_flag = false;
            this.clnt_pulse = "";
          }
        }
      }
    });

    this.moduleList = Helper_Class.getmodulelist();

    if (this.moduleList != undefined) {
      for (var i = 0; i < this.moduleList.length; i++) {
        if (this.moduleList[i].module_id == "6") {
          console.log(JSON.stringify(this.moduleList[i]));
          if (this.moduleList[i].edit == "1") {
            this.editbutton = true;//update button
          }
          if (this.moduleList[i].create == "1") {
            this.newbutton = true;
          }
          if (this.moduleList[i].delete == "1") {
            this.deletebutton = true;
          }
          if (this.moduleList[i].print == "1") {
            this.printbutton = true;
          }
          if (this.moduleList[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }

    var Moduleidlist;
    if (Doc_Helper.getModuleList() != null) {
      Moduleidlist = Doc_Helper.getModuleList();
      for (var i = 0; i < Moduleidlist.length; i++) {
        if (Moduleidlist[i] == "99") {
          this.genericDisplayFlag = true;
        }
      }
    }
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });

    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      this.medPresFlag = message;
      if (message == "max") {
        this.med_view_list = false;
        this.print_view_flag = true;
        Helper_Class.set_med_pres_id(undefined);
      }

      if (Doc_Helper.getDiagAppflow() != "diag") {
        this.addmed = false;
        this.frommedical = true;
        Doc_Helper.setMedicalHeading("false");

      } else {
        this.saveflag = true;
        if (Doc_Helper.getMedDiag() != undefined) {
          if (Doc_Helper.getMedDiag() == true) {
            this.saveflag = false;
          } else {
            this.saveflag = true;
          }
        } else if ((Doc_Helper.getDiagSave() != undefined) || (Doc_Helper.getMedSave() != undefined)) {
          if (Doc_Helper.getDiagSave() == true && Doc_Helper.getMedSave() == undefined) {
            this.saveflag = true;
          }

          if (Doc_Helper.getDiagSave() == true && Doc_Helper.getMedSave() == true) {
            this.saveflag = false;
          }

          if (Doc_Helper.getMedSave() == true && Doc_Helper.getDiagSave() == undefined) {
            this.saveflag = false;
          }

        } else {
          this.saveflag = true;
        }
        this.frommedical = false;
        this.addmed = true;
      }
    });

    this.userinfo = Helper_Class.getInfo();
    console.log("USER INFO " + JSON.stringify(this.userinfo))
    this.user_id = this.userinfo.user_id;
    this.req_estimate = this.userinfo.hospitals[0].req_estimate;
    if (this.userinfo.hospitals[0].pres_duration_flag == "0") {
      this.dur_flag = true;
    }
    if (this.userinfo.hospitals[0].pres_notes_flag == "0") {
      this.notes_flag = true;
    }
    this.language = this.userinfo.hospitals[0].language;
   
    if (FrontDesk_Helper.getreceptviewprecription().barcode != undefined) {
      this.barcode_flag = true;
      this.barcode_url = ipaddress.Ip_with_img_address + FrontDesk_Helper.getreceptviewprecription().barcode;
    }
    if (Doc_Helper.getHospital_pres_logo() != undefined && Doc_Helper.getHospital_pres_logo() != "undefined")
      this.hospitalLogo = Doc_Helper.getHospital_pres_logo();
      console.log(this.hospitalLogo)
      if (Doc_Helper.getHospital_bg_image() != undefined)
        this.bgImage = Doc_Helper.getHospital_bg_image();
    // if(this.userheaderdata.allergy != undefined){
    //   this.allergy = this.userheaderdata.allergy
    // }
    // if(this.userheaderdata.drug_details != undefined){
    //   this.drug_details = this.userheaderdata.drug_details
    // }
    // if(this.userheaderdata.env_details != undefined){
    //   this.env_details = this.userheaderdata.env_details
    // }
    // if(this.userheaderdata.food_details != undefined){
    //   this.food_details = this.userheaderdata.food_details
    // }

    var getdata = this.userinfo.medicares;
    if (getdata != undefined && getdata != null) {
      for (var i = 0; i < getdata.length; i++) {
        this.Medicare_name = getdata[0].medicare_name;
      }
    }

    if (Helper_Class.getHospitalInfo() != undefined) {
      this.printFlag = Helper_Class.getHospitalInfo().doc_pres_print == "1" ? true : false;
    }

    this.locationinfo = Helper_Class.getHospitalInfo();

    this.doctorname = this.userinfo.first_name + " " + this.userinfo.last_name;
    this.doct_name = this.doctorname;
    this.hospitalinfoarray = Helper_Class.getHospital();
    this.hospitalname = Helper_Class.getHospital()[0].hptl_name;
    this.lookalike =this.hospitalinfoarray[0].look_alike_color;
    this.soundalike =this.hospitalinfoarray[0].sound_alike_color;
    this.highrisk=this.hospitalinfoarray[0].high_risk_color;
    this.pharma_id = this.hospitalinfoarray[0].pharma_id;
    this.prfer_pharmatxt = this.hospitalinfoarray[0].pharma_name;
    if (this.prfer_pharmatxt != undefined) {
      this.hospital_clinic_id = this.hospitalinfoarray[0].hptl_clinic_id;
      this.Get_pharmacy_address();
      this.getmedicineonit();
    }

    this.specializations_name = Helper_Class.getSpecializations();
    this.qualification = Helper_Class.getQualification();
    this.doc_qualif = Helper_Class.getQualification();
    this.recognition = null;
    this.recognizing = false;
    this.licence_code = this.userinfo.licence_code;
    Helper_Class.set_med_pres_id(null);
    Helper_Class.set_diag_pres_id(null);

    if (Doc_Helper.getHospital_logo() != undefined) {
      this.hosp_img = Doc_Helper.getHospital_logo();
      this.getBase64Image(this.hosp_img);
    }

    if (Doc_Helper.getHospFooter() != undefined) {
      this.hosp_footer = Doc_Helper.getHospFooter();
      this.printf_flag = false;
    } else {
      this.printf_flag = true;
    }
    if (Doc_Helper.getHospital_bg_image() != undefined) {
      this.bg_image = Doc_Helper.getHospital_bg_image();
    }
    this.clnt_bp = this.personalinfo.bp == undefined ? "" : this.personalinfo.bp + " " + "mmHg";
    if (this.personalinfo.bp != null && this.personalinfo.bp != undefined) {
      this.clnt_bp = this.personalinfo.bp + " " + "mmHg";
      this.bp_flag = true;
    } else {
      // this.clnt_bp = "";
      this.bp_flag = false;
    }
    if (this.personalinfo.pulse != undefined && this.personalinfo.bp != null) {
      this.clnt_pulse = this.personalinfo.pulse;
      this.bp_flag = true;
    } else {
      this.bp_flag = false;
      this.clnt_pulse = "";
    }

    if (this.personalinfo.cvs != null) {
      this.cvsflag = true;
      this.clnt_cvs = this.personalinfo.cvs;
      this.ortho_flag = true;
    } else {
      //this.clnt_cvs = "";
      this.ortho_flag = false;
    }

    if (this.personalinfo.rs != null) {
      this.rsflag = true;
      this.clnt_rs = this.personalinfo.rs
      this.ortho_flag = true;
    } else {
      this.clnt_rs = "";
    }
    if (this.personalinfo.heart_rate != null) {
      this.heartrateflag = true;
      this.clnt_heartrate = this.personalinfo.heart_rate
      //  this.ortho_flag = true;
    } else {
      this.clnt_heartrate = "";
    }
    if (this.personalinfo.temparature != undefined && this.personalinfo.temparature != "") {
      this.temparature = this.personalinfo.temparature;
      this.temparature_flag = true;
    } else {
      this.temparature_flag = false;
    }
    if (this.personalinfo.pulse != undefined ) {
      this.clnt_pulse = this.personalinfo.pulse;
      this.pulseflag = true;
      //this.bp_flag = true;
    } else {
      this.bp_flag = false;
      this.pulseflag = false;
      this.clnt_pulse = "";
    }
    if (this.personalinfo.cns != null) {
      this.clnt_cns = this.personalinfo.cns;
      this.cnsflag = true;
    } else {
      //this.clnt_cvs = "";
      this.ortho_flag = false;
    }

    if (this.personalinfo.cvs != null) {
      this.clnt_cvs = this.personalinfo.cvs;
      this.cvsflag = true;
    } else {
      //this.clnt_cvs = "";
      this.ortho_flag = false;
    }

    if (this.personalinfo.rs != null) {
      this.rsflag = true;
      this.clnt_rs = this.personalinfo.rs
      this.ortho_flag = true;
    } else {
      this.clnt_rs = "";
    }

    if (this.personalinfo.rate != undefined) {
      this.clnt_heartrate = this.personalinfo.rate;
      this.heartrateflag = true
    }
    if (this.personalinfo.abd != undefined) {
      this.clnt_abd = this.personalinfo.abd;
      this.abdflag = true
    }
    if (this.personalinfo.spo2 != undefined) {
      this.clnt_spo2 = this.personalinfo.spo2;
      this.spoflag = true
    }
    // if (this.personalinfo.abd != undefined) {
    //   this.clnt_spo2 = this.personalinfo.abd;
    //   this.abdflag = true
    // }


    if (this.personalinfo.cbg != undefined) {
      this.clnt_cbg = this.personalinfo.cbg;
      this.cbgflag = true
    }

    if (this.personalinfo.rr != undefined) {
      this.clnt_rr = this.personalinfo.rr;
      this.rrflag = true
    }
    if (this.personalinfo.kidney != null) {
      this.clnt_kidney = this.personalinfo.kidney;
      this.kidney_flag = true;
      this.ortho_flag = true;
    } else {
      this.clnt_kidney = "";
    }
    if(this.personalinfo.blood_group != undefined){
      this.blood_group=this.personalinfo.blood_group;
    }else{
      this.blood_group="";
    }
    

    if (this.hospitalinfoarray.hptl_name != undefined && this.hospitalinfoarray.hptl_name != "undefined") {
      this.dct_hospital = this.hospitalinfoarray.hptl_name;
    }
    if (this.userinfo.hospitals[0].pres_print_template != undefined) {
      this.print_template = this.userinfo.hospitals[0].pres_print_template;
    } else {
      this.print_template = "noheader";
    }

    if (this.userinfo.hospitals[0].pres_print_logo != undefined && this.userinfo.hospitals[0].pres_print_logo != "undefined") {
      this.hptl_pres_logo = ipaddress.Ip_with_img_address + this.userinfo.hospitals[0].pres_print_logo;
      this.preslogo_style = this.userinfo.hospitals[0].pres_print_logo_style;
    }

    this.headerstyle = this.sanitizer.bypassSecurityTrustHtml(this.userinfo.hospitals[0].pres_print_heading);
    this.footerstyle = this.sanitizer.bypassSecurityTrustHtml(this.userinfo.hospitals[0].pres_print_footer);

    if (Doc_Helper.getHospital_bg_image() != undefined) {
      this.bg_image = Doc_Helper.getHospital_bg_image();
    }

    if (masterCSData_Helper.getMasterIntakeSession != undefined) {
      await this.masterCSdata.getIntakeSession();
      this.Get_Intake_session();
    } else {
      this.Get_Intake_session();
    }

    this.languageChange();
    this.hosp_address = this.home_care == "1" ? true : false;

    if (Doc_Helper.getAppFlow() == "Inpatient") {
      this.inpatBackFlag = true;
      this.addmed = this.personalinfo.addmed == "write" ? true : false;
    }

    this.listProducts = [];
    this.presListView = true;

    if (this.dur_flag == true && this.notes_flag == true) {
      this.med_style = "width: 275px;border: 1px solid black;border-collapse: collapse;";
      this.dosage_style = "width: 150px;border: 1px solid black;border-collapse: collapse;";
      this.dur_style = "width: 100px;border: 1px solid black;border-collapse: collapse;";
      this.freq_style = "width: 100px;border: 1px solid black;border-collapse: collapse;";

    } else if (this.dur_flag == false && this.notes_flag == true) {
      this.med_style = "width: 250px;border: 1px solid black;border-collapse: collapse;";
      this.dosage_style = "width: 125px;border: 1px solid black;border-collapse: collapse;";
      this.dur_style = "width: 75px;border: 1px solid black;border-collapse: collapse;";
      this.freq_style = "width: 75px;border: 1px solid black;border-collapse: collapse;";

    } else if (this.dur_flag == true && this.notes_flag == false) {
      this.med_style = "width: 200px;border: 1px solid black;border-collapse: collapse;";
      this.dosage_style = "width: 125px;border: 1px solid black;border-collapse: collapse;";
      this.dur_style = "width: 75px;border: 1px solid black;border-collapse: collapse;";
      this.freq_style = "width: 75px;border: 1px solid black;border-collapse: collapse;";

    } else {
      this.med_style = "width: 200px;border: 1px solid black;border-collapse: collapse;";
      this.dosage_style = "width: 100px;border: 1px solid black;border-collapse: collapse;";
      this.dur_style = "width: 50px;border: 1px solid black;border-collapse: collapse;";
      this.freq_style = "width:50px;border: 1px solid black;border-collapse: collapse;";
    }
    this.appGetdate()
   
  }

  getPrescriptionDetails(url) {
    console.log("PRES this.sendPresIdData " + JSON.stringify(this.sendPresIdData))
    FrontDesk_Helper.setfrontdeskprescriptiondetails(null)
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + url,
      JSON.stringify(
        this.sendPresIdData
      ),
      { headers: headers }).subscribe(
        data => {
          //this.prescriptionFlag = true;
          var obj = JSON.parse(data["_body"]);
          console.log("PRES DETAILS " + JSON.stringify(obj))
          if (obj != null && obj.length != 0) {
            if (obj.salutation != undefined) {
              this.doct_clientname = obj.salutation + " " + encrypt_decript.Decript(obj.cl_first_name) + " " + encrypt_decript.Decript(obj.cl_last_name);
            } else {
              this.doct_clientname = obj.salutation + " " + encrypt_decript.Decript(obj.cl_first_name) + " " + encrypt_decript.Decript(obj.cl_last_name);

            }
            if (obj.mobile != undefined) {
              this.mobile = encrypt_decript.Decript(obj.mobile)
            }

            if (obj.gender != undefined) {
              this.client_gender = encrypt_decript.Decript(obj.gender)
            }
            if (obj.age != undefined) {
              this.client_age = obj.age;
            }
            if (obj.pres_date != undefined) {
              var dateval = obj.pres_date.split("-");
              this.Appoint_Date = dateval[2] + "-" + dateval[1] + "-" + dateval[0];
            }
            this.language = obj.language;
            this.languageChange()
            this.investigation = obj.oberse_med;
            this.mobile = encrypt_decript.Decript(obj.mobile);

            if (obj.instructions != undefined && obj.instructions != "" && obj.instructions != null) {
              this.instructions = this.sanitizer.bypassSecurityTrustHtml(obj.instructions);
            } else {
              this.instructions = "";
            }

            if(obj.complaints != undefined && obj.complaints != '' && obj.complaints != null){
              this.complaints=obj.complaints
            }else{
              this.complaints=''
            }

            if (obj.bg_image != undefined) {
              FrontDesk_Helper.setbgimage(null)
              FrontDesk_Helper.setbgimage(obj.bg_image)
            }

            if (obj.pharmacy_id != null) // Suggested Pharmacy
            {
              this.pharmaId = obj.pharmacy_id
              this.prfer_pharmatxt = obj.pharmacy_name;
              if (obj.address1 != undefined) {
                this.prefer_add = obj.par_address1 + ", " + obj.par_address2 + ", " + obj.par_location + ", " + obj.par_city + " - " + obj.par_zipcode + ", " + obj.par_state + ", " + obj.par_country;
              }

              if (obj.par_telephone != null) {
                this.telephone = obj.par_telephone;
              }
            }

            this.dis_txt = obj.disease;
            
            if (obj.next_visit != undefined) {            
              this.next_txt=Date_Formate(obj.next_visit);
              this.next_datetxt=obj.next_visit;
              this.OnDateMatePicker(obj.next_visit)
            }
            
            for (var i = 0; i < obj.drug_list.length; i++) {
              if (obj.drug_list[i].show_short_form == "1") {
                console.log("if ****")
                if (obj.drug_list[i].show_intake == "0") {
                  this.every_six = obj.drug_list[i].morning;
                  this.param_four = true;
                } else {
                  var shortform = obj.drug_list[i].short_form;
                  if (obj.drug_list[i].intake == "1") {
                    this.every_six = obj.drug_list[i].morning+" "+ shortform + " - 0 - 0 - 0 ";
                    this.param_four = false;

                  } else if (obj.drug_list[i].intake == "2") {
                    this.every_six = "0" + obj.drug_list[i].afternoon +" "+ shortform + " - 0 " + " - 0 ";
                    this.param_four = false;

                  } else if (obj.drug_list[i].intake == "3") {
                    this.every_six = "0 - 0 - 0 " + obj.drug_list[i].night +" "+ shortform;
                    this.param_four = false;

                  } else if (obj.drug_list[i].intake == "4") {
                    this.every_six = obj.drug_list[i].morning +" "+ shortform + " - " + obj.drug_list[i].afternoon +" "+ shortform + " - 0 " + " - 0 ";
                    this.param_four = false;

                  } else if (obj.drug_list[i].intake == "5") {
                    this.every_six = obj.drug_list[i].morning +" "+ shortform + " - 0 - " + " - 0 - " + obj.drug_list[i].night +" "+ shortform;
                    this.param_four = false;

                  } else if (obj.drug_list[i].intake == "6") {
                    this.every_six = "0 - " + obj.drug_list[i].afternoon +" "+ shortform + " - " + obj.drug_list[i].evening +" "+ shortform + " - " + obj.drug_list[i].night +" "+ shortform;;
                    this.param_four = false;
                  } else if (obj.drug_list[i].intake == "7") {
                    this.every_six = obj.drug_list[i].morning +" "+ shortform + " - " + obj.drug_list[i].afternoon +" "+ shortform + " - " + obj.drug_list[i].evening +" "+ shortform + " - " + obj.drug_list[i].night +" "+ shortform;
                    this.param_four = false;
                  } else if (obj.drug_list[i].intake == "8") {
                    this.every_six = obj.drug_list[i].morning +" "+ shortform + " - " + obj.drug_list[i].afternoon +" "+ shortform + " - " + obj.drug_list[i].evening+" " + shortform + " - " + obj.drug_list[i].night+" " + shortform;
                    this.param_four = false;
                  } else if (obj.drug_list[i].intake == "9") {
                    this.every_six = obj.drug_list[i].morning+" " + shortform + " - Every 6 hours";
                    this.param_four = true;

                  } else if (obj.drug_list[i].intake == "10") {
                    this.every_six = obj.drug_list[i].morning+" " + shortform + " - Every 8 hours";
                    this.param_four = true;

                  } else if (obj.drug_list[i].intake == "11") {
                    this.every_six = obj.drug_list[i].morning+" " + shortform + " - Every 12 hours ";
                    this.param_four = true;

                  } else if (obj.drug_list[i].intake == "12") {
                    this.every_six = obj.drug_list[i].morning+" " + shortform + " - Once a day";
                    this.param_four = true;

                  } else if (obj.drug_list[i].intake == "13") {
                    this.every_six = obj.drug_list[i].morning +" "+ shortform + " SOS - if required";
                    this.param_four = true;

                  } else if (obj.drug_list[i].intake == "14") {
                    this.every_six = obj.drug_list[i].morning+" " + shortform + " - " + obj.drug_list[i].afternoon +" "+ shortform + " - " + obj.drug_list[i].evening+" " + shortform + " - " + obj.drug_list[i].night+" " + shortform + " Alternative days";
                    this.param_four = true;
                  } else if (obj.drug_list[i].intake == "15") {
                    this.param_four = true;
                    this.every_six = obj.drug_list[i].morning+" " + shortform + " - " + obj.drug_list[i].afternoon+" " + shortform + " - " + obj.drug_list[i].evening +" "+ shortform + " - " + obj.drug_list[i].night+" " + shortform + " Once a week";

                  } else if (obj.drug_list[i].intake == "16") {
                    this.every_six = obj.drug_list[i].morning+" " + shortform + " - " + obj.drug_list[i].afternoon +" "+ shortform + " - " + obj.drug_list[i].evening+" " + shortform + " - " + obj.drug_list[i].night +" "+ shortform + " Once in two weeks";
                    this.param_four = true;
                  } else if (obj.drug_list[i].intake == "17") {
                    this.every_six = obj.drug_list[i].morning+" " + shortform + " STAT";
                    this.param_four = true;
                  } else if (obj.drug_list[i].intake == "18") {
                    console.log("obj.drug_list[i].intakeobj.drug_list[i].intake" + obj.drug_list[i].intake + shortform)
                    this.every_six = obj.drug_list[i].morning +" "+ shortform + " Once a month";
                    this.param_four = true;
                    console.log("obj.drug_list[i].intakeobj.drug_list[i].intake" + obj.drug_list[i].intake+" " + shortform + this.every_six)

                  } else if (obj.drug_list[i].intake == "19") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;
                  } else if (obj.drug_list[i].intake == "20") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;
                  } else if (obj.drug_list[i].intake == "21") {
                    this.param_four=false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening  +  " - "  + obj.drug_list[i].night;
  
                  } else if (obj.drug_list[i].intake == "22") {
                    this.param_four=false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening  +  " - "  + obj.drug_list[i].night;
  
                  } else if (obj.drug_list[i].intake == "23") {
                    this.param_four=false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening  +  " - "  + obj.drug_list[i].night;
                  }

                  if (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != " ") {
                    this.dure_write = obj.drug_list[i].time_duration + " " + "mins";

                  } else {
                    if (obj.drug_list[i].intake != "17" && (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != " ")) {
                      this.dure_write = obj.drug_list[i].time_duration + " " + "mins ";
                    } else {
                      this.dure_write = "";
                      this.dure_flag = true;
                    }
                  }
                }

                if (this.afterfood_txt == undefined) {
                  this.afterfood_txt = "";
                }
                var frequency;
                if (obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks" || obj.drug_list[i].intake == "Twice a week" || obj.drug_list[i].intake == "Once a month") {
                  frequency = this.intake_txt;

                } else {
                  if (obj.drug_list[i].intake == "Stat" || obj.drug_list[i].intake == "SOS") {
                    frequency = " ";
                  } else {
                    frequency = "Daily";
                  }
                }
                
                var product: any = {
                  med_typetxt: obj.drug_list[i].drug_type_name,
                  short_name: obj.drug_list[i].short_name,
                  //  genericname:obj.drug_list[i].generic_name,
                  drug_id: obj.drug_list[i].drug_id,
                  // //genericname: this.ganericName,
                  drug_name: obj.drug_list[i].drug_name,
                  days: obj.drug_list[i].days,
                  period: obj.drug_list[i].period,
                  drug_intake: obj.drug_list[i].drug_intake,
                  intake: obj.drug_list[i].intake,
                  every_six: this.every_six,
                  dure_txt_table: this.dure_write,
                  time_duration: obj.drug_list[i].time_duration,
                  // morning: obj.drug_list[i].morning,
                  // afternoon: obj.drug_list[i].afternoon,
                  // evening: obj.drug_list[i].evening,
                  // night: obj.drug_list[i].night,
                  morning: obj.drug_list[i].morning,
                  afternoon: obj.drug_list[i].afternoon,
                  evening: obj.drug_list[i].evening,
                  night: obj.drug_list[i].night,
                  morning1: obj.drug_list[i].morning !== "0" && shortform ? `${obj.drug_list[i].morning} ${shortform}` : obj.drug_list[i].morning,
                  afternoon1: obj.drug_list[i].afternoon !== "0" && shortform ? `${obj.drug_list[i].afternoon} ${shortform}` : obj.drug_list[i].afternoon,
                  evening1: obj.drug_list[i].evening !== "0" && shortform ? `${obj.drug_list[i].evening} ${shortform}` : obj.drug_list[i].evening,
                  night1: obj.drug_list[i].night !== "0" && shortform ? `${obj.drug_list[i].night} ${shortform}` : obj.drug_list[i].night,
                  drug_type_id: obj.drug_list[i].drug_type_id,
                  frequency: frequency,
                  show_intake: obj.drug_list[i].show_intake,
                  remarks: obj.drug_list[i].remarks,
                  mixval: obj.drug_list[i].mixtype,
                  param_four: this.param_four,
                  default_quantity: obj.drug_list[i].default_quantity,
                  intake_mode:obj.drug_list[i].intake_mode
                }
                if (obj.drug_list[i].generic_name !== undefined) {
                  product.genericname = obj.drug_list[i].generic_name;
                }
                this.listProducts.push(product);

              } else {
                if (obj.drug_list[i].show_intake == "0") {
                  this.every_six = obj.drug_list[i].morning;
                  this.param_four = true;
                } else {
                  console.log("else ****")
                  if (obj.drug_list[i].intake == "1") {
                    this.every_six = obj.drug_list[i].morning + " - 0 - 0 - 0 ";
                    this.param_four = false;

                  } else if (obj.drug_list[i].intake == "2") {
                    this.every_six = "0 - " + obj.drug_list[i].afternoon + " - 0 - 0 ";
                    this.param_four = false;

                  } else if (obj.drug_list[i].intake == "3") {
                    this.every_six = "0 - 0 - 0 -" + obj.drug_list[i].night;
                    this.param_four = false;

                  } else if (obj.drug_list[i].intake == "4") {
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - 0 - 0 ";
                    this.param_four = false;

                  } else if (obj.drug_list[i].intake == "5") {
                    this.every_six = obj.drug_list[i].morning + " - 0  - 0 " + obj.drug_list[i].night;
                    this.param_four = false;

                  } else if (obj.drug_list[i].intake == "6") {
                    this.every_six = "0 - " + obj.drug_list[i].afternoon + "  - 0 " + obj.drug_list[i].night;
                    this.param_four = false;

                  } else if (obj.drug_list[i].intake == "7") {
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;
                    this.param_four = false;
                  } else if (obj.drug_list[i].intake == "8") {
                    this.param_four = false;

                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;

                  } else if (obj.drug_list[i].intake == "9") {
                    this.param_four = true;
                    this.every_six = obj.drug_list[i].morning + " - Every 6 hours";

                  } else if (obj.drug_list[i].intake == "10") {
                    this.param_four = true;
                    this.every_six = obj.drug_list[i].morning + " - Every 8 hours";

                  } else if (obj.drug_list[i].intake == "11") {
                    this.param_four = true;
                    this.every_six = obj.drug_list[i].morning + " - Every 12 hours";

                  } else if (obj.drug_list[i].intake == "12") {
                    this.param_four = true;
                    this.every_six = obj.drug_list[i].morning + " - Once a day";

                  } else if (obj.drug_list[i].intake == "13") {
                    this.param_four = true;
                    this.every_six = obj.drug_list[i].morning + " SOS - if required";

                  } else if (obj.drug_list[i].intake == "14") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;

                  } else if (obj.drug_list[i].intake == "15") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;

                  } else if (obj.drug_list[i].intake == "16") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;

                  } else if (obj.drug_list[i].intake == "17") {
                    this.param_four = true;
                    this.every_six = obj.drug_list[i].morning + " STAT";
                  }
                  else if (obj.drug_list[i].intake == "18") {
                    this.every_six = obj.drug_list[i].morning+" " + shortform + " - Once a month";
                    this.param_four = false;
                  }
                  else if (obj.drug_list[i].intake == "19") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;
                  }
                  else if (obj.drug_list[i].intake == "20") {
                    this.param_four = false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night;
                  }
                  else if (obj.drug_list[i].intake == "21") {
                    this.param_four=false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening  +  " - "  + obj.drug_list[i].night;
  
                  }
                  else if (obj.drug_list[i].intake == "22") {
                    this.param_four=false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening  +  " - "  + obj.drug_list[i].night;
  
                  }
                  else if (obj.drug_list[i].intake == "23") {
                    this.param_four=false;
                    this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening  +  " - "  + obj.drug_list[i].night;
  
                  }
                  if (obj.drug_list[i].intake != "17" && (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "")) {
                    this.dure_write = obj.drug_list[i].time_duration + " " + "mins";

                  } else {
                    if (obj.drug_list[i].intake != "17" && (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "")) {
                      this.dure_write = obj.drug_list[i].time_duration + " " + "mins ";
                    } else {
                      if (obj.drug_list[i].intake != "17" && (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != " ")) {
                        this.dure_write = obj.drug_list[i].time_duration + " " + "mins ";
                      } else {
                        this.dure_write = "";
                        this.dure_flag = true;
                      }
                    }
                  }
                }


                if (this.afterfood_txt == undefined) {
                  this.afterfood_txt = "";
                }
                var frequency;
                if (obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks" || obj.drug_list[i].intake == "Twice a week" || obj.drug_list[i].intake == "Once a month") {
                  frequency = this.intake_txt;

                } else {
                  if (obj.drug_list[i].intake == "Stat" || obj.drug_list[i].intake == "SOS") {
                    frequency = " ";
                  } else {
                    frequency = "Daily";
                  }

                }
                var product: any = {
                  med_typetxt: obj.drug_list[i].drug_type_name,
                  drug_id: obj.drug_list[i].drug_id,
                  drug_name: obj.drug_list[i].drug_name,
                  //  genericname:obj.drug_list[i].generic_name,
                  short_name: obj.drug_list[i].short_name,
                  days: obj.drug_list[i].days,
                  period: obj.drug_list[i].period,
                  drug_intake: obj.drug_list[i].drug_intake,
                  intake: obj.drug_list[i].intake,
                  every_six: this.every_six,
                  dure_txt_table: this.dure_write,
                  time_duration: obj.drug_list[i].time_duration,
                  // morning: obj.drug_list[i].morning,
                  // afternoon: obj.drug_list[i].afternoon,
                  // evening: obj.drug_list[i].evening,
                  // night: obj.drug_list[i].night,
                  morning: obj.drug_list[i].morning,
                  afternoon: obj.drug_list[i].afternoon,
                  evening: obj.drug_list[i].evening,
                  night: obj.drug_list[i].night,
                  morning1: obj.drug_list[i].morning !== "0" && shortform ? `${obj.drug_list[i].morning} ${shortform}` : obj.drug_list[i].morning,
                  afternoon1: obj.drug_list[i].afternoon !== "0" && shortform ? `${obj.drug_list[i].afternoon} ${shortform}` : obj.drug_list[i].afternoon,
                  evening1: obj.drug_list[i].evening !== "0" && shortform ? `${obj.drug_list[i].evening} ${shortform}` : obj.drug_list[i].evening,
                  night1: obj.drug_list[i].night !== "0" && shortform ? `${obj.drug_list[i].night} ${shortform}` : obj.drug_list[i].night,
                  drug_type_id: obj.drug_list[i].drug_type_id,
                  frequency: frequency,
                  show_intake: obj.drug_list[i].show_intake,
                  remarks: obj.drug_list[i].remarks,
                  param_four: this.param_four,
                  default_quantity:obj.drug_list[i].default_quantity,
                  intake_mode:obj.drug_list[i].intake_mode
                }
                if (obj.drug_list[i].generic_name !== undefined) {
                  product.genericname = obj.drug_list[i].generic_name;
                }
                this.listProducts.push(product);
                console.log("chec prodcurs ---" + JSON.stringify(this.listProducts))
              }

            }

            if (this.listProducts.length != 0) {
              this.Medicine_table = false;
              this.saveflag = true;
              this.printFlag = true;
              this.print_view_flag = false;
            }
            console.log("ddatty check--+JS" + JSON.stringify(this.listProducts))
          }
        },
        error => { });
  }

  backtoPreslist() {
    this.naviagationService.sendMessage('prescriptionlist')
  }

  getmedicineonit() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gmd',
      {
        hptl_clinic_id: this.hospital_clinic_id,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          var list = obj.drug_details;
          for (var i = 0; i < list.length; i++) {
            if (list[i].med_type_id != undefined) {
              this.mediceList.push({
                med_id: list[i].med_id,
                type_name: list[i].type_name,
                med_name: list[i].med_name,
                quantity: list[i].quantity,
                med_type_id: list[i].med_type_id,
                show_intake: list[i].show_intake,
                short_form: list[i].short_form,
                show_short_form: list[i].show_short_form,
              });
            }
          }
        },
        error => { });
  }

  appGetdate() {
    var obj = Master_Helper.getMasterCurrentDate();
    if (obj.current_date != null) {
      this.curr_date = obj.current_date;
      this.current_date = Date_Formate(obj.current_date);
      this.current_time = obj.current_time;
      this.current_year = obj.current_date.split('-')[0];
      this.printtime = Time_Formate(this.current_time)
      this.CurrentDatetime = obj.current_date;
      this.currentDate = obj.current_date;
      var presUrl = "prescription/mpdoc";
      this.getPrescriptionDetails(presUrl);
    }
  }

  getBase64Image(data) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/gb64/', {
      imagepath: data
    },
      { headers: headers })
      .subscribe(
        response => {
          //  JSON.parse(JSON.stringify(response));
          var data = response.json();
          this.imagestring = "data:image/jpeg;base64," + data.imagestr;
        });
  }

  languageChange() {
    if (this.language != "English") {
      this.printLanguageflag = true;
    } else {
      this.printLanguageflag = false;
    }
    if (this.language == "English") {
      this.translate.use('english');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")

    } else if (this.language == "Tamil") {
      this.translate.use('tamil');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")

      // this.mixtype.push("15ml தண்ணீருடன்")
      // this.mixtype.push("100ml தண்ணீருடன்")
      // this.mixtype.push("தயிருடன்")

    } else if (this.language == "Telugu") {
      this.translate.use('telugu');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")

    } else if (this.language == "Malayalam") {
      this.translate.use('malayalam');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")
    } else if (this.language == "Kannada") {
      this.translate.use('kannada');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")

    } else if (this.language == "Oriya") {
      this.translate.use('oriya');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")
    } else if (this.language == "Bengali") {
      this.translate.use('bangla');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")

    } else if (this.language == "Hindi") {
      this.translate.use('hindi');
      this.intakeOption = [];
      this.mixtype = [];
      this.intakeOption.push("Empty stomach");
      this.intakeOption.push("After food");
      this.intakeOption.push("Before food");
      this.intakeOption.push("With Food");

      this.mixtype.push("with 100 ml water")
      this.mixtype.push("with 15 ml water")
      this.mixtype.push("with curd")
    }
  }

  Get_Intake_session() {
    var obj = masterCSData_Helper.getMasterIntakeSession();
    if (obj != undefined){
      this.Get_Intake_array = obj.drug_intake;
      this.intake_txt = "Thrice a day";
      this.intakemodes = obj.intake_mode;
    }
      
  }

  getSugeryImage() {
    this.sugeryimg = Doc_Helper.getSugeryDetails().sugImageString;
  }

  Get_pharmacy_address() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/gfac',
      {
        hptl_clinic_id: this.hospital_clinic_id,
        type: "pharma"
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          this.prfer_pharmatxt = obj.hptl_name;
          if (obj.address1 != undefined) {
            this.prefer_add = obj.address1 + ", " + obj.location + ", " + obj.city + " - " + obj.zipcode + ", " + obj.state + ", " + obj.country;
          }
          //this.appmedpresList();
        },
        error => { });
  }

  getmedicine() {
    this.medicineFlag = !this.medicineFlag;
  }
  setMedicine(id) {
    this.medicineFlag = false;
    console.log("mediceList" + JSON.stringify(this.mediceList))
    for (var i = 0; i < this.mediceList.length; i++) {
      if (this.mediceList[i].med_id == id) {
        this.medicine_nametxt = this.mediceList[i].med_name;
        this.drug_typetxt = this.mediceList[i].med_type_id;
        this.medtypetxt = this.mediceList[i].type_name;
        this.show_intake = this.mediceList[i].show_intake;
        this.short_name = this.mediceList[i].type_name;
        this.med_id = this.mediceList[i].med_id;

        this.short_form = this.mediceList[i].short_form;
        this.show = this.mediceList[i].show_short_form;
        this.genericname = this.mediceList[i].genericname;
        if (this.genericname != undefined) {
          if (Helper_Class.getInfo().both_modules.length != 0) {
            var Moduleidlist
            Moduleidlist = Helper_Class.getInfo().both_modules;
            for (var i = 0; i < Moduleidlist.length; i++) {
              if (Moduleidlist[i].module_id == "34") {
                //this.medicalprescription = false;
                this.genericDisplayFlag = true;
              }
            }
          }

          this.ganericName = this.genericname
        }
        if (this.show_intake == "1" || this.show_intake == 1) {
          this.getIntakeSession();
          this.intakesession = false
        } else {
          this.intakesession = true;
          this.quan_lable = false;
          this.morning_dis = false;
          this.mor_id = true;
          this.after_id = true;
          this.eve_id = true;
          this.intake_show = true;
          this.dure_show = true;

          this.eve_eventxt = true;
          this.night_eventxt = true;
          this.daydur_show = true;

          this.mor_lable = true;
          this.after_eventxt = true;
          this.morning_req = true;
          this.morn_eventxt = false;
        }
        //this.getIntakeSession();
        break;
      }
    }
  }

  showMedType(new_med) {
    this.medtypetxt = "";
    this.new_med = new_med

    if (this.new_med == true) {
      this.newmed_flag = false;

    } else {
      this.newmed_flag = true;
    }
  }

  print_Viewprescription() {
    this.printtime = moment().format("h:mm A");
    this.print_area('');
  }

  change_type(id, value) {
    if (value == "yes") {
      this.ynopt1 = true;
      this.presType = "Rx";
      this.medpres = true;
      this.diagpres = false;
      Doc_Helper.setDiagAppflow("medical")

    } else {
      this.ynopt1 = false;
      this.presType = "Dx";
      this.medpres = false;
      this.diagpres = true;
      Doc_Helper.setDiagAppflow("diag")
    }
  }

  checkNextVisit() {
    if (this.next_txt == undefined && this.book_app_now == true) {
      this.toastr.error("Select next visit date");
    }
  }

  fillType(e) {
    this.fill_type = e;
    console.log("fill data" + JSON.stringify(this.fill_type))
    var send_data;
    var sub_rel_id = null;
    if (this.personalinfo.sub_id.length != 0) {
      sub_rel_id = this.personalinfo.sub_id;
    }

    var spl = Doc_Helper.getClient_Info().spl;

    if (this.fill_type == "refill") {
      send_data = {
        doc_reg_id: this.user_id,
        spl_id: spl,
        client_age: this.client_age,
        client_reg_id: this.personalinfo.Client_id,
        relation_id: this.personalinfo.rel_id,
        sub_rel_id: sub_rel_id
      }

      const dialogRef1 = this.dialog.open(MedPresPopupPageComponent, {
        width: '700px',
      });
      dialogRef1.afterClosed().subscribe(result => {
        if (result != null) {
          this.listProducts = result;
          this.genericnameflag = true;
          this.Medicine_table = false;
          this.drug_id_list = [];
          for (var i = 0; i < this.listProducts.length; i++) {
            this.drug_id_list.push(this.listProducts[i].drug_id);
          }
        }
      });
    } else if (this.fill_type == "autoRx") {
      this.getMedicineDetails();

    } else if (this.fill_type == "previous") {
      send_data = {
        client_id: this.personalinfo.Client_id,
        relation_id: this.personalinfo.rel_id,
        sub_rel_id: this.personalinfo.sub_id,
        spl_name: "General",
        doc_reg_id: this.user_id
      }
      const dialogRef2 = this.dialog.open(PreviousPrescriptionPopupComponent, {
        width: '900px',
      });

      dialogRef2.afterClosed().subscribe(result => {
        if (result != null) {
          this.listProducts = result;
          this.Medicine_table = false;
          this.drug_id_list = [];
          for (var i = 0; i < this.listProducts.length; i++) {
            this.drug_id_list.push(this.listProducts[i].drug_id);
          }
        }
        this.Medicine_table = false;
      });
    }
  }

  select_symptom(data) {
    this.newsymptoms.push(data);
    for (var j = 0; j < this.symptoms_list.length; j++) {
      if (this.symptoms_list[j].description == data) {
        this.selected_symptoms.push(data);
        break;
      }
    }

    if (this.dis_txt.indexOf(',') > -1) {
      var dataval = this.dis_txt.split(",");
      dataval.pop();
      this.dis_txt = dataval.join(",");

      this.dis_txt = this.dis_txt + "," + data;
    } else {
      this.dis_txt = data;
    }
    this.symptomlist = [];
  }

  onSelectionChanged = (event) => { }
  onContentChanged = (event) => {
    this.instructions = event.html;
    if (this.instructions != undefined) {
      this.print_instruction = this.sanitizer.bypassSecurityTrustHtml(this.instructions);
    }
  }

  getMedicineDetails() {
    var agerange;
    if (this.client_age == "<1") {
      agerange = "<1";
    } else if (this.client_age == "1" || this.client_age == "2" || this.client_age == "3") {
      agerange = "between 1 and 3 ";
    } else if (this.client_age == "4" || this.client_age == "5") {
      agerange = "between 4 and 5 ";
    } else if (this.client_age == "6" || this.client_age == "7") {
      agerange = "between 6 and 7 ";
    } else if (this.client_age == "8" || this.client_age == "9" || this.client_age == "10") {
      agerange = "between 8 and 10 ";
    } else if (this.client_age == "11" || this.client_age == "12" || this.client_age == "13") {
      agerange = "between 11 and 13 ";
    } else if (this.client_age == "14" || this.client_age == "15") {
      agerange = "between 14 and 15 ";
    } else if (this.client_age == "16" || this.client_age == "17" || this.client_age == "18") {
      agerange = "between 16 and 18 ";
    } else if (this.client_age == "19" || this.client_age == "20" || this.client_age == "21") {
      agerange = "between 19 and 21 ";
    } else {
      agerange = "between 21 and 120 ";
    }

    var temp;
    if (Helper_Class.getTemparature() != undefined || Helper_Class.getTemparature() != null) {
      temp = Helper_Class.getTemparature();
    }

    var bp;
    if (this.clnt_bp != null && this.clnt_bp != "" && this.clnt_bp != undefined) {
      bp = this.clnt_bp;
    }

    var cgender;
    if (this.client_age != undefined && parseInt(this.client_age) > 10) {
      if (this.client_gender != undefined) {
        cgender = this.personalinfo.Gender_data;
      }
    }

    this.symptoms = Helper_Class.getsymptoms();
    if (this.symptoms != undefined) {
      var sym = this.symptoms;
    }
    if (temp != undefined) {
      var temp_value = temp
    }

    var genflag = true;
    if (cgender != undefined && (encrypt_decript.Decript(cgender) == "Male" && this.personalinfo.spl == "Gynecology")) {
      genflag = false;
    } else {
      genflag = true
    }

    var gender_val = encrypt_decript.Encript(cgender).toString()

    //  /  if(this.cgender !=null)
    var age = this.client_age != undefined && this.client_age != "" ? this.client_age : undefined;
    var ageRange = this.client_age != undefined && this.client_age != "" ? agerange : undefined;

    this.symptoms = this.getdata_value;
    if (genflag == true) {
      var send_data = {
        relation_id: this.personalinfo.rel_id,
        spl_name: this.personalinfo.spl,
        agerange: ageRange,
        gender: gender_val,
        symptoms: this.symptoms,
        bp: bp,
        temparature: temp,
        diagnosis: this.dis_txt,
        clntage: age,
      }
      console.log("genflag" + genflag)
      console.log("send_data send_data" + JSON.stringify(send_data))
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'prescription/gmpbycasv/', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            this.listProducts = [];
            if (obj != undefined && obj.pres_drug_id != undefined) {
              this.dis_txt = obj.disease;
              if (obj.drug_list.length != 0) {
                console.log("obj.drug_list check 00---->" + JSON.stringify(obj.drug_list))
                for (var i = 0; i < obj.drug_list.length; i++) {
                  if (obj.drug_list[i].show_short_form == "1") {
                    var shortform = obj.drug_list[i].short_form;
                    this.short_form = obj.drug_list[i].short_form;
                    var morning = " ", afternoon = " ", evening = " ", night = " "
                    if (obj.drug_list[i].intake == "1") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                      // this.every_six =  obj.drug_list[i].morning + shortform  + " - " + obj.drug_list[i].afternoon+ " - "  + obj.drug_list[i].evening  + " - 0";
                    } else if (obj.drug_list[i].intake == "2") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "3") {
                      this.param_four = false;
                      this.every_six = obj.drug_list[i].morning + " - " + obj.drug_list[i].afternoon + " - " + obj.drug_list[i].evening + " - " + obj.drug_list[i].night +" " + shortform;

                      // this.every_six = "0 - 0 - "+ obj.drug_list[i].evening + shortform  + obj.drug_list[i].night + shortform;

                    } else if (obj.drug_list[i].intake == "4") {
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night; this.param_four = false;

                    } else if (obj.drug_list[i].intake == "5") {
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                      this.param_four = false;

                    } else if (obj.drug_list[i].intake == "6") {
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night; this.param_four = false;

                    } else if (obj.drug_list[i].intake == "7") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "8") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "9") {
                      this.param_four = true;
                      this.every_six = obj.drug_list[i].morning+" "  + shortform + " - Every 6 hours";

                    } else if (obj.drug_list[i].intake == "10") {
                      this.param_four = true;
                      this.every_six = obj.drug_list[i].morning +" " + shortform + " - Every 8 hours";

                    } else if (obj.drug_list[i].intake == "11") {
                      this.param_four = true;
                      this.every_six = obj.drug_list[i].morning+" "  + shortform + " - Every 12 hours ";

                    } else if (obj.drug_list[i].intake == "12") {
                      this.every_six = obj.drug_list[i].morning+" "  + shortform + " - Once a day";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "13") {
                      this.every_six = obj.drug_list[i].morning +" " + shortform + " SOS - if required";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "14") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "15") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "16") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "17") {
                      this.param_four = true;
                      this.every_six = obj.drug_list[i].morning+" "  + shortform + " STAT";
                    }
                    else if (obj.drug_list[i].intake == "18") {
                      this.param_four = true;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    }
                    else if (obj.drug_list[i].intake == "19") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    }
                    else if (obj.drug_list[i].intake == "20") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " " + this.short_form + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    }


                    if (obj.drug_list[i].intake != "17" && (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "")) {
                      this.dure_write = obj.drug_list[i].time_duration + " " + "mins ";
                    } else {
                      if (obj.drug_list[i].intake != "17" && (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != " ")) {
                        this.dure_write = obj.drug_list[i].time_duration + " " + "mins ";
                      } else {
                        this.dure_write = "";
                        this.dure_flag = true;
                      }
                    }

                    if (this.afterfood_txt == undefined) {
                      this.afterfood_txt = "";
                    }
                    var frequency;
                    if (obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks" || obj.drug_list[i].intake == "Twice a week" || obj.drug_list[i].intake == "Once a month") {
                      frequency = this.intake_txt;
                    } else {
                      if (obj.drug_list[i].days == "1" && obj.drug_list[i].day_dur == "day") {
                        frequency = "";
                      } if (obj.drug_list[i].intake == "Stat" || obj.drug_list[i].intake == "SOS") {
                        frequency = " ";
                      } else {
                        frequency = "Daily";
                      }
                    }
                    if (obj.drug_list[i].generic_name !== undefined) {
                      var genericname = obj.drug_list[i].generic_name;
                    }
                    var product: any = {
                      med_typetxt: obj.drug_list[i].drug_type_name,
                      drug_id: obj.drug_list[i].drug_id,
                      drug_name: obj.drug_list[i].drug_name,
                      // //genericname: this.ganericName,
                      genericname: genericname,
                      days: obj.drug_list[i].days,
                      period: obj.drug_list[i].day_dur,
                      drug_intake: obj.drug_list[i].drug_intake,
                      intake: obj.drug_list[i].intake,
                      every_six: this.every_six,
                      dure_txt_table: this.dure_write,
                      time_duration: obj.drug_list[i].time_duration,
                      morning: obj.drug_list[i].morning,
                      afternoon: obj.drug_list[i].afternoon,
                      evening: obj.drug_list[i].evening,
                      night: obj.drug_list[i].night,
                      morning1: obj.drug_list[i].morning !== "0" && shortform ? `${obj.drug_list[i].morning} ${shortform}` : obj.drug_list[i].morning,
                      afternoon1: obj.drug_list[i].afternoon !== "0" && shortform ? `${obj.drug_list[i].afternoon} ${shortform}` : obj.drug_list[i].afternoon,
                      evening1: obj.drug_list[i].evening !== "0" && shortform ? `${obj.drug_list[i].evening} ${shortform}` : obj.drug_list[i].evening,
                      night1: obj.drug_list[i].night !== "0" && shortform ? `${obj.drug_list[i].night} ${shortform}` : obj.drug_list[i].night,
                     
                      drug_type_id: obj.drug_list[i].drug_type_id,
                      frequency: frequency,
                      show_intake: obj.drug_list[i].show_intake,
                      short_name: obj.drug_list[i].short_name,
                      mixval: obj.drug_list[i].mixtype,
                      param_four: this.param_four,
                      default_quantity:obj.drug_list[i].default_quantity,
                      intake_mode:obj.drug_list[i].intake_mode
                    }

                    if (obj.drug_list[i].generic_name !== undefined) {
                      product.genericname = obj.drug_list[i].generic_name;
                    }
                    this.listProducts.push(product);
                  } else {
                    if (obj.drug_list[i].intake == "1") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "2") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "3") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "4") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "5") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "6") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "7") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "8") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "9") {
                      this.every_six = obj.drug_list[i].morning + " - Every 6 hours";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "10") {
                      this.every_six = obj.drug_list[i].morning + " - Every 8 hours";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "11") {
                      this.every_six = obj.drug_list[i].morning + " - Every 12 hours";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "12") {
                      this.every_six = obj.drug_list[i].morning + " - Once a day";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "13") {
                      this.every_six = obj.drug_list[i].morning + " SOS - if required";
                      this.param_four = true;

                    } else if (obj.drug_list[i].intake == "14") {
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night; this.param_four = false;
                    } else if (obj.drug_list[i].intake == "15") {
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night; this.param_four = false;
                    } else if (obj.drug_list[i].intake == "16") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    } else if (obj.drug_list[i].intake == "17") {
                      this.param_four = true;
                      this.every_six = obj.drug_list[i].morning + " STAT";
                    }
                    else if (obj.drug_list[i].intake == "18") {
                      this.param_four = false;
                      this.every_six = obj.drug_list[i].morning + " Once a month";
                    }
                    else if (obj.drug_list[i].intake == "19") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    }
                    else if (obj.drug_list[i].intake == "20") {
                      this.param_four = false;
                      if (obj.drug_list[i].morning !== "0") {
                        morning = obj.drug_list[i].morning + " " + this.short_form + " - ";
                      } else {
                        morning = obj.drug_list[i].morning + " - ";
                      }
                      if (obj.drug_list[i].afternoon !== "0") {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      } else {
                        afternoon = obj.drug_list[i].afternoon + " - ";
                      }
                      if (obj.drug_list[i].evening !== "0") {
                        evening = obj.drug_list[i].evening + " " + this.short_form + " - ";
                      } else {
                        evening = obj.drug_list[i].evening + " - ";
                      }
                      if (obj.drug_list[i].night !== "0") {
                        night = obj.drug_list[i].night + " " + this.short_form;
                      } else {
                        night = obj.drug_list[i].night;
                      }

                      this.every_six = morning + afternoon + evening + night;
                    }


                    else {
                      if (obj.drug_list[i].evening == 0 && obj.drug_list[i].night != 0) {
                        if (obj.drug_list[i].morning !== "0") {
                          morning = obj.drug_list[i].morning + " - ";
                        } else {
                          morning = obj.drug_list[i].morning + " - ";
                        }
                        if (obj.drug_list[i].afternoon !== "0") {
                          afternoon = obj.drug_list[i].afternoon + " - ";
                        } else {
                          afternoon = obj.drug_list[i].afternoon + " - ";
                        }
                        if (obj.drug_list[i].evening !== "0") {
                          evening = obj.drug_list[i].evening + " - ";
                        } else {
                          evening = obj.drug_list[i].evening + " - ";
                        }
                        if (obj.drug_list[i].night !== "0") {
                          night = obj.drug_list[i].night;
                        } else {
                          night = obj.drug_list[i].night;
                        }

                        this.every_six = morning + afternoon + evening + night;
                      } else if (obj.drug_list[i].evening != 0 && obj.drug_list[i].night == 0) {
                        if (obj.drug_list[i].morning !== "0") {
                          morning = obj.drug_list[i].morning + " - ";
                        } else {
                          morning = obj.drug_list[i].morning + " - ";
                        }
                        if (obj.drug_list[i].afternoon !== "0") {
                          afternoon = obj.drug_list[i].afternoon + " - ";
                        } else {
                          afternoon = obj.drug_list[i].afternoon + " - ";
                        }
                        if (obj.drug_list[i].evening !== "0") {
                          evening = obj.drug_list[i].evening + " - ";
                        } else {
                          evening = obj.drug_list[i].evening + " - ";
                        }
                        if (obj.drug_list[i].night !== "0") {
                          night = obj.drug_list[i].night_eventxt;
                        } else {
                          night = obj.drug_list[i].night;
                        }

                        this.every_six = morning + afternoon + evening + night;
                      }
                      this.param_four = false;
                    }

                    if (obj.drug_list[i].intake != "17" && (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != "")) {
                      this.dure_write = obj.drug_list[i].time_duration + " " + "mins ";
                    } else {
                      if (obj.drug_list[i].intake != "17" && (obj.drug_list[i].time_duration != undefined && obj.drug_list[i].time_duration != " ")) {
                        this.dure_write = obj.drug_list[i].time_duration + " " + "mins ";
                      } else {
                        this.dure_write = "";
                        this.dure_flag = true;
                      }
                    }
                    if (this.afterfood_txt == undefined) {
                      this.afterfood_txt = "";
                    }
                    if (obj.drug_list[i].generic_name !== undefined) {
                      var genericname = obj.drug_list[i].generic_name;
                    }
                    var frequency;
                    frequency = obj.drug_list[i].intake == "Alternative day" || obj.drug_list[i].intake == "Once a week" || obj.drug_list[i].intake == "Once in two weeks" ? this.intake_txt : "Daily";
                    var product: any = {
                      med_typetxt: obj.drug_list[i].drug_type_name,
                      drug_id: obj.drug_list[i].drug_id,
                      genericname: genericname,
                      drug_name: obj.drug_list[i].drug_name,
                      days: obj.drug_list[i].days,
                      period: obj.drug_list[i].day_dur,
                      drug_intake: obj.drug_list[i].drug_intake,
                      intake: obj.drug_list[i].intake,
                      every_six: this.every_six,
                      dure_txt_table: this.dure_write,
                      time_duration: obj.drug_list[i].time_duration,
                      morning: obj.drug_list[i].morning,
                      afternoon: obj.drug_list[i].afternoon,
                      evening: obj.drug_list[i].evening,
                      night: obj.drug_list[i].night,
                      morning1: obj.drug_list[i].morning !== "0" && shortform ? `${obj.drug_list[i].morning} ${shortform}` : obj.drug_list[i].morning,
                      afternoon1: obj.drug_list[i].afternoon !== "0" && shortform ? `${obj.drug_list[i].afternoon} ${shortform}` : obj.drug_list[i].afternoon,
                      evening1: obj.drug_list[i].evening !== "0" && shortform ? `${obj.drug_list[i].evening} ${shortform}` : obj.drug_list[i].evening,
                      night1: obj.drug_list[i].night !== "0" && shortform ? `${obj.drug_list[i].night} ${shortform}` : obj.drug_list[i].night,
                      // night: obj.drug_list[i].night,
                      drug_type_id: obj.drug_list[i].drug_type_id,
                      frequency: frequency,
                      show_intake: obj.drug_list[i].show_intake,
                      remarks: obj.drug_list[i].remarks,
                      short_name: obj.drug_list[i].short_name,
                      param_four: this.param_four,
                      default_quantity: obj.drug_list[i].default_quantity,
                      intake_mode:obj.drug_list[i].intake_mode
                    }
                    if (obj.drug_list[i].generic_name !== undefined) {
                      product.genericname = obj.drug_list[i].generic_name;
                    }
                    this.listProducts.push(product);
                    console.log("cj check 00---->" + JSON.stringify(this.listProducts))
                  }
                }
                this.Medicine_table = false;
              }
            }
          });
    }
  }

  Pharma_medicine_name(item) {
    this.prfer_pharmatxt = item;

    if (item == this.Pharmanamelist) {
      for (var i = 0; i < this.Pharma_list_arry.length; i++) {
        this.pharma_id = this.Pharma_list_arry[i].pharma_id;
        if (this.Pharma_list_arry[i].telephone != undefined && this.Pharma_list_arry[i].telephone != 'undefined') {
          this.prefer_add = this.Pharma_list_arry[i].address1 + ", " + this.Pharma_list_arry[i].location + ", " + this.Pharma_list_arry[i].city + " - " + this.Pharma_list_arry[i].zipcode + ", " + this.Pharma_list_arry[i].state + ", " + this.Pharma_list_arry[i].country + " , " + this.Pharma_list_arry[i].telephone;
        } else {
          this.prefer_add = this.Pharma_list_arry[i].address1 + ", " + this.Pharma_list_arry[i].location + ", " + this.Pharma_list_arry[i].city + " - " + this.Pharma_list_arry[i].zipcode + ", " + this.Pharma_list_arry[i].state + ", " + this.Pharma_list_arry[i].country;
        }
      }
      this.Pharmanamelist = [];
    }
  }

  Prefer_pharmacy_change(e) {
    this.Pharmanamelist = [];
    this.Pharma_data = [];
    this.prfer_pharmatxt = e.target.value.toString();
    if (this.prfer_pharmatxt != undefined && this.prfer_pharmatxt.length >= 3) {
      this.prefer_add = "";

      for (var i = 0; i < this.Medical_prescription_array.length; i++) {
        if (this.Medical_prescription_array[i].med_name == this.med_typetxt) {
          this.drug_typetxt = this.Medical_prescription_array[i].med_code;
        }
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/pharmabyname/',
        {
          location: this.doct_location,
          pharma_name: this.prfer_pharmatxt,
          drug_ids: this.drug_id_list,
          flag: "prescription",
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();

            if (obj.pharma_list != null) {
              this.Pharma_list_arry = obj.pharma_list;
              for (var i = 0; i < obj.pharma_list.length; i++) {
                this.Pharma_data.push(obj.pharma_list[i].pharmacy_name);
                this.pharma_id = obj.pharma_list[i].pharma_id;
              }

              Doc_Helper.setpharmaid(this.pharma_id)
              this.Pharmanamelist = this.Pharma_data.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.prfer_pharmatxt.toLowerCase()) > -1;
              }.bind(this));
            } else {
              this.toastr.error(Message_data.noPharmaFound);
            }
          },
          error => { }
        )
    }
  }

  Duration() {
    var duration = this.day_txt + " " + this.daydur_txt;
    return duration
  }

  OnDateMatePicker(e) {
    this.next_datetxt = e;
    this.next_txt = Date_Formate(e);
   // this.next_datetxt_diag = e;
    var check1 = moment(this.CurrentDatetime, 'YYYY-MM-DD')
    var check2 = moment(this.next_datetxt, 'YYYY-MM-DD')
    this.no_of_days = check2.diff(check1, 'days');
    this.next_txt = e;
    // alert("this.no_of_days"+check2+check1)
    // alert("this.no_of_days"+this.no_of_days)
    //this.no_of_days_diag = (check2.diff(check1, 'days'));
  }

  Medicine_name_change(e, type) {
    if (this.new_med != true) {
      if (e.target.value.toString() != null && e.target.value.length.toString() > 2) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/',
          {
            drug_category: this.user_id,
            drug_name: e.target.value.toString(),
            type: type,
            // pharmacy_id: this.pharma_id,
          },
          { headers: headers })
          .subscribe(
            response => {
              var obj = response.json();
              console.log("med_data = "+JSON.stringify(obj))
              console.log("send data ="+JSON.stringify({
              drug_category: this.user_id,
              drug_name: e.target.value.toString(),
              type: type,
              }))

              if (obj.med_details != null) {
                this.Medicinenamelist = [];
                this.Medicine_list = [];
                this.Medicne_data = [];

                for (var i = 0; i < obj.med_details.length; i++) {
                  var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name
                  this.Medicne_data.push({ name: medname, generic: obj.med_details[i].generic_name, quantity: obj.med_details[i].quantity, drug_id: obj.med_details[i].med_id });
                  this.Medicine_list.push({
                    med_id: obj.med_details[i].med_id,
                    med_name: obj.med_details[i].med_name,
                    med_type_id: obj.med_details[i].med_type_id,
                    type_name: obj.med_details[i].type_name,
                    short_name: obj.med_details[i].short_name,
                    short_form: obj.med_details[i].short_form,
                    show_short_form: obj.med_details[i].show_short_form,
                    show_intake: obj.med_details[i].show_intake,
                    medname: medname
                  })
                }

                this.Medicinenamelist = this.Medicne_data.filter(function (this: any, el) {
                  return el.toLowerCase().indexOf(this.medicine_nametxt.toLowerCase()) > -1;
                }.bind(this));

                this.Med_addbtn = true;
              }
            },
            error => {

            }
          )
      } else {
        this.Medicinenamelist = [];
      }

    } else {
      if (this.medtypetxt == "" || this.medtypetxt == undefined) {
        this.toastr.error(Message_data.enterMedType);
      }
      this.med_id = "0";
      this.Med_addbtn = true;
    }
  }

  select_medicine_item(item) {
    console.log("check item --" + JSON.stringify(item))
    console.log("this.viewpreslist_array-" + JSON.stringify(this.viewpreslist_array))
    console.log("viewpreslist_array" + JSON.stringify(this.viewpreslist_array))
    if (this.personalinfo.AppFlow == "Inpatient" || this.personalinfo.AppFlow == "InpatMedPres") {
      if (this.intake_txt != "SOS") {
        var matchingPrescription = this.viewpreslist_array.find((prescription) => {
          return prescription.medicines.some((medicine) => {
            return medicine.drug_id === item.drug_id.toString();
          });
        });
      }
      else {
        for (var i = 0; i < this.Medicine_list.length; i++) {
          if (this.Medicine_list[i].medname == item.name) {
            this.medicine_nametxt = this.Medicine_list[i].med_name;
            this.drug_typetxt = this.Medicine_list[i].med_type_id;
            this.medtypetxt = this.Medicine_list[i].type_name;
            this.ganericName = item.generic;
            this.short_form = this.Medicine_list[i].short_form;
            this.show = this.Medicine_list[i].show_short_form;
            this.show_intake = this.Medicine_list[i].show_intake;
            this.med_id = this.Medicine_list[i].med_id;
            this.short_name = this.Medicine_list[i].short_name;
            if ((parseInt(this.drug_typetxt) == 5) || (parseInt(this.drug_typetxt) == 14) || (parseInt(this.drug_typetxt) == 75)) {
              this.mix_show = false;
            } else {
              this.mix_show = true;
            }
          }
        }
        // this.sesssion_basechange();
        if (this.show_intake == "1" || this.show_intake == 1) {
          this.getIntakeSession();
          this.intakesession = false
        } else {
          this.intakesession = true;
          this.quan_lable = false;
          this.morning_dis = false;
          this.mor_id = true;
          this.after_id = true;
          this.eve_id = true;
          this.intake_show = true;
          this.dure_show = true;

          this.eve_eventxt = true;
          this.night_eventxt = true;
          this.daydur_show = true;

          this.mor_lable = true;
          this.after_eventxt = true;
          this.morning_req = true;
          this.morn_eventxt = false;
        }
        this.Medicne_data = [];
        this.Medicinenamelist = [];
      }
      console.log("viewpreslist_array" + JSON.stringify(matchingPrescription))


      if (matchingPrescription) {
        this.toastr.error("Already prescriped medicine");
        this.medicine_nametxt = ""
        item = ""
        console.log("Matching Prescription found:", matchingPrescription);
      } else {
        for (var i = 0; i < this.Medicine_list.length; i++) {
          if (this.Medicine_list[i].medname == item.name) {
            this.medicine_nametxt = this.Medicine_list[i].med_name;
            this.drug_typetxt = this.Medicine_list[i].med_type_id;
            this.medtypetxt = this.Medicine_list[i].type_name;
            this.ganericName = item.generic;
            this.short_form = this.Medicine_list[i].short_form;
            this.show = this.Medicine_list[i].show_short_form;
            this.show_intake = this.Medicine_list[i].show_intake;
            this.med_id = this.Medicine_list[i].med_id;
            this.short_name = this.Medicine_list[i].short_name;
            if ((parseInt(this.drug_typetxt) == 5) || (parseInt(this.drug_typetxt) == 14) || (parseInt(this.drug_typetxt) == 75)) {
              this.mix_show = false;
            } else {
              this.mix_show = true;
            }
          }
        }
        // this.sesssion_basechange();
        if (this.show_intake == "1" || this.show_intake == 1) {
          this.getIntakeSession();
          this.intakesession = false
        } else {
          this.intakesession = true;
          this.quan_lable = false;
          this.morning_dis = false;
          this.mor_id = true;
          this.after_id = true;
          this.eve_id = true;
          this.intake_show = true;
          this.dure_show = true;

          this.eve_eventxt = true;
          this.night_eventxt = true;
          this.daydur_show = true;

          this.mor_lable = true;
          this.after_eventxt = true;
          this.morning_req = true;
          this.morn_eventxt = false;
        }

        this.Medicne_data = [];
        this.Medicinenamelist = [];
      }

    } else {
      for (var i = 0; i < this.Medicine_list.length; i++) {
        if (this.Medicine_list[i].medname == item.name) {
          this.medicine_nametxt = this.Medicine_list[i].med_name;
          this.drug_typetxt = this.Medicine_list[i].med_type_id;
          this.medtypetxt = this.Medicine_list[i].type_name;
          this.ganericName = item.generic;
          this.short_form = this.Medicine_list[i].short_form;
          this.show = this.Medicine_list[i].show_short_form;
          this.show_intake = this.Medicine_list[i].show_intake;
          this.med_id = this.Medicine_list[i].med_id;
          this.short_name = this.Medicine_list[i].short_name;
          if ((parseInt(this.drug_typetxt) == 5) || (parseInt(this.drug_typetxt) == 14) || (parseInt(this.drug_typetxt) == 75)) {
            this.mix_show = false;
          } else {
            this.mix_show = true;
          }
        }
      }
      // this.sesssion_basechange();
      if (this.show_intake == "1" || this.show_intake == 1) {
        this.getIntakeSession();
        this.intakesession = false
      } else {
        this.intakesession = true;
        this.quan_lable = false;
        this.morning_dis = false;
        this.mor_id = true;
        this.after_id = true;
        this.eve_id = true;
        this.intake_show = true;
        this.dure_show = true;

        this.eve_eventxt = true;
        this.night_eventxt = true;
        this.daydur_show = true;

        this.mor_lable = true;
        this.after_eventxt = true;
        this.morning_req = true;
        this.morn_eventxt = false;
      }
      this.Medicne_data = [];
      this.Medicinenamelist = [];
    }
    this.medicinecolorarray = this.medicinecolorarray || [];

    let lookAlikeColor = item.look_alike !== "0" && item.look_alike !== undefined ? this.hospitalinfoarray[0].look_alike_color : '';
    let soundAlikeColor = item.sound_alike !== "0" && item.sound_alike !== undefined ? this.hospitalinfoarray[0].sound_alike_color : '';
    let highRiskColor = item.high_risk !== "0" && item.high_risk !== undefined ? this.hospitalinfoarray[0].high_risk_color : '';
    let wardOnlyColor = item.ward_only !== "0" && item.ward_only !== undefined ? this.hospitalinfoarray[0].ward_only_color : '';

    if (item.look_alike !== "0" && item.look_alike !== undefined) {
      this.medicinecolorarray.push({ type: 'look_alike', color: lookAlikeColor });
    }
    if (item.sound_alike !== "0" && item.sound_alike !== undefined) {
      this.medicinecolorarray.push({ type: 'sound_alike', color: soundAlikeColor });
    }
    if (item.high_risk !== "0" && item.high_risk !== undefined) {
      this.medicinecolorarray.push({ type: 'high_risk', color: highRiskColor });
    }
    if (item.ward_only !== "0" && item.ward_only !== undefined) {
      this.medicinecolorarray.push({ type: 'ward_only', color: wardOnlyColor });
    }
    this.Medicne_data = [];
    this.Medicinenamelist = [];
    this.Medicne_data = [];
    this.Medicinenamelist = [];
    console.log("cjeckmjnjk" + this.show_intake + "this.showthis.showthis.show" + this.show)

  }

  getIntakeSession() {
    var send_data = {
      product_id: this.med_id
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gis', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          for (var i = 0; i < this.Get_Intake_array.length; i++) {
            if (obj.intake == this.Get_Intake_array[i].drug_int_id) {
              this.intake_txt = this.Get_Intake_array[i].description
              this.intake_id = this.Get_Intake_array[i].drug_int_id;
            }
          }
          this.sesssion_basechange();

        })
  }

  Delete_medicalpres(medtype, medname) {
    for (var i = 0; i < this.listProducts.length; i++) {
      if (this.listProducts[i].med_typetxt == medtype && this.listProducts[i].drug_name == medname) {
        this.listProducts.splice(i, 1);
        break;
      }
    }
    this.highRiskPresent = this.listProducts.some(drug => drug.highrisk === "1");

    if (this.listProducts.length != 0) {
      this.Medicine_table = false;
      this.print_medidiag = true;
    }
    else {
      this.Medicine_table = true;
      this.print_medidiag = false;
      this.saveflag = false;
      this.printFlag = false;
      this.print_view_flag = true;
    }
  }


  editMedicalPres(productid, rowid, ganericName, intake) {
    this.drug_typetxt = "";
    console.log("this.productid" + JSON.stringify(productid))
    console.log("this.rowidrowidrowid" + JSON.stringify(rowid))
    // console.log("this.rowidrowidrowid drugname" + JSON.stringify(intake)+drugname)
    // console.log("this.line item" + JSON.stringify(product))
    console.log("this.Get_Intake_array" + JSON.stringify(this.Get_Intake_array))

    for (var i = 0; i < this.listProducts.length; i++) {
      if (this.listProducts[i].drug_id == productid && this.listProducts[i].rowid == rowid && this.listProducts[i].intake == intake) {
        this.rowid = rowid;
        console.log("this.productid" + JSON.stringify(this.listProducts))
        this.show_intake = this.listProducts[i].show_intake;
        this.short_name = this.listProducts[i].short_name;//show_intake
        this.medtypetxt = this.listProducts[i].med_typetxt;
        this.medicine_nametxt = this.listProducts[i].drug_name;
        this.intake_mode=this.listProducts[i].intake_mode;

        if (this.listProducts[i].genericname != undefined) {
          this.ganericName = this.listProducts[i].genericname
        }
        this.med_id = this.listProducts[i].drug_id;
        //  var dayvale = this.listProducts[i].days.split(' ');
        console.log("this.productid days" + JSON.stringify(this.listProducts[i].days))
        this.day_txt = this.listProducts[i].days;
        if (this.listProducts[i].period != undefined) {
          if (this.listProducts[i].period == "day" || this.listProducts[i].period == "days") {
            this.daydur_txt = "day(s)";

          } else if (this.listProducts[i].period == "week" || this.listProducts[i].period == "weeks") {
            this.daydur_txt = "week(s)";

          } else if (this.listProducts[i].period == "month" || this.listProducts[i].period == "month") {
            this.daydur_txt = "month(s)";

          } else {
            this.daydur_txt = "day(s)";
          }
        } else {
          this.daydur_txt = "";
        }

        //dure_txt_table
        this.afterfood_txt = this.listProducts[i].drug_intake;
        if (this.listProducts[i].dure_txt_table != "") {
          var mintxt = this.listProducts[i].dure_txt_table.split(' ');
          this.dure_txt = mintxt[0];
          if (this.dure_txt != undefined) {
            this.dure_show = false;
          }
        } else {
          this.dure_show = true;
        }


        this.mixing_txt = this.listProducts[i].mixval;
        if (this.listProducts[i].mixval != undefined) {
          this.mix_show = false;
        } else {
          this.mix_show = true;
        }

        this.intake_id = this.listProducts[i].intake;
        this.remark_txt = this.listProducts[i].remarks;

        if (this.intake_id == 8 || this.intake_id == 7) {
          this.quan_lable = false;
          this.mor_lable = true;

        } else {
          this.quan_lable = true;
          this.mor_lable = false;
        }

        this.mor = this.listProducts[i].morning;
        this.aft = this.listProducts[i].afternoon;
        this.eve = this.listProducts[i].evening;
        this.ngt = this.listProducts[i].night;
        this.Med_addbtn = true;

        if (this.listProducts[i].show_intake != undefined) {
          this.show_intake = this.listProducts[i].show_intake;
          if (this.show_intake == "0") {
            this.intakesession = true;
          }
        }

        if (this.listProducts[i].short_form != undefined) {
          this.short_form = this.listProducts[i].short_form;
        }

        this.listProducts.splice(i, 1);
        this.getMedtypeForEdit();

        break;
      }
    }
    this.highRiskPresent = this.listProducts.some(drug => drug.highrisk === "1");

    if (this.show_intake != "0") {
      for (var i = 0; i < this.Get_Intake_array.length; i++) {
        if (this.intake_id == this.Get_Intake_array[i].drug_int_id) {
          this.intake_txt = this.Get_Intake_array[i].description;
          this.drug_typetxt = this.Get_Intake_array[i].drug_type_id
          console.log("this.intake_txt" + this.intake_txt)
          this.sesssion_basechange();
        }
      }
    } else {
      this.quan_lable = false;
      this.mor_lable = true;
      this.eve_eventxt = true;
      this.night_eventxt = true;
      this.daydur_show = true;
      this.morning_dis = false;
      this.afternoon_dis = true;
      this.even_dis = true;
      this.ngt_dis = true;
      this.dure_show = true
      this.after_eventxt = true;
      this.intakesession = true;
      this.intake_show = true;
    }

    if (this.listProducts.length != 0) {
      this.Medicine_table = false;
    } else {
      this.Medicine_table = true;
      this.saveflag = false;
      this.printFlag = false;
      this.print_view_flag = true;
    }
  }
  getMedtypeForEdit() {
    var headers = new Headers();
    if (this.medtypetxt != null && this.medtypetxt != undefined && this.medtypetxt.length > 2) {
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/medtypesedit/',
        {
          medicare_id: this.Medicare_name,
          med_name: this.medtypetxt,
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            this.Medical_prescription_array = [];
            this.drug_typetxt = obj.med_code;
            this.short_form = obj.short_form;
            this.show = obj.show_short_form;
            this.show_intake = obj.show_intake;
            this.intake_change();
          },
          error => {

          }
        )
    }
  }

  intake_change = function (this: any) {
    if (this.afterfood_txt == "After food" || this.afterfood_txt == "Before food") {
      this.dure_show = false;
      this.dure_require = true;
      this.dure_txt = "30";
    } else {
      this.dure_show = true;
      this.dure_require = false;
      this.dure_txt = undefined;
    }
  }

  sesssion_basechange() {
    if (this.show_intake == undefined) {
      this.toastr.error(Message_data.selectMedicine);
    }
    if (this.intake_txt == "Morning only" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;
      this.morning_dis = false;
      this.afternoon_dis = true;
      this.even_dis = true;
      this.ngt_dis = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;

      this.morning_req = true;
      this.afternoon_req = false;
      this.evening_req = false;
      this.night_req = false;

      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }

      this.morn_eventxt = false;

    } else if (this.intake_txt == "Afternoon only" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "0";
          } else {
            this.mor = "0";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.afternoon_dis = false;
      this.morning_dis = true;
      this.even_dis = true;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;

      this.morning_req = false;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = true;

      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Evening only" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "0";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "2.5";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "5";
            this.ngt = "0";
          } else {
            this.mor = "0";
            this.aft = "0";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = true;
      this.even_dis = false;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;

      this.morning_req = false;
      this.afternoon_req = false;
      this.evening_req = true;
      this.night_req = false;

      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Night only" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "1";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "5";
          } else {
            this.mor = "0";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }

      this.afternoon_dis = true;
      this.morning_dis = true;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = false;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Morning & Afternoon" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "0";
          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = true;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Morning & Evening" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "5";
            this.ngt = "0";
          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = true;
      this.even_dis = false;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = false;
      this.evening_req = true;
      this.night_req = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Morning & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "1";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "5";
          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = true;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = false;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Afternoon & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "1";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "5";
          } else {
            this.mor = "0";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = false;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Afternoon & Evening" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "";
          } else {
            this.mor = "0";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Evening & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "0";
        this.eve = "1";
        this.ngt = "1";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "2.5";
            this.ngt = "2.5";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "5";
            this.ngt = "5";
          } else {
            this.mor = "0";
            this.aft = "0";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = false;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Morning,Afternoon & Evening" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "0";
      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "0";
          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "";
          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "0";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = true;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = false;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    } else if (this.intake_txt == "Thrice a day" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "5";

          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.eve_eventxt = false;
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Morning,Afternoon & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "0";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "5";

          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = true;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = true;

      this.eve_eventxt = true;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = false;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Morning,Afternoon,Evening & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "5";

          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = false;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Four times a day" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "5";

          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = false;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = false;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = true;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Afternoon,Evening & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "1";
        this.eve = "1";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "2.5";
            this.eve = "2.5";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "5";
            this.eve = "5";
            this.ngt = "5";

          } else {
            this.mor = "0";
            this.aft = "10";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = false;
      this.even_dis = false;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = false;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = true;
      this.evening_req = true;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Evening & Night" && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "0";
        this.aft = "0";
        this.eve = "1";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "2.5";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "0";
            this.aft = "0";
            this.eve = "5";
            this.ngt = "5";

          } else {
            this.mor = "0";
            this.aft = "0";
            this.eve = "10";
            this.ngt = "10";
          }
        }
      }

      this.morning_dis = true;
      this.afternoon_dis = true;
      this.even_dis = false;
      this.ngt_dis = false;

      this.mor_id = false;
      this.after_id = false;
      this.eve_id = false;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;

      this.morning_req = false;
      this.afternoon_req = false;
      this.evening_req = true;
      this.night_req = true;

      this.mor_lable = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.intake_show = false;

      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if ((this.intake_txt == "Every 6 hours" || this.intake_txt == "Every 8 hours"
      || this.intake_txt == "Every 12 hours") && this.show_intake == "1") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.quan_lable = false;
      this.morning_dis = false;
      this.mor_id = true;
      this.after_id = true;
      this.eve_id = true;

      this.eve_eventxt = true;
      this.night_eventxt = true;
      this.daydur_show = false;

      this.mor_lable = true;
      this.after_eventxt = true;
      this.morning_req = true;
      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Stat") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.quan_lable = false;
      this.morning_dis = false;
      this.mor_id = true;
      this.after_id = true;
      this.eve_id = true;
      this.intake_show = true;
      this.dure_show = true;

      this.eve_eventxt = true;
      this.night_eventxt = true;
      this.daydur_show = true;

      this.mor_lable = true;
      this.after_eventxt = true;
      this.morning_req = true;
      this.morn_eventxt = false;
      this.dure_txt = " ";
      this.daydur_txt = " ";
      this.day_txt = " ";
      this.afterfood_txt = ""

    } else if (this.intake_txt == "Once a day") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }
      }

      this.quan_lable = false;
      this.morning_dis = false;
      this.mor_id = true;
      this.after_id = true;
      this.eve_id = true;

      this.eve_eventxt = true;
      this.night_eventxt = true;
      this.daydur_show = false;
      this.mor_lable = true;
      this.after_eventxt = true;
      this.morning_req = true;
      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "SOS") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";

          } else {
            this.mor = "10";
            this.aft = "0";
            this.eve = "0";
            this.ngt = "0";
          }
        }

      } else {
        this.mor = "0";
        this.aft = "0";
        this.eve = "0";
        this.ngt = "0";
      }

      this.quan_lable = false;
      this.morning_dis = false;
      this.mor_id = true;
      this.after_id = true;
      this.eve_id = true;

      this.eve_eventxt = true;
      this.night_eventxt = true;
      this.daydur_show = false;
      this.dure_txt = " ";
      this.daydur_txt = " ";
      this.day_txt = " ";
      this.afterfood_txt = " ";
      this.mor_lable = true;
      this.after_eventxt = true;
      this.morning_req = true;
      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;

    } else if (this.intake_txt == "Alternative day" || this.intake_txt == "Once a week"
      || this.intake_txt == "Once in two weeks" || this.intake_txt == "Twice a week" || this.intake_txt == "Once a month") {
      if (this.drug_typetxt == "1" || this.drug_typetxt == "2" || this.drug_typetxt == "3" || this.drug_typetxt == "4"
        || this.drug_typetxt == "6" || this.drug_typetxt == "7" || this.drug_typetxt == "8" || this.drug_typetxt == "9"
        || this.drug_typetxt == "10" || this.drug_typetxt == "11" || this.drug_typetxt == "12" || this.drug_typetxt == "13"
        || this.drug_typetxt == "15" || this.drug_typetxt == "16" || this.drug_typetxt == "17" || this.drug_typetxt == "19"
        || this.drug_typetxt == "22" || this.drug_typetxt == "23" || this.drug_typetxt == "24" || this.drug_typetxt == "28"
        || this.drug_typetxt == "30" || this.drug_typetxt == "25" || this.drug_typetxt == "29" || this.drug_typetxt == "53"
        || this.drug_typetxt == "56" || this.drug_typetxt == "57" || this.drug_typetxt == "59" || this.drug_typetxt == "61"
        || this.drug_typetxt == "65" || this.drug_typetxt == "76" || this.drug_typetxt == "222") {
        this.mor = "1";
        this.aft = "1";
        this.eve = "0";
        this.ngt = "1";

      } else if (this.drug_typetxt == "5" || this.drug_typetxt == "14" || this.drug_typetxt == "26" || this.drug_typetxt == "44") {
        if (this.client_age != undefined) {
          var cage = parseInt(this.client_age);
          if (cage <= 5) {
            this.mor = "2.5";
            this.aft = "0";
            this.eve = "2.5";
            this.ngt = "2.5";

          } else if (cage > 5 && cage <= 13) {
            this.mor = "5";
            this.aft = "5";
            this.eve = "0";
            this.ngt = "5";

          } else {
            this.mor = "10";
            this.aft = "10";
            this.eve = "0";
            this.ngt = "10";
          }
        }
      }
      this.afternoon_dis = false;
      this.morning_dis = false;
      this.mor_id = false;
      this.mor_lable = false;
      this.after_id = false;
      this.eve_id = false;

      this.eve_eventxt = false;
      this.night_eventxt = false;
      this.daydur_show = false;
      this.even_dis = false;
      this.ngt_dis = false;
      this.after_eventxt = false;
      this.quan_lable = true;
      this.evening_req = true;
      this.afternoon_req = true;
      this.morning_req = true;
      this.intake_show = false;
      if (this.afterfood_txt != "With food") {
        this.dure_show = false;
      }
      this.morn_eventxt = false;
    }
    if (this.intake_txt != "Stat") {
      this.daydur_txt = "day(s)"
      this.afterfood_txt = "After food";
      this.dure_txt = "30";
    }
    for (var i = 0; i < this.Get_Intake_array.length; i++) {
      if (this.intake_txt == this.Get_Intake_array[i].description) {
        // this.intake_txt = this.Get_Intake_array[i].description
        this.intake_id = this.Get_Intake_array[i].drug_int_id;
        break;
      }
    }
    if (this.intake_txt == "Once a week"
      || this.intake_txt == "Once in two weeks" || this.intake_txt == "Twice a week") {
      this.daydur_txt = "week(s)";
    } else if (this.intake_txt == "Once a month") {
      this.daydur_txt = "month(s)";
    } else {
      this.daydur_txt = "day(s)";
    }
  }

  Medicalpres_add() {
    this.high_riskcolor="";
    this.sound_alikecolor="";
    this.look_alikecolor="";
    this.ward_onlycolor="";     
    if(this.high_risk != undefined){
      this.high_risk=this.high_risk;
      this.high_riskcolor=this.hospitalinfoarray[0].high_risk_color;
    }else{
      this.high_risk=""
      this.high_riskcolor=""
    }
    if(this.sound_alike != undefined){
      this.sound_alike=this.sound_alike;
      this.sound_alikecolor=this.hospitalinfoarray[0].sound_alike_color;
    }else{
      this.sound_alike="";
      this.sound_alikecolor="";
    }
    if(this.look_alike != undefined){
      this.look_alike=this.look_alike;
      this.look_alikecolor=this.hospitalinfoarray[0].look_alike_color;
    }else{
      this.look_alike="";
      this.look_alikecolor="";
    }
    if(this.ward_only != undefined){
      this.ward_only=this.ward_only;
      this.ward_onlycolor=this.hospitalinfoarray[0].ward_only_color;
    }else{
      this.ward_only="";
      this.ward_onlycolor="";
    }
    if (this.ganericName != undefined) {
      this.genericnameflag = true;
    }
    if (this.default_quantity != undefined) {
      this.default_quantity = this.default_quantity
    }
    //this.intakesession = true;
    console.log("Medical_prescription_array" + JSON.stringify(this.listProducts))
    console.log("Medical_prescription_array" + JSON.stringify(this.med_id))
    console.log("this.intake_txt ---" + JSON.stringify(this.intake_txt) + this.intakesession)
    console.log("Medical_prescription_array" + JSON.stringify(this.Medical_prescription_array))
    var flag = false;
    if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
      flag = false;
    } else {
      if (this.intakesession != true) {
        if (this.intake_txt != "Stat" && this.intake_txt != "SOS" && (this.day_txt == undefined || this.day_txt == "" || this.day_txt == " ")) {
          this.toastr.error(Message_data.enterNoOfDays);
          flag = true;
        }
      } else {
        this.intake_txt = ""
      }

    }
    this.found = this.listProducts.some(item => item.drug_id === this.med_id && item.intake === this.intake_id);

    if (this.new_med == true) {
      if (this.medtypetxt == undefined || this.medtypetxt == "" || this.medtypetxt == " ") {
        this.toastr.error(Message_data.enterMedType);
        flag = true;
        this.new_med = true;
        this.newmed_flag = false;
      }
    }

    if (this.medicine_nametxt == undefined || this.medicine_nametxt == "") {
      this.toastr.error(Message_data.enterMedName);
      flag = true;
    } else if (this.intakesession != true) {
      if (this.intake_txt == undefined || this.intake_txt == "") {
        this.toastr.error(Message_data.enterIntkSess);
        flag = true;
      }
    }

    if (this.found == true) {
      this.toastr.error("Already prescriped medicine");
      flag = true;
    }

    if (this.show_intake == "1") {
      if (this.intake_txt != undefined && this.intake_txt == "Morning only" && (this.mor == undefined || this.mor == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Afternoon only" && (this.aft == undefined || this.aft == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Evening only" && (this.eve == undefined || this.eve == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Night only" && (this.ngt == undefined || this.ngt == "")) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning & Afternoon" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning & Evening" &&
        ((this.mor == undefined || this.mor == "") || (this.eve == undefined || this.eve == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning & Night" &&
        ((this.mor == undefined || this.mor == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Afternoon & Evening" &&
        ((this.aft == undefined || this.aft == "") || (this.eve == undefined || this.eve == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Afternoon & Night" &&
        ((this.aft == undefined || this.aft == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning,Afternoon & Evening" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.eve == undefined || this.eve == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning,Afternoon & Night" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Morning,Afternoon,Evening & Night" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.eve == undefined || this.eve == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Afternoon,Evening & Night" &&
        ((this.aft == undefined || this.aft == "") || (this.eve == undefined || this.eve == "")
          || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Evening & Night" &&
        ((this.eve == undefined || this.eve == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Thrice a day" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && this.intake_txt == "Four times a day" &&
        ((this.mor == undefined || this.mor == "") || (this.aft == undefined || this.aft == "")
          || (this.eve == undefined || this.eve == "") || (this.ngt == undefined || this.ngt == ""))) {
        this.toastr.error(Message_data.enterDailyIntk);
        flag = true;
      } else if (this.intake_txt != undefined && (this.intake_txt == "Every 6 hours" || this.intake_txt == "Every 8 hours"
        || this.intake_txt == "Every 12 hours" || this.intake_txt == "Once a day" || this.intake_txt == "Stat")
        && (this.mor == undefined || this.mor == "")) {
        this.toastr.error(Message_data.enterIntake);
        flag = true;
      } else if (this.intake_txt == "Stat" && (this.mor == undefined || this.mor == "")) {
        this.toastr.error(Message_data.enterIntake);
        flag = true;
      } else if (this.intake_txt != undefined && (this.intake_txt == "Alternative day" || this.intake_txt == "Once a week"
        || this.intake_txt == "Once in two weeks" || this.intake_txt == "Twice a week") && (this.mor == "0" && this.aft == "0" && this.eve == "0" && this.ngt == "0")) {
        this.toastr.error(Message_data.enterIntake);
        flag = true;
      }
    }


    if (flag == false) {
      this.saveflag = true;
      this.printFlag = true;
      this.print_view_flag = false;

      for (var i = 0; i < this.Medical_prescription_array.length; i++) {
        if (this.Medical_prescription_array[i].med_name == this.medtypetxt) {
          this.drug_typetxt = this.Medical_prescription_array[i].med_code;
        }
      }

      for (var i = 0; i < this.Get_Intake_array.length; i++) {
        if (this.intake_txt == this.Get_Intake_array[i].description) {
          this.intake_id = this.Get_Intake_array[i].drug_int_id;
        }
      }

      this.Medicine_table = false;
      if (this.med_id == null) {
        this.med_id = "0";
      }

      var aftfood;
      console.log("this.show.this.show --" + this.show)
      console.log("this.show_intake -intakesession-" + this.show_intake + this.intake_txt + this.intakesession)
      console.log("this.short_form --" + this.short_form)
      console.log("this.short_form **--" + this.short_form);

      if (this.show == "1") {
        if (this.intakesession == true && this.intake_txt == "") {
          this.dure_txt = ""
        } else {
          this.dure_txt = this.dure_txt
        }
        var morntxt = parseFloat(this.mor) != 0 ? this.mor : "0";
        var afttxt = parseFloat(this.aft) != 0 ? this.aft : "0";
        var evetxt = parseFloat(this.eve) != 0 ? this.eve : "0";
        var ngttxt = parseFloat(this.ngt) != 0 ? this.ngt : "0";

        if (this.show_intake == "0") {
          if (this.intakesession == true && this.intake_txt == "") {
            this.every_six = morntxt
            this.param_four = true;
            // this.dure_txt="";

          } else {
            this.every_six = this.intake_txt;
          }
        } else {
          var morning = " ", afternoon = " ", evening = " ", night = " ";
          if (this.intake_txt == "Morning only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night;
            this.param_four = false;

          }
          else if (this.intake_txt == "Afternoon only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night;
            this.param_four = false;

          }
          else if (this.intake_txt == "Night only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Morning & Afternoon") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night;
            this.param_four = false;

          }
          else if (this.intake_txt == "Morning & Night") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Afternoon & Night") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Thrice a day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Four times a day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Every 6 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 6 hours";
            this.param_four = true;

          } else if (this.intake_txt == "Every 8 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 8 hours";
            this.param_four = true;

          } else if (this.intake_txt == "Every 12 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 12 hours";
            this.param_four = true;

          }
          else if (this.intake_txt == "Once a day") {
            this.every_six = morntxt + " " + this.short_form + " - Once a day";
            this.param_four = true;

          }
          else if (this.intake_txt == "SOS") {
            if (this.drug_typetxt == "1" || this.drug_typetxt == "7" || this.drug_typetxt == "10"
              || this.drug_typetxt == "23" || this.drug_typetxt == "30") {
              this.every_six = morntxt + " " + this.short_form + " SOS - if required";
              this.param_four = true;
            } else {
              this.every_six = morntxt + " " + this.short_form + " SOS - if required";
              this.param_four = true;

            }

          } else if (this.intake_txt == "Once a week") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            // this.remark_txt= this.intake_txt

          } else if (this.intake_txt == "Once in two weeks") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            // this.remark_txt= this.intake_txt

          } else if (this.intake_txt == "Twice a week") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            //  this.remark_txt= this.intake_txt

          } else if (this.intake_txt == "Alternative day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            // this.remark_txt= this.intake_txt

          } else if (this.intake_txt == "Once a day") {
            this.every_six = morntxt + " " + this.short_form + " - Once a day";
            this.param_four = true;

          } else if (this.intake_txt == "Stat") {
            this.every_six = morntxt + " " + this.short_form + " - Stat";
            this.param_four = true;
            // this.dure_txt="";

          } else if (this.intake_txt == "Once a month") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night;
            // this.every_six = morntxt + " - " + afttxt + " - " + evetxt + " - " + ngttxt + " - per month";

            // this.every_six = morntxt+ " " + this.short_form + " - per month";
            this.param_four = false;

          }

          else if (this.intake_txt == "Evening only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
          }
        }
        console.log("seeeeeeeeedure_txteeeeeee" + this.dure_txt)

        if ((this.intake_txt != "Stat") && (this.dure_txt != undefined && this.dure_txt != "" && this.dure_txt != " ")) {
          if (this.intake_txt == "SOS") {
            if (this.dure_txt != " ") {
              this.dure_write = this.dure_txt + " " + "mins ";
            } else {
              this.dure_write = "";
              this.dure_flag = true;
            }

          } else {
            console.log("else **" + this.intake_txt + this.dure_txt)
            if (this.dure_txt != undefined && this.dure_txt != "" && this.dure_txt != " ") {
              if (this.intakesession == true && this.intake_txt == "") {
                this.dure_write = "";
              } else {
                this.dure_write = this.dure_txt + " " + "mins ";
              }

            }
          }
        } else {
          console.log("else (((((" + this.intake_txt + this.dure_txt)
          this.dure_write = "";
          this.dure_flag = true;
        }
        if (this.afterfood_txt == undefined) {
          this.afterfood_txt = "";
        }

        var frequency;
        if (this.intake_txt == "Alternative day" || this.intake_txt == "Once a week" || this.intake_txt == "Once in two weeks" || this.intake_txt == "Twice a week" || this.intake_txt == "Once a month") {
          frequency = this.intake_txt;
        } else {
          if (this.intake_txt == "Stat" || this.intake_txt == "SOS") {
            frequency = "";
          } else {
            if (this.day_txt == "1" && this.daydur_txt == "day(s)") {
              frequency = "";
            } else {
              if (this.intakesession == true && this.intake_txt == "") {
                frequency = "";
              } else {
                frequency = "Daily";
              }

            }
          }
        }

        if (this.intake_txt == "Stat") {
          period = "";
          this.day_txt = "";
        } else {
          var period
          if (this.day_txt == "1" && this.daydur_txt == "day(s)") {
            period = "day";

          } else if (this.day_txt != "1" && this.daydur_txt == "day(s)") {
            period = "days";

          } else if (this.day_txt == "1" && this.daydur_txt == "week(s)") {
            period = "week";

          } else if (this.day_txt != "1" && this.daydur_txt == "week(s)") {
            period = "weeks";

          } else if (this.day_txt == "1" && this.daydur_txt == "month(s)") {
            period = "month";

          } else if (this.day_txt != "1" && this.daydur_txt == "month(s)") {
            period = "months";
          } else {
            if (this.intakesession == true && this.intake_txt == "") {
              period = "";
            } else {
              period = "";
            }
            // period = "";
          }
        }

        if (this.intake_txt != "Stat" && (this.afterfood_txt != undefined && this.afterfood_txt != 'Select')) {
          aftfood = this.afterfood_txt;
        } else {
          aftfood = "";
        }
        // alert("check i")

        if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
          if (this.day_txt != "") {
            this.listProducts.push({
              med_typetxt: this.medtypetxt,
              drug_name: this.medicine_nametxt,
              genericname: this.ganericName,
              days: this.day_txt,
              period: period,
              drug_intake: aftfood,
              intake: this.intake_id,
              every_six: this.every_six,
              dure_txt_table: this.dure_write,
              time_duration: this.dure_txt,
              morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
              afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
              evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
              night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
              morning: this.mor,
              afternoon: this.aft,
              evening: this.eve,
              night: this.ngt,
              drug_id: this.med_id,
              drug_type_id: this.drug_typetxt,
              remarks: this.remark_txt,
              frequency: frequency,
              show_intake: this.show_intake,
              rowid: this.rowid,
              short_name: this.short_name,
              mixval: this.mixing_txt,
              param_four: this.param_four,
              default_quantity: this.default_quantity,
              intake_mode:this.intake_mode,
              highrisk:this.high_risk,
              soundalike:this.sound_alike,
              lookalike:this.look_alike,
              ward:this.ward_only,
              high_riskcolor:this.high_riskcolor,
              sound_alikecolor:this.sound_alikecolor,
              look_alikecolor:this.look_alikecolor,
              ward_onlycolor:this.ward_onlycolor,
            });

          } else {
            this.listProducts.push({
              med_typetxt: this.medtypetxt,
              drug_name: this.medicine_nametxt,
              genericname: this.ganericName,
              // days: this.day_txt,
              // period: period,
              drug_intake: aftfood,
              intake: this.intake_id,
              every_six: this.every_six,
              dure_txt_table: this.dure_write,
              time_duration: this.dure_txt,
              morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
              afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
              evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
              night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
              morning: this.mor,
              afternoon: this.aft,
              evening: this.eve,
              night: this.ngt,
              drug_id: this.med_id,
              drug_type_id: this.drug_typetxt,
              remarks: this.remark_txt,
              frequency: frequency,
              show_intake: this.show_intake,
              rowid: this.rowid,
              short_name: this.short_name,
              mixval: this.mixing_txt, param_four: this.param_four,
              default_quantity: this.default_quantity,
              intake_mode:this.intake_mode,
              highrisk:this.high_risk,
              soundalike:this.sound_alike,
              lookalike:this.look_alike,
              ward:this.ward_only,
              high_riskcolor:this.high_riskcolor,
              sound_alikecolor:this.sound_alikecolor,
              look_alikecolor:this.look_alikecolor,
              ward_onlycolor:this.ward_onlycolor,
            });
          }
        }
        else {
          if (this.intakesession == true && this.intake_txt == "") {
            this.dure_txt = ""
            //   alert("ttt hjsdsdh")
          } else {
            this.dure_txt = this.dure_txt
          }
          this.listProducts.push({
            med_typetxt: this.medtypetxt,
            drug_name: this.medicine_nametxt,
            genericname: this.ganericName,
            days: this.day_txt,
            period: period,
            drug_intake: aftfood,
            intake: this.intake_id,
            every_six: this.every_six,
            dure_txt_table: this.dure_write,
            time_duration: this.dure_txt,
            morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
            afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
            evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
            night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
            morning: this.mor,
            afternoon: this.aft,
            evening: this.eve,
            night: this.ngt,
            drug_id: this.med_id,
            drug_type_id: this.drug_typetxt,
            remarks: this.remark_txt,
            frequency: frequency,
            show_intake: this.show_intake,
            rowid: this.rowid,
            short_name: this.short_name,
            mixval: this.mixing_txt, param_four: this.param_four,
            default_quantity: this.default_quantity,
            intake_mode:this.intake_mode,
            highrisk:this.high_risk,
            soundalike:this.sound_alike,
            lookalike:this.look_alike,
            ward:this.ward_only,
            high_riskcolor:this.high_riskcolor,
            sound_alikecolor:this.sound_alikecolor,
            look_alikecolor:this.look_alikecolor,
            ward_onlycolor:this.ward_onlycolor,
          });
        }
      } else {
        //  alert("check 3")
        if (this.intakesession == true && this.intake_txt == "") {
          this.dure_txt = ""

        } else {
          this.dure_txt = this.dure_txt
        }
        var morntxt = parseFloat(this.mor) != 0 ? this.mor : "0";
        var afttxt = parseFloat(this.aft) != 0 ? this.aft : "0";
        var evetxt = parseFloat(this.eve) != 0 ? this.eve : "0";
        var ngttxt = parseFloat(this.ngt) != 0 ? this.ngt : "0";

        if (this.show_intake == "0") {
          if (this.intakesession == true) {
            this.intake_txt = ""
          }
          //alert("ok333333"+this.intakesession+this.intake_txt)    
          if (this.intakesession == true && this.intake_txt == "") {
            this.every_six = morntxt
            //  this.param_four = true;
            // this.dure_txt="";

          } else {
            this.every_six = this.intake_txt;
          }
        } else {
          var morning = " ", afternoon = " ", evening = " ", night = " ";

          if (this.intake_txt == "Morning only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Afternoon only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Night only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Morning & Afternoon") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Morning & Night") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Afternoon & Night") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Thrice a day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Four times a day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          }
          else if (this.intake_txt == "Every 6 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 6 hours";
            this.param_four = true;

          } else if (this.intake_txt == "Every 8 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 8 hours";
            this.param_four = true;

          } else if (this.intake_txt == "Every 12 hours") {
            this.every_six = morntxt + " " + this.short_form + " - Every 12 hours";
            this.param_four = true;

          }
          else if (this.intake_txt == "Once a day") {
            this.every_six = morntxt + " " + this.short_form + " - Once a day";
            this.param_four = true;

          }
          else if (this.intake_txt == "SOS") {
            if (this.drug_typetxt == "1" || this.drug_typetxt == "7" || this.drug_typetxt == "10"
              || this.drug_typetxt == "23" || this.drug_typetxt == "30") {
              this.every_six = morntxt + " " + this.short_form + " SOS - if required";
              this.param_four = true;
            } else {
              this.every_six = morntxt + " " + this.short_form + " SOS - if required";
              this.param_four = true;

            }

          } else if (this.intake_txt == "Once a week") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            // this.remark_txt= this.intake_txt

          } else if (this.intake_txt == "Once in two weeks") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            // this.remark_txt= this.intake_txt

          } else if (this.intake_txt == "Twice a week") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            //  this.remark_txt= this.intake_txt
          } else if (this.intake_txt == "Alternative day") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
            //  this.remark_txt= this.intake_txt
          } else if (this.intake_txt == "Once a day") {
            this.every_six = morntxt + " " + this.short_form + " - Once a day";
            this.param_four = true;

          } else if (this.intake_txt == "Stat") {
            this.every_six = morntxt + " " + this.short_form + " - Stat";
            this.param_four = true;
            // this.dure_txt="";

          } else if (this.intake_txt == "Once a month") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night;
            //  this.every_six = morntxt+ " " + this.short_form + " - per month";
            this.param_four = false;

          } else if (this.intake_txt == "Evening only") {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;

          } else if (this.intake_txt == "") {
            this.every_six = morntxt + " " + this.short_form;
            this.param_four = true;
            // this.dure_txt="";

          } else {
            if (morntxt !== "0") {
              morning = morntxt + " " + this.short_form + " - ";
            } else {
              morning = morntxt + " - ";
            }
            if (afttxt !== "0") {
              afternoon = afttxt + " " + this.short_form + " - ";
            } else {
              afternoon = afttxt + " - ";
            }
            if (evetxt !== "0") {
              evening = evetxt + " " + this.short_form + " - ";
            } else {
              evening = evetxt + " - ";
            }
            if (ngttxt !== "0") {
              night = ngttxt + " " + this.short_form;
            } else {
              night = ngttxt;
            }

            this.every_six = morning + afternoon + evening + night; this.param_four = false;
          }
        }
        if ((this.intake_txt != "Stat") && (this.dure_txt != undefined && this.dure_txt != "" && this.dure_txt != " ")) {
          if (this.intake_txt == "SOS") {
            if (this.dure_txt != " ") {
              this.dure_write = this.dure_txt + " " + "mins ";
            } else {
              this.dure_write = "";
              this.dure_flag = true;
            }

          } else {
            console.log("else **" + this.intake_txt + this.dure_txt)
            if (this.dure_txt != undefined && this.dure_txt != "" && this.dure_txt != " ") {
              if (this.intakesession == true && this.intake_txt == "") {
                this.dure_write = "";
              } else {
                this.dure_write = this.dure_txt + " " + "mins ";
              }

            }
          }
        } else {
          console.log("else (((((" + this.intake_txt + this.dure_txt)
          this.dure_write = "";
          this.dure_flag = true;
        }

        var frequency;
        if (this.intake_txt == "Alternative day" || this.intake_txt == "Once a week" || this.intake_txt == "Once in two weeks" || this.intake_txt == "Twice a week" || this.intake_txt == "Once a month") {
          frequency = this.intake_txt;
        } else {
          if (this.intakesession == true && this.intake_txt == "") {
            frequency = ""
          } else {
            if (this.intake_txt == "Stat" || this.intake_txt == "SOS") {
              frequency = "";
            } else {
              if (this.day_txt == "1" && this.daydur_txt == "day(s)") {
                frequency = "";
              } else {
                if (this.intakesession == true && this.intake_txt == "") {
                  frequency = ""
                } else {
                  frequency = "Daily";

                }
              }
            }
          }

        }

        var period
        if (this.intakesession == true && this.intake_txt == "") {
          period = "";
        } else {
          if (this.day_txt == "1" && this.daydur_txt == "day(s)") {
            period = "day";
          } else if (this.day_txt != "1" && this.daydur_txt == "day(s)") {
            period = "days";
          } else if (this.day_txt == "1" && this.daydur_txt == "week(s)") {
            period = "week";
          } else if (this.day_txt != "1" && this.daydur_txt == "week(s)") {
            period = "weeks";
          } else if (this.day_txt == "1" && this.daydur_txt == "month(s)") {
            period = "month";
          } else if (this.day_txt != "1" && this.daydur_txt == "month(s)") {
            period = "months";
          } else {
            if (this.intakesession == true && this.intake_txt == "") {
              period = "";
            } else {
              period = "";
            }
          }
        }
        if (this.intakesession == true && this.intake_txt == "") {
          aftfood = ""
        } else {
          if (this.afterfood_txt != undefined && this.afterfood_txt != 'Select') {
            if (this.intakesession == true && this.intake_txt == "") {
              aftfood = ""
            } else {
              aftfood = this.afterfood_txt;
            }
          } else {
            aftfood = "";
          }
        }

        if (this.personalinfo.AppFlow == "InpatMedPres" || this.personalinfo.AppFlow == "Inpatient") {
          // alert("ok")
          if (this.day_txt !== "") {
            this.listProducts.push({
              med_typetxt: this.medtypetxt,
              drug_name: this.medicine_nametxt,
              genericname: this.ganericName,
              days: this.day_txt,
              period: period,
              drug_intake: aftfood,
              intake: this.intake_id,
              every_six: this.every_six,
              dure_txt_table: this.dure_write,
              time_duration: this.dure_txt,
              morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
              afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
              evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
              night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
              morning: this.mor,
              afternoon: this.aft,
              evening: this.eve,
              night: this.ngt,
              drug_id: this.med_id,
              drug_type_id: this.drug_typetxt,
              frequency: frequency,
              remarks: this.remark_txt,
              show_intake: this.show_intake,
              rowid: this.rowid,
              short_name: this.short_name,
              param_four: this.param_four,
              default_quantity: this.default_quantity,
              intake_mode:this.intake_mode,
              highrisk:this.high_risk,
              soundalike:this.sound_alike,
              lookalike:this.look_alike,
              ward:this.ward_only,
              high_riskcolor:this.high_riskcolor,
              sound_alikecolor:this.sound_alikecolor,
              look_alikecolor:this.look_alikecolor,
              ward_onlycolor:this.ward_onlycolor,
            });
          } else {
            this.listProducts.push({
              med_typetxt: this.medtypetxt,
              drug_name: this.medicine_nametxt,
              // days: this.day_txt,
              // period: period,
              drug_intake: aftfood,
              intake: this.intake_id,
              every_six: this.every_six,
              dure_txt_table: this.dure_write,
              time_duration: this.dure_txt,
              morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
              afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
              evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
              night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
              morning: this.mor,
              afternoon: this.aft,
              evening: this.eve,
              night: this.ngt,
              drug_id: this.med_id,
              drug_type_id: this.drug_typetxt,
              frequency: frequency,
              remarks: this.remark_txt,
              show_intake: this.show_intake,
              rowid: this.rowid,
              short_name: this.short_name,
              param_four: this.param_four,
              default_quantity: this.default_quantity,
              intake_mode:this.intake_mode,
              highrisk:this.high_risk,
              soundalike:this.sound_alike,
              lookalike:this.look_alike,
              ward:this.ward_only,
              high_riskcolor:this.high_riskcolor,
              sound_alikecolor:this.sound_alikecolor,
              look_alikecolor:this.look_alikecolor,
              ward_onlycolor:this.ward_onlycolor,
            });
          }

        } else {
          // alert(this.day_txt)
          if (this.day_txt !== "") {
            this.listProducts.push({
              med_typetxt: this.medtypetxt,
              drug_name: this.medicine_nametxt,
              genericname: this.ganericName,
              days: this.day_txt,
              period: period,
              drug_intake: aftfood,
              intake: this.intake_id,
              every_six: this.every_six,
              dure_txt_table: this.dure_write,
              time_duration: this.dure_txt,
              morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
              afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
              evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
              night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
              morning: this.mor,
              afternoon: this.aft,
              evening: this.eve,
              night: this.ngt,
              drug_id: this.med_id,
              drug_type_id: this.drug_typetxt,
              frequency: frequency,
              remarks: this.remark_txt,
              show_intake: this.show_intake,
              rowid: this.rowid,
              short_name: this.short_name,
              param_four: this.param_four,
              default_quantity: this.default_quantity,
              intake_mode:this.intake_mode,
              highrisk:this.high_risk,
              soundalike:this.sound_alike,
              lookalike:this.look_alike,
              ward:this.ward_only,
              high_riskcolor:this.high_riskcolor,
              sound_alikecolor:this.sound_alikecolor,
              look_alikecolor:this.look_alikecolor,
              ward_onlycolor:this.ward_onlycolor,
            });
          } else {
            this.listProducts.push({
              med_typetxt: this.medtypetxt,
              drug_name: this.medicine_nametxt,
              drug_intake: aftfood,
              intake: this.intake_id,
              every_six: this.every_six,
              dure_txt_table: this.dure_write,
              time_duration: this.dure_txt,
              morning1: this.mor !== "0" && this.short_form ? `${this.mor} ${this.short_form}` : this.mor,
              afternoon1: this.aft !== "0" && this.short_form ? `${this.aft} ${this.short_form}` : this.aft,
              evening1: this.eve !== "0" && this.short_form ? `${this.eve} ${this.short_form}` : this.eve,
              night1: this.ngt !== "0" && this.short_form ? `${this.ngt} ${this.short_form}` : this.ngt,
              morning: this.mor,
              afternoon: this.aft,
              evening: this.eve,
              night: this.ngt,
              drug_id: this.med_id,
              drug_type_id: this.drug_typetxt,
              frequency: frequency,
              remarks: this.remark_txt,
              show_intake: this.show_intake,
              rowid: this.rowid,
              short_name: this.short_name,
              param_four: this.param_four,
              default_quantity: this.default_quantity,
              intake_mode:this.intake_mode,
              highrisk:this.high_risk,
              soundalike:this.sound_alike,
              lookalike:this.look_alike,
              ward:this.ward_only,
              high_riskcolor:this.high_riskcolor,
              sound_alikecolor:this.sound_alikecolor,
              look_alikecolor:this.look_alikecolor,
              ward_onlycolor:this.ward_onlycolor,
            });
          }
        }
      }
      console.log("list product::::::" + JSON.stringify(this.listProducts))
      this.highRiskPresent = this.listProducts.some(drug => drug.highrisk === "1");
      //Resets the form 
      this.med_typetxt = "";
      this.medicine_nametxt = "";
      this.short_name = "";
      this.short_form = "";
      this.newmed_flag = true;
      this.new_med = false;
      this.print_med = true;
      this.mixing_txt = "";
      this.ganericName = "";
      this.day_txt = "";
      this.remark_txt="";
      // this.dure_txt=""
      //}
      this.medicineaddbtn = false; // Add button
      console.log("MED LIST " + JSON.stringify(this.listProducts))
    }

    var flag: boolean;
    if (this.subtestuname.length != 0) {
      flag = true;
    } else if (this.subtestfname.length != 0) {
      flag = true;
    } else if (this.subtestname.length != 0) {
      flag = true;
    } else if (this.subtestsname.length != 0) {
      flag = true;
    } else if (this.subtestxname.length != 0) {
      flag = true;
    } else if (this.subtesturname.length != 0) {
      flag = true;
    } else if (this.subtestbname.length != 0) {
      flag = true;
    } else if (this.subtestcname.length != 0) {
      flag = true;
    } else {
      flag = false;
    }

    if (this.listProducts.length != 0) {
      this.print_med = true;
    }
    if (flag == true && this.listProducts.length != 0) {
      this.print_medidiag = true;
    } else {
      this.print_medidiag = false;
    }

  }

  //Get medicine type
  Get_medicine_type(e) {
    this.medtypetxt = e.target.value.toString();
    this.Medical_prescription_array = [];
    var headers = new Headers();
    if (this.medtypetxt != null && this.medtypetxt != undefined && this.medtypetxt.length > 2) {
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/medtypes/',
        {
          medicare_id: this.Medicare_name,
          medicine_name: this.medtypetxt
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            var medtypetxt1 = [];
            this.Medical_prescription_array = obj.med_details;
          },
          error => {
          }
        )
    }
  }

  Medcine_type_change(l) {
    for (var i = 0; i < this.Medical_prescription_array.length; i++) {
      if (this.Medical_prescription_array[i].med_name == this.med_typetxt) {
        this.drug_typetxt = this.Medical_prescription_array[i].med_code;
      }
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/meddetails/',
      {
        drug_type: this.drug_typetxt,
        drug_category: this.user_id,
        drug_name: this.medicine_nametxt,
        pharmacy_id: this.pharma_id,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();

          if (obj.med_details != null) {
            this.Medicine_list = obj.med_details;
            for (var i = 0; i < obj.med_details.length; i++) {
              this.Medicne_data.push(obj.med_details[i].med_name);
            }
            this.Medicinenamelist = this.Medicne_data.filter(function (this: any, el) {
              return el.toLowerCase().indexOf(this.medicine_nametxt.toLowerCase()) > -1;
            }.bind(this));
            this.Med_addbtn = true;
          }
        },
        error => { })
  }

  clearmedtype() {
    this.medtypetxt = null;
    this.drug_typetxt = undefined;
    this.medicine_nametxt = null;
    this.Medicinenamelist = [];
  }

  select_medicine_type(item) {
    this.medtypetxt = item.med_name;
    this.drug_typetxt = item.med_code;
    this.short_form = item.short_form;
    this.show = item.show_short_form;
    this.show_intake = item.show_intake;
    this.Medical_prescription_array = [];
    this.sesssion_basechange();
  }

  getSymptoms() {
    var searchString;
    if (this.dis_txt.indexOf(',') > -1) {
      var dataval = this.dis_txt.split(",");
      searchString = dataval[dataval.length - 1].trim();
    } else {
      searchString = this.dis_txt;
    }

    if (searchString.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/gsymbn/',
        JSON.stringify({
          symp_name: searchString
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            if (obj.symptoms != null) {
              this.symptoms_list = [];
              this.symptomlist = [];
              this.symptom_data = [];
              this.symptoms_list = obj.symptoms;
              for (var i = 0; i < obj.symptoms.length; i++) {
                this.symptom_data.push(obj.symptoms[i].description);
              }

              this.symptomlist = this.symptom_data.filter(function (el) {
                return el.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
              }.bind(this));

            }
          },
          error => {

          })
    }
  }

  print_area(value) {
    var flag = true;

    if (Doc_Helper.getDiagAppflow() != "diag") {
      if (this.print_view_flag == true) {
        if (this.dis_txt == "") {
          this.toastr.error(Message_data.enterDisease);
          flag = false;
        }

        if (this.listProducts.length == 0) {
          this.toastr.error(Message_data.addMedDetails);
          flag = false;
        }
      }
    }

    if (Doc_Helper.getDiagAppflow() == "diag") {
      if (this.subtestuname.length != 0) {
        flag = true;

      } else if (this.subtestfname.length != 0) {
        flag = true;

      } else if (this.subtestname.length != 0) {
        flag = true;

      } else if (this.subtestsname.length != 0) {
        flag = true;

      } else if (this.subtestxname.length != 0) {
        flag = true;

      } else if (this.subtesturname.length != 0) {
        flag = true;

      } else if (this.subtestbname.length != 0) {
        flag = true;

      } else if (this.subtestcname.length != 0) {
        flag = true;

      } else {
        flag = false;
      }

      if (flag == false) {
        this.toastr.error(Message_data.enterDiagpres);
      }
    }

    if (this.listProducts.length != 0) {
      this.print_med = true;
    }

    if (this.print_view_flag == false) {
      flag = true;
    }
    this.next_txt=this.next_txt;
    // alert(this.next_txt)
    this.cd.detectChanges();
    console.log("this.nextvisit_print -=="+this.next_txt)
    this.printdetection(flag)
  
  }
  printdetection(flag){
    if (flag == true) {
      if (Helper_Class.get_med_pres_id() == undefined) {
        //if (this.print_view_flag == true) {
        this.savePrescription(this.print_template);
        //}
      }
      var footerimg = this.hosp_footer;
      var footerflag = this.printf_flag;
      var backimg = this.bg_image;
      let printContents, popupWin;

      if (this.print_template != undefined && this.print_template == "banner") {
        printContents = this.printbanner.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

      } else if (this.print_template != undefined && this.print_template == "logowithname") {
        printContents = this.printlogowithname.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

      } else if (this.print_template != undefined && this.print_template == "printnoheadsidebar") {
        printContents = this.printnoheadsidebar.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

      } else {
        printContents = this.printnoheader.nativeElement.innerHTML;
      }
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
        <head>
          <title>Prescription</title>
          <link rel="stylesheet" media="screen" href="">
          <style>
          @page { size: auto;  margin: 0mm; }
          .table_class {
            background-color: rgb(108, 180, 248);
          }
          .casesheet_container {
            width: 211px;
            padding: 1px;
            background: #277196;
            display: inline-flex;
            position: relative;
            padding-left: 5px;
            top: 21px;
            color: #FFFFFF;
          }
          .border_style{
            border:solid 1px;
            border-color: #488aff;
          }
          .width_style{
            margin-top:4px;
            width:600px;
          }
          @media all {
            .page-break { display: none; }
            }
            
            @media print {
            .page-break { display: block; page-break-before: always; }
            }
          @media print {
            body{
              -webkit-print-color-adjust: exact;
              -moz-print-color-adjust: exact;
              -ms-print-color-adjust: exact;
              print-color-adjust: exact;
            }
            footer {
              position: fixed;
              bottom: 0;
            }
          }
          @page { size: auto;  margin: 0mm 10mm 10mm 10mm; }
          .table_class {
            background-color: rgb(108, 180, 248);
          }
          .block1_grid{
            margin: 7px auto;
            width: 90%;
          }
          .block1{
            margin:3px 0;
          }
          .test-names-container {
            display: flex;
            flex-wrap: wrap;
          }
          .rowformat{
            display: flex;
            flex-wrap: wrap;
          }
          table td{
            font-size:11px !important;
          }
          table th{
            font-size:12px !important;
          }
          .test-name {
            margin-right: 10px; /* Adjust spacing between test names */
            white-space: nowrap; /* Prevent wrapping before reaching the width limit */
          }
          </style>
        </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}" >${printContents}</body>
        <footer [hidden]=${footerflag}>
        <img src=${footerimg}>
      </footer>
      </html>`
      );
      popupWin.document.close();
      printContents = "";
      if (this.print_view_flag == false) {
        this.next_txt = "";
        this.instructions = "";
        this.print_instruction = "";
        this.oberse_med = "";
        this.disease_name = "";
        this.disease_flag = false;
        //this.spiltarea('default');
        this.med_view_list = false;
        this.print_diag = false;
        this.Blood_test = [];
        this.sercah_center = "";
        this.obs_add_diag = "";
        this.subtestbname = [];
        this.subtestfname = [];
        this.subtestname = [];
        this.subtestname = [];
        this.subtestsname = [];
        this.subtestuname = [];
        this.subtesturname = [];
        this.subtestxname = [];
        this.subtestcname = [];
      } else {
        this.next_txt = "";
        this.instructions = "";
        this.oberse_med = "";
        this.print_instruction = "";
        this.print_diag = false;
      }
      this.print_view_flag = true;
      //this.listProducts = [];
    }
  }
  savePrescription(data) {
    if (data == "print") {
      this.Medical_prescription_save(null);
      this.printpres = "1";
    } else {
      this.printpres = "0";
      if (this.print_template != undefined && this.print_template == "banner") {
        this.bannerTemplateToPdf();

      } else if (this.print_template != undefined && this.print_template == "logowithname") {
        this.printlogowithnamepdf();

      } else if (this.print_template != undefined && this.print_template == "printnoheadsidebar") {
        //printContents = this.printnoheadsidebar.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

      } else {
        this.printnoheaderdf();
      }
    }
  }

  bannerTemplateToPdf() {
    //margin:[left, top, right, bottom]
    var tempArr = [];
    var pdffile;
    for (var i = 0; i < this.listProducts.length; i++) {
      var remarks
      if (this.listProducts[i].remarks == undefined) {
        remarks = "";
      } else {
        remarks = this.listProducts[i].remarks
      }
      tempArr.push(
        {
          Medicine: this.listProducts[i].med_typetxt + " " + this.listProducts[i].drug_name,
          Dosage: this.listProducts[i].every_six,
          Duration: this.listProducts[i].days + " " + this.listProducts[i].period,
          Frequency: this.listProducts[i].frequency +" " + this.listProducts[i].intake_mode + " " + this.listProducts[i].dure_txt_table + " " + this.listProducts[i].drug_intake,
          Note: remarks
        }
      );
    } 

    if (this.oberse_med == undefined) {
      this.oberse_med = "";
    }
    if (this.instructions == undefined) {
      this.instructions = "";
    }
    if (this.next_txt == undefined) {
      this.next_txt = "";
    }

    let docDefinition = {
      content: [
        // {
        //   image: this.imagestring,
        //   width: 200,
        //   height: 200,
        //   margin: [-30, -20, 0, 20],
        //   layout: 'noBorders'
        // },
        {
          style: 'personaltable',
          table: {
            widths: [400, 300],
            body: [
              ['Name: ' + this.doct_clientname, 'Date: ' + this.Appoint_Date],
              ['', ''],
              ['Age: ' + this.client_age + '     Gender: ' + this.client_gender, 'BP: ' + this.clnt_bp + '     Pulse: ' + this.clnt_pulse],
            ]
          },
          layout: 'noBorders'
        },
        {
          text: 'Investigation: ' + this.oberse_med,
          margin: [0, 20, 0, 0],
          style: 'diagstyle'

        },
        {
          text: 'Diagnosis: ' + this.dis_txt,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        this.medicinetable(tempArr, ['Medicine', 'Dosage', 'Duration', 'Frequency', 'Note']),
        {
          text: 'Instructions: ' + this.instructions,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Next visit: ' + this.next_txt,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Dr. ' + this.doct_name + " " + this.doc_qualif,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Registration no: ' + this.licence_code,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Find your prescription online at www.tervys.com',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'This is digitally generated prescription',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }
      ],
      styles: {
        personaltable: {
          fontSize: 12,
          width: 800,
        },
        diagstyle: {
          fontSize: 12,
          bold: true,
        }
      }
    };
    this.pdfObj = pdfMake.createPdf(docDefinition);
    //this.save_data(this.pdfObj);
    this.pdfObj.getBuffer((buffer) => {
      var blob = new Blob([buffer], {
        type: 'application/pdf'
      });

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function (this: any) {
        pdffile = reader.result;
        this.Medical_prescription_save(pdffile);
      }.bind(this);
    });
  }

  printlogowithnamepdf() {
    var tempArr = [];
    var pdffile;
    for (var i = 0; i < this.listProducts.length; i++) {
      var remarks
      if (this.listProducts[i].remarks == undefined) {
        remarks = "";
      } else {
        remarks = this.listProducts[i].remarks
      }
      tempArr.push(
        {
          Medicine: this.listProducts[i].med_typetxt + " " + this.listProducts[i].drug_name,
          Dosage: this.listProducts[i].every_six,
          Duration: this.listProducts[i].days + " " + this.listProducts[i].period,
          Frequency: this.listProducts[i].frequency +" " + this.listProducts[i].intake_mode + " " + this.listProducts[i].dure_txt_table + " " + this.listProducts[i].drug_intake,
          Note: remarks
        }
      );
    }

    if (this.oberse_med == undefined) {
      this.oberse_med = "";
    }
    if (this.instructions == undefined) {
      this.instructions = "";
    }
    if (this.next_txt == undefined) {
      this.next_txt = "";
    }
    if (this.dct_hospital == undefined) {
      this.dct_hospital = "";
    }
    if (this.doct_address == undefined) {
      this.doct_address = "";
    }

    let docDefinition = {
      content: [
        {
          style: 'personaltable',
          table: {
            widths: [60, 600],
            body: [
              [
                {
                  rowspan: 4,
                  image: this.imagestring,
                  width: 50,
                  height: 50,
                },
                [
                  {
                    table: {
                      widths: [310, 250],
                      style: 'personaltable',
                      body: [
                        [this.dct_hospital, 'Doctor: Dr.' + this.doct_name],
                        [this.doct_address + " " + this.doct_location, 'Date: ' + this.Appoint_Date],
                        [this.doct_city, ' '],
                        [this.doct_state, ' '],
                      ]
                    },
                    layout: 'noBorders'
                  }
                ],
              ],
            ]
          },
          layout: 'noBorders'
        },
        {
          style: 'personaltable',
          table: {
            widths: [400, 300],
            body: [
              ['Name: ' + this.doct_clientname, 'BP: ' + this.clnt_bp],
              ['', ''],
              ['Age: ' + this.client_age + '     Gender: ' + this.client_gender, ' Pulse: ' + this.clnt_pulse],
            ]
          },
          layout: 'noBorders'
        },
        {
          text: 'Investigation: ' + this.oberse_med,
          margin: [0, 20, 0, 0],
          style: 'diagstyle'
        },
        {
          text: 'Diagnosis: ' + this.dis_txt,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        this.medicinetable(tempArr, ['Medicine', 'Dosage', 'Duration', 'Frequency', 'Note']),
        {
          text: 'Instructions: ' + this.instructions,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Next visit: ' + this.next_txt,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Dr. ' + this.doct_name + " " + this.doc_qualif,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Registration no: ' + this.licence_code,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Find your prescription online at www.tervys.com',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'This is digitally generated prescription',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }
      ],
      styles: {
        headertable: {
          fontSize: 12,
          width: 800,
          marginLeft: 120,
          marginTop: -200
        },
        personaltable: {
          fontSize: 12,
          width: 800,
        },
        diagstyle: {
          fontSize: 12,
          bold: true,
        }
      }
    };
    this.pdfObj = pdfMake.createPdf(docDefinition);

    this.pdfObj.getBuffer((buffer) => {
      var blob = new Blob([buffer], {
        type: 'application/pdf'
      });

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function (this: any) {
        pdffile = reader.result;
        this.Medical_prescription_save(pdffile);
      }.bind(this);
    });
  }

  printnoheaderdf() {
    var tempArr = [];
    var pdffile;
    for (var i = 0; i < this.listProducts.length; i++) {
      var remarks
      if (this.listProducts[i].remarks == undefined) {
        remarks = "";
      } else {
        remarks = this.listProducts[i].remarks
      }
      tempArr.push(
        {
          Medicine: this.listProducts[i].med_typetxt + " " + this.listProducts[i].drug_name,
          Dosage: this.listProducts[i].every_six,
          Duration: this.listProducts[i].days + " " + this.listProducts[i].period,
          Frequency: this.listProducts[i].frequency +" " + this.listProducts[i].intake_mode + " " + this.listProducts[i].dure_txt_table + " " + this.listProducts[i].drug_intake,
          Note: remarks
        }
      );
    }

    if (this.oberse_med == undefined) {
      this.oberse_med = "";
    }
    if (this.instructions == undefined) {
      this.instructions = "";
    }
    if (this.next_txt == undefined) {
      this.next_txt = "";
    }

    let docDefinition = {
      content: [
        {
          style: 'personaltable',
          margin: [0, 150, 0, 0],
          table: {
            widths: [400, 300],
            body: [
              ['Name: ' + this.doct_clientname, 'BP: ' + this.clnt_bp],
              ['', ''],
              ['Age: ' + this.client_age + '     Gender: ' + this.client_gender, ' Pulse: ' + this.clnt_pulse],
            ]
          },
          layout: 'noBorders'
        },
        {
          text: 'Investigation: ' + this.oberse_med,
          margin: [0, 20, 0, 0],
          style: 'diagstyle'
        },
        {
          text: 'Diagnosis: ' + this.dis_txt,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        this.medicinetable(tempArr, ['Medicine', 'Dosage', 'Duration', 'Frequency', 'Note']),
        {
          text: 'Instructions: ' + this.instructions,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Next visit: ' + this.next_txt,
          margin: [0, 20, 0, 20],
          style: 'diagstyle'
        },
        {
          text: 'Dr. ' + this.doct_name + " " + this.doc_qualif,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Registration no: ' + this.licence_code,
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'Find your prescription online at www.tervys.com',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }, {
          text: 'This is digitally generated prescription',
          margin: [0, 5, 0, 5],
          style: 'diagstyle'
        }
      ],
      styles: {
        headertable: {
          fontSize: 12,
          width: 800,
          marginLeft: 120,
          marginTop: -200
        },
        personaltable: {
          fontSize: 12,
          width: 800,
        },
        diagstyle: {
          fontSize: 12,
          bold: true,
        }
      }
    };
    this.pdfObj = pdfMake.createPdf(docDefinition);
    //this.save_data(this.pdfObj);
    this.pdfObj.getBuffer((buffer) => {
      var blob = new Blob([buffer], {
        type: 'application/pdf'
      });

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function (this: any,) {
        pdffile = reader.result;
        this.Medical_prescription_save(pdffile);
      }.bind(this);
    });
  }

  medicinetable(data, columns) {
    return {
      table: {
        widths: [140, 60, 50, 120, 100],
        headerRows: 1,
        body: this.buildTableBody(data, columns)
      }
    };
  }

  buildTableBody(data, columns) {
    var body = [];
    body.push(columns);
    data.forEach(function (row) {
      var dataRow = [];
      columns.forEach(function (column) {
        dataRow.push(row[column]);
      })
      body.push(dataRow);
    });
    return body;
  }

  saveData() {
    if (this.listProducts.length != 0) {
      this.Medical_prescription_save(null);
    }
  }

  Medical_prescription_save(pdffilevalue) {
    var flag = true;
    if (this.listProducts.length == 0) {
      this.toastr.error(Message_data.addMedDetails);
      flag = false;
    }

    var ordernow = this.order_now == true ? "1" : 0;

    if (this.dis_txt.trim() != "") {
      var new_symptoms = [];
      if (this.dis_txt.indexOf(',') > -1) {
        var dataval = this.dis_txt.trim().split(",");
        for (var i = 0; i < dataval.length; i++) {
          if (this.newsymptoms.length != 0) {
            for (var j = 0; j < this.newsymptoms.length; j++) {
              if (dataval[i] != "") {
                if (this.newsymptoms[j] != dataval[i]) {
                  new_symptoms.push(dataval[i]);
                }
              }
            }
          } else {
            if (dataval[i] != "") {
              new_symptoms.push(this.dis_txt.trim());
            }
          }
        }

      } else {
        if (this.newsymptoms.length != 0) {
          for (var j = 0; j < this.newsymptoms.length; j++) {
            if (this.newsymptoms[j] != this.dis_txt.trim()) {
              new_symptoms.push(this.dis_txt.trim());
            }
          }
        } else {
          new_symptoms.push(this.dis_txt.trim());
        }
      }

    } else {
      this.dis_txt = "";
    }

    if (flag == true) {
      this.saveflag = false;
      var sen_pass;

      sen_pass = JSON.stringify({
        disease: this.dis_txt,
        investigation: this.oberse_med,
        instructions: this.instructions,
        next_visit: this.next_datetxt,
        medicines: this.listProducts,
        pharmacy_id: this.pharma_id,
        order_now: ordernow,
        language: this.language,
        pdfip: ipaddress.Ip_with_img_address,
        print: this.printpres,
        newsymptoms: new_symptoms,
        doc_reg_id: this.user_id,
        pres_drug_id: this.presID.pres_id,
        book_now: this.book_app_now,
        hptl_clinic_id: this.hospital_clinic_id,
        time: "1",
        req_estimate: this.req_estimate,
        country: ipaddress.country_code,
      });
      console.log("data check --" + JSON.stringify(sen_pass))
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'prescription/updpres/', sen_pass,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            Doc_Helper.setMedForDischarge(this.listProducts);
            this.print_med = true;
            if (obj.status != null && obj.status == "1") {
              this.listProducts = [];
              this.toastr.success(Message_data.prescriptionSavedSuccess);
              this.naviagationService.sendMessage('prescriptionlist')

            } else {
              this.saveflag = true;
              this.toastr.error(Message_data.unableToSavePrescription);
            }
          },
          error => {
          }
        )
    }
  }

  updatePres() {
    var sen_pass = {
      pres_drug_id: Helper_Class.get_med_pres_id(),
      inpatient_id: this.personalinfo.inpatient_id
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/udsp/', sen_pass,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.key != "0") {
            this.toastr.success("Prescription updated successfully");
            Doc_Helper.setAdmission_id(null)
            var send = {
              admission_id: this.personalinfo.app_id,
              flag: "edit"
            }
            Doc_Helper.setAdmission_id(send);
            this.naviagationService.sendMessage("inPatientDS");
          }

        },
        error => { }
      )
  }

  save_data() {
    var flag;

    if (this.dis_txt.trim() != "") {
      this.dis_txt = this.dis_txt.trim();
      var new_symptoms = [];
      if (this.dis_txt.indexOf(',') > -1) {
        var dataval = this.dis_txt.trim().split(",");
        for (var i = 0; i < dataval.length; i++) {
          if (this.newsymptoms.length != 0) {
            for (var j = 0; j < this.newsymptoms.length; j++) {
              if (dataval[i] != "") {
                if (this.newsymptoms[j] != dataval[i]) {
                  new_symptoms.push(dataval[i]);
                }
              }
            }
          } else {
            if (dataval[i] != "") {
              new_symptoms.push(this.dis_txt.trim());
            }
          }
        }

      } else {
        if (this.newsymptoms.length != 0) {
          for (var j = 0; j < this.newsymptoms.length; j++) {
            if (this.newsymptoms[j] != this.dis_txt.trim()) {
              new_symptoms.push(this.dis_txt.trim());
            }
          }
        } else {
          new_symptoms.push(this.dis_txt.trim());
        }
      }

    } else {
      this.dis_txt = "";
    }

    if (flag == true && this.listProducts.length != 0) {
      // both medical and dig datas save
      Doc_Helper.setMedDiag(true);
      if (this.obs_diag != undefined) {
        this.observation = this.obs_diag;
      }
      else {
        this.observation = "";
      }
      if (this.test_now == true) {
        this.book_app = "1";
      }
      else {
        this.book_app = "0";
      }

      var ordernow;
      if (this.order_now == true) {
        ordernow = "1";

      } else {
        ordernow = 0;
      }

      var sen_pass;
      if (this.personalinfo.sub_id.length != 0) {
        sen_pass = JSON.stringify({
          client_reg_id: this.personalinfo.Client_id,
          relation_id: this.personalinfo.rel_id,
          sub_rel_id: this.personalinfo.sub_id,
          appointment_id: this.personalinfo.app_id,
          date: this.CurrentDatetime,
          disease: this.dis_txt,
          investigation: this.oberse_med,
          instructions: this.instructions,
          next_visit: this.next_datetxt,
          medicines: this.listProducts,
          pharmacy_id: this.pharma_id,
          order_now: ordernow,
          language: this.language,
          // pdfdoc: pdffilevalue,
          pdfip: ipaddress.Ip_with_img_address,
          print: this.printpres,
          newsymptoms: new_symptoms,
        });

      } else {
        sen_pass = JSON.stringify({
          client_reg_id: this.personalinfo.Client_id,
          relation_id: this.personalinfo.rel_id,
          appointment_id: this.personalinfo.app_id,
          date: this.CurrentDatetime,
          disease: this.dis_txt,
          comment: this.oberse_med,
          investigation: this.oberse_med,
          instructions: this.instructions,
          next_visit: this.next_datetxt,
          medicines: this.listProducts,
          pharmacy_id: this.pharma_id,
          order_now: ordernow,
          language: this.language,
          // pdfdoc: pdffilevalue,
          pdfip: ipaddress.Ip_with_img_address,
          print: this.printpres,
          newsymptoms: new_symptoms,
        });
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'prescription/savepres/', sen_pass,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            var flag_diag = true;
            if (this.subtestuname.length != 0) {
              flag_diag = true;
            } else if (this.subtestfname.length != 0) {
              flag_diag = true;
            } else if (this.subtestname.length != 0) {
              flag_diag = true;
            } else if (this.subtestsname.length != 0) {
              flag_diag = true;
            } else if (this.subtestxname.length != 0) {
              flag_diag = true;
            } else if (this.subtesturname.length != 0) {
              flag_diag = true;
            } else if (this.subtestbname.length != 0) {
              flag_diag = true;
            } else if (this.subtestcname.length != 0) {
              flag_diag = true;
            } else {
              flag_diag = false;
            }

            if (flag_diag == true) {
              //this.Diagnosis_prescription_save();
            } else {
              if (this.home_care != "0" && this.home_care != undefined) {
                if (obj["pres_id"] != null) {
                  Helper_Class.set_med_pres_id(obj["pres_id"]);
                  if (this.personalinfo.AppFlow == "Doc_app_list") {
                    if (Doc_Helper.getDiagAppflow() == 'medical') {
                      Doc_Helper.setMedSave(true);
                      this.listProducts = [];
                      //this.appmedpresList();
                      this.addmed = false;
                      this.messageservice.sendDiagComponent("save_med");

                    } else {
                      Doc_Helper.setMedDiag(true);
                      this.subtestbname = [];
                      this.subtestfname = [];
                      this.subtestname = [];
                      this.subtestsname = [];
                      this.subtestuname = [];
                      this.subtesturname = [];
                      this.subtestxname = [];
                      this.subtestcname = [];
                      this.messageservice.sendDiagComponent("save_diag");
                    }
                    this.saveflag = true;
                    this.toastr.success(Message_data.prescriptionSavedSuccess);

                  } else {
                    this.toastr.success(Message_data.prescriptionSavedSuccess);
                    this.addmed = false;
                  }

                } else {
                  this.saveflag = true;

                  this.toastr.error(Message_data.unableToSavePrescription);
                }

              } else {
                Helper_Class.set_med_pres_id(null);
                if (obj["pres_id"] != undefined && obj["pres_id"] != null) {
                  Helper_Class.set_med_pres_id(obj["pres_id"]);
                  this.diab_medpres = obj["pres_id"];

                  if (this.personalinfo.AppFlow == "Doc_app_list") {
                    if (Doc_Helper.getDiagAppflow() == 'medical') {
                      Doc_Helper.setMedSave(true);
                      this.listProducts = [];
                      //this.appmedpresList();
                      this.addmed = false;
                      this.messageservice.sendDiagComponent("save_med");
                    } else {
                      Doc_Helper.setMedDiag(true);
                      this.subtestbname = [];
                      this.subtestfname = [];
                      this.subtestname = [];
                      this.subtestsname = [];
                      this.subtestuname = [];
                      this.subtesturname = [];
                      this.subtestxname = [];
                      this.subtestcname = [];
                      this.messageservice.sendDiagComponent("save_diag");
                    }
                    this.saveflag = true;
                    this.toastr.success(Message_data.prescriptionSavedSuccess);

                  } else if (this.personalinfo.AppFlow == "Walkin") {
                    if (Doc_Helper.getDiagAppflow() == 'medical') {
                      Doc_Helper.setMedSave(true);
                      this.listProducts = [];
                      //this.appmedpresList();
                      this.addmed = false;
                      this.messageservice.sendDiagComponent("save_med");
                    } else {
                      Doc_Helper.setMedDiag(true);
                      this.subtestbname = [];
                      this.subtestfname = [];
                      this.subtestname = [];
                      this.subtestsname = [];
                      this.subtestuname = [];
                      this.subtesturname = [];
                      this.subtestxname = [];
                      this.subtestcname = [];
                      this.messageservice.sendDiagComponent("save_diag");
                    }
                    this.saveflag = true;
                    this.toastr.success(Message_data.prescriptionSavedSuccess);

                  } else {
                    this.toastr.success(Message_data.prescriptionSavedSuccess);
                    this.addmed = false;
                  }

                  var dat = this.check_pres;
                  if (dat == "0") {
                    this.check_pres = "1";

                  } else {
                    var seconds = 1;
                    setInterval(function () {
                      seconds--;

                    }, 500);
                  }
                }
                else {
                  this.saveflag = true;
                  this.toastr.error(Message_data.unableToSavePrescription);
                }
              }
            }
          },
          error => {
          }
        )
    } else if (Doc_Helper.getDiagAppflow() == 'diag') {
      //this.Diagnosis_prescription_save();
    } else if (Doc_Helper.getDiagAppflow() == 'medical') {
      this.savePrescription('noprint');
    }
  }
  changecomplaints = (event) => {
    this.complaints = event.html;
    if (this.complaints.length > 250) {
      this.complaints = this.complaints.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };
  changediagnosis = (event) => {
    this.dis_txt = event.html;
    if (this.dis_txt.length > 250) {
      this.dis_txt = this.dis_txt.substring(0, 250);
      console.log(`Content truncated to  characters.`);
    }
  };
  concernformprint(value){
    this.highRiskPresent=true;
    this.print_area(value)
   
  }
  getLeftPosition(index: number): number {
    return 5 + index * 1; // Adjust the multiplier (15) for spacing between squares
  }

  Noofdays(date) {
   
    this.DATE = parseInt(date);
    this.currentDate = moment().format('YYYY-MM-DD');
    this.next_txt = moment().add(this.DATE, 'days').format('YYYY-MM-DD');
  //  alert(this.next_txt)
    this.next_datetxt = moment().add(this.DATE, 'days').format('YYYY-MM-DD');
    // this.next_datetxt_diag = moment().add(this.DATE, 'days').format('YYYY-MM-DD');
  //  this.no_of_days_diag = (date);
  }
}
