<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Receivable report</h5>
          </div>
          <div lass="col-12 col-sm-6 col-md-4 col-xl-4 col-lg-4">
            <mat-label class="matlabel">Sort</mat-label>
            <mat-select class="ipcss" [(ngModel)]="selected_filter" style="width: 200px;" (selectionChange)="getReceivablesData()">
             
              <!-- <mat-option  value="supplier">Store Name</mat-option> -->
              <mat-option  value="supplier" selected>Suppliers</mat-option>
              <mat-option value="po_no">PO No</mat-option>
              <mat-option  value='po_date'>Po Date</mat-option>
              <mat-option  value='received_date'>Received date</mat-option>
              <mat-option value="invoice_no">Invoice No</mat-option>
              <mat-option value="invoice_date">Invoice date</mat-option>
              <mat-option value="gr_no">GRN NO</mat-option>
              <mat-option value="gr_date">GRN date</mat-option>
              <mat-option value="invoice_amount">Invoice amount </mat-option>
              <mat-option value="status">Status </mat-option>
            </mat-select> 
          </div>
          <div class="headerButtons" *ngIf="receiveData.length != 0">
            <!-- <img src="../../../assets/ui_icons/buttons/back_icon.svg" style="width:30px;margin-right: 5px;"
            (click)="todisplaycoverdiv()" *ngIf="backbutton" /> -->
          <a style="margin:0 5px;" (click)="print_area()"><img src="../../../assets/img/printer.svg"
              class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;" *ngIf="backbutton" /></a>
          <img src="../../../assets/ui_icons/buttons/XL_export.svg" class="saveimgbtn_inpatinfo"
            (click)="getExcelData('excel')" style="margin-right:10px" *ngIf="backbutton" />
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body class="card_body_padding">
        <div class="cover_div" style="padding-bottom: 13px;">
          <!-- <div class="header_lable">
            Receivable details
          </div> -->
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-xl-2 col-lg-2">
                <mat-label class="matlabel">Type<br>
                  <select disableOptionCentering class="ipcss " required [(ngModel)]="type"
                    (change)="changeProductType()">
                    <option value="all" selected>All</option>
                    <option value="supplier">Supplier</option>
                    <option value="product">Product</option>
                  </select>
                </mat-label>
              </div>
             
              <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3" *ngIf="productFlag">
                <mat-label class="matlabel">Product name<br>  
                  <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
                  (keyup)="changeMedicineName($event)" maxlength="50" [(ngModel)]="productName" matInput
                  [matAutocomplete]="auto2" />
                <mat-autocomplete #auto2="matAutocomplete" [panelWidth]="'auto'">
                  <mat-option (click)="selectMedicineItem(medicine)" *ngFor="let medicine of MedicneData"
                    value="{{medicine.name}}">
                    <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">{{medicine.name}}</div> 
                  </mat-option>
                </mat-autocomplete>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3" *ngIf="suplierFlag">
                <mat-label class="matlabel">Supplier name<br>  
                  <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
                  (keyup)="changeSupplier($event)" maxlength="50" [(ngModel)]="supllierName" matInput
                  [matAutocomplete]="auto3" />
                <mat-autocomplete #auto3="matAutocomplete" [panelWidth]="'auto'">
                  <mat-option (click)="selectSupplierName(supplier)" *ngFor="let supplier of supplierArray"
                    value="{{supplier.name}}"> <div>{{supplier.name}}</div> 
                  </mat-option>
                </mat-autocomplete>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <mat-label class="matlabel" style="margin-top: 7px;">PO from
                  <input type="date" class="ipcss_date " (change)="dateSelect(fromDate,'1')" [(ngModel)]="fromDate"
                    max="{{currentDate}}" #matInput>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <mat-label class="matlabel" style="margin-top: 7px;">PO to
                  <input type="date" class="ipcss_date " (change)="dateSelect(toDate,'2')" [(ngModel)]="toDate"
                    max="{{currentDate}}" #matInput>
                </mat-label>
              </div>

              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <mat-label class="matlabel"><br>
                  <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                    (click)="getReceivablesData()" />
                </mat-label>
              </div>
            </div>
          </div>
        </div>
        <p class="nodata" *ngIf="receiveData.length == 0">No records found</p>
        <div class="row" *ngIf="receiveData.length != 0">
          <div class="col-12" style="margin-top: 10px;">

            <div [hidden]="true">
              <table style="margin-left:10px;margin-right: 10px;" #printimage id="printimage">
                <tr>
                  <td>
                    <img alt="image" src={{hospital_logo}} style="width: 95%; height: 130px;">
                  </td>
                </tr>
              </table>
              <div style="position: relative;top: 20px;left: 10px;text-align: left;" #hospitalwithnamelogo
                id="hospitalwithnamelogo">
                <span><b>{{hosp_name}}</b>,</span><br />
                <span *ngIf="hosp_addr != ''">{{hosp_addr}}</span><br />
                <span>{{hosp_csz}}</span><br />
                <span *ngIf="hosp_tel != undefined"><img src="../../../assets/img/phone.png" width="15px" height="15px" />{{hosp_tel}}.</span><br>
              </div>
            </div>

            <div style="overflow-y:scroll" class="table-responsive dig_table_overflow" *ngIf="receiveData.length !=0" >
              <mat-accordion displayMode="flat" multi="false" class="mat-table">
                <section matSort class="mat-elevation-z2 mat-header-row" style="background-color: #c1e6fb;">
                  <span class="mat-header-cell" *ngIf="columns.store_name">Store name</span>
                  <span class="mat-header-cell" *ngIf="columns.supplier">Supplier</span>
                  <span class="mat-header-cell" *ngIf="columns.po_no" style="text-align: center;">PO no</span> 
                  <span class="mat-header-cell" *ngIf="columns.podate_time">PO date/time</span>
                  <span class="mat-header-cell" *ngIf="columns.received_date" style="text-align: center;">Received date</span>
                  <span class="mat-header-cell" *ngIf="columns.invo_no" style="text-align: center;">Invoice no</span>
                  <span class="mat-header-cell" *ngIf="columns.invo_date">Invoice date</span>
                  <span class="mat-header-cell" *ngIf="columns.gr_no">Gr no</span>
                  <span class="mat-header-cell" *ngIf="columns.gr_date">Gr date</span>
                  <span class="mat-header-cell" *ngIf="columns.invoice_amount">Invoice amount</span>
                </section>
                <mat-expansion-panel *ngFor="let receive of groupedarray">
                  <mat-expansion-panel-header class="mat-row">
                    <span class="mat-cell"  *ngIf="columns.store_name">{{receive.store_name}}</span>
                    <span class="mat-cell"  *ngIf="columns.supplier">{{receive.supName}}</span>
                    <span class="mat-cell" *ngIf="columns.po_no" style="text-align: center;">{{receive.po_code}}</span>
                    <span class="mat-cell" *ngIf="columns.podate_time">{{receive.poDate}}  {{receive.potime}}</span>
                    <span class="mat-cell" *ngIf="columns.received_date" style="padding-left: 20px;">{{receive. received_date}} {{receive. received_time}}</span>
                    <span class="mat-cell" *ngIf="columns.invo_no" style="text-align: center;">{{receive.invoice_no}}</span>
                    <span class="mat-cell" *ngIf="columns.invo_date">{{receive.invoice_date}} </span>
                    <span class="mat-cell" *ngIf="columns.gr_no">{{receive.gr_no}}</span>
                    <span class="mat-cell" *ngIf="columns.gr_date">{{receive.gr_date}} {{receive.gr_time}}</span>
                    <span class="mat-cell" *ngIf="columns.invoice_amount" style="text-align: right;padding-right: 50px !important;">{{receive.invoice_amount}}</span>
                  </mat-expansion-panel-header>
                  <div style="display:flex">
                    <table>
                      <thead>
                        <tr>
                          <th *ngIf="columns.product_name">Product Name</th>
                          <th *ngIf="columns.po_quantity">PO qty</th>
                          <th *ngIf="columns.receive_qty">Received qty</th>
                          <th *ngIf="columns.free_qty">Free qty</th>
                          <th>Batch no</th>
                          <th>Expiry date</th>
                          <th *ngIf="columns.gst">GST</th>
                          <!-- <th *ngIf="columns.pending_qty">On Hand</th> -->
                          <th *ngIf="columns.cp">Cost price</th>
                          <th *ngIf="columns.sp">Sales price</th>
                          <th *ngIf="columns.mrp">MRP</th>
                          <th  *ngIf="columns.discount">Discount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngIf="receive.receivable_prod.length === 0; else productData">
                        <tr>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        </ng-container>
                        <ng-template #productData>
                          <tr *ngFor="let item of receive.receivable_prod">
                            <td>{{ item.product_name || '-' }}</td>
                            <td>{{ item.po_qty || '-' }}</td>
                            <td>{{ item.qty_received || '-' }}</td>
                            <td>{{ item.free_qty || '-' }}</td>
                            <td>{{item.batch_no}}</td>
                            <td>{{item.expiry_date}}</td>
                            <td>{{ item.gst || '-' }}</td>
                            <!-- <td>{{ item.balance || '-' }}</td> -->
                            <td>{{ item.costPrice || '-' }}</td>
                            <td>{{ item.salsePrice || '-' }}</td>
                            <td>{{ item.mrp || '-' }}</td>
                            <td>{{ item.discount || '-' }}</td>
                          </tr>
                        </ng-template>
                      </tbody>
                    </table>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              
              <table #tbl id="tbl" hidden class="table table-hover table-dynamic" style="width:120%"
              style="font-size: 12px;margin-left: 5px;">
              <thead style="background-color:#c1e6fb;position: sticky;top: 0">
                <tr>
                  <th *ngIf="columns.store_name">Store name</th> 
                  <th *ngIf="columns.supplier">Supplier</th> 
                  <th *ngIf="columns.po_no">PO no</th>
                  <th *ngIf="columns.podate_time">PO date/time</th>
                  <th *ngIf="columns.received_date">Received date</th>
                  <th *ngIf="columns.invo_no">Invoice no</th>
                  <th *ngIf="columns.invo_date">Invoice date</th>
                  <th *ngIf="columns.gr_no">Gr no</th>
                  <th *ngIf="columns.gr_date">Gr date</th>
                  <th  *ngIf="columns.product_name">Product name</th>
                  <th  *ngIf="columns.batch_no">Batch no</th>
                  <th  *ngIf="columns.expiry_date">Expiry date</th>
                  <th *ngIf="columns.po_quantity">PO qty</th>
                  <th *ngIf="columns.receive_qty">Received qty</th>
                  <th *ngIf="columns.gst">GST</th>
                  <th *ngIf="columns.pending_qty">On Hand</th>
                  <th *ngIf="columns.cp">Cost price</th>
                  <th *ngIf="columns.sp">Sales price</th>
                  <th *ngIf="columns.mrp">MRP</th>
                  <th  *ngIf="columns.discount">Discount</th>
                  <th  *ngIf="columns.invoice_amount">Invoice amount</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let receive of receiveData">
                  <td  *ngIf="columns.store_name" class="align_left">{{receive.store_name}}</td>
                  <td  *ngIf="columns.supplier" class="align_left">{{receive.supName}}</td>
                  <td *ngIf="columns.po_no">{{receive.po_code}}</td>
                  <td *ngIf="columns.podate_time">{{receive.poDate}}  {{receive.potime}}</td>
                  <td *ngIf="columns.received_date">{{receive. received_date}}</td>
                  <td *ngIf="columns.invo_no">{{receive.invoice_no}}</td>
                  <td *ngIf="columns.invo_date">{{receive.invoice_date}} </td>
                  <td *ngIf="columns.gr_no">{{receive.gr_no}}</td>
                  <td *ngIf="columns.gr_date">{{receive.gr_date}} </td>
                  <td *ngIf="columns.product_name" class="align_left">{{receive.product_name}}</td>
                  <td *ngIf="columns.batch_no" class="align_left">{{receive.batch_no}}</td>
                  <td *ngIf="columns.expiry_date" class="align_left">{{receive.expiry_date}}</td>
                  <td *ngIf="columns.po_quantity">{{receive.po_qty}}</td>
                  <td *ngIf="columns.receive_qty">{{receive.qty_received}}</td>
                  <td *ngIf="columns.gst">{{receive.gst}}</td>
                  <td *ngIf="columns.pending_qty">{{receive.balance}}</td>
                  <td *ngIf="columns.cp">{{receive.costPrice}}</td>
                  <td *ngIf="columns.sp">{{receive.salsePrice}}</td>
                  <td *ngIf="columns.mrp">{{receive.mrp}}</td>
                  <td *ngIf="columns.discount">{{receive.discount}}</td>
                  <td *ngIf="columns.invoice_amount">{{receive.invoice_amount}}</td>
                </tr>
              </tbody>
            </table>

            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>