<div class="row" style="width: 100%; margin: 0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Purchase request</h5>
          </div>
          <div class="headerButtons">

            <img *ngIf="frompurchasereq == 'frompurchasereq'" src="../../../assets/ui_icons/buttons/Back_button.svg"
              class="saveimgbtn_inpatinfo" (click)="previous_purchasereqlist()">

            <img *ngIf="saveFlag" src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo"
              (click)="requestSave()" alt="">
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Department<br>
                  <mat-select disableOptionCentering class="ipcss " [(ngModel)]="prdepartname"
                    (selectionChange)="prbaseon_departement($event)" [disabled]="departmentlength">

                    <mat-option *ngFor="let department of departmentarray"
                      value={{department.department_id}}>{{department.description}}</mat-option>

                  </mat-select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Store name<br>
                  <mat-select class="ipcss " [(ngModel)]="prstorename">

                    <mat-option *ngFor="let stro of storearray" placeholder={{stro.store_desc}}
                      value={{stro.store_names_id}}>{{stro.store_desc}}</mat-option>

                  </mat-select>
                </mat-label>
              </div>



              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Supplier<br>
                  <input type="text" class="ipcss widthappt" required maxlength="50" (keyup)="getVendor()"
                    maxlength="50" [(ngModel)]="vendor" matInput [matAutocomplete]="auto1" />
                  <mat-autocomplete #auto1="matAutocomplete">
                    <mat-option (click)="selected_vendor(vendor)" *ngFor="let vendor of pharmacyVendorArray"
                      value={{vendor.name}}>
                      {{vendor.name}}
                    </mat-option>
                  </mat-autocomplete>


                </mat-label>
              </div>



              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Product type<br>
                  <select disableOptionCentering class="ipcss widthappt" [(ngModel)]="productType">
                    <option *ngFor="let pro_type of productTypeList" value={{pro_type.med_code}}>
                      {{pro_type.med_name}}</option>
                  </select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Product name<br>
                  <input type="text" class="ipcss widthappt" required maxlength="50" (keyup)="getProductDetails()"
                    maxlength="50" [(ngModel)]="productName" matInput [matAutocomplete]="auto" />
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option (click)="getProductName(prod)" *ngFor="let prod of productArray" value={{prod.name}}>
                      {{prod.name}} {{prod.reorder_level}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">UOM
                  <input matInput class="ipcss widthappt" [(ngModel)]="uom" type="text" disabled="true">
                </mat-label>
              </div>

              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <!-- <mat-label class="matlabel">Quantity
                  <input matInput class="ipcss widthappt" [(ngModel)]="quantity" required maxlength="5" type="text">
                </mat-label> -->
                <mat-label class="matlabel">Quantity<br>
                  <input type="text" class="ipcss widthappt" required [(ngModel)]="quantity"
                  matInput list="perunit">
               <datalist [(ngModel)]="quantity"  id="perunit">
                  <option  *ngFor="let unit of perunit" value={{unit.perunit_desc}} >
                      {{unit.perunit_desc}}
                  </option>
               </datalist>
              </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label class="matlabel">price
                  <input matInput class="ipcss widthappt" [(ngModel)]="price" type="text" disabled="true">
                </mat-label>
              </div>

              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                <mat-label>
                  <img src="../../../assets/ui_icons/buttons/add_button.svg" (click)="purchaseRequest()"
                    class="saveimgbtn_inpatinfo " style="margin: 20px 0;">
                </mat-label>
              </div>



            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12 col-sm-12 col-xl-12 col-lg-12">
            <table *ngIf="purchaseReq.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable billcreae_table">
              <thead class="tableheading">
                <tr>
                  <th>Supplier</th>
                  <th>Product</th>
                  <th> UOM </th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <!-- <th>Amount</th> -->

                  <th *ngIf="deletebutton">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let person of purchaseReq  let i = index" class="tdvalues">
                  <td style="text-align: left !important;">{{person.mfg_name}}</td>
                  <td style="text-align: left !important;">{{person.product_name}}</td>
                  <td>{{person.uom}}</td>
                  
                  <td>{{person.quantity}}</td>
                  <td>{{person.price}}</td>
                  <!-- <td>{{person.amount}}</td> -->

                  <td *ngIf="deletebutton">
                    <img class="deleteimg" (click)="edit(i)" src="../../../assets/ui_icons/buttons/trash_icon.svg" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>