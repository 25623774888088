<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white;">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">
                     Receivables
                        </h5>
                    </div>
                    <div class="headerButtons">
                        
                        <img style="width:85px" src="../../../assets/ui_icons/buttons/xl_import.svg" (click)="importFromExcel()"
                             alt="">

                        <img *ngIf="this.inventory.length != 0" style="width:85px" src="../../../assets/ui_icons/buttons/update_button.svg" (click)="save_data()"/>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="cover_div" style="padding-bottom: 13px;">
                    <div class="header_lable">
                        Inventory details
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Supplier / distributor name<br>
                                 
                                    <input type="text" class="ipcss widthappt" required [(ngModel)]="manufacturer"
                                    matInput list="dropValues" (input)="supplier_changee()" >
                                 <datalist [(ngModel)]="manufacturer"  id="dropValues" (change)="supplier_change('')" clickable>
                                    
                                    <option type="button"  *ngFor="let supplier of supplierList" value={{supplier.name}}  (click)="supplier_change(supplier)">
                                        {{supplier.name}}
                                    </option>
                                 </datalist>
                                </mat-label> 
                            </div> -->

                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" >
                                <mat-label class="matlabel" [hidden]="supplierflag">Supplier / distributor name<br>
                                    <input type="text" class="ipcss widthappt"   required [(ngModel)]="manufacturer" 
                                        matInput list="dropValues" (change)="supplier_changee()"  (click)="clearSupplier()">
                                    <datalist [(ngModel)]="manufacturer" id="dropValues" clickable>
                                        <option type="button" *ngFor="let supplier of supplierList"  value={{supplier.name}}>
                                            {{supplier.name}}
                                        </option>
                                    </datalist>
                                </mat-label>
                               
                               <mat-label [hidden]="inputsupplierflag" class="matlabel">Supplier / distributor name<br>
                                <input type="text" class="ipcss widthappt"    [(ngModel)]="manufacturer" disabled >
                                </mat-label>
                            </div>


                           
                            <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <mat-label class="matlabel" style="margin-top: 7px;">Purchase Order<br>
                                    <mat-select disableOptionCentering class="ipcss " [(ngModel)]="poID" (change)="getPurchaseDetails();getsupplierid()">
                                        <mat-option disabled>Select</mat-option>
                                        Select all
                                        <input type="checkbox" [(ngModel)]="filtercheckbox" [checked]="multicheck" style="width:14px;height:14px;margin-left:150px" (change)="checkboxfunction()" >
                                        <mat-option *ngFor="let po of product_data" value={{po.purchase_order_id}} >
                                            <input type="checkbox"  [(ngModel)]="po.checked"
                                            name="list_name" value="{{po.purchase_order_id}}"
                                            (click)="isAllSelectedPO(po,po.checked)">
                                            {{po.purchase_order_id}} </mat-option>
                                    </mat-select>
                                </mat-label>
                            </div> -->



                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <mat-label class="matlabel">Purchase Order</mat-label>
                                <mat-select class="ipcss" multiple [(ngModel)]="poID"
                                (change)="getPurchaseDetails();getsupplierid()">
                                  <span style="display:inline-block;padding:10px">Select all</span>
                                  <input type="checkbox" [(ngModel)]="filtercheckbox" [checked]="multicheck" style="width:14px;height:14px;margin-left:150px" (change)="checkboxfunction()" >
                                  <mat-option *ngFor="let po of product_data" value={{po.purchase_order_id}}  (click)="isAllSelectedPO(po,po.checked)">
                                    {{po.purchase_order_id}} </mat-option>
                                </mat-select>
                              </div>
                            

                            
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <mat-label class="matlabel" style="margin-top: 7px;">Invoice No<br>
                                    <input type="text" class="ipcss " [(ngModel)]="invoiceNO" required maxlength="25"
                                        matInput />
                                </mat-label>
                            </div>


                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <mat-label class="matlabel" style="margin-top: 7px;">Invoice date<br>
                                    <input type="date" class="ipcss " [(ngModel)]="invDate" max="{{currentDate}}" disable="true" matInput />
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <mat-label class="matlabel" style="margin-top: 7px;">Invoice amount<br>
                                    <input type="text" class="ipcss " [(ngModel)]="actual_invoiceAmount" required maxlength="10"
                                        matInput/>
                                </mat-label>
                            </div>
                        
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">GST number<br>
                                    <input type="text" class="ipcss" readonly [(ngModel)]="gst_no" disable="true"
                                        matInput />
                                </mat-label>
                            </div>
                            
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <mat-label class="matlabel" style="margin-top: 7px;">Received date<br>
                                    <input type="date" class="ipcss " [(ngModel)]="recvDate" disable="true" matInput />
                                </mat-label>
                            </div>

                            <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                               
                            </div> -->

                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" >
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-5 col-xl-5">
                                        <mat-label class="matlabel" style="margin-top: 7px"> Discount type <br>
                                            <mat-select class="ipcss" [(ngModel)]="discount_option" >
                                                <mat-option value="select" (click)="updateTotalCost()">Select</mat-option> 
                                                <mat-option value="product_wise" (click)="isDiscountOptionSelected(discount_option)">Product wise</mat-option>
                                                <mat-option value="percentage" (click)="isDiscountOptionSelected(discount_option)">Percentage</mat-option>
                                                <!-- <mat-option value="price" (click)="isDiscountOptionSelected(discount_option)">Value</mat-option> -->
                                            </mat-select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-5 col-xl-5" *ngIf="discountpercentageflag">
                                        <mat-label class="matlabel"  > Percentage <br>
                                            <input type="text" class="ipcss " (keyup)="discountcal()" [(ngModel)]="Discount_value" disable="true" matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-5 col-xl-5" *ngIf="this.discountpriceflag">
                                        <mat-label class="matlabel" > Discount value <br>
                                            <input class="ipcss widthappt" [(ngModel)]="discountamount" (keyup)="discountcal()">
                                        </mat-label>
                                    </div>
                                    
                                </div>
                                
                                <!-- <mat-label class="matlabel" style="margin-top: 7px;">Discount<br>
                                    <input type="text" class="ipcss " [(ngModel)]="Discount_value" disable="true" matInput />   
                                </mat-label> -->
                            
                                <!-- <input type="radio" id="option1" name="options" [(ngModel)]="discount_option" value="percentage" (change)="isDiscountOptionSelected(discount_option)">
                                &nbsp;&nbsp;<label for="option1" style="margin-top:9px">Discount percentage</label>
  

                                <mat-label class="matlabel" *ngIf="discountpercentageflag" >
                                    <input type="text" class="ipcss " style="width:40px" (keyup)="discountcal()" [(ngModel)]="Discount_value" disable="true" matInput />
                                </mat-label>


                                <input type="radio" id="option2" name="options" style="margin-left:20px" [(ngModel)]="discount_option" value="price" (change)="isDiscountOptionSelected(discount_option)">
                                &nbsp;&nbsp;<label for="option2" style="margin-top:9px">Discount value</label>
                              
                                <input type="radio" id="option2" name="options" style="margin-left:20px" [(ngModel)]="discount_option" value="product_wise" (change)="isDiscountOptionSelected(discount_option)">
                                &nbsp;&nbsp;<label for="option2" style="margin-top:9px">product wise discount</label> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- [hidden]="inventory.length == 0"  -->
                <div class="row" style="margin-top:15px">
                    <div class="col-12">
                        <!-- *ngIf="newbutton" -->
                        <!-- <img  style="float:right;" class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg"
                            (click)="addInventory()" /> -->
                        <img style="float:right;" class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/Add_icon.svg"
                            (click)="addInventory()" />
                    </div>
                </div>
                <div class="row" style="margin-top:15px;padding: 0 10px 0 10px;">
                    <div [hidden]="inventory.length == 0" class="dig_table_overflow">
                        <div class="table-responsive">
                            <!--  -->
                            <!--   -->
                            <table id="tbl" class="table table-hover table-dynamic" style="font-size: 11px;">
                                <thead>
                                    <tr>
                                        <th>S.no</th>
                                        <th class="header">Product name</th>
                                        <!-- <th> Package</th> -->
                                        <th class="header">Per unit</th>
                                        <th class="header">Batch no</th>
                                        <!-- <th>UOM</th> -->
                                        <th class="header">Req qty </th>
                                        <th class="header">Free qty </th>
                                        <th class="header">CP</th>
                                        <th class="header">SP</th>
                                        <th class="header">MRP</th>
                                        <th class="header">CGST</th>
                                        <th class="header">SGST</th>
                                        <th class="header">Discount</th>
                                        <th class="header">Mfg date</th>
                                        <!-- <th>Receive date</th> -->
                                        <th class="header">Expiry date</th>
                                        <th class="header"> Action </th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    <tr *ngFor="let data of inventory">
                                        <td>{{data.sno}}</td>
                                        <td *ngIf="data.type == 'noinputbox'">{{data.product_name}}</td>
                                        <td *ngIf="data.type =='input'">
                                            <!-- <input type="text" class="ipcss pharma_invt_width"  [(ngModel)]="data.product_name" maxlength="15"  required>  -->
                                            <input type="text" class="ipcss order_width getMedcien" maxlength="50"
                                            (keyup)="changeMedicineName($event,'medicine')" maxlength="50"
                                            [(ngModel)]="data.product_name" matInput [matAutocomplete]="autoINV" />
                                            <mat-autocomplete #autoINV="matAutocomplete" [panelWidth]="'auto'">
                                                <mat-option (click)="selectMedicineItem(medicine,data)"
                                                *ngFor="let medicine of MedicneData" value="{{medicine.name}}">
                                                <div [ngStyle]="{'color' : medicine.quantity == '0' ? '#FFA07A' : '#000' }">
                                                    {{medicine.name}}</div>
                                                </mat-option>
                                            </mat-autocomplete>
                                        </td>
                                        <td>
                                            <input type="text" class="ipcss pharma_invt_width"  [(ngModel)]="data.perunit" maxlength="15" required>
                                        </td>
                                        <td>
                                            <!-- <input type="text" class="ipcss pharma_invt_width"  [(ngModel)]="data.batch_no" maxlength="15" (change)="batchNo(data)" required> -->
                                            <input type="text" class="ipcss pharma_invt_width"  [(ngModel)]="data.batch_no" [matAutocomplete]="autobatch" 
                                             maxlength="15" (keyup)="getbatchnos(data)" (change)="batchNo(data)" required>
                                            <mat-autocomplete #autobatch="matAutocomplete" [panelWidth]="'auto'" >
                                                <mat-option *ngFor="let batch of batchno_array" value="{{batch}}" (click)="getPriceDetails(data)">
                                                    {{batch}}
                                                </mat-option>
                                            </mat-autocomplete>

                                        </td>
                                        
                                        <td><input type="text" class="ipcss pharma_invt_width"  [(ngModel)]="data.quantity" required (input)="cost_price(data)" maxlength="4" onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput />
                                        </td>
                                        <td><input type="text" class="ipcss pharma_invt_width"
                                                [(ngModel)]="data.free_qty" maxlength="4"
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                matInput />
                                        </td>
                                        
                                        <td><input type="text" class="ipcss pharma_invt_width" [(ngModel)]="data.cost_price" (input)="cost_price(data)" (keyup)="discountcal()" required></td>
                                        <td><input type="text" class="ipcss pharma_invt_width" [(ngModel)]="data.sales_price" required (keyup)="setSalesPrice(data)"></td>
                                        <td><input type="text" class="ipcss pharma_invt_width" [(ngModel)]="data.unit_price" required ></td>
                                        <td><input type="text" class="ipcss pharma_invt_width" [(ngModel)]="data.cgst" (keyup)="discountcal();setgstdata(data)" maxlength="2"  onkeypress="return event.charCode >= 48 && event.charCode <= 57"  matInput />
                                        </td>
                                        <td><input type="text" class="ipcss pharma_invt_width" [(ngModel)]="data.sgst" (keyup)="discountcal()"
                                                maxlength="2"
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                matInput />
                                        </td>
                                        <!-- (keyup)="discountcal()" -->
                                        <td><input type="text" class="ipcss pharma_invt_width" [(ngModel)]="data.discount" (keyup)="discountcal()" maxlength="2" [disabled]="product_wise_discountflag"   onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                matInput />
                                        </td>
                                        <td><input style="margin-top: 13px;" class="ipcss pharma_invt_width" [(ngModel)]="data.mfg_date" matInput /></td>
                                        
                                        <td><input style="margin-top: 13px;" class="ipcss pharma_invt_width" type="tel" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                             (keyup)="changeExpiryDate(data.expiry_date,data)" (change)="validateExpiryDate(data.expiry_date,data)"
                                                [(ngModel)]="data.expiry_date" matInput maxlength="7" placeholder="mm-yyyy"/></td>
                                        <td>
                                            <img *ngIf="data.checked == false" (click)="isAllSelected(data,'select')" src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;"  width="15px" />
                                            <img *ngIf="data.checked == true" (click)="isAllSelected(data,'unselect')"  src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"  width="15px" />
                                            <a (click)="deleteItemReceive(data)" class="curser_pointer" style="margin-left:15px;">
                                                <img src="../../../assets/ui_icons/buttons/trash_icon.svg" width="15px"
                                                    height="auto" class="editDelicon" alt="">
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="col-0 col-sm-9 col-md-5 col-lg-12 col-xl-12" [hidden]="inventory.length == 0">
                        <div class="row" style="margin: 10px 0px;">
                          <div class="col-6">
                            <table>
                                <tr>
                                    <td><strong style="font-weight: 500;">Total Price (In-Words)</strong></td>
                                    <td>{{constpriceinwords}}</td>
                                </tr>
                            </table>
                          </div>
                          <div class="col-6">
                            <table style="margin-left:auto">
                                <tr>
                                    <td style="width:200px !important"><strong style="font-weight: 500;">Total Price</strong></td>
                                    <td class="align_right" *ngIf="!invoice_editflag">{{tcprice}}</td>
                                    <input type="text" class="ipcss" [(ngModel)]="tcprice" *ngIf="invoice_editflag">
                                </tr>

                                <tr>
                                    <td style="width:200px !important"><strong style="font-weight: 500;">Discount</strong></td>
                                    <td class="align_right" *ngIf="!invoice_editflag">{{disamt}}</td>
                                    <input type="text" class="ipcss" [(ngModel)]="disamt" *ngIf="invoice_editflag">
                                </tr>
                                
                                <tr>
                                    <td style="width:200px !important"><strong style="font-weight: 500;">GST</strong></td>
                                    <td class="align_right" *ngIf="!invoice_editflag">{{GSTamount}}</td>
                                    <input type="text" class="ipcss" [(ngModel)]="GSTamount"  *ngIf="invoice_editflag">
                                </tr>

                                <tr>
                                    <td style="width:200px !important"><strong style="font-weight: 500;">Invoice amount</strong></td>
                                    <td class="align_right" *ngIf="!invoice_editflag">{{invoiceAmount}}</td>
                                    <input type="text" class="ipcss" [(ngModel)]="invoiceAmount" (keyup)="invoiceamount(invoiceAmount)" *ngIf="invoice_editflag">
                                </tr>
                                
                                <tr>
                                    <td style="width:200px !important"><strong style="font-weight: 500;">Returns</strong></td>
                                    <td class="align_right" *ngIf="!invoice_editflag">{{returnAmount}}</td>
                                    <input type="text" class="ipcss" [(ngModel)]="returnAmount"  *ngIf="invoice_editflag">
                                </tr>

                                <!-- <tr>
                                    <td style="width:200px !important"><strong style="font-weight: 500;">Received amount</strong></td>
                                    <td class="align_right" *ngIf="!invoice_editflag">{{receivedAmount}}</td>
                                    <input type="text" class="ipcss" [(ngModel)]="receivedAmount"  *ngIf="invoice_editflag">
                                </tr> -->

                                <tr>
                                    <td style="width:200px !important"><strong style="font-weight: 500;">Balance amount</strong></td>
                                    <td class="align_right" *ngIf="!invoice_editflag">{{balance}}</td>
                                    <input type="text" class="ipcss" [(ngModel)]="balance"  *ngIf="invoice_editflag">
                                </tr>

                            </table>
                          </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>













